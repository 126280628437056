import React, { useReducer, createContext } from 'react';

export const SimulationContext = createContext();

const defaultState = {
  screenResolution: [1920, 1080],
  clientGroup: '',
  buildId: null,
  fullscreen: false,
  visiblePlaces: [],
  selectedLine: null,
  selectedDestination: null,
  selectedStop: null,
  playing: false,
  schemeId: null,
  currentLocation: undefined,

  // MediaServer
  dateTime: 0,
  iframeSrc: '',
  loading: true,
  history: [],
  error: null,
};

const actionFactory = (type) => (payload) => ({
  payload, type,
});

export const setScreenResolution = actionFactory('screenResolution');
export const setClientGroup = actionFactory('clientGroup');
export const setBuildId = actionFactory('buildId');
export const setFullscreen = actionFactory('fullscreen');
export const setVisiblePlaces = actionFactory('visiblePlaces');
export const setSelectedLine = actionFactory('selectedLine');
export const setSelectedDestination = actionFactory('selectedDestination');
export const setSelectedStop = actionFactory('selectedStop');
export const setPlaying = actionFactory('playing');
export const setSchemeId = actionFactory('schemeId');
export const setCurrentLocation = actionFactory('currentLocation');

// Media server
export const setDateTime = actionFactory('dateTime');
export const setIframeSrc = actionFactory('iframeSrc');
export const setLoading = actionFactory('loading');
export const setHistory = actionFactory('history');
export const setError = actionFactory('error');

function valuesReducer(state, action) {
  const { type, payload } = action;

  if (Object.hasOwnProperty.call(state, type)) {
    return {
      ...state,
      [type]: payload,
    };
  }

  return state;
}

export const StateManagerHOC = (Comp) => {
  const StateManager = (props) => {
    // api = [values, dispatchChange]
    // TODO: Findout if the array is immutable?
    const api = useReducer(valuesReducer, defaultState);

    return (
      <SimulationContext.Provider value={api}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Comp {...props} />
      </SimulationContext.Provider>
    );
  };
  return StateManager;
};
