import { extend } from 'lodash';
import Immutable from 'immutable';
import cancan from 'js/system/cancan';
import { PLANNING_LONG_TERM, PLANNING_TIMEPLAN } from 'constants/permissions';
import { firstDayOfWeek, parseISODateTime } from 'tzdateutils';
import { UNORDERED_THRESHOLD } from 'src/screens/Playlist/components/limit-picker';


// Prep templates
export function mediaByTmpl(media, templates) {
  // Group media by matching template
  return media.groupBy((m) => {
    const s = m.get('start');
    const e = m.get('end');
    const startDay = s && firstDayOfWeek(s);
    const oneWeekLater = startDay && new Date(startDay).setDate(startDay.getDate() + 7);

    // Media ends less than a week from interval start
    if (e && oneWeekLater >= e) {
      for (let i = 0; i < templates.length; i++) {
        const t = templates[i];
        const eday = (e.getDay() - 1 + 7) % 7;
        const sday = (s.getDay() - 1 + 7) % 7;

        if (sday >= t.start && eday <= t.end && sday <= eday) {
          return t.id;
        }
      }
    }

    return 'default';
  });
}

function getTimestamp(obj, attr) {
  const date = obj && obj.get && obj.get(attr);
  return date && date.valueOf && date.valueOf();
}

export function totalPlanned(planned, media) {
  let items = planned;
  const hasTimeplan = cancan.can('read', PLANNING_TIMEPLAN);
  const hasTimeLimits = cancan.can('read', PLANNING_LONG_TERM);

  if (!hasTimeplan && hasTimeLimits) {
    const now = Date.now();
    items = items.filter((mo) => {
      return getTimestamp(mo, 'start') < now && getTimestamp(mo, 'end') > now;
    });
  }

  // group plannings by block group, if block group != 0
  const grouped = items.toList().groupBy((p, idx) => {
    const group = p.get('block_group');
    const order = p.get('order');

    if (order > UNORDERED_THRESHOLD) {
      return `${order}_${idx}`;
    }

    return group === 0
      ? order
      : `-${group}`;
  });

  // sum the max length for each group
  return grouped.reduce((acc, g) => {
    return acc + g.reduce((acc2, item) => {
      const m = media.get(item.get('media').toString());
      return Math.max(acc2, (m && m.get('length')) || 0);
    }, 0);
  }, 0);
}

export function limitFilter(media, filterValue) {
  const hasTimeplan = cancan.can('read', PLANNING_TIMEPLAN);
  const hasTimeLimits = cancan.can('read', PLANNING_LONG_TERM);

  if (!media || hasTimeplan || !hasTimeLimits) {
    return media;
  }

  const now = Date.now();

  switch (filterValue) {
  case 'current':
    return media.filter((mo) => {
      return getTimestamp(mo, 'start') < now && getTimestamp(mo, 'end') > now;
    });

  case 'past':
    return media.filter((mo) => {
      return getTimestamp(mo, 'end') < now;
    });

  case 'future':
    return media.filter((mo) => {
      return getTimestamp(mo, 'start') > now;
    });

  default:
    return media.filter((mo) => {
      return getTimestamp(mo, 'end') >= now;
    });
  }
}

export function plannedLimits(media) {
  // Convert dates to date-objects.
  const _media = media.asMutable();
  return _media.map((mo) => {
    return mo.set('start', parseISODateTime(mo.get('time_limit_lower')))
      .set('end', parseISODateTime(mo.get('time_limit_upper')));
  }).asImmutable();
}

export default function prepTemplates(playlists, planned, mediaById, templates) {
  const groupedByPlaylist = planned.groupBy((p) => p.get('playlist'));

  return playlists.reduce((acc, pl) => {
    let tmpls = templates;
    const m = groupedByPlaylist.get(pl.get('id'));
    const groupedByTemplate = m ? mediaByTmpl(m, tmpls) : Immutable.Map();
    const defaultTmpl = groupedByTemplate.get('default');

    // If there is media outside the templates, add default template:
    if ((defaultTmpl && templates.length !== 1) || templates.length === 0) {
      tmpls = tmpls.concat([{
        id: 'default',
        name: '',
      }]);
    }

    // For every template, add media & calculate totals.
    acc[pl.get('id')] = tmpls.map((tmpl) => {
      const _planned = groupedByTemplate.get(tmpl.id) || Immutable.List();
      let totalPl = totalPlanned(_planned, mediaById);

      if (defaultTmpl && templates.length === 1) {
        totalPl += totalPlanned(groupedByTemplate.get('default') || Immutable.List(), mediaById);
      }
      return extend({
        planned: _planned,
        totalPlanned: totalPl,
      }, tmpl);
    });

    return acc;
  }, {});
}
