import { FormattedMessage } from 'react-intl';
import debug from 'debug';
import React from 'react';
import moment from 'moment';
import { parseISODateTime } from 'tzdateutils';
import List from 'js/react/_utils/react-list';

require('./gs_react_widgets.scss');

const log = debug('js:react:history:history-list');

// Rows used in the main list
class HistoryRow extends React.Component {
  state = {}

  shouldComponentUpdate(nextProps) {
    return (
      !nextProps.item.equals(this.props.item) ||
      nextProps.highlight !== this.props.highlight
    );
  }

  getFormattedTime = (time) => {
    try {
      if (time && typeof time === 'string') {
        var time = parseISODateTime(time.split(' ').join('T'));
        return isNaN(time.getTime()) ? '-' : moment(time).format('HH:mm:ss');
      }
    } catch (e) {
      console.log(e);
    }

    return time;
  }

  onTitleClick = (id) => {
    return (e) => {
      e.stopPropagation();
      e.preventDefault();

      this.props.onTitleClick(id);
    };
  }

  render() {
    log('render row');
    const { item } = this.props;
    const hasFixClass = item.get('has_fix') === false ? 'no-fix' : '';
    const formattedTime = this.getFormattedTime(item.get('time'));
    const name = item.get('object') ? item.get('object').get('name') : '';

    return (
      <tr
        onClick={() => this.props.onClick(item)}
        onMouseEnter={() => this.props.onHover(item.get('id'))}
        className={`${hasFixClass} ${this.props.highlight ? 'hover' : ''}`}
      >
        <td
          className=""
          title={name}
        >
          <a
            href="#"
            onClick={this.onTitleClick(item.get('object').get('mid'))}
          >
            {name}
          </a>
        </td>
        <td>{formattedTime}</td>
        <td className="text-nowrap">{item.get('bus') ? item.get('bus').get('name') : ''}</td>
        <td>{item.get('line') ? item.get('line').get('name') : ''}</td>
      </tr>
    );
  }
}

const HistoryListBody = List((props) => {
  return (
    <tbody>
      {props.items
        .map((i, idx) => (
          <HistoryRow
            key={idx}
            item={i}
            idx={idx}
            onHover={props.onHover}
            onTitleClick={props.onTitleClick}
            onClick={props.onClick}
            highlight={props.highlight == i.get('id')}
          />
        ))
        .toArray()}
    </tbody>
  );
});

// Main placelist component
class HistoryList extends React.Component { // eslint-disable-line
  state = {
    sortAsc: true,
    sortBy: 'name',
    sortFunc: 'name',
  }

  setSortParams = (category) => {
    return () => {
      if (this.state.sortBy === category) {
        this.setState({
          sortAsc: !this.state.sortAsc,
        });
      } else {
        let sortFunc = category;

        if (category === 'time') {
          sortFunc = (item) => {
            return new Date(item.get('time')).getTime();
          };
        } else if (category === 'object') {
          sortFunc = (item) => {
            return item.get('object').get('name');
          };
        }
        this.setState({
          sortAsc: true,
          sortBy: category,
          sortFunc,
        });
      }
    };
  }

  render() {
    log('render list');
    const sortChevron = this.state.sortAsc
      ? ' icon-chevron-up'
      : ' icon-chevron-down';

    return (
      <div className="table_container">
        <table
          className="table table-striped table-fixed mb-0"
          onMouseLeave={() => this.props.onHover(-1)}
          data-testid="HistoryList"
        >
          <colgroup span="6">
            <col span="1" />
            <col span="1" style={{ width: '14%' }} />
            <col span="1" style={{ width: '35%' }} />
            <col span="1" style={{ width: '12%' }} />
          </colgroup>
          <thead>
            <tr>
              <th className="sortList" onClick={this.setSortParams('object')}>
                <i
                  className={
                    `sortIcon${
                      this.state.sortBy === 'object'
                        ? sortChevron
                        : ' icon-chevron-right'}`
                  }
                />
                <FormattedMessage id="common.name" />
              </th>
              <th className="sortList" onClick={this.setSortParams('time')}>
                <i
                  className={
                    `sortIcon${
                      this.state.sortBy === 'time'
                        ? sortChevron
                        : ' icon-chevron-right'}`
                  }
                />
                <FormattedMessage id="common.time" />
              </th>
              <th className="sortList" onClick={this.setSortParams('bus')}>
                <i
                  className={
                    `sortIcon${
                      this.state.sortBy === 'bus'
                        ? sortChevron
                        : ' icon-chevron-right'}`
                  }
                />
                <FormattedMessage id="common.client" />
              </th>
              <th className="sortList p-0" onClick={this.setSortParams('line')}>
                <i
                  className={
                    `sortIcon${
                      this.state.sortBy === 'line'
                        ? sortChevron
                        : ' icon-chevron-right'}`
                  }
                />
                <FormattedMessage id="common.line" />
              </th>
            </tr>
          </thead>
          <HistoryListBody
            items={this.props.history}
            highlight={this.props.highlight}
            onHover={this.props.onHover}
            onTitleClick={this.props.onTitleClick}
            onClick={this.props.onClick}
            sortBy={this.state.sortFunc}
            asc={this.state.sortAsc}
          />
        </table>
      </div>
    );
  }
}

export default HistoryList;
