import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  fetchHealthVariables,
} from 'src/entities/healthVariables';
import { fetchClientHealthStatuses, getClientHealthStatusesInArray } from 'src/entities/healthStatuses';
import Spinner from '../../components/Spinner';
import HealthPie from './HealthPie';

function mapStateToProps() {
  return (state) => ({
    clientHealthStatuses: getClientHealthStatusesInArray(state),
  });
}
const mapDispatchToActions = {
  fetchClientHealthStatuses,
  fetchHealthVariables,
};


class ClientHealth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingStatuses: true,
      loadingVars: true,
    };
  }

  componentDidMount() {
    this.loadData();

    this.timer = setInterval(this.loadData, 60000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  loadData = () => {
    this.props.fetchClientHealthStatuses().then(() => {
      this.setState({
        loadingStatuses: false,
      });
    });
    this.props.fetchHealthVariables().then(() => {
      this.setState({
        loadingVars: false,
      });
    });
  }

  render() {
    if (this.state.loadingStatuses || this.state.loadingVars) {
      return (
        <div className="d-col d-flex">
          <Spinner
            style={{ minWidth: 300, height: 200 }}
          />
        </div>
      );
    }

    const { clientHealthStatuses } = this.props;

    return (
      <div className="d-col d-flex">
        <HealthPie
          statuses={clientHealthStatuses}
        />
      </div>
    );
  }
}

ClientHealth.propTypes = {
  clientHealthStatuses: PropTypes.array.isRequired,
  fetchClientHealthStatuses: PropTypes.func.isRequired,
  fetchHealthVariables: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToActions)(ClientHealth);
