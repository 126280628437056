import React from 'react';
import { useSelector } from 'react-redux';
import * as selectors from 'js/redux/selectors';

import './timeline.scss';
import { ConditionsModal } from 'src/screens/MediaPlaybackConditions';

const TimelineItem = ({
  upper, lower, title, overflowUpper, overflowLower,
}) => {
  let bg = (upper === 1 && lower === 0) ? ' inactive' : '';
  if (overflowUpper) {
    bg += ' overflowUpper';
  }
  if (overflowLower) {
    bg += ' overflowLower';
  }

  let _upper = upper;
  let _lower = lower;
  if (upper > 1 || upper < 0 || lower < 0 || lower > 1) {
    _upper = 1;
    _lower = 0;
  }

  const baseStyle = {
    height: 8,
  };

  const fillStyle = {
    height: '100%',
    left: `${Math.round(Math.max(_lower, 0) * 1000) / 10}%`,
    right: `${Math.round(Math.min(_upper, 1) * 1000) / 10}%`,
    position: 'absolute',
    borderRadius: 3,
  };

  return (
    <div
      style={baseStyle}
      title={title}
      data-testid="limit-bar"
      className="position-relative"
    >
      <div
        style={fillStyle}
        className={`timeline${bg}`}
      />
    </div>
  );
};

export const Timeline = ({ start, end, plannings }) => {
  const minDate = useSelector(selectors.getFromDate);
  const maxDate = useSelector(selectors.getToDate);

  const min = minDate && minDate.valueOf();
  const max = maxDate && maxDate.valueOf();
  const tot = max - min;
  const startDiff = start.valueOf() - min;
  const endDiff = max - end.valueOf();

  return (
    <tr>
      <td
        colSpan="5"
        className="bg-gray p-0"
      >
        <ConditionsModal
          plannings={plannings.toJS()}
          minDate={minDate}
          maxDate={maxDate}
          className="w-100 d-block p-0 border-0"
        >
          <TimelineItem
            title={`${start.format('YYYY-MM-DD HH:mm')} - ${end.format('YYYY-MM-DD HH:mm')}`}
            upper={Math.max(endDiff, 0) / tot}
            lower={Math.max(startDiff, 0) / tot}
            overflowUpper={endDiff < 0}
            overflowLower={startDiff < 0}
          />
        </ConditionsModal>
      </td>
    </tr>
  );
};
