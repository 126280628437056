import { node } from 'prop-types';
import React from 'react';

export const ConditionSection = ({ label, children, options }) => {
  return (
    <div className="mb-4">
      <div className="d-flex">
        <h5>{label}</h5>
        <div className="ml-auto">
          {options}
        </div>
      </div>
      {children}
    </div>
  );
};

ConditionSection.propTypes = {
  label: node,
  children: node,
};
