import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

class AddTag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newTagName: '',
      showInput: false,
    };
  }

  render() {
    const {
      intl: { formatMessage },
    } = this.props;

    const {
      newTagName,
      showInput,
    } = this.state;

    return (
      <div style={{ marginTop: '10px' }}>
        {!showInput && (
          <button
            className="btn btn-md mt-1 btn-secondary"
            data-testid="addTagButton"
            type="button"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              this.setState({ showInput: true });
            }}
          >
            <FormattedMessage id="views.playlists.addSection" />
          </button>
        )}
        {showInput && (
          <input
            data-testid="addTagInput"
            type="text"
            className="form-control"
            onChange={(event) => {
              this.setState({
                newTagName: event.target.value,
              });
            }}
            placeholder={formatMessage({ id: 'views.playlists.typeSectionName' })}
            value={newTagName}
          />
        )}
        {showInput && (
          <button
            className={`btn btn-md mt-1 btn-primary ${!newTagName && ' disabled'}`}
            data-testid="saveTagButton"
            type="button"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              if (newTagName) {
                this.props.onTagAdd(newTagName);
                this.setState({ newTagName: '', showInput: false });
              }
            }}
            style={{ float: 'left' }}
          >
            <FormattedMessage id="views.playlists.saveNewSection" />
          </button>
        )}
      </div>
    );
  }
}

export default injectIntl(AddTag);
