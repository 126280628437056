import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import stringUtils from 'js/react/_utils/stringUtils';

export const TurnaroundTimeInput = ({ isEditing, scheme, onChange }) => {
  const [value, setValue] = useState(scheme.get('duration'));

  useEffect(() => {
    setValue(scheme.get('duration'));
  }, [scheme.get('duration')]);

  return (
    <p>
      <FormattedMessage id="views.playlists.turnAroundTime" />
      {':'}
      <span className="bold form-inline">
        {stringUtils.toMMSS(scheme.get('duration'))}
        {isEditing && (
          <input
            type="number"
            className="form-control input-sm ma-0 mb-0 ml-2 scheme-duration"
            onChange={(e) => setValue(e.target.value)}
            onBlur={() => onChange(value)}
            value={value}
          />
        )}
      </span>
    </p>
  );
};
