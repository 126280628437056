import React from 'react';
import PropTypes from 'prop-types';
import { TokenPicker } from 'src/components/SpeechAudioInput/TokenPicker';
import HighlightedTextArea from './HighlightedTextArea';

const propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

function AnnouncementText(props) {
  const {
    tags,
    value,
    onChange,
    disabled,
  } = props;
  const highligths = tags && tags.map(
    ({ tag }) => tag,
  );

  function addMarker(marker) {
    onChange(
      value + marker,
    );
  }

  return (
    <div>
      <div className="mb-1">
        <TokenPicker
          className="btn btn-sm btn-light border mr-1 mb-1"
          onChange={addMarker}
          disabled={disabled}
        />
      </div>
      <HighlightedTextArea
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        highligths={highligths}
      />
    </div>
  );
}

AnnouncementText.propTypes = propTypes;

export default AnnouncementText;
