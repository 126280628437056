/* global parseISODateTime */
import moment from 'moment';

export const createPrepareMediaObjectForPlanning = ({ highestOrder, playlistId }) => {
  return function prepareMediaObjectForPlanning(mediaObject, index) {
    return {
      media: mediaObject.id,
      order: highestOrder + 1 + index,
      playlist: playlistId,
      block_group: 0,
      time_limit_lower: '1970-01-01T00:00:00',
      time_limit_upper: '2100-01-01T23:59:59',
    };
  };
};

export const enhanceMediaWithTimeTemplates = ({ fromDate, preparedMediaObjects, templates }) => {
  return templates.reduce((prepped, { start: startInput, end: endInput }) => {
    const tmplMedia = preparedMediaObjects.map((mediaObject) => {
      const start = moment(fromDate).add(startInput, 'd');
      const end = moment(fromDate).add(endInput, 'd').endOf('d');

      return {
        ...mediaObject,
        time_limit_lower: start.format('YYYY-MM-DDTHH:mm:ss'),
        time_limit_upper: end.format('YYYY-MM-DDTHH:mm:ss'),
      };
    });

    return prepped.concat(tmplMedia);
  }, []);
};
