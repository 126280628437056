import Collection from 'ol/Collection';
import Draw from 'ol/interaction/Draw';
import LayerVector from 'ol/layer/Vector';
import Map from 'ol/Map';
import Modify from 'ol/interaction/Modify';
import OSM, { ATTRIBUTION } from 'ol/source/OSM';
import SourceVector from 'ol/source/Vector';
import TileLayer from 'ol/layer/Tile';
import View from 'ol/View';
import WKT from 'ol/format/WKT';
import { getDistance } from 'ol/sphere';
import { shiftKeyOnly, singleClick } from 'ol/events/condition';
import { transform } from 'ol/proj';

import { DefaultStyle, HighlightStyle } from '../style';

export function createOsmMap({ center, eventListeners = {}, target }) {
  const map = new Map({
    target,
    layers: [
      new TileLayer({
        source: new OSM({
          layer: 'osm',
          attributions: [ATTRIBUTION],
        }),
      }),
    ],
    view: new View({
      center,
      zoom: 13,
      maxZoom: 18,
    }),
  });

  Object.keys(eventListeners).forEach((key) => {
    if (key && eventListeners[key]) {
      map.on(key, eventListeners[key]);
    }
  });

  return map;
}

export function wkt2vector(wkt, id = 0) {
  /*  wkt ='POLYGON((10.689697265625 -25.0927734375, 34.595947265625 ' +
        '-20.1708984375, 38.814697265625 -35.6396484375, 13.502197265625 ' +
        '-39.1552734375, 10.689697265625 -25.0927734375))'; */

  const formater = new WKT();
  const vector = wkt && formater.readFeature(wkt, {
    dataProjection: 'EPSG:900913',
    featureProjection: 'EPSG:900913',
  });

  vector.setId(id);

  return vector;
}

export function vector2wkt(feature, dataProj = 'EPSG:9000913') {
  const formater = new WKT();
  const wkt = formater.writeFeature(feature, {
    dataProjection: dataProj,
    featureProjection: 'EPSG:900913',
  });
  return wkt;
}

export function panTo(map, dst) {
  const view = map.getView();

  view.setCenter(dst);
}

export function createLayerSource(features, style) {
  const layerSource = new SourceVector({});
  layerSource.addFeatures(features);

  const layer = new LayerVector({
    source: layerSource,
    style,
  });

  return layer;
}

export function createModify(features) {
  return new Modify({
    features: new Collection(features),
    deleteCondition: (event) => {
      return shiftKeyOnly(event) && singleClick(event);
    },
  });
}

export function createDrawInteraction(callback, type) {
  const draw = new Draw({
    type,
    style: (type === 'LineString' && DefaultStyle) || HighlightStyle,
  });

  draw.on('drawend', (f) => {
    callback({
      wkt: vector2wkt(f.feature, 'EPSG:4326'),
      wkt1: vector2wkt(f.feature),
    });
  });

  return draw;
}

export function transWgs84(coord) {
  return transform(coord, 'EPSG:3857', 'EPSG:4326');
}

export function featureFromPixel(map, layer, pixel) {
  if (!layer) {
    return null;
  }
  const coord = map.getCoordinateFromPixel(pixel);

  const features = layer.getSource().getFeaturesAtCoordinate(coord);

  if (features.length > 0) {
    return features[0];
  }

  const closest = layer.getSource().getClosestFeatureToCoordinate(coord);

  if (!closest) {
    return null;
  }

  const c1 = transWgs84(coord);
  const c2 = transWgs84(closest.getGeometry().getCoordinates());
  const dist = getDistance(c1, c2);
  if (dist < 100) {
    return closest;
  }

  return null;
}
