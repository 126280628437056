import React, { Component } from 'react';
import {
  number, object, oneOfType, shape, string,
} from 'prop-types';
import debug from 'debug';

const log = debug('js:react:plan2:media-sidebar:Widgets:computeFormProps');

const propTypes = {
  mediaObject: shape({
    id: oneOfType([number, string]).isRequired,
    length: number.isRequired,
    name: string.isRequired,
    config: object,
  }),
  widgetTypeSpecification: shape({
    config: object.isRequired,
    name: string.isRequired,
    type: string.isRequired,
  }),
};

const computeFormProps = (ComposedComponent) => {
  class WithFormProps extends Component {
    constructor(props) {
      super(props);
      this.state = {
        configKeys: undefined,
        initialValues: undefined,
        required: undefined,
      };
    }

    UNSAFE_componentWillMount() {
      const { mediaObject, widgetTypeSpecification } = this.props;

      if (widgetTypeSpecification) {
        this.extractConfigKeys(widgetTypeSpecification);
      }

      if (mediaObject) {
        this.extractInitialValues(mediaObject);
      }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (
        (!this.props.widgetTypeSpecification &&
          nextProps.widgetTypeSpecification) ||
        (this.props.widgetTypeSpecification &&
          this.props.widgetTypeSpecification !==
            nextProps.widgetTypeSpecification)
      ) {
        this.extractConfigKeys(nextProps.widgetTypeSpecification);
      }

      if (
        (!this.props.mediaObject && nextProps.mediaObject) ||
        (this.props.mediaObject &&
          this.props.mediaObject !== nextProps.mediaObject)
      ) {
        this.extractInitialValues(nextProps.mediaObject);
      }
    }

    extractConfigKeys = ({ config, required }) => {
      this.setState({
        configKeys: Object.keys(config || {}),
        requiredKeys: required,
      });
    };

    extractInitialValues = ({
      config, id, length, name,
    }) => {
      log('extractInitialValues');

      const { required, ...configParams } = config || {}; // eslint-disable-line no-unused-vars

      this.setState({
        initialValues: {
          ...configParams,
          id,
          length,
          name,
        },
      });
    };

    render() {
      const { configKeys, initialValues, requiredKeys } = this.state;

      return (
        <ComposedComponent
          configKeys={configKeys}
          requiredKeys={requiredKeys}
          initialValues={initialValues}
          {...this.props}
        />
      );
    }
  }

  WithFormProps.propTypes = propTypes;

  return WithFormProps;
};

export default computeFormProps;
