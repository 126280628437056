import React, { useState, useEffect } from 'react';
import Immutable from 'immutable';

import ContextProvider from 'components/ContextProvider';
import * as MessageActions from 'js/react/actions/message-actions';
import MessageConstants from 'js/react/constants/message-constants';
import MsgStore from 'js/react/stores/message-store';

function getClassNameByType(message) {
  switch (message.get('type')) {
  case MessageConstants.MESSAGE_SUCCESS:
    return 'alert-success';
  case MessageConstants.MESSAGE_ERROR:
    return 'alert-danger';
  default:
    return 'alert-info';
  }
}

function getDisplayTimeByType(message) {
  switch (message.get('type')) {
  case MessageConstants.MESSAGE_SUCCESS:
    return 5;
  case MessageConstants.MESSAGE_ERROR:
    return 8;
  default:
    return 5;
  }
}

function Message({ displayTime, message = Immutable.Map() }) {
  useEffect(() => {
    // start timer to remove message
    setTimeout(
      () => {
        MessageActions.hide(message.get('id'));
      },
      displayTime * 1000,
    );
  }, []);

  function close() {
    MessageActions.hide(message.get('id'));
  }

  return (
    <div className={`alert alert-block ${getClassNameByType(message)}`}>
      <button
        type="button"
        className="close ml-1"
        aria-label="Close"
        onClick={close}
      >
        <span aria-hidden="true">
          {'\u00D7'}
        </span>
      </button>
      {message.get('text')}
    </div>
  );
}

function MessageController() {
  const [messages, setMessage] = useState();

  useEffect(
    () => {
      function handleMsgStoreChange() {
        setMessage((MsgStore.getState() || {}).messages);
      }
      MsgStore.addChangeListener(handleMsgStoreChange);
      return () => {
        // Clean up
        MsgStore.removeChangeListener(handleMsgStoreChange);
      };
    },
    [],
  );

  return (
    <ContextProvider>
      <div>
        {messages && messages.size && messages
          .filter((x) => x.get('show') === true)
          .map((item, i) => (
            <Message
              key={i}
              message={item}
              show={item.get('show')}
              displayTime={getDisplayTimeByType(item)}
            />
          ))}
      </div>
    </ContextProvider>
  );
}

export default MessageController;
