import React from 'react';
import PropTypes from 'prop-types';
import { geoId, playerLayoutAreaShape } from 'utils/shapes';
import { LAYOUT_AREA_MEDIA } from '../_utils';

const AREA_STYLE = {
  backgroundColor: '#eee',
  position: 'absolute',
  opacity: 0.8,
  width: 20,
  height: 20,
  top: 0,
  left: 0,
};

function getAreaPosition({
  width, height, top, left,
}) {
  return {
    width: `${width}%`,
    height: `${height}%`,
    top: `${top}%`,
    left: `${left}%`,
  };
}

function convertPosition({ content = {} } = {}, { media_type } = {}) {
  if (media_type && media_type !== 'image') {
    return {
      ...content,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '50%',
      backgroundPosition: 'center',
      backgroundColor: 'rgba(124, 183, 213, 0.9)',
    };
  }

  return {
    ...content,
    backgroundRepeat: 'no-repeat',
    backgroundSize: content.objectFit,
    backgroundPosition: content.objectPosition,
    objectFit: undefined,
    objectPosition: undefined,
  };
}

function getPreview(settings = {}, image) {
  if (image && settings.contentType === LAYOUT_AREA_MEDIA) {
    return image.thumb || image.preview;
  }

  return '';
}

function getBgImage(settings = {}, image) {
  const bg = getPreview(settings, image);
  return bg
    ? { backgroundImage: `url(${bg})` }
    : {};
}

function Area({
  id, areaSettings, nolabels, overlayText, media, other,
}) {
  const image = media[areaSettings.content];
  const style = areaSettings.style || {};

  return (
    <div
      className="border d-flex bbox justify-content-center"
      style={{
        ...AREA_STYLE,
        ...(style.area || {}),
        ...getAreaPosition(other),
      }}
    >
      <div
        className="d-flex bbox justify-content-center"
        style={{
          ...convertPosition(style, image),
          ...getBgImage(areaSettings, image),
          flexGrow: 1,
        }}
      >
        {!nolabels && (
          <span className="align-self-center p-2 rounded" style={{ backgroundColor: 'rgba(255,255,255,0.5)' }}>
            <b>{id}</b><br />
            <span>{overlayText}</span>
          </span>
        )}
      </div>
    </div>
  );
}

Area.propTypes = {
  id: geoId,
  areaSettings: playerLayoutAreaShape,
  nolabels: PropTypes.bool,
  overlayText: PropTypes.object,
  media: PropTypes.object, // eslint-disable-line
  other: PropTypes.object, // eslint-disable-line
};

export default Area;
