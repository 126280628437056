import React, { useState } from 'react';
import PropTypes, { array, objectOf, shape } from 'prop-types';
import Dropdown, { DropdownToggle, DropdownContent } from 'components/Dropdown';
import { ConditionPicker } from './ConditionPicker';

const propTypes = {
  trigger: shape({
    conditions: objectOf(array),
  }).isRequired,
  playlistId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const ConditionDropdown = ({
  playlistId, trigger, onChange, disabled,
}) => {
  const { conditions } = trigger;
  const [tab, setTab] = useState(Object.keys(conditions || {})[0]);

  const handleTab = (t) => (e) => {
    e.preventDefault();
    setTab(t);
  };

  const handleChange = (key, values) => {
    onChange({
      ...trigger,
      conditions: {
        ...(conditions || {}),
        [key]: values,
      },
    });
  };

  const renderTabs = () => {
    return (
      <ul className="nav nav-tabs flex-nowrap mb-3">
        {Object.keys(conditions || {}).map((key) => {
          const className = `nav-link text-capitalize${tab === key ? ' active' : ''}`;
          return (
            <li
              key={key}
              className="nav-item"
            >
              <a
                href="#"
                className={className}
                onClick={handleTab(key)}
              >
                {key}
              </a>
            </li>
          );
        })}
      </ul>
    );
  };

  const getLabels = () => {
    const size = Object.values(conditions || {})
      .reduce((acc, selected) => acc + selected.length, 0);

    return size > 0
      ? `${size} items`
      : '+ Add';
  };

  return (
    <div className="mb-1 float-left" style={{ clear: 'both' }}>
      <Dropdown
        right
        className="btn-group float-right"
        disabled={disabled}
      >
        <DropdownToggle
          className="btn btn-primary btn-sm"
        >
          {getLabels()}
        </DropdownToggle>
        <DropdownContent>
          <div className="p-2">
            {renderTabs()}
            <ConditionPicker
              key={tab}
              playlistId={playlistId}
              conditionKey={tab}
              selected={trigger.conditions?.[tab]}
              onChange={handleChange}
            />
          </div>
        </DropdownContent>
      </Dropdown>
    </div>
  );
};

ConditionDropdown.propTypes = propTypes;

export default ConditionDropdown;
