/*global module, require */

/*
	Modified pure render-mixin that correctly compares Immutables.
	Import as mixin.
*/

var isEqual = function (next, current) {
	var key;

	for (var key in next) {
		if (next.hasOwnProperty(key)) {
			if (!current.hasOwnProperty(key)) {
				return false;
			}

			var nxt = next[key],
				curr = current[key];

			// Compare Immutables
			if (nxt && nxt.equals) {
				if (nxt.equals(curr)) {
					continue;
				}
				return false;
			} else if (curr && curr.equals) {
				return false;
			}

			if (nxt !== curr) {
				return false;
			}
		}
	}

	return true;
};

export default {
	shouldComponentUpdate: function shouldComponentUpdate(nextProps, nextState) {
		return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
	}
};