import { getColors } from './createPieData';

export default function createBarData(dates, media, {
  colors,
  limit,
}) {
  const colr = colors || getColors(media);
  const count = limit || media.size;
  const sorted = media.sortBy(
    (d) => -d.get('cnts').reduce((acc, c) => acc += c, 0),
  );

  const datasets = sorted.take(count).map((m) => {
    const id = m.get('id');
    const data = dates.map((k) => (m.get('cnts').get(k) || 0) * 10);
    const color = colr[id] || '#ddd';

    return {
      label: m.get('id'),
      backgroundColor: color,
      data,
    };
  }).toJS();

  const rest = sorted.skip(count);
  if (rest && rest.size > 0) {
    const otherData = rest.reduce((acc, m) => {
      return dates.map((k, i) => {
        const prev = acc[i] || 0;
        return prev + ((m.get('cnts').get(k) || 0) * 10);
      });
    }, []);

    datasets.push({
      label: 'other',
      backgroundColor: '#ddd',
      data: otherData,
    });
  }

  return {
    labels: dates,
    datasets,
  };
}
