import { identity } from 'lodash';
import React, { useContext, useMemo, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { DropdownList } from 'react-widgets';

import { getSchemesInObject } from 'src/entities/schemes';

import { useClientGroups } from '../hooks/useClientGroups';
import { setClientGroup, setSchemeId, SimulationContext } from './StateManager';

const useFormattedSchemes = () => {
  const schemes = useSelector(getSchemesInObject);

  return useMemo(() => {
    return Object.values(schemes || {}).map((scheme) => ({
      ...scheme,
      displayName: scheme.name,
      type: 'scheme',
    })).filter(identity);
  }, [schemes]);
};

const GroupHeading = ({ item }) => {
  return (
    <FormattedMessage id={item === 'scheme'
      ? 'common.schemes'
      : 'common.clientGroups'}
    />
  );
};

export const SchemePicker = () => {
  const clientGroups = useClientGroups();
  const schemes = useFormattedSchemes();
  const [{ schemeId }, dispatch] = useContext(SimulationContext);

  const onSchemeChange = (scheme) => {
    if (scheme.type === 'scheme') {
      dispatch(setSchemeId(scheme.id));
      dispatch(setClientGroup(''));
    } else {
      dispatch(setClientGroup(scheme.name));
    }
  };

  useEffect(() => {
    if (schemes.length && !schemeId) {
      dispatch(setSchemeId(schemes[0]?.id));
    }
  }, [schemes]);

  return (
    <DropdownList
      data={[
        ...schemes,
        ...clientGroups,
      ]}
      onChange={onSchemeChange}
      defaultValue={schemes[0]}
      groupBy={(item) => item.type}
      groupComponent={GroupHeading}
      textField={(item) => item.displayName}
    />
  );
};
