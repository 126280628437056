import React, { useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import GSColumnsLayout from 'js/react/components/GSColumnsLayout';
import { useAsyncDispatch } from 'src/helpers/useAsyncDispatch';
import { fetchPlaces } from 'src/entities/places';
import { fetchGroups } from 'js/redux/api/data';

import { PlacesMap as PlacesOpenStreetMap } from './PlacesMap';
import { PlacesList } from './PlacesList';
import { InterceptNav } from './InterceptNav';
import { EditPlace } from './EditPlace';
import { PlacesStateManager } from './PlacesStateManager';
import { AddPlace } from './AddPlace';

const useActions = (actions = '') => {
  const parts = (actions && actions.split('/')) || [''];
  const placeToEditId = parts[1] === 'edit' ? parts[2] : null;
  const visibleIds = useMemo(() => {
    return parts[0] !== 'edit' && parts[0] !== 'create' && parts[0]
      ? parts[0].split(',')
      : [];
  }, [parts[0]]);

  if (parts.includes('create')) {
    return [visibleIds, null, true];
  }

  if (actions === 'create') {
    return [[], null, true];
  }

  return [visibleIds, placeToEditId];
};

export const Places = () => {
  const [dispatch, pending] = useAsyncDispatch();
  const [visibleIds, placeToEditId, create] = useActions(useParams().id);

  useEffect(() => {
    dispatch(fetchPlaces());
    dispatch(fetchGroups());
  }, []);

  return (
    <InterceptNav visibleIds={visibleIds} placeToEditId={placeToEditId}>
      <PlacesStateManager>
        <GSColumnsLayout
          leftCol={
            <PlacesList visibleIds={visibleIds} isLoading={!!pending?.length} />
          }
          rightCol={
            <>
              <PlacesOpenStreetMap placeToEditId={placeToEditId} visibleIds={visibleIds} />
              {placeToEditId && (
                <EditPlace
                  placeToEditId={placeToEditId}
                  visibleIds={visibleIds}
                />
              )}
              {create && <AddPlace visibleIds={visibleIds} />}
            </>
          }
        />
      </PlacesStateManager>
    </InterceptNav>
  );
};
