import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { setScreenResolution, SimulationContext } from './StateManager';

export const ResolutionPicker = () => {
  const [{
    screenResolution,
  }, dispatch] = useContext(SimulationContext);

  const handleChange = (index) => (e) => {
    const newRes = [...screenResolution];
    newRes[index] = parseInt(e.target.value, 10);
    dispatch(setScreenResolution(newRes));
  };

  return (
    <div className="d-flex justify-content-center align-items-center text-light text-sm py-1">
      <span className="mr-2">
        <FormattedMessage id="views.simulation.screenResolution" />
      </span>
      <input
        className="form-control form-control-sm bg-secondary text-light w75"
        type="number"
        value={screenResolution[0]}
        onChange={handleChange(0)}
      />
      <span className="mx-1">{'x'}</span>
      <input
        className="form-control form-control-sm bg-secondary text-light w75"
        type="number"
        value={screenResolution[1]}
        onChange={handleChange(1)}
      />
    </div>
  );
};
