import { GEO_UI_LANGUAGE } from 'js/redux/i18n/constants';
import * as moment from 'moment';

// Set first day of week to Monday.
['en', 'sv', 'es'].forEach((language) => {
  moment.updateLocale(language, {
    longDateFormat: {
      L: 'YYYY-MM-DD',
    },
    week: {
      dow: 1,
    },
  });
});

const locale = () => {
  if (window.localStorage && window.localStorage.getItem(GEO_UI_LANGUAGE)) {
    moment.locale(window.localStorage.getItem(GEO_UI_LANGUAGE));
  } else {
    moment.locale('en');
  }
};

export default locale;
