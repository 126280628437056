import React from 'react';
import { Rnd } from 'react-rnd';
import { createSelector } from 'reselect';

const GRID = [1, 1];
const STYLE = {
  opacity: 0.8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 'solid 1px #ddd',
  background: '#f0f0f0',
  boxSizing: 'border-box',
};

class ResizeableBox extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = this.getDefaultPosition(props);
  }

  UNSAFE_componentWillReceiveProps({
    width, height, x, y,
  }) {
    this.setState({
      width, height, x, y,
    });
  }

  getDefaultPosition({
    width = 100,
    height = 100,
    x = Math.floor(Math.random() * 100),
    y = Math.floor(Math.random() * 100),
  }) {
    return {
      width, height, x, y,
    };
  }

  getStyle = createSelector(
    ({ style }) => style,
    ({ selected }, dragging) => dragging || selected,
    (custom, selected) => {
      return {
        ...STYLE,
        ...custom,
        border: selected
          ? '1px dashed #000'
          : 'none',
      };
    },
  )

  handleReposition = (e, { x, y }) => {
    this.setState({ x, y, dragging: false }, () => {
      this.props.onChange && this.props.onChange(this.state);
    });
  }

  handleResize = (e, direction, { style }, delta, position) => {
    this.setState({
      width: style.width,
      height: style.height,
      ...position,
    }, () => {
      this.props.onChange && this.props.onChange(this.state);
    });
  }

  handleDragStart = () => {
    this.setState({ dragging: true });
  }

  render() {
    const { overlay } = this.props;
    const canResize = !!(this.props.onChange && overlay);

    return (
      <Rnd
        className={overlay ? 'active' : undefined}
        bounds="parent"
        style={this.getStyle(this.props, this.state.dragging)}
        size={{
          width: this.state.width,
          height: this.state.height,
        }}
        position={{
          x: this.state.x,
          y: this.state.y,
        }}
        resizeHandleClasses={{
          bottom: 'resizeable-handle-bottom',
          bottomLeft: 'resizeable-handle-bottomLeft',
          bottomRight: 'resizeable-handle-bottomRight',
          left: 'resizeable-handle-left',
          right: 'resizeable-handle-right',
          top: 'resizeable-handle-top',
          topLeft: 'resizeable-handle-topLeft',
          topRight: 'resizeable-handle-topRight',
        }}
        onDragStart={this.handleDragStart}
        onDragStop={this.handleReposition}
        onResize={this.handleResize}
        resizeGrid={GRID}
        dragGrid={GRID}
        onMouseDown={this.props.onClick}
        disableDragging={!canResize}
        enableResizing={{
          bottom: canResize,
          bottomLeft: canResize,
          bottomRight: canResize,
          left: canResize,
          right: canResize,
          top: canResize,
          topLeft: canResize,
          topRight: canResize,
        }}
      >
        <h4 className="text-outline-white">
          {this.props.children}
        </h4>
      </Rnd>
    );
  }
}

export default ResizeableBox;
