import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import HelpIcon from 'components/HelpIcon';
import { success as successMessage } from 'js/react/actions/message-actions';
import { updatePassword } from 'js/redux/api/auth/actionCreators';

const getInitialState = () => ({
  oldPassword: '',
  newPassword: '',
  newPasswordConfirm: '',
});

const mapDispatchToProps = {
  updatePassword,
};

const propTypes = {
  updatePassword: PropTypes.func.isRequired,
};

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = getInitialState();
  }

  handleChangeOldPassword = (event) => {
    event.preventDefault();
    this.setState({
      oldPassword: event.target.value,
    });
  };

  handleChangeNewPassword = (event) => {
    event.preventDefault();
    this.setState({
      newPassword: event.target.value,
    });
  };

  handleChangeNewPasswordConfirm = (event) => {
    event.preventDefault();
    this.setState({
      newPasswordConfirm: event.target.value,
    });
  };

  handleUpdatePassword = (event) => {
    event.preventDefault();
    return this.props.updatePassword(this.state).then(() => {
      successMessage('Password updated');
      this.setState(getInitialState());
    });
  };

  render() {
    return (
      <div className="container-fluid pt44">
        <div className="row">
          <div className="col-6">
            <div className="mx-2 mb-2 mt-3 card p-3">
              <h2>
                <FormattedMessage id="common.changePassword" />
                <HelpIcon
                  bodyKey="help.changePassword.body"
                  headerKey="help.changePassword.header"
                />
              </h2>

              <label>
                <FormattedMessage id="common.currentPassword" />
                {':'}
                <br />
                <input
                  type="password"
                  className="form-control"
                  onChange={this.handleChangeOldPassword}
                  value={this.state.oldPassword}
                />
              </label>
              <label>
                <FormattedMessage id="common.newPassword" />
                {':'}
                <br />
                <input
                  type="password"
                  className="form-control"
                  onChange={this.handleChangeNewPassword}
                  value={this.state.newPassword}
                />
              </label>
              <label>
                <FormattedMessage id="common.repeatNewPassword" />
                {':'}
                <br />
                <input
                  type="password"
                  className="form-control"
                  onChange={this.handleChangeNewPasswordConfirm}
                  value={this.state.newPasswordConfirm}
                />
              </label>
              <button
                id="updatebtn"
                className="btn btn-primary"
                onClick={this.handleUpdatePassword}
                style={{ 'text-transform': 'capitalize' }}
              >
                <FormattedMessage id="common.changePassword" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ChangePassword.propTypes = propTypes;

export default connect(null, mapDispatchToProps)(ChangePassword);
