import React from 'react';

export const ApiErrorMessage = ({ error, className = '' }) => {
  return !error ? null : (
    <div className={`alert alert-danger ${className}`}>
      {error.payload?.code || 'Error'}{': '}
      {error.payload?.status || 'Unknown error'}
    </div>
  );
};
