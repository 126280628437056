import React, { memo } from 'react';

const HistoryItem = memo((props) => {
  const { name, thumb, playlist } = props;

  return (
    <div className="history-item row no-gutters">
      <div
        className="history-item-image-wrapper"
        style={{
          backgroundImage: `url(${thumb})`,
        }}
      />

      <div className="history-item-name col">
        {name}
        <div className="history-item-playlist">
          {playlist.name || 'Wat'}
        </div>
      </div>
    </div>
  );
});

export default HistoryItem;
