import React from 'react';
import { useIntl } from 'react-intl';
import { ODDDAY_CONDITION } from '../constants';
import { useFieldCondition } from '../hooks/useFieldCondition';
import { ConditionSection } from './ConditionSection';

export const OddDayCondition = () => {
  const { formatMessage } = useIntl();
  const [field, onChange] = useFieldCondition(ODDDAY_CONDITION, 'and');
  const { value: valueArray } = field || {};
  const value = valueArray?.[0];

  const handleSelect = (day) => () => {
    onChange(value === day ? null : [day]);
  };

  return (
    <ConditionSection
      label={formatMessage({ id: 'views.planning.playbackConditions.oddEvenDays' })}
    >
      <div className="btn-group">
        <button
          type="button"
          onClick={handleSelect('1')}
          className={`btn border${value === '1' ? ' btn-primary active' : ''}`}
        >
          {formatMessage({ id: 'views.planning.playbackConditions.oddDays' })}
        </button>
        <button
          type="button"
          onClick={handleSelect('0')}
          className={`btn border${value === '0' ? ' btn-primary active' : ''}`}
        >
          {formatMessage({ id: 'views.planning.playbackConditions.evenDays' })}
        </button>
      </div>
    </ConditionSection>
  );
};
