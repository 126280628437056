import { useEffect } from 'react';

// Push location to server when it changes
export const useSyncCurrentLocation = ({ currentLocation, error }, request, rootPath) => {
  const setLocation = ([lon, lat] = []) => {
    !error && rootPath && request(`${rootPath}/api/v1/location`, 'PUT', {
      lat,
      lon,
    });
  };

  useEffect(() => {
    // This has to be called twice to get past GPS noise controls
    setLocation(currentLocation);
    requestAnimationFrame(() => {
      setLocation(currentLocation);
    });
  }, [rootPath, currentLocation]);
};
