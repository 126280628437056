import moment from 'moment';
import Immutable from 'immutable';
import { createSelector } from 'reselect';

import { createSelectors } from 'src/core/data';
import cancan from 'js/system/cancan';
import { UNORDERED_THRESHOLD } from 'src/screens/Playlist/components/limit-picker';
import { getFromDate, getToDate } from 'js/redux/selectors';
import { PLANNING_TIMEPLAN } from 'constants/permissions';
import { PLANNED_MEDIA_COLLECTION, PLANNED_MEDIA_CONFIGS_COLLECTION } from './constants';

export const {
  getAll: getAllPlanned,
  getAllInArray: getPlanned,
  getById: getPlannedById,
} = createSelectors(PLANNED_MEDIA_COLLECTION);

export const {
  getAllInArray: getConfigs,
} = createSelectors(PLANNED_MEDIA_CONFIGS_COLLECTION);

export const getPlanningsByPlaylist = createSelector(
  (_, { id }) => id,
  (state, { from }) => from || getFromDate(state),
  (state, { to }) => to || getToDate(state),
  (state) => getPlanned(state),
  (id, fromDate, toDate, planned) => {
    const isInInterval = cancan.can('read', PLANNING_TIMEPLAN)
      ? (item) => {
        const f = moment(item.time_limit_lower);
        const to = moment(item.time_limit_upper);
        return f.isBefore(toDate) && to.isAfter(fromDate);
      }
      : () => true;

    return Immutable.fromJS(
      planned.filter((item) => {
        return (
          // Needs to be == because ids can be ints. Sigh...
          // eslint-disable-next-line eqeqeq
          item.playlist == id && isInInterval(item)
        );
      }),
    );
  },
);

function isUnordered(order) {
  return order >= UNORDERED_THRESHOLD;
}

export const getHighestOrder = (state, playlistId, ignoreOnordered = true) => {
  // Get the highest order of any planned object in (at least) the current interval.
  const plannings = getPlanned(state);
  return (plannings || []).reduce((t, m) => {
    // Needs to be "==" because of the int-id problem.
    // eslint-disable-next-line eqeqeq
    if (m.playlist == playlistId && !(ignoreOnordered && isUnordered(m.order))) {
      const o = m.order;
      return o > t ? o : t;
    }
    return t;
  }, -1);
};

export const getHighestOrdersForPlaylistIds = (state, playlistIds, ignoreOnordered = true) => {
  return (playlistIds || []).map(playlistId => getHighestOrder(state, playlistId, ignoreOnordered))
};

export const getHighestGroupOrder = (state, playlistId, ignoreOnordered = true) => {
  // Get the highest order of any planned object in (at least) the current interval.
  const plannings = getPlanned(state);
  return (plannings || []).reduce((t, m) => {
    // Needs to be "==" because of the int-id problem.
    // eslint-disable-next-line eqeqeq
    if (m.playlist == playlistId && !(ignoreOnordered && isUnordered(m.order))) {
      const o = m.block_group;
      return o > t ? o : t;
    }
    return t;
  }, -1);
};
