import { produce } from 'immer';
import set from 'lodash/set';
import unset from 'lodash/unset';
import { DATA_SET_COLLECTION, DATA_SET_ENTITY, DATA_REMOVE_ENTITY } from './constants';

const defaultState = {};

const mergeEntity = (state, collection, entity) => {
  const old = state[collection]?.[entity.id];
  return {
    ...(old || {}),
    ...entity,
  };
};

const normalizeArray = (list) => (!list ? {} : list.reduce(
  (acc, entity) => {
    acc[entity.id] = entity;
    return acc;
  }, {},
));

export const reducer = produce((draft, action) => {
  if (action.type.includes(DATA_SET_COLLECTION)) {
    const { reset, collection } = action.meta;

    if (reset) {
      draft[collection] = normalizeArray(
        action.payload.map((entity) => mergeEntity(draft, collection, entity)),
      );
    } else {
      draft[collection] = {
        ...(draft[collection] || {}),
        ...normalizeArray(
          action.payload.map((entity) => mergeEntity(draft, collection, entity)),
        ),
      };
    }
  }

  if (action.type.includes(DATA_SET_ENTITY)) {
    const { collection } = action.meta;
    set(draft, [collection, action.payload.id], mergeEntity(draft, collection, action.payload));
  }

  if (action.type.includes(DATA_REMOVE_ENTITY)) {
    const { collection } = action.meta;
    unset(draft, [collection, action.payload]);
  }
}, defaultState);
