import React from 'react';
import { reduce } from 'lodash';
import Store from 'js/stores/ajax-store';
import Immutable from 'immutable';
import moment from 'moment';
import { Listbox } from 'react-widgets';
import { FormattedMessage, injectIntl } from 'react-intl';
import HelpIcon from 'components/HelpIcon';

// import createBarData from './utils/createBarData';
import createPieData, { getColors } from './utils/createPieData';
import getDateRange from './utils/getDateRange';

// import BarChart from './components/BarChart';
import PieChart from './components/PieChart';
import StatsTable from './components/StatsTable';
import Filter from './components/Filter';
import DateRangePicker from './components/DateRangePicker';

import './report.scss';

const ReportStore = Store('/monitor/aggstats');
const PlaylistStore = Store('/playlists');

function sortInt(a, b) {
  return parseInt(a, 10) - parseInt(b, 10);
}

function sortStr(a, b) {
  return String(a).localeCompare(String(b));
}

export const filterConfig = Immutable.fromJS([
  {
    translationKey: 'common.clients',
    dataKey: 'buses',
    filteredKey: 'selectedBuses',
  },
  {
    translationKey: 'common.lines',
    dataKey: 'lines',
    filteredKey: 'selectedLines',
  },
  {
    translationKey: 'common.playlists',
    dataKey: 'playlists',
    filteredKey: 'selectedPlaylists',
  },
  {
    translationKey: 'common.media',
    dataKey: 'names',
    filteredKey: 'selectedNames',
  },
]);

export const aggregationConfig = [
  { translationKey: 'common.media', key: 'name', sortFunc: sortStr },
  { translationKey: 'common.client', key: 'bus-id', sortFunc: sortStr },
  { translationKey: 'common.line', key: 'line', sortFunc: sortInt },
  {
    translationKey: 'common.playlist',
    key: 'playlist_id',
    displayKey: 'playlist_name',
    sortFunc: sortStr,
  },
  { translationKey: 'common.date', key: 'date', sortFunc: sortStr },
];

class ReportView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: Immutable.List(),
      lines: Immutable.Set(),
      names: Immutable.Set(),
      buses: Immutable.Set(),
      playlists: Immutable.Set(),
      barChart: false,
      loading: false,
      inputReg: '.*',
      from: moment()
        .add(-3, 'd')
        .toDate(),
      to: new Date(),
      toggledMedia: Immutable.Set(),
      aggrField: aggregationConfig[0],
    };
  }

  componentDidMount() {
    // var reqs = this.state.dates.map((d) => ReportStore.fetch("date="+d));
    this.loadData(this.state.from, this.state.to);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !moment(this.state.from).isSame(moment(prevState.from)) ||
      !moment(this.state.to).isSame(moment(prevState.to))
    ) {
      this.loadData(this.state.from, this.state.to);
    }
  }

  clearFilters = () => {
    const filters = filterConfig.reduce((acc, f) => {
      acc[f.get('filteredKey')] = Immutable.Set();
      return acc;
    }, {});
    this.setState(filters);
  }

  onDateRangeChange = (start, end) => {
    this.setState({ from: start, to: end });
  }

  onAggregationChanged = (aggrField) => {
    this.setState({
      toggledMedia: Immutable.Set(),
      aggrField,
    });
  }

  onToggleMedia = (m) => {
    let toggledMedia;
    if (this.state.toggledMedia.has(m)) {
      toggledMedia = this.state.toggledMedia.remove(m);
    } else {
      toggledMedia = this.state.toggledMedia.add(m);
    }
    this.setState({ toggledMedia });
  }

  loadData = (start, end) => {
    const dates = getDateRange(start, end);

    // fetch reports for each selected date
    let ps = dates.map((d) => ReportStore.fetch(`date=${d}`));

    //  last, also fetch playlists to resolve names
    ps = ps.push(PlaylistStore.fetch());

    this.setState({ loading: true });

    Promise.all(ps).then((data) => {
      data = Immutable.List(data);

      // create an id->name map for all avaiable playlists
      const playlistNameMap = data.get(-1).reduce((acc, pl) => {
        return acc.set(pl.get('id'), pl.get('name'));
      }, Immutable.Map());

      // concatenate all fetched report data (excluding the playlists)
      // to a single list
      // for each report, set the playlist name using the playlistNameMap
      data = data.butLast().reduce((acc, l) => {
        const moddedList = l.map((m) => {
          const playlistId = m.get('playlist_id');
          const playlistName =
            playlistNameMap.get(playlistId) || `Unknown (${playlistId})`;

          return m.set('playlist_name', playlistName);
        });
        return acc.concat(moddedList);
      }, Immutable.List());

      this.setState({
        data,
        lines: data
          .map((d) => d.get('line'))
          .toOrderedSet()
          .sort(sortInt),
        names: data
          .map((d) => d.get('name'))
          .toOrderedSet()
          .sort(sortStr),
        buses: data
          .map((d) => d.get('bus-id'))
          .toOrderedSet()
          .sort(sortStr),
        playlists: data
          .map((d) => d.get('playlist_name'))
          .toOrderedSet()
          .sort(sortStr),
        loading: false,
      });
    });
  }

  render() {
    const dateRange = getDateRange(this.state.from, this.state.to).toJS();

    const filters = filterConfig.map((f, index) => {
      const onChange = (data) => {
        this.setState({ [`${f.get('filteredKey')}`]: data });
      };

      const data = this.state[f.get('dataKey')];
      const filtered = this.state[f.get('filteredKey')];

      return (
        <Filter
          key={index}
          name={this.props.intl.formatMessage({ id: f.get('translationKey') })}
          onChange={onChange}
          data={(data && data.toJS()) || []}
          filteredData={(filtered && filtered.toJS()) || []}
          selectAll
        />
      );
    });

    const filterData = (data, lines, names, buses, playlists) => {
      if (lines && lines.size > 0) {
        data = data.filter((d) => {
          return lines.has(d.get('line'));
        });
      }

      if (names && names.size > 0) {
        data = data.filter((d) => {
          return names.has(d.get('name'));
        });
      }

      if (buses && buses.size > 0) {
        data = data.filter((d) => {
          return buses.has(d.get('bus-id'));
        });
      }

      if (playlists && playlists.size > 0) {
        data = data.filter((d) => {
          return playlists.has(d.get('playlist_name'));
        });
      }

      return data;
    };

    // filter data
    const filteredData = filterData(
      this.state.data,
      this.state.selectedLines,
      this.state.selectedNames,
      this.state.selectedBuses,
      this.state.selectedPlaylists,
    );

    // aggregate data
    const aggregated = filteredData.reduce((acc, d) => {
      // include only media that matches current regex (or if regez is not set)
      const match = (`${d.get(this.state.aggrField.key)}`).match(
        new RegExp(this.state.inputReg),
      );
      const mediaId = match && match[0];

      if (!mediaId) {
        return acc;
      }

      const date = d.get('date');
      const cnt = d.get('cnt');
      const dur = d.get('duration');

      if (acc[`${mediaId}`]) {
        // aggregation record exists, update count and duration
        const dd = acc[mediaId];

        if (!dd.cnts[`${date}`]) {
          dd.cnts[`${date}`] = 0;
        }

        dd.cnts[`${date}`] += cnt;
        dd.tot += cnt;
        dd.dur += cnt * dur;
        return acc;
      }
      // aggregation does not exist
      const cnts = reduce(
        dateRange,
        (acc, d) => {
          acc[`${d}`] = 0;
          return acc;
        },
        {},
      );

      cnts[`${date}`] = cnt;

      acc[mediaId] = {
        cnts,
        tot: cnt,
        dur: cnt * dur,
        id: this.state.aggrField.displayKey ? d.get(this.state.aggrField.displayKey) : mediaId,
        name: d.get('name'),
        duration: dur,
      };
      return acc;
    }, {});

    const grouped = Immutable.fromJS(aggregated).toList();
    const toggled = this.state.toggledMedia;

    // const invalidDateRange = moment(this.state.to).diff(moment(this.state.from)) > 3600*24*7*1000;

    const expandToWeek = (e) => {
      e.preventDefault();
      const start = moment(this.state.from)
        .weekday(0)
        .toDate();
      const end = moment(this.state.from)
        .weekday(6)
        .toDate();

      this.setState({ from: start, to: end });
    };

    const regChange = (e) => {
      if (!e.keyCode || e.keyCode == 13) {
        const val = e.target.value;
        this.setState({ inputReg: val !== '' ? val : '.*' });
      }
    };

    const graphData = (toggled.size > 0 && toggled) || grouped;
    const colorById = getColors(graphData);

    const pieDataCount = createPieData(graphData, {
      type: 'cnts',
      colors: colorById,
    });

    const pieDataDur = createPieData(graphData, {
      type: 'dur',
      colors: colorById,
    });

    return (
      <div className="container-fluid report-view">
        <div className="card bshadow p-3 mt-3">
          <div className="row">
            <div className="col-4 p-3">
              <h3 className="mb-2">
                <FormattedMessage id="views.report.playbackReport" />
                <HelpIcon
                  bodyKey="help.report.body"
                  headerKey="help.report.header"
                />
              </h3>

              <h4 className="my-3">
                1. <FormattedMessage id="views.report.selectPeriod" />
              </h4>
              <DateRangePicker
                from={this.state.from}
                to={this.state.to}
                onChange={this.onDateRangeChange}
              />
              <p className="small">
                <a
                  href="#"
                  data-testid="reportToday"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ from: new Date(), to: new Date() });
                  }}
                >
                  <FormattedMessage id="common.today" />
                </a>
                &nbsp;-&nbsp;
                <a
                  href="#"
                  data-testid="reportWeek"
                  onClick={expandToWeek}
                >
                  <FormattedMessage id="views.report.expandToWeek" />
                </a>
              </p>
              <a
                href={`/api/gui/monitor/csvstats?from=${moment(
                  this.state.from,
                ).format('YYYY-MM-DD')}&to=${moment(this.state.to).format(
                  'YYYY-MM-DD',
                )}`}
              >
                <FormattedMessage id="views.report.downloadCsv" />
              </a>
              <hr />
              <h4 className="my-3">
                2. <FormattedMessage id="views.report.aggregate" />
              </h4>
              <div className="cap">
                <Listbox
                  data-testid="reportAggregationParameter"
                  value={this.state.aggrField}
                  onChange={this.onAggregationChanged}
                  textField="name"
                  dataKey="key"
                  data={aggregationConfig.map((config) => {
                    return {
                      ...config,
                      name: this.props.intl.formatMessage({
                        id: config.translationKey,
                      }),
                    };
                  })}
                />
              </div>
              <hr />
              <h4 className="my-3">
                3. <FormattedMessage id="common.filter" />
              </h4>
              {filters}
              <a
                onClick={this.clearFilters}
                className="btn btn-sm btn-light border mt-3"
              >
                <FormattedMessage id="views.report.clearFilters" />
              </a>
              <hr />

              <div className="mt-2">
                <em>
                  <FormattedMessage id="views.report.advancedGroupAggregationsWithRegexp" />
                </em>
                <input
                  className="form-control filter-input"
                  type="text"
                  onBlur={regChange}
                  onKeyDown={regChange}
                />
              </div>
            </div>

            <div className="col-8 pt-3 position-relative">
              {this.state.loading && (
                <em className="load-text">
                  <span>
                    <FormattedMessage id="common.loading" />...
                  </span>
                </em>
              )}
              <h4 className="my-3">
                4. <FormattedMessage id="views.report.compare" />
              </h4>

              <div className="d-flex">
                <div className="d-col">
                  <h5>
                    <FormattedMessage id="views.report.duration" />
                  </h5>
                  <PieChart
                    data={pieDataDur}
                  />
                </div>
                <div className="d-col">
                  <h5>
                    <FormattedMessage id="views.report.plays" />
                  </h5>
                  <PieChart
                    data={pieDataCount}
                  />
                </div>
              </div>

              {/* <div
                  onClick={() =>
                    this.setState({ barChart: !this.state.barChart })}
                >
                  <p className="small tt-none">
                    <a className={!this.state.barChart && 'disabled'}>
                      <FormattedMessage id="views.report.pieChart" />
                    </a>
                    &nbsp;-&nbsp;
                    <a className={this.state.barChart && 'disabled'}>
                      <FormattedMessage id="views.report.barChart" />
                    </a>
                  </p>
                  {!this.state.barChart && (
                    <PieChart
                      data={createPieData(
                        (toggled.size > 0 && toggled) || grouped
                      )}
                    />
                  )}
                  {this.state.barChart && (
                    <BarChart
                      data={createBarData(
                        dateRange,
                        (toggled.size > 0 && toggled) || grouped
                      )}
                    />
                  )}
                </div> */}

              <div>
                <button
                  type="button"
                  onClick={() => this.setState({ toggledMedia: Immutable.Set() })}
                  className={`btn btn-light btn-sm ${this.state.toggledMedia.size > 0 ? '' : 'disabled'}`}
                  disabled={!this.state.toggledMedia.size}
                >
                  <FormattedMessage id="views.report.clearSelection" />
                </button>
                <div style={{ overflowX: 'auto' }}>
                  <StatsTable
                    data={grouped}
                    colors={colorById}
                    dates={dateRange}
                    primarySortFunc={this.state.aggrField.sortFunc}
                    primaryFieldTranslationKey={
                      this.state.aggrField.translationKey
                    }
                    toggledMedia={this.state.toggledMedia}
                    onToggle={this.onToggleMedia}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(ReportView);
