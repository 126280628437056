import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import sortBy from 'lodash/sortBy';
import { injectIntl, useIntl } from 'react-intl';
import { intlShape } from 'utils/shapes';

import {
  updateUserViews,
} from 'js/redux/api/data';
import { success as successMessage } from 'js/react/actions/message-actions';

const mapDispatchToActions = {
  update: updateUserViews,
};

const propTypes = {
  update: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  className: PropTypes.string,
};
const defaultProps = {};

const ViewOption = ({ name, checked, onChange }) => {
  const intl = useIntl()

  const localizedLabel = intl.formatMessage({
    id: `common.${name}`
  }).replace('common.', '');

  return (
    <td>
      <div className="form-check">
        <label
          className="form-check-label"
        >
          <input
            name={name}
            type="checkbox"
            className="form-check-input"
            checked={checked}
            onChange={onChange}
            data-testid={name}
          />
          {localizedLabel}
        </label>
      </div>
    </td>
  );
};

class UserViewPermissions extends React.Component {
  constructor(props) {
    super(props);
    const { accessible_views: views } = props.user;

    this.state = { views };
  }

  handleViewCheckboxChange = (event) => {
    const { target } = event;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    // if (name === HISTORY_AND_REPORT) {
    //   return this.setState(({ views }) => ({
    //     views: {
    //       ...views,
    //       history: value,
    //       report: value,
    //     },
    //   }));
    // }

    return this.setState(({ views }) => ({
      views: {
        ...views,
        [name]: value,
      },
      hasChanges: true,
    }));
  };

  handleSave = () => {
    const { update, intl, user } = this.props;
    const { views } = this.state;

    return update({
      userId: user.id,
      accessibleViews: views,
    }).then(() => {
      this.setState({ hasChanges: false });
      successMessage(
        intl.formatMessage({
          id: 'views.admin.userManagement.viewPermissionsUpdated',
        }),
      );
    });
  };

  render() {
    const { intl } = this.props;
    const { views = {}, hasChanges } = this.state;
    const viewNames = Object.keys(views);
    const sorted = sortBy(viewNames, (name) => {
      return intl.formatMessage({
        id: `common.${name}`,
      });
    });

    return (
      <>
        {sorted.map((name) => (
          <ViewOption
            key={name}
            name={name}
            checked={views[name]}
            onChange={this.handleViewCheckboxChange}
          />
        ))}
        <td>
          <button
            type="button"
            className={hasChanges
              ? 'btn btn-primary float-right'
              : 'btn btn-white border float-right'
            }
            disabled={!hasChanges}
            onClick={hasChanges && this.handleSave}
            data-testid="save-user-permissions"
          >
            {intl.formatMessage({
              id: 'common.save',
            })}
          </button>
        </td>
      </>
    );
  }
}

UserViewPermissions.propTypes = propTypes;
UserViewPermissions.defaultProps = defaultProps;

export default compose(
  injectIntl,
  connect(null, mapDispatchToActions),
)(UserViewPermissions);
