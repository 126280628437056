import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as selectors from 'js/redux/selectors';
import * as actions from 'js/redux/plan/plan-actions';
import { injectIntl } from 'react-intl';

function FilterBtn({
  id, onChange, active, children,
}) {
  return (
    <button
      type="button"
      className={`btn btn-sm border${id === active ? ' btn-primary active' : ''}`}
      onClick={() => onChange(id)}
    >
      {children}
    </button>
  );
}

function TimeLimitFilterView({
  value = '', onFilter, onChange, intl,
}) {
  function setFilter(...args) {
    onFilter(...args);
    onChange && onChange(...args);
  }

  return (
    <div className="btn-group mb-2">
      <FilterBtn
        id=""
        onChange={setFilter}
        active={value}
      >
        {intl.formatMessage({ id: 'views.planning.playlists.plan' })}
      </FilterBtn>
      <FilterBtn
        id="current"
        onChange={setFilter}
        active={value}
      >
        {intl.formatMessage({ id: 'views.planning.media.current' })}
      </FilterBtn>
      <FilterBtn
        id="future"
        onChange={setFilter}
        active={value}
      >
        {intl.formatMessage({ id: 'views.planning.media.future' })}
      </FilterBtn>
      <FilterBtn
        id="past"
        onChange={setFilter}
        active={value}
      >
        {intl.formatMessage({ id: 'views.planning.media.past' })}
      </FilterBtn>
    </div>
  );
}

const mapState = (state) => ({
  value: selectors.getLimitFilter(state),
});

const mapDispatch = {
  onFilter: actions.setTimeLimitFilter,
};

export const TimeLimitFilter = compose( // eslint-disable-line
  connect(mapState, mapDispatch),
  injectIntl,
)(TimeLimitFilterView);
