/* eslint-disable react/jsx-no-literals */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import createClass from 'create-react-class';
import moment from 'moment';
import Store from 'js/stores/ajax-store';
import Immutable from 'immutable';
import { FormattedMessage, injectIntl } from 'react-intl';
import HelpIcon from 'js/react/components/HelpIcon';
import List from 'js/react/_utils/react-list';
import { DatePicker } from 'react-widgets';

const ActivityStore = Store('/monitor/activity');

const iconMap = {
  create: 'icon-plus',
  update: 'icon-edit',
  delete: 'icon-remove',
  login: 'icon-user',
  logout: 'icon-user',
};

function DataField(props) {
  const keyStyle = { color: '#888', fontStyle: 'italic' };

  const rows = props.data.map((d, i) => {
    return (
      <span key={i}>
        <span style={keyStyle}>{d.get('key')}:&nbsp;</span>
        {d.get('val')}
        {i !== props.data.size - 1 && ','} &nbsp;
      </span>
    );
  });

  return <span>{rows}</span>;
}

function ListRow(props) {
  const data = props.item.get('data');
  let dataField;

  if (data && data !== '') {
    dataField = <DataField data={data} />;
  }

  return (
    <tr>
      <td>
        <i className={iconMap[props.item.get('action')]} />
      </td>
      <td>{props.item.get('created')}</td>
      <td>{props.item.get('user')}</td>
      <td>{props.item.get('action')}</td>
      <td>{props.item.get('type')}</td>
      <td>{props.item.get('id')}</td>
      <td>{dataField}</td>
    </tr>
  );
}

const ListRows = List((props) => {
  return (
    <tbody data-testid="user-activity-table-body">
      {props.items.map((i) => <ListRow key={i.get('id')} item={i} />).toArray()}
    </tbody>
  );
});

const Activity = injectIntl(
  createClass({
    mixins: [List.sortableMixin],

    getDefaultProps() {
      return {
        initialSortBy: 'created',
        initialAsc: false,
      };
    },

    getInitialState() {
      return {
        data: Immutable.fromJS([]),
        selectedItem: null,
        queryStart: moment()
          .add(-3, 'd')
          .toDate(),
        queryEnd: moment().toDate(),
        loading: false,
        searchQuery: '',
        searchField: 'action',
      };
    },
    getQueryString() {
      const f = moment(this.state.queryStart).format('YYYY-MM-DD');
      const t = moment(this.state.queryEnd).format('YYYY-MM-DD');
      return `from=${f}&to=${t}`;
    },
    loadData() {
      const that = this;
      this.setState({ loading: true });

      ActivityStore.fetch(this.getQueryString()).then((data) => {
        that.setState({
          data,
          loading: false,
        });
      });
    },

    search(e) {
      const q = e.target.value;

      this.setState({
        searchQuery: q,
      });
    },

    onItemClicked(item) {
      this.setState({ selectedItem: item });
    },
    clearItem() {
      this.setState({ selectedItem: null });
    },
    UNSAFE_componentWillMount() {
      this.loadData();
    },
    renderForm() {
      const minMax = {
        min: moment()
          .add(-30, 'd')
          .toDate(),
        max: new Date(),
      };

      const handleChange = (key) => {
        return (val) => this.setState({ [key]: val });
      };

      return (
        <div className="form-row form-inline admin-form mb-3">
          <label className="mr-3">
            <span className="mr-2">
              <FormattedMessage id="common.from" />:
            </span>
            <DatePicker
              defaultValue={this.state.queryStart}
              onChange={handleChange('queryStart')}
              data-testid="from-date-container"
              {...minMax}
            />
          </label>
          <label className="mr-3">
            <span className="mr-2">
              <FormattedMessage id="common.to" />:
            </span>
            <DatePicker
              defaultValue={this.state.queryEnd}
              onChange={handleChange('queryEnd')}
              data-testid="to-date-container"
              {...minMax}
            />
          </label>
          <button
            type="button"
            className="btn btn-primary mr-auto"
            disabled={this.state.loading}
            onClick={this.loadData}
            data-testid="update-table-button"
          >
            <FormattedMessage id="views.admin.userActivity.updateTable" />
          </button>

          <label>
            <input
              data-testid="search-field"
              id="search_field"
              type="text"
              onChange={this.search}
              className="form-control search-query"
              placeholder={this.props.intl.formatMessage({
                id: 'common.search',
              })}
            />
          </label>
        </div>
      );
    },
    renderTable() {
      const {
        intl: { formatMessage },
      } = this.props;
      const loadingText = this.state.loading
        ? formatMessage({ id: 'common.loading' })
        : '';
      const s = this.sortableParams();
      const searchBy = (i) => {
        return (
          i.get('action').toLowerCase() +
          i.get('type').toLowerCase() +
          i.get('user').toLowerCase()
        );
      };

      const style = { minWidth: 60 };

      return (
        <div>
          <h2 className="mb-3">
            <FormattedMessage id="views.admin.userActivity.userActivity" />
            <HelpIcon
              bodyKey="help.admin.userActivity.body"
              headerKey="help.admin.userActivity.header"
            />
          </h2>
          {this.renderForm()}
          <table className="table">
            <thead>
              <tr>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <th />
                <th>
                  <List.Sortable
                    {...s}
                    sort="created"
                    label={formatMessage({ id: 'common.time' })}
                  />
                </th>
                <th style={style}>
                  <List.Sortable
                    {...s}
                    sort="user"
                    label={formatMessage({ id: 'common.user' })}
                  />
                </th>
                <th style={style}>
                  <List.Sortable
                    {...s}
                    sort="action"
                    label={formatMessage({ id: 'common.action' })}
                  />
                </th>
                <th style={style}>
                  <List.Sortable
                    {...s}
                    sort="type"
                    label={formatMessage({ id: 'common.item' })}
                  />
                </th>
                <th>
                  <List.Sortable
                    {...s}
                    sort="id"
                    label={formatMessage({ id: 'common.id' })}
                  />
                </th>
                <th>
                  <FormattedMessage id="common.data" />
                </th>
              </tr>
            </thead>
            {!this.state.loading && (
              <ListRows
                items={this.state.data}
                sortBy={this.state.sortBy}
                asc={this.state.asc}
                searchQuery={this.state.searchQuery}
                searchBy={searchBy}
              />
            )}
          </table>
        </div>
      );
    },
    render() {
      return this.renderTable();
    },
  }),
);

export default injectIntl(Activity);
