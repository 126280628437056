import React from "react";
import Button from "../Button";
import { DropdownContext } from "./Dropdown";

const style = { maxWidth: 200 };

function DropdownToggle({
  children,
  className = "btn btn-light border",
  Component,
  autoFocus,
  testid = "dropdown-toggle",
  ...other
}) {
  if (Component) {
    return (
      <DropdownContext.Consumer>
        {({ handleToggle, handleFocus, open, disabled }) => (
          <Component
            className={`${className}${disabled ? " disabled" : ""}${
              open ? " active" : ""
            }`}
            disabled={disabled}
            onClick={handleToggle}
            onFocus={handleFocus}
            autoFocus={autoFocus}
            data-testid={testid}
            {...other}
          >
            {children}
          </Component>
        )}
      </DropdownContext.Consumer>
    );
  }

  return (
    <DropdownContext.Consumer>
      {({ handleToggle, handleFocus, open, disabled }) => (
        <Button
          className={`dropdown-toggle overflow-hidden ${className}`}
          data-toggle="dropdown"
          aria-haspopup="true"
          data-inline-manual={other["data-inline-manual"]}
          disabled={disabled}
          active={open}
          onClick={handleToggle}
          onFocus={handleFocus}
          style={other.style || style}
          autoFocus={autoFocus}
          data-testid={testid}
        >
          {children}
        </Button>
      )}
    </DropdownContext.Consumer>
  );
}

export default DropdownToggle;
