import React from 'react';
import PropTypes from 'prop-types';

function Card({ label, children, actions }) {
  return (
    <div className="card m-3 bshadow p-3">
      <div className="row no-gutters mb-2">
        <h4 className="col pr-2 mr-auto">
          {label}
        </h4>
        <div>
          {actions}
        </div>
      </div>
      {children}
    </div>
  );
}

Card.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  actions: PropTypes.element,
};

export default Card;
