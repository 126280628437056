import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { set } from 'lodash';

import * as MsgActions from 'js/react/actions/message-actions';
import { createPlace } from 'src/entities/places';
import { useRequestWithPromise } from 'src/core/api';
import { placeNavigate } from './InterceptNav';
import { PlaceMapContext } from './PlacesStateManager';
import { AddPlaceForm } from './AddPlaceForm';

const DEFAULT_PLACE = { name: '', type: 'area' };

export const AddPlace = ({ visibleIds }) => {
  const { dispatch } = useRequestWithPromise();
  const { placeToCreate = DEFAULT_PLACE, setPlaceToCreate } =
    useContext(PlaceMapContext);
  const history = useHistory();

  useEffect(() => {
    setPlaceToCreate(DEFAULT_PLACE);

    return () => {
      // Clear on unmount
      setPlaceToCreate(null);
    };
  }, []);

  const handleSave = () => {
    const placeWithoutWkt1 = {
      ...placeToCreate,
      name: placeToCreate.name.trim(),
    };
    delete placeWithoutWkt1.wkt1;

    return dispatch(createPlace(placeWithoutWkt1)).then(
      ({ payload }) => {
        MsgActions.success(`Created place ${payload.name}`);
        setPlaceToCreate(null);
        placeNavigate([...(visibleIds || []), payload.id], payload.id, history);
      },
      () => {
        setPlaceToCreate(null);
      }
    );
  };

  const handleCancel = () => {
    placeNavigate(visibleIds, null, history);
  };

  const handleAttributeChange = (key) => (value) => {
    setPlaceToCreate(set({ ...placeToCreate }, key, value));
  };

  const handleTypeChange = (type) => {
    setPlaceToCreate({
      ...placeToCreate,
      type,
      wkt: '',
      wkt1: '',
    });
  };

  return (
    <div
      className="m-2 p-3 bdr880 rounded bg-white bshadow position-absolute z9999 r0 span3 w300"
      style={{
        top: 0,
      }}
    >
      <AddPlaceForm
        item={{ ...placeToCreate, identifier: null }}
        onNameChange={handleAttributeChange('name')}
        onTypeChange={handleTypeChange}
        onSave={handleSave}
        onCancel={handleCancel}
      />
    </div>
  );
};

AddPlace.propTypes = {};
