import { groupBy, reduce } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactChart from '../../components/ReactChart';

const COLORS = {
  'No warnings': '#10e323',
  'Has Warnings': '#b94a48',
};

function prepGraphData(statuses = []) {
  const grouped = groupBy(statuses, (status) => {
    if (status.warnings && status.warnings.length > 0) {
      return 'Has Warnings';
    }

    return 'No warnings';
  });

  const data = reduce(grouped, (acc, statuses, health) => {
    acc.labels.push(health);
    acc.data.push(statuses.length);
    acc.color.push(COLORS[health]);

    return acc;
  }, {
    data: [],
    labels: [],
    color: [],
  });

  return {
    labels: data.labels,
    datasets: [{
      data: data.data,
      backgroundColor: data.color,
    }],
  };
}

class HealthChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pieData: prepGraphData(props.statuses),
    };
  }

  render() {
    return (
      <div className="d-col align-self-center" style={{ maxHeight: 200 }}>
        <ReactChart
          type="pie"
          data={this.state.pieData}
          options={{
            legend: {
              position: 'right',
            },
            maintainAspectRatio: false,
          }}
          width="350px"
          height="300px"
        />
      </div>
    );
  }
}

HealthChart.propTypes = {
  statuses: PropTypes.array.isRequired,
};

export default HealthChart;
