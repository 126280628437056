import React, { useState } from 'react';
import { Combobox } from 'react-widgets';

export const GSCombobox = ({ value, onToggle, ...other }) => {
  const [text, setText] = useState('');
  const [open, setOpen] = useState(false);

  const handleToggle = (opn) => {
    setOpen(opn);
    setText(opn ? text : '');
    onToggle?.(opn);
  };

  const handleChange = (val) => {
    setText(typeof val === 'string'
      ? val || null
      : null);
  };

  const selected = value ? value.toString() : undefined;

  return (
    <Combobox
      dataKey="id"
      textField="name"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      value={open ? text : selected}
      onChange={handleChange}
      onToggle={handleToggle}
    />
  );
};

export default GSCombobox;
