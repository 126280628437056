import React, { useContext } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import cancan from 'js/system/cancan';
import { MinMaxTimeForm } from 'src/screens/Playlist/components/MinMaxTimePicker';
import { applyTimeLimits } from 'src/screens/Playlist/components/limit-picker';
import { getHighestOrder } from 'src/entities/plannedMedia';
import { PLANNING_TIMEPLAN } from 'constants/permissions';
import { useIntl } from 'react-intl';
import { bool, instanceOf } from 'prop-types';
import { ConditionSection } from './ConditionSection';
import { ConditionsContext } from './ConditionsContext';
import { TIME_LIMITS } from '../constants';

export const TimelimitCondition = ({
  isLocked, minDate, maxDate,
}) => {
  const { formatMessage } = useIntl();
  const {
    values,
    dispatchChange,
  } = useContext(ConditionsContext);

  const hasTimeplan = cancan.can('read', PLANNING_TIMEPLAN);
  const {
    playlist: playlistId,
    time_limit_lower: timeLimitLower,
    time_limit_upper: timeLimitUpper,
  } = values;
  const maxOrder = useSelector((state) => getHighestOrder(state, playlistId));
  const planStart = timeLimitLower && moment(timeLimitLower).toDate();
  const planEnd = timeLimitUpper && moment(timeLimitUpper).toDate();

  const planDateChanged = ([fromDate, toDate]) => {
    dispatchChange({
      type: TIME_LIMITS,
      payload: applyTimeLimits(values, fromDate, toDate, minDate, maxDate, maxOrder),
    });
  };

  return (
    <ConditionSection
      label={formatMessage({ id: 'views.planning.playlists.custom-start-end' })}
    >
      <MinMaxTimeForm
        dropUp={false}
        start={planStart}
        end={planEnd}
        onChange={planDateChanged}
        readOnly={isLocked}
        max={hasTimeplan ? maxDate?.toDate() : undefined}
        min={hasTimeplan ? minDate?.toDate() : undefined}
      />
    </ConditionSection>
  );
};

TimelimitCondition.propTypes = {
  isLocked: bool,
  minDate: instanceOf(moment),
  maxDate: instanceOf(moment),
};
