import {
  buildApiMetaFactory, buildDeleteType, buildGetType, buildPostType, buildPutType,
} from 'src/core/api';
import { COLLECTION } from './constants';

export const fetchClientGroupLayouts = (clientId) => {
  const buildApiMeta = buildApiMetaFactory({
    collection: COLLECTION,
    url: `/clients/groups/${clientId}/layouts`,
  });

  return {
    type: buildGetType(COLLECTION),
    meta: buildApiMeta(),
  };
};

export const updateLayout = (clientId, layout = {}) => {
  const buildApiMeta = buildApiMetaFactory({
    collection: COLLECTION,
    url: `/clients/groups/${clientId}/layouts`,
  });

  return {
    type: buildPutType(COLLECTION),
    meta: buildApiMeta({ entityId: layout.id }),
    payload: {
      ...layout,
      id: parseInt(layout.id, 10),
    },
  };
};

export const createLayout = (clientId, layout = {}) => {
  const buildApiMeta = buildApiMetaFactory({
    collection: COLLECTION,
    url: `/clients/groups/${clientId}/layouts`,
  });

  return {
    type: buildPostType(COLLECTION),
    meta: buildApiMeta(),
    payload: layout,
  };
};

export const removeLayout = (clientId, layoutId) => {
  const buildApiMeta = buildApiMetaFactory({
    collection: COLLECTION,
    url: `/clients/groups/${clientId}/layouts`,
  });

  return {
    type: buildDeleteType(COLLECTION),
    meta: buildApiMeta({ entityId: layoutId }),
  };
};
