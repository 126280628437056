import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { actionCreators } from 'js/redux/dashboard';
import globalSelectors from 'js/redux/dashboard/globalSelectors';
import { aggregationConfig } from 'js/react/report/report';
import {
  DisplayOptions,
  SummaryOptions,
  EditPeriod,
  EditAggregation,
  EditFilter,
} from './Editors';
import { WIDGET_NAMES } from '../index';

const WIDGET_TYPE = 'playbackreport';
const BAR = 'bar';
const CNTS = 'cnts';

class ReportEdit extends React.Component {
  constructor(props) {
    super(props);

    const widget = props.widget || {};
    const settings = widget.settings || {};

    this.state = {
      title: widget.title || '',
      graph: settings.graph || 'pie',
      sumBy: settings.sumBy || 'dur',
      days: settings.days || 1,
      aggregate: settings.aggregate || aggregationConfig[0],
      filters: settings.filters || {},
    };
  }

  handleTitle = (title) => this.setState({ title })

  handleGraph = (graph) => this.setState({ graph })

  handleSumBy = (sumBy) => this.setState({ sumBy })

  handleDays = (days) => this.setState({ days })

  handleAggregate = (aggregate) => this.setState({ aggregate })

  handleFilters = (filters) => this.setState({ filters })

  handleSave = () => {
    this.props.saveSettings(this.props.activeId, {
      title: this.state.title,
      settings: {
        graph: this.state.graph,
        sumBy: this.state.sumBy,
        days: this.state.days,
        aggregate: this.state.aggregate,
        filters: this.state.filters,
      },
    });
    this.props.onClose && this.props.onClose();
  }

  render() {
    return (
      <div>
        <h3>
          <FormattedMessage id="views.dashboard.editDashboardWidget" />
          {': '}
          {WIDGET_NAMES[WIDGET_TYPE]}
        </h3>
        <form>
          <DisplayOptions
            intl={this.props.intl}
            onTitleChange={this.handleTitle}
            onGraphChange={this.handleGraph}
            title={this.state.title}
            graph={this.state.graph}
          />
          <SummaryOptions
            intl={this.props.intl}
            onChange={this.handleSumBy}
            sumBy={this.state.graph === BAR
              ? CNTS
              : this.state.sumBy
            }
            disabled={this.state.graph === BAR}
          />
          <EditPeriod
            intl={this.props.intl}
            onChange={this.handleDays}
            value={this.state.days}
          />
          <EditAggregation
            intl={this.props.intl}
            onChange={this.handleAggregate}
            value={this.state.aggregate}
          />
          <EditFilter
            intl={this.props.intl}
            onChange={this.handleFilters}
            value={this.state.filters}
          />

          <fieldset>
            <button
              className="btn btn-primary"
              onClick={this.state.title && this.handleSave}
              disabled={!this.state.title}
            >
              <FormattedMessage id="common.save" />
            </button>
          </fieldset>
        </form>
      </div>
    );
  }
}

ReportEdit.propTypes = {
  activeId: PropTypes.string,
  widget: PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
  }),
  saveSettings: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  intl: PropTypes.object,
};

const mapState = (state, { activeId }) => {
  return {
    widget: globalSelectors.getDashboardWidget(state, { widgetId: activeId }),
  };
};

const mapActions = (dispatch) => ({
  saveSettings: (widgetId, { title, settings }) => {
    const newTitle = { widgetId, title };
    const newSettings = {
      widgetId,
      settings,
    };

    if (!widgetId) {
      dispatch(actionCreators.addDashboardWidget({
        widgetType: WIDGET_TYPE,
        ...newTitle,
        ...newSettings,
      }));
    } else {
      dispatch(actionCreators.updateDashboardWidgetTitle(newTitle));
      dispatch(actionCreators.updateDashboardWidgetSettings(newSettings));
    }
  },
});

export default connect(mapState, mapActions)(injectIntl(ReportEdit));
