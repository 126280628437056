import { v4 as getUuid } from 'uuid';

import * as actions from '../actions';

import {
  get,
  post,
  put,
  del,
} from '../helpers/actionCreatorFactories';


/*
  Playlists
*/

export const fetchPlaylists = () => {
  const url = '/playlists';
  const types = [
    actions.FETCH_PLAYLISTS,
    actions.FETCH_PLAYLISTS_SUCCESS,
    actions.FETCH_PLAYLISTS_FAIL,
  ];
  const meta = {
    verb: 'get',
    collection: 'playlists',
    uuid: getUuid(),
    url,
    reset: true,
  };

  return get({ url, types, meta });
};

export const createPlaylist = (bodyInput) => {
  const url = '/playlists';
  const types = [
    actions.CREATE_PLAYLIST,
    actions.CREATE_PLAYLIST_SUCCESS,
    actions.CREATE_PLAYLIST_FAIL,
  ];
  const meta = {
    verb: 'post',
    bodyInput,
    collection: 'playlists',
    uuid: getUuid(),
    url,
  };

  return post({
    url, types, meta, bodyInput,
  });
};

export const updatePlaylist = (bodyInput) => {
  const url = '/playlists';
  const types = [
    actions.UPDATE_PLAYLIST,
    actions.UPDATE_PLAYLIST_SUCCESS,
    actions.UPDATE_PLAYLIST_FAIL,
  ];
  const meta = {
    verb: 'put',
    bodyInput,
    id: bodyInput.id,
    collection: 'playlists',
    uuid: getUuid(),
    url,
  };

  return put({
    url, types, meta, bodyInput,
  });
};

export const deletePlaylist = (id) => {
  const url = '/playlists';
  const types = [
    actions.DELETE_PLAYLIST,
    actions.DELETE_PLAYLIST_SUCCESS,
    actions.DELETE_PLAYLIST_FAIL,
  ];
  const meta = {
    verb: 'del',
    id,
    collection: 'playlists',
    uuid: getUuid(),
    url,
  };

  return del({
    url, types, meta, id,
  });
};
