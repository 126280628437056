import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { intlShape } from 'utils/shapes';

import { error as errorMessage } from 'js/react/actions/message-actions';
import Dropdown, { DropdownToggle, DropdownContent } from 'components/Dropdown';
import { isValidPassword } from 'js/react/_utils/validation';

const getEmptyState = () => ({
  name: '',
  password: '',
  group: '',
});

const propTypes = {
  createUser: PropTypes.func,
  groups: PropTypes.array,
  intl: intlShape.isRequired,
};

class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = getEmptyState();
  }

  handleInput = (key, e) => {
    this.setState({
      [key]: e.target.value,
    });
  };

  handleSave = e => {
    e.preventDefault();
    if (!this.state.name) {
      errorMessage('Please fill in a name.');
    } else if (!isValidPassword(this.state.password)) {
      errorMessage(
        `Password requirements: Min. 4 chars long, 1 lowercase letter,
                1 uppercase letter, 1 digit.`
      );
    } else if (!this.state.group) {
      errorMessage('Please select a group.');
    } else {
      this.props.createUser(this.state);
      this.setState(getEmptyState());
    }
  };

  renderGroupOptions() {
    const groups = this.props.groups;
    if (!groups) return [];
    return [
      <option key={0} value={''}>
        {this.props.intl.formatMessage({
          id: 'views.admin.userManagement.chooseAGroup',
        })}
      </option>,
      ...groups.map(g => (
        <option key={g.id} value={g.id} data-testid={g.id}>
          {g.name}
        </option>
      )),
    ];
  }

  render() {
    const {
      intl: { formatMessage },
    } = this.props;

    return (
      <Dropdown>
        <DropdownToggle
          className="btn btn-primary"
        >
          {formatMessage({ id: 'views.admin.userManagement.addUser' })}
        </DropdownToggle>
        <DropdownContent>
          <div className="w300 p-2">
            <form className="mb-0">
              <fieldset id="addUserFieldset">
                <div className="form-group">
                  <label htmlFor="userManagement.name">
                    <FormattedMessage id="common.name" />
                  </label>
                  <input
                    id="userManagement.name"
                    type="text"
                    name="name"
                    data-testid="input-name"
                    className="form-control"
                    placeholder={formatMessage({ id: 'common.name' }) + '...'}
                    value={this.state.name}
                    onChange={this.handleInput.bind(null, 'name')}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="userManagement.password">
                    <FormattedMessage id="views.admin.userManagement.password" />
                  </label>
                  <input
                    id="userManagement.password"
                    type="password"
                    name="email"
                    data-testid="input-password"
                    className="form-control"
                    value={this.state.password}
                    onChange={this.handleInput.bind(null, 'password')}
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="userManagement.group"
                    className="d-block"
                  >
                    <FormattedMessage id="common.group" />
                  </label>
                  <select
                    id="userManagement.group"
                    name="group"
                    className="form-control"
                    value={this.state.group}
                    onChange={this.handleInput.bind(null, 'group')}
                  >
                    {this.renderGroupOptions()}
                  </select>
                </div>
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleSave}
                    data-testid="create-button"
                  >
                    <FormattedMessage id="common.create" />
                  </button>
                </div>
              </fieldset>
            </form>
          </div>
        </DropdownContent>
      </Dropdown>
    );
  }
}

AddUser.propTypes = propTypes;

export default compose(injectIntl)(AddUser);
