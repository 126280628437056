import { useEffect, useContext } from 'react';

import { SimulationContext } from '../../components/StateManager';
import { useCSRFRequest } from './useCSRFRequest';
import { BUILD_API_PATH, useMediaServer } from './useMediaServer';
import { usePollHistory } from './usePollHistory';
import { useSyncCurrentLocation } from './useSyncCurrentLocation';
import { useSyncDateTime } from './useSyncDateTime';
import { useSyncSelectedLine } from './useSyncSelectedLine';

// Setup media server and related communication
export const useMediaServerManager = () => {
  const request = useCSRFRequest();
  const [state, dispatch] = useContext(SimulationContext);
  const { error } = state;
  const mediaServerPath = useMediaServer(state, request, dispatch);

  const nextMedia = () => {
    request(`${mediaServerPath}/testing/control/next`, 'POST');
  };

  usePollHistory(state, request, mediaServerPath, dispatch);
  useSyncSelectedLine(state, request, mediaServerPath);
  useSyncCurrentLocation(state, request, mediaServerPath);
  useSyncDateTime(state, request, mediaServerPath);

  useEffect(() => () => {
    // Cleanup on unmount
    !error && request(BUILD_API_PATH, 'DELETE');
  }, []);

  return nextMedia;
};
