import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, batch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Route, Switch, Link } from 'react-router-dom';

import cancan from 'js/system/cancan';
import { CLIENTS_SETTINGS } from 'constants/permissions';
import LoadingSpinner from 'src/components/LoadingSpinner';

import { fetchClients, getClients } from 'src/entities/clients';
import { fetchClientGroups, getClientGroups } from 'src/entities/clientGroups';
import { fetchHealthVariables, getHealthVariablesSortedByOrder } from 'src/entities/healthVariables';

import { useAsyncDispatch } from 'src/helpers/useAsyncDispatch';
import { ClientsList } from './ClientsList';
import { SelectOptions } from './SelectOptions';
import { PageHeader } from './PageHeader';

const useLoadClients = () => {
  const [dispatch, pending] = useAsyncDispatch();

  const refresh = () => {
    batch(() => {
      dispatch(fetchClients());
      dispatch(fetchClientGroups());
      dispatch(fetchHealthVariables());
    });
  };

  // Do initial load
  useEffect(refresh, []);

  return [pending ? !!pending.length : 'initial load', refresh];
};

const useEnrichClientsWithClientGroup = (clients, clientGroups) => {
  return useMemo(() => {
    return Object.values(clients || {}).map((client) => ({
      ...client,
      fields: {
        ...client.fields,
        client_group: clientGroups?.[client.client_group_id]?.name,
      }
    }))
  }, [clients, clientGroups]);
};

export const Clients = ({ routes }) => {
  const clients = useSelector(getClients);
  const clientGroups = useSelector(getClientGroups);
  const healthVariables = useSelector(getHealthVariablesSortedByOrder);
  const [selected, setSelected] = useState([]);
  const [loading, refresh] = useLoadClients();
  const enrichedClients = useEnrichClientsWithClientGroup(clients, clientGroups);
  const numberOfClients = enrichedClients.length;
  const columns = useMemo(() => {
    return [
      ...healthVariables,
      {
        id: 'clientGroup',
        identifier: 'client_group',
        description: 'Client group',
      },
    ];
  }, [healthVariables]);

  return (
    <>
      <div className="container-fluid p-3 p-lg-5 h-100 position-absolute w-100 overflow-y-auto">
        <PageHeader />
        <div className="bg-white rounded">
          <div className="d-flex p-4">
            <h2>
              <FormattedMessage id="common.clients" />{' '}
              {!loading && <small>({numberOfClients} <FormattedMessage id="views.clients.connected" />)</small>}
            </h2>
            <div className="ml-auto d-flex align-self-center">
              {selected && !!selected.length && (
                <SelectOptions
                  selected={selected}
                  reset={() => setSelected([])}
                  clients={clients}
                  clientGroups={clientGroups}
                />
              )}
              <button
                type="button"
                className="btn btn-light border"
                onClick={loading ? undefined : refresh}
                disabled={loading}
              >
                {!loading && (<i className="icon-refresh mr-1" />)}
                {loading
                  ? <FormattedMessage id="common.loading" />
                  : <FormattedMessage id="common.refresh" />}
              </button>
              {cancan.can('read', CLIENTS_SETTINGS) && (
                <Link
                  to="/clients/health_variables"
                  data-testid="settings-button"
                  className="btn btn-light border text-nowrap align-self-center ml-2"
                >
                  <i className="icon-cog" /> <FormattedMessage id="common.settings" />
                </Link>
              )}
            </div>
          </div>
          {loading 
            ? <LoadingSpinner diameter="5rem" wrapperClassNames="p-5" />
            : (
              <ClientsList
                selected={selected}
                onSelect={setSelected}
                statuses={enrichedClients}
                columns={columns}
              />
            )
          }
        </div>
      </div>
      <Switch>
        {routes.map((route, i) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Route key={i} {...route} />
        ))}
      </Switch>
    </>
  );
};
