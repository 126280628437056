import moment from 'moment';

import cancan from 'js/system/cancan';
import { parseISODateTime } from 'tzdateutils';
import { getFromDate } from 'js/redux/selectors';
import * as MsgActions from 'js/react/actions/message-actions';
import { PLANNING_TIMEPLAN } from 'constants/permissions';

import { bulkPostPlannings, fetchPlanned } from './actionCreators';
import { BULK_PLAN_MEDIA, COPY_MEDIA_FROM_INTERVAL } from './actionTypes';
import {
  createPrepareMediaObjectForPlanning,
  enhanceMediaWithTimeTemplates,
} from './helpers';
import {
  getHighestOrder,
  getHighestGroupOrder,
  getPlanningsByPlaylist,
} from './selectors';

const addTimeLimitsMiddleware = ({ getState }) => (next) => (action) => {
  if (action.type === BULK_PLAN_MEDIA) {
    const { mediaObjects, playlistId, templates } = action.payload;

    if (!playlistId) {
      // eslint-disable-next-line no-console
      console.error('Cannot plan media. Playlist id is required!');
      return;
    }

    const fromDate = getFromDate(getState());
    const highestOrder = getHighestOrder(getState(), playlistId) + 1;

    const prepareMediaObjectForPlanning = createPrepareMediaObjectForPlanning({
      highestOrder,
      playlistId,
    });
    const preparedMediaObjects = mediaObjects.map(prepareMediaObjectForPlanning);

    const hasTimeplan = cancan.can('read', PLANNING_TIMEPLAN);
    const media = hasTimeplan ? enhanceMediaWithTimeTemplates({
      fromDate,
      preparedMediaObjects,
      templates,
    }) : preparedMediaObjects;

    // eslint-disable-next-line consistent-return
    return next({
      ...action,
      payload: { media },
    });
  }

  // eslint-disable-next-line consistent-return
  return next(action);
};

const bulkPlanMediaMiddleware = ({ dispatch }) => (next) => async (action) => {
  // Dispatch next action first to get the log sequence right.
  const value = next(action);

  if (action.type === BULK_PLAN_MEDIA) {
    const { payload: { media } } = action;

    dispatch(bulkPostPlannings(media));
  }

  // Older async actions may depend on the returned value.
  return value;
};

const copyMediaFromIntervalMiddleware = ({ dispatch, getState }) => (next) => async (action) => {
  // Dispatch next action first to get the log sequence right.
  const value = next(action);

  if (action.type === COPY_MEDIA_FROM_INTERVAL) {
    const {
      fromStart, fromEnd, moveFn, playlistId,
    } = action.meta;

    const moveDate = (date) => {
      return moveFn(parseISODateTime(date)).format('YYYY-MM-DDTHH:mm:ss');
    };
    const highestOrder = getHighestOrder(getState(), playlistId) + 1;
    const highestGroupOrder = getHighestGroupOrder(getState(), playlistId) + 1;

    await dispatch(fetchPlanned(fromStart, fromEnd, { reset: false }));

    const potentialPlanningsToCopy = getPlanningsByPlaylist(getState(), {
      id: playlistId,
      from: fromStart,
      to: fromEnd,
    });

    // filter out plannings that are not completely within the time limits
    // since getPlanningsByPlaylist does not check all four conditions
    // and we can't add the check in getPlanningsByPlaylist because then
    // it will not show media outside time templates
    const planningsToCopy = potentialPlanningsToCopy.toJS().filter(item => {
      const itemLowerTime = moment(item.time_limit_lower);
      const itemUpperTime = moment(item.time_limit_upper);

      return (
        itemLowerTime.isSameOrAfter(fromStart) && 
        itemLowerTime.isBefore(fromEnd) && 
        itemUpperTime.isAfter(fromStart) && 
        itemUpperTime.isSameOrBefore(fromEnd)
      );
    })

    const movedPlannings = planningsToCopy.map((item, i) => {
      const prepped = {
        ...item,
        time_limit_lower: moveDate(item.time_limit_lower),
        time_limit_upper: moveDate(item.time_limit_upper),
        order: highestOrder + i,
        block_group: item.block_group > 0 ? item.block_group + highestGroupOrder : 0,
      };
      delete prepped.id;
      return prepped;
    });

    return dispatch(bulkPostPlannings(movedPlannings))
      .then(() => {
        MsgActions.success('Copied last week.');
      });
  }

  // Older async actions may depend on the returned value.
  return value;
};

export const plannedMediaMiddlewares = [
  addTimeLimitsMiddleware,
  bulkPlanMediaMiddleware,
  copyMediaFromIntervalMiddleware,
];
