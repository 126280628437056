import React, {
  memo, useEffect, useMemo, useState,
} from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { map } from 'lodash';
import Dropdown, { DropdownToggle, DropdownContent } from 'components/Dropdown';
import { DatePicker } from 'react-widgets';

/* setup datepicker localization */
// Moment.locale('en');

const getId = (client) => client.get('cid');
const getVehicleId = (client) => client.getIn(['fields', 'VEHICLE_ID']) || client.getIn(['fields', 'gsVehicleId']) || '';
const hasNonDigitVehicleId = (vehicleId) => !Number(vehicleId); // e.g. returns true for 'N/A'

const sortByString = (idA, idB) => {
  if (idA && !idB) {
    return -1;
  }
  if (!idA && idB) {
    return 1;
  }
  if (!idA && !idB) {
    return 0;
  }
  if (idA < idB) {
    return -1;
  }
  if (idA > idB) {
    return 1;
  }
  return 0;
};
const sortByNumber = (numberA, numberB) => {
  if (numberA === numberB) return 0;
  return numberA < numberB ? -1 : 1;
};

const CountSelector = (props) => {
  const options = ['100', '200', '500'];

  const rows = map(options, (o, index) => {
    return (
      <label
        key={index}
        className="radio inline mx-2"
      >
        <input
          type="radio"
          value={o}
          checked={props.value === o}
          onClick={(e) => props.onChange(e.target.value)}
          className="mr-1"
        />
        {o}
      </label>
    );
  });

  return (
    <p className="form-inline">
      <span>
        <em>
          <FormattedMessage id="views.history.numberOfPlaybacks" />{': '}
        </em>
      </span>
      {rows}
    </p>
  );
};

const ClientsDropDown = memo(({
  clients, selected, onChange, onReset,
}) => {
  const intl = useIntl();
  const [query, setSearch] = useState('');
  const [debouncedQuery, setDebounced] = useState('');

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const listStyle = {
    maxHeight: 300,
    overflowY: 'scroll',
  };

  useEffect(() => {
    // Debounce search for performance
    const timer = setTimeout(() => {
      setDebounced(query);
    }, 250);
    return () => {
      clearTimeout(timer);
    };
  }, [query]);

  const idsArray = useMemo(() => {
    return clients.map((client) => {
      const id = getId(client);
      const vId = getVehicleId(client);
      return [id, vId];
    }).sort(([idA, vehicleIdA], [idB, vehicleIdB]) => {
      const hasNonDigitVehicleIdA = hasNonDigitVehicleId(vehicleIdA);
      const hasNonDigitVehicleIdB = hasNonDigitVehicleId(vehicleIdB);

      // valid vehicleId goes before invalid
      if (vehicleIdA && (!vehicleIdB || hasNonDigitVehicleIdB)) {
        return -1;
      }
      if ((!vehicleIdA || hasNonDigitVehicleIdA) && vehicleIdB) {
        return 1;
      }

      // if both vehicleIds are falsy, sort by id
      if (!vehicleIdA && !vehicleIdB) {
        return sortByString(idA, idB);
      }
      // since both vehicleIds are non-digit strings, sort by them
      if (hasNonDigitVehicleIdA && hasNonDigitVehicleIdB) {
        return sortByString(vehicleIdA, vehicleIdB);
      }

      // sort by numerical vehicleId
      const numberVehicleIdA = Number(vehicleIdA);
      const numberVehicleIdB = Number(vehicleIdB);

      return sortByNumber(numberVehicleIdA, numberVehicleIdB);
    }).toArray();
  }, [clients]);

  const items = useMemo(() => {
    if (!debouncedQuery) { return idsArray; }
    return idsArray.filter(([id, vId]) => {
      return id?.includes(debouncedQuery) || vId?.includes(debouncedQuery);
    });
  }, [idsArray, debouncedQuery]);

  return (
    <Dropdown>
      <DropdownToggle>
        {intl.formatMessage({ id: 'common.client' })}
      </DropdownToggle>
      <DropdownContent
        data-testid="history-filters"
      >
        <div className="p-2" style={listStyle}>
          <div className={`py-2 mb-1 ${selected?.size ? 'alert-info px-2' : ''}`}>
            {!!selected?.size && (
              <>
                <FormattedMessage id="views.history.selectedClients" />{':'} {selected.size}
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={() => onReset()}
                >
                  <span aria-hidden="true">
                    {'\u00D7'}
                  </span>
                </button>
              </>
            )}
            {!selected?.size && (
              <FormattedMessage id="views.history.selectClient" />
            )}
          </div>
          {clients.size > 1 && (
            <input
              data-testid="search-field"
              id="search_field"
              type="text"
              value={query}
              onChange={handleSearch}
              className="form-control search-query"
              placeholder={intl.formatMessage({
                id: 'common.search',
              })}
            />
          )}
          <div className="media-list">
            {items.map(([id, vId]) => {
              return (
                <button
                  key={id}
                  type="button"
                  className={
                    `btn d-block p-2 border pointer mt-1 text-nowrap ${
                      selected.has(id) ? 'btn-info' : 'btn-light'}`
                  }
                  onClick={onChange(id)}
                >
                  <span className="bus">
                    {`${vId && `${vId} - `}${id}`}
                  </span>
                </button>
              );
            })}
          </div>
        </div>
      </DropdownContent>
    </Dropdown>
  );
});

class HistoryFilter extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (
      this.props.search !== nextProps.search ||
      !this.props.selectedClients.equals(nextProps.selectedClients) ||
      !this.props.clients.equals(nextProps.clients) ||
      this.props.displayCount !== nextProps.displayCount
    );
  }

  setRef = (el) => {
    this.searchref = el;
  }

  /**
     * Method callback when user changes client filter
     * @param {integer} clientId Selected client id as filter
     */
  handleClientFilterChange = (clientId) => {
    return (event) => {
      let c = this.props.selectedClients;
      if (c.has(clientId)) {
        c = c.remove(clientId);
      } else {
        c = c.add(clientId);
      }
      this.props.onFilterChange(this.props.date, c.toJS(), this.props.search);
      event.preventDefault();
    };
  }

  handleResetSelected = () => {
    this.props.onFilterChange(this.props.date, [], this.props.search);
  }

  /**
     * Called when searchfield value is changed
     * @param {object} event Javascript onChange event
     */
  handleHistorySearch = (event) => {
    // Callback onChange in the search-field
    const str = event.target.value;
    this.props.onFilterChange(
      this.props.date,
      this.props.selectedClients.toJS(),
      str,
    );
    event.preventDefault();
  }

  handleSearchKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.props.onFilterChange(
        this.props.date,
        this.props.selectedClients.toJS(),
        e.target.value,
      );
    } else if (e.keyCode === 27) {
      this.searchref.blur();
    }
  }

  handleDatetimeChange = (date) => {
    this.props.onFilterChange(
      date,
      this.props.selectedClients.toJS(),
      this.props.search,
    );
  }

  handleDatePickerKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  }

  render() {
    return (
      <div>
        <div className="row no-gutters mb-2">
          <div
            className="mr-2"
            data-testid="ClientsDropDown"
          >
            <ClientsDropDown
              onChange={this.handleClientFilterChange}
              onReset={this.handleResetSelected}
              clients={this.props.clients}
              selected={this.props.selectedClients}
            />
          </div>
          <div className="col mr-2">
            <DatePicker
              includeTime
              format="YYYY-MM-DD HH:mm"
              valueFormat="YYYY-MM-DD HH:mm"
              defaultValue={this.props.date}
              culture="en-GB"
              max={new Date()}
              onKeyDown={this.handleDatePickerKeyDown}
              onChange={this.handleDatetimeChange}
            />
          </div>
          <div className="search_field ">
            <input
              ref={this.setRef}
              type="text"
              className="form-control search-query h-100"
              placeholder={this.props.intl.formatMessage({
                id: 'common.search',
              })}
              defaultValue={this.props.search}
              onBlur={this.handleHistorySearch}
              onKeyDown={this.handleSearchKeyDown}
            />
          </div>
        </div>
        <CountSelector
          onChange={this.props.onDisplayCountChange}
          value={this.props.displayCount}
        />
      </div>
    );
  }
}

export default injectIntl(HistoryFilter);
