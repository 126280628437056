import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import storeListener from 'js/stores/store-listener';

import { connect } from 'react-redux';
import {
  createClientGroup,
  deleteClientGroup,
  fetchClientGroups,
  getClientGroupsInArray,
  updateClientGroup,
} from 'src/entities/clientGroups';

const propTypes = {
  id: PropTypes.string.isRequired,
};

class ClientGroupsSidebar extends React.PureComponent {
  state = {
    clientGroups: [],
    loading: true,
    editing: [],
    editValues: {},
  };

  componentDidMount() {
    this.loadGroupData();
  }

  componentWillUnmount() {
    storeListener.stopListening.call(this);
  }

  loadGroupData = () => {
    this.props.fetchClientGroups();
  };

  toggleEditing = (id) => {
    if (this.state.editing.includes(id)) {
      this.setState({
        editing: this.state.editing.filter((eid) => eid !== id),
      });
    } else {
      this.setState({
        editing: [...this.state.editing, id],
        editValues: {
          ...this.state.editValues,
          [id]: this.props.clientGroups.find((group) => group.id === id).name,
        },
      });
    }
  };

  onTitleChange = (id, text) => {
    this.setState({
      editValues: {
        ...this.state.editValues,
        [id]: text,
      },
    });
  };

  onSave = (id) => {
    const group = this.props.clientGroups.find((group) => group.id === id);
    const name = this.state.editValues[id];

    this.props.updateClientGroup({
      ...group,
      name,
    });

    this.toggleEditing(id);
  };

  onDelete = (id) => {
    this.props.deleteClientGroup(id);
  };

  onCreate = () => {
    this.props.createClientGroup({
      name: 'New group',
    });
  };

  render() {
    return (
      <div className="holder bg-white p-3">
        <div className="module_head mb-3 clearfix">
          <div>
            <a
              href="#/playlists"
              aria-label="Close"
              className="close"
            >
              <span aria-hidden="true">
                {'\u00D7'}
              </span>
            </a>
          </div>
          <div className="tabs" />
          <h3>
            <span>
              <FormattedMessage id="views.playlists.editclientGroups" />
            </span>
          </h3>

          <button
            type="button"
            onClick={() => this.onCreate()}
            className="float-right btn btn-primary mb-1 d-inline-block"
          >
            <i className="icon-white icon-list pr-2" />
            <span>
              <FormattedMessage id="common.add" />
            </span>
          </button>
        </div>

        <div className="border-top">
          {this.props.clientGroups.map((group) => {
            const className = `btn btn-danger mr-2 ${group.playlist_scheme_id && 'disabled'}`;
            return (
              <div
                key={group.id}
                className="d-flex py-2 align-items-center border-bottom"
              >
                <div className="mr-auto">
                  {this.state.editing.includes(group.id) ? (
                    <input
                      className="form-control"
                      value={this.state.editValues[group.id]}
                      onChange={(e) => this.onTitleChange(group.id, e.target.value)}
                    />
                  ) : (
                    <React.Fragment>
                      {group.name}
                      {group.playlist_scheme_id && (
                        <i>
                          <small>
                            {' - '}
                            <FormattedMessage id="common.inUse" />
                          </small>
                        </i>
                      )}
                    </React.Fragment>
                  )}
                </div>

                <div>
                  {this.state.editing.includes(group.id) ? (
                    <div>
                      <button
                        type="button"
                        onClick={() => this.toggleEditing(group.id)}
                        className="btn btn-light border mr-2"
                      >
                        <i className="icon-remove-sign icon-black" />
                      </button>
                      <button
                        type="button"
                        onClick={() => this.onSave(group.id)}
                        className="btn btn-primary"
                      >
                        <i className="icon-ok-sign icon-white" />
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        type="button"
                        onClick={() => !group.playlist_scheme_id && this.onDelete(group.id)
                        }
                        className={className}
                      >
                        <i className="icon-trash icon-white" />
                      </button>
                      <button
                        type="button"
                        onClick={() => this.toggleEditing(group.id)}
                        className="btn btn-primary"
                      >
                        <i className="icon-edit icon-white" />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}


function getSelected(clientGroups, schemeId) {
  return clientGroups && clientGroups
    .filter((g) => g.playlist_scheme_id === schemeId)
    .map((g) => g.id);
}

function mapState(state, { schemeId }) {
  const clientGroups = getClientGroupsInArray(state);
  return {
    clientGroups: clientGroups && clientGroups.sort((a, b) => (a.id > b.id ? 1 : -1)),
  };
}

const mapDispatch = {
  fetchClientGroups,
  updateClientGroup,
  createClientGroup,
  deleteClientGroup,
};

export default connect(mapState, mapDispatch)(ClientGroupsSidebar);
