import React, { useEffect, useState } from 'react';
import {
  number,
  shape,
  string,
  func,
  bool,
  array,
  object,
  oneOfType,
} from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { isEmpty, sortBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { fetchMediaObjects, getApprovedAudio } from '../../../entities/media';

import AudioPicker from '../../../../js/react/components/AudioPicker';
import SpeechInput from '../../../components/SpeechInput';
import { sortGeometries } from '../helpers/buildMapData';

const DeleteButton = ({ onDelete }) => {
  const [confirm, setConfirm] = useState(0);
  const handleDeleteClick = () => {
    if (confirm) {
      setConfirm(false);
      onDelete();
    } else {
      setConfirm(true);
    }
  };

  useEffect(() => {
    setConfirm(false);
  }, [onDelete]);

  return (
    <button
      type="button"
      className={`btn mr-auto btn-sm btn-${
        (!confirm && 'danger') || 'warning'
      }`}
      onClick={handleDeleteClick}
    >
      {!confirm ? (
        <i className="icon-trash icon-white" />
      ) : (
        <FormattedMessage id="common.confirmDelete" />
      )}
    </button>
  );
};

const NameField = ({ item, onChange }) => {
  const handleNameChange = (e) => {
    onChange(e.target.value, 'name');
  };

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className="border-bottom mb-2 pb-2 d-block">
      <FormattedMessage id="common.name" />
      {': '}
      <input
        type="text"
        className="form-control"
        value={item.name}
        onChange={handleNameChange}
      />
    </label>
  );
};

const ZoneEdit = ({ item, geometryIndex, setGeometryIndex }) => {
  const geometries = Object.values(item.geometry || {});
  const geometryIsListWithMoreThanOneItem = geometries?.length > 1;

  return (
    geometryIsListWithMoreThanOneItem && (
      <div className="border-bottom mb-2 d-block">
        <label>
          <FormattedMessage id="views.places.editPlace.Zones" />
          {': '}
          {geometries && (
            <select
              value={geometryIndex}
              onChange={(event) =>
                setGeometryIndex(parseInt(event.target.value, 10))
              }
              className="form-control text-capitalize"
            >
              {sortBy(geometries, sortGeometries).map(({ category }, index) => {
                if (category.toLowerCase() === 'stop') {
                  // don't let user edit original stop point
                  return null;
                }
                return (
                  <option key={index} value={index}>
                    {category}
                  </option>
                );
              })}
            </select>
          )}
        </label>
      </div>
    )
  );
};

const IDEdit = ({ item, onChange, allowExtId }) => {
  const handleExtIDChange = (e) => {
    const val = e.target.value ? e.target.value : null;
    onChange(val, 'identifier');
  };

  if (!allowExtId) {
    return (
      <div className="border-bottom mb-2 pb-2">
        {`ID: ${item.identifier}`}
      </div>
    );
  }

  return (
    Object.hasOwnProperty.call(item, 'identifier') && (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label className="border-bottom mb-2 pb-2 d-block">
        {'ID: '}
        <input
          type="text"
          className="form-control"
          value={item.identifier || ''}
          onChange={handleExtIDChange}
        />
      </label>
    )
  );
};

const AudioPickerField = ({ item, onChange }) => {
  const selected = item.connectedMedia?.audio;
  const handleAudio = (audio) => {
    onChange(
      {
        ...item.connectedMedia,
        audio,
      },
      'connectedMedia'
    );
  };

  return (
    <div className="border-bottom mb-2 pb-2 d-block">
      <FormattedMessage id="views.places.editPlace.audio" />
      {': '}
      <AudioPicker selected={selected} onChange={handleAudio} width="100%" />
    </div>
  );
};

const Speech = ({ item, onChange }) => {
  const handleSpeech = (text) => {
    onChange(text, 'speech');
  };

  const dispatch = useDispatch();
  const audio = useSelector(getApprovedAudio);

  useEffect(() => {
    if (!audio?.length) {
      // Load audio (well, all media) if needed
      dispatch(fetchMediaObjects());
    }
  }, [item.speech]);

  return (
    Object.hasOwnProperty.call(item, 'speech') && (
      <div className="border-bottom mb-2 pb-2 d-block">
        <FormattedMessage id="views.places.editPlace.speech" />
        {': '}
        <SpeechInput width={185} value={item.speech} onChange={handleSpeech} />
      </div>
    )
  );
};

export const EditPlaceForm = ({
  item,
  geometryIndex,
  setGeometryIndex,
  allowExtId,
  onChange,
  onDelete,
  onCancel,
  onSave,
  editable,
  changed,
}) => {
  if (isEmpty(item)) {
    return null;
  }

  const { id, name, type, connectedMedia: connectedMedia } = item;

  return (
    <div>
      <h4>{name?.trim()}</h4>
      <p className="border-bottom mb-2 pb-2 d-block">
        <FormattedMessage id="common.type" />
        {': '}
        <b>{type}</b>
        {' ('}
        <span>
          <FormattedMessage id="common.id" />
          {': '}
          {id}
        </span>
        {')'}
      </p>

      {!editable && (
        <em>
          <FormattedMessage id="views.places.thisPlaceCannotBeEdited" />
        </em>
      )}

      {editable && (
        <div>
          <NameField item={item} onChange={onChange} />
          <ZoneEdit
            item={item}
            geometryIndex={geometryIndex}
            setGeometryIndex={setGeometryIndex}
          />
          <IDEdit item={item} onChange={onChange} allowExtId={allowExtId} />
          {connectedMedia && (
            <AudioPickerField item={item} onChange={onChange} />
          )}
          <Speech item={item} onChange={onChange} />

          <div className="d-flex">
            <DeleteButton onDelete={onDelete} />
            <div>
              <button
                type="button"
                className="btn btn-light btn-sm border"
                onClick={onCancel}
              >
                {changed ? (
                  <FormattedMessage id="common.cancel" />
                ) : (
                  <FormattedMessage id="common.close" />
                )}
              </button>
              <button
                type="button"
                className={`btn btn-sm btn-primary ml-1${
                  (!changed && ' disabled') || ''
                }`}
                onClick={onSave}
                data-testid="place-save-btn"
              >
                <FormattedMessage id="common.save" />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

EditPlaceForm.propTypes = {
  geometryIndex: number.isRequired,
  item: shape({
    category: string,
    connectedMedia: shape({
      audio: string,
    }),
    identifier: string,
    geometry: oneOfType([array, object]),
    id: string.isRequired,
    name: string.isRequired,
    speech: string,
  }).isRequired,
  editable: bool,
  changed: bool,
  allowExtId: bool,
  onCancel: func.isRequired,
  onChange: func.isRequired,
  onDelete: func.isRequired,
  onSave: func.isRequired,
  setGeometryIndex: func.isRequired,
};
