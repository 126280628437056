import * as actions from './actions';

const initialState = {
  progress: -1,
};

// The arguments of the update* methods should probably be changed to be part
// of an object, but it's a copy-paste thing that works for now.
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
  case actions.UPLOAD_STARTED :
    if (state.progress === 0) {
      return state;
    }
    return {
      ...state,
      progress: 0,
    };
  case actions.UPLOAD_PROGRESS :
    if (action.payload === state.progress) {
      return state;
    }
    return {
      ...state,
      progress: action.payload,
    };
  case actions.UPLOAD_ENDED :
  case actions.UPLOAD_FAILED :
    if (state.progress === -1) {
      return state;
    }
    return {
      ...state,
      progress: -1,
    };
  default :
    return state;
  }
}
