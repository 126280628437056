

export function getColors(graphData) {
  let idx = 0;
  return graphData && graphData.reduce((acc, d) => {
    const hue = idx / graphData.size * 360;
    const color = `hsl(${hue}, 45%, 60%)`;
    acc[d.get('id')] = color;
    idx += 1;
    return acc;
  }, {});
}

export default function createPieData(media, {
  type = 'dur',
  colors,
  limit,
}) {
  const colr = colors || getColors(media);
  const count = limit || media.size;
  const prepped = media.map(
    (d) => {
      const value = type === 'dur'
        ? d.get('dur')
        : d.get('cnts').reduce((acc, c) => acc += c, 0);

      return d.set('value', value);
    },
  ).sortBy((d) => -d.get('value'));

  const playlistPieData = prepped.reduce((acc, d, index) => {
    const value = d.get('value');

    if (index == count) {
      acc.labels.push('other');
      acc.data.push(value);
      acc.color.push('#ddd');
      return acc;
    }

    if (index > count) {
      const last = acc.data.pop();
      acc.data.push(last + value);
      return acc;
    }

    const id = d.get('id');
    // const dur = d.get('dur') / tot;
    // const hue = idx / count * 360;
    // const color = `hsl(${hue}, 45%, 60%)`;
    const color = colr[id] || '#ddd';
    // idx++;

    acc.labels.push(id);
    acc.data.push(value);
    acc.color.push(color);
    acc.colorById[id] = color;

    return acc;
  }, {
    data: [],
    labels: [],
    color: [],
    colorById: {},
  });

  return {
    labels: playlistPieData.labels,
    datasets: [{
      data: playlistPieData.data,
      backgroundColor: playlistPieData.color,
    }],
    colorById: playlistPieData.colorById,
  };
}
