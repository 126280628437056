import { createSelector } from 'reselect';
import Immutable from 'immutable';
import moment from 'moment';
import cancan from 'js/system/cancan';
import { PLANNING_TIMEPLAN } from 'constants/permissions';
import * as types from './constants';

function defaultState() {
  return Immutable.Map({
    fromDate: moment(new Date()).startOf('w'),
    toDate: moment(new Date()).endOf('w'),
    clipboard: Immutable.List(),
    plannedMediaLimitFilter: '',

    planLocked: cancan.can('read', PLANNING_TIMEPLAN) || null,
  });
}

function updateRoute(date) {
  // Update route
  const _from = date.format('YYYY-MM-DD');
  const baseRoute = window.location.hash.split(';')[0];
  const link = `;${_from}`;
  // TODO: This is wrong, we shouldn't set the location ourself, but since
  //  this is a reducer we don't have access to useHistory.
  window.location = baseRoute + link;

  return link;
}

function lockedInterval(date) {
  if (cancan.can('read', PLANNING_TIMEPLAN)) {
    const now = moment().endOf('w');
    return now.isAfter(date) || now.isSame(date);
  }
  return false;
}

function moveDates(state, incr, period = 'w') {
  const newDate = moment(state.get('fromDate')).add(incr, period);
  const toDate = moment(state.get('toDate')).add(incr, period);

  updateRoute(newDate);

  return state
    .set('fromDate', newDate)
    .set('toDate', toDate)
    .set('planLocked', lockedInterval(toDate));
}

function setDate(state, payload = moment()) {
  const date = moment.isDate(payload) ? moment(payload) : payload;

  if (moment.isMoment(date)) {
    updateRoute(date);
    const toDate = moment(date).endOf('w');
    const fromDate = date.startOf('w');

    if (state.get('planLocked') !== null && fromDate.isSame(state.get('fromDate'))) {
      return state;
    }

    return state
      .set('fromDate', fromDate)
      .set('toDate', toDate)
      .set('planLocked', lockedInterval(toDate));
  }

  return state;
}

function PlaceReducer(state = defaultState(), action) {
  switch (action.type) {
  case types.UNLOCK:
    return state.set('planLocked', false);

    // Time interval:
  case types.SETDATE: return setDate(state, action.payload);
  case types.NEXTINTERVAL: return moveDates(state, 1);
  case types.PREVINTERVAL: return moveDates(state, -1);

  case types.COPY: return state.set('clipboard', action.payload);

  case types.TIME_LIMIT_FILTER:
    return state.set('plannedMediaLimitFilter', action.payload || '');

  default:
    return state;
  }
}

export default PlaceReducer;

export const getPasteData = createSelector(
  (state) => state,
  (state) => state.get('clipboard'),
);

export const getLimitFilter = (state) => {
  return state.get('plannedMediaLimitFilter');
};
