import {
  buildApiMetaFactory,
  buildDeleteType,
  createCrudActionCreators,
} from '../../core/api';
import {
  URL,
  COLLECTION,
  ANNOUNCEMENTS_URL,
  ANNOUNCEMENTS_COLL,
  ANNOUNCEMENTS_TAGS_URL,
  ANNOUNCEMENTS_TAGS_COLLECTION,
} from './constants';

export const {
  create: createMediaObject,
  readAll: fetchMediaObjects,
  readById: fetchMediaObject,
  update: updateMediaObject,
  destroy: deleteMediaObject,
} = createCrudActionCreators({ collection: COLLECTION, url: URL });

// Announcement update/create returns media objects
export const { create: createAnnouncement, update: updateAnnouncement } =
  createCrudActionCreators({ collection: COLLECTION, url: ANNOUNCEMENTS_URL });

export const { readById: fetchAnnouncement } = createCrudActionCreators({
  collection: ANNOUNCEMENTS_COLL,
  url: ANNOUNCEMENTS_URL,
});

export const { readAll: fetchAnnouncementTags } = createCrudActionCreators({
  collection: ANNOUNCEMENTS_TAGS_COLLECTION,
  url: ANNOUNCEMENTS_TAGS_URL,
});

export const destroyPermanently = (entityId) => {
  return {
    type: buildDeleteType(COLLECTION),
    meta: buildApiMetaFactory({
      collection: COLLECTION,
      url: `/media/${entityId}/delete`,
    })(),
  };
};
