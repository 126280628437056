import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actionCreators as i18nActionCreators } from 'js/redux/i18n';
import * as selectors from 'js/redux/selectors';

const mapStateToProps = (state) => {
  return {
    availableLanguages: selectors.getAvailableLanguages(state),
    language: selectors.getLanguage(state),
    languageNames: selectors.getLanguageNames(state),
  };
};
const mapDispatchToProps = { setLanguage: i18nActionCreators.setLanguage };

const propTypes = {
  availableLanguages: PropTypes.array.isRequired,
  language: PropTypes.string.isRequired,
  languageNames: PropTypes.object.isRequired,
  setLanguage: PropTypes.func.isRequired,
};

class LanguagePicker extends Component {
  handleSetLanguage = (event) => {
    event.preventDefault();
    this.props.setLanguage(event.target.value);
  };

  render() {
    return (
      <select
        name="language"
        type="select"
        className="form-control"
        value={this.props.language}
        onChange={this.handleSetLanguage}
      >
        {this.props.availableLanguages.map((language) => (
          <option key={language} value={language}>
            {this.props.languageNames[language] || language}
          </option>
        ))}
      </select>
    );
  }
}

LanguagePicker.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(LanguagePicker);
