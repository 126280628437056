import React, { useEffect, useState } from 'react';

export const EQToggle = ({ value = 'eq', onChange }) => {
  const handleChange = (e) => {
    onChange(e.target.checked
      ? 'neq'
      : 'eq');
  }

  return (
    <div className="mt-1 form-inline">
      <label>
        <input
          type="checkbox"
          onChange={handleChange}
          checked={value !== 'eq'}
          className="mr-1"
        />
        Not equal to
      </label>
    </div>
  );
}

export const useOperator = (field, onChange) => {
  const [operator, setOperator] = useState(field?.op);

  useEffect(() => {
    onChange(field?.value, operator);
  }, [operator])

  useEffect(() => {
    setOperator(field?.op);
  }, [field?.op])

  return [operator, setOperator];
}
