/* jshint white:true, browser:true, unused:true, undef:true, newcap:true, latedef:true, indent:4, forin:true, camelcase:true, esnext:true */
/* global module, require */

/*
    List utilities.

    The list wrapper wraps a component in a higher order component.
    The wrapper takes care of filtering & sorting and
    passes on "items" to the child component props.

    /////////////////////////////////////
    Basic use:

    var List = require('utils/react/react-list');

    var ListRows = List(function (props) {
        return (
            <ul>
                { props.items.map(
                    (i) => <li>{ i.get('name') }</li>
                ).toArray() }
            </ul>
        );
    });

    <ListRows
        items       = { Immutable.List([ unfiltered items ]) }
        sortBy      = { 'string-attribute' || function(item) {} }
        asc         = { true }
        searchQuery = { 'string' }

        searchBy : (i) => {
            // Should return a searchable string in lower case.
            return (i.get('name') || i.get('title') || '').toLowerCase();
        }
    />


    /////////////////////////////////////
    Include checkbox functionality:

    var ListRows = List(function (props) {
        return (
            <ul>
                { props.items.map(
                    (i) => <li>{ i.get('name') }</li>
                ).toArray() }
            </ul>
        );
    }, {
        _checkbox : 'checkbox-namespace'
    });


    /////////////////////////////////////
    Sortable utils

    var ListComponent = createClass({
        mixins : [
            List.sortableMixin
        ],

        render() {
            return (
                <div>
                    <List.Sortable
                        {...this.sortableParams()}
                        sort ='name'
                        label ={ t.name }
                    />

                    <ListRows
                        items       = { this.props.items }
                        sortBy      = { this.state.sortBy }
                        asc         = { this.state.asc }
                        searchQuery = { this.props.searchQuery }
                    />
                </div>
            );
        }
    });
*/

import {
  extend,
  isArray,
  isString,
  isFunction,
  isUndefined,
} from 'lodash';
import React from 'react';
import createClass from 'create-react-class';
import Immutable from 'immutable';
import PureRenderMixin from 'js/react/_mixins/pure-render';
import CheckBox from 'js/react/_utils/checkbox';


const listWrapper = function (Component, options) {
  options = extend({
    searchBy: (i) => {
      // Should return a searchable string in lower case.
      return (i.get('name') || i.get('title') || '').toLowerCase();
    },

    sortBy: (i) => i.get('name'),

    searchQuery: '',

    // _checkbox : 'namespace' // Automatically include checkbox wrapper
  }, options);

  const W = createClass({
    getDefaultProps() {
      return options;
    },

    getInitialState() {
      return this.getVisible(this.props);
    },

    // Life cycle
    shouldComponentUpdate(nextProps, nextState) {
      return !nextProps.loading && (nextProps.cap !== this.props.cap ||
                    PureRenderMixin.shouldComponentUpdate.call(this, nextProps, nextState));
    },

    UNSAFE_componentWillReceiveProps(newProps) {
      this.setState(this.getVisible(newProps));
    },

    // prep search query
    searchQuery(q) {
      return q.trim().toLowerCase();
    },

    // Callback function from parent to returned search/filtered items
    getVisibleItems() {
      return this.state.visibleItems;
    },

    // Filters
    getVisible(props) {
      const items = props.items || Immutable.List();
      const searchQuery = this.searchQuery(props.searchQuery);
      const searched = this.applySearch(items, searchQuery);
      // filtered = this.applyFilters(items, props.filterBy),
      const filtered = searched;
      let visibleItems = this.sortItems(filtered, props.sortBy, props.asc);

      const preCapSize = visibleItems.size;
      if (props.cap !== null) {
        visibleItems = visibleItems.slice(0, props.cap);
      }
      if (options._checkbox && !isArray(options._checkbox)) {
        CheckBox.allItems(options._checkbox, visibleItems);
      }

      return {
        searched, filtered, visibleItems, preCapSize,
      };
    },

    applySearch(items, query) {
      if (this.state &&
                query === this.state.searchQuery &&
                items === this.props.items) {
        return this.state.searched;
      }

      return items.filter((i) => {
        let val = this.props.searchBy(i);
        val = isString(val) ? val.toLowerCase() : (val.toString ? val.toString() : '');
        return query.length === 0 || val.indexOf(query) !== -1;
      });
    },

    // applyFilters(items, filterBy) {
    //  // Check if filters have changed & return old state if not.

    //  return items.filter((i) => {
    //      return filterBy.reduce()
    //  });
    // },

    sortItems(items, sortBy, asc) {
      const fn = isFunction(sortBy) ? sortBy : this.defaultSort(sortBy);
      const sorted = items.sortBy(fn, (aVal, bVal) => {
        if (aVal === undefined || bVal === undefined) {
          return;
        }
        return aVal.localeCompare(bVal, navigator.languages[0] || navigator.language, {
          numeric: true,
          ignorePunctuation: true,
        });
      });
      return asc ? sorted : sorted.reverse();
    },

    defaultSort(key) {
      const getter = isArray(key) ? 'getIn' : 'get';
      return (i) => {
        let val = '';
        // Check if we have an immutable object or not
        if (typeof i.get === 'function') {
          val = i[getter](key);
        } else if (typeof i[key] === 'undefined') {
          val = i;
        } else {
          val = i[key];
        }

        return val && val.toLowerCase ? val.toLowerCase() : val;
      };
    },

    searchFilter(params) {
      const searchParam = params.searchParam || this.props.searchParam;
      if (!searchParam || isUndefined(params.searchQuery)) {
        return params.data || this.state.data;
      }

      const query = params.searchQuery.trim().toLowerCase();
      const data = params.data || this.state.data;
      const getVal = isFunction(searchParam) ?
        searchParam : (i) => i.get(searchParam);

      if (query === this.state.searchQuery && data === this.state.data) {
        return this.state.searched;
      }

      return data.filter((i) => {
        let val = getVal(i);
        val = isString(val) ? val.toLowerCase() : (val.toString ? val.toString() : '');
        return query.length === 0 || val.indexOf(query) !== -1;
      });
    },


    render() {
      return (
        <Component
          {...this.props}
          items={this.state.visibleItems}
          preCapSize={this.state.preCapSize}
        />
      );
    },
  });

  if (options._checkbox) {
    return CheckBox.wrap(W, options._checkbox, {
      parent: true,
    });
  }

  return W;
};


// Sortable

const Sortable = function (props) {
  let cls = 'icon-chevron-right';
  let mt = 6;

  if (props.sortBy === props.sort) {
    cls = props.asc ? 'icon-chevron-up' : 'icon-chevron-down';
    mt = 8;
  }

  return (
    <span onClick={props.onClick(props.sort)} className="pointer text-truncate">
      <i className={`${cls}`} /> { props.label || props.sort }
    </span>
  );
};

const sortableMixin = {
  getInitialState() {
    return {
      sortBy: this.props.initialSortBy || 'name',
      asc: this.props.initialAsc,
    };
  },

  sortBy(key) {
    return (e) => {
      this.setState({
        sortBy: key,
        asc: this.state.sortBy === key ?
          !this.state.asc : true,
      });
    };
  },

  sortableParams() {
    /*
            For convenience when creating sortables:
            <List.Sortable {...this.sortableParams()} sort='name' label={ t.name } />
        */
    return {
      sortBy: this.state.sortBy,
      asc: this.state.asc,
      onClick: this.sortBy,
    };
  },
};


listWrapper.Sortable = Sortable;
listWrapper.sortableMixin = sortableMixin;

// Lower case name to avoid linter interpreting wrapper
// as a constructor that should have a "new" prefix:
listWrapper.wrap = listWrapper;

export default listWrapper;
