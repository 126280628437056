import React, {
  createContext, useState, useContext, memo,
} from 'react';
import PropTypes from 'prop-types';

// Keep track of shift clicks from all ShiftSelectContexts.
let globalLastClicked;
const SelectContext = createContext();

export const ShiftSelectWrapper = memo(({
  items, children, selected, onChange,
}) => {
  const [lastClicked, setLastClicked] = useState();
  const selectItems = (ids) => {
    onChange(Array.from(new Set([
      ...selected,
      ...ids,
    ])));
  };
  const deselectItems = (ids) => {
    onChange(selected.filter((id) => !ids.includes(id)));
  };
  const getIdsInRange = (fromId, toId) => {
    const ids = items.map((item) => (item.id ? item.id : item));
    const first = ids.indexOf(fromId);
    const last = ids.indexOf(toId);
    if (first > last) {
      return new Set(ids.slice(last, first)).add(fromId);
    }
    return new Set(ids.slice(first, last)).add(fromId);
  };
  const toggleRange = (ids, clickedId) => {
    const currentState = selected.includes(clickedId);
    let prevState = selected.includes(lastClicked);
    if (prevState === currentState) {
      ids.add(lastClicked);
      prevState = !prevState;
    }
    if (prevState) {
      selectItems(Array.from(ids));
    } else {
      deselectItems(Array.from(ids));
    }
  };
  const toggleSingle = (id) => {
    globalLastClicked = id;
    setLastClicked(id);
    if (selected.includes(id)) {
      deselectItems([id]);
    } else {
      selectItems([id]);
    }
  };
  const handleShiftSelect = (e, id) => {
    if (lastClicked && lastClicked === globalLastClicked) {
      const ids = getIdsInRange(id, lastClicked);
      toggleRange(ids, id);
    } else {
      toggleSingle(id);
    }
  };
  const api = {
    selected,
    handleShiftSelect,
    toggleSingle,
  };

  return (
    <SelectContext.Provider value={api}>
      {children}
    </SelectContext.Provider>
  );
});

ShiftSelectWrapper.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  })),
  children: PropTypes.node,
  selected: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  onChange: PropTypes.func,
};


export const Checkbox = ({
  id,
  disabled,
}) => {
  const { selected, handleShiftSelect, toggleSingle } = useContext(SelectContext);
  function handleClick(e) {
    if (e.shiftKey && handleShiftSelect) {
      handleShiftSelect(e, id);
    } else {
      toggleSingle(id);
    }
  }
  const handleKeyDown = ({ key }) => {
    if (key === 'Enter') {
      toggleSingle(id);
    }
  };

  return (
    <input
      type="checkbox"
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      onChange={() => {}}
      checked={!!selected && selected.includes(id)}
      disabled={disabled}
    />
  );
};

Checkbox.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  disabled: PropTypes.bool,
};
