import React from 'react';
import PropTypes from 'prop-types';

/*
 * Component that alerts if you click outside of it
 */

class OutsideClick extends React.PureComponent {
  componentDidMount() {
    if (!this.props.disabled) {
      this.startListeners();
    }
  }

  componentDidUpdate({ disabled }) {
    if (disabled && !this.props.disabled) {
      this.startListeners();
    } else if (this.props.disabled) {
      this.stopListening();
    }
  }

  componentWillUnmount() {
    this.stopListening();
  }

  startListeners = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      document.addEventListener('mousedown', this.handleClickOutside);
      document.addEventListener('keydown', this.handleDocumentKeyUp);

      if (this.props.onBlur) {
        document.addEventListener('focusin', this.handleFocusOutside);
      }
    }, 100);
  }

  stopListening = () => {
    clearTimeout(this.timer);
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('keydown', this.handleDocumentKeyUp);
    document.removeEventListener('focusin', this.handleFocusOutside);
  }

  /*
     * Set the wrapper ref
     */
  trackOutsideClicks = (node) => {
    this.wrapperRef = node;
  };

  /*
     * Alert if clicked on outside of element
     */
  handleClickOutside = (event) => {
    const isDropdownClick = this.inDropDown(event.target);

    if (!isDropdownClick && this.isOutsideWrapper(event.target)) {
      this.props.onClickOutside && this.props.onClickOutside(event);
    }
  };

  handleFocusOutside = (event) => {
    const isDropdownClick = this.inDropDown(event.target);

    if (!isDropdownClick && this.isOutsideWrapper(event.target)) {
      this.props.onBlur(event);
    }
  };

  handleDocumentKeyUp = (e) => {
    if (e.keyCode === 27) {
      this.props.onClickOutside && this.props.onClickOutside(e);
    }
  };

  isOutsideWrapper(target) {
    return this.wrapperRef && !this.wrapperRef.contains(target);
  }

  inDropDown(target) {
    const dropdownPortals = Array.from(
      document.getElementsByClassName('dropdownPortalContainer'),
    );

    return dropdownPortals.find((container) => {
      return container.contains(target);
    });
  }

  render() {
    const {
      children,
      onClickOutside,
      onBlur,
      TagName = 'div',
      ...other
    } = this.props;

    return (
      <TagName
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...other}
        ref={this.trackOutsideClicks}
      >
        {this.props.children}
      </TagName>
    );
  }
}

OutsideClick.propTypes = {
  children: PropTypes.node.isRequired,
  onClickOutside: PropTypes.func,
};

export default OutsideClick;
