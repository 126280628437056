import React from 'react';
import Immutable from 'immutable';
import GsSideBar from 'utils/right-sidebar';
import GSColumnsLayout from 'components/GSColumnsLayout';
import { connect } from 'react-redux';
import { fetchPlaylists, getPlaylists } from 'src/entities/playlists';
import { defaultMemoize } from 'reselect';
import SchemeList from './scheme-list';
import ClientGroupsSidebar from './ClientGroupsSidebar';
import { PlaylistSidebar } from './PlaylistSidebar';
import List from './playlist-list';

class PlayLists extends React.Component {
  state = {
    playlists: Immutable.List(),
    loading: true,
  }

  UNSAFE_componentWillMount() {
    this.loadData();
  }

  loadData = () => {
    this.props.fetchPlaylists();
  }

  dataLoaded = () => {
    this.setState({
      loading: false,
    });
  }

  rcol() {
    return (
      <SchemeList
        loading={this.state.loading}
        playlists={this.props.playlists}
      />
    );
  }

  lcol() {
    return (
      <List
        loading={this.state.loading}
        playlists={this.props.playlists}
        playlistId={this.props.id}
      />
    );
  }

  render() {
    const onCloseSidebar = (history) => {
      history.replace('/playlists');
    };

    const { id } = this.props.match.params;

    return (
      <GSColumnsLayout
        leftCol={this.lcol()}
        rightCol={this.rcol()}
        sidebar={(
          <GsSideBar href="/playlists" onClose={() => onCloseSidebar(this.props.history)}>
            {id && (
              <PlaylistSidebar
                key={id}
                id={id}
                tab={this.props.match.params.tab}
              />
            )}
            {this.props.location.pathname.includes('edit-groups') && (
              <ClientGroupsSidebar />
            )}
          </GsSideBar>
        )}
      />
    );
  }
}

const mapStateToProps = () => {
  const toImmutable = defaultMemoize((playlists) => {
    return Immutable.fromJS(playlists);
  });

  return (state) => ({
    playlists: toImmutable(getPlaylists(state)),
  });
};

const mapDispatchToProps = {
  fetchPlaylists,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayLists);
