import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { string } from 'prop-types';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import cancan from '../../../../js/system/cancan';
import * as MsgActions from '../../../../js/react/actions/message-actions';
import Spinner from '../../../../js/react/Dashboard/components/Spinner';
import {
  getPlaceById,
  updatePlace,
  deletePlace,
  fetchPlace,
} from '../../../entities/places';
import { placeReadonly } from '../../../../constants/permissions';
import { useRequestWithPromise } from '../../../../src/core/api';
import { EditPlaceForm } from './EditPlaceForm';
import { placeNavigate } from './InterceptNav';
import { PlaceMapContext } from './PlacesStateManager';

function isEditable(placeType = '') {
  return !cancan.can('read', placeReadonly(placeType.toLowerCase()));
}

export const EditPlace = ({ placeToEditId, visibleIds }) => {
  const { dispatch } = useRequestWithPromise();
  const placeToEdit = useSelector(getPlaceById(placeToEditId));
  const { geometryIndex, setGeometryIndex, placeToSave, setPlace } =
    useContext(PlaceMapContext);
  const history = useHistory();

  useEffect(() => {
    // Update state if placeToEdit changes outside for some reason
    setPlace(placeToEdit);
  }, [placeToEdit]);

  useEffect(
    () => () => {
      // Clear on unmount
      setPlace(null);
      setGeometryIndex(0);
    },
    []
  );

  useEffect(() => {
    dispatch(fetchPlace(placeToEditId));
  }, [placeToEditId]);

  const handleSave = () => {
    const audioIsString =
      placeToSave.connectedMedia &&
      Object.keys(placeToSave.connectedMedia).length === 1 &&
      placeToSave.connectedMedia.audio === '';

    dispatch(
      updatePlace({
        ...placeToSave,
        name: placeToSave.name?.trim(),
        // Fix bug with passing connected.audio as empty string, when backend requires integer
        connected: audioIsString ? {} : placeToSave.connectedMedia,
      })
    ).then(
      ({ payload }) => {
        MsgActions.success(`Saved place ${payload?.name}`);
      },
      () => {
        MsgActions.error('Failed to update place');
      }
    );
  };

  const handleDelete = () => {
    const { id } = placeToEdit;
    try {
      dispatch(deletePlace(id)).then(({ payload }) => {
        MsgActions.success(`Deleted place ${payload?.name}`);
      });
    } catch (e) {
      // Here to ensure that we navigate away.
    }
    placeNavigate(
      visibleIds.filter((i) => i !== id),
      null,
      history
    );
  };

  const handleCancel = () => {
    placeNavigate(visibleIds, null, history);
  };

  const handleAttributeChange = (value, key = 'name') => {
    setPlace({
      ...placeToSave,
      [key]: value,
    });
  };

  return (
    <div
      className="m-2 p-3 bdr880 rounded bg-white bshadow position-absolute z9999 r0 span3 w300"
      style={{
        top: 0,
      }}
    >
      {!isEmpty(placeToSave) && (
        <EditPlaceForm
          item={placeToSave}
          editable={isEditable(placeToSave.type)}
          changed={placeToEdit !== placeToSave}
          onSave={handleSave}
          onDelete={handleDelete}
          onCancel={handleCancel}
          onChange={handleAttributeChange}
          geometryIndex={geometryIndex}
          setGeometryIndex={setGeometryIndex}
          allowExtId={!placeToEdit?.identifier}
        />
      )}
      {isEmpty(placeToSave) && <Spinner />}
    </div>
  );
};

EditPlace.propTypes = {
  placeToEditId: string.isRequired,
};
