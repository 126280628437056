/*!
 * GeoSignage Sverige AB
 * http://geosignage.com/
 *
 * Copyright 2015, GeoSignage Sverige AB
 * All rights reserved.
 */

import React from 'react';
import { createRoot } from 'react-dom/client';
import icon from 'js/theme/icons';
import cancan from 'js/system/cancan';
import locale from 'js/locale';

import './gs-reset.jsx';
import 'react-widgets/scss/styles.scss';
import 'js/react/history/gs_react_widgets.scss';
import 'js/react/plan2/media/react_widgets.scss';
import 'isomorphic-fetch';

import './style/entry.scss';

locale();
document.addEventListener('DOMContentLoaded', async () => {
  window.icon = icon;

  const response = await fetch(`${window.GS_API}/session`);
  if (!response.ok) {
    if (window.location.hostname !== 'localhost') {
      window.location.replace('/login');
    }
  }

  const jsonResponse = await response.json();
  cancan.setSession(jsonResponse);

  const App = require('src/screens/App').default;
  const ReactMsg = require('js/react/system/messages.jsx').default;
  const HelpModalProvider = require('js/react/system/HelpModalProvider.jsx').default;

  const appRoot = createRoot(document.getElementById('gs_app'));
  appRoot.render(<App username={jsonResponse.username} />);

  const msgRoot = createRoot(document.getElementById('messages'));
  msgRoot.render(<ReactMsg />);

  const helpModalRoot = createRoot(document.getElementById('helpModal'));
  helpModalRoot.render(<HelpModalProvider />);
});
