import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { intlShape } from 'utils/shapes';
import { error as errorMessage } from 'js/react/actions/message-actions';
import Dropdown, { DropdownToggle, DropdownContent } from 'components/Dropdown';

const propTypes = {
  createGroup: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

class AddGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
    };
  }

  handleName = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  handleSave = (e) => {
    e.preventDefault();
    if (!this.state.name) {
      errorMessage('Please fill in a name.');
    } else {
      this.props.createGroup(this.state.name);
      this.setState({ name: '' });
    }
  };

  render() {
    const {
      intl: { formatMessage },
    } = this.props;
    const placeholder = `${formatMessage({ id: 'common.name' })}...`;

    return (
      <Dropdown>
        <DropdownToggle
          className="btn btn-primary"
        >
          {formatMessage({ id: 'views.admin.groupManagement.addGroup' })}
        </DropdownToggle>
        <DropdownContent>
          <div className="w300 p-3" data-action="preventDefault">
            <form className="mb-0">
              <fieldset id="addGroupFieldset" className="">
                <div className="form-group">
                  <label htmlFor="groupmgmt.name">
                    <FormattedMessage id="common.name" />
                  </label>
                  <input
                    type="text"
                    name="groupmgmt.name"
                    className="form-control"
                    placeholder={placeholder}
                    value={this.state.name}
                    onChange={this.handleName}
                    data-testid="input-name"
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.handleSave}
                  data-testid="create-button"
                >
                  <FormattedMessage id="common.create" />
                </button>
              </fieldset>
            </form>
          </div>
        </DropdownContent>
      </Dropdown>
    );
  }
}

AddGroup.propTypes = propTypes;

export default injectIntl(AddGroup);
