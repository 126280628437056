import { useContext, useEffect } from 'react';
import { setDateTime, SimulationContext } from '../../components/StateManager';

// Push time and location to media server when they change
export const useSyncDateTime = ({ dateTime, currentLocation, error }, request, rootPath) => {
  const [, dispatch] = useContext(SimulationContext);

  const resetTime = () => {
    // Reset time
    rootPath && request(`${rootPath}/testing/time`, 'DELETE');
  };

  const setTime = () => {
    rootPath && !error && dateTime && request(`${rootPath}/testing/time`, 'PUT', {
      // Convert to seconds from ms
      now: Math.round((dateTime || Date.now()) / 1000),

      // Todo: Get for current position in map?
      location: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  };

  const getTime = async () => {
    const { now } = await request(`${rootPath}/testing/time`).then(
      (res) => res.json(),
    );
    // Reponse will be in seconds, convert to ms
    dispatch(setDateTime(now * 1000));
  };

  useEffect(() => {
    if (dateTime) {
      setTime();
    } else {
      resetTime();
    }
  }, [rootPath, dateTime]);

  useEffect(() => {
    // Get initial time
    rootPath && getTime();

    // On unmount
    return resetTime;
  }, [rootPath]);
};
