/* eslint-disable max-classes-per-file */
import Immutable from 'immutable';
import React from 'react';

/*
    Checkbox utils for lists.

    Use:

    const List = connect(
        state => {
            return {
                selected : state.media.get('selected')
            };
        },
        {
            handleSelect    : mediaActions.select,
            handleDeselect  : mediaActions.deselect,
            selectAll       : mediaActions.selectAll
        }
    )(ListView);
*/

function getKeys(items) {
  if (Immutable.List.isList(items)) {
    return items.map((i) => i.get('id'));
  }

  return items.keySeq();
}

class CheckboxUtils extends React.PureComponent {
    static defaultProps = {
      selected: Immutable.Set(),
      handleDeselect: () => {},
      handleSelect: () => {},
    }

    constructor(props) {
      super(props);
      this.state = {
        lastSelected: '',
      };
    }

    componentWillUnmount() {
      this.props.selectAll && this.props.selectAll();
    }

    getAffectedIds(id, items) {
      const ids = getKeys(items);
      const first = ids.indexOf(id);
      const last = ids.indexOf(this.state.lastSelected);

      if (first > last) {
        return ids.slice(last, first).toSet().add(id);
      }

      return ids.slice(first, last).toSet().add(id);
    }

    toggleSingle(id) {
      const selected = this.props.selected.contains(id);
      if (selected) {
        this.props.handleDeselect(id);
      } else {
        this.props.handleSelect(id);
      }
    }

    toggleMultiple(ids) {
      const selected = this.props.selected.contains(this.state.lastSelected);
      if (selected) {
        this.props.handleSelect(ids);
      } else {
        this.props.handleDeselect(ids);
      }
    }

    toggleSelect(id, items) {
      return (e) => {
        if (items && this.state.lastSelected && e.shiftKey) {
          const ids = this.getAffectedIds(id, items).add(id);
          this.toggleMultiple(ids);
        } else {
          this.toggleSingle(id);
        }

        this.setState({
          lastSelected: id,
        });
      };
    }

    allSelected(items, selected) {
      const ids = items.keySeq().toSet();
      return ids.isSubset(selected);
    }

    selectAll = (items) => {
      return (e) => {
        let ids;
        if (e.target.checked) {
          ids = items.keySeq().toSet();
        }
        this.props.selectAll(ids);
      };
    }
}

export function checkboxWrapper(Component) {
  return class Checker extends CheckboxUtils {
    render() {
      return (
        <Component
          {...this.props}
          {...this.state}
          toggleSelect={this.toggleSelect}
        />
      );
    }
  };
}

export default CheckboxUtils;
