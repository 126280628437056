import React from 'react';
import { useSelector } from 'react-redux';

import * as selectors from 'js/redux/selectors';
import HelpIcon from 'components/HelpIcon';
import TabView from 'js/react/components/TabView';
import { EditPlaylist } from 'js/react/playlists/EditPlaylist';
import { PlaylistPlaces } from 'js/react/playlists/PlaylistPlaces';
import { PlannedMedia } from './PlannedMedia';
import { InfoBox } from './InfoBox';

export const Main = (props) => {
  const {
    date,
    playlistId,
    onClose,
  } = props;

  const playlist = useSelector((state) => selectors.getPlaylistById(state, playlistId));

  return (
    <div className="holder bg-white p-3">
      {onClose && (
        <button
          type="button"
          aria-label="Close"
          className="close d-none d-sm-inline-block"
          onClick={onClose}
          data-testid="close-sidebar"
        >
          <span aria-hidden="true">
            {'\u00D7'}
          </span>
        </button>
      )}
      <h3>
        {playlist?.name}
        <HelpIcon
          bodyKey="help.planning.playlistSidebar.body"
          headerKey="help.planning.playlistSidebar.header"
        />
      </h3>
      <div className="card bg-light p-3 mb-3">
        {playlist && (
          <InfoBox
            playlist={playlist}
          />
        )}
      </div>
      <TabView
        className="mb-3"
        // Disabled until we know how we want to handle this.
        //   tabs={[{
        //     id: 'plan',
        //     label: formatMessage({
        //       id: 'common.media',
        //     }),
        //     'data-testid': 'playlist-plan-tab',
        //   },
        //   !['default', 'filler'].includes(playlist?.sub_type) && {
        //     id: 'places',
        //     label: formatMessage({
        //       id: 'common.places',
        //     }),
        //     'data-testid': 'playlist-places-tab',
        //   }, {
        //     id: 'edit',
        //     label: formatMessage({
        //       id: 'common.edit',
        //     }),
        //     'data-testid': 'playlist-edit-tab',
        //   }
        // ]}
      >
        {(tab) => {
          switch (tab) {
          case 'edit':
            return (
              <EditPlaylist playlistId={playlistId} />
            );
          case 'places':
            return (
              <PlaylistPlaces playlistId={playlistId} />
            );
          default:
            return (
              <PlannedMedia
                date={date}
                playlistId={playlistId}
              />
            );
          }
        }}
      </TabView>
    </div>
  );
};
