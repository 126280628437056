import { createSelectors } from 'src/core/data';

import { COLLECTION, HISTORY_COLLECTION } from './constants';

export const {
  getAll: getClients,
} = createSelectors(COLLECTION);

export const {
  getById: getClientHistory,
} = createSelectors(HISTORY_COLLECTION);
