import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

const propTypes = {
  onDelete: PropTypes.func.isRequired,
  confirmLabel: PropTypes.string,
  children: PropTypes.node,
  enableCancel: PropTypes.bool,
};

class DeleteConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
    };
  }

  handleConfirm = () => {
    this.setState((prevState) => ({
      confirm: !prevState.confirm,
    }));
  };

  renderConfirmation() {
    const confirmLabel =
      this.props.confirmLabel ||
      this.props.intl.formatMessage({ id: 'common.confirmDelete' });
    return (
      <span>
        <button
          type="button"
          className="btn btn-warning"
          onClick={this.props.onDelete}
          data-testid="confirm-button"
        >
          {confirmLabel}
        </button>
        {this.props.enableCancel && (
          <button type="button" className="btn btn-light border" onClick={this.handleConfirm}>
            <i className="icon-ban-circle" />{' '}
            <FormattedMessage id="common.cancel" />
          </button>
        )}
      </span>
    );
  }

  renderDeleteBtn() {
    return (
      <button
        type="button"
        className="btn btn-danger"
        onClick={this.handleConfirm}
        data-testid="delete-button"
      >
        {this.props.children || <i className="icon-trash icon-white" />}
      </button>
    );
  }

  render() {
    const {
      children,
      confirmLabel,
      intl,
      onDelete,
      enableCancel,
      ...other
    } = this.props;

    return (
      <div className="d-inline-block" {...other}>
        {this.state.confirm
          ? this.renderConfirmation()
          : this.renderDeleteBtn()}
      </div>
    );
  }
}

DeleteConfirm.propTypes = propTypes;

export default injectIntl(DeleteConfirm);
