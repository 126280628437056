import { createSelector } from 'reselect';
import filterReports from 'js/redux/utils/filterReports';
import { PRIMARY_DASHBOARD_ID } from './constants';
import aggregateReports from '../utils/aggregateReports';

export const getLocalState = (state) => state.dashboard;

export const getDashboards = (state) => state.dashboards;
export const getDashboard = (
  state,
  { dashboardId = PRIMARY_DASHBOARD_ID } = {},
) => state.dashboards[dashboardId];

export const getDashboardBadges = createSelector(
  getDashboard,
  (dashboard) => dashboard.badges,
);
export const getDashboardWidgets = createSelector(
  getDashboard,
  (dashboard) => dashboard.widgets,
);

export const getDashboardBadge = createSelector(
  getDashboardBadges,
  (_, { badgeId }) => badgeId,
  (dashboardBadges, badgeId) => dashboardBadges.find(({ id }) => id === badgeId),
);
export const getDashboardWidget = createSelector(
  getDashboardWidgets,
  (_, { widgetId }) => widgetId,
  (dashboardWidgets, widgetId) => dashboardWidgets.find(({ id }) => id === widgetId),
);

export const getDashboardWidgetSettings = createSelector(
  getDashboardBadge,
  (dashboardBadge) => dashboardBadge.settings,
);

export const getDashboardWidgetTitle = createSelector(
  getDashboardWidget,
  (dashboardWidget) => dashboardWidget.title,
);

export const getReports = (state) => state.reports;

// Get reports for specific date range
export const getReportsForDateRange = createSelector(
  getReports,
  (_, __, dateRange) => dateRange,
  (reports, dateRange) => {
    const sortedDates = Object.keys(reports).sort();
    if (sortedDates.length) {
      return dateRange.reduce((acc, date) => {
        const reps = reports[date] || [];
        return [...acc, ...reps];
      }, []);
    }

    return [];
  },
);

export const getFilteredReportsForDateRange = createSelector(
  getReportsForDateRange,
  (_, { filters }) => filters,
  (reports, filters) => {
    return filterReports({ data: reports, ...filters });
  },
);

export const getAggregatedFilteredReportsForDateRange = createSelector(
  getFilteredReportsForDateRange,
  (_, settings) => settings,
  (_, __, dateRange) => dateRange,
  (reports, settings, dateRange) => {
    return aggregateReports({ reports, dateRange, settings });
  },
);
