import { useContext } from 'react';
import { isEmpty } from 'lodash';
import { ConditionsContext } from '../components/ConditionsContext';
import { FIELD_CONDITION } from '../constants';

export const useFieldCondition = (field, operator = 'and') => {
  const {
    values,
    dispatchChange,
  } = useContext(ConditionsContext);
  const conditions = values?.additional_data?.conditions?.ops || [];
  const idx = conditions.findIndex(({ field: f }) => f === field);

  const handleFieldCondition = (value = [], op = 'eq') => {
    const newConditions = [...conditions];
    const newIdx = idx > -1 ? idx : conditions.length;

    if (isEmpty(value)) {
      // Remove condition
      idx > -1 && newConditions.splice(idx, 1);
    } else {
      newConditions[newIdx] = {
        field,
        op,
        value,
      };
    }

    dispatchChange({
      type: FIELD_CONDITION,
      payload: {
        operator,
        conditions: newConditions,
      },
    });
  };

  return [conditions[idx], handleFieldCondition];
};
