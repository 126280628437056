import React, { memo, useMemo } from 'react';
import { AreaRow } from './AreaRow';

const AreaList = memo(({
  areas, areaSettings, updateArea, handleStyleSettings, defaultMode,
}) => {
  const selectedSchemes = useMemo(() => {
    const settings = Object.values(areaSettings || {});
    return settings
      .filter(({ contentType }) => contentType === 'scheme')
      .map(({ contentId }) => parseInt(contentId, 10));
  }, [areaSettings]);

  return (
    <div>
      {areas && areas.map((a) => {
        const settings = areaSettings[a.id] || {};
        return (
          <AreaRow
            key={a.id}
            defaultMode={defaultMode}
            settings={settings}
            updateArea={(value) => updateArea(a.id, value)}
            handleStyleSettings={(next) => handleStyleSettings(a.id, next)}
            selectedSchemes={selectedSchemes}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...a}
          />
        );
      }).reverse()}
    </div>
  );
});

export default AreaList;
