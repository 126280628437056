import { v4 as getUuid } from 'uuid';

import { get } from 'js/redux/api/data/helpers/actionCreatorFactories';
import * as actionTypes from '../actionTypes';

export default function fetchReports(isoDate) {
  const types = [
    actionTypes.FETCH_REPORTS_BY_DATE,
    actionTypes.FETCH_REPORTS_BY_DATE_SUCCESS,
    actionTypes.FETCH_REPORTS_BY_DATE_FAIL,
  ];
  const url = '/monitor/aggstats';
  const query = `?date=${isoDate}`;
  const meta = {
    verb: 'get',
    collection: 'reports',
    date: isoDate,
    query,
    uuid: getUuid(),
    url,
    reset: true,
  };

  return get({
    url, query, types, meta,
  });
}
