import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';
import { getClientGroupById } from 'src/entities/clientGroups';
import { baseRouteConsumer } from 'js/react/_utils/BaseRouteContext';
import { getMediaInObject } from 'src/entities/media';
import LayoutPreview from '../LayoutPreview';

const propTypes = {};

function ClientLayout({ clientGroup = {}, route, media }) {
  const href = `${route}/layout`;
  const layout = clientGroup.layout || {
    id: 'undef',
    name: '',
  };

  return (
    <div>
      <p className="mb-2">
        <FormattedMessage id="common.layout" />
        {':'}
      </p>
      <div
        key={layout.id}
        className="mb-2"
      >
        <LayoutPreview
          {...layout}
          media={media}
        />
      </div>
      <a
        href={href}
        className="btn btn-light border w-100"
      >
        {layout.name || <FormattedMessage id="views.layout.editLayout" />}
        <i className="icon-chevron-right" />
      </a>
    </div>
  );
}

function mapState(state, { clientId }) {
  return {
    clientGroup: getClientGroupById(clientId)(state) || undefined,
    media: getMediaInObject(state),
  };
}

ClientLayout.propTypes = propTypes;

export default compose(
  baseRouteConsumer,
  connect(mapState),
)(ClientLayout);
