import { ADMIN_GROUP_MANAGEMENT } from 'constants/permissions';

export const columnConfigs = [
  {
    hideTitle: true,
    sortable: false,
    propertyName: 'visible',
    width: 50,
  },
  {
    propertyName: 'name',
    sortable: true,
    translationKey: 'common.name',
  },
  {
    sortable: true,
    propertyName: 'type',
    translationKey: 'common.type',
  },
  {
    modulePermission: ADMIN_GROUP_MANAGEMENT,
    propertyName: 'sortableGroup',
    sortable: true,
    translationKey: 'common.group',
  },
];
