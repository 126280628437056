import React, { useState } from 'react';
import {
  func, number, oneOfType, string,
} from 'prop-types';
import { useSelector } from 'react-redux';
import debug from 'debug';

import { getWidgetType } from 'src/entities/widgets';
import { updateMediaObject, getMediaObjectById } from 'src/entities/media';
import * as MsgActions from 'js/react/actions/message-actions';

import { useRequestWithPromise } from 'src/core/api';
import Preview from '../Preview';
import Form from './Form';

const log = debug('js:react:plan2:media-sidebar:Widgets:Edit');

const CUSTOM_FORM_FIELDS = {
  widget_rss: ['url'],
  widget_weblink: ['url'],
  widget_html: [],
};

const REQUIRED_FIELDS_FOR_ALL_WIDGETS = ['name'];

const REQUIRED_FIELDS_BY_WIDGET_TYPE = {
  widget_rss: [...REQUIRED_FIELDS_FOR_ALL_WIDGETS, 'length', 'url', 'theme'],
  widget_weblink: [...REQUIRED_FIELDS_FOR_ALL_WIDGETS, 'length', 'url'],
  widget_html: [...REQUIRED_FIELDS_FOR_ALL_WIDGETS, 'length'],
};

const propTypes = {
  onClose: func.isRequired,
  mediaId: oneOfType([string, number]),
};

// this component assumes mediaObjects and widgetTypes are fetched in
// a parent, currently in media-lib
export const EditWidget = ({ onClose: handleClose, mediaId }) => {
  const { dispatch } = useRequestWithPromise();
  const [loading, setLoading] = useState(false);
  const mediaObject = useSelector(getMediaObjectById(mediaId));
  const widgetTypeSpecification = useSelector(
    (state) => getWidgetType(state, mediaObject?.media_type),
  );
  const { type: mediaType } = widgetTypeSpecification || {};
  const {
    config, type, id, name,
  } = mediaObject || {};

  const handleSubmit = (formData) => {
    log('handleSubmit');

    setLoading(true);
    dispatch(updateMediaObject(formData))
      .then((action) => {
        MsgActions.success(`Saved widget: ${action.payload.name}`);
        requestAnimationFrame(() => setLoading(false));
      })
      .catch(({ payload }) => {
        setLoading(false);
        payload
          ? MsgActions.error(Object.values(payload).join('\n'))
          : MsgActions.error('Unknown error');
      });
  };

  log('render mediaObject', mediaObject);

  return !mediaObject || !widgetTypeSpecification ? null : (
    <div className="holder bg-white p-3">
      {handleClose && (
        <button
          type="button"
          aria-label="Close"
          className="close d-none d-sm-inline-block"
          onClick={handleClose}
        >
          <span aria-hidden="true">
            {'\u00D7'}
          </span>
        </button>
      )}

      <h3>{name}</h3>

      <div className="thumbnail mb-3">
        <div>
          {!loading && <Preview id={id} configParams={config} type={type} mediaType={mediaType} />}
        </div>

        <Form
          loading={loading}
          mediaObject={mediaObject}
          onSubmit={handleSubmit}
          formKeys={CUSTOM_FORM_FIELDS[widgetTypeSpecification.type]}
          requiredKeys={REQUIRED_FIELDS_BY_WIDGET_TYPE[widgetTypeSpecification.type] || REQUIRED_FIELDS_FOR_ALL_WIDGETS}
          widgetTypeSpecification={widgetTypeSpecification}
        />
      </div>
    </div>
  );
};

EditWidget.propTypes = propTypes;
