import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ASPECT_RATIOS, DEFAULT_ASPECT_RATIO } from '../../_utils';

function NameEditor({ value, onChange }) {
  return (
    <div className="mr-3">
      <label htmlFor="layout-template-name">
        <FormattedMessage id="common.name" />
      </label>
      <input
        id="layout-template-name"
        type="text"
        className="form-control"
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

function SizeSelect({ value, onChange }) {
  return (
    <div>
      <label htmlFor="layout-template-res">
        <FormattedMessage id="views.layout.aspectRatio" />
      </label>
      <select
        className="form-control"
        name="resolution"
        id="layout-template-res"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        {ASPECT_RATIOS.map((size) => (
          <option key={size} value={size}>
            {size}
          </option>
        ))}
        <FormattedMessage
          id="views.layout.customAspectRatio"
        >
          {(txt) => <option value="custom">{txt}</option>}
        </FormattedMessage>
      </select>
    </div>
  );
}

function CustomSize({ value, onChange }) {
  const [w, h] = value.split(':');

  return (
    <div className="form-row">
      <div className="col form-inline mt-2">
        <label
          className="mr-1"
          htmlFor="layout-template-custom-w"
        >
          <FormattedMessage id="views.layout.aspectRatioWidth" />
        </label>
        <input
          id="layout-template-custom-w"
          type="number"
          className="form-control mr-2"
          value={parseInt(w, 10)}
          onChange={(e) => {
            onChange(`${e.target.value}:${h}`, true);
          }}
        />
      </div>
      <div className="col form-inline mt-2">
        <label
          className="mr-1"
          htmlFor="layout-template-custom-h"
        >
          <FormattedMessage id="views.layout.aspectRatioHeight" />
        </label>
        <input
          id="layout-template-custom-h"
          type="number"
          className="form-control"
          value={parseInt(h, 10)}
          onChange={(e) => {
            onChange(`${w}:${e.target.value}`, true);
          }}
        />
      </div>
    </div>
  );
}

class LayoutSettings extends React.PureComponent {
  constructor(props) {
    super(props);
    const aspectRatio = props.aspectRatio || DEFAULT_ASPECT_RATIO;
    this.state = {
      aspectRatio,
      name: props.name || '',
      showCustom: ASPECT_RATIOS.indexOf(aspectRatio) === -1,
    };
  }

  handleScreen = (aspectRatio = this.props.aspectRatio, isCustom) => {
    const showCustom = isCustom || ASPECT_RATIOS.indexOf(aspectRatio) === -1;

    this.setState({ showCustom, aspectRatio }, this.propagateChanges);
  }

  handleCustom = () => {}

  handleName = (e) => {
    this.setState({
      name: e.target.value,
    }, this.propagateChanges);
  }

  propagateChanges = () => {
    this.props.onChange({
      name: this.state.name,
      aspectRatio: this.state.aspectRatio,
    });
  }

  render() {
    return (
      <div
        className="mb-2"
      >
        <div className="d-flex">
          {this.props.name && (
            <NameEditor
              value={this.state.name}
              onChange={this.handleName}
            />
          )}
          <SizeSelect
            value={this.state.showCustom ? 'custom' : this.state.aspectRatio}
            onChange={this.handleScreen}
          />
        </div>
        {this.state.showCustom && (
          <CustomSize
            value={this.state.aspectRatio || this.props.aspectRatio || DEFAULT_ASPECT_RATIO}
            onChange={this.handleScreen}
          />
        )}
      </div>
    );
  }
}

export default LayoutSettings;
