import { useSelector } from 'react-redux';
import * as selectors from 'js/redux/selectors';

export const useDefaultScheme = () => {
  // TODO: Filter by group id??
  const schemes = useSelector(selectors.getSchemes);
  return schemes?.reduce?.((acc, scheme) => {
    return Math.min(acc, parseInt(scheme?.id, 10));
  }, parseInt(schemes?.[0]?.id, 10));
};
