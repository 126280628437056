import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import GroupRow from './GroupRow';

const propTypes = {
  groups: PropTypes.array,
  onSelect: PropTypes.func,
  selectAll: PropTypes.func,
  selected: PropTypes.array,
  updateGroupName: PropTypes.func.isRequired,
};

const defaultProps = {};

function GroupList({
  groups, onSelect, selected, selectAll, updateGroupName,
}) {
  const allSelected = groups.length === selected.length;

  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th style={{ width: '5%' }}>
            <input type="checkbox" checked={allSelected} onChange={selectAll} className="align-middle" />
          </th>
          <th className="w200" data-sortby="name" data-sorted="asc">
            {/* <span className="caret" /> */}
            <FormattedMessage id="common.name" />
          </th>
        </tr>
      </thead>
      <tbody data-testid="group-table-body">
        {groups &&
          groups.map((group) => (
            <GroupRow
              key={group.id}
              group={group}
              selected={selected.indexOf(group.id) !== -1}
              onSelect={onSelect}
              updateGroupName={updateGroupName(group.id)}
            />
          ))}
      </tbody>
    </table>
  );
}

GroupList.propTypes = propTypes;
GroupList.defaultProps = defaultProps;

export default GroupList;
