import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const propTypes = {
  group: PropTypes.object,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  updateGroupName: PropTypes.func.isRequired,
};

const GroupRow = ({
  group, selected, onSelect, updateGroupName,
}) => {
  const [name, setName] = useState(group.name);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    setName(group.name);
  }, [group]);

  const handleChange = (key, e) => {
    setName(e.target.value);
    setHasChanges(e.target.value !== group.name);
  };

  const handleSelect = () => onSelect(group.id);

  const handleNameUpdate = (e) => {
    e.preventDefault();
    updateGroupName(name);
    setHasChanges(false);
  };

  return (
    <tr>
      <td className="align-middle">
        <input
          type="checkbox"
          className="align-middle"
          onChange={handleSelect}
          checked={selected}
        />
      </td>
      <td className="form-inline d-flex">
        <input
          type="text"
          className="form-control mr-2"
          value={name}
          onChange={handleChange.bind(null, 'name')}
          data-testid="name-input"
        />
        <button
          type="button"
          className={`ml-auto btn ${hasChanges ? 'btn-primary' : 'btn-light border'}`}
          onClick={hasChanges && handleNameUpdate}
          disabled={!hasChanges}
        >
          <FormattedMessage id="common.save" />
        </button>
      </td>
    </tr>
  );
};

GroupRow.propTypes = propTypes;

export default GroupRow;
