import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js';

class ReactChart extends React.Component {
  componentDidUpdate(pp) {
    if (pp.data !== this.props.data) {
      this.updateChart();
    }
  }

  componentWillUnmount() {
    this.chart && this.chart.destroy && this.chart.destroy();
  }

  setCanvas = (canvas) => {
    this.canvas = canvas;
    this.initChart();
  }

  updateChart() {
    if (this.chart && this.props.data) {
      this.chart.data.labels = this.props.data.labels;
      this.chart.data.datasets = this.props.data.datasets;
      this.chart.update();
    } else {
      this.initChart();
    }
  }

  initChart = () => {
    if (this.canvas) {
      const ctx = this.canvas.getContext('2d');
      this.chart = new Chart(ctx, {
        type: this.props.type || 'pie',
        data: this.props.data,
        options: this.props.options || {
          legend: false,
          maintainAspectRatio: false,
        },
      });
    }
  }

  render() {
    // Omit data & options
    const { data, options, ...other } = this.props;

    return (
      <canvas
        ref={this.setCanvas}
        width="350px"
        height="300px"
        {...other}
      />
    );
  }
}

ReactChart.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string,
  options: PropTypes.object,
};

export default ReactChart;
