import { v4 as getUuid } from 'uuid';

import {
  ADD_DASHBOARD_BADGE,
  ADD_DASHBOARD_WIDGET,
  CREATE_DASHBOARD,
  DELETE_DASHBOARD,
  FETCH_REPORTS_BY_DATE_SUCCESS,
  REMOVE_DASHBOARD_BADGE,
  REMOVE_DASHBOARD_WIDGET,
  SET_DASHBOARDS_CONFIG,
  UPDATE_DASHBOARD_BADGES,
  UPDATE_DASHBOARD_WIDGET_SETTINGS,
  UPDATE_DASHBOARD_WIDGET_TITLE,
  UPDATE_DASHBOARD_WIDGETS,
} from './actionTypes';

import { PRIMARY_DASHBOARD_ID } from './constants';

const initialState = {
  dashboards: {
    [PRIMARY_DASHBOARD_ID]: {
      id: PRIMARY_DASHBOARD_ID,
      badges: [],
      widgets: [],
    },
  },
  reports: {},
};

function makeWidget({ settings, title, widgetType }) {
  return {
    id: getUuid(),
    type: widgetType,
    title,
    settings,
  };
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case ADD_DASHBOARD_BADGE: {
    const { dashboardId, badgeType } = action.payload;
    return {
      ...state,
      dashboards: {
        ...state.dashboards,
        [dashboardId]: {
          ...state.dashboards[dashboardId],
          badges: [
            ...state.dashboards[dashboardId].badges,
            { id: getUuid(), type: badgeType },
          ],
        },
      },
    };
  }

  case ADD_DASHBOARD_WIDGET: {
    const {
      dashboardId, settings, title, widgetType,
    } = action.payload;
    return {
      ...state,
      dashboards: {
        ...state.dashboards,
        [dashboardId]: {
          ...state.dashboards[dashboardId],
          widgets: [
            ...state.dashboards[dashboardId].widgets,
            makeWidget({ settings, title, widgetType }),
          ],
        },
      },
    };
  }

  case CREATE_DASHBOARD: {
    const id = getUuid();
    return {
      ...state,
      dashboards: {
        ...state.dashboards,
        [id]: {
          id,
          badges: [],
          widgets: [],
        },
      },
    };
  }

  case DELETE_DASHBOARD: {
    const { dashboardId } = action.payload;
    if (dashboardId === PRIMARY_DASHBOARD_ID) {
      return {
        ...state,
        dashboards: {
          ...state.dashboards,
          [PRIMARY_DASHBOARD_ID]: initialState[PRIMARY_DASHBOARD_ID],
        },
      };
    }

    const newState = { ...state };
    delete newState.dashboards[dashboardId];

    return newState;
  }

  case FETCH_REPORTS_BY_DATE_SUCCESS: {
    const { date } = action.meta;

    return {
      ...state,
      reports: {
        ...state.reports,
        [date]: action.payload,
      },
    };
  }

  case REMOVE_DASHBOARD_BADGE: {
    const { dashboardId, badgeId } = action.payload;
    return {
      ...state,
      dashboards: {
        ...state.dashboards,
        [dashboardId]: {
          ...state.dashboards[dashboardId],
          badges: [
            ...state.dashboards[dashboardId].badges.filter(
              ({ id }) => id !== badgeId,
            ),
          ],
        },
      },
    };
  }

  case REMOVE_DASHBOARD_WIDGET: {
    const { dashboardId, widgetId } = action.payload;
    return {
      ...state,
      dashboards: {
        ...state.dashboards,
        [dashboardId]: {
          ...state.dashboards[dashboardId],
          widgets: [
            ...state.dashboards[dashboardId].widgets.filter(
              ({ id }) => id !== widgetId,
            ),
          ],
        },
      },
    };
  }

  case SET_DASHBOARDS_CONFIG: {
    return {
      ...state,
      dashboards: action.payload,
    };
  }

  case UPDATE_DASHBOARD_BADGES: {
    const { dashboardId, badges } = action.payload;
    return {
      ...state,
      dashboards: {
        ...state.dashboards,
        [dashboardId]: {
          ...state.dashboards[dashboardId],
          badges,
        },
      },
    };
  }

  case UPDATE_DASHBOARD_WIDGET_SETTINGS: {
    const { dashboardId, widgetId, settings } = action.payload;
    const widgetIndex = state.dashboards[dashboardId].widgets.findIndex(
      ({ id }) => id === widgetId,
    );
    const updatedWidget = {
      ...state.dashboards[dashboardId].widgets[widgetIndex],
      settings,
    };
    const updatedWidgets = [...state.dashboards[dashboardId].widgets];
    updatedWidgets[widgetIndex] = updatedWidget;

    return {
      ...state,
      dashboards: {
        ...state.dashboards,
        [dashboardId]: {
          ...state.dashboards[dashboardId],
          widgets: updatedWidgets,
        },
      },
    };
  }

  case UPDATE_DASHBOARD_WIDGET_TITLE: {
    const { dashboardId, widgetId, title } = action.payload;
    const widgetIndex = state.dashboards[dashboardId].widgets.findIndex(
      ({ id }) => id === widgetId,
    );
    const updatedWidget = {
      ...state.dashboards[dashboardId].widgets[widgetIndex],
      title,
    };
    const updatedWidgets = [...state.dashboards[dashboardId].widgets];
    updatedWidgets[widgetIndex] = updatedWidget;

    return {
      ...state,
      dashboards: {
        ...state.dashboards,
        [dashboardId]: {
          ...state.dashboards[dashboardId],
          widgets: updatedWidgets,
        },
      },
    };
  }

  case UPDATE_DASHBOARD_WIDGETS: {
    const { dashboardId, widgets } = action.payload;
    return {
      ...state,
      dashboards: {
        ...state.dashboards,
        [dashboardId]: {
          ...state.dashboards[dashboardId],
          widgets,
        },
      },
    };
  }

  default:
    return state;
  }
}
