import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import debug from 'debug';
import { useHistory } from 'react-router-dom';

import { getWidgetType } from 'src/entities/widgets';
import { createMediaObject } from 'src/entities/media';
import * as MsgActions from 'js/react/actions/message-actions';
import { useRequestWithPromise } from 'src/core/api';
import Form from './Form';

const log = debug('js:react:plan2:media-sidebar:Widgets:Create');

const propTypes = {
  onClose: PropTypes.func.isRequired,
  widgetType: PropTypes.string.isRequired,
};

const CUSTOM_FORM_FIELDS = {
  widget_rss: ['url'],
  widget_weblink: ['url'],
  widget_html: [],
};

const REQUIRED_FIELDS = {
  widget_rss: ['name', 'length', 'url', 'theme'],
  widget_weblink: ['name', 'length', 'url'],
  widget_html: ['name', 'length', 'file', 'filename'],
};

// this component assumes widgetTypes are fetched in a parent, currently in
// media-lib
export const CreateMedia = ({ onClose: handleClose, widgetType }) => {
  const { dispatch, pending: loading } = useRequestWithPromise();
  const widgetTypeSpecification = useSelector((state) => getWidgetType(state, widgetType));
  const history = useHistory();

  const handleSubmit = (formData) => {
    log('handleSubmit');

    dispatch(createMediaObject(formData))
      .then((action) => {
        MsgActions.success(`Saved widget: ${action.payload.name}`);
        history.push(`/plan/media/${action.payload.id}`);
      })
      .catch(({ payload }) => {
        MsgActions.error(Object.values(payload || {}).join('.\n '));
      });
  };

  log('render widgetTypeSpecification', widgetTypeSpecification);

  if (!widgetTypeSpecification) {
    return null;
  }

  return (
    <div className="holder bg-white p-3">
      {handleClose && (
        <button
          type="button"
          aria-label="Close"
          className="close d-none d-sm-inline-block"
          onClick={handleClose}
        >
          <span aria-hidden="true">
            {'\u00D7'}
          </span>
        </button>
      )}
      <h3>
        <FormattedMessage id="views.planning.media.createMedia" />
      </h3>
      <Form
        loading={loading}
        onSubmit={handleSubmit}
        formKeys={CUSTOM_FORM_FIELDS[widgetTypeSpecification.type]}
        requiredKeys={REQUIRED_FIELDS[widgetTypeSpecification.type]}
        widgetTypeSpecification={widgetTypeSpecification}
      />
    </div>
  );
};

CreateMedia.propTypes = propTypes;
