import moment from 'moment';

export const UNORDERED_THRESHOLD = 10000;
export const fmtDate = (d) => moment(d).format('YYYY-MM-DDTHH:mm:ss');

const setLimit = (key, value) => {
  return value
    ? { [key]: value }
    : {};
};

export const applyTimeLimits = (planning, start, end, min, max, resetOrder = 0) => {
  const oldStart = planning.start;
  const oldEnd = planning.end;
  const isInsideBounds = (lower, upper) => {
    return lower >= min && upper <= max;
  };

  const wasInside = isInsideBounds(oldStart, oldEnd);
  const isInside = isInsideBounds(start, end);
  let order = planning.order || 1;

  if (isInside && !wasInside && order > UNORDERED_THRESHOLD) {
    order = resetOrder + 1;
  } if (!isInside && wasInside && order < UNORDERED_THRESHOLD) {
    order += UNORDERED_THRESHOLD;
  }

  return {
    ...planning,
    ...setLimit('time_limit_lower', fmtDate(start)),
    ...setLimit('time_limit_upper', fmtDate(end)),
    order,
  };
};
