import React from 'react';
import { FormattedMessage } from 'react-intl';

export const URICallback = (
  {
    id,
    title,
    mediaObject,
  },
) => {
  const state = mediaObject?.state;

  return (
    <div
      id={id}
      className="form-group"
      data-testid="customWidgetURICallback"
    >
      <label htmlFor={id}>
        {title}
      </label>
      <div className="border rounded p-3">
        <small className="form-text text-muted">
          <FormattedMessage id={state && state !== 'pending'
            ? 'views.planning.media.isAuthorized'
            : 'views.planning.media.saveToAuthorize'}
          />
        </small>
      </div>
    </div>
  );
};
