define({
  root: {
    // Geosignage terms:

    mediaobjects: 'Media objects',
    playlists: 'Playlists',
    show_playlists: 'Show playlists',
    'Show all places': 'Show all places',
    'Only show connected places': 'Only show connecpted places',
    add_to_more_playlists: 'Add to more playlists',
    triggers: 'Triggers',
    demo: 'Demo',
    plan: 'Plan',
    planning: 'Planning',
    places: 'Places',
    placeobjects: 'Place objects',
    realtime_messages: 'Real time messages',
    history: 'History',
    busstations: 'Busstations',
    place_bus_station: 'Place Bus station',
    busslines: 'Busslines',
    administration: 'Administration',
    help_module: 'Help',
    admin: 'Admin',

    plan_module: 'Plan module',

    edit_mediaobjects: 'Edit media objects',

    // UI:

    ok: 'OK',
    cancel: 'Cancel',
    save: 'Save',
    Close: 'Close',
    delete: 'Delete',
    Reset: 'Reset',
    add: 'Add',
    'Add from list': 'Add from list',
    'Hide list': 'Hide list',
    'Quick add': 'Quick add',
    name: 'Name',
    short_name: 'Short name',
    edit: 'Edit',
    remove: 'Remove',
    new_: 'New',
    search: 'Search',
    show_all: 'Show all',
    next: 'Next',
    Previous: 'Previous',
    pick: 'Pick',
    'Log out': 'Log out',
    Unlock: 'Unlock',

    time: 'Time',
    type: 'Type',
    id: 'ID',
    play: 'Play',
    play_type: 'Play type',
    play_time: 'Play Time',
    'Available time': 'Available time',
    uploaded: 'Uploaded',
    upload_file: 'Upload',
    description: 'Description',
    show: 'Show',
    Category: 'Category',
    visibility: 'Visibility',
    'Time templates': 'Time templates',
    Template: 'Template',

    playlist_type: 'Playlist type',
    media_type: 'Media type',

    Today: 'Today',
    Week: 'Week',
    this_week: 'This week',
    next_week: 'Next week',
    prev_week: 'Previous week',

    fullDateTimeFormat: 'mmm d "at" H:MM',
    monthAndDateFormat: 'mmmm dd',
    shortDateFormat: 'ddd d/mm',
    weekDateFormat: 'G "Week" V',

    shortDateFormat: 'ddd d/m yyyy',
    shortWeekDateFormat: '"v." V G',
    tinyDateFormat: 'ddd d/m',
    tinyDateTimeFormat: 'ddd d/m HH:MM',
    shortWeekDateFormat: '"v." V G',
    datebrowse_nextmonth: 'Next month',
    datebrowse_nextweek: 'Next week',
    datebrowse_prevmonth: 'Previous month',
    datebrowse_prevweek: 'Previous week',
    datebrowse_thismonth: 'This month',

    position: 'Position',
    longitude: 'Longitude',
    lattitude: 'Lattitude',
    update_filter: 'Update filters',

    toolbar: 'Toolbar',
    show_triggers: 'Show triggers',

    exceptions: 'Exceptions',
    general: 'General',
    nothing_selected: 'Nothing selected',

    Filter: 'Filter',
    Start: 'Start',
    End: 'End',
    Remove: 'Remove',
    'Remove selected': 'Remove selected',
    Preview: 'Preview',
    Settings: 'Settings',

    Repeating: 'Repeating',
    Direct: 'Direct',
    repeating: 'Repeating',
    direct: 'Direct',

    // Objects & Parameters:

    mediaobject: 'Media object',
    edit_mediaobject: 'Edit media object',
    media: 'Media',
    time_interval: 'Time interval',
    time_interval_start: 'Start time',
    time_interval_end: 'End time',
    time_settings: 'Time settings',
    max_time: 'Length',
    date_interval_start: 'Start date',
    date_interval_end: 'End date',
    'Pick date': 'Pick date',
    image: 'Image',
    film: 'Film',
    sound: 'Sound',
    widget: 'Widget',
    widget_type: 'Widget type',
    line: 'Line',
    busID: 'Bus ID',
    line: 'Line',
    select_lines: 'Select lines',
    direction: 'Direction',
    select_directions: 'Select directions',
    bus: 'Bus',
    station: 'Station',
    message: 'Message',
    text: 'Text',
    minute: 'minute',
    headline: 'Headline',

    trigger: 'Trigger',
    edit_trigger: 'Edit trigger',
    trigger_type: 'Trigger type',
    'Trigger when': 'Trigger when',
    zone: 'Zone',
    zones: 'Zones',
    components: 'Components',
    change_zone: 'Change zone',

    lines_and_routes: 'Lines & Routes',

    route: 'Route',
    edit_route: 'Edit route',
    add_route: 'Add route',

    busline: 'Bus line',
    edit_busline: 'Edit busline',
    add_bussline: 'Add busline',

    busstation: 'Busstation',
    edit_busstation: 'Edit busstation',
    add_busstation: 'Add busstation',
    add_station: 'Add station',

    delete_this_place: 'Delete this place',
    place_point_to_create: 'Place the point where you want to create an area.',

    object: 'Object',
    edit_object: 'Edit object',
    object_saved: 'Object saved.',
    object_save_failed: "Couldn't save object.",

    connected_zones: 'Connected zones',
    connected_triggers: 'Connected triggers',
    connected_playlists: 'Connected triggers',
    connectedMedia: 'Connected triggers',

    selectable_triggers: 'Selectable triggers',
    selectable_mediaobjects: 'Selectable media objects',
    selectable_mediaobjects: 'Selectable playlists',

    region: 'Region',
    draw_region: 'Draw region',
    save_new_region: 'Save new region',

    new_route: 'New Route',
    create_new_line: 'Create new line',
    new_line_name_: 'New line(name)',

    tv4_news: 'TV4 News',
    fullscreen: 'Fullscreen',
    strip: 'Strip',

    times_played: 'Times played',
    display_time: 'Display time',
    show_where: 'Show where',
    show_until: 'Show until',

    active_until: 'Active until',

    // Trigger types

    before_station: 'Before station',
    leaving_and_stopped: 'Leaving and stopped',
    heading_for: 'Heading for',
    in_area: 'In area',
    enter_area: 'Enter area',

    // Playlists

    'Added new playlist': 'Added new playlist',
    no_places_connected: 'No places connected to playlist yet.',
    no_places_connected_expl:
      'Connect the playlist to any places you wish the media to be played in.',
    Connect: 'Connect',
    'selected place to playlist': 'selected place to playlist',
    'selected places to playlist': 'selected places to playlist',
    'Check the places you wish to add below':
      'Check the places you wish to add below',

    'selected media from playlist': 'selected media from playlist',
    'Empty entire list.': 'Empty entire list.',

    // Media
    'Add to': 'Add to',
    'checked playlists': 'checked playlists',

    // Playlist types:
    default: 'Default',
    news: 'News',
    ads: 'Advertisements',
    info: 'Information',
    other: 'Other',
    filler: 'Filler',
    'default-type': 'Default',
    'Sub-type': 'Subtype',
    area: 'Area',
    Stop: 'Stop',
    stop: 'Stop',

    // Empty list rows

    noFilterResult: 'No matches found!',

    no_media_added: "You haven't added any media objects yet!",
    add_some_media:
      'Click on the button "Upload Media" above to upload a media object.',

    no_playlists_added: "You haven't created any playlists yet!",
    add_some_playlists:
      'Click on the button "Add Playlist" above to create a new playlist.',
    no_planning_lists:
      'You either picked a date outside the current planning interval, or have not created any playlists yet.',
    no_playlist_templates:
      'No available playlists for the current date interval!',
    no_media_added_to_template:
      'No media objects connected to this playlist yet.',
    'You need to unlock to edit': 'Current time templates are locked',

    no_places_added: 'No places added',
    add_some_places: 'Create a new place by clicking the "Add"-button above.',

    no_history: 'No history data matched.',
    no_history_expl:
      'No history data matched the current filters. Adjust the filters above to load history data.',
    // Descriptions:

    list_of_triggers: 'List of triggers',
    add_to_more_playlists: 'Add to more playlists',
    long_lat_help:
      'Click anywhere in the map and the longitude and latitude will be automatically filled in.',

    create_region_instruction:
      'To create a new region enter a name and draw the region in the map. Finish by cklicking "Save new region".',
    playlist_contains:
      'The playlist contains the following media objects, which are played during the assigned time slots.',
    playlist_contains_triggers:
      'The playlist is connected to the following triggers, which decide how and where the playlist should be activated.',
    playlist_connected_triggers:
      'The playlist is connected to the following triggers:',
    mediaobject_connected_playlists:
      'The media object is connected to the following playlists: ',

    save_before_edit: 'You have to save the model before making changes here.',

    // Help section

    what_do_in_view: 'What do you do in this view?',
    mediaobjects_help:
      'The Media Library manages all information, which can be shown on the displays. ' +
      'The information is presented in the form of media objects. A media object can be a video, image, sound or a widget.',
    mediaobjects_do_in_view:
      'In this view, you can add, upload and remove media objects, edit their properties and attach them to playlists.',

    playlists_help:
      'A playlist is quite simply a list of media objects. For a media object to be played it has to be included in a playlist. How and when a playlist is played is decided by triggers. A playlist can be connected to muliple triggers, you cold for example have a playlist that is played at every station.',
    playlists_do_in_view:
      'In this view you can create, delete and edit playlists. You can also add media objects and schedule playing and also connect the playlist to triggers.',

    triggers_help:
      'A trigger is what decides how and when a playlist should be played.' +
      'A trigger is connected to one ore more geographic zones. There are thre different trigger types that trigger playlists differently:' +
      +'<ul>' +
      '<li>Approaching [zone] - Triggers connected playlists when the unit approaches a zone.</li>' +
      '<li>Leaves [zon] - Triggers connected playlists when the unit laves a zone.</li>' +
      '<li>Is inside [zon] - Connected playlists are continuously playing while the unit is inside the zone.</li>' +
      '</ul>',

    triggers_do_in_view:
      'In this view you can create, delete and edit triggers. You can also add playlists and edit connected zones.',

    places_help: 'This is the place view. OMG.',

    priority: 'Priority',
    normal: 'Normal',
    high: 'High',
  },
  sv: true,
});
