import React, { memo, useContext, useMemo } from 'react';
import { string, arrayOf } from 'prop-types';
import { fromJS, Set } from 'immutable';

import cancan from 'js/system/cancan';
import FeatureMap from 'js/react/map/map';
import { identity, sortBy } from 'lodash';
import {
  useMapData,
  formatWktFeature,
  sortGeometries,
  isEditable,
} from '../helpers/buildMapData';
import { PlaceMapContext } from './PlacesStateManager';
import { PLACE_TYPES } from './constants';

export const PlacesMap = memo(({ placeToEditId, visibleIds }) => {
  const {
    placeToCreate,
    setPlaceToCreate,
    placeToSave,
    setPlace,
    highlight,
    geometryIndex,
  } = useContext(PlaceMapContext);
  const mapData = useMapData(visibleIds, placeToEditId);

  const editFeatures = useMemo(
    () =>
      placeToSave
        ? fromJS(
            [formatWktFeature(placeToSave, geometryIndex)].filter(identity)
          ).toSet()
        : Set(),
    [placeToSave, geometryIndex]
  );

  const getPlaceToCreate = () => {
    return placeToCreate?.wkt1?.length
      ? mapData.push(fromJS({ id: -1, wkt: placeToCreate.wkt1 }))
      : mapData;
  };

  const handlePolyChange = (wkt) => {
    const type = placeToSave?.type?.toLowerCase();

    if (!isEditable(type)) {
      return;
    }

    if (type === PLACE_TYPES.LINE) {
      setPlace({
        ...placeToSave,
        geometry: {
          ...(placeToSave.geometry || {}),
          wkt,
        },
      });
    } else {
      const geometry = sortBy(placeToSave.geometry, sortGeometries);
      geometry[geometryIndex] = {
        ...(geometry[geometryIndex] || {}),
        wkt,
      };
      setPlace({
        ...placeToSave,
        geometry,
      });
    }
  };

  const handlePointSelected = ({ wkt, wkt1 }) => {
    setPlaceToCreate({
      ...placeToCreate,
      wkt,
      wkt1,
    });
  };

  return (
    <FeatureMap
      center={cancan.getSession().home_location}
      features={placeToCreate ? getPlaceToCreate() : mapData}
      highlight={(highlight !== placeToEditId && highlight) || -1}
      editFeatures={editFeatures}
      selectPointEnabled={placeToCreate?.type || null}
      onPolyChange={handlePolyChange}
      onPointSelected={handlePointSelected}
    />
  );
});

PlacesMap.propTypes = {
  placeToEditId: string,
  visibleIds: arrayOf(string),
};
