import React, { createContext, useState } from 'react';
import { node } from 'prop-types';

export const PlaceMapContext = createContext();

export const PlacesStateManager = ({ children }) => {
  const [highlight, setHighlight] = useState();
  const [geometryIndex, setGeometryIndex] = useState(0);
  const [placeToSave, setPlace] = useState();
  const [placeToCreate, setPlaceToCreate] = useState();
  const placeToCreateRef = React.useRef(placeToCreate);

  React.useEffect(() => {
    placeToCreateRef.current = placeToCreate;
  }, [placeToCreate]);

  return (
    <PlaceMapContext.Provider
      value={{
        highlight,
        setHighlight,
        geometryIndex,
        setGeometryIndex,
        placeToSave,
        setPlace,
        placeToCreate,
        placeToCreateRef,
        setPlaceToCreate,
      }}
    >
      {children}
    </PlaceMapContext.Provider>
  );
};

PlacesStateManager.propTypes = {
  children: node,
};
