import React from 'react';
import './style.css';

export default function LoadingSpinner({ diameter = '2rem', inverted = false, wrapperClassNames = '' }) {
  return (
    <div
      className={`w100p d-flex justify-content-center ${wrapperClassNames}`}
    >
      <div
        className={inverted ? 'lds-dual-ring inverted' : 'lds-dual-ring'}
        style={{
          width: diameter,
          height: diameter,
        }}
      />
    </div>
  );
}
