import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';
import { intlShape } from 'utils/shapes';
import Store from 'js/stores/ajax-store';
import storeListener from 'js/stores/store-listener';
import debug from 'debug';

import stringUtils from 'js/react/_utils/stringUtils';
import FileUploadButton from 'js/react/FileUploadBtn';
import computeFormProps from './computeFormProps';
import FormField from './FormField';

const ThemeStore = Store('/media/themes/');

const log = debug('js:react:plan2:media-sidebar:Widgets:Form');

const themeBoxStyle = {
  width: 140,
  height: 74,
  boxShadow: '0 1px 4px 0 rgba(25, 29, 34, 0.1)',
  textAlign: 'center',
  background: '#333',
  display: 'inline-block',
  verticalAlign: 'top',
  transition: 'opacity 0.15s ease-out',
  border: '3px solid #fff',
  cursor: 'pointer',
  marginRight: 10,
  color: 'rgba(25, 29, 34, 0.54)',
  transform: 'scale(1)',
  marginBottom: 10,
  opacity: 0.7,
};

const selectedThemeBoxStyle = {
  ...themeBoxStyle,
  cursor: 'normal',
  border: '3px solid #333',
  opacity: 1,
};

const themeNameInputStyle = {
  marginRight: 10,
  top: 3,
};

const themeGradientStyle = {
  background: 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.6))',
  height: 40,
  position: 'absolute',
  bottom: 0,
  left: 3,
  width: 'calc(100% - 6px)',
};

const themeNameStyle = {
  position: 'absolute',
  textShadow: '0px 1px 1px #000',
  color: '#fff',
  fontWeight: 'bold',
  fontSize: 11,
  bottom: 5,
  left: 10,
};

const propTypes = {
  loading: PropTypes.bool,
  configKeys: PropTypes.arrayOf(PropTypes.string.isRequired),
  requiredKeys: PropTypes.arrayOf(PropTypes.string.isRequired),
  initialValues: PropTypes.object,
  intl: intlShape.isRequired,
  mediaObject: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  widgetTypeSpecification: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
};
const defaultProps = {
  baseResource: undefined,
  configKeys: [],
  initialValues: undefined,
  mediaObject: undefined,
  requiredKeys: [],
};

class Form extends Component {
  constructor(props) {
    super(props);

    this.state = this.buildInitialState(props);
  }

  componentDidMount() {
    if (this.props.widgetTypeSpecification.type === 'widget_rss') {
      this.loadData();
      storeListener.listenTo([ThemeStore], 'add change.* destroy.*', this.loadData);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.initialValues && nextProps.initialValues) {
      this.setState((prevState) => {
        return {
          ...this.buildInitialState(nextProps),
          ...prevState,
        };
      });
    }
  }

  loadData = () => {
    ThemeStore.fetch().then((themes) => {
      this.setState({
        themes: themes.toJS(),
      });
    });
  };

  buildInitialState = ({ configKeys, initialValues } = {}) => {
    const base = {
      errors: {},
      file: null,
      themes: [],
      themeName: '',
      length: 10,
      name: '',
    };

    const configKeysEmptyValues = configKeys.reduce((acc, key) => {
      acc[key] = '';
      return acc;
    }, {});

    return {
      ...base,
      ...configKeysEmptyValues,
      ...initialValues,
    };
  };

  getFormData = () => {
    const { requiredKeys, mediaObject, widgetTypeSpecification } = this.props;
    const data = requiredKeys.reduce((configValues, key) => {
      return {
        ...configValues,
        [key]: this.state[key],
      };
    }, mediaObject && mediaObject.config ? { ...mediaObject.config } : {});

    return {
      ...(mediaObject || {}),
      ...data,
      config: data,
      media_type: widgetTypeSpecification.type,
    };
  };

  handleChange = (key, value) => {
    this.validateFieldData(key, value);
    this.setState({
      [key]: value,
    });
  };

  handleSave = () => {
    const formErrors = this.validateFormData();
    if (!formErrors) {
      const formData = this.getFormData();
      this.props.onSubmit(formData);
    }
  };

  validateFieldData = (key, value) => {
    const { requiredKeys } = this.props;
    log('validateFieldData', key, value, requiredKeys);

    if (key === 'length') {
      if (!value || value <= 0) {
        return this.setState((prevState) => {
          return {
            errors: {
              ...prevState.errors,
              length: true,
            },
          };
        });
      }
    }

    if (key === 'name' || (requiredKeys && requiredKeys.indexOf(key) > -1)) {
      if (!value) {
        return this.setState((prevState) => {
          return {
            errors: {
              ...prevState.errors,
              [key]: true,
            },
          };
        });
      }
    }

    return this.setState((prevState) => {
      delete prevState.errors[key];
      return {
        errors: prevState.errors,
      };
    });
  };

  onThemeUploadComplete = async (data) => {
    if (!data.success) {
      console.error('Failed');
      return;
    }

    try {
      const response = await ThemeStore.create({
        filename: data.files[0].path,
        file: data.files[0].name,
        name: this.state.themeName || data.files[0].name,
      });

      this.loadData();
    } catch (e) {
      console.error(e);
    }
  };

  onHTMLFileUploadComplete = (data) => {
    if (!data.success) {
      console.error('Failed', data);
      return;
    }

    this.setState(
      {
        filename: data.files[0].path,
        file: data.files[0].name,
      },
      () => {
        this.validateFormData();
      },
    );
  };

  onThemeNameChange = (e) => {
    this.setState({
      themeName: e.target.value,
    });
  };

  validateFormData = () => {
    log('validateFormData');
    // NOTE: returns errors or null if no errors
    const { length, name } = this.state;

    const errors = {};

    if (!length || length <= 0) {
      errors.length = true;
    }

    if (!name) {
      errors.name = true;
    }

    this.props.requiredKeys.forEach((key) => {
      if (!this.state[key]) {
        errors[key] = true;
      }
    });

    if (Object.keys(errors).length) {
      this.setState({ errors });
      return errors;
    }

    this.setState({ errors: {} });
    return null;
  };

  render() {
    log('render', this.state);
    const {
      loading,
      formKeys,
      mediaObject,
      intl: { formatMessage },
      widgetTypeSpecification,
    } = this.props;
    const {
      errors, name, length, themes, themeName,
    } = this.state;
    const playTime = formatMessage({ id: 'common.playTime' });
    const secondsAbb = formatMessage({ id: 'common.secondsAbbreviation' });
    const title = `${playTime} (${secondsAbb})`;

    return (
      <div>
        <table className="settings mt-3 mb-3 w-75">
          <tbody>
            <tr>
              <td>
                <small>
                  <FormattedMessage id="common.type" />
                  {': '}
                </small>
              </td>
              <td>
                <b>{widgetTypeSpecification.name}</b>
              </td>
            </tr>
            <FormField
              hasError={errors.name}
              key="name"
              name="name"
              onChange={(event) => this.handleChange('name', event.target.value)}
              title={<FormattedMessage id="common.name" />}
              value={name}
            />
            <FormField
              hasError={errors.length}
              key="length"
              name="name"
              onChange={(event) => this.handleChange('length', Number(event.target.value))}
              title={title}
              type="number"
              value={length}
            />

            {(formKeys || []).map((key) => {
              const translationKey = `common.${key}`;
              const attemptedTranslation = formatMessage({
                id: translationKey,
              });

              return (
                <FormField
                  hasError={errors[key]}
                  key={key}
                  loading={Boolean(mediaObject)}
                  name={key}
                  onChange={(event) => this.handleChange(key, event.target.value)}
                  title={
                    attemptedTranslation !== translationKey
                      ? attemptedTranslation
                      : stringUtils.capitalize(key)
                  }
                  value={this.state[key]}
                />
              );
            })}
            {mediaObject && (
              <tr>
                <td>
                  <small>
                    <FormattedMessage id="common.created" />
                    :
                  </small>
                </td>
                <td>
                  <b>{moment(mediaObject.upload_date).format('YYYY-MM-DD HH:mm')}</b>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {widgetTypeSpecification.type === 'widget_rss' && (
          <div className="clearfix">
            <h5>
              <FormattedMessage id="common.theme" />
            </h5>

            {themes.map((theme) => (
              <div
                key={theme.id}
                onClick={() => this.handleChange('theme', theme.name)}
                style={this.state.theme === theme.name ? selectedThemeBoxStyle : themeBoxStyle}
              >
                <img src={theme.thumb} />
                <div style={themeGradientStyle} />

                <span style={themeNameStyle}>{theme.name}</span>
              </div>
            ))}
          </div>
        )}

        {widgetTypeSpecification.type === 'widget_html' &&
          !mediaObject && (
          <div className="mb-3">
            <FileUploadButton
              onUploadComplete={this.onHTMLFileUploadComplete}
              fileUploadStarted={this.onFileUpload}
              fileUploadFailed={this.onFileUploadFailed}
              type="file"
              id="media-html-uploader"
            />

            <span>{this.state.file && this.state.file}</span>
          </div>
        )}

        <div className="row no-gutters">
          <div className="mr-auto">
            <button
              type="button"
              className="btn btn-primary mt-2"
              onClick={this.handleSave}
              disabled={!!Object.keys(errors).length || loading}
            >
              <FormattedMessage id={loading ? 'common.loading' : 'common.save'} />
            </button>
          </div>
          {widgetTypeSpecification.type === 'widget_rss' && (
            <div className="form-inline">
              <input
                style={themeNameInputStyle}
                type="text"
                onChange={this.onThemeNameChange}
                value={themeName}
                placeholder="Theme name..."
                className="form-control mr-2"
              />
              <FileUploadButton
                onUploadComplete={this.onThemeUploadComplete}
                type="file"
                id="media-theme-uploader"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

export default compose(injectIntl, computeFormProps)(Form);
