import React from 'react';
import PropTypes from 'prop-types';
import { defaultMemoize } from 'reselect';
import DropdownPosition from './DropdownPosition';

export const DropdownContentContext = React.createContext();

const propTypes = {
  open: PropTypes.bool,
  right: PropTypes.bool,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
};

const TRANSITION = 400;

class DropdownContent extends React.PureComponent {
  getApi = defaultMemoize(() => {
    return {
      setChildren: this.setChildren,
      activateContent: this.setNestling,
    };
  })

  state = {
    nestled: [],
    slideDirection: 'slideLeft',
    nestledContent: {},
  }

  UNSAFE_componentWillReceiveProps(np) {
    if (!np.open && this.props.open) {
      this.setState({
        nestled: [],
      });
    }
  }

  setChildren = (contentId, children) => {
    this.setState(({ nestledContent }) => ({
      nestledContent: {
        ...nestledContent,
        [contentId]: children,
      },
    }), this.props.doReposition);
  }

  setNestling = (contentId) => {
    this.setState(({ nestled }) => ({
      nestled: [...nestled, contentId],
      slideDirection: 'slideLeft',
      hideOverflow: true,
    }), this.props.doReposition);
  }

  popNestling = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(({ nestled }) => ({
      nestled: nestled.slice(0, -1),
      slideDirection: 'slideRight',
    }), () => {
      if (!this.state.nestled.length) {
        setTimeout(() => {
          this.setState({
            hideOverflow: false,
          }, this.props.doReposition);
        }, TRANSITION);
      }

      if (this.props.doReposition) {
        this.props.doReposition();
      }
    });
  }

  render() {
    const {
      // Inherited from Dropdown:
      right,
      onClose,
      onClick,
      handleToggle,
      handleFocus,
      doReposition,

      // Custom
      children,
      className = 'dropdown-menu show pt-0',
      ...other
    } = this.props;

    const hasNestling = this.state.nestled.length > 0;
    const _className = `${className}${right ? ' dropdown-menu-right' : ''}${
      // Hide overflow when sliding in nestled popovers.
      this.state.hideOverflow ? ' overflow-hidden' : ''
    }`;

    return (
      <DropdownContentContext.Provider value={this.getApi()}>
        <div
          className={_className}
          // transitionName={this.state.slideDirection}
          // transitionEnterTimeout={TRANSITION}
          // transitionLeaveTimeout={TRANSITION}
          data-testid="dropdown-container"
        >
          <div
            key={this.state.nestled.length}
            className="mt-1"
            {...other}
            onClick={onClick}
          >
            {hasNestling && (
              <button
                type="button"
                className="dropdown-item cursorPointer"
                onClick={this.popNestling}
              >
                <i className="halflings halflings-chevron-left" /> {t.popover_go_back}
              </button>
            )}
            {hasNestling &&
            <div className="dropdown-divider" />}
            {(
              // when displaying nestled content we still render
              // all parent content without displaying it.
              // Nestled content receives updates from its parent.
              <div className="only-display-last">
                <div>{this.props.children}</div>
                {this.state.nestled.map((id) => (
                  <div key={id}>
                    {this.state.nestledContent[id]}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </DropdownContentContext.Provider>
    );
  }
}

DropdownContent.propTypes = propTypes;

export function PositionedDropdownContent(props) {
  const { open, right, dropUp } = props;
  return open && (
    <DropdownPosition
      right={right}
      dropUp={dropUp}
    >
      {(handleReposition) => (
        <DropdownContent
          {...props}
          open
          doReposition={handleReposition}
        />
      )}
    </DropdownPosition>
  );
}

export default DropdownContent;
