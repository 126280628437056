import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import cancan from 'js/system/cancan';
import { canCreateMedia } from 'constants/permissions';
import FileUploadBtn from 'js/react/FileUploadBtn';
import * as MsgActions from 'js/react/actions/message-actions';
import { createMediaObject, deleteMediaObject } from 'src/entities/media';
import { getWidgetTypes } from 'src/entities/widgets';

import { useRequestWithPromise } from 'src/core/api';
import { fetchPlanned as fetchPlannedAC } from 'src/entities/plannedMedia';
import { actions as mediaActions } from 'js/redux/media/media-actions';
import * as selectors from 'js/redux/selectors';
import Dropdown, { DropdownToggle, DropdownContent } from 'components/Dropdown';
import DeleteConfirm from 'components/DeleteConfirm';

import CreateWidgetDropdownItems from './CreateWidgetDropdownItems';

const DestroyButton = ({ selected }) => {
  const { dispatch } = useRequestWithPromise();
  const fromDate = useSelector(selectors.getFromDate);
  const toDate = useSelector(selectors.getToDate);

  const handleDestroy = () => {
    return Promise.all(selected.map((id) => {
      return dispatch(deleteMediaObject(id)).catch(({ payload }) => {
        MsgActions.error(Object.values(payload || {}).join('.\n'));
      });
    })).then(() => {
      dispatch(fetchPlannedAC(fromDate, toDate));
      dispatch(mediaActions.selectAll());
    });
  };

  return (
    <DeleteConfirm
      onDelete={handleDestroy}
      className="d-inline-block ml-1"
    />
  );
};

const Upload = () => {
  const { dispatch } = useRequestWithPromise();
  const maxUploadBytes = cancan.getSession().max_upload_bytes || 50 * 1024 * 1024;

  const handleUploadFiles = (result) => {
    if (result.success) {
      result.files.forEach((f) => {
        const mediaItem = {
          file: f.name,
          filename: f.path,
        };
        dispatch(createMediaObject(mediaItem)).then(() => {
          MsgActions.success(`Saved file: ${f.name}`);
        }, ({ payload }) => {
          payload.detail
            ? MsgActions.error(payload.detail)
            : MsgActions.error('Unknown error');
        });
      });
    } else {
      MsgActions.success('Failed to upload files');
    }
  };

  return (
    <FileUploadBtn
      onUploadComplete={handleUploadFiles}
      maxUploadytes={maxUploadBytes}
    />
  );
};

export const MediaActions = () => {
  const selectedMedia = useSelector((state) => state.media.get('selected'));
  const widgetTypeSpecifications = useSelector(getWidgetTypes);

  const permittedWidgetTypes = useMemo(() => {
    return !widgetTypeSpecifications
      ? []
      : widgetTypeSpecifications.reduce(
        (arr, { name, type, id }) => {
          if (cancan.can('read', canCreateMedia(type))) {
            return [...arr, { name, id }];
          }
          return arr;
        },
        [],
      );
  }, [widgetTypeSpecifications]);

  return (
    <>
      {selectedMedia.size > 0 && (
        <DestroyButton selected={selectedMedia} />
      )}
      <div className="ml-1">
        {!!permittedWidgetTypes.length && (
          <Dropdown closeOnClick>
            <DropdownToggle>
              <FormattedMessage id="common.create" />
            </DropdownToggle>
            <DropdownContent>
              <CreateWidgetDropdownItems
                permittedWidgetTypes={permittedWidgetTypes}
              />
            </DropdownContent>
          </Dropdown>
        )}
      </div>
      <div className="ml-1">
        <Upload />
      </div>
    </>
  );
};
