import { isFunction } from 'lodash';
import { API_DELETE_REQUEST, API_REQUEST_SUCCESS, DELETE } from '../api';

import { removeEntity, setCollection, setEntity } from './actionCreators';
import { getEntity } from './selectors';
import { API_REQUEST_ERROR } from '../api/actionTypes';

const stringifyId = (raw) => {
  return {
    ...raw,
    id: raw.id?.toString(),
  };
};

const enhanceDataFormatMiddleware = () => (next) => async (action) => {
  if (
    action.type?.includes(API_REQUEST_SUCCESS) &&
    action.meta?.httpMethod !== DELETE
  ) {
    const { payload } = action;
    const processed = isFunction(action.meta.processResponse)
      ? action.meta.processResponse(payload)
      : payload;

    if (Array.isArray(processed)) {
      return next({ ...action, payload: processed.map(stringifyId) });
    }
    return next({ ...action, payload: stringifyId(processed) });
  }

  // Older async actions may depend on the returned value.
  return next(action);
};

const setDataMiddleware = () => (next) => async (action) => {
  // Dispatch next action first to get the log sequence right.
  const value = next(action);

  if (
    action.type.includes(API_REQUEST_SUCCESS) &&
    action.meta?.httpMethod !== DELETE
  ) {
    const { payload, meta } = action;

    if (Array.isArray(payload)) {
      next(setCollection({ payload, meta }));
    } else {
      if (!payload.id && meta.entityId) {
        payload.id = meta.entityId;
      } else if (!payload.id) {
        // eslint-disable-next-line no-console
        console.error('Entity id missing in response payload', action);
      }
      next(setEntity({ payload, meta }));
    }
  }

  // Older async actions may depend on the returned value.
  return value;
};

const stash = {};

const optimisticDeleteMiddleware =
  ({ getState }) => (next) => (action) => {
    const value = next(action);

    if (action.type.includes(API_DELETE_REQUEST) && action.meta?.entityId) {
      const { collection, entityId, requestId } = action.meta;
      const entity = getEntity(getState(), { collection, entityId });

      stash[requestId] = entity;

      next(removeEntity({ meta: action.meta }));
    }

    if (
      action.type.includes(API_REQUEST_SUCCESS) &&
      action.meta?.httpMethod === DELETE
    ) {
      const { requestId } = action.meta;
      delete stash[requestId];
    }

    if (
      action.type.includes(API_REQUEST_ERROR) &&
      action.meta?.httpMethod === DELETE
    ) {
      const { requestId } = action.meta;
      const entity = stash[requestId];
      delete stash[requestId];

      next(
        setEntity({
          payload: entity,
          meta: { ...action.meta, isRevertOfOptimisticDelete: true },
        }),
      );
    }

    return value;
  };

// Add data to request payload (use for extending data with related ids
// or other things contextual values that may not be included in the api response).
// Use together with extendPayloadActionEnhancer()
const extendPayloadMiddleware = () => (next) => (action) => {
  if (action.type.includes(API_REQUEST_SUCCESS) && action.meta?.extendPayload) {
    const extendPayload = (data) => ({
      ...(data || {}),
      ...action.meta.extendPayload,
    });
    const payload = Array.isArray(action.payload)
      ? action.payload.map((pl) => extendPayload(pl))
      : extendPayload(action.payload);

    return next({
      ...action,
      payload,
    });
  }

  return next(action);
};

export const dataMiddlewares = [
  extendPayloadMiddleware,
  enhanceDataFormatMiddleware,
  optimisticDeleteMiddleware,
  setDataMiddleware,
];
