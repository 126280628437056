import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { actions as mediaActions } from 'js/redux/media/media-actions';
import { sortWrapper } from 'utils/sort-util';
import CheckboxWrapper from 'utils/checkbox-wrapper';
import PaginatedList from 'components/PaginatedList';

import MediaRow from './row';
import ListHeader from './list-header';
import CustomDrag from './custom-drag';

function ListTable(props) {
  return (
    <table className="table table-hover table-responsive-xl rounded mb-0">
      <ListHeader
        asc={props.asc}
        sortBy={props.sortBy}
        onClick={props.handleSort}
        selectAll={props.handleSelectAll}
        allSelected={props.allSelected}
      />
      <tbody key="list">
        {props.children}
      </tbody>
    </table>
  );
}

class MediaListView extends CheckboxWrapper {
  constructor(props) {
    super(props);
    this.state = {
      cap: 15,
      allSelected: this.allSelected(props.items, props.selected),
    };
  }

  UNSAFE_componentWillReceiveProps({ items, selected }) {
    const sel = this.props.selected;
    if (items !== this.props.items || selected !== sel) {
      this.setState({
        allSelected: this.allSelected(items, selected),
      });
    }

    if (
      this.props.hasSearch &&
      ((selected && selected.size) || 0) > ((sel && sel.size) || 0)
    ) {
      // Reset cap if selecting while searching.
      this.handleResetCap();
    }
  }

  handleResetCap = () => this.setState({ cap: -1 });

  handleSelectAll = (e) => {
    this.selectAll(this.props.items)(e);
    this.handleResetCap(); // Show all media when selecting all.
  };

  renderRows() {
    const { items, groupsById } = this.props;

    return items.map((media) => {
      const id = media.get('id');
      return (
        <MediaRow
          key={id}
          mediaId={id}
          groupsById={groupsById}
          allMedia={items}
          onSelect={this.toggleSelect(id, items)}
          selected={this.props.selected}
        />
      );
    }).toList();
  }

  render() {
    return (
      <div className="table_container" data-testid="MediaList">
        <CustomDrag />
        <PaginatedList
          className="table_container"
          pagerClass="m-2"
          pageSize={this.state.cap}
          Component={ListTable}
          asc={this.props.asc}
          sortBy={this.props.sortBy}
          handleSort={this.props.handleSort}
          handleSelectAll={this.handleSelectAll}
          allSelected={this.state.allSelected}
        >
          {this.renderRows()}
        </PaginatedList>
      </div>
    );
  }
}

const mapState = () => {
  return (state) => {
    return {
      selected: state.media.get('selected'),
    };
  };
};

const mapDispatch = {
  handleSelect: mediaActions.select,
  handleDeselect: mediaActions.deselect,
  selectAll: mediaActions.selectAll,
};

export default compose(
  connect(
    mapState,
    mapDispatch,
  ),
  sortWrapper,
)(MediaListView);
