import React from 'react';
import { defaultMemoize } from 'reselect';
import { Link } from 'react-router-dom';

/*

  GS-SIDEBAR

  Sidebar that acts like a modal, sliding in from the right.
  The sidebar activates automatically when it has content.

  USE:

  <GsSideBar href="#">
    <div> Content! </div>
  </GsSideBar>

  API:
  props.href    = Optional path to route to on close.
  props.onClose   = Optional callback
  props.children  = Child elements or undefined. If undefined the sidebar will close automatically.
*/

class RightSidebar extends React.Component {
  static defaultProps = {
    href: window.location.hash,
    onClose: () => {},
    closeOnEsc: true,
    style: {},
  }

  isOpen = defaultMemoize((children) => {
    const c = children && React.Children.toArray(children).filter((o) => o);
    return c && !!c.length;
  })

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  onKeyDown = (e) => {
    const fromModal = e.srcElement && e.srcElement.className && e.srcElement.className.includes('ReactModal');
    if (!fromModal && this.props.closeOnEsc && e.keyCode === 27 && this.props.onClose) {
      this.props.onClose();
    }
  }

  blocker(open) {
    return (
      <Link
        to={this.props.href}
        onClick={this.props.onClose}
        className={`blocker${open ? ' blocked' : ''}`}
        data-testid="close-sidebar-blocker"
      >
        <span className="d-none">
          Close sidebar
        </span>
      </Link>
    );
  }

  render() {
    const open = this.isOpen(this.props.children);
    const style = {
      ...this.props.style,
      transform: `translateX(${(open ? 0 : 550)}px)`,
      maxWidth: '100vw',
    };

    if (!open) {
      style.width = 0;
    }

    return (
      <div
        className={`row no-gutters position-absolute${open ? ' w-100 h-100' : ''}`}
        style={{ top: 0 }}
      >
        <div className="col overflow-hidden">
          { this.blocker(open) }
          <div
            className={`gs-sidebar${open ? ' border-left bg-white' : ''}`}
            style={style}
            data-testid="RightSidebar"
          >
            { this.props.children }
          </div>
        </div>
      </div>
    );
  }
}

export default RightSidebar;
