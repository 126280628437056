import { REDUCER_NAME } from './constants';

const emptyObject = Object.freeze({});

export const getCollection = (state, { collection }) => {
  return state[REDUCER_NAME][collection] || emptyObject;
};

export const getEntity = (state, { collection, entityId }) => {
  return state[REDUCER_NAME][collection]?.[entityId];
};
