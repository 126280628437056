import Immutable from 'immutable';
import {
  getHighestOrder,
  getHighestGroupOrder,
  bulkPostPlannings,
  getPlanned,
} from 'src/entities/plannedMedia';
import * as types from './constants';


export function unlock() {
  return {
    type: types.UNLOCK,
  };
}

export function setDate(date) {
  return {
    type: types.SETDATE,
    payload: date,
  };
}

export function nextInterval() {
  return {
    type: types.NEXTINTERVAL,
  };
}

export function previousInterval() {
  return {
    type: types.PREVINTERVAL,
  };
}

export function copyByIds(ids) {
  return (dispatch, getState) => {
    const state = getState();
    const plannings = getPlanned(state);
    const plannedById = plannings && plannings.reduce((acc, p) => {
      acc[p.id] = p;
      return acc;
    }, {});
    const toCopy = plannedById && ids.map((id) => plannedById[id]).toArray();

    return dispatch({
      type: types.COPY,
      payload: Immutable.fromJS(toCopy),
    });
  };
}

export function pastePlannings(toPaste, playlistId) {
  return (dispatch, getState) => {
    const state = getState();
    const highestOrder = (getHighestOrder(state, playlistId) + 1) || 1;
    const highestGroupOrder = getHighestGroupOrder(getState(), playlistId) + 1;
    const data = toPaste.sortBy((p) => p.get('order')).map((p, i) => {
      const bg = p.get('block_group') || 0;
      return p
        .set('order', highestOrder + i)
        .set('block_group', bg > 0 ? bg + highestGroupOrder : 0);
    });

    dispatch(bulkPostPlannings(data));
  };
}

export function setTimeLimitFilter(payload) {
  return {
    type: types.TIME_LIMIT_FILTER,
    payload,
  };
}
