import {
  API_REQUEST_ERROR,
  API_REQUEST_SUCCESS,
  REMOVE_ONGOING,
  SET_ONGOING,
} from './actionTypes';

export const apiSuccess = ({ payload, meta }) => {
  return {
    type: `[${meta.collection}] ${API_REQUEST_SUCCESS}`,
    payload,
    meta,
  };
};

export const apiError = ({ payload, meta }) => {
  return {
    type: `[${meta.collection}] ${API_REQUEST_ERROR}`,
    error: true,
    payload,
    meta,
  };
};

export const setOngoingRequest = ({ requestId, url }) => {
  return {
    type: SET_ONGOING,
    payload: { requestId, url },
  };
};

export const removeOngoingRequest = ({ requestId }) => {
  return {
    type: REMOVE_ONGOING,
    payload: requestId,
  };
};
