export function getPrintableErrorMessage(potentialError) {
  if (typeof potentialError === 'string') {
    return potentialError; 
  } 
  
  if (typeof potentialError === 'object' && potentialError !== null) {
    if (potentialError?.code && potentialError?.status) {
      return `${potentialError.code}: ${potentialError.status}`;
    }

    return JSON.stringify(potentialError, null, 2);
  }
    
  if (potentialError?.toString) {
    return potentialError.toString();
  }

  return `An unknown error happened: ${potentialError}`
}