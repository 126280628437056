import Immutable from 'immutable';
import React from 'react';
import PropTypes from 'prop-types';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { fetchClientGroups, getClientGroupsInArray, updateClientGroup } from 'src/entities/clientGroups';
import { FormattedMessage } from 'react-intl';


const propTypes = {
  schemeId: PropTypes.number,
};

class ClientManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientGroups: null,
      selected: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    this.props.fetchClientGroups().then(this.dataLoaded);
  }

  updateClients = () => {
    const currentScheme = this.props.schemeId;
    const groups = this.props.clientGroups;

    const toSave =
      groups &&
      groups.reduce((acc, group) => {
        const id = group.get('id');
        const schemeId = group.get('playlist_scheme_id');
        const wasSelected = schemeId === currentScheme;
        const isSelected = this.state.selected.indexOf(id) !== -1;

        if (isSelected && !wasSelected) {
          return acc.push(group.set('playlist_scheme_id', currentScheme));
        } if (!isSelected && wasSelected) {
          return acc.push(group.set('playlist_scheme_id', null));
        }

        return acc;
      }, Immutable.List());

    return toSave && toSave.toJS().map(this.props.updateClientGroup);
  };

  handleSelect = (selected) => this.setState({ selected }, this.updateClients);

  dataLoaded = () => {
    const { schemeId } = this.props;
    this.setState({
      selected: this.props.clientGroups
        .filter((g) => g.get('playlist_scheme_id') === schemeId)
        .map((g) => g.get('id'))
        .toArray(),
    });
  };

  addClientGroup = (id) => {
    this.handleSelect([...this.state.selected, id]);
  };

  removeClientGroup = (id) => {
    const groups = this.state.selected.filter((gid) => gid !== id);

    this.handleSelect(groups);
  };

  getDisabled() {
    const { schemeId } = this.props;
    return (
      this.props.clientGroups &&
      this.props.clientGroups
        .filter((g) => {
          const sId = g.get('playlist_scheme_id');
          return sId && sId !== schemeId;
        })
        .map((g) => g.get('id'))
        .toArray()
    );
  }

  render() {
    const disabled = this.getDisabled();

    const groups = this.props.clientGroups
      ? this.props.clientGroups
        .toJS()
        .sort((a, b) => (a.name > b.name ? 1 : -1))
      : [];

    const selectedGroups = groups.filter((group) => this.state.selected.includes(group.id));
    const disabledGroups = groups.filter((group) => disabled.includes(group.id));
    const availableGroups = groups.filter(
      (group) => !disabled.includes(group.id) && !this.state.selected.includes(group.id),
    );

    return (
      <div className="row" style={{ marginLeft: 10 }}>
        <div className="col-6">
          <h6 className="first mb-2">
            <FormattedMessage id="common.selected" />
            {':'}
          </h6>
          {selectedGroups.length > 0 ? (
            selectedGroups.map((group) => (
              <button
                type="button"
                key={group.id}
                className="btn btn-light border mr-1"
                onClick={() => this.removeClientGroup(group.id)}
              >
                {group.name}
                <span>
                  {'-'}
                </span>
              </button>
            ))
          ) : (
            <i>
              <FormattedMessage id="views.playlists.noSelectedGroups" />
            </i>
          )}

          <h6 className="mt-3 mb-2">
            <FormattedMessage id="common.avilable" />
            {':'}
          </h6>
          {availableGroups.length > 0 ? (
            availableGroups.map((group) => (
              <button
                type="button"
                key={group.id}
                className="btn btn-light border mr-1"
                onClick={() => this.addClientGroup(group.id)}
              >
                {group.name}
                <span>
                  {'+'}
                </span>
              </button>
            ))
          ) : (
            <i>
              <FormattedMessage id="views.playlists.noAvailableGroups" />
            </i>
          )}

          {disabledGroups.length > 0 && (
            <h6 className="mt-3 mb-2">
              <FormattedMessage id="views.playlists.usedInOtherSchemes" />
              {':'}
            </h6>
          )}

          {disabledGroups.map((group) => (
            <button
              type="button"
              className="btn btn-light border mr-1 disabled"
            >
              {group.name}
            </button>
          ))}
        </div>

        <div className="col-6">
          <a className="float-right mr-3" href="#/playlists/edit-groups">
            <FormattedMessage id="views.playlists.editGroups" />
          </a>
        </div>
      </div>
    );
  }
}

ClientManager.propTypes = propTypes;

const getClientGroups = createSelector(
  (state) => getClientGroupsInArray(state),
  (clientGroups) => Immutable.fromJS(clientGroups),
);

function mapState(state) {
  return {
    clientGroups: getClientGroups(state),
  };
}

const mapDispatch = {
  fetchClientGroups,
  updateClientGroup,
};

export default connect(mapState, mapDispatch)(ClientManager);
