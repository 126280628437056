import uniqueId from 'lodash/uniqueId';

/**
* Factory that returns a buildMeta function for convenient creation of the meta
* object for API request actions
*
* @export
* @param {*} { collection, url }
* @returns
*/
export function buildApiMetaFactory({ collection, url }) {
  return function buildMeta({ entityId, reset = false, ...other } = {}) {
    const meta = {
      collection,
      entityId,
      requestId: uniqueId('request_'),
      reset,
      url,
      ...other,
    };

    if (!entityId) {
      delete meta.entityId;
    }

    return meta;
  };
}
