import React from 'react';
import { useIntl } from 'react-intl';
import { DAYOFWEEK_CONDITION } from '../constants';
import { useFieldCondition } from '../hooks/useFieldCondition';
import { ConditionSection } from './ConditionSection';
import { EQToggle, useOperator } from './EQToggle';

const WEEKDAYS = ['1', '2', '3', '4', '5', '6', '0'];

export const DayOfWeekCondition = () => {
  const { formatMessage } = useIntl();
  const [field, onChange] = useFieldCondition(DAYOFWEEK_CONDITION, 'and');
  const { value = [] } = field || {};
  const [operator, setOperator] = useOperator(field, onChange);

  const handleSelect = (day) => () => {
    const selected = value.includes(day)
      ? value.filter((val) => val !== day)
      : [...value, day];
    onChange(selected, operator);
  };

  return (
    <ConditionSection
      label={formatMessage({ id: 'views.planning.playbackConditions.dayOfWeek' })}
    >
      <div className="btn-group">
        {WEEKDAYS.map((day) => {
          return (
            <button
              type="button"
              key={day}
              onClick={handleSelect(day)}
              className={`btn border${value.includes(day) ? ' btn-primary active' : ''}`}
            >
              {formatMessage({ id: `common.weekdays_short.${day}` })}
            </button>
          );
        })}
      </div>
      <EQToggle
        value={operator}
        onChange={setOperator}
      />
    </ConditionSection>
  );
};
