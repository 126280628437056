import React from 'react';

export function FormCheck({
  checked, label, disabled, onChange,
}) {
  return (
    <div className="form-check">
      <label
        type="checkbox"
        className="form-check-label"
      >
        <input
          type="checkbox"
          className="form-check-input"
          checked={checked}
          disabled={disabled}
          onChange={onChange || undefined}
        />
        {label}
      </label>
    </div>
  );
}

export function FormText({ children, className = 'form-text text-muted' }) {
  return (
    <small className={className}>
      {children}
    </small>
  );
}

export function FormGroup({
  children, id, className = 'form-group', ...other
}) {
  return (
    <div
      id={id}
      data-testid={other['data-testid']}
      className={className}
    >
      {children}
    </div>
  );
}
