// Media types.
export const MEDIA_TYPE_ANNOUNCEMENT = 'announcement';
export const MEDIA_TYPE_AUDIO = 'audio';
export const MEDIA_TYPE_IMAGE = 'image';
export const MEDIA_TYPE_VIDEO = 'video';

export const MEDIA_TYPE_WIDGET1 = 'widget1';
export const MEDIA_TYPE_WIDGET12 = 'widget12';
export const MEDIA_TYPE_WIDGET_HTML = 'widget_html';
export const MEDIA_TYPE_WIDGET_RSS = 'widget_rss';
export const MEDIA_TYPE_WIDGET_WEBLINK = 'widget_weblink';

export const MEDIA_TYPE_CUSTOM_WIDGET = 'widget_config__';
