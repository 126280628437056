import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { intlShape } from 'utils/shapes';
import * as selectors from 'js/redux/selectors';
import Badge from '../components/Badge';

function PlaylistCount({ badgeId, intl, playlists }) {
  return (
    <Badge
      title={intl.formatMessage({ id: 'views.dashboard.playlistCount.totalPlaylists' })}
      badgeId={badgeId}
    >
      <h1 className="ma-0 text-center">
        {playlists
          ? playlists.length
          : `${intl.formatMessage({ id: 'common.loading' })}...`
        }
      </h1>
    </Badge>
  );
}

PlaylistCount.propTypes = {
  intl: intlShape.isRequired,
  playlists: PropTypes.array,
  badgeId: PropTypes.string.isRequired,
};


function mapStateToProps(state) {
  return {
    playlists: selectors.getPlaylists(state),
  };
}

export default injectIntl(connect(mapStateToProps)(PlaylistCount));
