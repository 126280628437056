import React from 'react';
import { Link } from 'react-router-dom';
import cancan from 'js/system/cancan';
import { FormattedMessage } from 'react-intl';
import { DASHBOARD } from 'constants/permissions';

export function NavItem({
  children, className = '', url = '#', active, icon, onClick,
}) {
  const liClassName = `nav-item${active ? ' active' : ''} ${className}`;
  return (
    <li className={liClassName}>
      <Link
        className="nav-link"
        to={url}
        onClick={onClick}
      >
        {icon && (
          <i
            className={`${icon} nav-icon`}
          />
        )} {children}
        {active && (
          <span className="sr-only">
            {'('}
            <FormattedMessage id="common.current" />
            {')'}
          </span>
        )}
      </Link>
    </li>
  );
}

export function ExternalNavItem({
  children, className = '', url = '#', active, icon, onClick,
}) {
  const liClassName = `nav-item${active ? ' active' : ''} ${className}`;
  return (
    <li className={liClassName}>
      <a
        className="nav-link"
        href={url}
        onClick={onClick}
      >
        {icon && (
          <i
            className={`${icon} nav-icon`}
          />
        )} {children}
        {active && (
          <span className="sr-only">
            {'('}
            <FormattedMessage id="common.current" />
            {')'}
          </span>
        )}
      </a>
    </li>
  );
}

class Nav extends React.Component {
  state = {
    expanded: false,
  }

  handleExpand = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  // Render
  render() {
    const { expanded } = this.state;
    const innerClassName = `collapse navbar-collapse${expanded ? ' show' : ''}`;

    return (
      <nav className="navbar navbar-expand-lg py-1 navbar-dark bg-dark">
        <a
          className="navbar-brand brand"
          href={
            cancan.can('read', DASHBOARD) ? '#dashboard' : '#'
          }
        >
          <span>
            <FormattedMessage id="common.geoSignage" />
          </span>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded={expanded ? 'true' : 'false'}
          aria-label="Toggle navigation"
          onClick={this.handleExpand}
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div
          className={innerClassName}
          id="navbarNav"
        >
          <ul
            className="navbar-nav mr-auto"
            data-testid={this.props['data-testid'] || ''}
          >
            {this.props.children}
          </ul>
          <ul className="navbar-nav">
            {this.props.rightArea}
          </ul>
        </div>
      </nav>
    );
  }
}

export default Nav;
