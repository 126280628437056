import { UPDATE_DASHBOARD_WIDGET_TITLE } from '../actionTypes';
import { PRIMARY_DASHBOARD_ID } from '../constants';

export default function updateDashboardWidgetTitle(
  { widgetId, title, dashboardId = PRIMARY_DASHBOARD_ID } = {},
) {
  return {
    payload: { dashboardId, widgetId, title },
    type: UPDATE_DASHBOARD_WIDGET_TITLE,
  };
}
