import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ChromePicker } from 'react-color';
import Dropdown, { DropdownToggle, DropdownContent } from 'components/Dropdown';
import { AREA_DEFAULT_STYLE } from 'js/react/ClientGroups/_utils';

function StyleOptions({
  attr, values, onChange, label, options = {},
}) {
  return (
    <div className="form-group">
      <label htmlFor="bg-size-picker">
        {label}
        {':'}
      </label>
      <select
        id="bg-size-picker"
        name="bg-size-picker"
        className="form-control"
        value={values[attr]}
        onChange={(e) => onChange(e.target.value, attr)}
      >
        {Object.keys(options).map((id) => (
          <FormattedMessage
            key={id}
            id={options[id]}
          >
            {(txt) => (
              <option
                value={id}
              >
                {txt}
              </option>
            )}
          </FormattedMessage>
        ))}
      </select>
    </div>
  );
}

const OpacitySlider = ({
  value,
  onChange,
  id = '',
  label = '',
}) => {
  const [opacity, setOpacity] = useState(value);
  const handleDone = () => {
    opacity !== value && onChange(opacity);
  };

  return (
    <>
      <label htmlFor={id}>
        <FormattedMessage id={label} />{': '}
      </label>
      <input
        type="range"
        id={id}
        className="form-control-range mb-3"
        min="0"
        max="1"
        step="0.1"
        value={opacity}
        onChange={(e) => setOpacity(e.target.value)}
        onMouseUp={handleDone}
        onKeyUp={handleDone}
      />
    </>
  );
};

export const StyleSettings = ({
  media: allMedia, areaId, areaSettings, handleClose, updateArea,
}) => {
  const settings = areaSettings?.[areaId] || {};
  const media = allMedia?.[settings.contentId];
  const isImage = media && media.type === 'I';
  const style = {
    area: {
      ...AREA_DEFAULT_STYLE.area,
      ...(settings.style?.area || {}),
    },
    content: {
      ...AREA_DEFAULT_STYLE.content,
      ...(settings.style?.content || {}),
    },
  };

  const setStyle = (value, attr, target = 'content') => {
    style[target][attr] = value;
    updateArea({ style });
  };

  const handleContentOpacity = (value) => setStyle(value, 'opacity');

  const handleAreaOpacity = (value) => setStyle(value, 'opacity', 'area');

  const handleColor = ({ rgb }) => {
    const prevColor = style.area.backgroundColor;
    const regex = /rgba\((.*),(.*),(.*),(.*)\)/;
    const res = regex.exec(prevColor);
    const [, r, g, b, prevAlpha] = (res || []).map((v = '') => v.trim());

    // Note: Intentional loose equality check.
    // eslint-disable-next-line eqeqeq
    if ((typeof prevAlpha === 'undefined' || prevAlpha == '0') && (
      rgb.r != r || // eslint-disable-line
      rgb.g != g || // eslint-disable-line
      rgb.b != b  // eslint-disable-line
    )) {
      // Hue changed and opacity was 0, reset alpha to 1
      rgb.a = 1; // eslint-disable-line
    }

    setStyle(
      `rgba(${rgb.r}, ${rgb.g} , ${rgb.b}, ${rgb.a})`,
      'backgroundColor',
      'area',
    );
  };

  return (
    <div className="card px-2">
      <div className="card-body">
        <h6>
          <FormattedMessage id="common.settings" />
          {': '}
          {areaId}
        </h6>
        <form className="mb-3">
          {isImage && (
            <>
              <StyleOptions
                attr="objectFit"
                values={style.content}
                onChange={setStyle}
                label={<FormattedMessage id="views.layout.objectScaling" />}
                options={{
                  // '': 'common.default', // No preview support yet...
                  contain: 'views.layout.contain',
                  cover: 'views.layout.cover',
                  '100% 100%': 'views.layout.stretch',
                }}
              />

              <StyleOptions
                attr="objectPosition"
                values={style.content}
                onChange={setStyle}
                label={<FormattedMessage id="views.layout.objectPosition" />}
                options={{
                  inherit: 'common.noOption',
                  left: 'views.layout.left',
                  right: 'views.layout.right',
                  top: 'views.layout.top',
                  bottom: 'views.layout.bottom',
                  center: 'views.layout.center',
                }}
              />
              <OpacitySlider
                value={style.content.opacity}
                onChange={handleContentOpacity}
                id="opacity-picker"
                label="views.layout.objectOpacity"
              />
            </>
          ) /* end isImage */}

          <div>
            <FormattedMessage id="views.layout.backgroundColor" />{': '}
          </div>
          <Dropdown>
            <DropdownToggle>
              <div
                className="d-inline-block rounded border align-middle"
                style={{
                  backgroundColor: style.area?.backgroundColor,
                  width: 25,
                  height: 25,
                }}
              />
            </DropdownToggle>
            <DropdownContent
              className=""
            >
              <ChromePicker
                color={style.area?.backgroundColor}
                onChangeComplete={handleColor}
              />
            </DropdownContent>
          </Dropdown>

          <div className="mt-3">
            <OpacitySlider
              value={style.area?.opacity}
              onChange={handleAreaOpacity}
              id="bg-opacity-picker"
              label="views.layout.backgroundOpacity"
            />
          </div>
        </form>
        <button
          type="button"
          className="btn btn-light border mr-1"
          onClick={() => handleClose()}
        >
          <FormattedMessage id="common.back" />
        </button>
      </div>
    </div>
  );
};
