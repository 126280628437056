import Immutable 	from 'immutable';

export const actions = {};
export const types = {};

types.SELECT 		= '@MEDIA.SELECT';
types.DESELECT 		= '@MEDIA.DESELECT';
types.SETSELECTED 	= '@MEDIA.SETSELECTED';

actions.select = function (id) {
	return {
		type 	: types.SELECT,
		payload : id
	};
};

actions.deselect = function (id) {
	return {
		type 	: types.DESELECT,
		payload : id
	};
};

actions.selectAll = function (ids) {
	return {
		type 	: types.SETSELECTED,
		payload : ids || Immutable.Set()
	};
};