/* jshint white:true, browser:true, unused:true, undef:true, newcap:true, latedef:true, indent:4, forin:true, camelcase:true, esnext:true */
/*global module, require */

/*
    Checkbox components & utils.

    /////////////////////////////////////
    USE:

    var CheckBox  = require('utils/react/checkbox');

    var NAMESPACE = 'myNamespace';
    var CheckTest = createClass({
        
        getInitialState() {
            var items = Immutable.List();

            for (var i = 0; i < 100; i++) {
                items = items.push(Immutable.fromJS({
                    id : uniqueId('_id_'),
                    name : 'name_' + i
                }));
            }
            
            // When updating list content, make sure to update visibleItems for shift-click & check-all to work.
            CheckBox.visibleItems(NAMESPACE, items);

            return { items };
        },

        componentDidMount() {
            // If the the checkboxes aren't rendered in the same order as supplied to visibleItems()
            // use orderInNode() to update the actual rendered order. Useful for grouped lists etc.
            // Needed for shift-click support! 

            var node = ReactDom.findDOMNode(this);
            var newOrder = CheckBox.orderInNode(node, NAMESPACE);
        },

        render() {
            return (
                <div>

                    // this.props.checkedItems is passed from CheckBox.Wrap
                    Checked items: { this.props.checkedItems.size }
                    
                    // These methods can be used in any context.
                    Checked items: { CheckBox.getChecked(NAMESPACE).size }
                    Visible items: { CheckBox.getVisible(NAMESPACE).size }

                    <CheckBox.All namespace={ NAMESPACE }>
                        { 'CHECK ALL' }
                    </CheckBox.All>

                    <ul>
                        { this.state.items.map(i => (
                            <li key={ getId(i) }>

                                <CheckBox namespace={ NAMESPACE } item={ i }>
                                    { i.get('name') }
                                </CheckBox>

                            </li>
                        )).toArray() }
                    </ul>
                </div>
            );
        }
    });
    
    // Wrapper needed for checkboxes to update.
    export default CheckBox.Wrap(CheckTest, NAMESPACE);

    // Alternatively, use as mixin:
    mixins: [
        Checkbox.mixin(NAMESPACE)
    ]
*/

import {
  map,
  isFunction,
  extend,
  isArray,
  reduce,
} from 'lodash';
import React from 'react';
import ReactDom from 'react-dom';
import Immutable from 'immutable';
import EventEmitter from 'eventemitter2';
import createClass from 'create-react-class'

var checkStore = Immutable.Map(),
    visibleItems = Immutable.Map(),
    allItems = Immutable.Map(),
    lastChecked,
    emitter = new EventEmitter({
        wildcard: true
    });

// Utils

function getId(item) {
    // Handle GeoSignages int-ids...
    var id = item.get('id');
    return id.toString ? id.toString() : id;
}

function idsFromNode(node, namespace) {
    var checkboxes = node.getElementsByClassName("tzcb-" + namespace);

    return map(checkboxes, (c) => {
        if ( c.dataset !== undefined ) {
            return c.dataset.id;
        }
        return c.getAttribute('data-id'); // IE 10 & older...
    });
}

function orderInNode(node, namespace) {
    var ids = idsFromNode(node, namespace);
    // TODO: Update order without removing not visible items? (eg. hidden by scroll render or something.)
    visibleItems = visibleItems.set(namespace, Immutable.OrderedSet(ids));
    return ids;
}

function getAll(ns) {
    var all = allItems.get(ns);
    if (all) {
        return all.map(i => i && getId(i));
    }
    return visibleItems.get(ns) || Immutable.OrderedSet();
}

// Callbacks

var checkRange = function (ns, id) {
    if (lastChecked[0] !== ns) { return; }

    var visible   = (visibleItems.get(ns) || Immutable.OrderedSet()).toList(),
        lastState = checkStore.hasIn(lastChecked),
        lastIndex = visible.indexOf(lastChecked[1]),
        newIndex  = visible.indexOf(id);

    if (lastIndex !== -1) {
        var checkedItems = checkStore.get(ns),

            match        = newIndex > lastIndex ? [lastIndex, newIndex] : [newIndex, lastIndex],
            start        = match[0],
            end          = match[1],
            itemsToCheck = visible.slice(start, end + 1).toSet(),
            fn           = lastState ? 'union' : 'subtract';

        checkStore = checkStore.set(ns, checkedItems[fn](itemsToCheck));
        return {
            state : lastState,
            items : itemsToCheck
        };
    }

    return {
        state : lastState,
        items : Immutable.Set([id])
    };
};

var checkItem = function(ns, item, callback) {
    return (e) => {
        var range = {};
        var id = getId(item);

        if (e.shiftKey && lastChecked && lastChecked[1] !== id) {
            range = checkRange(ns, id);
        } else {
            var set = checkStore.get(ns) || Immutable.OrderedSet();

            if (set.includes(id)) {
                set = set.delete(id);
                range.state = false;
            } else {
                set = set.add(id);
                range.state = true;
            }

            range.items = Immutable.Set([id]);
            checkStore = checkStore.set(ns, set);
        }

        if (isFunction(callback)) {
            callback(e, range.state, range.items);
        }

        lastChecked = [ns, id];

        // TODO: Only trigger if state changed?
        emitter.emit('check.' + ns);

        return range;
    };
};

var checkItems = function(ns, items, state) {
    var fn = state === false ? 'subtract' : 'union';
    var ids = items.map(i => getId(i));
    var set = checkStore.get(ns) || Immutable.OrderedSet();
    
    checkStore = checkStore.set(ns, set[fn](ids));

    var newSet = checkStore.get(ns);

    if (newSet !== set) {
        // Only trigger if states changed!
        emitter.emit('check.' + ns);
    }

    return newSet;
};

var checkAll = function(ns, callback) {
    return (e, state) => {
        var checked = Boolean(e && e.target.checked || state);
        var all = getAll(ns);
        var items = checked ? all : Immutable.OrderedSet();

        checkStore = checkStore.set(ns, items);
        emitter.emit('check.' + ns);
        
        if (isFunction(callback)) {
            callback(e, checked, all);
        }
    };
};


// Components

var CheckBox = function (props) {
    var cl = checkItem(props.namespace, props.item, props.onClick),
        id = getId(props.item),
        params = {
        type        : "checkbox",
        checked     : checkStore.hasIn([props.namespace, id]),
        onChange    : props.onChange
    };

    if (props.children) {
        return (
            <label>
                <input onClick={cl} {...params} className={ "tzcb-" + props.namespace } data-id={ id } /> {props.children}
            </label>
        );
    }

    return <input onClick={cl} {...params} className={ "tzcb-" + props.namespace } data-id={ id } />;
};

var CheckAll = function (props) {
    var { namespace, children, onChange, ...other } = props;

    var vi = getAll(namespace),
        checked = checkStore.get(namespace) || Immutable.OrderedSet(),
        allChecked = checked.size > 0 && vi.subtract(checked).size === 0,
        inp = <input
            type        = "checkbox"
            checked     = { allChecked }
            onChange    = { checkAll(namespace, onChange) }
            { ...other }
        />;

    if (children) {
        return (
            <label>{inp} {children}</label>
        );
    }

    return inp;
};


// Wrapper

var mixin = (namespace, options) => {
    // Default settings:
    options = extend({
        clearOnUnmount : true,
        parent : false // Are the checkboxes children to the component?
    }, options);

    function getItems() {
        if (isArray(namespace)) {
            return reduce(namespace, (acc, ns) => {
                acc[ns] = checkStore.get(ns) || Immutable.Set();
                return acc;
            }, {});
        } else {
            return checkStore.get(namespace) || Immutable.Set();
        }
    }

    function forNS(fn) {
        if (isArray(namespace)) {
            return map(namespace, ns => {
                fn.call(this, ns);
            });
        } else {
            return fn.call(this, namespace);
        }
    }

    return {
        getInitialState() {
            return {
                checkedItems : getItems()
            };
        },

        UNSAFE_componentWillMount() {
            forNS.call(this, (ns) => {
                emitter.on('check.' + ns, this.check);
            });
        },

        componentDidMount() {
            this.updateCheckboxOrder();  
        },

        componentDidUpdate() {
            this.updateCheckboxOrder();
        },

        updateCheckboxOrder() {
            if (options.parent) {
                // Update order of child checkboxes (for shift-click & check-all support):
                var node = ReactDom.findDOMNode(this);
                forNS.call(this, (ns) => {
                    CheckBox.orderInNode(node, ns); 
                });
            }
        },

        componentWillUnmount() {
            forNS.call(this, (ns) => {
                emitter.off('check.' + ns, this.check);

                if (options.clearOnUnmount) {
                    // Clear cached data:
                    checkStore = checkStore.delete(ns);
                    visibleItems = visibleItems.delete(ns);
                    allItems = allItems.delete(ns);
                }
            });
        },

        check() {
            this.setState({
                checkedItems : getItems()
            });
        },

        getChecked(ns) {
            var ci = ns ? this.state.checkedItems[ns] : this.state.checkedItems,
                all = allItems.get(ns || namespace);
            if (all) {
                return all.filter(i => {
                    return ci.includes(i && getId(i));
                });
            } else {
                console.warn('To use CheckBox.getChecked(), you first need to supply items with CheckBox.allItems().');
                return ci;
            }
        }
    };
};

function Wrapper(Component, namespace, options) {
    var params = mixin(namespace, options);
    params.render = function () {
        return <Component {...this.props} {...this.state} getChecked={ this.getChecked }/>;
    };

    return createClass(params);
}

// API export:

CheckBox.on = () => {
    var args = arguments;
    emitter.on.apply(emitter, args);
    return {
        emitter,
        off : () => emitter.off.apply(emitter, args)
    };
};

// Utils:
CheckBox.allItems   = (ns, items) => { allItems = allItems.set(ns, items.toSet()); };
CheckBox.orderInNode = orderInNode;
CheckBox.getChecked = ns => { return ns ? (checkStore.get(ns) || Immutable.OrderedSet()) : checkStore; };
CheckBox.getVisible = ns => { return ns ? (visibleItems.get(ns) || Immutable.List()) : visibleItems; };
CheckBox.checkItem  = checkItem;
CheckBox.checkItems = checkItems;
CheckBox.checkAll   = checkAll;
CheckBox.isChecked  = (ns, item) => checkStore.hasIn([ns, getId(item)]);

// Wrap/mixin
CheckBox.wrap       = Wrapper;
CheckBox.mixin      = mixin;

// Components
CheckBox.All        = CheckAll;

export default CheckBox;
