// Views
export const ADMIN = 'admin';
export const CLIENTS = 'clients';
export const HISTORY = 'history';
export const PLACES = 'places';
export const PLANNING = 'planning';
export const PLAYLISTS = 'playlists';
export const REPORT = 'report';
export const SIMULATION = 'simulation';
export const DASHBOARD = 'dashboard';

// Modules
export const PLANNING_LONG_TERM = 'planning.long_term';
export const MODULE_DOWNLOAD_BUNDLE = 'module.download_bundle';
export const ADMIN_USER_ACTIVITY = 'admin.user_activity';
export const ADMIN_GROUP_MANAGEMENT = 'admin.group_management';
export const ADMIN_MEDIABUILD = 'admin.media_build';
export const ADMIN_USER_MANAGEMENT = 'admin.user_management';
export const CLIENTS_SETTINGS = 'clients.settings';
export const CLIENTS_SETTINGS_PREVENT_CUSTOM_LAYOUT = 'clients.noCustomLayout';
export const CLIENTS_SETTINGS_PREVENT_GROUP_CONNECT = 'clients.noGroupConnect';
export const PLANNING_MAX_COUNT = 'planning.max_count';
export const PLANNING_TIMEPLAN = 'planning.timeplan';
export const VIEW_PASSWORD = 'user.password';
export const USER_HIDE_LOGOUT = 'user.hide_logout';
export const PLAN_PLAYBACK_CONDITIONS = 'planning.media_playback_conditions';
export const NEXT_UI = 'next_ui';

// Announcements
export const ANNOUNCEMENT_CHANNEL_AUDIO = 'planning.announcement.audio';
export const ANNOUNCEMENT_CHANNEL_MEDIA = 'planning.announcement.media';
export const ANNOUNCEMENT_CHANNEL_TEXTEXTERIOR = 'planning.announcement.textExterior';
export const ANNOUNCEMENT_CHANNEL_TEXTINTERIOR = 'planning.announcement.textInterior';

// Utils for dynamic permissions
export const placeTypePermission = (placeType) => `places.${placeType}`;
export const placeReadonly = (placeType) => `places.${placeType}.read-only`;
export const canCreateMedia = (type) => `planning.create.${type}`;

// System settings
export const ANNOUNCEMENT_SYSTEM = 'announcement_system';
