import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { actionCreators } from 'js/redux/helpModal';

const mapDispatchToProps = { setHelpModal: actionCreators.setHelpModal };

const propTypes = {
  bodyKey: PropTypes.string,
  headerKey: PropTypes.string,
  setHelpModal: PropTypes.func.isRequired,
};

const HelpIcon = ({ bodyKey, headerKey, setHelpModal }) => {
  if (!bodyKey && !headerKey) {
    return null;
  }

  return (
    <button
      type="button"
      className="btn btn-sm px-0 gs-help-icon pointer"
      data-testid="help-icon"
      onClick={(event) => {
        event.preventDefault();
        setHelpModal({
          bodyKey,
          headerKey,
        });
      }}
    >
      <i className="icon-question-sign" />
    </button>
  );
};

HelpIcon.propTypes = propTypes;

export default connect(undefined, mapDispatchToProps)(HelpIcon);
