import React from 'react';

const CloseButton = ({ onClick, disabled }) => {
  return (
    <button
      type="button"
      aria-label="Close"
      className="close"
      onClick={onClick}
      disabled={disabled}
    >
      <span aria-hidden="true">
         &times;
      </span>
    </button>
  );
};

export default CloseButton;
