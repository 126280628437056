import isEqual from 'lodash/isEqual';

// Constants

export const SCHEME = 'scheme';

export const LAYOUT_AREA_MEDIA = 'media';

export const ASPECT_RATIOS = Object.freeze(['9:16', '10:16', '16:9', '16:10']);

export const DEFAULT_ASPECT_RATIO = ASPECT_RATIOS[2];

export const AREA_DEFAULT_STYLE = Object.freeze({
  area: {
    opacity: 1,
    backgroundColor: 'transparent',
  },
  content: {
    opacity: 1,
    objectFit: 'contain',
    objectPosition: 'center',
  },
});

export const DEFAULT_AREA = Object.freeze({
  id: 'A',
  width: 100,
  height: 100,
  top: 0,
  left: 0,
});

export const DEFAULT_AREAS = Object.freeze([DEFAULT_AREA]);

const DEFAULT_AREA_SETTING = Object.freeze({
  contentType: SCHEME,
  contentId: null,
  style: { ...AREA_DEFAULT_STYLE },
});

export const DEFAULT_LAYOUT = Object.freeze({
  aspectRatio: DEFAULT_ASPECT_RATIO,
  areas: [{ ...DEFAULT_AREA }],
  areaSettings: {
    A: { ...DEFAULT_AREA_SETTING },
  },
});

// Utils

export const isDefaultMode = ({ areas, areaSettings } = {}) => {
  if (!areas) {
    return true;
  }
  return areas.length === 1 && isEqual(areas[0], DEFAULT_AREA) && areaSettings?.A?.contentType !== LAYOUT_AREA_MEDIA;
};

export function makeDefaultArea(id = '') {
  return { ...DEFAULT_AREA, id };
}

function mergeAreaSettingsStyles({ area, content } = {}) {
  return {
    area: {
      ...AREA_DEFAULT_STYLE.area,
      ...(area || {}),
    },
    content: {
      ...AREA_DEFAULT_STYLE.content,
      ...(content || {}),
    },
  };
}

export function mergeAreaSetting(settings = {}) {
  return {
    ...DEFAULT_AREA_SETTING,
    ...settings,
    style: mergeAreaSettingsStyles(settings.style),
  };
}
