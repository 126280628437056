import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import cancan from 'js/system/cancan';
import DeleteConfirm from 'components/DeleteConfirm';
import { deleteClient, fetchClients, updateClient } from 'src/entities/clients/actionCreators';
import { CLIENTS_SETTINGS_PREVENT_GROUP_CONNECT } from 'constants/permissions';
import { ClientGroupSelector } from './ClientGroupSelector';

export const SelectOptions = ({
  selected, reset, clients, clientGroups,
}) => {
  const dispatch = useDispatch();
  const canConnectGroups = !cancan.can('read', CLIENTS_SETTINGS_PREVENT_GROUP_CONNECT);

  const handleDestroy = () => {
    selected.forEach((id) => {
      dispatch(deleteClient(id)).then(reset);
    });
  };

  const handleGroup = (groupId) => {
    selected.forEach((id) => {
      const client = clients[id] || {};
      client.id && dispatch(updateClient({
        ...client,
        client_group_id: groupId,
      })).then(() => {
        dispatch(fetchClients());
        reset();
      });
    });
  };

  const currentGroup = useMemo(() => {
    return selected.reduce((acc, id) => {
      const client = clients[id] || {};
      return acc === client.client_group_id
        ? acc
        : undefined;
    }, (clients[selected[0]] || {}).client_group_id);
  }, [selected, clients]);

  return (
    <div className="d-flex">
      <DeleteConfirm
        onDelete={handleDestroy}
        className="d-inline-block mr-2 mb-0 text-nowrap"
      />
      {canConnectGroups && (
        <ClientGroupSelector
          selected={currentGroup}
          onChange={handleGroup}
          clientGroups={clientGroups}
        />
      )}
    </div>
  );
};
