import { createCrudActionCreators } from 'src/core/api';

import { COLLECTION, URL } from './constants';

export const {
  create: createClientGroup,
  readAll: fetchClientGroups,
  readById: fetchClientGroup,
  update: updateClientGroup,
  destroy: deleteClientGroup,
} = createCrudActionCreators({ collection: COLLECTION, url: URL });
