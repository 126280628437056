import React from 'react';
import { FormattedMessage } from 'react-intl';
import LanguagePicker from 'components/LanguagePicker';

function ChangeLanguage() {
  return (
    <div className="container-fluid pt44">
      <div className="row">
        <div className="col-6">
          <div className="card mx-2 mb-2 mt-3 p-3">
            <h2>
              <FormattedMessage id="common.changeLanguage" />
            </h2>
            <LanguagePicker />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeLanguage;
