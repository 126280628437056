import React from 'react';
import { defaultMemoize } from 'reselect';
import ResizeableBox from './ResizeableBox';

// Colors from https://sashat.me/2017/01/11/list-of-20-simple-distinct-colors/
const AREA_COLORS = {
  A: '#e6194b',
  B: '#3cb44b',
  C: '#ffe119',
  D: '#4363d8',
  E: '#f58231',
  F: '#911eb4',
  G: '#46f0f0',
  H: '#f032e6',
  I: '#bcf60c',
  J: '#fabebe',
  K: '#008080',
  L: '#e6beff',
  N: '#fffac8',
  O: '#800000',
  P: '#aaffc3',
  Q: '#808000',
  R: '#ffd8b1',
  S: '#000075',
  T: '#808080',
  U: '#ffffff',
  V: '#000000',
};

const AREA_CONTAINER_STYLE = {
  position: 'absolute',
  height: '100%',
  width: '100%',
  top: 0,
  left: 0,
};

class LayoutPreview extends React.PureComponent {
  state = {
    currentWidth: 0,
    currentHeight: 0,
  }

  componentDidMount() {
    this.timer = setInterval(this.trackSizeChange, 1000);
  }

  componentDidUpdate() {
    this.trackSizeChange();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  getAspectRatio = defaultMemoize((ratio = '16:9') => {
    const [w, h] = ratio.split(':');
    let height = h / w * 100;
    let width = 100;

    if (height > 50) {
      const scale = 50 / height;
      width = Math.round(scale * 100);
      height = 50;
    }

    return {
      width: `${width}%`,
      paddingBottom: `${Math.round(height)}%`, // Use padding-hack to display correct ratio.
    };
  })

  handleSelect = (selected) => () => this.props.onSelect(selected)

  convertPixelsToViewPort = (id) => ({
    width, height, x, y,
  }) => {
    const { currentWidth } = this.state;
    const { currentHeight } = this.state;

    this.props.onChangeArea({
      id,
      width: Math.round((parseInt(width, 10) / currentWidth) * 100),
      height: Math.round((parseInt(height, 10) / currentHeight) * 100),
      top: Math.round((y / currentHeight) * 100),
      left: Math.round((x / currentWidth) * 100),
    });
  }

  trackSizeChange = (el = this.container) => {
    if (el) {
      this.container = el;
      const currentWidth = el.clientWidth;
      const currentHeight = el.clientHeight;
      if (currentWidth !== this.state.currentWidth || currentHeight !== this.state.currentHeight) {
        this.setState({
          currentWidth,
          currentHeight,
        });
      }
    }
  }

  convertViewportToPixels({
    width, height, top, left,
  }) {
    const { currentWidth } = this.state;
    const { currentHeight } = this.state;

    return {
      width: currentWidth * (width / 100),
      height: currentHeight * (height / 100),
      x: currentWidth * (left / 100),
      y: currentHeight * (top / 100),
    };
  }

  renderAreas() {
    const { areas, selected } = this.props;
    return areas.map(({ id, ...other }) => (
      <ResizeableBox
        key={id}
        selected={id === selected}
        onClick={this.handleSelect(id)}
        style={{
          backgroundColor: AREA_COLORS[id],
        }}
        {...this.convertViewportToPixels(other)}
      >
        {id}
      </ResizeableBox>
    ));
  }

  renderSelected() {
    const { areas, selected } = this.props;
    const selectedArea = areas.find((a) => a.id === selected);

    return selectedArea && (
      <ResizeableBox
        overlay
        onChange={this.convertPixelsToViewPort(selectedArea.id)}
        style={{
          backgroundColor: 'transparent',
        }}
        {...this.convertViewportToPixels(selectedArea)}
      >
        {selectedArea.id}
      </ResizeableBox>
    );
  }

  render() {
    return (
      <div
        className="position-relative mx-auto"
        style={{
          ...this.getAspectRatio(this.props.aspectRatio),
          border: '2px solid #000',
        }}
      >
        <div
          ref={this.trackSizeChange}
          style={AREA_CONTAINER_STYLE}
          className="bbox"
        >
          {this.renderAreas()}
          {this.renderSelected()}
        </div>
      </div>
    );
  }
}

export default LayoutPreview;
