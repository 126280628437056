import { useState, useEffect } from 'react';

export const usePlayerScale = (ref, ratio) => {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const checkSize = () => {
      const [playerWidth, playerHeight] = ratio;
      const wScale = ref.current?.clientWidth / playerWidth;
      const hScale = ref.current?.clientHeight / playerHeight;

      setHeight(Math.min(wScale, hScale));
    };
    window.addEventListener('resize', checkSize);
    checkSize();

    return () => {
      window.removeEventListener('resize', checkSize);
    };
  }, [ratio]);

  return height;
};
