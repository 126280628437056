import React, { useState } from 'react';
import {
  arrayOf, bool, element, func, oneOfType, shape, string,
} from 'prop-types';
import { identity } from 'lodash';

const TabView = ({
  defaultTab, tabs = [], className = '', children,
}) => {
  const [active, setActive] = useState(defaultTab || tabs[0]?.id);

  const renderTab = ({
    id, label, href, className: tabClass = '', ...other
  }) => {
    const Tab = href ? 'a' : 'button';
    const liClassName = `nav-item mr-1${tabClass}`;
    const tabClassName = `nav-link ${active === id ? ' active' : ''}`;

    return (
      <li
        key={id}
        className={liClassName}
      >
        <Tab
          type={href ? 'link' : 'button'}
          href={href}
          className={tabClassName}
          onClick={() => setActive(id)}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...other}
        >
          {label}
        </Tab>
      </li>
    );
  };

  const ulClassName = `nav-tabs nav ${className}`;

  return (
    <div data-testid="tabsContainer">
      {!!tabs.length && (
        <ul className={ulClassName}>
          {tabs.filter(identity).map(renderTab)}
        </ul>
      )}
      {children(active)}
    </div>
  );
};

TabView.propTypes = {
  tabs: arrayOf(
    oneOfType([
      bool,
      shape({
        id: string.isRequired,
        label: oneOfType([
          string,
          element,
        ]).isRequired,
        href: string,
        className: string,
      }),

    ]),
  ),
  children: func.isRequired,
  className: string,
  defaultTab: string,
};

export default TabView;
