import React, { useContext } from 'react';
import { DatePicker } from 'react-widgets';

import LoadingSpinner from 'src/components/LoadingSpinner';
import { setDateTime, SimulationContext } from './StateManager';

export const PlaybackDateTimePicker = () => {
  const [{ dateTime }, dispatch] = useContext(SimulationContext);

  const handleTime = (now = new Date()) => {
    dispatch(setDateTime(now.valueOf()));
  };

  if (!dateTime) {
    return (
      <LoadingSpinner
        diameter="1.5rem"
        wrapperClassNames="my-1"
      />
    );
  }

  return (
    <DatePicker
      includeTime
      valueFormat="YYYY-MM-DD HH:mm"
      value={new Date(dateTime)}
      onChange={handleTime}
      culture={'en-GB'}
      display={'inline-block'}
      className="rw-popup-right-align"
    />
  );
};
