import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { updateHealthVariable, getHealthVariablesSortedByOrder } from 'src/entities/healthVariables';

import { success as successMessage } from 'js/react/actions/message-actions';
import NumberPicker from 'components/NumberPicker';

const propTypes = {
  className: PropTypes.string,
  // email: PropTypes.string,
  // frequency: PropTypes.string,
  // updateEmailAndFrequency: PropTypes.func,
};

const defaultProps = {
  className: '',
};

const ListHead = () => (
  <thead>
    <tr>
      <th className="text-truncate">
        <FormattedMessage id="common.parameter" />
      </th>
      <th className="text-truncate" colSpan="2">
        <FormattedMessage id="common.description" />
      </th>
      <th className="text-truncate">
        <FormattedMessage id="common.threshold" />
      </th>
    </tr>
  </thead>
);

const ListRows = ({ variables = [], handleTreshold }) => variables
  .filter((v) => v.operator && v.threshold !== 'None')
  .map((variable) => {
    const id = `views.admin.health.${variable.identifier}`;
    const idDescription = `views.admin.health.${variable.identifier}.description`;
    return (
      <tr key={variable.id}>
        <td>
          <FormattedMessage
            id={id}
          />
        </td>
        <td className="text-help text-muted" colSpan="2">
          <FormattedMessage
            id={idDescription}
            defaultMessage={variable.description}
          />
        </td>
        <td>
          <small className="d-block">
            {variable.operator === '>' ? (
              <FormattedMessage id="views.admin.health.moreThan" />
            ) : (
              <FormattedMessage id="views.admin.health.lessThan" />
            )}
            {':'}
          </small>
          <NumberPicker
            value={parseInt(variable.threshold, 10)}
            onChange={handleTreshold(variable.identifier)}
            submitOnlyOnChange
            step="any"
          />
        </td>
      </tr>
    );
  });

const HealthVariables = ({
  className,
  // createUpdateHealthVariable,
  // frequency,
  // email: initialEmail,
  // updateEmailAndFrequency,
}) => {
  // disabled until implemented in backend and frontend

  // const [email, setEmail] = React.useState(initialEmail || '');
  // const frequencyOptions = [1, 2, 3];

  // const handleEmail = (e) => {
  //   setEmail(e.target.value);
  // };

  // const updateEmail = () => {
  //   updateEmailAndFrequency(email, frequency || '');
  // };

  // const handleFrequency = (e) => {
  //   updateEmailAndFrequency(initialEmail, e.target.value);
  // };

  const healthVariables = useSelector(getHealthVariablesSortedByOrder);
  const dispatch = useDispatch();

  const createUpdateHealthVariable = (identifier, key, value) => {
    return dispatch(updateHealthVariable({
      // filling in the healthVariable values as they are before change
      ...healthVariables.find(
        (healthVariable) => healthVariable.identifier === identifier,
      ),
      // overwriting the specified property with new value to be saved
      [key]: value,
    })).then(() => successMessage('Health variable updated'));
  };

  const handleTreshold = (identifier) => (value) => {
    createUpdateHealthVariable(identifier, 'threshold', value);
  };

  return (
    <div className="holder bg-white p-3">
      <h2 className="my-3">
        <FormattedMessage id="common.settings" />
      </h2>
      <div className={className}>
        {/* // disabled until implemented in backend and frontend
          <div className="row">
            <label className="col-6">
              <FormattedMessage id="common.email" />
              {':'}
              <br />
              <input
                type="email"
                className="form-control w-100"
                value={email}
                onChange={handleEmail}
                onBlur={updateEmail}
              />
            </label>
            <label className="col-6">
              <FormattedMessage id="views.admin.health.reportInterval" />
              {':'}
              <br />
              <select
                onChange={handleFrequency}
                className="form-control w-100"
              >
                {frequencyOptions.map((option) => {
                  return (
                    <option value={option}>
                      {option}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
        */}
        <table className="table table-borderless table-fixed" data-testid="health_vars_table">
          <ListHead />
          <tbody>
            <ListRows
              variables={healthVariables}
              handleTreshold={handleTreshold}
            />
          </tbody>
        </table>
      </div>
    </div>
  );
};

HealthVariables.propTypes = propTypes;
HealthVariables.defaultProps = defaultProps;

export default HealthVariables;
