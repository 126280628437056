import { createCrudActionCreators } from 'src/core/api';
import {
  URL,
  COLLECTION,
} from './constants';

export const {
  create: createMediaBuild,
  readAll: fetchMediaBuilds,
  destroy: deleteMediaBuild,
} = createCrudActionCreators({ collection: COLLECTION, url: URL });
