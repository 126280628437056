import { REMOVE_DASHBOARD_BADGE } from '../actionTypes';
import { PRIMARY_DASHBOARD_ID } from '../constants';

export default function removeDashboardBadge(
  { badgeId, dashboardId = PRIMARY_DASHBOARD_ID } = {},
) {
  return {
    payload: { dashboardId, badgeId },
    type: REMOVE_DASHBOARD_BADGE,
  };
}
