import React, { memo, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { sortBy, uniq } from 'lodash';
import cancan from 'js/system/cancan';

import { DataTableFilter } from 'js/react/components/DataTable/DataTableFilter';
import { getGroupsSortedByName, getGroupsInObject } from 'js/redux/selectors';
import { DataTableContext } from 'js/react/components/DataTable/DataTableContext';
import { ADMIN_GROUP_MANAGEMENT } from 'constants/permissions';

export const PlaceFilters = memo(() => {
  const groupsSortedByName = useSelector(getGroupsSortedByName);
  const groupsById = useSelector(getGroupsInObject);
  const { items, processedItems } = useContext(DataTableContext);

  const placeTypeOptions = useMemo(() => {
    const placeTypes = uniq((items || [])
      .filter((place) => place.state !== 'trashed')
      .reduce(
        (acc, item) => [...acc, item.type.toLocaleLowerCase()],
        [],
      ));

    return sortBy(placeTypes.map((placeType) => {
      return {
        id: placeType,
        translationKey: `views.places.${placeType}`,
      };
    }), (({ id }) => id));
  }, [items]);

  const placesContainUnknownGroups = useMemo(() => {
    return !!items.find(({ group: groupId }) => {
      return !groupId || !groupsById?.[groupId];
    });
  }, [items, groupsById]);

  return (
    <>
      <em className="d-block py-2" data-testid="places-count-label">
        <FormattedMessage id="views.places.numberOfPlaces" />{': '}
        {processedItems.length}
      </em>
      <div className="row">
        <DataTableFilter
          wrapperClassNames="col-md-6"
          options={placeTypeOptions}
          placeholderTranslationKey="views.places.filterByPlaceType"
          testId="MultiselectFilter-placeType"
          localStorageKey="placelist-filters"
          propertyName="type"
        />
        {cancan.can('read', ADMIN_GROUP_MANAGEMENT) && (
          <DataTableFilter
            options={groupsSortedByName}
            optionsCanBeUnknown={placesContainUnknownGroups}
            placeholderTranslationKey="common.filterByGroup"
            testId="MultiselectFilter-group"
            localStorageKey="placelist-filters-groups"
            propertyName="group"
          />
        )}
      </div>
    </>
  );
});
