import React from 'react';

export const Sortable = function ({sortBy, sort, asc, onClick, label}) {
    let cls = 'icon-chevron-right';

    if (sortBy === sort) {
        cls = asc ? 'icon-chevron-up' : 'icon-chevron-down';
    }

    return (
        <span onClick={onClick(sort)} className="pointer">
            <i className={'' + cls}/> {label || sort}
        </span>
    );
};

function isNotPossibleToCompare(value) {
    return value === null || value === undefined || value === '';
}

class SortUtil extends React.Component {
    constructor(props) {
        super(props);

        const sortParams = {
            asc : props.asc !== false,
            sortBy : props.sortBy || 'name'
        };

        this.state = {
            ...sortParams,
            items : this.sortItems(props.items, sortParams)
        };
    }

    UNSAFE_componentWillReceiveProps(np) {
        if (np.items !== this.props.items) {
            this.setState({
                items : this.sortItems(np.items)
            });
        }
    }

    sortItems = (items, state) => {
        const { asc, sortBy } = (state || this.state);

        const sorted = (items || this.props.items).sortBy((item) => item.get(sortBy), (valueA, valueB) => {
            const aIsNotPossibleToCompare = isNotPossibleToCompare(valueA);
            const bIsNotPossibleToCompare = isNotPossibleToCompare(valueB);

            if (aIsNotPossibleToCompare && bIsNotPossibleToCompare) {
                return 0;
            }

            if (aIsNotPossibleToCompare) {
                return 1;
            }

            if (bIsNotPossibleToCompare) {
                return -1;
            }

            if (valueA < valueB) {
                return asc ? -1 : 1;
            }

            if (valueA > valueB) {
                return asc ? 1 : -1;
            }

            return 0;
        });

        return sorted;
    }

    handleSort = (sortBy) => {
        return (e) => {
            e.preventDefault();
            const asc = this.state.sortBy === sortBy ? !this.state.asc : true;
            this.setState({
                sortBy, asc,
                items : this.sortItems(this.props.items, {sortBy, asc})
            });
        };
    }
}

export function sortWrapper(Component) {
    return class Sorter extends SortUtil {
        render() {
            return (
                <Component
                    {...this.props}
                    {...this.state}
                    handleSort = {this.handleSort}
                />
            );
        }
    };
}

export default SortUtil;