import React from 'react';

function Version() {
  return (
    <div className="card m-4">
      <div className="card-body">
        <h6>Current version:</h6>
        <h3>{process.env.GS_VERSION}</h3>
        <h6>Build time</h6>
        <p>{new Date(process.env.BUILD_TIME).toString()}</p>
      </div>
    </div>
  );
}

export default Version;
