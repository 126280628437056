import React from 'react';
import { useIntl } from 'react-intl';
import { TIMEOFDAY_CONDITION_UPPER, TIMEOFDAY_CONDITION_LOWER } from '../constants';
import { useFieldCondition } from '../hooks/useFieldCondition';
import { ConditionSection } from './ConditionSection';

const convertValue = (value) => (value
  ? `${value.hours}:${value.minutes}`
  : '');

const LimitInput = ({
  conditionName, operator, min, max,
}) => {
  const [fieldValue, onChange] = useFieldCondition(conditionName, 'and');
  const { value } = fieldValue || {};
  const handleChange = (e) => {
    let [hours, minutes] = e.target.value.split(':');
    const hh = parseInt(hours, 10);
    const mm = parseInt(minutes, 10);
    const mxh = max && parseInt(max.hours, 10);
    const mxm = max && parseInt(max.minutes, 10);
    const mnh = min && parseInt(min.hours, 10);
    const mnm = min && parseInt(min.minutes, 10);

    if (hh > mxh || (hh === mxh && mm > mxm)) {
      hours = mxh;
      minutes = mxm;
    }

    if (hh < mnh || (hh === mnh && mm < mnm)) {
      hours = mnh;
      minutes = mnm;
    }

    onChange({
      hours, minutes,
    }, operator);
  };

  return (
    <div className="btn-group mr-2">
      <input
        type="time"
        className="form-control"
        value={convertValue(value)}
        onChange={handleChange}
        min={min ? convertValue(min) : undefined}
        max={max ? convertValue(max) : undefined}
        step={60 * 5}
      />
      <button
        type="button"
        className="btn border"
        aria-label="clear"
        onClick={() => onChange(null)}
      >
        <span aria-hidden="true">{'×'}</span>
      </button>
    </div>
  );
};

export const TimeOfDayCondition = () => {
  const { formatMessage } = useIntl();
  const [lower] = useFieldCondition(TIMEOFDAY_CONDITION_LOWER, 'and');
  const [upper] = useFieldCondition(TIMEOFDAY_CONDITION_UPPER, 'and');

  return (
    <ConditionSection
      label={formatMessage({ id: 'views.planning.playbackConditions.timeOfDay' })}
    >
      <div className="d-flex">
        <LimitInput
          conditionName={TIMEOFDAY_CONDITION_LOWER}
          operator="gte"
          max={upper?.value}
        />
        <LimitInput
          conditionName={TIMEOFDAY_CONDITION_UPPER}
          operator="lte"
          min={lower?.value}
        />
      </div>
    </ConditionSection>
  );
};
