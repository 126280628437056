import React from 'react';
import debug from 'debug';

const log = debug('src:components:DataTable:Container');

export const DataTableContainer = ({ children }) => {
  log('render');
  return (
    <div className="table_container">
      <table className={'table table-fixed table-striped table-sm mb-0'}>
        {children}
      </table>
    </div>
  );
};
