import map from 'lodash/map';
import isArray from 'lodash/isArray';
import reduce from 'lodash/reduce';
import each from 'lodash/each';

function stopListening() {
  each(this._tzListeners, (l) => {
    l.off();
  });
}

const storeListener = {
  componentWillUnmount() {
    stopListening.call(this);
  },

  listenTo(stores, e, callback, context) {
    let l; const
      events = e.split(' ');

    function listener(store) {
      return map(events, (ev) => {
        return store.on(ev, (...args) => {
          callback.apply(context || this, args);
        });
      });
    }

    this._tzListeners = this._tzListeners || [];

    if (isArray(stores)) {
      l = reduce(stores, (acc, store) => {
        return acc.concat(listener(store));
      }, []);
    } else {
      l = listener(stores);
    }
    this._tzListeners = this._tzListeners.concat(l);

    return l.length === 1 ? l[0] : l;
  },

  stopListening() {
    stopListening.call(this);
  },
};

export default storeListener;
