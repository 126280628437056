import Immutable from 'immutable';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';

import cancan from 'js/system/cancan';
import moment from 'moment';

import { fetchPlaylists } from 'js/redux/api/data';
import {
  fetchPlanned, getPlanned, fetchConfigs, getConfigs,
} from 'src/entities/plannedMedia';
import { setHelpModal } from 'js/redux/helpModal/actionCreators';
import HelpIcon from 'components/HelpIcon';
import * as selectors from 'js/redux/selectors';
import { getMediaObjects } from 'src/entities/media';
import { PLANNING_TIMEPLAN } from 'constants/permissions';
import DateBar from '../date-bar';
import templateUtils, { plannedLimits } from './template-utils';
import PlaylistList from './list';

export function packCollection(items) {
  return Immutable.fromJS(items)
    .reduce(
      (acc, i) => acc.set(`${i.get('id')}`, i),
      Immutable.Map().asMutable(),
    )
    .asImmutable();
}

export function propCompare(np, op) {
  return (acc, collection) => {
    const nc = np[collection];
    if (nc !== op[collection]) {
      acc[collection] = packCollection(nc);
    }
    return acc;
  };
}

const filterPlan = createSelector(
  selectors.getFromDate,
  selectors.getToDate,
  getPlanned,
  (fromDate, toDate, planned) => planned?.filter((item) => {
    const f = moment(item.time_limit_lower);
    const t = moment(item.time_limit_upper);

    return f.isBefore(toDate) && t.isAfter(fromDate);
  }),
);

// Connect to redux store
function mapStateToProps(state) {
  return {
    playlists: selectors.getPlaylists(state),
    planned: filterPlan(state),
    mediaObjects: getMediaObjects(state),
    configs: getConfigs(state),
    planLocked: state.plan.get('planLocked'),

    fromDate: selectors.getFromDate(state),
    toDate: selectors.getToDate(state),
  };
}

const mapDispatchToProps = {
  fetchPlaylists,
  fetchPlanned,
  fetchConfigs,
  setHelpModal,
};

class PlaylistLibrary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playlists: Immutable.Map(),
      planned: Immutable.Map(),
      mediaObjects: Immutable.Map(),
      timeplan: cancan.can('read', PLANNING_TIMEPLAN),
    };
  }

  componentDidMount() {
    // TODO: Ensure data is fetched as desired
    this.props.fetchPlaylists();
    this.props.fetchPlanned(this.props.fromDate, this.props.toDate);
    this.state.timeplan && this.props.fetchConfigs();
  }

  UNSAFE_componentWillReceiveProps(np) {
    const reducer = propCompare(np, this.props);
    const state = ['playlists', 'planned', 'mediaObjects'].reduce(reducer, {});

    state.planned = plannedLimits(
      Immutable.fromJS(state.planned || this.state.planned),
    );

    if (
      np.fromDate !== this.props.fromDate ||
      np.toDate !== this.props.toDate
    ) {
      this.props.fetchPlanned(np.fromDate, np.toDate);
    }

    state.templates = this.prepTemplates(state, np.configs);

    this.setState(state);
  }

  prepTemplates(newState, configs = []) {
    const completeState = {
      ...this.state,
      ...newState,
    };

    if (completeState.playlists.size > 0) {
      return templateUtils(
        completeState.playlists,
        completeState.planned,
        completeState.mediaObjects,
        configs,
      );
    }

    return this.state.templates;
  }

  render() {
    const { date } = this.props;
    const hasTimeplan = cancan.can('read', PLANNING_TIMEPLAN);

    return (
      <div className={this.props.className}>
        <div className="pa15 d-flex">
          <h3 className="ma-0">
            <FormattedMessage id="common.planning" />
            <HelpIcon
              bodyKey="help.planning.body"
              headerKey="help.planning.header"
            />
          </h3>
          <a
            href="/#/plan/mediaLibrary"
            className="d-sm-none ml-auto"
          >
            <button className="btn btn-secondary" type="button">
              <FormattedMessage id="views.planning.playlists.browseMedia" />
            </button>
          </a>
        </div>
        {hasTimeplan && (
          <div className="d-sm-none">
            <DateBar
              noDateSetup
              testId="playlist-list-datebar"
              date={date}
            />
          </div>
        )}
        <PlaylistList
          date={date}
          items={this.state.playlists}
          templates={this.state.templates}
          planLocked={this.props.planLocked}
          fromDate={this.props.fromDate}
          toDate={this.props.toDate}
        />
      </div>
    );
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
)(PlaylistLibrary);
