import {
  CLIENTS, HISTORY, PLANNING, REPORT, SIMULATION, VIEW_PASSWORD,
} from 'constants/permissions';

import Plan from 'js/react/plan2/plan';
import Playlists from 'js/react/playlists/playlists';
import Version from 'js/react/Version';
import ChangeLanguage from 'js/react/admin/UserManagement/ChangeLanguage';
import ChangePassword from 'js/react/admin/UserManagement/ChangePassword';
import AdminMediaBuild from 'js/react/admin/mediabuild';
import Admin from 'js/react/admin';
import AdminActivity from 'js/react/admin/activity';
import AdminUserManagement from 'js/react/admin/UserManagement';
import AdminGroupManagement from 'js/react/admin/GroupManagement';
import History from 'js/react/history/history';
import Report from 'js/react/report/report';

import Places from 'src/screens/Places';
import Simulation from 'src/screens/Simulation';
import Clients from 'src/screens/Clients';
import { ClientGroupEditorSidebar } from 'src/screens/Clients/components/ClientGroupEditorSidebar';
import { HealthVariablesSidebar } from 'src/screens/Clients/components/HealthVariablesSidebar';

const routes = [
  {
    path: ['/places/:id*', '/places'],
    component: Places,
    /* routes: [
      {
        path: "/places/create",
        component: require('src/screens/Places/components/AddPlace').AddPlace,
      },
      {
        path: "/places/edit/:id",
        component: require('src/screens/Places/components/EditPlace').EditPlace,
      },
    ] */
  },
  {
    path: ['/playlists/:id/:tab', '/playlists/edit-groups', '/playlists'],
    component: Playlists,
  },
  {
    path: [
      '/clients',
    ],
    component: Clients,
    can: CLIENTS,
    routes: [
      {
        path: '/clients/health_variables',
        component: HealthVariablesSidebar,
      },
      {
        path: '/clients/client_groups/:clientId/edit',
        component: ClientGroupEditorSidebar,
      },
    ],
  },
  {
    path: '/simulation2',
    component: Simulation,
    can: SIMULATION,
  },
  {
    path: ['/history/:actions*', '/history'],
    component: History,
    can: HISTORY,
  },
  {
    path: '/report',
    component: Report,
    can: REPORT,
  },
  {
    path: '/admin',
    component: Admin,
    routes: [
      {
        path: '/admin/mediabuild',
        component: AdminMediaBuild,
      },
      {
        path: '/admin/activity',
        component: AdminActivity,
      },
      {
        path: '/admin/groups',
        component: AdminGroupManagement,
      },
      {
        path: ['/admin/users', '/admin'],
        component: AdminUserManagement,
      },
    ],
  },
  {
    path: '/usersettings',
    component: ChangePassword,
    can: VIEW_PASSWORD,
  },
  {
    path: '/language',
    component: ChangeLanguage,
  },
  {
    path: '/version',
    component: Version,
  },
  {
    path: [
      '/plan/createMedia/:widgetId;:date;:interval',
      '/plan/createMedia/:widgetId;:date',
      '/plan/createMedia/:widgetId',
    ],
    component: Plan,
    can: PLANNING,
  },
  {
    path: [
      '/plan/:sidebar/:activeId;:date;:interval',
      '/plan/:sidebar/:activeId;:date',
      '/plan/:sidebar/:activeId',
      '/plan/:sidebar;:date',
      '/plan/:sidebar',
    ],
    component: Plan,
    can: PLANNING,
  },
  {
    path: ['/plan', '/'],
    component: Plan,
    can: PLANNING,
  },
];

export default routes;
