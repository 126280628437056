import {
  API_GET_REQUEST,
  API_DELETE_REQUEST,
  API_POST_REQUEST,
  API_PUT_REQUEST,
} from '../constants';

export function appendSlashIfMissing(path) {
  if (path.endsWith('/')) {
    return path;
  }

  return `${path}/`;
}

export function getCsrfToken() {
  const cookieName = 'csrftoken';
  let cookieValue = null;

  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, cookieName.length + 1) === (`${cookieName}=`)) {
        cookieValue = decodeURIComponent(cookie.substring(cookieName.length + 1));
        break;
      }
    }
  }

  return cookieValue;
}

export function getHttpMethod({ type }) {
  if (type.includes(API_GET_REQUEST)) return 'GET';
  if (type.includes(API_DELETE_REQUEST)) return 'DELETE';
  if (type.includes(API_POST_REQUEST)) return 'POST';
  if (type.includes(API_PUT_REQUEST)) return 'PUT';

  throw new Error('Unknown HTTP method');
}
