/* jshint white:true, browser:true, unused:true, undef:true, newcap:true, latedef:true, indent:4, forin:true, camelcase:true, esnext:true */
/* global module, require */

/*
	Put any global overrides, extends or resets here.
*/

// Handle missing console.log in IE
if (typeof (console) === 'undefined') {
  console = {};
}
if (typeof (console.log) === 'undefined') {
  console.log = function () {};
}
if (typeof (console.trace) === 'undefined') {
  console.trace = function () {};
}
if (typeof (console.error) === 'undefined') {
  console.error = function () {};
}
if (typeof (console.warn) === 'undefined') {
  console.warn = function () {};
}

// Globals (for use in console for debugging)
window.ROOT_PATH = window.ROOT_PATH || '';
window.GS_API = `${window.ROOT_PATH}/api/gui`;
