import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js';
// import Legend from './Legend';

class PieChart extends React.Component {
  componentDidUpdate(pp) {
    if (pp.data !== this.props.data) {
      this.updateChart();
    }
  }

  componentWillUnmount() {
    this.chart && this.chart.destroy && this.chart.destroy();
  }

  setCanvas = (canvas) => {
    this.canvas = canvas;
    this.initChart();
  }

  initChart = () => {
    if (this.canvas) {
      // const options = {
      //   animationEasing: 'easeOut',
      //   animationSteps: 1,
      //   tooltipTemplate:
      //     '<%if (label){%><%=label%>: <%}%><%= value.toFixed(2) %>',
      // };

      const ctx = this.canvas.getContext('2d');
      this.chart = new Chart(ctx, {
        type: 'pie',
        data: this.props.data,
        // options: options
        options: {
          legend: false,
          maintainAspectRatio: false,
          // animation : false,
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                const dataset = data.datasets[tooltipItem.datasetIndex];
                const total = dataset.data.reduce((previousValue, currentValue) => {
                  return previousValue + currentValue;
                });
                const currentValue = dataset.data[tooltipItem.index];
                const percentage = Math.floor(((currentValue/total) * 100)+0.5);
                const label = data.labels[tooltipItem.index];
                return `${label}: ${percentage}%`;
              },
            },
          },
        },
      });
    }
  }

  updateChart() {
    if (this.chart && this.props.data) {
      this.chart.data.labels = this.props.data.labels;
      this.chart.data.datasets = this.props.data.datasets;
      this.chart.update();
    } else {
      this.initChart();
    }
  }

  render() {
    return (
      <div className="">
        <div className="text-center">
          <canvas
            ref={this.setCanvas}
            width="500px"
            height="300px"
          />
        </div>
        {/* <div className="col-6">
          <Legend
            dataset={(this.props.data.datasets || [])[0]}
            labels={this.props.data.labels || []}
            limit={8}
          />
        </div> */}
      </div>
    );
  }
}

PieChart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PieChart;
