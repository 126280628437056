export const ADD_DASHBOARD_BADGE = 'ADD_DASHBOARD_BADGE';
export const ADD_DASHBOARD_WIDGET = 'ADD_DASHBOARD_WIDGET';
export const BOOTSTRAP_DASHBOARDS = 'BOOTSTRAP_DASHBOARDS';
export const CREATE_DASHBOARD = 'CREATE_DASHBOARD';
export const DELETE_DASHBOARD = 'DELETE_DASHBOARD';
export const FETCH_REPORTS_BY_DATE = '@DATA_FETCH_REPORTS_BY_DATE';
export const FETCH_REPORTS_BY_DATE_FAIL = '@DATA_FETCH_REPORTS_BY_DATE_FAIL';
export const FETCH_REPORTS_BY_DATE_SUCCESS =
  '@DATA_FETCH_REPORTS_BY_DATE_SUCCESS';
export const REMOVE_DASHBOARD_BADGE = 'REMOVE_DASHBOARD_BADGE';
export const REMOVE_DASHBOARD_WIDGET = 'REMOVE_DASHBOARD_WIDGET';
export const SET_DASHBOARDS_CONFIG = 'SET_DASHBOARDS_CONFIG';
export const UPDATE_DASHBOARD_BADGES = 'UPDATE_DASHBOARD_BADGES';
export const UPDATE_DASHBOARD_WIDGET_SETTINGS =
  'UPDATE_DASHBOARD_WIDGET_SETTINGS';
export const UPDATE_DASHBOARD_WIDGET_TITLE = 'UPDATE_DASHBOARD_WIDGET_TITLE';
export const UPDATE_DASHBOARD_WIDGETS = 'UPDATE_DASHBOARD_WIDGETS';
