import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createSelector } from 'reselect';
import { isObject } from 'lodash';
import cancan from 'js/system/cancan';
import {
  fetchAnnouncementTags,
  createAnnouncement,
  fetchAnnouncement,
  updateAnnouncement,
  getAnnouncement,
  getAnnouncementTags,
  getMediaInObject,
  getApprovedAudio,
} from 'src/entities/media';
import {
  ANNOUNCEMENT_CHANNEL_AUDIO,
  ANNOUNCEMENT_CHANNEL_MEDIA,
  ANNOUNCEMENT_CHANNEL_TEXTEXTERIOR,
  ANNOUNCEMENT_CHANNEL_TEXTINTERIOR,
} from 'constants/permissions';
import { SpeechAudioInput } from 'src/components/SpeechAudioInput';
import MediaPicker from 'js/react/components/MediaPicker';
import * as MsgActions from 'js/react/actions/message-actions';
import { useRequestWithPromise } from 'src/core/api';
import AnnouncementText from './AnnouncementText';
import {
  calculateTextDuration,
  calculateAudioDuration,
  calculateMediaDuration,
} from './calculateAnnouncementDuration';

const propTypes = {
  announcementId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

function onlyImage({ type }) {
  return type === 'I';
}

const getErrorMsg = (payload) => Object.entries(payload || {})
  .map(([key, value]) => `${key}: ${value}`)
  .join('.\n ');

const getTags = createSelector(
  getAnnouncementTags,
  (tags) => Object.values(tags),
);

const AnnouncementCreator = ({ announcementId, onClose }) => {
  const { dispatch, pending: loading } = useRequestWithPromise();
  const announcement = useSelector(getAnnouncement(announcementId));
  const tags = useSelector(getTags);
  const allMedia = useSelector(getMediaInObject);
  const approvedAudio = useSelector(getApprovedAudio);
  const [formData, setData] = useState({
    name: '',
    length: 10,
    config: {
      textInterior: '',
      textExterior: '',
      audios: [],
      mediaIds: [],
    },
  });

  useEffect(() => {
    if (announcementId) {
      dispatch(fetchAnnouncement(announcementId));
    }

    dispatch(fetchAnnouncementTags());
  }, []);

  useEffect(() => {
    if (announcement) {
      // Overwrite state if init data changes
      const requiredKeys = ['textInterior', 'textExterior', 'audios', 'mediaIds'];
      if (!isObject(announcement?.config) || !requiredKeys.every((prop) => Object.hasOwnProperty.call(announcement.config, prop))) {
        MsgActions.error('Internal error, contact support');
      }
      setData(announcement);
    }
  }, [announcement]);

  const renderNameInput = () => {
    return (
      <div>
        <h5 className="border-bottom my-3 pb-2">
          <FormattedMessage id="common.name" />
        </h5>
        <input
          type="text"
          value={formData.name}
          onChange={(val) => {
            val.persist();
            setData((data) => ({
              ...data,
              name: val.target.value,
            }));
          }}
          disabled={loading}
          className="form-control"
          data-testid="announcement-name-input"
        />
      </div>
    );
  };

  const renderTextEditor = (field, label) => {
    return (
      <div data-testid="AnnouncementCreatorTextEditor">
        <h5 className="border-bottom my-3 pb-2">
          <FormattedMessage id={label} />
        </h5>
        <AnnouncementText
          onChange={(val) => setData((data) => ({
            ...data,
            config: ({
              ...data.config,
              [field]: val,
            }),
          }))}
          value={formData.config[field]}
          tags={tags}
          disabled={loading}
        />
      </div>
    );
  };

  const renderAudioPicker = () => {
    return (
      <div data-testid="AnnouncementCreatorAudioPicker">
        <h5 className="border-bottom my-3 pb-2">
          <FormattedMessage id="views.planning.media.audio" />
        </h5>
        <SpeechAudioInput
          audioConfig={formData.config?.audios}
          onChange={(val) => setData((data) => {
            if (!isObject(data.config)) {
              MsgActions.error('Internal error, contact support');
              return data;
            }

            return ({
              ...data,
              config: ({
                ...data.config,
                audios: val,
              }),
            })
          })}
        />
      </div>
    );
  };

  const renderMediaPicker = () => {
    return (
      <div data-testid="AnnouncementCreatorMediaPicker">
        <h5 className="border-bottom my-3 pb-2">
          <FormattedMessage id="common.media" />
        </h5>
        <MediaPicker
          onChange={(val) => setData((data) => {
            if (!isObject(data.config)) {
              MsgActions.error('Internal error, contact support');
              return data;
            }

            return ({
              ...data,
              config: ({
                ...data.config,
                mediaIds: val,
              }),
            })
          })}
          selected={formData.config.mediaIds}
          mediaFilter={onlyImage}
        />
      </div>
    );
  };

  const calculateDuration = () => {
    const interiorDuration = calculateTextDuration(formData.config.textInterior);
    const extDuration = calculateTextDuration(formData.config.textExterior);
    const textToSpeech = (formData.config.audios || [])
      .filter(({ type }) => type === 'text')
      .map(({ value }) => value).join(' ');
    const audioTokenIds = (formData.config.audios || [])
      .filter(({ type }) => type !== 'text')
      .map(({ value }) => value);
    const speechDuration = calculateTextDuration(textToSpeech);
    const audioDuration = calculateAudioDuration(audioTokenIds, approvedAudio, tags);
    const mediaDuration = calculateMediaDuration(formData.config.mediaIds, allMedia);

    // eslint-disable-next-line no-console
    console.log(`
      interiorDuration: ${interiorDuration}\n
      extDuration: ${extDuration}\n
      audioDuration: ${audioDuration + speechDuration}\n
      mediaDuration: ${mediaDuration}\n
    `);

    return Math.ceil(
      Math.max(interiorDuration, extDuration, audioDuration + speechDuration, mediaDuration),
    );
  };

  const handleSubmit = () => {
    const data = {
      name: formData.name,
      length: Math.min(calculateDuration(), 1), // Ensure we have a valid length,
      media_type: 'announcement',
      description: '',
      config: {
        textInterior: formData.config.textInterior,
        textExterior: formData.config.textExterior,
        audios: formData.config.audios || [],
        mediaIds: formData.config.mediaIds?.filter((id) => id),
      },
    };

    if (announcementId) {
      if (!isObject(announcement)) {
        MsgActions.error('Internal error, contact support');
        return;
      }

      dispatch(updateAnnouncement({
        ...announcement,
        ...data,
      }))
        .then((action) => {
          MsgActions.success(`Edit announcement: ${action.payload.name}`);
          onClose?.();
        })
        .catch(({ payload }) => {
          MsgActions.error(getErrorMsg(payload));
        });
    } else {
      dispatch(createAnnouncement(data))
        .then((action) => {
          MsgActions.success(`Saved announcement: ${action.payload.name}`);
          onClose?.();
        })
        .catch(({ payload }) => {
          MsgActions.error(getErrorMsg(payload));
        });
    }
  };

  return (
    <div className="holder bg-white p-3">
      {onClose && (
        <button
          type="button"
          aria-label="Close"
          className="close d-none d-sm-inline-block"
          onClick={onClose}
        >
          <span aria-hidden="true">{'\u00D7'}</span>
        </button>
      )}
      <h3>
        {announcementId
          ? <FormattedMessage id="views.planning.announcement.edit" />
          : <FormattedMessage id="views.planning.announcement.create" />}
      </h3>

      {renderNameInput()}
      {cancan.can('read', ANNOUNCEMENT_CHANNEL_TEXTINTERIOR) && renderTextEditor('textInterior', 'views.planning.announcement.InteriorText')}
      {cancan.can('read', ANNOUNCEMENT_CHANNEL_TEXTEXTERIOR) && renderTextEditor('textExterior', 'views.planning.announcement.ExteriorText')}
      {cancan.can('read', ANNOUNCEMENT_CHANNEL_AUDIO) && renderAudioPicker()}
      {cancan.can('read', ANNOUNCEMENT_CHANNEL_MEDIA) && renderMediaPicker()}

      <button
        type="button"
        className="btn btn-primary mt-2"
        onClick={loading ? undefined : handleSubmit}
        disabled={loading}
      >
        {announcementId
          ? <FormattedMessage id="views.planning.announcement.update" />
          : <FormattedMessage id="views.planning.announcement.create" />}
      </button>
    </div>
  );
};

AnnouncementCreator.propTypes = propTypes;

export default AnnouncementCreator;
