import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
// Utils
import cancan from 'js/system/cancan';
import * as MsgActions from 'js/react/actions/message-actions';
import DeleteConfirm from 'components/DeleteConfirm';
import GroupSelect from 'components/GroupSelect';
import { ADMIN_GROUP_MANAGEMENT } from 'constants/permissions';
import { useRequestWithPromise } from 'src/core/api';
import {
  deletePlaylist, fetchPlaylist, getPlaylistById, updatePlaylist,
} from 'src/entities/playlists';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

const propTypes = {
  playlistId: PropTypes.string.isRequired,
};

export const EditPlaylist = ({ playlistId }) => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const { dispatch } = useRequestWithPromise();
  const [loading, setLoading] = useState(true);
  const playlist = useSelector(getPlaylistById(playlistId));
  const typeId = `common.${playlist?.type}`;
  const subTypeId = `common.${playlist?.sub_type}`;

  useEffect(() => {
    !playlist && dispatch(fetchPlaylist(playlistId)).then(() => {
      setLoading(false);
    });
  }, []);

  /*
        Methods
    */
  // Called when a playlist field is changed
  const handlePlaylistChange = async (event) => {
    const field = event.target;

    // Make sure we have changed the value
    if (field.value !== playlist[field.name]) {
      // Make a copy and update
      const newPl = { ...playlist };
      newPl[field.name] = field.value;

      setLoading(true);

      try {
        await dispatch(updatePlaylist(newPl));
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
  };

  const handleRemovePlaylist = () => {
    const { name } = playlist;
    dispatch(deletePlaylist(playlistId))
      .then(() => {
        MsgActions.success(
          `Playlist: ${name} deleted`,
        );
        history.push('/playlists');
      });
  };

  if (loading && isEmpty(playlist)) {
    return (
      <div className="animate module_body">
        <FormattedMessage id="common.loading" />
        {'...'}
      </div>
    );
  }

  if (isEmpty(playlist)) {
    return (
      <div className="animate module_body">
        {'Not found'}
      </div>
    );
  }

  return (
    <div className="animate module_body">
      <div className="bg-white module_content edit-module pa15 rounded">
        <table className="settings w-75 v_top">
          <tbody>
            <tr className="control-group">
              <td>
                <label htmlFor="name">
                  <FormattedMessage id="common.name" />
                  {': '}
                </label>
              </td>
              <td>
                <input
                  name="name"
                  type="text"
                  className="form-control mb-1"
                  defaultValue={playlist.name}
                  onBlur={handlePlaylistChange}
                />
              </td>
            </tr>
            <tr className="control-group">
              <td>
                <label htmlFor="description">
                  <FormattedMessage id="common.description" />
                  {': '}
                </label>
              </td>
              <td>
                <textarea
                  name="description"
                  rows="5"
                  type="text"
                  className="form-control mb-1"
                  defaultValue={playlist.description}
                  onBlur={handlePlaylistChange}
                />
              </td>
            </tr>
            <tr className="control-group">
              <td>
                <FormattedMessage id="common.type" />
                {': '}
              </td>
              <td className="pb-1">
                <b>
                  <FormattedMessage
                    id={typeId}
                  />
                </b>
              </td>
            </tr>
            <tr className="control-group">
              <td>
                <FormattedMessage id="common.subtype" />
                {': '}
              </td>
              <td className="pb-1">
                <b>
                  <FormattedMessage
                    id={subTypeId}
                  />
                </b>
              </td>
            </tr>
            <tr className="control-group">
              <td>
                <label htmlFor="max_time">
                  <FormattedMessage id="common.length" />
                  {'('}
                  <FormattedMessage id="common.secondsAbbreviation" />
                  {')'}
                  {': '}
                </label>
              </td>
              <td>
                <input
                  name="max_time"
                  type="number"
                  className="form-control mb-1"
                  defaultValue={playlist.max_time}
                  onBlur={handlePlaylistChange}
                />
              </td>
            </tr>
            {cancan.can('read', ADMIN_GROUP_MANAGEMENT) && (
              <tr className="control-group">
                <td>
                  <FormattedMessage id="common.group" />
                  {':'}
                </td>
                <td>
                  <GroupSelect
                    onChange={handlePlaylistChange}
                    selected={playlist.group}
                  />
                </td>
              </tr>
            )}
            {playlist.type === 'direct' && (
              <tr>
                <td>
                  <FormattedMessage id="common.priority" />
                  {': '}
                </td>
                <td>
                  <select
                    type="select"
                    name="priority"
                    className="form-control mb-1"
                    onBlur={handlePlaylistChange}
                    defaultValue={playlist.priority}
                  >
                    <option value="40">
                      {formatMessage({
                        id: 'common.high',
                      })}
                    </option>
                    <option value="50">
                      {formatMessage({
                        id: 'common.veryHigh',
                      })}
                    </option>
                    <option value="1000">
                      {formatMessage({
                        id: 'common.Extreme',
                      })}
                    </option>
                  </select>
                </td>
              </tr>
            )}
            <tr className="control-group" />
          </tbody>
        </table>
      </div>

      <div className="bg-white module_content edit-module pa15 rounded">
        <h6>
          <FormattedMessage id="views.playlists.editPlaylist.removePlaylist" />
          {':'}
        </h6>
        <DeleteConfirm onDelete={handleRemovePlaylist} />
      </div>
    </div>
  );
};

EditPlaylist.propTypes = propTypes;
