import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getMediaInObject } from 'src/entities/media';
import { getPlaylistsInObject } from 'src/entities/playlists';

import {
  setError, setHistory,
} from '../../components/StateManager';

// Poll playback history
export const usePollHistory = ({
  error,
  history,
  schemeId,
  clientGroup,
}, request, rootPath, dispatch) => {
  const mediaById = useSelector(getMediaInObject);
  const playlistsById = useSelector(getPlaylistsInObject);

  const buildMediaItem = (mediaItem) => {
    if (error || mediaItem.media_id === -1) {
      dispatch(setError('No media found in scheme'));

      return null;
    }

    const media = mediaById[mediaItem.media_id];
    const playlist = playlistsById[mediaItem.playlist] || {};

    return {
      ...media,
      playlist,
      id: mediaItem.id,
    };
  };

  const requestHistory = async () => {
    if (!rootPath || error) {
      return;
    }

    let url = `${rootPath}/api/v1/history`;
    if (clientGroup === '') {
      url = `${url}/${schemeId}`;
    }

    const data = await request(url).then(
      (res) => res.json(),
    );

    const loadedData = data.map(buildMediaItem);

    dispatch(setHistory(loadedData));
  };

  useEffect(() => {
    const timer = setTimeout(requestHistory, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [rootPath, error, schemeId, history]);
};
