import React from 'react';
import { PagePicker } from './PagePicker';

class PaginatedList extends React.PureComponent {
  state = {
    currentIndex: 0,
  }

  getRows() {
    const { pageSize = 10 } = this.props;
    const { currentIndex } = this.state;

    if (currentIndex < 0 || pageSize < 0) {
      return this.props.children;
    }

    const allRows = React.Children.toArray(this.props.children);
    return allRows.slice(currentIndex, currentIndex + pageSize);
  }

  handleIndex = (currentIndex) => {
    this.setState({ currentIndex });
  }

  render() {
    const {
      Component = 'div',
      className = '',
      pagerClass,
      children,
      pageSize = 0,
      ...other
    } = this.props;

    return (
      <div className={className}>
        <Component {...other}>
          {this.getRows()}
        </Component>
        <PagePicker
          className={pagerClass}
          pageSize={pageSize}
          totalItems={React.Children.count(children)}
          currentIndex={this.state.currentIndex}
          onChange={this.handleIndex}
          showAll={this.handleShowAll}
        />
      </div>
    );
  }
}

export default PaginatedList;
