import { useMemo } from 'react';

const getCSRFToken = () => {
  const results = document.cookie.match('(^|;)\\s*csrftoken\\s*=\\s*([^;]+)');
  return results ? results.pop() : '';
};

const createRequestWithToken = (token) => (url, type = 'GET', data = null) => {
  return fetch(url, {
    method: type,
    credentials: 'same-origin',
    body: data && JSON.stringify(data),
    headers: {
      'X-CSRFToken': token,
      'Content-Type': 'application/json',
    },
  });
};

export const useCSRFRequest = () => {
  return useMemo(() => {
    return createRequestWithToken(getCSRFToken());
  }, []);
};
