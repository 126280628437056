import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { intlShape } from 'utils/shapes';

import * as selectors from 'js/redux/selectors';
import {
  createUser,
  deleteUser,
  fetchGroups,
  fetchUsers,
  updateUserGroup,
  updateUserName,
  updateUserPassword,
} from 'js/redux/api/data';
import HelpIcon from 'components/HelpIcon';
import TabView from 'components/TabView';
import { success as successMessage } from 'js/react/actions/message-actions';
import AddUser from './AddUser';
import UserList from './UserList';
import PermissionsList from './UserViewPermissions/PermissionsList';

function mapStateToProps(state) {
  return {
    groups: selectors.getGroupsSortedByNameExceptAdmin(state),
    users: selectors.getUsersSortedByName(state),
  };
}
const mapDispatchToActions = {
  createUser,
  deleteUser,
  fetchGroups,
  fetchUsers,
  updateUserGroup,
  updateUserName,
  updateUserPassword,
};

const propTypes = {
  createUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  fetchGroups: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
  updateUserGroup: PropTypes.func.isRequired,
  updateUserName: PropTypes.func.isRequired,
  updateUserPassword: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
};
const defaultProps = {};

class UserManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    };
  }

  componentDidMount() {
    this.props.fetchGroups();
    this.props.fetchUsers();
  }

  handleRemove = (e) => {
    e.preventDefault();
    this.state.selected.forEach((userId) => {
      return this.props.deleteUser(userId).then(() => successMessage(
        this.props.intl.formatMessage({
          id: 'views.admin.userManagement.userDeleted',
        }),
      ));
    });
    this.setState({ selected: [] });
  };

  handleSelect = (id) => {
    const idx = this.state.selected.indexOf(id);
    const { selected } = this.state;

    if (idx === -1) {
      selected.push(id);
    } else {
      selected.splice(idx, 1);
    }

    this.setState({ selected });
  };

  createUser = (resource) => {
    return this.props.createUser(resource).then(() => successMessage(
      this.props.intl.formatMessage({
        id: 'views.admin.userManagement.userCreated',
      }),
    ));
  };

  selectAll = () => {
    let { selected } = this.state;
    if (selected.length === this.props.users.length && selected.length) {
      selected = [];
    } else {
      selected = this.props.users.map((g) => g.id);
    }

    this.setState({ selected });
  };

  updateUserGroup = (userId) => (group) => {
    return this.props.updateUserGroup({ userId, group }).then(() => successMessage(
      this.props.intl.formatMessage({
        id: 'views.admin.userManagement.userUpdated',
      }),
    ));
  };

  updateUserName = (userId) => (name) => {
    return this.props.updateUserName({ userId, name }).then(
      () => successMessage(
        this.props.intl.formatMessage({
          id: 'views.admin.userManagement.userUpdated',
        }),
      ),
      () => this.props.fetchUsers(),
    );
  };

  updateUserPassword = (userId) => (password) => {
    return this.props.updateUserPassword({ userId, password }).then(() => successMessage(
      this.props.intl.formatMessage({
        id: 'views.admin.userManagement.passwordUpdated',
      }),
    ));
  };

  renderList() {
    const { groups, users } = this.props;

    return (
      <UserList
        groups={groups}
        onSelect={this.handleSelect}
        selectAll={this.selectAll}
        selected={this.state.selected}
        updateUserGroup={this.updateUserGroup}
        updateUserName={this.updateUserName}
        updateUserPassword={this.updateUserPassword}
        users={users}
      />
    );
  }

  renderPermissions() {
    return (
      <PermissionsList
        users={this.props.users}
      />
    );
  }

  render() {
    const { groups, intl } = this.props;

    return (
      <>
        <div className="mb-3">
          <h2>
            <FormattedMessage id="views.admin.userManagement.userManagement" />
            <HelpIcon
              bodyKey="help.admin.userManagement.body"
              headerKey="help.admin.userManagement.header"
            />
          </h2>
          <div
            data-testid="add-user-container"
          >
            <AddUser groups={groups} createUser={this.createUser} />
            {this.state.selected.length > 0 && (
              <button
                type="button"
                className="btn btn-danger ml-2"
                onClick={this.handleRemove}
              >
                <i className="icon-trash icon-white" />{' '}
                <FormattedMessage id="views.admin.userManagement.removeSelectedUsers" />
              </button>
            )}
          </div>
        </div>
        <TabView
          tabs={[{
            id: 'edit',
            label: intl.formatMessage({
              id: 'common.users',
            }),
            'data-testid': 'user-admin-edit',
          }, {
            id: 'permissions',
            label: intl.formatMessage({
              id: 'views.admin.userManagement.Permissions',
            }),
            'data-testid': 'user-admin-permissions',
          }]}
        >
          {(tab) => (tab === 'edit'
            ? this.renderList()
            : this.renderPermissions()
          )}
        </TabView>
      </>
    );
  }
}

UserManage.propTypes = propTypes;
UserManage.defaultProps = defaultProps;

export default compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToActions,
  ),
)(UserManage);
