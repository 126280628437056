export default function aggregateReports({ reports, dateRange = [], settings = {} }) {
  return reports.reduce((acc, d) => {
    const val = (d[settings.aggregate && settings.aggregate.key] || '').toString();

    // include only media that matches current regex (or if regex is not set)
    const regex = settings.regex || '.*';
    const match = (val).match(new RegExp(regex));
    const mediaId = match && match[0];

    if (!mediaId) {
      return acc;
    }

    const {
      date, cnt, duration: dur, name,
    } = d;

    if (acc[`${mediaId}`]) {
      // aggregation record exists, update count and duration
      const dd = acc[mediaId];

      if (!dd.cnts[`${date}`]) {
        dd.cnts[`${date}`] = 0;
      }

      dd.cnts[`${date}`] += cnt;
      dd.tot += cnt;
      dd.dur += cnt * dur;
      return acc;
    }
    // aggregation does not exist
    const cnts = dateRange.reduce(
      (acc, d) => {
        acc[`${d}`] = 0;
        return acc;
      },
      {},
    );

    cnts[`${date}`] = cnt;

    acc[mediaId] = {
      cnts,
      tot: cnt,
      dur: cnt * dur,
      id: mediaId,
      name,
      duration: dur,
    };
    return acc;
  }, {});
}
