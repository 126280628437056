import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { fetchMediaObjects, getMediaObjects } from 'src/entities/media'; import GSCombobox from 'components/GSCombobox';

const propTypes = {
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func.isRequired,
  fetchMediaObjects: PropTypes.func.isRequired,
  media: PropTypes.array,
  custom: PropTypes.array,
  disabled: PropTypes.bool,
};

function ListItem({ item }) {
  return (
    <span className="w-100 text-nowrap">
      {item.thumb && (
        <img
          alt=""
          src={window.ROOT_PATH + item.thumb}
          style={{
            maxWidth: 60,
          }}
        />
      )} {item.name}
    </span>
  );
}

function filterMedia(item, value) {
  const name = item.name.toLowerCase();
  const search = value.toLowerCase();
  return name.indexOf(search) !== -1;
}

ListItem.propTypes = {
  item: PropTypes.object,
};

class SingleMediaPicker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: this.buildData(props),
    };
  }

  componentDidMount() {
    if (!this.props.media || !this.props.media.length) {
      // Load media (well, all media) if needed
      this.props.fetchMediaObjects();
    }
  }

  UNSAFE_componentWillReceiveProps(np) {
    if (np.media !== this.props.media) {
      this.setState({
        data: this.buildData(np),
      });
    }
  }

  handleChange = (media) => {
    if (media && media.id) {
      this.props.onChange(media.id, media);
    }
  }

  handleDeselect = () => {
    this.props.onChange(null, {});
  }

  buildData({ media = [], custom, allowEmpty }) {
    // Convert all ids to strings.
    const allMedia = media.map(
      (m) => ({
        ...m,
        id: m.id.toString(),
      }),
    );

    if (allowEmpty) {
      return [{
        id: null,
        name: '-',
      }, ...custom, ...allMedia];
    }

    return [...custom, ...allMedia];
  }

  renderThumb(item) {
    return item.thumb && (
      <img
        alt=""
        className="mr-2"
        src={window.ROOT_PATH + item.thumb}
      />
    );
  }

  render() {
    const {
      media = [],
      selected,
      custom,
      fetchMediaObjects,
      onChange,
      allowEmpty,
      mediaFilter,
      onlyPicker,
      ...other
    } = this.props;
    // eslint-disable-next-line eqeqeq
    const selectedMedia = media.find((a) => a.id == selected);

    if (onlyPicker) {
      return (
        <GSCombobox
          data={this.state.data}
          value={selectedMedia && selected.toString()}
          onSelect={this.handleChange}
          filter={filterMedia}
          renderListItem={ListItem}
          className="d-inline-block d-col align-self-center"
          placeholder="Find media file"
        />
      );
    }

    return (
      <div
        className="alert alert-info alert-sm mb-1"
        data-testid="SingleMediaPickerRow"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
      >
        <div className="row no-gutters align-items-center ">
          {selectedMedia && this.renderThumb(selectedMedia)}
          <GSCombobox
            data={this.state.data}
            value={selected && selected.toString()}
            onSelect={this.handleChange}
            filter={filterMedia}
            renderListItem={ListItem}
            className="col align-self-center mr-3"
            placeholder="Find media file"
          />
          <button
            type="button"
            aria-label="Close"
            className="close"
            onClick={this.handleDeselect}
          >
            <span aria-hidden="true">
              {'\u00D7'}
            </span>
          </button>
        </div>
      </div>
    );
  }
}

SingleMediaPicker.propTypes = propTypes;
SingleMediaPicker.defaultProps = {
  custom: [],
  allowEmpty: true,
};

const allowAll = () => true;

export default connect(
  (_, { mediaFilter = allowAll }) => {
    const getMedia = createSelector(
      (state) => getMediaObjects(state),
      (media = []) => {
        return media.filter(
          (m) => m && m.state !== 'trashed' && mediaFilter(m),
        ).sort((a, b) => (a.name > b.name ? 1 : -1));
      },
    );

    return (state) => {
      return {
        media: getMedia(state),
      };
    };
  },
  {
    fetchMediaObjects,
  },
)(SingleMediaPicker);
