import React from 'react';
import ReactDOM from 'react-dom';

class ContextMenuPortal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.el.className = props.className || '';
    this.el.style.top = 0;
    this.el.style.left = 0;
    this.el.style.zIndex = 9999;
    this.el.setAttribute('data-testid', this.props['data-testid'] || 'portal-container');
  }

  componentDidMount() {
    const main = document.getElementById('gs_main');
    main.appendChild(this.el);
  }

  componentWillUnmount() {
    const main = document.getElementById('gs_main');
    main.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

export default ContextMenuPortal;
