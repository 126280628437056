import { UPDATE_DASHBOARD_WIDGET_SETTINGS } from '../actionTypes';
import { PRIMARY_DASHBOARD_ID } from '../constants';

export default function updateDashboardWidgetSettings(
  { widgetId, settings, dashboardId = PRIMARY_DASHBOARD_ID } = {},
) {
  return {
    payload: { dashboardId, widgetId, settings },
    type: UPDATE_DASHBOARD_WIDGET_SETTINGS,
  };
}
