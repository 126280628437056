import React from "react";
import Popper from "popper.js";
import Portal from "../Portal";

class DropdownPosition extends React.PureComponent {
  _isMounted = true;

  state = {};

  componentDidUpdate() {
    this.handleReposition();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._popper && this._popper.destroy();
    this._popper = undefined;
    this.el = undefined;
  }

  handleAnchor = (el) => {
    this.anchor = el;
    this.position();
  };

  handleDropdown = (dropdown) => {
    this.dropdown = dropdown;
    requestAnimationFrame(this.position);
  };

  handleReposition = () => {
    if (this._popper) {
      this._popper.scheduleUpdate();
    }
  };

  position = () => {
    if (this.dropdown && this.anchor) {
      this._popper = new Popper(this.anchor, this.dropdown, {
        placement: this.props.right ? "bottom-end" : "bottom-start",
        modifiers: {
          flip: {
            enabled: true,
            behavior: "clockwise",
            boundariesElement: "viewport",
          },
          preventOverflow: {
            enabled: true,
            priority: ["bottom", "top", "left", "right"],
            boundariesElement: "viewport",
            padding: 20,
          },
          computeStyle: {
            // Prevents blur when zooming the page
            // gpuAcceleration: window.devicePixelRatio > 1.5,
          },
        },
      });

      // Reposition on scroll
      // this._popper.enableEventListeners();
      this._popper.disableEventListeners();
    }
  };

  killPopper() {
    try {
      this._popper && this._popper.disableEventListeners();
      this._popper && this._popper.destroy();
    } catch (e) {
      // Will throw error if element is missing.
    }
    this._popper = undefined;
  }

  render() {
    const { right, dropUp, children } = this.props;

    return (
      <div
        ref={this.handleAnchor}
        style={{
          position: "absolute",
          bottom: dropUp ? "auto" : 0,
          top: dropUp ? 0 : "auto",
          left: right ? "auto" : 0,
          right: right ? 0 : "auto",
        }}
      >
        <Portal
          className="dropdownPortalContainer z-tooltip p-absolute noprint"
          data-testid="dropdownPortalContainer"
        >
          <div ref={this.handleDropdown}>
            {children && children(this.handleReposition)}
          </div>
        </Portal>
      </div>
    );
  }
}

export default DropdownPosition;
