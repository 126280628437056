import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import AudioPlayer from '../../components/AudioPlayer';
import { getCsrfToken } from '../../../../src/core/api/apiRequest/helpers';

const propTypes = {
  configParams: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
  type: PropTypes.string.isRequired,
  preview: PropTypes.string,
  mediaType: PropTypes.string,
};

const ensureUrlPrefixed = (url) => {
  const pattern = /^((http|https):\/\/)/;
  let parsedUrl = url;

  if (!pattern.test(url)) {
    parsedUrl = `http://${url}`;
  }

  return parsedUrl;
};

class Preview extends React.PureComponent {
  componentWillUnmount() {
    const { mediaType, id } = this.props;

    if (mediaType === 'widget_rss' || mediaType === 'widget_html') {
      fetch(`/api/gui/media/${id}/preview`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': getCsrfToken(),
        },
      });
    }
  }

  render() {
    const {
      id, configParams, type, mediaType, preview,
    } = this.props;
    const rootSrc = `${window.ROOT_PATH}/${preview}`;
    const src = `/${preview}`;

    if (type === 'V') {
      return (
        <div>
          <div className="video-spinner">
            <img
              alt="loading spinner"
              style={{ height: 20, width: 20 }}
              src={require('../../../../images/spinner.gif').default}
            />
            <span>
              {' '}
              <FormattedMessage id="views.planning.media.loadingMediaPleaseWait" />
              {'... '}
            </span>
          </div>
          <video // eslint-disable-line jsx-a11y/media-has-caption
            controls
            autoPlay
            width="100%"
            src={rootSrc}
          />
        </div>
      );
    }
    if (type === 'I') {
      return <img alt="preview" src={src} style={{ maxWidth: '100%' }} />;
    }
    if (mediaType === 'audio') {
      return (
        <AudioPlayer src={rootSrc} className="btn btn-large btn-block btn-info">
          <FormattedMessage id="views.planning.media.preview" />
        </AudioPlayer>
      );
    }
    if (type === 'U') {
      if (
        mediaType === 'widget_weblink' ||
        mediaType === 'widget_rss' ||
        mediaType === 'widget_html'
      ) {
        let url = configParams ? ensureUrlPrefixed(configParams.url) : null;

        if (mediaType === 'widget_rss' || mediaType === 'widget_html') {
          url = `/api/gui/media/${id}/preview`;
        }

        return (
          <>
            <iframe
              title="widget"
              src={url}
              frameBorder={0}
              style={{
                background: '#fff',
                border: 'none',
                height: '292px',
                margin: 0,
                padding: 0,
                width: '510px',
                maxWidth: '100%',
              }}
            />
            {url.includes('http:') && (
              <div className="alert alert-warning">
                <FormattedMessage id="views.planning.media.previewHTTPWarning" />
              </div>
            )}
          </>
        );
      }
    }

    return null;
  }
}

Preview.propTypes = propTypes;

export default Preview;
