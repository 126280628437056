import { sortBy } from 'lodash';
import React, {
  memo, useMemo, useContext,
} from 'react';
import { useSelector } from 'react-redux';

import {
  DataTableBody, DataTableContainer, DataTableHead, DataTableProvider,
} from 'js/react/components/DataTable';
import LoadingSpinner from 'src/components/LoadingSpinner';
import { getPlacesInObject } from 'src/entities/places';
import { PlaceFilters } from 'src/screens/Places';
import { SimulationContext, setVisiblePlaces } from './StateManager';

const columnConfigs = [
  {
    propertyName: 'name',
    sortable: true,
    translationKey: 'common.name',
  },
  {
    sortable: true,
    propertyName: 'type',
    translationKey: 'common.type',
  },
];

export const PlaceList = memo(() => {
  const [{ visiblePlaces: selected }, dispatch] = useContext(SimulationContext);
  const allPlaces = useSelector(getPlacesInObject);

  const onSelect = (places) => {
    dispatch(setVisiblePlaces(places));
  };

  const places = useMemo(
    () => sortBy(Object.values(allPlaces || {}), 'name'),
    [allPlaces],
  );

  const handleSelect = (id) => () => {
    const idx = selected.indexOf(id);

    if (idx > -1) {
      const copy = [...selected];
      copy.splice(idx, 1);
      onSelect(copy);
    } else {
      onSelect([
        ...selected,
        id,
      ]);
    }
  };

  return (
    <DataTableProvider
      initialSortByProperty="name"
      items={places}
    >
      <div className="mb-2 container-fluid">
        <PlaceFilters />
      </div>
      {(
        <DataTableContainer>
          <DataTableHead theadConfigs={columnConfigs} />
          <DataTableBody>
            {(place) => {
              const sel = selected.includes(place.id);
              return (
                <tr
                  key={place.id}
                  onClick={handleSelect(place.id)}
                  className={sel ? 'bg-info' : ''}
                >
                  <td>
                    <i
                      className={sel
                        ? 'mr-1 icon-eye-open icon-white'
                        : 'mr-1 icon-eye-close'}
                      style={{
                        pointerEvents: 'none',
                      }}
                    />
                    {place.name}
                    {sel && !place.geometry && (
                      <div className="ml-1 align-middle d-inline-block">
                        <LoadingSpinner diameter="1rem" />
                      </div>
                    )}
                  </td>
                  <td>
                    {place.type}
                  </td>
                </tr>
              );
            }}
          </DataTableBody>
        </DataTableContainer>
      )}
    </DataTableProvider>
  );
});
