import React from 'react';
import { get } from 'lodash';
import cancan from 'js/system/cancan';
import format from 'js/react/plan/format';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useDrag } from 'react-dnd';
import { DragTypes } from 'utils/constants';
import mediaUrl from 'js/react/_utils/mediaUrl';
import { ADMIN_GROUP_MANAGEMENT } from 'constants/permissions';
import { getMediaObjectById } from 'src/entities/media';

function noWarn() { /* Prevents console warning on checkboxes. */ }

function MediaRow({
  mediaId, selected, onSelect, groupsById,
}) {
  const intl = useIntl();
  const media = useSelector(getMediaObjectById(mediaId));
  const [collected, drag, dragPreview] = useDrag(() => ({
    type: DragTypes.MEDIAROW,
    item: {
      mediaIds: selected.add(mediaId).toJS(),
    },
    drop: () => ({
      mediaIds: selected.add(mediaId),
    }),
  }), [selected]);

  return (
    <>
      <tr
        ref={drag}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...collected}
        style={{
          opacity: collected.isDragging ? 0.5 : 1,
        }}
      >
        <td className="listOrder text-center p-0 d-none d-md-table-cell">
          <i className="icon-th" />
          <div ref={dragPreview} />
        </td>
        <td className="text-center">
          <input
            type="checkbox"
            checked={selected.contains(mediaId)}
            onClick={onSelect}
            onChange={noWarn}
          />
        </td>
        <td className="d-none d-md-table-cell">
          <img
            alt=""
            src={`${window.ROOT_PATH}${media.thumb}`}
          />
        </td>
        <td>
          <a href={mediaUrl(media)}>
            {media.name || mediaId}
          </a>
        </td>
        <td>
          {format.time(media.length)}
        </td>
        <td>
          <i className={format.mediaType(media.media_type) || format.type(media.type)} />
        </td>
        {cancan.can('read', ADMIN_GROUP_MANAGEMENT) && (
          <td>{get(groupsById, [media.group, 'name'], intl.formatMessage({ id: 'common.unknownOption' }))}</td>
        )}
        <td className="text-nowrap d-none d-md-table-cell">
          {format.uploaded(media.upload_date)}
        </td>
      </tr>
    </>
  );
}

export default MediaRow;
