import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as selectors from 'js/redux/selectors';
import { fetchGroups } from 'js/redux/api/data';

/*
* GroupSelect
*/
const mapStateToProps = (state) => {
  return {
    groups: selectors.getGroups(state),
  };
};

const mapDispatchToProps = {
  fetchGroups,
};

const propTypes = {
  fetchGroups: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

class GroupSelect extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  /*
        Data Methods
    */

  loadData = () => {
    this.props.fetchGroups().then(this.dataLoaded);
  }

  dataLoaded = () => {
    this.setState({
      loading: false,
    });
  };

  /*
         Render Function
    */
  render() {
    if (this.state.loading) {
      return (
        <div className="animate module_body">
          <FormattedMessage id="common.loading" />
          {'...'}
        </div>
      );
    }

    return (
      <select
        name="group"
        type="select"
        className="form-control mb-1"
        defaultValue={this.props.selected}
        onChange={this.props.onChange}
        onBlur={this.props.onDone}
      >
        {this.props.groups.map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </select>
    );
  }
}

GroupSelect.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(GroupSelect);
