import React, { memo } from 'react';
import PropTypes from 'prop-types';
import HelpIcon from 'components/HelpIcon';
import SearchInput from 'components/SearchInput';

const propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  actions: PropTypes.element,
  onSearch: PropTypes.func,
  helpBody: PropTypes.string,
  helpHeader: PropTypes.string,
};

const ListHeader = (props) => {
  const {
    label,
    actions,
    onSearch,
    helpBody,
    helpHeader,
    children,
  } = props;

  return (
    <div className="p-3">
      <div className="d-flex align-items-center">
        {label && (
          <h3 className="mr-auto mb-0">
            {label}
            {(helpBody || helpHeader) && (
              <HelpIcon
                bodyKey={helpBody}
                headerKey={helpHeader}
              />
            )}
          </h3>
        )}
        <div className="d-flex align-items-center">
          <div>
            {actions}
          </div>
          <div className="ml-1">
            {onSearch && (
              <SearchInput
                onSearch={onSearch}
              />
            )}
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

ListHeader.propTypes = propTypes;

export default memo(ListHeader);
