import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

const propTypes = {
  permittedWidgetTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }).isRequired,
  ).isRequired,
};

const CreateWidgetDropdownItems = ({
  permittedWidgetTypes,
}) => {
  const history = useHistory();

  return (
    <>
      <h6 className="dropdown-header">
        <FormattedMessage id="views.planning.media.selectMediaType" />
      </h6>
      {permittedWidgetTypes.map(({ name, id }) => {
        return (
          <button
            key={name}
            type="button"
            id={id}
            className="dropdown-item"
            data-testid="create-media-button"
            onClick={(event) => {
              event.preventDefault();
              history.push(`/plan/createMedia/${id}`);
            }}
            style={{ cursor: 'pointer' }}
          >
            {name}
          </button>
        );
      })}
    </>
  );
};

CreateWidgetDropdownItems.propTypes = propTypes;

export default CreateWidgetDropdownItems;
