/*
* Here we import the composed reducer, define & bootstrap middlewares and create
* the store.
*
* NOTE: The redux-logger middleware is only applied if window.environment ===
* 'development'
*/

import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';

import { apiMiddleware } from 'src/core/api';
import { dataMiddlewares } from 'src/core/data';
import { mediaMiddlewares } from 'src/entities/media';
import { plannedMediaMiddlewares } from 'src/entities/plannedMedia';
import apiClientMiddleware from 'js/redux/middleware/apiClientMiddleware';
import dashboardMiddleware from 'js/redux/dashboard/middleware';
import i18nMiddleware from 'js/redux/i18n/middleware';
import reducer from './reducer';

let middleware = [
  apiClientMiddleware(), // needs to be first as it is a thunk
  apiMiddleware, // should be before middlewares from entities
  ...plannedMediaMiddlewares,
  ...mediaMiddlewares,
  ...dataMiddlewares, // should be after middlewares from entities
  i18nMiddleware(),
  dashboardMiddleware(),
];

if (window.environment && window.environment === 'development') {
  const logger = createLogger({ collapsed: true });
  middleware = [...middleware, logger];
}

export default createStore(reducer, composeWithDevTools(
  applyMiddleware(...middleware),
));
