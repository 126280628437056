import React from 'react';

const SidebarNavigation = ({ onClose: handleClose }) => {
  return (
    <div className="col">
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          window.history.back();
        }}
        data-testid="close-sidebar-back"
      >
        <i className="icon-chevron-left" />
      </a>
      <a
        href="#"
        className="close"
        onClick={handleClose}
      >
        {'\u00D7'}
      </a>
    </div>
  );
};

export default SidebarNavigation;
