import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

import { fetchCustomWidget, getWidgetById } from 'src/entities/widgets';
import {
  createMediaObject,
  updateMediaObject,
  fetchMediaObject,
  getMediaObjectById,
  destroyPermanently,
} from 'src/entities/media';

import { useRequestWithPromise } from 'src/core/api';
import * as MsgActions from 'js/react/actions/message-actions';
import { Dialog } from 'src/components/Dialog';
import Preview from 'js/react/plan2/media-sidebar/Preview';
import { Button } from './components/Button';
import CloseButton from './components/CloseButton';
import Sidebar from './components/Sidebar';
import SettingsFactory from './SettingsFactory';
import { useGetTranslation } from './useGetTranslation';

const CREATE_MEDIA = 'createMedia';
const CUSTOM_WIDGET = 'widget_custom';
const PERM_DELETEABLE = ['widget_config__facebook', 'widget_config__instagram'];

const getErrorMsg = (payload) => Object.entries(payload || {})
  .map(([key, value]) => `${key}: ${value}`)
  .join('.\n ');

const isFieldVisible = ({ conditional }, values) => {
  if (conditional) {
    const { field, value } = conditional;
    return values[field] === value;
  }

  return true;
};

export const CustomWidgetSidebar = ({ activeId, sidebar, onClose }) => {
  const getTranslation = useGetTranslation();
  const { dispatch, pending: loading } = useRequestWithPromise();
  const widget = useSelector(getWidgetById(sidebar === CREATE_MEDIA && activeId));
  const mediaObject = useSelector(getMediaObjectById(sidebar === CUSTOM_WIDGET && activeId));
  const [formData, setData] = useState({});
  const { settings = [], localization = [], config = {} } = formData;
  const { title, description } = getTranslation(localization);
  const { formatMessage } = useIntl();
  const messageId = sidebar === CREATE_MEDIA
    ? 'common.create'
    : 'common.edit';

  useEffect(() => {
    switch (sidebar) {
    case CREATE_MEDIA:
      dispatch(fetchCustomWidget(activeId));
      break;
    case CUSTOM_WIDGET:
      dispatch(fetchMediaObject(activeId));
      break;
    default: // Do nothing
    }
  }, []);

  useEffect(() => {
    // Overwrite state if init data changes
    switch (sidebar) {
    case CREATE_MEDIA:
      setData({ ...(widget || {}) });
      break;
    case CUSTOM_WIDGET:
      setData({ ...(mediaObject || {}) });
      break;
    default: // Do nothing
    }
  }, [widget, mediaObject]);

  const handleSave = (actionCreator, data, msg) => {
    const response = dispatch(actionCreator(data));
    response
      .then((action) => {
        MsgActions.success(`${msg}: ${action.payload.name}`);
        return action;
      })
      .catch((action) => {
        MsgActions.error(getErrorMsg(action.payload));
        return action;
      });
    return response;
  };

  const handleSubmit = async (close = true) => {
    const fData = {
      name: formData.name,
      length: formData.length,
      config: formData.config,
      media_type: formData.media_type || formData.type,
    };
    let resp;

    if (sidebar === CREATE_MEDIA) {
      resp = await handleSave(createMediaObject, fData, 'Saved widget', close);
    } else {
      fData.id = activeId;
      resp = await handleSave(updateMediaObject, fData, 'Edit widget', close);
    }

    // If social media widget that needs authorization
    if (resp?.payload?.state === 'pending' && resp?.payload?.config?.uri) {
      window.open(resp.payload.config.uri, 'authWindow', 'popup');
    }

    close && onClose?.();
  };

  const handlePermanentDelete = () => {
    dispatch(destroyPermanently(activeId)).then(() => {
      onClose?.();
    });
  };

  return (
    <Sidebar>
      <CloseButton
        onClick={onClose}
        disabled={loading}
      />
      <h3>
        <FormattedMessage
          id={messageId}
        />
        &#160;
        {title}
      </h3>

      {(sidebar !== CREATE_MEDIA) && (
        <div className="thumbnail mb-3">
          {!loading && <Preview id={activeId} type="U" mediaType="widget_html" />}
        </div>
      )}

      <SettingsFactory
        value={formData.name}
        type="text"
        disabled={loading}
        title={formatMessage({ id: 'common.name' })}
        onChange={(val) => setData((data) => ({
          ...data,
          name: val,
        }))}
      />

      <SettingsFactory
        value={formData.length}
        type="number"
        disabled={loading}
        title={formatMessage({ id: 'common.length' })}
        onChange={(val) => setData((data) => ({
          ...data,
          length: Number(val),
        }))}
      />

      <h5 className="form-text text-muted mb-4">
        {description}
      </h5>

      {Object.entries(settings || []).map(([name, setting]) => {
        const value = name in config
          ? config[name]
          : setting.value;

        return isFieldVisible(setting, config) && (
          <SettingsFactory
            key={`key-setting-${name}`}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(setting || {})}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getTranslation(setting.localization)}
            id={`id-setting-${name}`}
            value={value}
            disabled={loading}
            onChange={(val) => setData((prevState) => ({
              ...prevState,
              config: {
                ...prevState.config,
                [name]: val,
              },
            }))}
            mediaObject={mediaObject}
          />
        );
      })}
      <div className="mt-2">
        <Button
          onClick={handleSubmit}
          disabled={loading || !formData.name?.length}
        >
          <FormattedMessage
            id={messageId}
          />
        </Button>
        {sidebar !== CREATE_MEDIA && PERM_DELETEABLE.includes(mediaObject?.media_type) && (
          <Dialog
            onConfirm={handlePermanentDelete}
            disabled={loading}
            variant="btn-danger"
            confirmLabel={(
              <FormattedMessage
                id="common.delete-permanently"
              />
            )}
            renderToggle={(handleButtonClick) => (
              <button
                type="button"
                className="btn btn-danger ml-2"
                onClick={handleButtonClick}
              >
                <FormattedMessage
                  id="common.delete-permanently"
                />
              </button>
            )}
          >
            <FormattedMessage
              id="views.planning.media.cannot-be-undone"
            />
          </Dialog>
        )}
      </div>
    </Sidebar>
  );
};
