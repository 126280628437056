import React from 'react';
import { WIDGET_EDITORS } from '../widgets';

import AddWidget from './AddWidget';
import AddBadge from './AddBadge';

const SIDEBARS = {
  ...WIDGET_EDITORS,
  add: AddWidget,
  'add-badge': AddBadge,
};

const notFound = '404';

export default function Widget({ widgettype, ...other }) {
  const Component = SIDEBARS[widgettype];

  if (!Component) {
    return (
      <div>
        <h2>
          {notFound}
        </h2>
      </div>
    );
  }

  return Component && (
    <div className="holder bbox bg-white px-3 pt-3">
      <Component {...other} />
    </div>
  );
}
