export default function filterReports({
  data,
  lines,
  names,
  buses,
  playlists,
}) {
  let filteredData = [...data];

  if (lines && lines.size > 0) {
    filteredData = filteredData.filter((report) => {
      return lines.includes(report.line);
    });
  }

  if (names && names.size > 0) {
    filteredData = filteredData.filter((report) => {
      return names.includes(report.name);
    });
  }

  if (buses && buses.size > 0) {
    filteredData = filteredData.filter((report) => {
      return buses.includes(report['bus-id']);
    });
  }

  if (playlists && playlists.size > 0) {
    filteredData = filteredData.filter((report) => {
      return playlists.includes(report.playlist_name);
    });
  }

  return filteredData;
}
