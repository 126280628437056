import React, {
  useEffect, useRef, memo, useContext,
} from 'react';

import { FormattedMessage } from 'react-intl';
import { usePlayerScale } from '../hooks/usePlayerScale';
import { ResolutionPicker } from './ResolutionPicker';
import { SimulationContext, setFullscreen } from './StateManager';

export const SimulationFrame = memo(() => {
  const frameRef = useRef();
  const containerRef = useRef();
  const [{
    fullscreen,
    iframeSrc: src,
    error,
    screenResolution: ratio,
  }, dispatch] = useContext(SimulationContext);

  const scale = usePlayerScale(containerRef, ratio);

  const toggleFullscreen = () => {
    dispatch(setFullscreen(!fullscreen));
  };

  useEffect(() => {
    const frame = frameRef.current;
    if (fullscreen && frame) {
      if (frame.webkitRequestFullScreen) {
        frame.webkitRequestFullScreen();
      } else if (frame.mozRequestFullScreen) {
        frame.mozRequestFullScreen();
      } else if (frame.webkitRequestFullscreen) {
        frame.webkitRequestFullscreen();
      } else if (frame.msRequestFullscreen) {
        frame.msRequestFullscreen();
      }
    }
  }, [fullscreen]);

  return (
    <div className="bg-secondary h-100 d-flex flex-column">
      <ResolutionPicker />
      <div
        ref={containerRef}
        className="position-relative h-100 w-100"
      >

        {!error && src && (
          <div
            className="position-absolute overflow-hidden"
            style={{
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
            }}
          >
            <iframe
              key={src}
              name="player-iframe"
              title="Player"
              style={{
                width: ratio?.[0],
                height: ratio?.[1],
                top: '50%',
                left: '50%',
                position: 'relative',
                transform: `scale(${scale}) translate(-50%, -50%)`,
                transformOrigin: '0 0',
              }}
              ref={frameRef}
              src={src}
            />
          </div>
        )}

        {!error && !src && (
          <span /* Div causes CSS issues due to some other rule */
            style={{
              width: '100%',
              position: 'absolute',
              top: '50%',
              left: '0px',
              color: '#aaa',
              textAlign: 'center',
              transform: 'translateY(-50%)',
            }}
          >
            <FormattedMessage id="common.loading" />
          </span>
        )}

        {!error && (
          <span /* Div causes CSS issues due to some other rule */
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              cursor: 'pointer',
              top: 0,
              left: 0,
            }}
            onDoubleClick={toggleFullscreen}
          />
        )}

        {error && (
          <h3
            style={{
              width: '100%',
              height: '100%',
              color: '#fff',
              margin: 40,
            }}
          >
            {error}
          </h3>
        )}
      </div>
    </div>
  );
});
