import Immutable from 'immutable';
import moment from 'moment';

export default function getDateRange(fromDate, toDate, format = 'YYYY-MM-DD') {
  const end = moment(toDate).format('YYYY-MM-DD');
  let now = moment(fromDate);

  const res = [];
  while (now.format('YYYY-MM-DD') <= end) {
    res.push(now.format(format));
    now = now.add(1, 'd');
  }

  return Immutable.fromJS(res);
}
