import cancan from 'js/system/cancan';

import {
  put,
} from '../data/helpers/actionCreatorFactories';

import * as actions from './actions';


export function updatePassword({
  oldPassword,
  newPassword,
  newPasswordConfirm,
}) {
  const session = cancan.getSession();
  const url = `/auth/users/${session.uid}`;

  const requestBody = {
    password_current: oldPassword,
    password_new: newPassword,
    password_confirm: newPasswordConfirm,
  };

  const types = [
    actions.UPDATE_PASSWORD,
    actions.UPDATE_PASSWORD_SUCCESS,
    actions.UPDATE_PASSWORD_FAIL,
  ];
  const meta = {
    verb: 'put',
    bodyInput: requestBody,
    url,
  };

  return put({
    url, types, meta, bodyInput: requestBody,
  });
}
