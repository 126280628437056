import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { geoId } from 'utils/shapes';

const propTypes = {
  clientId: geoId.isRequired,
  onChange: PropTypes.func.isRequired,
};

class ClientGroupEditor extends React.PureComponent {
  handleChange = (e) => {
    this.props.onChange(e.target.value);
  }

  render() {
    const {
      name,
      clientId,
    } = this.props;
    const htmlFor = `clientName${clientId}`;

    return (
      <div>
        <form className="mb-3">
          <label
            htmlFor={htmlFor}
            className="mr-2"
          >
            <FormattedMessage id="common.name" />{':'}
          </label>
          <input
            id={htmlFor}
            type="text"
            className="form-control"
            value={name}
            onChange={this.handleChange}
            onBlur={this.props.onBlur || undefined}
          />
        </form>
      </div>
    );
  }
}

ClientGroupEditor.propTypes = propTypes;

export default ClientGroupEditor;
