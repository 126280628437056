import React, { useMemo, useState } from 'react';
import debug from 'debug';
import { DataTableContext } from './DataTableContext';

const log = debug('src:components:DataTable:Provider');

export const DataTableProvider = ({
  initialSortByProperty,
  items,
  children,
}) => {
  log('render');

  const [filters, setFilters] = useState({});
  // [searchQuery, setSearch] = useState();
  const [sortByProperty, setSortByProperty] = useState(initialSortByProperty);
  const [shouldSortInAscendingOrder, setShouldSortInAscendingOrder] = useState(
    true,
  );

  const processedItems = useMemo(() => {
    return items
      .filter((item) => Object.values(filters).reduce(
        (pass, filter) => pass && filter(item),
        true,
      ))
      .sort((a, b) => {
        const aVal = a[sortByProperty]?.toLowerCase();
        const bVal = b[sortByProperty]?.toLowerCase();

        if (sortByProperty && aVal !== bVal) {
          const val = aVal.localeCompare(bVal, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true});
          if (shouldSortInAscendingOrder) {
            return val >= 1 ? 1 : -1;
          }
          return val <= 1 ? -1 : 1;
        }

        return 0;
      });
  }, [filters, items, shouldSortInAscendingOrder, sortByProperty]);

  const dataTableApi = useMemo(
    () => ({
      filters,
      items,
      processedItems,
      setFilters,
      setShouldSortInAscendingOrder,
      setSortByProperty,
      shouldSortInAscendingOrder,
      sortByProperty,
    }),
    [
      filters,
      items,
      processedItems,
      setFilters,
      setShouldSortInAscendingOrder,
      setSortByProperty,
      shouldSortInAscendingOrder,
      sortByProperty,
    ],
  );

  return (
    <DataTableContext.Provider value={dataTableApi}>
      {children}
    </DataTableContext.Provider>
  );
};
