import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from 'components/Modal';
import { actionCreators, globalSelectors } from 'js/redux/helpModal';

const mapStateToProps = (state) => {
  return {
    helpModal: globalSelectors.getHelpModal(state),
  };
};

const mapDispatchToProps = { clearHelpModal: actionCreators.clearHelpModal };

const propTypes = {
  clearHelpModal: PropTypes.func.isRequired,
  helpModal: PropTypes.shape({
    bodyKey: PropTypes.string,
    headerKey: PropTypes.string,
  }),
};

const HelpModal = ({ clearHelpModal, helpModal }) => {
  const hasHelpModal = !!helpModal;

  if (!hasHelpModal) {
    return null;
  }

  const { bodyKey, headerKey } = helpModal;

  return (
    <Modal
      isOpen={hasHelpModal}
      bodyKey={bodyKey}
      headerKey={headerKey}
      closeModal={clearHelpModal}
      style={{
        content: {
          bottom: 'auto',
          margin: 'auto',
          maxWidth: '600px',
          minHeight: '150px',
          top: '120px',
        },
      }}
    />
  );
};

HelpModal.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(HelpModal);
