import React from 'react';

const createMarkup = (text) => {
  return { __html: text };
};

export const TextArea = (
  {
    id,
    title,
    description,
    value,
    onChange,
    disabled,
  },
) => {
  return (
    <div
      id={id}
      className="form-group"
      data-testid="customWidgetTextArea"
    >
      <label htmlFor={id}>
        {title}
      </label>
      <textarea
        className="form-control"
        onChange={(event) => onChange(event.target.value)}
        value={value}
        disabled={disabled}
      />
      <small className="form-text text-muted">
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={createMarkup(description)}
        />
      </small>
    </div>
  );
};
