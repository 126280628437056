import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { FormattedMessage, injectIntl } from 'react-intl';
import { intlShape } from 'utils/shapes';

const propTypes = {
  bodyKey: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  headerKey: PropTypes.string,
  intl: intlShape.isRequired,
  isOpen: PropTypes.bool.isRequired,
  style: PropTypes.object,
};

const Modal = ({
  bodyKey, closeModal, headerKey, intl, isOpen, style,
}) => {
  return (
    <ReactModal
      appElement={document.getElementById('gs_main')}
      isOpen={isOpen}
      contentLabel={headerKey || bodyKey}
      onRequestClose={closeModal}
      style={style}
    >
      <div>
        {headerKey && (
          <h3>
            <FormattedMessage id={headerKey} />
          </h3>
        )}
        {bodyKey && (
          <p>
            <div
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: bodyKey }),
              }}
            />
          </p>
        )}
        <button
          className="btn btn-primary"
          onClick={closeModal}
          data-testid="close-button"
        >
          <FormattedMessage id="common.close" />
        </button>
      </div>
    </ReactModal>
  );
};

Modal.propTypes = propTypes;

export default injectIntl(Modal);
