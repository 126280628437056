/* eslint-disable max-classes-per-file */
import { FormattedMessage, useIntl } from 'react-intl';
import React, {
  forwardRef, useEffect, useRef, useState,
} from 'react';
import im from 'immutable';
import cancan from 'js/system/cancan';
import stringUtils from 'js/react/_utils/stringUtils';
import { CLIENTS } from 'constants/permissions';
import { useSelector } from 'react-redux';
import { getGroupsInObject } from 'js/redux/selectors';
import DragDropComponent from '../scheme-dnd';
import ClientManager from '../ClientManager';
import LayoutPicker from '../LayoutPicker';
import { TurnaroundTimeInput } from '../TurnaroundTimeInput';
import { SchemeItemList } from './SchemeItemList';

const ItemRow = forwardRef(({
  editing,
  draggable,
  className,
  onRemove,
  label,
  time,
}, ref) => (
  <tr
    ref={ref}
    className={className}
  >
    {editing && draggable && (
      <td className="handle">
        <i className="icon-list" />
      </td>
    )}
    <td className="indented">
      {label}
    </td>
    <td>
      <span className="float-right">
        {time}
      </span>
    </td>

    {editing && (
      <td>
        <button
          className="border-0 rounded"
          type="button"
          data-testid="playlistRemoveButton"
          onClick={onRemove}
        >
          <i
            className="icon-remove-sign"
          />
        </button>
      </td>
    )}
  </tr>
));

const SchemeItemRow = forwardRef(({
  playlist,
  schemePlaylist,
  sectionName,
  tagId,
  editing,
  onTagDelete,
  onPlaylistDelete,
  draggable,
}, ref) => {
  const { formatMessage } = useIntl();
  let subType;
  let name;
  let maxTime;

  if (playlist) {
    subType = playlist.get('sub_type');
    name = playlist.get('name');
    maxTime = playlist.get('max_time');
  } else {
    subType = schemePlaylist.order < 0 ? 'direct' : 'repeating';
    name = formatMessage({ id: 'views.playlists.missingPermissionPlaylist' }, { id: schemePlaylist.get('id') });
    maxTime = 0;
  }

  const isDragging = schemePlaylist.get('isDragging');

  let clsName = '';
  clsName += subType ? `playlist ${subType}` : '';
  clsName += sectionName ? ' section-header' : '';
  clsName += isDragging ? ' is-dragging' : '';
  clsName += draggable ? ' draggable' : '';

  if (tagId) {
    return (
      <ItemRow
        ref={ref}
        className={clsName}
        editing={editing}
        draggable={draggable}
        onRemove={(event) => {
          event.preventDefault();
          event.stopPropagation();
          onTagDelete(tagId);
        }}
        label={sectionName || tagId}
      />
    );
  }

  return (
    <ItemRow
      ref={ref}
      className={clsName}
      editing={editing}
      draggable={draggable}
      onRemove={() => onPlaylistDelete(schemePlaylist.get('id'))}
      label={name}
      time={stringUtils.toMMSS(maxTime, true)}
    />
  );
});

function filterPlaylistType(schemeItems = im.fromJS([]), playlists, wantedType) {
  return schemeItems.filter((scheme) => {
    const type = scheme.get('order') < 0 ? 'direct' : 'repeating';
    if (type === wantedType) {
      return scheme;
    }
    return null;
  });
}

function filterByName(schemeItems = im.fromJS([]), playlists) {
  return schemeItems.sort((schemeItemA, schemeItemB) => {
    const playlistA = playlists.get(schemeItemA.get('playlist'));
    const playlistB = playlists.get(schemeItemB.get('playlist'));
    const playlistNameA = playlistA && playlistA.get('name').toLowerCase();
    const playlistNameB = playlistB && playlistB.get('name').toLowerCase();
    if (!playlistNameA) {
      return 0;
    }

    if (!playlistNameB) {
      return 0;
    }

    return playlistNameA < playlistNameB ? -1 : 1;
  });
}

const SchemeTextEditorComponent = ({ initialValue, onChange }) => {
  const [value, setValue] = useState(initialValue);

  return (
    <div className="col mr-3">
      <input
        type="text"
        data-testid="SchemeNameInput"
        className="form-control name-input"
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => onChange(value)}
        value={value}
      />
    </div>
  );
};

// It would have been ideal to use the components/DeleteConfirm
// component here but it was a little too opinionated to make it work
const SchemeDeleteConfirmComponent = ({ onDelete, confirmLabel }) => {
  const [confirming, setConfirm] = useState(false);

  const handleConfirm = () => {
    setConfirm(true);
  };

  if (confirming) {
    return (
      <button
        type="button"
        className="btn btn-warning btn-sm ml-1 mr-3 px-3"
        onClick={onDelete}
      >
        {confirmLabel}
      </button>
    );
  }

  return (
    <button
      type="button"
      className="btn btn-danger btn-sm ml-1 mr-3 px-3"
      onClick={handleConfirm}
    >
      {/* No pointer events to prevent toggle of edit mode */}
      <i
        style={{ pointerEvents: 'none' }}
        className="icon-trash icon-white"
      />
    </button>
  );
};

export const SchemeView = ({
  tags,
  scheme,
  editing,
  onToggleEdit,
  layoutImgSrc,
  playlists,
  onSchemePlaylistAdd,
  onSchemePlaylistDelete,
  onSchemeDurationChange,
  onSchemeNameChange,
  onUpdateRepeating,
  onSchemeDestroy,
}) => {
  const ref = useRef();
  const [tab, setTab] = useState('area');
  const groupsById = useSelector(getGroupsInObject);
  const group = groupsById?.[scheme.get('group')];
  const hasClientsView = cancan.can('read', CLIENTS);
  const { formatMessage } = useIntl();

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (!ref.current?.contains(e.target)) {
        // click outside, close editing of current scheme
        onToggleEdit(-1);
      }
    };
    // if edit began, add click listener
    editing && window.addEventListener('click', handleDocumentClick);
    return () => {
      window.removeEventListener('click', handleDocumentClick);
    };
  }, [editing]);

  const schemeItems = scheme.get('playlists') || im.fromJS([]);

  // filter direct/repeating scheme playlists
  const directItems = filterByName(
    filterPlaylistType(schemeItems, playlists, 'direct'),
    playlists,
  );
  const repeatingItems = filterPlaylistType(
    schemeItems,
    playlists,
    'repeating',
  );

  // filter direct/repeating choosable playlists
  const directPlaylists = playlists.filter((pl) => {
    return pl.get('type') === 'direct';
  });

  const repeatingPlaylists = playlists.filter(
    (pl) => pl.get('type') === 'repeating',
  );

  // if scheme has filler playlists planned, special function are enabled
  const hasFiller = schemeItems.find((si) => {
    const pl = playlists.get(si.get('playlist')) || playlists.get(si.get('playlist')?.toString());
    return pl?.get('sub_type') === 'filler';
  });

  // click handlers
  const onPlaylistSelected = (playlistId, tag) => {
    onSchemePlaylistAdd(scheme.get('id'), playlistId, tag);
  };

  const onPlaylistDelete = (itemId) => {
    onSchemePlaylistDelete(scheme.get('id'), itemId);
  };

  const handleDurationSave = (value) => {
    onSchemeDurationChange(
      scheme.get('id'),
      value,
      true,
    );
  };

  const onNameSave = (name) => {
    onSchemeNameChange(scheme.get('id'), name, true);
  };

  const onUpdateOrder = (schemePlaylists) => {
    onUpdateRepeating(scheme.get('id'), schemePlaylists);
  };

  let RepeatingItemRowComponent = SchemeItemRow;

  if (editing && repeatingItems.size > 1) {
    const contextId = `repeating-${scheme.get('id')}`;
    RepeatingItemRowComponent = DragDropComponent(SchemeItemRow, contextId);
  }

  return (
    <div
      ref={ref}
      className="m-2 card bshadow p-3"
      style={{ display: 'block' }}
      data-testid="SchemeListItem"
    >
      <div className="row no-gutters mb-2">
        {(editing) ? (
          <SchemeTextEditorComponent
            onChange={onNameSave}
            initialValue={scheme.get('name')}
          />
        ) : (
          <h4
            className="d-inline-block mr-auto"
            data-testid="SchemeListName"
          >
            {scheme.get('name')}
            <span className="font-weight-normal small text-muted">
              {group && ` (id: ${scheme.get('id')}, ${formatMessage({ id: 'views.playlists.owner_group' })}: ${group.name})`}
            </span>
          </h4>
        )}

        <button
          type="button"
          className={
            `px-3 btn btn-primary btn-sm${editing ? ' hidden' : ''}`
          }
          onClick={() => onToggleEdit(scheme.get('id'))}
          data-testid="SchemeListItemEdit"
        >
          <i className="icon-edit icon-white" />
        </button>

        {editing && (
          <SchemeDeleteConfirmComponent
            confirmLabel={<FormattedMessage id="common.confirmDelete" />}
            onDelete={() => onSchemeDestroy(scheme.get('id'))}
          />
        )}

        <button
          type="button"
          aria-label="Close"
          className={
            `btn btn-light border px-3 ${(editing ? '' : ' hidden')}`
          }
          onClick={() => onToggleEdit(scheme.get('id'))}
          data-testid="SchemeListItemCancel"
        >
          <i className="icon-ban-circle" />
        </button>
      </div>

      <div className="row no-gutters">
        {!hasClientsView && (
          <div className="col-6 col-md-3 pr-3 mx-auto">
            {editing && (
              <div className="row no-gutters">
                <div className="tabbable tabs-left" />
              </div>
            )}
            <div className="mt-2">
              <img
                className="layout w-100"
                src={layoutImgSrc}
                alt=""
              />
            </div>
            <LayoutPicker
              value={scheme.get('layout')}
              schemeId={scheme.get('id')}
            />
          </div>
        )}
        <div className={`col-12${hasClientsView ? '' : ' col-md-9'}`}>
          {!hasClientsView && (
            <ul className="nav-tabs nav mb-3">
              <li className="nav-item">
                <button
                  type="button"
                  className={`nav-link btn btn-white ${tab === 'area' ? ' active' : ''}`}
                  onClick={() => setTab('area')}
                >
                  <FormattedMessage id="common.scheme" />
                </button>
              </li>
              <li
                className="nav-item ml-auto"
              >
                <button
                  type="button"
                  onClick={() => setTab('clients')}
                  className={
                    `nav-link btn btn-white ${(tab === 'clients' && ' active') || ''}`
                  }
                >
                  <FormattedMessage id="views.playlists.clientGroups" />
                </button>
              </li>
            </ul>
          )}

          {tab === 'area' && (
            <div className="container-fluid">
              <div className="row">
                <div
                  className="col-6 p-2"
                  data-testid="repeatingPlaylists"
                >
                  <div className="card bg-light p-3">
                    <h5>
                      <i className="icon-repeat" />&nbsp;<FormattedMessage id="common.repeatingPlaylists" />
                    </h5>
                    {hasFiller && (
                      <TurnaroundTimeInput
                        scheme={scheme}
                        isEditing={editing}
                        onChange={handleDurationSave}
                      />
                    )}

                    <SchemeItemList
                      RowComp={RepeatingItemRowComponent}
                      contextId={`repeating-${scheme.get('id')}`}
                      editing={editing}
                      onUpdateOrder={onUpdateOrder}
                      onPlaylistSelected={onPlaylistSelected}
                      onPlaylistDelete={onPlaylistDelete}
                      playlists={repeatingPlaylists}
                      items={repeatingItems}
                      repeating
                      tags={tags}
                    />
                  </div>
                </div>
                <div
                  className="col-6 p-2"
                  data-testid="directPlaylists"
                >
                  <div className="card bg-light p-3">
                    <h5>
                      <i className="icon-play-circle" />&nbsp;<FormattedMessage id="common.directPlaylists" />
                    </h5>
                    <SchemeItemList
                      RowComp={SchemeItemRow}
                      uniqueOnly
                      editing={editing}
                      onPlaylistSelected={onPlaylistSelected}
                      onPlaylistDelete={onPlaylistDelete}
                      playlists={directPlaylists}
                      items={directItems}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {tab === 'clients' && (
            <ClientManager schemeId={scheme.get('id')} />
          )}
        </div>
      </div>
    </div>
  );
};
