import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fromJS, Map } from 'immutable';
import { isEmpty, sortBy } from 'lodash';
import cancan from '../../../../js/system/cancan';
import { fetchPlace, getPlacesInObject } from '../../../entities/places';
import { placeReadonly } from '../../../../constants/permissions';

export function isEditable(placeType) {
  return !cancan.can('read', placeReadonly(placeType));
}

const usePlacesWithGeometry = (visibleIds) => {
  const dispatch = useDispatch();
  const allPlaces = useSelector(getPlacesInObject);
  const visibleItems = useMemo(() => {
    return visibleIds?.map((id) => allPlaces[id] || { id });
  }, [allPlaces, visibleIds]);

  useEffect(() => {
    // Reload places missing geometry data
    // Geometry is not included in the sparse index-api.
    visibleItems.forEach(({ id, geometry }) => {
      if (!geometry && id !== 'create') {
        dispatch(fetchPlace(id));
      }
    });
  }, [visibleIds]);

  return useMemo(
    () => visibleItems.filter(({ geometry }) => !!geometry),
    [visibleItems]
  );
};

export function sortGeometries({ category = [] } = {}) {
  if (category.includes('inner_zone')) {
    return 0;
  }
  return category.includes('outer_zone') ? 1 : 2;
}

export const formatWktFeature = (place, geometryIndex = 0) => {
  const { id, geometry } = place;
  let wkt = '';

  if (!isEmpty(geometry)) {
    if (geometry.wkt) {
      wkt = geometry.wkt;
    } else {
      const geoms = sortBy(geometry, sortGeometries);
      wkt = geoms[geometryIndex]?.wkt || geoms[0]?.wkt || '';
    }
    return { wkt, id };
  }
};

export const useMapData = (visibleIds, placeToEditId) => {
  const visiblePlaces = usePlacesWithGeometry(visibleIds);

  return useMemo(() => {
    return visiblePlaces
      ? fromJS(
          visiblePlaces
            .filter((obj) => {
              return obj.id !== placeToEditId || !isEditable(obj.type);
            })
            .map((p) => formatWktFeature(p, 0))
        )
      : Map();
  }, [visiblePlaces, placeToEditId]);
};
