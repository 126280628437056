import { v4 as getUuid } from 'uuid';

import * as actions from '../actions';

import {
  get,
  post,
  put,
  del,
} from '../helpers/actionCreatorFactories';

const url = '/auth/groups';

export function fetchGroups() {
  const types = [
    actions.FETCH_GROUPS,
    actions.FETCH_GROUPS_SUCCESS,
    actions.FETCH_GROUPS_FAIL,
  ];
  const meta = {
    verb: 'get',
    collection: 'groups',
    uuid: getUuid(),
    url,
    reset: true,
  };

  return get({ url, types, meta });
}

export function createGroup(bodyInput) {
  const types = [
    actions.CREATE_GROUP,
    actions.CREATE_GROUP_SUCCESS,
    actions.CREATE_GROUP_FAIL,
  ];
  const meta = {
    verb: 'post',
    bodyInput,
    collection: 'groups',
    uuid: getUuid(),
    url,
  };

  return post({
    url, types, meta, bodyInput,
  });
}

export function updateGroup(bodyInput) {
  const types = [
    actions.UPDATE_GROUP,
    actions.UPDATE_GROUP_SUCCESS,
    actions.UPDATE_GROUP_FAIL,
  ];
  const meta = {
    verb: 'put',
    bodyInput,
    id: bodyInput.id,
    collection: 'groups',
    uuid: getUuid(),
    url,
  };

  return put({
    url, types, meta, bodyInput,
  });
}

export function deleteGroup(id) {
  const types = [
    actions.DELETE_GROUP,
    actions.DELETE_GROUP_SUCCESS,
    actions.DELETE_GROUP_FAIL,
  ];
  const meta = {
    verb: 'del',
    id,
    collection: 'groups',
    uuid: getUuid(),
    url,
  };

  return del({
    url, types, meta, id,
  });
}
