import React from 'react';
import cancan from 'js/system/cancan';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Dropdown, { DropdownToggle, DropdownContent } from 'components/Dropdown';
import moment from 'moment';
import * as selectors from 'js/redux/selectors';
import * as actions from 'js/redux/plan/plan-actions';
import { copyFromInterval } from 'src/entities/plannedMedia';
import { PLANNING_TIMEPLAN } from 'constants/permissions';
import { ISO_DATE_WITHOUT_TIMEZONE } from 'constants/dates';

function OptionsDropdown({
  checked,
  clipboard,
  groupChecked,
  ungroupChecked,

  copyLastWeek,
  pasteFromClipboard,
  copyCheckedToClipboard,
  planLocked,
}) {
  const timeplan = cancan.can('read', PLANNING_TIMEPLAN);
  const cCnt = checked.size;
  const clipboardCnt = clipboard && clipboard.size;

  return (
    <Dropdown
      right
      className="btn-group ml-1"
      closeOnClick
      data-testid="template-options-dropdown"
    >
      <DropdownToggle />
      <DropdownContent>
        {!planLocked && timeplan && (
          <button
            data-testid="dropdown-copy-last-week"
            type="button"
            onClick={copyLastWeek}
            className="dropdown-item"
          >
            <FormattedMessage id="views.planning.playlists.copyAndPasteLastWeek" />
          </button>
        )}
        {!planLocked && timeplan && <div className="dropdown-divider" />}

        {!planLocked && (
          <button
            data-testid="dropdown-paste-media"
            type="button"
            className={`dropdown-item${clipboardCnt ? '' : ' disabled'}`}
            onClick={clipboardCnt > 0 ? (() => pasteFromClipboard(clipboard)) : undefined}
          >
            <FormattedMessage id="views.planning.playlists.pasteSelected" />
          </button>
        )}
        {!planLocked && <div className="dropdown-divider" />}
        <button
          data-testid="dropdown-copy-media"
          type="button"
          className={`dropdown-item${cCnt === 0 ? ' disabled' : ''}`}
          onClick={cCnt > 0 ? copyCheckedToClipboard : undefined}
        >
          <FormattedMessage id="views.planning.playlists.copySelected" />
        </button>
        {!planLocked && (
          <>
            <button
              data-testid="dropdown-enable-alternation"
              type="button"
              className={`dropdown-item${cCnt < 2 ? ' disabled' : ''}`}
              onClick={cCnt >= 2 ? groupChecked : undefined}
            >
              <FormattedMessage id="views.planning.playlists.enableAlternation" />
            </button>
            <button
              data-testid="dropdown-disable-alternation"
              type="button"
              className={`dropdown-item${cCnt < 2 ? ' disabled' : ''}`}
              onClick={cCnt >= 2 ? ungroupChecked : undefined}
            >
              <FormattedMessage id="views.planning.playlists.disableAlternation" />
            </button>
          </>
        )}
      </DropdownContent>
    </Dropdown>
  );
}

export default connect(
  (state) => ({
    clipboard: selectors.getPasteData(state),
  }),
  (dispatch, props) => ({
    copyLastWeek() {
      dispatch(
        copyFromInterval(
          moment(props.templateFrom).add(-1, 'w'),
          moment(props.templateTo).add(-1, 'w'),
          (date) => moment(date).add(1, 'w'),
          props.playlistId,
        ),
      );
    },

    copyCheckedToClipboard() {
      return dispatch(actions.copyByIds(props.checked));
    },

    pasteFromClipboard(clipboard) {
      const { playlistId } = props;
      const tp = cancan.can('read', PLANNING_TIMEPLAN);
      // adjust the copied planning to the current time template limits
      const templateFrom = tp && moment(props.templateFrom.toDate()).format(ISO_DATE_WITHOUT_TIMEZONE);
      const templateTo = tp && moment(props.templateTo.toDate()).format(ISO_DATE_WITHOUT_TIMEZONE);

      const toPaste =
        clipboard &&
        clipboard.map((p) => {
          return p
            .set('time_limit_lower', templateFrom || p.get('time_limit_lower'))
            .set('time_limit_upper', templateTo || p.get('time_limit_upper'))
            .set('playlist', playlistId)
            .delete('id');
        });

      return dispatch(actions.pastePlannings(toPaste, playlistId));
    },
  }),
)(OptionsDropdown);
