import { createSelector } from 'reselect';

import {
  getCollection,
  getEntity,
} from '../selectors';

export function createSelectors(collection) {
  const getAll = (state) => getCollection(state, { collection });

  const getAllInArray = createSelector(
    getAll,
    (allEntities) => {
      return Object.values(allEntities);
    },
  );

  const getById = (entityId) => (state) => getEntity(state, {
    collection,
    entityId,
  });

  return {
    getAll,
    getAllInArray,
    getById,
  };
}
