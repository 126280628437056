import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import debug from 'debug';
import cancan from 'js/system/cancan';
import { intlShape } from 'utils/shapes';
import { DataTableContext } from './DataTableContext';

const log = debug('src:components:DataTable:Head');

const propTypes = {
  intl: intlShape.isRequired,
  theadConfigs: PropTypes.arrayOf(
    PropTypes.shape({
      hideTitle: PropTypes.bool,
      modulePermission: PropTypes.string,
      propertyName: PropTypes.string,
      sortable: PropTypes.bool,
      translationKey: PropTypes.string,
      width: PropTypes.number,
    }),
  ),
};

const DataTableHeadComponent = ({
  intl,
  theadConfigs = [],
}) => {
  log('render');
  const {
    setShouldSortInAscendingOrder,
    setSortByProperty,
    shouldSortInAscendingOrder,
    sortByProperty,
  } = useContext(DataTableContext);

  const sortableIcon = shouldSortInAscendingOrder
    ? 'icon-chevron-up'
    : 'icon-chevron-down';

  return (
    <thead>
      <tr>
        {theadConfigs.map(
          ({
            hideTitle,
            modulePermission,
            propertyName,
            sortable,
            translationKey,
            width,
          }) => {
            if (modulePermission && !cancan.can('read', modulePermission)) {
              return null;
            }

            const sortableClassName = sortable ? 'sortList pointer' : undefined;
            const widthClassName = width ? `w${width}` : undefined;
            const title = translationKey
              ? intl.formatMessage({ id: translationKey })
              : undefined;

            return (
              <th
                className={`${sortableClassName} ${widthClassName}`}
                key={title || propertyName}
                onClick={() => {
                  setSortByProperty(propertyName);
                  setShouldSortInAscendingOrder(!shouldSortInAscendingOrder);
                }}
                title={title}
              >
                {sortable && (
                  <i
                    className={`sortIcon ${
                      sortByProperty === propertyName
                        ? sortableIcon
                        : 'icon-chevron-right'
                    }`}
                  />
                )}
                {!hideTitle && title}
              </th>
            );
          },
        )}
      </tr>
    </thead>
  );
};

DataTableHeadComponent.propTypes = propTypes;

export const DataTableHead = injectIntl(DataTableHeadComponent);
