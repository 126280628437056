/*
* These functions make updates to slices of the data state. If there has been
* no change, they return the unchanged state. Otherwise they return a new state,
* i.e. the state is treated as IMMUTABLE by convention.
*/

export function updateEntities(oldEntities, payload, meta) {
  const {
    collection,
    verb,
    id,
    reset,
  } = meta;

  if (verb === 'del' && id) {
    const newEntities = {
      ...oldEntities,
      [collection]: {
        ...oldEntities[collection],
      },
    };
    delete newEntities[collection][id];
    return newEntities;
  }

  if (id || payload.id) {
    return {
      ...oldEntities,
      [collection]: {
        ...oldEntities[collection],
        [id || payload.id]: payload,
      },
    };
  }

  if (Array.isArray(payload)) {
    const newCollection = {
      ...(reset ? [] : oldEntities[collection]),
    };

    const collectionById = payload.reduce((collection, resource) => {
      const { id } = resource;
      return {
        ...collection,
        [id]: resource,
      };
    }, newCollection);

    return {
      ...oldEntities,
      [collection]: {
        ...(reset ? [] : oldEntities[collection]),
        ...collectionById,
      },
    };
  }

  return oldEntities;
}


function buildRequestKey({ verb, url }) {
  return `${verb}${url}`;
}

export function updateRequests(requests, meta, outcome) {
  const { uuid } = meta;
  const key = buildRequestKey(meta);
  if (outcome === 'start') {
    const newRequests = {
      ...requests,
      [key]: {
        ...requests[key],
        [uuid]: meta,
      },
    };

    return newRequests;
  }

  if (outcome === 'success' || outcome === 'fail') {
    const newRequests = { ...requests };
    if (newRequests[key]) {
      delete newRequests[key][uuid];
    }
    return newRequests;
  }

  return requests;
}

export function updateErrors(errors, error, meta, outcome) {
  const key = buildRequestKey(meta);

  if (outcome === 'fail') {
    return {
      ...errors,
      [key]: { meta, error },
    };
  }

  if (outcome === 'start' || outcome === 'success') {
    const newErrors = { ...errors };
    delete newErrors[key];
    return newErrors;
  }

  return errors;
}
