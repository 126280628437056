import React, { PureComponent } from 'react';
import PropTypes, { oneOfType, bool } from 'prop-types';
import Immutable from 'immutable';
import { each } from 'lodash';
import 'ol/ol.css';
import { getCenter } from 'ol/extent';
import debug from 'debug';

import { DefaultStyle, EditStyle, HighlightStyle } from './style';
import {
  createOsmMap,
  createDrawInteraction,
  createLayerSource,
  createModify,
  featureFromPixel,
  panTo,
  transWgs84,
  vector2wkt,
  wkt2vector,
} from './utils';


const log = debug('js:react:map:map');

const drawInteractions = {
  line: 'LineString',
  area: 'Point',
  stop: 'Point',
};

const propTypes = {
  center: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.number,
    PropTypes.string,
  ]),
  editFeatures: PropTypes.instanceOf(Immutable.Set),
  features: PropTypes.oneOfType([
    PropTypes.instanceOf(Immutable.List),
    PropTypes.instanceOf(Immutable.Map),
  ]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  highlight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
  onHover: PropTypes.func,
  onMapClick: PropTypes.func,
  onPointSelected: PropTypes.func,
  onPolyChange: PropTypes.func,
  selectPointEnabled: oneOfType([bool, PropTypes.string]),
};

const defaultProps = {
  center: undefined,
  editFeatures: undefined,
  features: undefined,
  height: undefined,
  highlight: undefined,
  onClick: undefined,
  onHover: undefined,
  onMapClick: undefined,
  onPointSelected: undefined,
  onPolyChange: undefined,
  selectPointEnabled: false,
};

class FeatureMap extends PureComponent {
  constructor(props) {
    super(props);

    this.map = {};
    this.layers = {};
    this.interactions = [];

    this.state = { isModifying: false };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // clear all old interfactions
    each(this.interactions, (i) => {
      this.map.removeInteraction(i);
    });

    this.interactions = [];

    if (nextProps.center && nextProps.center !== this.props.center) {
      panTo(
        this.map,
        wkt2vector(nextProps.center)
          .getGeometry()
          .getCoordinates(),
      );
    }

    // create the base feature layer
    if (!nextProps.features.equals(this.props.features)) {
      this.createAndAddLayer('base', nextProps.features, DefaultStyle);
    }

    // create edit feature layer from editFeatures
    // FIXME: it does not work to do this.props.editFeatures !== nextProps.editFeatures
    // because then when trying to move a point the map moves instead
    if (nextProps.editFeatures) {
      const features = this.createAndAddLayer(
        'edit',
        nextProps.editFeatures,
        EditStyle,
      );

      // add the modify interfaction for this layer
      const modify = createModify(features);
      modify.on('modifystart', () => {
        this.setState({ isModifying: true });
      });
      modify.on('modifyend', (f) => {
        this.setState({ isModifying: false });
        this.props.onPolyChange(vector2wkt(f.features.getArray()[0]));
      });

      this.map.addInteraction(modify);
      this.interactions.push(modify);

      // if the edit features has changed from last time, center on map
      const nextIds = nextProps.editFeatures.map((f) => f.get('id'));
      const prevIds =
        this.props.editFeatures &&
        this.props.editFeatures.map((f) => f.get('id'));

      if (nextIds.size > 0 && !nextIds.equals(prevIds)) {
        const center = getCenter(this.layers.edit.getSource().getExtent());
        this.map.getView().setCenter(center);
      }
    }

    // create highlight layer
    if (
      nextProps.highlight !== null &&
      nextProps.highlight !== this.props.highlight
    ) {
      const features = nextProps.features.filter((f) => {
        return f.get('id') === nextProps.highlight;
      });

      this.createAndAddLayer('highlight', features, HighlightStyle);
    }

    if (nextProps.selectPointEnabled) {
      const drawType = drawInteractions[nextProps.selectPointEnabled];

      if (drawType) {
        const draw = createDrawInteraction(
          this.handlePointSelected,
          drawType,
        );
        this.map.addInteraction(draw);
        this.interactions.push(draw);
      } else {
        throw new Error(
          `No drawInteraction specified for type: "${
            nextProps.selectPointEnabled
          }"`,
        );
      }
    }
  }

  initMap = () => {
    const lsc = window.localStorage.getItem('center');
    const center = (lsc && JSON.parse(lsc)) || [1447424.77536, 7479000.0];

    this.map = createOsmMap({
      center,
      eventListeners: {
        // enable click feature callback
        click: this.handleMapClick,
        // store postition for later retreival
        moveend: this.handleMapMove,
        // enable hover callback when hovering a feature on the map
        pointermove: this.handlePointerMove,
      },
      target: this.mapRef,
    });

    window.onresize = () => {
      this.forceUpdate();
      this.map.updateSize();
    };
  }

  handlePointSelected = (...args) => {
    this.props.onPointSelected(...args);
  }

  handleMapRef = (el) => {
    if (el !== this.mapRef) {
      this.mapRef = el;
      this.initMap();
    }
  }

  handleMapClick = (event) => {
    const coord = this.map.getCoordinateFromPixel(event.pixel);

    if (this.props.onMapClick) {
      this.props.onMapClick(transWgs84(coord));
    }

    const f = featureFromPixel(this.map, this.layers.base, event.pixel);

    if (this.props.onClick && !this.props.selectPointEnabled && f) {
      this.props.onClick(f.getId());
    }
  };

  handleMapMove = (event) => {
    window.localStorage.setItem(
      'center',
      JSON.stringify(event.map.getView().getCenter()),
    );
  };

  handlePointerMove = (event) => {
    if (this.state.isModifying || !this.props.onHover) {
      return;
    }

    const f = featureFromPixel(this.map, this.layers.base, event.pixel);
    this.props.onHover(f ? f.getId() : -1);
  };

  createAndAddLayer(layerKey, features, style) {
    const f = features
      .map((f) => {
        const wkt = f.get('wkt');
        if (!wkt) {
          return;
        }
        const ll = wkt && wkt2vector(f.get('wkt'), f.get('id'));
        if (f.has('style')) {
          // const fjs = f.toJS();
          ll.setStyle(f.get('style'));
        }
        return ll;
      })
      .filter((val) => val)
      .toList()
      .toJS();

    const layer = createLayerSource(f, style);
    this.map.removeLayer(this.layers[`${layerKey}`]);
    this.map.addLayer(layer);
    this.layers[`${layerKey}`] = layer;

    return f;
  }

  render() {
    log('render');
    const mapStyle = {
      width: '100%',
      height: this.props.height || '100%',
    };

    return <div ref={this.handleMapRef} style={mapStyle} />;
  }
}

FeatureMap.propTypes = propTypes;
FeatureMap.defaultProps = defaultProps;

export default FeatureMap;
