/* eslint-disable react/jsx-props-no-spreading */
// Libs
import React, { useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cancan from 'js/system/cancan';

import { MEDIA_TYPE_CUSTOM_WIDGET } from 'constants/mediaTypes';
import { canCreateMedia } from 'constants/permissions';
import { getWidgetTypes } from 'src/entities/widgets';

// Components
import { CreateMedia } from 'js/react/plan2/media-sidebar/Widgets/CreateMedia';
import { MediaEditor } from 'js/react/plan2/media-sidebar/MediaEditor';
import MediaLibrary from 'js/react/plan2/media/MediaLibrary';
import { Main as PlaylistPlan } from 'src/screens/Playlist';
import AnnouncementSidebar from 'js/react/plan2/AnnouncementSidebar';
import WidgetSidebar from 'js/react/Dashboard/components/WidgetSidebar';
import { CustomWidgetSidebar } from 'src/screens/CustomWidgetSidebar';

export const BS_SM_BREAKPOINT = 576; // sm-breakpoint from bootstrap/_variables.scss

const Sidebar = ({ href, props, widgets }) => {
  const history = useHistory();
  const handleCloseSidebar = useCallback(() => {
    const isMobile = window.innerWidth <= BS_SM_BREAKPOINT;
    if (!isMobile) {
      history.replace(href);
    }
  }, [href]);
  let widget;
  let widgetType;

  switch (props.sidebar) {
  case 'createMedia':
    widget = widgets.find((item) => {
      return item.id === String(props.widgetId);
    });
    if (!widget) {
      return null;
    }
    widgetType = widget.type;
    if (!cancan.can('read', canCreateMedia(widgetType))) {
      return null;
    }

    if (widgetType.includes(MEDIA_TYPE_CUSTOM_WIDGET)) {
      return (
        <CustomWidgetSidebar
          onClose={handleCloseSidebar}
          sidebar={props.sidebar}
          activeId={props.widgetId}
        />
      );
    }

    switch (widgetType) {
    case 'announcement':
      return (
        <AnnouncementSidebar
          onClose={handleCloseSidebar}
          announcementId={props.activeId}
        />
      );

    default:
      return (
        <CreateMedia
          onClose={handleCloseSidebar}
          widgetType={widgetType}
        />
      );
    }

  case 'media':
    return (
      <MediaEditor
        mediaId={props.activeId}
        onClose={handleCloseSidebar}
      />
    );

  case 'mediaLibrary':
    return (
      <MediaLibrary className="holder bg-white" />
    );

  case 'playlist':
    return (
      <PlaylistPlan
        {...props}
        playlistId={props.activeId}
        onClose={handleCloseSidebar}
      />
    );

  case 'announcement':
    return (
      cancan.can('read', canCreateMedia('announcement')) && (
        <AnnouncementSidebar
          onClose={handleCloseSidebar}
          announcementId={props.activeId}
        />
      )
    );

  case 'widget_custom':
    return (
      <CustomWidgetSidebar
        onClose={handleCloseSidebar}
        sidebar={props.sidebar}
        activeId={props.activeId}
      />
    );

  case 'widget':
    return <WidgetSidebar onClose={handleCloseSidebar} {...props} />;

  default:
    return null;
  }
};

const mapStateToProps = (state) => ({
  widgets: getWidgetTypes(state),
});

export default compose(connect(mapStateToProps))(Sidebar);
