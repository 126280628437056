import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { identity } from 'lodash';

import * as MsgActions from 'js/react/actions/message-actions';
import { getApprovedAudio } from 'src/entities/media';
import LoadingSpinner from '../LoadingSpinner';
import { AUDIO } from './constants';

export const AudioSequencePreviewer = ({ sequence }) => {
  const [playingIdx, setPlayingIdx] = useState(-1);
  const [previewAudio, setPreviewAudio] = useState();
  const [pending, setPending] = useState(false);
  const allAudio = useSelector(getApprovedAudio);
  const icon = playingIdx === -1 ? 'icon-play' : 'icon-stop';

  const handleToggle = () => {
    // Cache the audio since allAudio can be updated by external sources,
    // which could mess up the following segments.
    setPreviewAudio(allAudio);
    setPending(playingIdx === -1);
    setPlayingIdx(
      playingIdx === -1 ? 0 : -1,
    );
  };

  useEffect(() => {
    // Reset pending if audio was stopped
    playingIdx === -1 && setPending(false);
  }, [playingIdx]);

  useEffect(() => {
    const timer = pending && setTimeout(() => {
      setPlayingIdx(-1);
      MsgActions.error('Timeout');
    }, 20000);
    return () => {
      clearTimeout(timer);
    };
  }, [pending]);

  const audioItems = useMemo(() => {
    return playingIdx >= 0 && sequence.map(({ type, value }) => {
      const val = value?.trim();

      if (!val) { return null; }

      const audio = type === AUDIO
          // eslint-disable-next-line eqeqeq
          && previewAudio?.find((a) => a.id == val);
      const src = audio
        ? audio.preview
        : `${window.GS_API}/speech/${btoa(val)}`;

      const el = new Audio(src);
      el.addEventListener('error', (error) => {
        MsgActions.error('Unable to load preview audio, ensure SSML is valid.');
        setPlayingIdx(-1);
      });
      return el;
    }).filter(identity);
  }, [playingIdx]);

  useEffect(() => {
    const activeItem = audioItems[playingIdx];
    const play = () => {
      setPending(false);
      activeItem.play();
    };
    const next = () => setPlayingIdx((idx) => idx + 1);

    if (activeItem) {
      setPending(true);
      activeItem.readyState >= HTMLMediaElement.HAVE_FUTURE_DATA
        ? play()
        : activeItem.addEventListener('canplaythrough', play);
      activeItem.addEventListener('ended', next);
    } else {
      setPlayingIdx(-1);
    }

    return () => {
      // Stop playback
      activeItem?.pause();
      activeItem?.removeEventListener('canplaythrough', play);
      activeItem?.removeEventListener('ended', next);
    };
  }, [sequence, playingIdx]);

  return (
    <button
      type="button"
      onClick={handleToggle}
      className="btn btn-primary btn-sm"
    >
      {pending
        ? (
          <LoadingSpinner diameter="1.1rem" inverted />
        )
        : <i className={`${icon} icon-white`} />}
    </button>
  );
};
