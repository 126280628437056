import {
  buildGetType,
  buildDeleteType,
  buildPostType,
  buildPutType,
} from './buildApiActionTypes';
import {
  buildApiMetaFactory,
} from './buildApiMetaFactory';

export {
  buildGetType,
  buildDeleteType,
  buildPostType,
  buildPutType,
  buildApiMetaFactory,
};

export function createCrudActionCreators({ collection, url }) {
  const buildApiMeta = buildApiMetaFactory({
    collection,
    url,
  });

  const create = (body) => {
    return {
      type: buildPostType(collection),
      payload: body,
      meta: buildApiMeta(),
    };
  };

  const readAll = () => {
    return {
      type: buildGetType(collection),
      meta: buildApiMeta({ reset: true }),
    };
  };

  const readById = (entityId) => {
    return {
      type: buildGetType(collection),
      meta: buildApiMeta({ entityId }),
    };
  };

  const update = (body) => {
    return {
      type: buildPutType(collection),
      payload: body,
      meta: buildApiMeta({ entityId: body.id }),
    };
  };

  // called destroy to not conflict with the delete keyword
  const destroy = (entityId) => {
    return {
      type: buildDeleteType(collection),
      meta: buildApiMeta({ entityId }),
    };
  };

  return {
    create,
    readAll,
    readById,
    update,
    destroy,
  };
}
