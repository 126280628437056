import $ from 'jquery';
import { isNumber } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { intlShape } from 'utils/shapes';
import * as MsgActions from 'js/react/actions/message-actions';
// eslint-disable-next-line
import fileUpload from 'js/lib/upload'; // Jquery-plugin
import {
  fileUploadStarted,
  fileUploadProgress,
  fileUploadEnded,
  fileUploadFailed,
} from 'js/redux/api/fileUpload';
import { getFileUploadProgress } from 'js/redux/selectors';

/*
If FileUploadBtn hasnt been used or is finished then no copy exits in localStorage;
But when file starts uploading then a reference to the component is saved in localStorage;
The first file uploads itself and when finished issues a call to a callback;
this callback references the localStorage and in turn references back to the orginal component;
Through this the reference will be to the state of the component;
If FileUploadBtn has been used and is drawn, then a reference is present in localStorage;
Then FileUploadBtn grabs that reference and uses that to plugin into the process
*/

function mapStateToProps(state) {
  return {
    progress: getFileUploadProgress(state),
  };
}

const mapDispatchToProps = {
  fileUploadStarted,
  fileUploadProgress,
  fileUploadEnded,
  fileUploadFailed,
};

const propTypes = {
  intl: intlShape.isRequired,
  progress: PropTypes.number,
  onUploadComplete: PropTypes.func.isRequired,
  fileUploadStarted: PropTypes.func.isRequired,
  fileUploadProgress: PropTypes.func.isRequired,
  fileUploadEnded: PropTypes.func.isRequired,
  fileUploadFailed: PropTypes.func.isRequired,
};

class FileUploadBtn extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fileNames: [],
      fileObjs: [],
    };
  }

  setUploadButton = (uploadButton) => {
    const that = this;
    uploadButton && $(uploadButton).fileupload({
      url: `${window.GS_API}/media/upload`,
      dataType: 'json',
      sequentialUploads: true,

      progressall: (e, data) => {
        let progress = parseInt(data.loaded / data.total * 100, 10);
        if (progress === 100) {
          progress = null;
        }
        that.props.fileUploadProgress(progress);
      },

      add(e, data) {
        let errors = '';

        function formatBytes() {
          return `${(that.props.maxUploadytes / 1024 / 1024).toFixed(1)}MB`;
        }

        for (let i = 0; i < data.files.length; i++) {
          const f = data.files[i];
          if (f.size > that.props.maxUploadytes) {
            errors +=
              `${f.name
              } is too large (${
                formatBytes(f.size)
              }), max allowed is ${
                formatBytes(that.props.maxUploadytes)}`;
          }
        }

        if (errors.length > 0) {
          MsgActions.error(errors);
        } else {
          data.submit();
        }
      },

      done(e, data) {
        if (!that.props.onUploadComplete) {
          // eslint-disable-next-line
          console.warn('Unhandled upload: ' + data.result);
        } else {
          that.props.onUploadComplete(data.result);
        }
        that.props.fileUploadEnded();
      },

      fail(e, data) {
        if (data.jqXHR.status === 413) {
          MsgActions.error('(413) Upload failed, file is to large.');
        } else {
          try {
            const resp = JSON.parse(data.jqXHR.responseText);
            MsgActions.error(resp.detail);
          } catch (err) {
            MsgActions.error(data.jqXHR.statusText);
          }
        }
        that.props.fileUploadFailed();
      },

      beforeSend: (xhr) => {
        that.props.fileUploadStarted();
        xhr.setRequestHeader('X-CSRFToken', that.getFromCookie('csrftoken'));
      },
    });
  }

  // handleChange(e) {
  //  var that = this;
  //  return function(e) {
  //      files = e.target.files;
  //      // that.parseFile(files);
  //      //files = _.filter(files, that.filterFiles);
  //      that.setState({ 'fileObjs': files }, that.doUpload);
  //  }
  // }

  getFromCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = $.trim(cookies[i]);
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) == `${name}=`) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  // doUpload() {
  //     this.parseFile(this.state.fileObjs);
  //     const f_length = this.state.fileObjs.length;

  //     for (var i = 0; i < f_length; i++) {
  //         const f_handle = this.state.fileObjs[i];
  //         const xhr = new XMLHttpRequest();
  //         console.log(f_handle);

  //         // Check file size and type?!
  //         if (xhr.upload) {
  //             xhr.open('POST', window.GS_API + '/media/upload/', true);
  //             console.log('Creating POST XHR');
  //             xhr.setRequestHeader('X_FILENAME', f_handle.name);
  //             xhr.setRequestHeader('X-CSRFToken', this.getFromCookie('csrftoken'));
  //             xhr.setRequestHeader('Content-Disposition', 'attachment; filename="' +
  //                 encodeURI(f_handle.name) + '"');
  //             xhr.setRequestHeader('Content-Length', f_handle.size);
  //             console.log('Creating Request Headers');
  //             console.log('Starting file send...');
  //             xhr.send(this.state.fileObjs[i]);
  //             console.log('Done?');
  //             // Add file to persistent state and callbacks accordingly.
  //             // Also add callback so that when a file is done it starts next upload.
  //             // No simultaneusly. Ie. a bootstrap uplaoder
  //         }
  //     }
  //     console.log('All files are processed');
  // }

  render() {
    const uploadText = this.props.intl.formatMessage({
      id: 'views.planning.media.upload',
    });
    let btnType = 'btn-primary';
    let text = uploadText;
    if (isNumber(this.props.progress) && this.props.progress >= 0) {
      text = `${uploadText}  ${this.props.progress}%`;
      btnType = 'btn-warning disabled';
    }

    return (
      <span id="media_upload" className="d-inline-block mr-1" encType="multipart/form-data">
        <button
          type="button"
          className={`position-relative btn ${btnType}`}
        >
          <i className="icon-upload icon-white" /> {text}
          <input
            ref={this.setUploadButton}
            type="file"
            name="files[]"
            multiple="multiple"
            style={{
              opacity: '0',
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
            }}
          />
        </button>
      </span>
    );
  }
}

FileUploadBtn.propTypes = propTypes;

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(FileUploadBtn);

/* Flera media samtidigt, Progress bar  */
