import {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import identity from 'lodash/identity';
import { getOngoingRequests } from 'src/core/api';

export const useAsyncDispatch = () => {
  const dispatch = useDispatch();
  const [requests, setRequests] = useState();
  const ongoing = useSelector(getOngoingRequests);
  const pendingRequests = useMemo(() => (
    requests?.map((id) => ongoing[id]).filter(identity)
  ), [ongoing]);

  const trackedDispatch = useCallback((action) => {
    const requestId = action.meta?.requestId;
    requestId && setRequests((reqs = []) => [...reqs, requestId]);
    dispatch(action);
  }, []);

  useEffect(() => {
    if (pendingRequests?.length !== requests?.length) {
      setRequests(pendingRequests);
    }
  }, [pendingRequests]);

  return [trackedDispatch, pendingRequests];
};
