import React from 'react';
import { useGetTranslation } from '../useGetTranslation';

const createMarkup = (text) => {
  return { __html: text };
};

export const OptionSelect = (
  {
    id,
    title,
    description,
    value,
    options,
    onChange,
    disabled,
  },
) => {
  const getTranslation = useGetTranslation();

  return (
    <div
      id={id}
      className="form-group"
      data-testid="customWidgetSelectField"
    >
      <label htmlFor={id}>
        {title}
      </label>
      <select
        onChange={(event) => onChange(event.target.value)}
        value={value}
        type="number"
        className="form-control"
        disabled={disabled}
      >
        {options?.map(({ value: val, localization }) => {
          const { title: label, description: desc } = getTranslation(localization) || {};
          return (
            <option
              key={val}
              value={val}
              title={desc}
            >
              {label}
            </option>
          );
        })}
      </select>
      <small className="form-text text-muted">
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={createMarkup(description)}
        />
      </small>
    </div>
  );
};
