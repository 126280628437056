// Libs
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import cancan from 'js/system/cancan';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Components
import GsSideBar from 'utils/right-sidebar';
import GSColumnsLayout from 'components/GSColumnsLayout';
import SidebarContent from 'components/SidebarContent';
import SidebarNavigation from 'components/SidebarContent/SidebarNavigation';
import Sidebar from 'js/react/sidebars';
import { fetchMediaObjects } from 'src/entities/media';
import { PLANNING_TIMEPLAN } from 'constants/permissions';
import DateBar from './date-bar';
import MediaLibrary from './media/MediaLibrary';
import PlaylistLibrary from './playlists/playlist-lib';

const PlanLayout = ({ history, date, match }) => {
  const params = { ...match.params };
  if (Object.prototype.hasOwnProperty.call(params, 'widgetId')) {
    params.sidebar = 'createMedia';
  }
  const { sidebar } = params;
  const hasTimeplan = cancan.can('read', PLANNING_TIMEPLAN);

  const handleCloseSidebar = (e) => {
    e && e.preventDefault();
    history.replace('/plan');
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <GSColumnsLayout
        topBar={hasTimeplan && (
          <div className="d-none d-sm-block">
            <DateBar date={date} />
          </div>
        )}
        leftCol={
          <PlaylistLibrary />
        }
        rightCol={
          <MediaLibrary />
        }
        sidebar={(
          <GsSideBar
            href="/plan"
            onClose={handleCloseSidebar}
          >
            {sidebar && (
              <SidebarContent
                onClose={handleCloseSidebar}
                headerClass="d-sm-none modal-header flex-shrink no-gutters"
                header={(
                  <SidebarNavigation
                    onClose={handleCloseSidebar}
                  />
                )}
              >
                <Sidebar
                  href="/plan"
                  props={params}
                />
              </SidebarContent>
            )}
          </GsSideBar>
        )}
      />
    </DndProvider>
  );
};

const mapDispatchToProps = {
  fetchMediaObjects,
};

export default compose(
  connect(undefined, mapDispatchToProps),
)(PlanLayout);
