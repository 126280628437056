import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { intlShape } from 'utils/shapes';
import Store from 'js/stores/ajax-store';
import Badge from '../components/Badge';

const MediaBuildStore = Store('/build');

class LatestMediaBuild extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loading: true,
    };
  }

  UNSAFE_componentWillMount() {
    this.loadData();
  }

  loadData() {
    MediaBuildStore.fetch().then((data) => {
      this.setState({
        data: this.prepData(data),
        loading: false,
      });
    });
  }

  prepData(data) {
    const completed = data && data.filter(
      (b) => b.get('build_status') !== -1,
    ).sortBy(
      (b) => b.get('build_schedule'),
    );
    return completed.last();
  }

  getClass(status) {
    if (status == 0) {
      return 'alert alert-success';
    } if (status > 0) {
      return 'alert alert-danger';
    }

    return 'alert alert-warning';
  }

  getIcon(status) {
    if (status == 0) {
      return 'icon-ok mt3';
    } if (status > 0) {
      return 'icon-cancel mt3';
    }

    return '';
  }

  render() {
    const { data } = this.state;
    const { badgeId, intl } = this.props;
    const date = data && moment(
      data.get('build_schedule'),
    ).format('YYYY-MM-DD HH:mm');
    const status = data && data.get('build_status');

    return (
      <Badge
        title={intl.formatMessage({ id: 'views.dashboard.mediaBuild.latestbuild' })}
        badgeId={badgeId}
        className={this.getClass(status)}
      >
        {this.state.loading && `${intl.formatMessage({ id: 'common.loading' })}...`}
        <h4 className="ma-0">
          <i className={this.getIcon(status)} /> {data && data.get('id')}
        </h4>
        <small>
          {date}
        </small>
      </Badge>
    );
  }
}

LatestMediaBuild.propTypes = {
  badgeId: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(LatestMediaBuild);
