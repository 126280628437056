import { useRef } from 'react';
import {
  DAYOFWEEK_CONDITION, ODDDAY_CONDITION, TIMEOFDAY_CONDITION_LOWER, TIMEOFDAY_CONDITION_UPPER,
} from '../constants';

export const filtersConfig = [
  {
    field: 'gsID',
    op: 'eq',
  },
  {
    field: 'area',
    op: 'eq',
  },
  {
    field: 'stop',
    op: 'eq',
  },
  {
    field: 'line',
    op: 'eq',
  },
  {
    field: 'destination',
    op: 'eq',
  },
  {
    field: DAYOFWEEK_CONDITION,
    op: 'eq',
  },
  {
    field: TIMEOFDAY_CONDITION_UPPER,
    op: 'lte',
  },
  {
    field: TIMEOFDAY_CONDITION_LOWER,
    op: 'gte',
  },
  {
    field: ODDDAY_CONDITION,
    op: 'eq',
  },
];

function getCommonValue(plannings, attr) {
  return plannings?.reduce((acc, m) => {
    return m[attr]?.valueOf() === acc
      ? acc?.valueOf()
      : undefined;
  }, plannings?.[0]?.[attr]);
}

function getFieldConditions(planning) {
  return planning?.additional_data?.conditions?.ops || [];
}

const getFieldConditionValueFactory = (index) => (planning) => {
  return JSON.stringify(getFieldConditions(planning)?.[index]?.value || []);
};

const getFieldConditionOperatorFactory = (index) => (planning) => {
  return getFieldConditions(planning)?.[index]?.op;
};

function getFieldConditionIndex(planning, field) {
  const conditions = getFieldConditions(planning);
  return conditions.findIndex(({ field: f }) => f === field);
}

function getCommonCondition(plannings, field) {
  const fieldIndex = getFieldConditionIndex(plannings[0], field);
  const getValue = getFieldConditionValueFactory(fieldIndex);
  const firstValue = getValue(plannings[0]);
  const allAreSame = !plannings.find((planning) => {
    const val = getValue(planning);
    return val !== firstValue;
  });

  return allAreSame ? JSON.parse(firstValue || '[]') : [];
}

function getCommonFieldOperator(plannings, field) {
  const fieldIndex = getFieldConditionIndex(plannings[0], field);
  const getOpValue = getFieldConditionOperatorFactory(fieldIndex);
  const firstOpValue = getOpValue(plannings[0]) || field.op;
  const allAreSame = !plannings.find((planning) => {
    const val = getOpValue(planning) || field.op;
    return val !== firstOpValue;
  });

  return allAreSame ? firstOpValue : field.op;
}

// Avoid setting key for required fields if they are undefined
// to allow default value override when saving.
const setOptionalAttr = (key, value) => {
  return value
    ? { [key]: value }
    : {};
};

export const useSharedValues = (plannings) => {
  const values = useRef();

  if (!values.current) {
    values.current = {
      max_play_count: getCommonValue(plannings, 'max_play_count'),
      playlistId: plannings && plannings[0] && plannings[0].playlist,
      ...setOptionalAttr('time_limit_lower', getCommonValue(plannings, 'time_limit_lower')),
      ...setOptionalAttr('time_limit_upper', getCommonValue(plannings, 'time_limit_upper')),
      additional_data: {
        conditions: {
          op: 'and',
          ops: filtersConfig.map(({ field }) => ({
            field,
            op: getCommonFieldOperator(plannings, field),
            value: getCommonCondition(plannings, field),
          })),
        },
      },
    };
  }

  return values;
};
