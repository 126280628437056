/* global GS_API */
import { isFunction, isUndefined } from 'lodash';
import * as MsgActions from 'js/react/actions/message-actions';
import { getCsrfToken } from 'src/core/api/apiRequest/helpers';

async function handleApiError(response) {
  // API error response should contain json object with "detail" field
  // display that, or display the raw response text

  let body;
  try {
    body = await response.json();
  } catch (e) {
    body = await response.text();
  }

  if (body !== '') {
    if (!isUndefined(body.detail)) {
      MsgActions.error(body.detail);
    }
    MsgActions.error(JSON.stringify(body));
  } else if (response.status === 403) {
    MsgActions.error('Action forbidden (403)');
  } else {
    MsgActions.error(`Error ${response.status}`);
  }

  return body;
}

function appendSlash(path) {
  if (path.substr(path.length - 1, 1) === '/') {
    return path;
  }
  return `${path}/`;
}


// exports:

export function fetchJson(url) {
  return fetch(GS_API + url).then((response) => response.json());
}

export function create(url, data) {
  return window
    .fetch(GS_API + url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCsrfToken(),
      },
      body: JSON.stringify(data),
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      throw handleApiError(response);
    });
}

export function update(url, item) {
  const id = isFunction(item.get)
    ? item.get('id')
    : (item.id || '');

  return window
    .fetch(id ? GS_API + appendSlash(url) + id : GS_API + url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCsrfToken(),
      },
      body: JSON.stringify(item),
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }

      throw handleApiError(response);
    });
}

export function destroy(url, id) {
  return window
    .fetch(GS_API + appendSlash(url) + id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCsrfToken(),
      },
    })
    .then((response) => {
      if (response.ok) {
        return;
      }

      throw handleApiError(response);
    });
}
