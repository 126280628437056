/* eslint-disable react/jsx-no-bind */
import React, { memo, useMemo, useContext } from 'react';
import { uniq } from 'lodash';
import { useSelector } from 'react-redux';

import GSCombobox from 'components/GSCombobox';
import { getPlacesInObject } from 'src/entities/places';

import { useIntl } from 'react-intl';
import SpeedControls from './SpeedControls';
import {
  setPlaying,
  setSelectedDestination,
  setSelectedLine,
  setSelectedStop,
  setVisiblePlaces,
  SimulationContext,
} from './StateManager';
import { usePlaybackManager } from '../hooks/usePlaybackManager';

const usePlacesByType = (placeType, selected) => {
  const allPlaces = useSelector(getPlacesInObject) || {};
  const activeItem = allPlaces[selected];
  const items = useMemo(() => {
    const places = Object.values(allPlaces || {});
    return places.filter(({ type }) => {
      return type.toLowerCase() === placeType;
    });
  }, [allPlaces]);

  return [items, activeItem];
};

const Controls = memo(() => {
  const [
    {
      selectedLine, selectedDestination, selectedStop, playing, visiblePlaces,
    },
    dispatch,
  ] = useContext(SimulationContext);
  const [lines, activeLine] = usePlacesByType('line', selectedLine);
  const [destinations, activeDestination] = usePlacesByType(
    'stop',
    selectedDestination,
  );
  const [stops, activeStop] = usePlacesByType('stop', selectedStop);
  const intl = useIntl();

  // The playback manager handles playback speed state,
  // and automatic movement on the map based on current speed
  const { playbackSpeed, onSpeedChange, onStep } = usePlaybackManager();

  function filterByName(item, value) {
    const name = item.name.toLowerCase();
    const search = value.toLowerCase();
    return name.indexOf(search) !== -1;
  }

  const handleSelect = (line) => {
    dispatch(setSelectedLine(line.id || ''));

    if (line.id) {
      dispatch(setVisiblePlaces(uniq([...visiblePlaces, line.id])));
    }
  };

  const handleSelectDest = (stop) => {
    dispatch(setSelectedDestination(stop.id || ''));
    if (stop.id) {
      dispatch(setVisiblePlaces(uniq([...visiblePlaces, stop.id])));
    }
  };

  const handleSelectStop = (stop) => {
    dispatch(setSelectedStop(stop.id || ''));
    if (stop.id) {
      dispatch(setVisiblePlaces(uniq([...visiblePlaces, stop.id])));
    }
  };

  const onTogglePlayback = (pl) => {
    dispatch(setPlaying(pl));
  };

  const renderListItem = ({ item }) => (
    <span>
      {!item.id && (
        <span className="mr-1">
          <i className="icon-ban-circle" />
        </span>
      )}
      {item.name}
    </span>
  );

  return (
    <>
      <div
        className="d-flex flex-wrap md-flex-no-wrap gap-2"
        title={activeLine?.name || ''}
        style={{
          minWidth: '50%',
        }}
      >
        <GSCombobox
          renderListItem={renderListItem}
          placeholder={intl.formatMessage({
            id: 'views.simulation.selectLine',
          })}
          data={[
            {
              name: intl.formatMessage({ id: 'views.report.clearSelection' }),
              id: null,
            },
            ...lines,
          ]}
          value={activeLine?.name || null}
          onSelect={handleSelect}
          filter={filterByName}
          className="mr-2 mb-2 mb-xl-0"
        />
        <GSCombobox
          renderListItem={renderListItem}
          placeholder={intl.formatMessage({
            id: 'views.simulation.selectDestination',
          })}
          data={[
            {
              name: intl.formatMessage({ id: 'views.report.clearSelection' }),
              id: null,
            },
            ...destinations,
          ]}
          value={activeDestination?.name || null}
          onSelect={handleSelectDest}
          filter={filterByName}
          className="mr-2 mb-2 mb-xl-0"
        />
        <GSCombobox
          renderListItem={renderListItem}
          placeholder={intl.formatMessage({
            id: 'views.simulation.selectCurrentStop',
          })}
          data={[
            {
              name: intl.formatMessage({ id: 'views.report.clearSelection' }),
              id: null,
            },
            ...stops,
          ]}
          value={activeStop?.name || null}
          onSelect={handleSelectStop}
          filter={filterByName}
          className="mr-2 mb-2 mb-xl-0"
        />
      </div>

      <SpeedControls
        backward
        stop
        play
        fastForward
        currentSpeed={playbackSpeed}
        onSpeedChange={onSpeedChange}
        onStep={onStep}
        playing={playing}
        onTogglePlayback={onTogglePlayback}
        speeds={[0.5, 1, 3]}
      />
    </>
  );
});

export default Controls;
