import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defaultMemoize } from 'reselect';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  fetchSchemes,
} from 'js/redux/api/data';
import { createClientGroup, fetchClientGroups, getClientGroupsInArray } from 'src/entities/clientGroups';

import { clientGroupShape } from 'utils/shapes';

const ROUTE = '/clients/client_groups';

const propTypes = {
  clientGroups: PropTypes.arrayOf(clientGroupShape),
  fetchClientGroups: PropTypes.func.isRequired,
  createClientGroup: PropTypes.func.isRequired,
  fetchSchemes: PropTypes.func.isRequired,
};

function ClientGroupRow({ group }) {
  const href = `${ROUTE}/${group.id}/edit`;

  return (
    <div className="row flex-nowrap border-bottom">
      <div className="d-flex col py-3">
        <Link to={href}>
          {group.name}
        </Link>
      </div>
    </div>
  );
}

ClientGroupRow.propTypes = {
  group: clientGroupShape.isRequired,
};

class ClientGroupList extends React.PureComponent {
  state = {
    searchQuery: '',
  }

  filterAndSort = defaultMemoize((clientGroups = [], searchQuery) => {
    const groups = clientGroups.sort((a, b) => (a.name > b.name ? 1 : -1));
    return searchQuery
      ? groups.filter(({ name }) => name.toLowerCase().includes(searchQuery))
      : groups;
  })

  componentDidMount() {
    this.loadGroupData();
  }

  loadGroupData = () => {
    this.props.fetchClientGroups();

    // Make sure schemes are up to date.
    // Needed as long as we still are using schemeStore elsewhere.
    this.props.fetchSchemes();
  };

  handleCreate = () => {
    this.props.createClientGroup({
      name: ' New group',
    }).then(({ payload: { id } = {} }) => {
      if (id !== undefined) {
        this.props.history.replace(`${ROUTE}/${id}/client_group_edit`);
      }
    });
  };

  handleSearch = (searchQuery) => {
    this.setState({ searchQuery });
  }

  renderClients() {
    const clientGroups = this.filterAndSort(
      this.props.clientGroups,
      this.state.searchQuery,
    );

    return clientGroups.map((group) => (
      <ClientGroupRow
        key={group.id}
        group={group}
      />
    ));
  }

  renderActions() {
    return (
      <div className="align-self-center ml-auto">
        <button
          type="button"
          onClick={() => this.handleCreate()}
          className="float-right btn btn-primary"
        >
          <i className="icon-white icon-list pr10" />
          <FormattedMessage id="common.add" />
        </button>
      </div>
    );
  }

  render() {
    const {
      className,
    } = this.props;

    return (
      <div className={className}>
        <div className="d-flex p-3">
          <h2 className="mb-0">
            <FormattedMessage id="common.clientGroups" />
          </h2>
          {this.renderActions()}
        </div>
        <div
          className="border-top overflow-y-auto col min-height-1"
          style={{
            minHeight: 160,
          }}
        >
          {this.renderClients()}
        </div>
      </div>
    );
  }
}

function mapState(state) {
  const clientGroups = getClientGroupsInArray(state);
  return {
    clientGroups,
  };
}

const mapDispatch = {
  fetchClientGroups,
  createClientGroup,
  fetchSchemes,
};

ClientGroupList.propTypes = propTypes;

export default connect(mapState, mapDispatch)(ClientGroupList);
