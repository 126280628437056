import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import LineString from 'ol/geom/LineString';
import MultiPoint from 'ol/geom/MultiPoint';
import Point from 'ol/geom/Point';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';

const dot = new Circle({
  radius: 5,
  fill: new Fill({
    color: 'rgba(74, 226, 255, 0.39)',
  }),
  stroke: new Stroke({
    color: '#37474f',
    width: 2,
  }),
});

const dotSmall = new Circle({
  radius: 4,
  fill: new Fill({
    color: 'rgba(255, 0, 0, 0.1)',
  }),
  stroke: new Stroke({
    color: 'rgba(0, 0, 0, 0.95)',
    width: 2,
  }),
});

// const dotXSmall = new Circle({
//   radius: 3,
//   fill: new Fill({
//     color: 'rgba(74, 226, 255, 0.39)',
//   }),
//   stroke: new Stroke({
//     color: '#0055CC',
//     width: 2,
//   }),
// });

const dotHighlight = new Circle({
  radius: 6,
  fill: new Fill({
    color: 'rgba(255, 0, 0, 0.60)',
  }),
  stroke: new Stroke({
    color: '#37474f',
    width: 2,
  }),
});

var Default = [
  new Style({
    point: dot,
    fill: new Fill({
      color: 'rgba(0, 95, 204, 0.15)',
    }),
    stroke: new Stroke({
      color: 'black',
      width: 3,
    }),
    image: dot,
  }),
];

export const HighlightStyle = new Style({
  image: dotHighlight,
  fill: new Fill({
    color: 'rgba(255, 0, 0, 0.2)',
  }),
  stroke: new Stroke({
    color: 'black',
    width: 4,
  }),
});

var Edit = [
  new Style({
    fill: new Fill({
      color: 'rgba(255, 0, 0, 0.1)',
    }),
    stroke: new Stroke({
      color: 'rgba(255, 0, 0, 0.95)',
      width: 3,
      lineDash: [10, 10],
    }),
    image: dot,
  }),
  new Style({
    image: dotSmall,
    geometry: function(feature) {
      const multiLine = feature.getGeometry().getCoordinates();
      const coordinates = multiLine[0];

      if (Array.isArray(coordinates)) {
        return new MultiPoint(coordinates);
      }

      return new MultiPoint(multiLine);
    },
  }),
];

/** custom styling for linestrings*/
export const DefaultStyle = function(feature) {
  if (feature.getGeometry().forEachSegment) {
    var styles = [];

    feature.getGeometry().forEachSegment((start, end) => {
      var s = new Style({
        geometry: new LineString([start, end]),
        stroke: new Stroke({
          color: 'black',
          width: 3,
          lineDash: [10, 10],
        }),
      });
      styles.push(s);
    });

    return styles;
  } else {
    return Default;
  }
};

export const EditStyle = function(feature) {
  if (feature.getGeometry().forEachSegment) {
    var styles = [];

    let idx = 0;

    feature.getGeometry().forEachSegment((start, end) => {
      var s = new Style({
        stroke: new Stroke({
          color: 'rgba(255, 0, 0, 0.95)',
          width: 3,
          lineDash: [10, 10],
        }),
      });

      styles.push(s);

      var s2 = new Style({
        geometry: new Point(end),
        image: dotSmall,
      });
      styles.push(s2);

      if (idx === 0) {
        var s3 = new Style({
          geometry: new Point(start),
          image: dotSmall,
        });
        styles.push(s3);
      }

      idx += 1;
    });

    return styles;
  }

  return Edit;
};
