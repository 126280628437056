export async function del({ csrfToken, url }) {
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrfToken,
    },
  });

  if (!response.ok) {
    throw (response);
  }

  try {
    if (response.statusText !== 'No Content') {
      return await response.json();
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
}
