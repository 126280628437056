import { useState, useEffect } from 'react';

import {
  setError, setHistory, setLoading, setIframeSrc,
} from '../../components/StateManager';

export const BUILD_API_PATH = '/api/gui/build/simulation';

// Create media player instance and get path to it.
export const useMediaServer = ({
  buildId,
  schemeId,
  clientGroup,
}, request, dispatch) => {
  const [rootPath, setRootPath] = useState('');

  const getPath = (response) => {
    if (clientGroup !== '') {
      return `${response.url}/simplayer/index.html?api-root=${response.apiRoot}&client-group=${clientGroup}`;
    }
    return `${response.url}/simplayer/index.html?api-root=${response.apiRoot}&scheme-id=${schemeId}`;
  };

  const updatePath = async () => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    dispatch(setHistory([]));
    dispatch(setIframeSrc(''));

    try {
      const response = await request(BUILD_API_PATH, 'POST', { buildId })
        .then((res) => res.json());

      if (!response.url) {
        throw new Error(response.detail);
      }

      // Bypass CORS issue on localhost
      const path = window.location.hostname === 'localhost'
        ? response.apiRoot
        : response.url;
      setRootPath(path);
      dispatch(setIframeSrc(getPath(response, schemeId, clientGroup)));
      dispatch(setLoading(false));
      dispatch(setHistory([]));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      dispatch(setLoading(false));
      dispatch(setError(e.message));
    }
  };

  useEffect(() => {
    schemeId && updatePath();
  }, [buildId, schemeId, clientGroup]);

  return rootPath;
};
