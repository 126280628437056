import { createSelectors } from "../../core/data";

import { COLLECTION } from "./constants";

export const { getById: getWidgetById, getAllInArray: getWidgetTypes } =
  createSelectors(COLLECTION);

export function getWidgetType(state, mediaType) {
  return getWidgetTypes(state).find(({ type }) => type === mediaType);
}
