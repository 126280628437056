import { FormattedMessage, injectIntl } from 'react-intl';
import React from 'react';
import createClass from 'create-react-class';
import im from 'immutable';
import cancan from 'js/system/cancan';
import Store from 'js/stores/ajax-store';
import * as MsgActions from 'js/react/actions/message-actions';
import HelpIcon from 'components/HelpIcon';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { SchemeView } from './SchemeView';

require('./scheme-list-style.scss');

const SchemeStore = Store('/schemesv2');
const SchemeTagsStore = Store('/schemesv2/tags');

const layoutImgs = [
  require('img/layout.png'),
  require('img/layout2.png'),
].map((i) => i.default);

export default injectIntl(
  createClass({
    getInitialState() {
      return {
        schemes: im.List(),
        editId: -1,
      };
    },

    UNSAFE_componentWillMount() {
      this.loadData();
    },

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (!this.props.playlists.equals(nextProps.playlists)) {
        this.loadData();
      }
    },

    loadData() {
      return Promise.all([
        new Promise((resolve) => {
          SchemeStore.fetch().then((schemes) => {
            this.setState({ schemes });
            resolve(schemes);
          });
        }),
        new Promise((resolve) => {
          SchemeTagsStore.fetch().then((tags) => {
            this.setState({ tags });
            resolve(tags);
          });
        }),
      ]);
    },

    saveScheme(scheme) {
      SchemeStore.update(scheme.toJS()).then(() => {
        MsgActions.success(`Updated scheme ${scheme.get('name')}`);
        this.loadData();
      });
    },

    render() {
      const { formatMessage } = this.props.intl;
      if (!this.props.playlists.size === 0) {
        // eslint-disable-next-line no-console
        console.warn('no playlists passed to scheme view');
        return <div />;
      }

      // map of playlists
      const playlists = this.props.playlists.reduce((acc, pl) => {
        return acc.set(pl.get('id'), pl);
      }, im.Map());

      const onSchemePlaylistAdd = (schemeId, playlistId, tag) => {
        const scheme = this.state.schemes
          .filter((innerScheme) => innerScheme.get('id') === schemeId)
          .get(0);

        let order = 0;
        const items = scheme.get('playlists').map((item) => {
          let o = -1;
          const type = item.get('order') < 0 ? 'direct' : 'repeating';
          if (type === 'repeating') {
            o = order;
            order += 1;
          }
          return item.set('order', o);
        });
        const tmp = scheme.set('playlists', items).toJS();
        let newOrder = order;

        if (playlists && playlists.get(playlistId).get('type') === 'direct') {
          newOrder = -1;
        }
        tmp.playlists.push({ playlist: playlistId, order: newOrder, tag });
        this.saveScheme(im.fromJS(tmp));
      };

      const onUpdateRepeating = (schemeId, schemePlaylists) => {
        // a list of repating schemePlaylists should be set for scheme
        // with schemeId

        // get the scheme
        const scheme = this.state.schemes
          .filter((innerScheme) => innerScheme.get('id') === schemeId)
          .get(0);

        // filter out all direct playlist items as an inital list
        let items = scheme.get('playlists').filter((item) => {
          const type = item.get('order') < 0 ? 'direct' : 'repeating';
          return type === 'direct';
        });

        // add repeating items in the order received in schemePlaylists
        items = items.concat(
          schemePlaylists.map((spl, i) => {
            return spl.set('order', i);
          }),
        );

        // save state for scheme
        this.saveScheme(scheme.set('playlists', items));
      };

      const onSchemePlaylistDelete = (schemeId, itemId) => {
        const scheme = this.state.schemes
          .filter((innerScheme) => innerScheme.get('id') === schemeId)
          .get(0);
        const items = scheme.get('playlists').filter((item) => {
          return item.get('id') !== itemId;
        });
        this.saveScheme(scheme.set('playlists', items));
      };

      const onSchemeDestroy = (schemeId) => {
        SchemeStore.destroy(schemeId).then(() => {
          this.loadData();
        });
      };

      const onCreateScheme = () => {
        SchemeStore.create({
          name: formatMessage({
            id: 'views.playlists.newSchemeName',
          }),
        }).then(() => {
          this.loadData().then((schemes) => {
            // Get the new / most recently created scheme
            const createdScheme = schemes.get(-1);

            // Enable edit mode on our newly created scheme
            this.setState({ editId: createdScheme.get('id') });
          });
        });
      };

      const setSchemeAttribute = (schemeId, key, value, save) => {
        this.setState((prevState) => ({
          ...(prevState.schemes.map((scheme) => {
            let newScheme = scheme;
            if (newScheme.get('id') === schemeId) {
              newScheme = scheme.set(key, value);
              if (save) {
                this.saveScheme(newScheme);
              }
            }
            return newScheme;
          })),
        }));
      };

      const onSchemeDurationChange = (schemeId, val, save) => {
        setSchemeAttribute(schemeId, 'duration', val, save);
      };

      const onSchemeNameChange = (schemeId, name, save) => {
        setSchemeAttribute(schemeId, 'name', name, save);
      };

      const onToggleEdit = (schemeId) => {
        if (this.state.editId === schemeId) {
          this.setState({ editId: -1 });
        } else {
          this.setState({ editId: schemeId });
        }
      };

      const showCreateButton = cancan.getSession().module_permissions.every((p) => !p.startsWith('mediabuild.'));

      return (
        <DndProvider backend={HTML5Backend}>
          <div className="scheme-list">
            <div className="pa15">
              <h3 className="ma-0">
                <FormattedMessage id="views.playlists.clientConfigurations" />
                <HelpIcon
                  bodyKey="help.playlists.clientConfigurations.body"
                  headerKey="help.playlists.clientConfigurations.header"
                />
                {showCreateButton && (
                  <button
                    type="button"
                    className="float-right btn btn-primary mb-1 d-inline-block"
                    onClick={onCreateScheme}
                  >
                    <i className="icon-white icon-plus" />&nbsp;<FormattedMessage id="common.create" />
                  </button>
                )}
              </h3>
            </div>
            {this.state.schemes.map((s, i) => {
              return (
                <SchemeView
                  key={i}
                  scheme={s}
                  editing={s.get('id') === this.state.editId}
                  layoutImgSrc={layoutImgs[0]}
                  onToggleEdit={onToggleEdit}
                  onUpdateRepeating={onUpdateRepeating}
                  onSchemeNameChange={onSchemeNameChange}
                  onSchemeDurationChange={onSchemeDurationChange}
                  onSchemeDestroy={onSchemeDestroy}
                  onSchemePlaylistDelete={onSchemePlaylistDelete}
                  onSchemePlaylistAdd={onSchemePlaylistAdd}
                  playlists={playlists}
                  tags={this.state.tags}
                />
              );
            })}
          </div>
        </DndProvider>
      );
    },
  }),
);
