import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actionCreators } from 'js/redux/dashboard';

const mapDispatchToProps = {
  removeBadge: actionCreators.removeDashboardBadge,
};

function Badge({
  title,
  badgeId,
  children,
  removeBadge,
  className = 'alert alert-info',
  ...other
}) {
  const outerClassName = `mr-3 mb-3 px-3 py-2 ${className}`;
  return (
    <div
      className={outerClassName}
      {...other}
    >
      <div className="d-flex align-items-center">
        <h6 className="mr-2 mb-0 auto">
          {title}
        </h6>
        <button
          type="button"
          className="btn btn-sm"
          onClick={() => removeBadge({ badgeId })}
        >
          <i
            className="icon-trash"
          />
        </button>
      </div>
      {children}
    </div>
  );
}

Badge.propTypes = {
  title: PropTypes.string.isRequired,
  badgeId: PropTypes.string.isRequired,
  removeBadge: PropTypes.func.isRequired,
  children: PropTypes.element,
  className: PropTypes.string,
};

export default connect(undefined, mapDispatchToProps)(Badge);
