import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const DragDropComponent = (Component, dragType) => {
  return function DraggableRow(props) {
    const ref = useRef(null);
    const [, drop] = useDrop({
      accept: dragType,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },

      canDrop() {
        return false;
      },

      hover(item, monitor) {
        const dragIndex = item.index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex || !ref.current) {
          return;
        }

        // Don't replace items with themselves
        if (dragIndex === hoverIndex || !ref.current) {
          return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
        // Time to actually perform the action

        props.handleHover(dragIndex, hoverIndex);

        item.index = hoverIndex;
      },
    }, [props.index]);

    const [{ isDragging }, drag] = useDrag({
      type: dragType,
      item: () => {
        return { index: props.index };
      },

      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),

      end() {
        // call ot parent to mark end of dnd session
        props.commitDrag();
      },
    }, [props.index]);

    drag(drop(ref));

    return (
      <Component
        ref={ref}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        isDragging={isDragging}
        draggable
      />
    );
  };
};

export default DragDropComponent;
