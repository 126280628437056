import {
  isString,
  isFunction,
  isUndefined,
} from 'lodash';
import Immutable from 'immutable';
import EventEmitter from 'eventemitter2';
import * as MsgActions from 'js/react/actions/message-actions';
import emitterOn from './on';
import { cacheFactory } from './cache-factory';
import {getCsrfToken} from "src/core/api/apiRequest/helpers";

function createUrl(path, query) {
  const queryStr = query ? `?q=${encodeURIComponent(JSON.stringify(query))}` : '';
  return path + queryStr;
}

async function handleApiError(response) {
  // API error repsonse should contain json object with "detail" field
  // diplay that, or diplay the raw response text

  let body;
  try {
    body = await response.json();
  } catch(e) {
    body = await response.text();
  }

  if (body !== '') {
    if (!isUndefined(body.detail)) {
      MsgActions.error(body.detail);
    }
    MsgActions.error(JSON.stringify(body));
  } else if (response.status === 403) {
    MsgActions.error('Action forbidden (403)');
  } else {
    MsgActions.error(`Error ${response.status}`);
  }

  return body;
}


function appendSlash(path) {
  if (path.substr(path.length - 1, 1) === '/') {
    return path;
  }
  return `${path}/`;
}


const emitter = new EventEmitter({ wildcard: true });
function ajaxStore(urlRoot, options = {}) {
  const cache = cacheFactory(urlRoot, options.lifetime, options.maxSize);
  const pending = {};

  console.log(`Creating AJAX store: ${urlRoot}`);

  function addToCache(i) {
    return cache.set(i.get('id'), i);
  }

  function invalidateCache() {
    return cache.flush();
  }

  function byId(id, vid, force) {
    let item = force ? null : cache.get(id);

    if (item && (!vid || item.get('vid') === vid)) {
      return item;
    }

    let url = GS_API + appendSlash(urlRoot) + id;
    if (vid) {
      url += `?vid=${vid}`;
    }

    if (pending[url]) {
      return pending[url];
    }

    return (pending[url] = window
      .fetch(url)
      .then((response) => response.json())
      .then((data) => {
        item = Immutable.fromJS(data);
        if (!options.noCache) {
          item = cache.set(id, item);
        }

        return item;
      })
      .finally(() => delete pending[url]));
  }

  async function fetch(query) {
    let url;
    let cached;

    if (!query) {
      url = GS_API + urlRoot;
    } else if (isString(query)) {
      url = `${GS_API + urlRoot}?${query}`;
    } else {
      url = createUrl(GS_API + urlRoot, query);
    }

    cached = cache.get(`filterBy:${url}`);
    if (cached) {
      return cached;
    }

    if (pending[url]) {
      return pending[url];
    }

    return (pending[url] = window
      .fetch(url)
      .then((response) => response.json())
      .then((data) => {
        let items = Immutable.fromJS(data);
        if (!options.noCache) {
          if (options.cacheFilterBy) {
            items = cache.set(`filterBy:${url}`, items);
          } else if (Immutable.Map.isMap(items)) {
            // Handle non-standard data here?
          } else if (items && items.forEach) {
            items.forEach(addToCache);
          } else {
            // Handle this? Abnormal response from server
          }
        }

        return items;
      })
      .finally(() => delete pending[url]));
  }

  function update(model) {
    console.log("UPDATE", model);
    let id;

    if (isFunction(model.get)) {
      id = model.get('id');
    } else {
      id = model.id;
    }

    return window
      .fetch(GS_API + appendSlash(urlRoot) + id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': getCsrfToken(),
        },
        body: JSON.stringify(model),
      })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }

        throw handleApiError(response);
      })
      .then((data) => {
        const item = cache.set(id, Immutable.fromJS(data));
        emitter.emit(`change.${id}`, item);
        return item;
      });
  }

  function create(data, urlExt) {
    let url = GS_API + urlRoot;

    if (!isUndefined(urlExt)) {
      url = GS_API + appendSlash(urlRoot) + urlExt;
    }

    return window
      .fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': getCsrfToken(),
        },
        body: JSON.stringify(data),
      })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }

        throw handleApiError(response);
      })
      .then((data) => {
        const item = cache.set(data.id, Immutable.fromJS(data));
        emitter.emit('add', item);
        return item;
      });
  }

  function destroy(id, vid) {
    return window
      .fetch(`${GS_API + appendSlash(urlRoot) + id}${vid ? `?vid=${vid}` : ''}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': getCsrfToken(),
        },
      })
      .then((response) => {
        if (response.ok) {
          return;
        }

        throw handleApiError(response);
      })
      .then(() => {
        cache.evict(id);
        emitter.emit(`destroy.${id}`, id);
        return id;
      });
  }

  return {
    byId,
    update,
    create,
    fetch,
    destroy,
    invalidateCache,
    on: emitterOn(emitter),
  };
}


/* jshint white:true, browser:true, unused:true, undef:true, newcap:true, latedef:true, indent:4, forin:true, camelcase:true, esnext:true */
/* global module, require */

const storeCache = {};

function forUrlRoot(urlRoot, options) {
  urlRoot = urlRoot.trim().replace(/\/+$/, '');
  const k = urlRoot;
  if (!storeCache[k]) {
    storeCache[k] = ajaxStore(urlRoot, options);
  }
  return storeCache[k];
}

export default forUrlRoot;
