/* global parseISODateTime */

import moment from 'moment';

const formatMediaType = (val) => {
  // Create icon for type.
  switch (val) {
    case 'audio':
      return 'icon-volume-up';
    case 'announcement':
      return 'icon-bullhorn';
    default:
      return false;
  }
};


const formatType = (val) => {
  // Create icon for type.
  switch (val) {
    case 'U':
      return 'icon-cog';
    case 'V':
      return 'icon-film';
    case 'announcement':
      return 'icon-bullhorn';
    default:
      return 'icon-picture';
  }
};

const formatTime = (time) => {
  const mins = Math.floor(time / 60);
  let secs = Math.floor(time % 60);
  if (secs < 10) {
    secs = `0${secs}`;
  }
  return `${mins}:${secs}`;
};


const formatUploadDate = (time) => {
  return moment(time).format('YYYY-MM-DD HH:mm')
};

const Formatter = {
  uploaded: formatUploadDate,
  time: formatTime,
  type: formatType,
  mediaType: formatMediaType,
};

export default Formatter;
