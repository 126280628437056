import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Calendar } from 'react-widgets';
import {
  setDate,
  unlock,
  previousInterval,
  nextInterval,
} from 'js/redux/plan/plan-actions';
import { getFromDate, getToDate } from 'js/redux/selectors';
import Dropdown, { DropdownContent, DropdownToggle } from '../components/Dropdown';

export function NavItem({
  children, className = '', url = '#', active, icon, onClick, testId,
}) {
  return (
    <li className={`nav-item${active ? ' active' : ''} ${className}`}>
      <a
        className={`nav-link px-3 ${className}`}
        data-testid={testId}
        onClick={onClick}
        href={url}
      >
        {icon && (
          <i
            className={icon}
          />
        )} {children}
        {active && (
          <span className="sr-only">
            {'(current)'}
          </span>
        )}
      </a>
    </li>
  );
}

const UnlockButton = () => {
  const dispatch = useDispatch();

  return (
    <ul className="navbar-nav ml-auto">
      <NavItem
        testId="unlock-planning"
        className="locked alert-secondary"
        onClick={(e) => {
          e.preventDefault();
          dispatch(unlock());
        }}
      >
        <i className="icon-lock" />{' '}
        <span
          className="d-md-none"
        >
          <FormattedMessage
            id="views.planning.calendarBar.unlock"
          />
        </span>
        <span
          className="d-none d-md-inline"
        >
          <FormattedMessage
            id="views.planning.calendarBar.clickToUnlock"
          />
        </span>
      </NavItem>
    </ul>
  );
};

const DateBar = ({
  date,
  noDateSetup,
  fullBorder,
  className = '',
  testId = 'plan-datebar',
}) => {
  const dispatch = useDispatch();
  const fromDate = useSelector(getFromDate);
  const toDate = useSelector(getToDate);
  const locked = useSelector((state) => state.plan.get('planLocked'));
  const showUnlockButton = locked && moment().isBefore(toDate);

  const handleToday = (e) => {
    e.preventDefault();
    dispatch(setDate());
  };

  const handleDate = (d) => {
    dispatch(setDate(d));
  };

  useEffect(() => () => {
    // On unmount
    if (date && !noDateSetup) {
      dispatch(setDate(date));
    }
  }, []);

  return (
    <nav
      className={`navbar navbar-expand navbar-light bg-light py-0 pl-0${fullBorder ? ' border' : ' border-bottom'} ${className}`}
    >
      <div
        className="collapse navbar-collapse"
        style={{ padding: 0 }}
        data-testid={testId}
      >
        <ul className="navbar-nav">
          <NavItem
            className="d-md-none"
          >
            <FormattedMessage id="views.planning.calendarBar.from" />
            {': '}
            {fromDate.format('YYYY-MM-DD')}
            <br />
            <FormattedMessage id="views.planning.calendarBar.to" />
            {': '}
            {toDate.format('YYYY-MM-DD')}
          </NavItem>
          <NavItem
            className="d-none d-md-flex"
          >
            <FormattedMessage id="common.week" />{' '}
            {fromDate.isoWeek()}
            {', '}
            {fromDate.format('YYYY-MM-DD')}
            {' - '}
            {toDate.format('YYYY-MM-DD')}
          </NavItem>
          <li className="nav-item rw-select-only mr-2 d-none d-md-flex">
            <Dropdown
              key={fromDate.format('YYYY-MM-DD')}
            >
              <DropdownToggle className="border-0 bg-transparent">
                <i className="icon-calendar" />
              </DropdownToggle>
              <DropdownContent className="d-block w300">
                <Calendar
                  onChange={handleDate}
                  value={fromDate.toDate()}
                />
              </DropdownContent>
            </Dropdown>
          </li>
          <NavItem
            testId="date-nav-prev"
            className="align-self-center"
            onClick={(e) => {
              e.preventDefault();
              dispatch(previousInterval());
            }}
          >
            <i className="icon-chevron-left" />
          </NavItem>
          <NavItem
            testId="date-nav-next"
            className="align-self-center"
            onClick={(e) => {
              e.preventDefault();
              dispatch(nextInterval());
            }}
          >
            <i className="icon-chevron-right" />
          </NavItem>
          {!showUnlockButton && (
            <NavItem
              testId="date-nav-today"
              className="align-self-center"
              onClick={handleToday}
            >
              <FormattedMessage id="common.today" />
            </NavItem>
          )}
        </ul>
        {showUnlockButton && (
          <UnlockButton />
        )}
      </div>
    </nav>
  );
};

export default DateBar;
