import Immutable from 'immutable';
import React from 'react';
import moment from 'moment';
import CheckboxWrapper from 'utils/checkbox-wrapper';

import { bulkUpdatePlannings } from 'src/entities/plannedMedia';
import PaginatedList from 'components/PaginatedList';
import { connect } from 'react-redux';
import { PLANNING_LONG_TERM } from 'constants/permissions';
import cancan from 'js/system/cancan';
import PlanRow from './PlanRow';
import { UNORDERED_THRESHOLD } from '../limit-picker';

/*
    Helper for grouping plannings into lists of plannings,
    each planning that does not beloing to a block_group should
    be in its own list, all plannings in the same block_group should
    be in the same list
*/

function makeId(planned) {
  return planned.map((p) => p.get('id')).join(',');
}

function groupPlannings(plannings) {
  let order = 0;
  const res = plannings
    .sortBy((m) => m.get('order'))
    .groupBy((m) => {
      const bg = m.get('block_group');
      order += 1;
      return bg === 0 ? order : `grouped-${bg}`;
    });

  return res.map((items) => Immutable.Map({
    id: makeId(items),
    items,
  })).toList();
}

const fmtDate = (d) => moment(d).format('YYYY-MM-DDTHH:mm:ss');

function flattenPlannings(grouped) {
  return grouped
    .reduce((acc, group) => acc.concat(group.get('items')), Immutable.List())
    .map((item, i) => item
      .set('time_limit_lower', fmtDate(item.get('start')))
      .set('time_limit_upper', fmtDate(item.get('end')))
      .set('order', item.get('order') >= UNORDERED_THRESHOLD ? item.get('order') : i)
      .delete('start')
      .delete('end'));
}

class PlanRows extends CheckboxWrapper {
  constructor(props) {
    super(props);
    this.state = {
      plannings: this.prepPlannings(props.plannings),
    };
  }

  UNSAFE_componentWillReceiveProps(np) {
    if (np.plannings !== this.props.plannings) {
      this.setState({
        plannings: this.prepPlannings(np.plannings),
      });
    }
  }

  prepPlannings(plannings) {
    return groupPlannings(plannings)
      .sortBy((pl) => pl.getIn(['items', 0, 'order']));
  }

  handleHover = (dragIdx, hoverIdx) => {
    /* Callback for handling a dragged planning hovering another planning */
    const items = this.state.plannings;
    const dragMedia = items.get(dragIdx);
    const plannings = items
      .splice(dragIdx, 1)
      .splice(hoverIdx, 0, dragMedia);

    this.setState({ plannings });
  }

  handleDragEnd = () => {
    const plannings = flattenPlannings(this.state.plannings).toJS();
    const ordered = cancan.can('read', PLANNING_LONG_TERM)
      ? plannings.reduce((acc, pl, order) => {
        return [
          ...acc,
          {
            ...pl,
            order,
          },
        ];
      }, [])
      : plannings;
    this.props.bulkUpdatePlannings(ordered);
  }

  // Render
  renderRow = (planned, index) => {
    const items = this.state.plannings;
    const compositeId = planned.get('id');

    return (
      <PlanRow
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...this.props}
        key={compositeId}
        planning={planned.get('items')}
        index={index}
        onHover={this.handleHover}
        onDragEnd={this.handleDragEnd}
        onSelect={this.toggleSelect(compositeId, items)}
        selected={this.props.selected.includes(compositeId)}
      />
    );
  }

  render() {
    return (
      <PaginatedList
        pageSize={10}
      >
        {this.state.plannings.map(this.renderRow).toArray()}
      </PaginatedList>
    );
  }
}

export default connect(null, { bulkUpdatePlannings })(PlanRows);
