import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { DatePicker } from 'react-widgets';
import cancan from 'js/system/cancan';
import { PLANNING_TIMEPLAN } from 'constants/permissions';

/* Datepicker for picking a date range inbetween min->max */
export const MinMaxTimeForm = ({
  min,
  max,
  start,
  end,
  onChange,
  readOnly,
  children,
  dropUp = true,
}) => {
  const isInsideInterval = (!start || start >= min) && (!end || end <= max);
  const [enforceTimeLimits, toggleLimits] = useState(isInsideInterval);

  const handleChange = (idx) => (val) => {
    const vals = [start || min, end || max];
    vals[idx] = val;
    onChange(vals);
  };

  const renderPicker = (time, update, limits = {}) => {
    // Ensure start is less than current end and vice versa.
    const localMin = !min || (limits.min && limits.min) > min ? limits.min : min;
    const localMax = !max || (limits.max && limits.max) < max ? limits.max : max;

    return (
      <DatePicker
        dropUp={dropUp}
        style={{ width: 230 }}
        valueFormat="YYYY-MM-DD HH:mm"
        readOnly={readOnly}
        disabled={readOnly}
        min={enforceTimeLimits && localMin ? localMin : limits.min}
        max={enforceTimeLimits && localMax ? localMax : limits.max}
        value={time}
        onChange={readOnly ? undefined : update}
        includeTime
        culture={'en-GB'}
        display={'inline-block'}
      />
    );
  };

  /* eslint-disable jsx-a11y/label-has-associated-control */
  return (
    <form>
      {isInsideInterval && (
        <div className="form-check mb-2">
          <input
            id="toggle-enforced-time-limits"
            name="Toggle time limits"
            type="checkbox"
            className="form-check-input"
            checked={enforceTimeLimits}
            onChange={() => toggleLimits(!enforceTimeLimits)}
            data-testid="toggle-enforce-timelimits"
          />
          <label
            className="form-check-label"
            htmlFor="toggle-enforced-time-limits"
          >
            <FormattedMessage id="views.planning.playlists.limitToCurrentWeek" />
          </label>
        </div>
      )}
      <label className="d-block">
        <FormattedMessage id="common.start" />
        {renderPicker(
          start,
          handleChange(0),
          { max: end > max ? max : end },
        )}
      </label>

      <label className="d-block">
        <FormattedMessage id="common.end" />
        {renderPicker(
          end,
          handleChange(1),
          { min: start < min ? min : start },
        )}
      </label>
      {children}
    </form>
  );
  /* eslint-enable jsx-a11y/label-has-associated-control */
};


export const MinMaxTimePicker = ({
  min,
  max,
  start,
  end,
  readOnly,
  onChange,
}) => {
  const canReset = !!min && !!max && cancan.can('read', PLANNING_TIMEPLAN);
  const [startNew, setStartNew] = useState(start?.toDate());
  const [endNew, setEndNew] = useState(end?.toDate());

  let isReset = false;
  if (min && max && startNew && endNew) {
    isReset =
      Math.abs(min.valueOf() - startNew.valueOf()) < 60000
      && Math.abs(max.valueOf() - endNew.valueOf()) < 60000;
  }

  const handleChange = ([s, e]) => {
    setStartNew(s);
    setEndNew(e);
  };

  const handleReset = () => {
    setStartNew(min.toDate());
    setEndNew(max.toDate());
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (startNew || endNew) {
      onChange(
        startNew || start?.toDate(),
        endNew || end?.toDate(),
      );
    }
  };

  useEffect(() => {
    setStartNew(start?.toDate());
    setEndNew(end?.toDate());
  }, [start?.valueOf(), end?.valueOf()]);

  return (
    <MinMaxTimeForm
      start={startNew}
      end={endNew}
      min={min?.toDate()}
      max={max?.toDate()}
      onChange={handleChange}
    >
      {!readOnly && (
        <div className="float-right">
          {canReset && (
            <button
              type="button"
              className={`btn btn-light border${isReset ? ' disabled' : ''}`}
              disabled={isReset}
              onClick={isReset ? undefined : handleReset}
            >
              <FormattedMessage id="common.reset" />
            </button>
          )}
          <button
            data-close-popover
            type="submit"
            className="btn btn-primary ml-2"
            onClick={handleSave}
          >
            <FormattedMessage id="common.save" />
          </button>
        </div>
      )}
    </MinMaxTimeForm>
  );
};
