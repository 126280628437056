import {
  DATA_SET_COLLECTION,
  DATA_SET_ENTITY,
  DATA_REMOVE_ENTITY,
} from './constants';

export const setCollection = ({ payload, meta }) => {
  return {
    type: `[${meta.collection}] ${DATA_SET_COLLECTION}`,
    payload,
    meta,
  };
};

export const setEntity = ({ payload, meta }) => {
  return {
    type: `[${meta.collection}] ${DATA_SET_ENTITY}`,
    payload,
    meta,
  };
};

export const removeEntity = ({ meta }) => {
  return {
    type: `[${meta.collection}] ${DATA_REMOVE_ENTITY}`,
    payload: meta.entityId,
    meta,
  };
};
