import React, { useContext } from 'react';
import debug from 'debug';
import { DataTableContext } from './DataTableContext';

const log = debug('src:components:DataTable:Body');

export const DataTableBody = ({ children, tbodyProps }) => {
  log('render');
  const { processedItems } = useContext(DataTableContext);
  log('processedItems', processedItems);
  return <tbody {...tbodyProps}>{processedItems.map(children)}</tbody>;
};
