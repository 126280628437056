import React, { useEffect, useRef } from 'react';
import {
  arrayOf, bool, func, string,
} from 'prop-types';

const propTypes = {
  value: string.isRequired,
  highligths: arrayOf(string).isRequired,
  onChange: func.isRequired,
  disabled: bool,
};

const HighlightedTextArea = ({
  value,
  onChange,
  highligths,
  disabled,
}) => {
  const textAreaRef = useRef();
  const hlTextAreaRef = useRef();
  // Split on any keywords wrapped in "{{}}"
  const keywordChunks = (value || '').split(/{\{(\w+)}\}/g);

  const handleScroll = () => {
    if (textAreaRef.current && hlTextAreaRef.current) {
      hlTextAreaRef.current.scrollTop = textAreaRef.current.scrollTop;
    }
  };

  const handleInput = (e) => {
    onChange(e.target.value);
  };

  useEffect(() => {
    handleScroll();
  });

  return (
    <div className="hltextarea-container">
      <div className="hltextarea-backdrop" ref={hlTextAreaRef}>
        <div className="hltextarea-highlights">
          {keywordChunks.map((chunk, i) => {
            const keyword = `{{${chunk}}}`;
            return highligths.includes(keyword)
              ? (<mark key={chunk + i}>{keyword}</mark>)
              : (<span key={`chunk${i}`}>{chunk}</span>);
          })}
        </div>
      </div>
      <textarea
        ref={textAreaRef}
        className="hltextarea-input"
        value={value}
        onChange={handleInput}
        disabled={disabled}
        onScroll={handleScroll}
      />
    </div>
  );
};

HighlightedTextArea.propTypes = propTypes;

export default HighlightedTextArea;
