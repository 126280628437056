/* jshint white:true, browser:true, unused:true, undef:true, newcap:true, latedef:true, indent:4, forin:true, camelcase:true, quotmark:true */
/* global define */


let instance;
const t = require('nls/lang').root;

const locale = localStorage.getItem('gsLang') ||
             (typeof navigator === 'undefined' ? '' :
               (navigator.language ||
                                      navigator.userLanguage ||
                                      '').toLowerCase());

if (locale === 'sv') {
  // Only use enligsh for now.
  // t = _.extend(t, require('nls/_sv/lang'));
}

function Translation() {
  this.language = t;
}

// window.t = t;

// module.exports = function getSingleton() {
//     return (instance = (instance || new Translation()));
// };

export default t;
