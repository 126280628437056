import React from 'react';
import { ChromePicker } from 'react-color';
import Dropdown, { DropdownToggle, DropdownContent } from 'components/Dropdown';

const createMarkup = (text) => {
  return { __html: text };
};

const DropdownElement = ({
  value,
  onChange,
  disabled,
}) => {
  return (
    <Dropdown
      disabled={disabled}
      className="btn-group mr-1"
    >
      <DropdownToggle className="form-control">
        <div
          className="d-inline-block rounded border align-middle"
          style={
            {
              backgroundColor: `${value}`,
              width: 20,
              height: 20,
            }
          }
        />
      </DropdownToggle>
      <DropdownContent>
        <ChromePicker
          disableAlpha
          color={value}
          onChangeComplete={(event) => onChange(event.hex)}
        />
      </DropdownContent>
    </Dropdown>
  );
};

const ColorPicker = ({
  id,
  title,
  description,
  value,
  onChange,
  disabled,
}) => {
  return (
    <div
      id={id}
      className="form-group"
      data-testid="customWidgetColorPicker"
    >
      <label htmlFor={id}>
        {title}
      </label>
      <div className="input-group">
        <DropdownElement
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
        <input
          onChange={(event) => onChange(event.target.value)}
          value={value}
          type="text"
          className="form-control w-50"
          disabled={disabled}
        />

      </div>
      <small className="form-text text-muted">
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={createMarkup(description)}
        />
      </small>
    </div>
  );
};

export default ColorPicker;
