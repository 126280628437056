import React, { memo } from 'react';
import * as Layout from './Layout';
import { SchemePicker } from './SchemePicker';
import { BuildPicker } from './BuildPicker';
import { PlaybackDateTimePicker } from './PlaybackDateTimePicker';

export const MediaPlayerControls = memo(() => {
  return (
    <>
      <Layout.ControlSection>
        <div className="row">
          <div className="col">
            <BuildPicker />
          </div>
          <div className="col">
            <PlaybackDateTimePicker />
          </div>
        </div>
      </Layout.ControlSection>
      <Layout.ControlSection
        title=""
      >
        <SchemePicker />
      </Layout.ControlSection>
    </>
  );
});
