import React, { useMemo, useRef } from 'react';
import {
  func, shape, string, arrayOf,
} from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Multiselect } from 'react-widgets';
import { keyBy, partition } from 'lodash';

import { useFieldCondition } from '../hooks/useFieldCondition';
import { usePlacesOfType } from '../hooks/usePlacesOfType';
import { ConditionSection } from './ConditionSection';
import { EQToggle, useOperator } from './EQToggle';

function getPlaceId(place) {
  return place.id;
}

const MultiPlaceSelect = ({
  placeType, value: field, fieldType, onChange, placeholder, disabled,
}) => {
  const data = usePlacesOfType(placeType);
  const dataById = useMemo(() => {
    return keyBy(data, 'id');
  }, [data]);

  const [gsPlaces, unknownPlaces] = partition(
    field?.value || [],
    (pId) => !!dataById[pId],
  );

  const values = gsPlaces.map((aId) => dataById[aId]);
  const unknownValues = unknownPlaces.map((name) => ({
    id: name,
    name,
  }));

  const initialUnknownValues = useRef(unknownValues);

  const handleChange = (places) => {
    onChange([
      ...places.map(getPlaceId),
      ...unknownPlaces,
    ]);
  };

  const handleUnknownChange = (places) => {
    !disabled && onChange([
      ...gsPlaces,
      ...places.map(getPlaceId),
    ]);
  };

  return (
    <>
      <Multiselect
        dataKey="id"
        textField="name"
        className="d-col align-self-center"
        placeholder={placeholder}
        data={data}
        value={values}
        onChange={handleChange}
        disabled={disabled}
        filter="contains"
      />
      {!!initialUnknownValues.current.length && (
        <div className="mt-3">
          <h6><FormattedMessage id={`views.planning.playbackConditions.external${fieldType}s`} /></h6>
          <Multiselect
            dataKey="id"
            textField="name"
            className="d-col align-self-center"
            placeholder={placeholder}
            data={initialUnknownValues.current}
            value={unknownValues}
            onChange={handleUnknownChange}
            filter="contains"
          />
        </div>
      )}
    </>
  );
};

MultiPlaceSelect.propTypes = {
  placeType: string,
  value: shape({
    value: arrayOf(string),
  }),
  onChange: func,
  placeholder: string,
};

const placeConditionFactory = (fieldType, placeType, label) => ({ disabled }) => {
  const { formatMessage } = useIntl();
  const [field, onChange] = useFieldCondition(fieldType, 'and');
  const [operator, setOperator] = useOperator(field, onChange);

  return (
    <ConditionSection
      label={formatMessage({ id: label })}
      options={field?.value?.length
        ? (
          <button
            type="button"
            className="btn badge badge-pill badge-secondary"
            onClick={() => onChange([])}
            title={formatMessage({ id: 'views.report.clearFilters' })}
          >
            {formatMessage({ id: 'common.count' })}: {field.value.length} {'\u00D7'}
          </button>
        )
        : null
      }
    >
      <MultiPlaceSelect
        value={field}
        fieldType={fieldType}
        placeType={placeType}
        onChange={onChange}
        disabled={disabled}
      />
      <EQToggle
        value={operator}
        onChange={setOperator}
      />
    </ConditionSection>
  );
};

export const AreaCondition = placeConditionFactory('area', 'area', 'common.areas');
export const StopCondition = placeConditionFactory('stop', 'stop', 'common.stops');
export const LineCondition = placeConditionFactory('line', 'line', 'common.lines');
export const VehicleCondition = placeConditionFactory('gsID', 'bus', 'common.clients');

export const DestinationCondition = placeConditionFactory('destination', 'stop', 'views.planning.playbackConditions.destinations');
