/*
* The purpose of this file is to send the appropriate state slice to the
* selectors from each module. This is a pattern suggested by Dan Abramov in his
* "idiomatic redux" series on egghead.io. Selectors used in components
* should be imported from here.
*/

import * as fileUpload from 'js/redux/api/fileUpload';
import * as fromData from 'js/redux/api/data';
import * as fromI18n from 'js/redux/i18n';
import * as plan from 'js/redux/plan/plan-reducer';

export const getPlaces = (state) => {
  return fromData.getPlaces(state.data);
};

export const getPlaceById = (state, id) => {
  return fromData.getPlaceById(state.data, id);
};

export const getPlacesInObject = (state) => {
  return fromData.getPlacesInObject(state.data);
};

export const getPlaylists = (state) => {
  return fromData.getPlaylists(state.data);
};

export const getPlaylistsInObject = (state) => {
  return fromData.getPlaylistsInObject(state.data);
};

export const getPlaylistById = (state, id) => {
  return fromData.getPlaylistById(state.data, id);
};

export const getTriggerConditions = (state, playlistId, condition) => {
  return fromData.getTriggerConditions(state.data, playlistId, condition);
};

export const getGroups = (state) => {
  return fromData.getGroups(state.data);
};

export const getGroupsInObject = (state) => {
  return fromData.getGroupsInObject(state.data);
};

export const getGroupsSortedByName = (state) => {
  return fromData.getGroupsSortedByName(state.data);
};

export const getGroupsSortedByNameExceptAdmin = (state) => {
  return fromData.getGroupsSortedByNameExceptAdmin(state.data);
};

export const getGroupIdsSortedByName = (state) => {
  return fromData.getGroupIdsSortedByName(state.data);
};


export const getUsers = (state) => {
  return fromData.getUsers(state.data);
};

export const getUsersSortedByName = (state) => {
  return fromData.getUsersSortedByName(state.data);
};

// TODO these should live in the plan module, not in the data module
export const getFromDate = (state) => {
  return fromData.getFromDate(state.plan);
};

export const getToDate = (state) => {
  return fromData.getToDate(state.plan);
};

export const getLanguage = (state) => {
  return fromI18n.selectors.getLanguage(state.i18n);
};

export const getDefaultLanguage = (state) => {
  return fromI18n.selectors.getDefaultLanguage(state.i18n);
};

export const getAvailableLanguages = (state) => {
  return fromI18n.selectors.getAvailableLanguages(state.i18n);
};

export const getLanguageNames = (state) => {
  return fromI18n.selectors.getLanguageNames(state.i18n);
};

export const getPasteData = (state) => {
  return plan.getPasteData(state.plan);
};

export const getLimitFilter = (state) => {
  return plan.getLimitFilter(state.plan);
};

export const getFileUploadProgress = (state) => {
  return fileUpload.getProgress(state.fileUpload);
};

export const getPlayerLayouts = (state) => {
  return fromData.getPlayerLayouts(state.data);
};

export const getPlayerLayoutsInObject = (state) => {
  return fromData.getPlayerLayoutsInObject(state.data);
};

export const getPlayerLayoutById = (state, id) => {
  return fromData.getPlayerLayoutById(state.data, id);
};

export const getLayoutTemplates = (state) => {
  return fromData.getLayoutTemplates(state.data);
};

export const getLayoutTemplateById = (state, id) => {
  return fromData.getLayoutTemplateById(state.data, id);
};

export const getSchemes = (state) => {
  return fromData.getSchemes(state.data);
};
