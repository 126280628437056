import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getPlacesInObject } from 'src/entities/places';

export const usePlacesOfType = (placeType) => {
  const places = useSelector(getPlacesInObject);

  return useMemo(() => {
    return Object.values(places || {}).filter(({ type }) => type.toLowerCase() === placeType);
  }, [places, placeType]);
};
