import { v4 as getUuid } from 'uuid';
import * as actions from '../actions';
import {
  get,
  post,
  put,
  del,
} from '../helpers/actionCreatorFactories';

/*
  SCHEME
*/

const SCHEME_URL = '/schemesv2';

export const fetchSchemes = () => {
  const url = SCHEME_URL;
  const types = [
    actions.FETCH_MEDIA_OBJECTS,
    actions.FETCH_MEDIA_OBJECTS_SUCCESS,
    actions.FETCH_MEDIA_OBJECTS_FAIL,
  ];
  const meta = {
    verb: 'get',
    collection: 'schemes',
    uuid: getUuid(),
    url,
    reset: true,
  };

  return get({ url, types, meta });
};


export const createScheme = (bodyInput) => {
  const url = SCHEME_URL;
  const types = [
    actions.CREATE_MEDIA_OBJECT,
    actions.CREATE_MEDIA_OBJECT_SUCCESS,
    actions.CREATE_MEDIA_OBJECT_FAIL,
  ];
  const meta = {
    verb: 'post',
    bodyInput,
    collection: 'schemes',
    uuid: getUuid(),
    url,
  };

  return post({
    url,
    types,
    meta,
    bodyInput,
  });
};

export const updateScheme = (bodyInput) => {
  const url = SCHEME_URL;
  const types = [
    actions.UPDATE_MEDIA_OBJECT,
    actions.UPDATE_MEDIA_OBJECT_SUCCESS,
    actions.UPDATE_MEDIA_OBJECT_FAIL,
  ];
  const meta = {
    verb: 'put',
    bodyInput,
    id: bodyInput.id,
    collection: 'schemes',
    uuid: getUuid(),
    url,
  };

  return put({
    url,
    types,
    meta,
    bodyInput,
  });
};

export const deleteScheme = (id) => {
  const url = SCHEME_URL;
  const types = [
    actions.DELETE_MEDIA_OBJECT,
    actions.DELETE_MEDIA_OBJECT_SUCCESS,
    actions.DELETE_MEDIA_OBJECT_FAIL,
  ];
  const meta = {
    verb: 'del',
    id,
    collection: 'schemes',
    uuid: getUuid(),
    url,
  };

  return del({
    url,
    types,
    meta,
    id,
  });
};
