
// Extend server response payload in extendPayloadMiddleware
export const extendPayloadActionEnhancer = (action, extendPayload = {}) => {
  return {
    ...action,
    meta: {
      ...(action.meta || {}),
      extendPayload,
    },
  };
};
