import { ANNOUNCEMENT_SYSTEM } from 'constants/permissions';
import cancan from 'js/system/cancan';
import { useMemo } from 'react';
import { TEXT } from './constants';

// Audio sequence should always start and end with text inputs.
// Adjecent text inputs should be merged.
// Custom tokens should be separated by text inputs.
export const useAudioSequence = (audioConfig = []) => {
  return useMemo(() => {
    const canTTS = cancan.can('tts', ANNOUNCEMENT_SYSTEM);
    const list = audioConfig?.length ? [...audioConfig] : [{ type: TEXT, value: '' }];

    if (!canTTS) {
      return list.filter(({ type }) => type !== TEXT);
    }

    // Ensure that last item is a text input
    list.push({ type: TEXT, value: '' });

    return list.reduce((acc, item) => {
      const prevItem = acc[acc.length - 1];

      if (!prevItem && item.type !== TEXT) {
        // Ensure first item is text input
        acc.push({ type: TEXT, value: '' });
        acc.push(item);
      } else if (prevItem && prevItem.type === TEXT && item.type === TEXT) {
        // Merge adjacent inputs.
        acc[acc.length - 1] = {
          type: TEXT,
          value: `${prevItem.value.trim()} ${item.value.trim()}`,
        };
      } else if (prevItem && prevItem.type !== TEXT && item.type !== TEXT) {
        // Ensure inputs between other types
        acc.push({ type: TEXT, value: '' });
        acc.push(item);
      } else {
        acc.push(item);
      }

      return acc;
    }, []);
  }, [audioConfig]);
};
