import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';

const propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
  children: PropTypes.element,
  submitOnlyOnChange: PropTypes.bool,
};

const defaultProps = {
  submitOnlyOnChange: false,
};

class MaxCount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: props.value,
    };
  }

  UNSAFE_componentWillReceiveProps(np) {
    if (np.value !== this.props.value) {
      this.setState({
        count: np.value,
      });
    }
  }

    handleCount = (e) => {
      this.setState({
        count: e.target.value,
      });
    }

    handleSubmit = () => {
      const {
        onChange,
        submitOnlyOnChange,
        value,
      } = this.props;
      const {
        count,
      } = this.state;

      if (submitOnlyOnChange && value === count) {
        return;
      }

      return onChange(count);
    }

    render() {
      const other = omit(this.props, ['value', 'onChange', 'submitOnlyOnChange', 'label', 'children']);
      return (
        <label className="d-block">
          {this.props.children}
          <input
            className="form-control mb-0"
            type="number"
            {...other}
            value={this.state.count}
            onChange={this.handleCount}
            onBlur={this.handleSubmit}
          />
        </label>
      );
    }
}

MaxCount.propTypes = propTypes;
export default MaxCount;
