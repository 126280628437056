import _sortBy from 'lodash/sortBy';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ShiftSelectWrapper } from 'utils/ShiftSelect';
import { ClientRow } from './ClientRow';

const propTypes = {
  statuses: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
};

const defaultProps = {};

function SortHandler({
  sorted, asc, children, handleHide, handleSort,
}) {
  const chevron = sorted
    ? (asc ? ' icon-chevron-up' : ' icon-chevron-down')
    : ' icon-chevron-right';

  return (
    <th className="nowrap pointer" onClick={handleSort}>
      <i className={chevron} /> {children}{' '}
      <button
        type="button"
        className="close float-none align-top"
        aria-label="Close"
        onClick={handleHide}
      >
        <span aria-hidden="true">
          {'\u00D7'}
        </span>
      </button>
    </th>
  );
}

export class ClientsList extends React.PureComponent {
  constructor(props) {
    super(props);
    const lowestOrder = props.columns.reduce((minOrder, col) => {
      return col.order < minOrder ? col.order : minOrder;
    }, Number.MAX_SAFE_INTEGER);
    this.state = {
      hidden: [],
      sortBy: (props.columns.find((col) => col.order === lowestOrder) || {}).identifier,
      asc: false,
    };
  }

  handleHideCol = (id, e) => {
    e.stopPropagation();

    this.setState({
      hidden: [...this.state.hidden, id],
    });
  };

  handleSort = (sortBy) => {
    this.setState({
      sortBy,
      asc: sortBy === this.state.sortBy ? !this.state.asc : true,
    });
  };

  applySorting = (statuses, cols) => {
    const identifierToSortBy = this.state.sortBy;
    const sortByCol = cols.find((col) => col.identifier === identifierToSortBy);
    const sorted = _sortBy(statuses, (status) => {
      const value = status.fields[identifierToSortBy];

      if (sortByCol && (sortByCol.type || '').toLowerCase() === 'number') {
        // trimming because backend sends strings with linebreak
        const number = value && Number(value.trim());
        // put last if NaN
        return number || Number.MAX_SAFE_INTEGER;
      }

      return value;
    });

    return this.state.asc ? sorted : sorted.reverse();
  };

  renderHead(columns) {
    return (
      columns && (
        <tr>
          <th />
          {columns.map((col) => {
            const id = `views.admin.health.${col.identifier}`;

            return (
              <SortHandler
                key={col.id}
                handleHide={this.handleHideCol.bind(this, col.identifier)}
                handleSort={this.handleSort.bind(this, col.identifier)}
                sorted={this.state.sortBy === col.identifier}
                asc={this.state.asc}
              >
                <FormattedMessage
                  defaultMessage={col.name}
                  id={id}
                />
              </SortHandler>
            );
          })}
        </tr>
      )
    );
  }

  renderRows(columns, statuses) {
    return (
      statuses &&
      statuses.map((status) => (
        <ClientRow
          key={status.id}
          status={status}
          columns={columns}
          warnings={status.warnings}
        />
      ))
    );
  }

  render() {
    const {
      statuses, columns, selected, onSelect,
    } = this.props;
    const sortedStatuses = this.applySorting(statuses, columns);
    const { hidden } = this.state;
    const cols = columns.filter((c) => hidden.indexOf(c.identifier) === -1);

    return (
      <ShiftSelectWrapper
        items={sortedStatuses}
        selected={selected}
        onChange={onSelect}
      >
        <div className="table-responsive">
          <table className="table table-hover table-bordered" data-testid="clients_table">
            <thead>{this.renderHead([...cols])}</thead>
            <tbody>{this.renderRows(cols, sortedStatuses)}</tbody>
          </table>
        </div>
      </ShiftSelectWrapper>
    );
  }
}

ClientsList.propTypes = propTypes;
ClientsList.defaultProps = defaultProps;
