import React from 'react';
import createClass from 'create-react-class';
import { FormattedMessage } from 'react-intl';
import Store from 'js/stores/ajax-store';
import storeListener from 'js/stores/store-listener';
import moment from 'moment';
import { STATUS_COMPLETE, STATUS_ERROR, STATUS_EXECUTING } from "constants/buildStatus";

const MediaBuildStore = Store('/build');

function StatusLabel({ status }) {
  let classStr = 'badge badge-secondary d-block w-100 ';
  let text = <FormattedMessage id="views.admin.health.planned" />;

  switch (status) {
    case STATUS_COMPLETE:
      text = <FormattedMessage id="common.success" />;
      classStr += 'badge-success';
      break;
    case STATUS_ERROR:
      text = <FormattedMessage id="common.errors" />;
      classStr += 'badge-danger';
      break;
    case STATUS_EXECUTING:
      text = <FormattedMessage id="views.admin.health.executing" />;
      break;
  }

  return (
    <span
      className={classStr}
    >
      {text}
    </span>
  );
}

class BuildList extends React.Component {
  static defaultProps = {
    data: [],
  }

  renderRows() {
    const { data = [] } = this.props;

    return data.map((d) => {
      const scheduleTime = moment(d.build_schedule).format('YYYY-MM-DD HH:mm');

      return (
        <tr key={d.id}>
          <td>
            {scheduleTime}
          </td>
          <td>
            <StatusLabel status={d.build_status} />
          </td>
          <td>
            {d.id}
          </td>
        </tr>
      );
    });
  }

  render() {
    return (
      <table className="table table-sm mb-0" style={{ fontSize: '0.8em' }}>
        <thead className="font-weight-bold">
          <tr>
            <th>
              <FormattedMessage id="views.admin.health.buildDate" />
            </th>
            <th>
              <FormattedMessage id="common.status" />
            </th>
            <th>
              <FormattedMessage id="views.admin.health.build" />
            </th>
          </tr>
        </thead>
        <tbody data-testid="latest-media-builds-table-body">
          {this.renderRows()}
        </tbody>
      </table>
    );
  }
}

const MediaBuildView = createClass({
  mixins: [storeListener],

  getInitialState() {
    return {
      data: [],
    };
  },

  UNSAFE_componentWillMount() {
    this.loadData();

    // update if data is changed
    this.listenTo([MediaBuildStore], 'add change.* destroy.*', this.loadData);
  },

  // fetch media build from api
  loadData() {
    const that = this;
    MediaBuildStore.fetch().then((data) => {
      that.setState({ data: data.toJS() });
    });
  },

  // render a list of sidebar messages, data.data should be a list of messages
  render() {
    const {
      upcoming,
      className = 'mt-2 card pa10 bshadow',
      children = (
        <h5>
          <FormattedMessage id="views.admin.health.latestMediaBuilds" />
        </h5>
      ),
    } = this.props;
    const { data = [] } = this.state;
    let completed = data.filter((b) => (
      upcoming
        ? b.build_status === -1
        : b.build_status !== -1
    ));
    completed = completed.reverse().slice(0, 5);

    return (
      <div className={className}>
        {children}
        <BuildList
          onClick={this.handleBuildDataClick}
          onChecked={this.toggleSelected}
          data={completed}
          selected={this.state.selected}
        />
      </div>
    );
  },
});

export default MediaBuildView;
