export async function put({ body, csrfToken, url }) {
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrfToken,
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    throw (response);
  }

  return response.json();
}
