import { MEDIA_TYPE_CUSTOM_WIDGET, MEDIA_TYPE_WIDGET_HTML } from 'constants/mediaTypes';

const mediaUrl = (media) => {
  let type = 'media';

  if (media.media_type === 'announcement') {
    type = 'announcement';
  } else if (
    media.media_type?.includes(MEDIA_TYPE_CUSTOM_WIDGET)
    || (media.media_type === MEDIA_TYPE_WIDGET_HTML && media.config)
  ) {
    type = 'widget_custom';
  }

  return `#/plan/${type}/${media.id}`;
};

export default mediaUrl;
