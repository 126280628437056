/*
* This function makes lookups in the data state and returns the results or a
* sensible default (null for single resources, [] or {} for collections,
* depending on the format requests).
*/

// use same empty object and array to avoid rerenders due to referential inequality
const emptyObject = {};
const emptyArray = [];

export default function readLocalData({
  collectionName,
  id,
  entities,
  format = 'array',
}) {
  const collection = entities[collectionName];
  if (!collection) {
    if (id !== undefined) return null;
    if (format === 'object') return emptyObject;
    return emptyArray;
  }

  if (id) {
    const entity = collection[Number(id)];
    if (!entity) {
      return null;
    }

    return entity;
  }

  if (format === 'object') {
    return collection;
  }

  const collectionInArray = Object.values(collection);

  return collectionInArray;
}
