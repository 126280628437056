import { UPDATE_DASHBOARD_WIDGETS } from '../actionTypes';
import { PRIMARY_DASHBOARD_ID } from '../constants';

export default function updateDashboardWidgets(
  { widgets, dashboardId = PRIMARY_DASHBOARD_ID } = {},
) {
  return {
    payload: { dashboardId, widgets },
    type: UPDATE_DASHBOARD_WIDGETS,
  };
}
