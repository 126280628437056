import React from 'react';

function StandardButton({
  children,
  onClick,
  icon = '',
  className = 'btn btn-light border pointer',
  active = false,
  disabled = false,
  pending = false,
  Component = 'button',
  collapse = false, // "sm" || "md" || "lg"...
  ...other
}) {
  return (
    <Component
      type={Component === 'button' ? 'button' : ''}
      className={`${className}${active ? ' active' : ''}${disabled ? ' disabled' : ''}`}
      onClick={disabled ? undefined : onClick}
      disabled={pending || disabled}
      {...other}
    >
      {(pending || icon)
        && (
          <i className={
            `${pending
              ? 'k-spinner mr-2 w-1r h-1r'
              : icon} mr-1`
          }
          />
        )
      }
      {collapse
        ? (
          <span className={`d-none d-${collapse}-inline`}>
            {children}
          </span>
        )
        : children
      }
    </Component>
  );
}

export default StandardButton;
