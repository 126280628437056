import { filter } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { fetchMediaObjects, getPreppedMediaObjects } from 'src/entities/media';

import Spinner from '../../components/Spinner';

function MediaRow({ media }) {
  return (
    <tr>
      <td style={{ width: 50 }}>
        <img
          src={window.ROOT_PATH + media.thumb}
          style={{ maxWidth: '100%' }}
        />
      </td>
      <td>
        <a href={`#dashboard/media/edit/${media.id}`}>
          {media.name}
        </a>
      </td>
      <td>
        {moment(media.upload_date).format('YYYY-MM-DD HH:mm')}
      </td>
    </tr>
  );
}

MediaRow.propTypes = {
  media: PropTypes.object,
};

class LatestMedia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      media: this.getLatestMedia(props.mediaObjects, props.settings.count),
    };
  }

  // componentDidMount() {
  //   this.props.fetchMediaObjects();
  // }

  UNSAFE_componentWillReceiveProps(np) {
    this.setState({
      media: this.getLatestMedia(np.mediaObjects, np.settings.count),
    });
  }

  getLatestMedia(media = [], count = 5) {
    return media.sort(
      (a, b) => a.upload_date.getTime() - b.upload_date.getTime(),
    ).reverse().slice(0, count);
  }

  renderMedia() {
    return this.state.media.map((m) => (
      <MediaRow
        key={m.id}
        media={m}
      />
    ));
  }

  render() {
    if (this.props.loadingMedia) {
      return (
        <Spinner
          style={{ minWidth: 300, height: 200 }}
        />
      );
    }
    return (
      <div style={{ fontSize: '0.9em' }}>
        <table className="table table-sm">
          <thead>
            <tr>
              <th colSpan="2">
                <FormattedMessage id="common.name" />
              </th>
              <th>
                <FormattedMessage id="common.uploaded" />
              </th>
            </tr>
          </thead>
          <tbody>
            {this.renderMedia()}
          </tbody>
        </table>
      </div>
    );
  }
}

LatestMedia.propTypes = {
  fetchMediaObjects: PropTypes.func.isRequired,
  mediaObjects: PropTypes.array,
  settings: PropTypes.object,
  loadingMedia: PropTypes.bool,
};

// Connect to redux store

const getMedia = createSelector(
  getPreppedMediaObjects,
  (media) => media && filter(media, (m) => m.state !== 'trashed'),
);

function mapStateToProps(state) {
  return {
    mediaObjects: getMedia(state),
  };
}

const mapDispatchToProps = {
  fetchMediaObjects,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(LatestMedia);
