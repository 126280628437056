/*
* The action creator factories wrap the methods in async.js and adapt them to
* work with the apiClientMiddleware (thunk).
*
* As a convenience there are also factories for standard resource CRUD that are
* abstracted one more level.
*
* TODO: Add checks for required inputs and throw error if missing
*/

import { v4 as getUuid } from 'uuid';
import {
  fetchJson,
  create,
  update,
  destroy,
} from 'js/redux/async';

export function get({
  url, query, types, meta,
}) {
  return (dispatch) => dispatch({
    types,
    meta,
    promise: () => fetchJson(url + (query || '')).then((res) => res),
  });
}

export function post({
  url, types, meta, bodyInput,
}) {
  return (dispatch) => dispatch({
    types,
    meta,
    promise: () => create(url, bodyInput).then((res) => res),
  });
}

export function put({
  url, types, meta, bodyInput,
}) {
  return (dispatch) => dispatch({
    types,
    meta,
    promise: () => update(url, bodyInput).then((res) => res),
  });
}

export function del({
  url, types, meta, id,
}) {
  return (dispatch) => dispatch({
    types,
    meta,
    promise: () => destroy(url, id).then((res) => res),
  });
}

export function fetchCollection({
  actionTypes,
  collection,
  url,
}) {
  const meta = {
    verb: 'get',
    collection,
    uuid: getUuid(),
    url,
    reset: true,
  };

  return get({ url, types: actionTypes, meta });
}

export function createResource({
  actionTypes,
  bodyInput,
  collection,
  url,
}) {
  const meta = {
    verb: 'post',
    bodyInput,
    collection,
    uuid: getUuid(),
    url,
  };

  return post({
    url, types: actionTypes, meta, bodyInput,
  });
}

export function updateResource({
  actionTypes,
  bodyInput,
  collection,
  url,
}) {
  const meta = {
    verb: 'put',
    bodyInput,
    id: bodyInput.id,
    collection,
    uuid: getUuid(),
    url,
  };

  return put({
    url, types: actionTypes, meta, bodyInput,
  });
}

export function deleteResource({
  actionTypes,
  collection,
  id,
  url,
}) {
  const meta = {
    verb: 'del',
    id,
    collection,
    uuid: getUuid(),
    url,
  };

  return del({
    url, types: actionTypes, meta, id,
  });
}
