import { REMOVE_DASHBOARD_WIDGET } from '../actionTypes';
import { PRIMARY_DASHBOARD_ID } from '../constants';

export default function removeDashboardWidget(
  { widgetId, dashboardId = PRIMARY_DASHBOARD_ID } = {},
) {
  return {
    payload: { dashboardId, widgetId },
    type: REMOVE_DASHBOARD_WIDGET,
  };
}
