import React from "react";
import PropTypes from "prop-types";
import { createSelector } from "reselect";
import OutsideClick from "../../_utils/OutsideClick";

export const DropdownContext = React.createContext();

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  closeOnClick: PropTypes.bool,
  disabled: PropTypes.bool,
  dropUp: PropTypes.bool,
  right: PropTypes.bool,
};

// Override "z-index: 2" set on "btn-group" by Bootstrap 4...
const UNSET_Z = {
  zIndex: "unset",
};

function shouldClose(element) {
  return element &&
    element.getAttribute &&
    element.getAttribute("data-close-popover")
    ? true
    : !!element.parentNode && shouldClose(element.parentNode);
}

class Bs4DropDown extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentDidUpdate(_, { open }) {
    if (this.props.disabled) {
      this.handleClose();
    } else if (this.props.onToggle && open !== this.state.open) {
      this.props.onToggle(this.state.open);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  getApi = createSelector(
    (_, { open }) => open,
    ({ disabled }) => disabled,
    ({ right }) => right,
    ({ noFocusOpen }) => noFocusOpen,
    ({ dropup }) => dropup,
    (open, disabled, right, noFocusOpen, dropup) => {
      return {
        open,
        right,
        dropup,
        disabled,
        handleContentClick: this.handleContentClick,
        handleToggle: disabled ? undefined : this.toggleOpen,
        handleFocus: disabled || noFocusOpen ? undefined : this.handleFocus,
        handleClose: this.handleClose,
      };
    }
  );

  handleClose = () => {
    if (this.state.open) {
      this.setState(
        {
          open: false,
        },
        this.props.onClose
      );
    }
  };

  handleOpen = () => {
    // For use outside dropdown through ref.
    this.setState({
      open: true,
    });
  };

  handleContentClick = (e) => {
    if (this.props.closeOnClick || (e && shouldClose(e.target))) {
      requestAnimationFrame(this.handleClose);
    }
  };

  handleFocus = () => {
    // Delay to avoid conflict with click event,
    // and to make it possible to tab past trigger without instantly opening it.
    this.timer = setTimeout(() => {
      this.setState({
        open: true,
      });
    }, 400);
  };

  handleBlur = () => {
    clearTimeout(this.timer);
    if (this.state.open) {
      this.setState({
        open: false,
      });
    }
  };

  toggleOpen = (e) => {
    e.preventDefault();
    clearTimeout(this.timer);
    this.setState(({ open }) => ({
      open: !open,
    }));
  };

  render() {
    const { className = "btn-group", TagName = "div" } = this.props;

    return (
      <OutsideClick
        disabled={!this.state.open}
        className={`${className}${this.props.dropUp ? " dropup" : ""}`}
        style={UNSET_Z}
        data-inline-manual={this.props["data-inline-manual"]}
        onBlur={this.handleClose}
        onClickOutside={this.handleClose}
        TagName={TagName}
        data-testid={this.props["data-testid"]}
      >
        <DropdownContext.Provider value={this.getApi(this.props, this.state)}>
          {this.props.children}
        </DropdownContext.Provider>
      </OutsideClick>
    );
  }
}

Bs4DropDown.propTypes = propTypes;

export default Bs4DropDown;
