/* eslint react/prop-types: 0 */
import React from 'react';
import { FormattedMessage } from 'react-intl';

const Button = ({
  icon, title, onClick, active,
}) => (
  <button
    type="button"
    className={`btn btn-light border${active ? ' active' : ''}`}
    onClick={onClick}
    title={title}
  >
    <i className={`icon-${icon}`} />
  </button>
);

const Controls = ({
  backward,
  play,
  stop,
  fastForward,
  playing,
  onTogglePlayback,
  onSpeedChange,
  onStep,
  currentSpeed,
  speeds,
}) => {
  const onChange = (e) => {
    onSpeedChange(parseFloat(e.target.value));
  };

  return (
    <div className="btn-toolbar d-flex align-items-center">
      <div className="btn-group">
        {backward && (
          <Button
            icon="backward"
            title="Step backwards"
            onClick={() => {
              onTogglePlayback(false);
              onStep(-1);
            }}
          />
        )}
        {stop && (
          <Button
            active={!playing}
            icon="stop"
            title="Stop"
            onClick={() => onTogglePlayback(false)}
          />
        )}
        {play && (
          <Button
            active={playing}
            icon="play"
            title="Play"
            onClick={() => onTogglePlayback(true)}
          />
        )}
        {fastForward && (
          <Button
            icon="forward"
            title="Step forwards"
            onClick={() => {
              onTogglePlayback(false);
              onStep(1);
            }}
          />
        )}

        <div className="d-flex align-items-center ml-2 form-inline">
          {speeds.map((speed) => (
            <label
              key={speed}
              className="radio inline mb-0 mr-2"
            >
              <input
                type="radio"
                className="mr-1"
                value={speed}
                checked={speed === currentSpeed}
                onChange={onChange}
              />
              {speed}
              <FormattedMessage id="views.simulation.xTimesSpeed" />
            </label>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Controls;
