import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { intlShape } from 'utils/shapes';
import { error as errorMessage } from 'js/react/actions/message-actions';
import { isValidPassword } from 'js/react/_utils/validation';

const getUserStateFromProps = (props) => {
  const { name, group } = props.user;

  return {
    name,
    group,
    password: '',
  };
};

const propTypes = {
  groups: PropTypes.array,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  updateUserGroup: PropTypes.func.isRequired,
  updateUserName: PropTypes.func.isRequired,
  updateUserPassword: PropTypes.func.isRequired,
  user: PropTypes.object,
  intl: intlShape.isRequired,
};

const defaultProps = {};

class UserRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = getUserStateFromProps(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.user !== nextProps.user) {
      this.setState(getUserStateFromProps(nextProps));
    }
  }

  handleChange = (key, { target }) => {
    const { value } = target;
    this.setState(({ hasChanges = {} }) => ({
      [key]: value,
      hasChanges: {
        ...hasChanges,
        [key]: true,
      },
    }));
  };

  handleSelect = () => {
    this.props.onSelect(this.props.user.id);
  };

  handleGroupUpdate = () => {
    this.props.updateUserGroup(this.state.group);
  };

  handleNameUpdate = () => {
    this.props.updateUserName(this.state.name);
  };

  handlePassUpdate = () => {
    if (!isValidPassword(this.state.password)) {
      errorMessage(
        `Password requirements: Min. 4 chars long, 1 lowercase letter,
                1 uppercase letter, 1 digit.`,
      );
    } else {
      this.props.updateUserPassword(this.state.password);
    }
  };

  handleSave = () => {
    const { hasChanges } = this.state;

    hasChanges.group && this.handleGroupUpdate();
    hasChanges.name && this.handleNameUpdate();
    hasChanges.password && this.handlePassUpdate();

    this.setState({ hasChanges: undefined });
  }

  renderGroupOptions() {
    return (
      this.props.groups &&
      this.props.groups.map((g) => (
        <option key={g.id} value={g.id}>
          {g.name}
        </option>
      ))
    );
  }

  render() {
    const { intl, selected } = this.props;
    const {
      hasChanges, name, password, group,
    } = this.state;

    return (
      <tr>
        <td className="align-middle">
          <input
            type="checkbox"
            className="align-middle"
            onChange={this.handleSelect}
            checked={selected}
          />
        </td>
        <td>
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={this.handleChange.bind(null, 'name')}
            data-testid="name-input"
          />
        </td>
        <td>
          <select
            className="form-control mb-0"
            onChange={this.handleChange.bind(null, 'group')}
            value={group}
          >
            {this.renderGroupOptions()}
          </select>
        </td>
        <td>
          <input
            placeholder="********"
            type="password"
            className="form-control"
            value={password}
            onChange={this.handleChange.bind(null, 'password')}
          />
        </td>
        <td>
          <button
            type="button"
            className={hasChanges
              ? 'btn btn-primary float-right'
              : 'btn btn-white border float-right'
            }
            disabled={!hasChanges}
            onClick={hasChanges && this.handleSave}
          >
            {intl.formatMessage({
              id: 'common.save',
            })}
          </button>
        </td>
      </tr>
    );
  }
}

UserRow.propTypes = propTypes;
UserRow.defaultProps = defaultProps;

export default injectIntl(UserRow);
