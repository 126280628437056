import _, { isEmpty } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import globalSelectors from 'js/redux/dashboard/globalSelectors';

import createBarData from 'js/react/report/utils/createBarData';
import createPieData from 'js/react/report/utils/createPieData';
import getDateRange from 'js/react/report/utils/getDateRange';

import BarChart from 'js/react/report/components/BarChart';
import PieChart from 'js/react/report/components/PieChart';
import Legend from 'js/react/report/components/Legend';

import Spinner from '../../components/Spinner';
// import StatsTable from 'js/react/report/components/StatsTable';

class CompactBar extends BarChart {
  componentDidUpdate() {
    // Override inherited
    if (!this.props.loading) {
      this.updateChart();
    }
  }

  render() {
    return (
      <div
        className="row justify-content-center"
        style={{
          marginBottom: -20,
          minWidth: 560,
        }}
      >
        <div className="pl-2 align-self-center position-relative">
          {this.props.data && (
            <canvas
              ref={this.setCanvas}
              width="320px"
              height="300px"
            />
          )}
        </div>
        <div className="col mb-3">
          {this.props.data && (
            <Legend
              dataset={this.props.data.datasets || []}
              limit={this.props.limit}
              type="cnts"
            />
          )}
        </div>
      </div>
    );
  }
}

class CompactPie extends PieChart {
  componentDidUpdate() {
    // Override inherited
    if (!this.props.loading) {
      this.updateChart();
    }
  }

  render() {
    return (
      <div
        className="d-col d-flex flex-wrap justify-content-center"
        style={{
          marginBottom: -20,
          minWidth: 560,
        }}
      >
        <div className="align-self-center mb-3 px-3 position-relative">
          {this.props.data && (
            <canvas
              ref={this.setCanvas}
              width="200px"
              height="200px"
            />
          )}
        </div>
        <div className="d-col mb-3">
          {this.props.data && (
            <Legend
              dataset={(this.props.data.datasets || [])[0]}
              labels={this.props.data.labels || []}
              limit={this.props.limit}
              type={this.props.type}
            />
          )}
        </div>
      </div>
    );
  }
}

class PlaybackReport extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: isEmpty(this.props.aggregatedReports),
      grouped: this.convertReports(this.props.aggregatedReports),
    };
  }

  UNSAFE_componentWillReceiveProps(np) {
    if (np.aggregatedReports !== this.props.aggregatedReports) {
      this.setState({
        grouped: this.convertReports(np.aggregatedReports),
      });
    }
  }

  convertReports(aggregatedReports) {
    return Immutable.fromJS(aggregatedReports).toList().sortBy((g) => {
      return -g.get('dur');
    });
  }

  render() {
    const limit = 8;

    if (this.props.loadingReports) {
      return (
        <Spinner
          style={{ minWidth: 400, height: 200 }}
        />
      );
    }

    if (this.props.settings.graph === 'pie') {
      return (
        <CompactPie
          limit={limit}
          data={createPieData(this.state.grouped, {
            type: this.props.settings.sumBy,
            limit: limit - 1,
          })}
          type={this.props.settings.sumBy}
          loading={this.props.loadingReports}
        />
      );
    }

    return (
      <CompactBar
        limit={limit}
        data={createBarData(
          this.props.dateRange.toJS(),
          this.state.grouped,
          {
            type: this.props.settings.sumBy,
            limit: limit - 1,
          },
        )}
        loading={this.props.loadingReports}
      />
    );
    //       <StatsTable
    //         data={grouped}
    //         dates={this.props.dateRange}
    //         primarySortFunc={val => val}
    //         primaryFieldTranslationKey="common.media"
    //       />
  }
}

PlaybackReport.propTypes = {
  loadingReports: PropTypes.bool,
  aggregatedReports: PropTypes.object.isRequired,
  dateRange: PropTypes.object.isRequired, // immutable
  settings: PropTypes.shape({
    aggregate: PropTypes.shape({ key: PropTypes.string.isRequired }),
    days: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    filters: PropTypes.object,
    graph: PropTypes.string.isRequired,
    sumBy: PropTypes.string,
  }).isRequired,
};

function mapStateToProps(state, { widgetId }) {
  const widget = globalSelectors.getDashboardWidget(state, { widgetId });
  const settings = (widget && widget.settings) || {};
  const dateRange = getDateRange(
    moment('2017-09-30').subtract(Number(settings.days) - 1, 'days'), // eslint-disable-line no-undef
    moment('2017-09-30'), // TODO remove this and use current date
  );

  return {
    dateRange,
    aggregatedReports: globalSelectors.getAggregatedFilteredReportsForDateRange(
      state,
      settings,
      dateRange,
    ),
    settings,
  };
}

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(PlaybackReport);
