import React, { memo, useMemo, useContext } from 'react';
import im from 'immutable';
import FeatureMap from 'js/react/map/map';
import { transform } from 'ol/proj';
import { setCurrentLocation, setPlaying, SimulationContext } from './StateManager';
import { usePlacesWithGeometry } from '../hooks/useEnsurePlacesWithGeo';

function featureObj(place) {
  const { id, geometry } = place;
  const wkt = Array.isArray(geometry)
    ? geometry[0]?.wkt
    : geometry.wkt;

  return { wkt, id };
}

export const MapView = memo(() => {
  const [{ visiblePlaces, currentLocation }, dispatch] = useContext(SimulationContext);
  const places = usePlacesWithGeometry(visiblePlaces);
  const c2 = currentLocation && transform(currentLocation, 'EPSG:4326', 'EPSG:3857');
  const point = c2 && `POINT(${c2[0]} ${c2[1]})`;

  const handleMapClick = (coordinates) => {
    // Stop playback if we click anywhere on the map
    dispatch(setPlaying(false));
    dispatch(setCurrentLocation(coordinates));
  };

  const imFeatures = useMemo(() => {
    const mapData = places.filter(({ geometry } = {}) => geometry).map((p) => {
      return featureObj(p);
    });

    mapData.push({
      id: 0,
      wkt: point,
    });

    return im.fromJS(mapData);
  }, [places, point]);

  return (
    <FeatureMap
      center={point}
      height="40vh"
      features={imFeatures}
      onMapClick={handleMapClick}
    />
  );
});
