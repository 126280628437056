import { createSelector } from 'reselect';

import { createSelectors } from 'src/core/data';
import { COLLECTION } from './constants';

function sortByOrder(healthVariables, descending = false) {
  return Object.values(healthVariables).sort((elementA, elementB) => {
    const orderA = elementA.order;
    const orderB = elementB.order;

    if (orderA < orderB) {
      return descending ? 1 : -1;
    }

    if (orderA > orderB) {
      return descending ? -1 : 1;
    }

    return 0;
  });
}

export const {
  getAll: getHealthVariables,
} = createSelectors(COLLECTION);

export const getHealthVariablesSortedByOrder = createSelector(
  getHealthVariables,
  (statuses) => sortByOrder(statuses),
);
