import React from 'react';
import { useDragLayer } from 'react-dnd';
import { useSelector } from 'react-redux';
import { getMediaInObject } from 'src/entities/media';
import { DragTypes } from 'utils/constants';

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

function getItemStyles(currentOffset) {
  if (!currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x } = currentOffset;
  const { y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    position: 'absolute',
    transform,
    WebkitTransform: transform,
    zIndex: 100,
  };
}

function collect(monitor) {
  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(),
  };
}

const CustomDrag = () => {
  const allMedia = useSelector(getMediaInObject);
  const { item, itemType, currentOffset } = useDragLayer(collect);

  const renderMediaBox = (mediaIds) => {
    let offset = 2;
    const imgs = mediaIds.map((mid) => {
      const media = allMedia[mid];
      const s = {
        position: 'absolute',
        left: -offset * 5,
        opacity: 0.5,
        width: 80,
        top: offset * 5,
        zIndex: 100 + offset,
        border: '1px solid white',
        borderRadius: 3,
      };

      offset += 1;

      return (
        <div key={mid} style={s}>
          <img alt="" src={media?.thumb} />
        </div>
      );
    });

    return (
      <div className="">{imgs}</div>
    );
  };

  const renderNumber = (nbr) => {
    const style = {
      position: 'absolute',
      opacity: 0.8,
      color: 'white',
      textShadow: '1px 1px 3px black',
      left: -nbr * 5 - 10,
      top: nbr * 6,
      zIndex: 200,

    };

    return (
      <h1 style={style}>{nbr}</h1>
    );
  };

  if (!item) {
    return (<span />);
  }

  const { mediaIds } = item;

  if (itemType !== DragTypes.MEDIAROW) {
    return (<div />);
  }

  return (
    <div style={getItemStyles(currentOffset)}>
      {renderNumber(mediaIds.length)}
      {renderMediaBox(mediaIds)}
    </div>
  );
};

function Cd() {
  return (
    <div style={layerStyles}>
      <CustomDrag />
    </div>
  );
}

export default Cd;
