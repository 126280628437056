import React from 'react';
import { reduce, sortBy, map } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { fmtDuration } from './StatsTable';

function getData(datasets) {
  if (datasets.data) {
    return datasets.data;
  }

  return datasets.map((d) => {
    return d.data.reduce((acc, val) => {
      return acc + val;
    }, 0);
  });
}

function getColors(datasets) {
  if (datasets.backgroundColor) {
    return datasets.backgroundColor;
  }
  return datasets.map((d) => {
    return d.backgroundColor;
  });
}

function getLabels(datasets) {
  return datasets.map((d) => {
    return d.label;
  });
}

function valToPercent(val, total) {
  return `${Math.round(1000 * val / total) / 10}%`;
}

const Legend = ({
  dataset, labels, limit = 20, type = 'dur', ...other
}) => {
  const data = getData(dataset);
  const backgroundColor = getColors(dataset);
  const _labels = labels || getLabels(dataset);

  // if (data.length > 20) {
  //   return (
  //     <span>
  //       <em>Select max 20 items to display legend.</em>
  //     </span>
  //   );
  // }

  const total = data.reduce((acc, d) => {
    return acc + d;
  }, 0);

  const labelMap = reduce(data, (acc, val, i) => {
    acc.push({
      label: _labels[i] || '',
      background: backgroundColor[i],
      color: '#fff',
      val,
    });
    return acc;
  }, []);

  const sorted = sortBy(labelMap, (l) => -l.val);
  const legendRows = map(sorted.slice(0, limit), ({ val, label, background }, i) => {
    const s = { background, color: '#fff' };

    return (
      <tr key={i} title={label}>
        <td>
          {type === 'dur'
            ? fmtDuration(val)
            : val}
        </td>
        <td>
          {val && valToPercent(val, total)}
        </td>
        <td>
          <span style={s} className="marker pa2 mr-1 rounded chart-label">
            {label}
          </span>
        </td>
      </tr>
    );

    // const label = _labels[i] || '';
    // var val = (d.value && d.value.toFixed(2) + ' - ') || '';

    // return (
    //   <div key={i} className="entry mb2" title={label}>
    //     <span style={s} className="marker pa2 mr-1 rounded">
    //       {fmtDuration(val)} {val && valToPercent(val, total)} {label}
    //     </span>
    //   </div>
    // );
  });

  // const listStyle = {};
  const hidden = data.length - legendRows.length;

  return (
    <div {...other}>
      <table className="table table-sm mb-0">
        <thead>
          <tr>
            <th>
              {type === 'dur' &&
                <FormattedMessage id="views.report.duration" />}
              {type !== 'dur' &&
                <FormattedMessage id="views.report.plays" />}
            </th>
            <th>
              %
            </th>
            <th>
              <FormattedMessage id="common.name" />
            </th>
          </tr>
        </thead>
        <tbody>
          {legendRows}
        </tbody>
      </table>
      {hidden > 0 && (
        <em className="float-right">
          <small>
            {'+'}
            {hidden}
            {' '}
            <FormattedMessage id="views.report.moreRows" />
          </small>
        </em>
      )}
    </div>
  );

  // return (
  //   <div className="legend" style={listStyle}>
  //     {legendRows}
  //   </div>
  // );
};

export default Legend;
