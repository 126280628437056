import React from 'react';
import { string, shape, func } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEditable } from '../helpers/buildMapData';
import { PLACE_TYPES } from './constants';

const availableTypes = [
  PLACE_TYPES.AREA,
  PLACE_TYPES.STOP,
  PLACE_TYPES.LINE,
].filter(isEditable);

export const AddPlaceForm = ({
  item,
  onNameChange,
  onTypeChange,
  onCancel,
  onSave,
}) => {
  const intl = useIntl();
  const { name, type, wkt } = item || {};
  const complete = name?.length > 0 && wkt?.length > 2;

  return !item ? null : (
    <div>
      <h4>
        {name
          ? name?.trim()
          : intl.formatMessage({
              id: 'views.places.addPlace.unnamedPlace',
            })}
      </h4>
      <hr className="my-2" />
      <div>
        <label>
          <input
            type="text"
            className="form-control"
            placeholder={`${intl.formatMessage({
              id: 'common.name',
            })}...`}
            value={name}
            onChange={(e) => onNameChange(e.target.value)}
          />
        </label>

        <select
          value={type}
          onChange={(e) => onTypeChange(e.target.value)}
          className="form-control text-capitalize mb-2"
        >
          {availableTypes.map((tp) => {
            return (
              <option key={tp} value={tp}>
                {intl.formatMessage({ id: `common.${tp}` })}
              </option>
            );
          })}
        </select>

        <div>
          <input
            type="checkbox"
            readOnly
            checked={(wkt && wkt.length > 2) || false}
            className="align-middle"
          />
          {type && (
            <em>
              {' '}
              <FormattedMessage
                id={`views.places.addPlace.placeThePoint.${type}`}
              />
            </em>
          )}
        </div>

        <div className="mt-2 float-right">
          <button
            type="button"
            className="btn btn-sm btn-light border"
            onClick={onCancel}
          >
            <FormattedMessage id="common.cancel" />
          </button>
          <button
            type="button"
            className={`btn btn-sm ml-1 btn-primary${
              !complete ? ' disabled' : ''
            }`}
            onClick={complete ? onSave : undefined}
            data-testid="place-save-btn"
          >
            <FormattedMessage id="common.save" />
          </button>
        </div>
      </div>
    </div>
  );
};

AddPlaceForm.propTypes = {
  item: shape({
    name: string,
    type: string,
  }),
  onNameChange: func.isRequired,
  onTypeChange: func.isRequired,
  onCancel: func.isRequired,
  onSave: func.isRequired,
};
