import React from 'react';
import {
  FormCheck,
  FormText,
  FormGroup,
} from 'components/Form';

const createMarkup = (text) => {
  return { __html: text };
};


const Checkbox = (
  {
    id,
    title,
    description,
    value,
    onChange,
    disabled,
  },
) => {
  return (
    <FormGroup
      id={id}
      data-testid="customWidgetCheckbox"
    >
      <FormCheck
        type="checkbox"
        label={title}
        checked={value}
        onChange={(event) => onChange(event.target.checked)}
        disabled={disabled}
      />

      <FormText className="text-muted">
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={createMarkup(description)}
        />
      </FormText>
    </FormGroup>
  );
};

export default Checkbox;
