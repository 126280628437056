import moment from 'moment';
import { createCrudActionCreators, buildGetType, buildApiMetaFactory } from 'src/core/api';
import cancan from 'js/system/cancan';
import { PLANNING_TIMEPLAN } from 'constants/permissions';

import { BULK_PLAN_MEDIA, COPY_MEDIA_FROM_INTERVAL } from './actionTypes';
import {
  PLANNED_MEDIA_BULK_URL,
  PLANNED_MEDIA_COLLECTION,
  PLANNED_MEDIA_CONFIGS_COLLECTION,
  PLANNED_MEDIA_CONFIG_URL,
  PLANNED_MEDIA_URL,
} from './constants';

export const {
  destroy: deletePlanning,
} = createCrudActionCreators({ collection: PLANNED_MEDIA_COLLECTION, url: PLANNED_MEDIA_URL });

export const {
  create: bulkPostPlannings,
  update: bulkUpdatePlannings,
} = createCrudActionCreators({ collection: PLANNED_MEDIA_COLLECTION, url: PLANNED_MEDIA_BULK_URL });

export const {
  readAll: fetchConfigs,
} = createCrudActionCreators({ collection: PLANNED_MEDIA_CONFIGS_COLLECTION, url: PLANNED_MEDIA_CONFIG_URL });

const buildPlannedMediaApiMeta = buildApiMetaFactory({
  collection: PLANNED_MEDIA_COLLECTION,
  url: PLANNED_MEDIA_URL,
});

export const fetchPlanned = (f, t, { reset = true } = {}) => {
  const hasTimeplan = cancan.can('read', PLANNING_TIMEPLAN);
  const _from = moment(f || '1970');
  const _to = moment(t || '2035');

  return {
    type: buildGetType(PLANNED_MEDIA_COLLECTION),
    meta: {
      ...buildPlannedMediaApiMeta({ reset }),
      queryParams: hasTimeplan ? {
        from: _from.format('YYYY-MM-DD'),
        to: _to.format('YYYY-MM-DD'),
      } : {},
    },
  };
};

export const bulkPlanMedia = (mediaObjects, playlistId, templates) => {
  return {
    type: BULK_PLAN_MEDIA,
    payload: {
      mediaObjects,
      playlistId,
      templates,
    },
  };
};

export const copyFromInterval = (fromStart, fromEnd, moveFn, playlistId) => {
  return {
    type: COPY_MEDIA_FROM_INTERVAL,
    meta: {
      fromStart, fromEnd, moveFn, playlistId,
    },
  };
};
