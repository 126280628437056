import isocodes from 'constants/isocodes.json';

import { I18N_SET_LANGUAGE } from './actionTypes';
import { GEO_UI_LANGUAGE } from './constants';

const initialState = {
  availableLanguages: isocodes.language.available,
  defaultLanguage: isocodes.language.default || 'en',
  language:
    (window.localStorage && window.localStorage.getItem(GEO_UI_LANGUAGE)) ||
    null,
  languageNames: isocodes.language.names,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case I18N_SET_LANGUAGE: {
    return {
      ...state,
      language: action.payload,
    };
  }
  default:
    return state;
  }
}
