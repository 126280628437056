import React, {
  useState, useEffect, useMemo, memo,
} from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import cancan from 'js/system/cancan';

import { fetchMediaObjects, getMediaInObject } from 'src/entities/media';
import { DEFAULT_LAYOUT, DEFAULT_AREAS, isDefaultMode } from 'js/react/ClientGroups/_utils';
import { getClientGroupById } from 'src/entities/clientGroups';
import { CLIENTS_SETTINGS_PREVENT_CUSTOM_LAYOUT } from 'constants/permissions';

import { useRequestWithPromise } from 'src/core/api';
import {
  createLayout,
  fetchClientGroupLayouts, getAllLayouts, removeLayout, updateLayout,
} from 'src/entities/layouts';
import { mergeAreaSetting } from '../_utils';
import { LayoutPreview } from '../LayoutPreview';
import LayoutEditor from '../LayoutEditor';
import AreaList from './components/AreaList';
import { StyleSettings } from './components/StyleSettings';
import LayoutCopy from './components/LayoutCopy';
import { useDefaultScheme } from './hooks/useDefaultScheme';

function mergeAreaSettings(areas, oldSettings = {}, newSettings = {}) {
  return areas.reduce((acc, { id }) => {
    const setting = oldSettings[id] || {};
    const updatedSettings = newSettings[id] || {};

    return {
      ...acc,
      [id]: mergeAreaSetting({
        ...setting,
        ...updatedSettings,
      }),
    };
  }, { ...oldSettings });
}

const useLayoutDefaultMode = (allLayouts, clientLayouts, layout) => {
  const isDefault = !clientLayouts.length || isDefaultMode(layout?.data);
  const [defaultMode, setDefaultMode] = useState(allLayouts && isDefault);

  useEffect(() => {
    setDefaultMode(isDefault);
  }, [isDefault]);

  return [defaultMode, setDefaultMode];
};

export const LayoutEditorSidebar = memo(({ clientId, isNested }) => {
  const { dispatch, pending: saving } = useRequestWithPromise();
  const [editLayout, setEditLayout] = useState(false);
  const [areaSettings, setAreaSettings] = useState({});
  const [displayStyleSettings, setDisplayStyleSettings] = useState(false);

  const clientGroup = useSelector(getClientGroupById(clientId));
  const media = useSelector(getMediaInObject);
  const allLayouts = useSelector(getAllLayouts);
  const defaultSchemeId = useDefaultScheme();

  const {
    id,
    name,
    playlist_scheme_id: plSchemeId,
  } = clientGroup || {};
  const noCustomLayout = cancan.can('read', CLIENTS_SETTINGS_PREVENT_CUSTOM_LAYOUT);

  const clientLayouts = useMemo(() => {
    return (allLayouts || []).filter(({ client_group: clientGroupId }) => {
      return clientGroupId === clientId;
    });
  }, [allLayouts]);

  const [layout = {}] = clientLayouts;
  const [defaultMode, setDefaultMode] = useLayoutDefaultMode(allLayouts, clientLayouts, layout);

  const mergedSettings = useMemo(() => {
    return mergeAreaSettings(
      layout?.data?.areas || [],
      layout?.data?.areaSettings,
      areaSettings,
    );
  }, [layout, plSchemeId, areaSettings]);

  useEffect(() => {
    // Needed for thumbs
    !media && dispatch(fetchMediaObjects());
    dispatch(fetchClientGroupLayouts(clientId));
  }, []);

  const handleEdit = () => {
    setEditLayout(true);
    setDisplayStyleSettings();
  };

  const handleSave = (nextSettings) => {
    layout?.data && dispatch(updateLayout(clientId, {
      ...layout,
      data: {
        ...layout.data,
        areaSettings: nextSettings,
        areas: layout.data?.areas,
      },
    }));
  };

  const handleAreaUpdate = (areaId, settings = {}) => {
    const aSettings = {
      ...mergedSettings,
      [areaId]: {
        ...(mergedSettings[areaId] || {}),
        ...settings,
      },
    };

    setAreaSettings(aSettings);
    handleSave(aSettings);
  };

  const handleResetStyle = () => {
    const areaId = displayStyleSettings;

    setDisplayStyleSettings(undefined);
    setAreaSettings((aSettings) => {
      const current = (aSettings[areaId] || {});
      delete current.style;

      return {
        ...aSettings,
        [areaId]: {
          ...current,
        },
      };
    });
  };

  const handleLayout = async (nextLayout) => {
    await nextLayout?.id
      ? dispatch(updateLayout(clientId, nextLayout))
      : dispatch(createLayout(clientId, nextLayout));
    setEditLayout(false);
  };

  const handleCopyLayout = async (nextLayout) => {
    await dispatch(updateLayout(clientId, {
      ...nextLayout,
      id: layout.id,
      name: layout.name,
      client_group: layout.client_group,
    }));
    setEditLayout(false);
  };

  const deleteLayout = async (layoutId) => {
    await dispatch(removeLayout(clientId, layoutId));
  };

  const handleToggleLayout = () => {
    if (defaultMode) {
      setDefaultMode(false);
    } else if (layout.id) {
      // Delete all but the first layout, reset first layout to default.
      clientLayouts.forEach((layt, idx) => {
        if (idx === 0) {
          handleLayout({
            ...layt,
            data: {
              ...DEFAULT_LAYOUT,
              areaSettings: {
                A: {
                  ...DEFAULT_LAYOUT.areaSettings.A,
                  contentId: defaultSchemeId,
                },
              },
            },
          });
        } else {
          deleteLayout(layt.id);
        }
      });
      setDefaultMode(true);
    }
  };

  return !!layout && (
    <div className={isNested ? '' : 'holder bg-white p-3'}>
      {!isNested && (
        <h3>
          {name}
        </h3>
      )}
      {isNested && (
        <div className="d-flex mb-2">
          <h5 className="mb-1">
            <FormattedMessage id={defaultMode
              ? 'views.layout.ChannelSettings'
              : 'views.layout.LayoutSettings'}
            />
          </h5>
          <form className={`form-inline ml-auto mb-1${noCustomLayout ? ' text-muted' : ''}`}>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>
              <input
                type="checkbox"
                checked={noCustomLayout ? false : !defaultMode}
                onChange={saving ? undefined : handleToggleLayout}
                disabled={saving || noCustomLayout}
                className="mr-1"
              />
              <FormattedMessage id="views.layout.enableCustom" />
            </label>
          </form>
        </div>
      )}
      <div className="no-gutters">
        {!defaultMode && (
          <div className="col mb-3">
            <div className="mx-auto">
              <LayoutPreview
                areas={layout.data?.areas}
                aspectRatio={layout.data?.aspectRatio}
                areaSettings={mergedSettings}
                className="mb-2"
                onClick={handleEdit}
                media={media}
                overlayText={isDefaultMode(layout) && (
                  <FormattedMessage id="views.layout.clickToEdit" />
                )}
              />
            </div>
          </div>
        )}
        <div className="col">
          {!displayStyleSettings && (
            <div className="card px-2 pb-2">
              <AreaList
                defaultMode={defaultMode}
                areas={layout?.data?.areas || DEFAULT_AREAS}
                areaSettings={mergedSettings}
                updateArea={handleAreaUpdate}
                handleStyleSettings={setDisplayStyleSettings}
              />
              {!defaultMode && (
                <div className="row no-gutters mt-2">
                  <div className="ml-auto">
                    <LayoutCopy
                      onSelect={handleCopyLayout}
                      exclude={[id]}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {displayStyleSettings && (
            <StyleSettings
              media={media}
              areaId={displayStyleSettings}
              areaSettings={mergedSettings}
              updateArea={(styleSettings) => handleAreaUpdate(displayStyleSettings, styleSettings)}
              handleClose={setDisplayStyleSettings}
              onCancel={handleResetStyle}
              saving={saving}
            />
          )}
        </div>
      </div>

      <ReactModal
        appElement={document.getElementById('gs_main')}
        isOpen={editLayout}
        contentLabel="Layout editor"
        onRequestClose={() => setEditLayout(false)}
        style={{
          content: {
            bottom: 'auto',
            margin: 'auto',
            minHeight: '150px',
            top: '120px',
          },
        }}
      >
        <LayoutEditor
          layout={layout}
          onClose={() => setEditLayout(false)}
          updateLayout={handleLayout}
        />
      </ReactModal>
    </div>
  );
});
