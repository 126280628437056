/* eslint-disable max-classes-per-file */
import React, { useEffect, useState } from 'react';
import PropTypes, {
  arrayOf,
  func,
  node, oneOfType, shape, string,
} from 'prop-types';

import { useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { intlShape } from 'utils/shapes';
import * as MsgActions from 'js/react/actions/message-actions';

import { updateMediaObject, fetchMediaObject, getMediaObjectById } from 'src/entities/media';
import format from 'js/react/plan/format';

import { useRequestWithPromise } from 'src/core/api';
import { isEmpty } from 'lodash';
import { EditWidget } from './Widgets/EditWidget';
import Preview from './Preview';

const LabelRow = ({ label, children }) => {
  return (
    <tr>
      <td>
        <small>
          {label}
          {': '}
        </small>
      </td>
      <td>
        <b>{children}</b>
      </td>
    </tr>
  );
};

LabelRow.propTypes = {
  label: string,
  children: oneOfType([
    arrayOf(node),
    node,
  ]),
};

const getStateMsgLabel = (message) => {
  switch (message) {
  case 'transcode_pending':
    return 'views.planning.media.transcodePending';
  case 'transcode_failed':
    return 'views.planning.media.transcodeFailed';
  default:
    return '';
  }
};

const SettingsTableComponent = ({
  intl,
  media,
  stateMsg,
  children,
}) => {
  const stateMsgLabel = getStateMsgLabel(stateMsg);

  return (
    <tbody>
      <LabelRow label={intl.formatMessage({ id: 'common.type' })}>
        {media.description}
      </LabelRow>
      {children}
      <LabelRow label={intl.formatMessage({ id: 'common.uploaded' })}>
        {format.uploaded(media.upload_date)}
      </LabelRow>
      {!!stateMsgLabel && (
        <LabelRow label={intl.formatMessage({ id: 'views.planning.media.state' })}>
          <FormattedMessage id={stateMsgLabel} />
        </LabelRow>
      )}
    </tbody>
  );
};

SettingsTableComponent.propTypes = {
  intl: intlShape.isRequired,
  media: shape({
    id: string,
    name: string,
  }),
  stateMsg: string,
  children: oneOfType([
    arrayOf(node),
    node,
  ]),
};

const SettingsTable = injectIntl(SettingsTableComponent);

const NameEditor = ({
  value,
  onChange,
  onDone,
  onCancel,
}) => {
  return (
    <tr className="control-group">
      <td>
        <label htmlFor="name">
          <small>
            <FormattedMessage id="common.name" />
            {': '}
          </small>
        </label>
      </td>
      <td>
        <input
          onChange={onChange}
          onKeyDown={onCancel}
          onBlur={onDone}
          value={value}
          name="name"
          type="text"
          className="form-control"
          data-testid="media-name-input"
        />
      </td>
    </tr>
  );
};

NameEditor.propTypes = {
  value: string,
  onChange: func,
  onDone: func,
  onCancel: func,
};

const LengthEditor = ({
  media,
  value,
  onChange,
  onDone,
  onCancel,
}) => {
  return (
    <tr className="control-group">
      <td>
        <small>
          <FormattedMessage id="common.playTime" />
          {': '}
        </small>
      </td>
      <td className="form-inline">
        {media.type === 'I' && (
          <input
            onChange={onChange}
            onKeyDown={onCancel}
            onBlur={onDone}
            value={value}
            type="number"
            className="form-control mr-1"
            data-testid="media-length-input"
          />
        )}
        <span>
          {'('}
          {format.time(media.length)}
          {')'}
        </span>
      </td>
    </tr>
  );
};

LengthEditor.propTypes = {
  media: shape({
    id: string,
    name: string,
  }),
  value: PropTypes.number,
  onChange: func,
  onDone: func,
  onCancel: func,
};

const Editor = ({ media, onChange }) => {
  const [params, setParams] = useState({
    name: media.name,
    length: media.length,
    group: media.group,
  });

  const handleChange = (param, saveOnChange) => {
    return (e) => {
      const { value } = e.target;
      setParams((prms) => ({
        ...prms,
        [param]: value,
      }));
      saveOnChange && onChange(params);
    };
  };

  const handleCancel = (event) => {
    if (event.keyCode === 27) {
      setParams({
        name: media.name,
        length: media.length,
        group: media.group,
      });
    }
  };

  const handleUpdate = () => {
    onChange(params);
  };

  useEffect(() => {
    setParams({
      name: media.name,
      length: media.length,
      group: media.group,
    });
  }, [media.name, media.length, media.group]);

  return (
    <SettingsTable media={media} stateMsg={media.state}>
      <NameEditor
        onChange={handleChange('name')}
        onCancel={handleCancel}
        onDone={handleUpdate}
        value={params.name}
      />
      <LengthEditor
        onChange={handleChange('length')}
        onCancel={handleCancel}
        onDone={handleUpdate}
        value={Number(params.length)}
        media={media}
      />
    </SettingsTable>
  );
};

export const MediaEditor = ({ mediaId, onClose }) => {
  const { dispatch } = useRequestWithPromise();
  const media = useSelector(getMediaObjectById(mediaId));

  useEffect(() => {
    const poll = setInterval(() => {
      dispatch(fetchMediaObject(mediaId));
    }, 2000);

    return () => {
      clearInterval(poll);
    };
  }, []);

  const handleSave = (params) => {
    dispatch(updateMediaObject({
      ...media,
      ...params,
    })).catch(({ payload }) => {
      payload.detail
        ? MsgActions.error(payload.detail)
        : MsgActions.error('Unknown error');
    });
  };

  if (!media) {
    return null;
  }

  if (media && media.type === 'U' && media.media_type.indexOf('widget') > -1) {
    return (
      <EditWidget
        mediaId={mediaId}
        onClose={onClose}
        widgetType={media.media_type}
      />
    );
  }

  return (
    <div className="holder bg-white p-3">
      {onClose && (
        <button
          type="button"
          aria-label="Close"
          className="close d-none d-sm-inline-block"
          onClick={onClose}
        >
          <span aria-hidden="true">
            {'\u00D7'}
          </span>
        </button>
      )}

      <h3 data-testid="edit-media-heading">
        {media && media.name}
      </h3>

      <div className="card p-2 mb-3 pb44">
        <div>
          {!isEmpty(media) && (
            <Preview
              configParams={media.config}
              type={media.type}
              mediaType={media.media_type}
              preview={media.preview}
            />
          )}
        </div>

        <table className="settings mt-3 mb-3">
          {!isEmpty(media) && (
            <Editor
              media={media}
              onChange={handleSave}
            />
          )}
        </table>
      </div>
    </div>
  );
};
