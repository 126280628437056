import React from 'react';

const createMarkup = (text) => {
  return { __html: text };
};

export const ReadOnly = (
  {
    id,
    title,
    value,
  },
) => {
  return (
    <div
      id={id}
      className="form-group"
      data-testid="customWidgetURICallback"
    >
      <label htmlFor={id}>
        {title}
      </label>
      <div className="border rounded p-3">
        {value}
      </div>
    </div>
  );
};
