import React, { useEffect, useMemo, useState } from 'react';
import Immutable from 'immutable';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import cancan from 'js/system/cancan';
import * as selectors from 'js/redux/selectors';
import { PLANNING_LONG_TERM, PLANNING_TIMEPLAN } from 'constants/permissions';

import { limitFilter, totalPlanned } from 'js/react/plan2/playlists/template-utils';
import { getMediaInObject } from 'src/entities/media';
import PlanRows from './plan-rows';
import { TimeLimitFilter } from './TimeLimitFilter';
import { PlannedMediaGroupOptions } from './PlannedMediaGroupOptions';

const Description = ({
  templateFrom,
  templateTo,
  template,
  hasTimeLimits,
}) => {
  const dateFmt = 'YYYY-MM-DD';
  const hasTimeplan = cancan.can('read', PLANNING_TIMEPLAN);

  return (
    <p className="description clearfix my-0 mb-1 mt-2 mt-sm-3">
      {hasTimeplan && (
        <b>
          <i className="icon-calendar" />{' '}
          {template.id !== 'default' && (
            <>
              <FormattedMessage id="common.template" />
              {': '}
              {template.name}
              {', '}
            </>
          )}
          {template.id === 'default' && (
            <>
              <FormattedMessage id="views.planning.playlists.outsideTimeTemplate" />
            </>
          )}
        </b>
      )}
      {hasTimeplan && template.id !== 'default' && (
        <span className="d-block d-sm-inline">
          <i>{templateFrom.format(dateFmt)}</i>
          {' - '}
          <i>{templateTo.format(dateFmt)}</i>
        </span>
      )}
      {!hasTimeplan && hasTimeLimits && (
        <span className="d-block d-sm-inline">
          <i>
            {'Current media'}
          </i>
        </span>
      )}
    </p>

  );
};

function ProgressBar({ progress }) {
  let filled = progress;
  let overflow = 0;

  if (progress > 100) {
    overflow = progress - 100;
    filled = progress - overflow;

    overflow = Math.ceil((overflow / progress) * 100);
    filled = Math.floor((filled / progress) * 100);
  }

  return (
    <div className="progress h10 mb-3 w100p">
      <div
        className="progress-bar"
        style={{ display: 'block', width: `${filled}%` }}
      />
      {overflow > 0 && (
        <div className="progress-bar bg-danger" style={{ width: `${overflow}%` }} />
      )}
    </div>
  );
}

export const PlannedMediaGroup = ({
  date,
  plannings: allPlannings,
  playlist,
  template,
  unordered,
}) => {
  const planLocked = useSelector((state) => state.plan.get('planLocked'));
  const fromDate = useSelector(selectors.getFromDate);
  const toDate = useSelector(selectors.getToDate);
  const mediaObjects = useSelector(getMediaInObject);
  const activeLimitFilter = useSelector(selectors.getLimitFilter);
  const hasTimeplan = cancan.can('read', PLANNING_TIMEPLAN);
  const hasTimeLimits = cancan.can('read', PLANNING_LONG_TERM);

  const [selected, setSelected] = useState(Immutable.Set());

  const allMedia = useMemo(() => {
    return Immutable.fromJS(mediaObjects || {});
  }, [mediaObjects]);

  const plannings = useMemo(() => {
    return limitFilter(allPlannings, activeLimitFilter);
  }, [allPlannings, activeLimitFilter]);

  const total = useMemo(() => {
    return totalPlanned(allPlannings, allMedia);
  }, [allPlannings, allMedia]);

  const max = playlist?.max_time;
  const progr = Math.round((total / max) * 100);

  const bounds = useMemo(() => {
    const hasTimeTemplate = cancan.can('read', PLANNING_TIMEPLAN);
    const startDay = template.start;
    const endDay = template.end;

    return {
      templateFrom: moment(
        (hasTimeTemplate && fromDate) || '2010-01-01',
      ).weekday(Number.isInteger(startDay) ? startDay : 0),
      templateTo: moment(
        (hasTimeTemplate && toDate) || '2035-12-31',
      ).weekday(Number.isInteger(endDay) ? endDay : 6),
    };
  }, [template, fromDate, toDate]);


  // Actions

  const handleSelect = (ids) => {
    setSelected(Immutable.Set.isSet(ids)
      ? selected.union(ids)
      : selected.add(ids));
  };

  const handleDeselect = (ids) => {
    setSelected(Immutable.Set.isSet(ids)
      ? selected.subtract(ids)
      : selected.delete(ids));
  };

  const handleDeselectAll = () => {
    setSelected(Immutable.Set());
  };

  useEffect(() => {
    handleDeselectAll();
  }, [fromDate, plannings.size]);

  // Render

  if (!playlist || !template) {
    return <div />;
  }

  return (
    <div className="mb-3">
      {(hasTimeplan || hasTimeLimits) && (
        <>
          <Description
            templateFrom={bounds.templateFrom}
            templateTo={bounds.templateTo}
            date={date}
            template={template}
            playlist={playlist}
            total={total}
            hasTimeLimits={hasTimeLimits}
          />
          {!hasTimeplan && <ProgressBar progress={progr} />}
        </>
      )}
      <PlannedMediaGroupOptions
        bounds={bounds}
        activeLimitFilter={activeLimitFilter}
        template={template}
        playlist={playlist}
        selected={selected}
        handleDeselectAll={handleDeselectAll}
        planLocked={planLocked}
        plannings={plannings}
        unordered={unordered}
      />

      {!hasTimeplan && hasTimeLimits && (
        <TimeLimitFilter onChange={handleDeselectAll} />
      )}

      <div
        className="media_container table_container card bg-light p-2 ui-sortable"
        style={{ background: '#fff' }}
      >
        {plannings.size === 0 && (
          <div className="alert alert-warning mb-0">
            <FormattedMessage id="views.planning.playlists.noMediaAdded" />
          </div>
        )}
        <PlanRows
          templateFrom={bounds.templateFrom}
          templateTo={bounds.templateTo}
          unordered={unordered}
          noDrag={unordered || activeLimitFilter}
          plannings={plannings}
          planLocked={planLocked}
          selected={selected}
          handleSelect={handleSelect /* Used in checkbox wrapper. */}
          handleDeselect={handleDeselect /* Used in checkbox wrapper. */}
        />
      </div>
    </div>
  );
};
