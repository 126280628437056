import { CLEAR_HELP_MODAL, SET_HELP_MODAL } from './actionTypes';

const initialState = {
  helpModal: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case CLEAR_HELP_MODAL: {
    return {
      helpModal: null,
    };
  }

  case SET_HELP_MODAL: {
    return {
      helpModal: action.payload,
    };
  }

  default:
    return state;
  }
}
