import React, { useMemo } from 'react';
import Immutable from 'immutable';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import cancan from 'js/system/cancan';
import * as selectors from 'js/redux/selectors';
import { PLANNING_TIMEPLAN } from 'constants/permissions';

import { getPlanningsByPlaylist, getConfigs } from 'src/entities/plannedMedia';
import { mediaByTmpl, plannedLimits } from 'js/react/plan2/playlists/template-utils';
import DateBar from 'js/react/plan2/date-bar';

import { PlannedMediaGroup } from './PlannedMediaGroup';
import { HeatMap } from './HeatMap';

export const PlannedMedia = ({ date, playlistId }) => {
  const playlist = useSelector((state) => selectors.getPlaylistById(state, playlistId));
  const planningsByPlaylist = useSelector(
    (state) => getPlanningsByPlaylist(state, { id: playlistId }),
  );
  const configs = useSelector(getConfigs);

  const hasTimeplan = cancan.can('read', PLANNING_TIMEPLAN);

  const planned = useMemo(() => {
    return plannedLimits(planningsByPlaylist);
  }, [planningsByPlaylist]);

  const groupedPlannings = useMemo(() => {
    return mediaByTmpl(planned, configs);
  }, [planned, configs]);

  const templates = useMemo(() => {
    const def = groupedPlannings.get('default');
    const tmpls = [...configs];
    if ((def && def.size > 0) || tmpls.length === 0) {
      tmpls.push({
        id: 'default',
        name: '',
      });
    }
    return tmpls;
  }, [groupedPlannings, configs]);


  return (
    <>
      {hasTimeplan && (
        <DateBar
          noDateSetup
          testId="playlist-edit-datebar"
          className="d-md-none"
          fullBorder
          date={date}
        />
      )}
      {hasTimeplan && playlist && (
        <>
          <b>
            <i className="icon-time mr-1" />
            <FormattedMessage id="common.totalPlayTime" />
          </b>
          <HeatMap
            plannings={planned}
            maxTime={playlist.max_time}
          />
        </>
      )}
      {templates?.map((tmpl) => {
        const plannings = groupedPlannings.get(tmpl.id) || Immutable.List();
        return (
          <PlannedMediaGroup
            key={tmpl.id}
            unordered={cancan.can('read', PLANNING_TIMEPLAN) && tmpl.id === 'default'}
            playlist={playlist}
            template={tmpl}
            plannings={plannings}
          />
        );
      })}
    </>
  );
};
