import { createSelector } from "reselect";
import { parseISODateTime } from "tzdateutils";
import { createSelectors } from "../../core/data";

import {
  COLLECTION,
  ANNOUNCEMENTS_COLL,
  ANNOUNCEMENTS_TAGS_COLLECTION,
} from "./constants";

export const {
  getAll: getMediaInObject,
  getAllInArray: getMediaObjects,
  getById: getMediaObjectById,
} = createSelectors(COLLECTION);

const getAudio = createSelector(getMediaObjects, (media) =>
  media.filter((m) => m.media_type === "audio")
);

export const getApprovedAudio = createSelector(getAudio, (audioObjects) =>
  audioObjects.filter((audioObject) => audioObject.state === "approved")
);

// TODO: Replace this when all media is auto-converted
export const getPreppedMediaObjects = createSelector(getMediaObjects, (media) =>
  media?.map((m) => {
    return {
      ...m,
      upload_date: parseISODateTime(m.upload_date),
    };
  })
);

export const { getById: getAnnouncement } = createSelectors(ANNOUNCEMENTS_COLL);

export const { getAllInArray: getAnnouncementTags } = createSelectors(
  ANNOUNCEMENTS_TAGS_COLLECTION
);
