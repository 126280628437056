import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

// import en from 'react-intl/locale-data/en';
// import es from 'react-intl/locale-data/es';
// import sv from 'react-intl/locale-data/sv';

import * as selectors from 'js/redux/selectors';
import translations from '../../../../translations/index.json';

// addLocaleData([...en, ...es, ...sv]);

const defaultLanguage = 'en';

const mapStateToProps = (state) => {
  return {
    language: selectors.getLanguage(state),
  };
};
const childContextTypes = {
  language: PropTypes.string.isRequired,
  translations: PropTypes.objectOf(
    PropTypes.objectOf(
      PropTypes.string,
    ),
  ).isRequired,
};
const propTypes = {
  children: PropTypes.node.isRequired,
  language: PropTypes.string.isRequired,
};

class I18nProvider extends Component {
  getChildContext() {
    const { language } = this.props;
    return { language, translations };
  }

  render() {
    const { language, children } = this.props;
    return (
      <IntlProvider
        key={language} // used to ensure rerender on locale change
        defaultLocale={defaultLanguage}
        locale={language}
        messages={translations[language]}
      >
        {children}
      </IntlProvider>
    );
  }
}

I18nProvider.childContextTypes = childContextTypes;
I18nProvider.propTypes = propTypes;

export default connect(mapStateToProps)(I18nProvider);
