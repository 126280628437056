import { setDashboardsConfig } from './actionCreators';
import {
  ADD_DASHBOARD_BADGE,
  ADD_DASHBOARD_WIDGET,
  BOOTSTRAP_DASHBOARDS,
  CREATE_DASHBOARD,
  DELETE_DASHBOARD,
  REMOVE_DASHBOARD_BADGE,
  REMOVE_DASHBOARD_WIDGET,
  UPDATE_DASHBOARD_BADGES,
  UPDATE_DASHBOARD_WIDGET_SETTINGS,
  UPDATE_DASHBOARD_WIDGET_TITLE,
  UPDATE_DASHBOARD_WIDGETS,
} from './actionTypes';
import { GEO_UI_DASHBOARD } from './constants';
import globalSelectors from './globalSelectors';

const PERSIST_DASHBOARDS_CONFIG_ACTIONS = [
  ADD_DASHBOARD_BADGE,
  ADD_DASHBOARD_WIDGET,
  CREATE_DASHBOARD,
  DELETE_DASHBOARD,
  REMOVE_DASHBOARD_BADGE,
  REMOVE_DASHBOARD_WIDGET,
  UPDATE_DASHBOARD_BADGES,
  UPDATE_DASHBOARD_WIDGET_SETTINGS,
  UPDATE_DASHBOARD_WIDGET_TITLE,
  UPDATE_DASHBOARD_WIDGETS,
];

export default function dashboardMiddleware() {
  return ({ dispatch, getState }) => {
    return (next) => (action) => {
      const result = next(action);

      if (action.type === BOOTSTRAP_DASHBOARDS) {
        const dashboardsConfigString =
          window.localStorage && window.localStorage.getItem(GEO_UI_DASHBOARD);

        if (dashboardsConfigString) {
          const configObject = JSON.parse(dashboardsConfigString);
          dispatch(setDashboardsConfig(configObject));
        }
      }

      if (PERSIST_DASHBOARDS_CONFIG_ACTIONS.includes(action.type)) {
        window.localStorage &&
          window.localStorage.setItem(
            GEO_UI_DASHBOARD,
            JSON.stringify(globalSelectors.getDashboards(getState())),
          );
      }

      return result;
    };
  };
}
