import { useEffect, useState } from 'react';

import { useRequestWithPromise } from 'src/core/api';
import { fetchClientGroup } from 'src/entities/clientGroups';
import { fetchPlaces } from 'src/entities/places';
import { fetchMediaBuilds } from 'src/entities/mediaBuilds';
import { fetchSchemes } from 'src/entities/schemes';
import { fetchPlaylists } from 'src/entities/playlists';
import { fetchMediaObjects } from 'src/entities/media';
import { useEnsurePlacesWithGeo } from './useEnsurePlacesWithGeo';


export const useSimulationData = (visiblePlaces) => {
  const { dispatch } = useRequestWithPromise();
  const [loading, setLoading] = useState(true);

  // Make sure to load full version of any selected places
  useEnsurePlacesWithGeo(visiblePlaces);

  useEffect(() => {
    const doFetch = async () => {
      await Promise.all([
        dispatch(fetchClientGroup()),
        dispatch(fetchMediaBuilds()),
        dispatch(fetchPlaces()),
        dispatch(fetchSchemes()),
        dispatch(fetchMediaObjects()),
        dispatch(fetchPlaylists()),
      ]);

      setLoading(false);
    };
    doFetch();
  }, []);

  return loading;
};
