import { ADD_DASHBOARD_BADGE } from '../actionTypes';
import { PRIMARY_DASHBOARD_ID } from '../constants';

export default function addDashboardBadge(
  { badgeType, dashboardId = PRIMARY_DASHBOARD_ID } = {},
) {
  return {
    payload: { dashboardId, badgeType },
    type: ADD_DASHBOARD_BADGE,
  };
}
