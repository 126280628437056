import {
  API_BASE_PATH, API_DELETE_REQUEST, API_GET_REQUEST, API_POST_REQUEST, API_PUT_REQUEST,
} from '../constants';
import { appendSlashIfMissing, getCsrfToken } from './helpers';
import { del } from './del';
import { get } from './get';
import { post } from './post';
import { put } from './put';

export { getHttpMethod } from './helpers';

export async function apiRequest({ type, payload: body, meta = {} }) {
  const { entityId, queryParams, url: basePath } = meta;

  const path = entityId ? `${appendSlashIfMissing(basePath)}${entityId}` : basePath;
  const url = `${API_BASE_PATH}${path}`;

  if (type.includes(API_GET_REQUEST)) {
    return get({ queryParams, url });
  }

  const csrfToken = getCsrfToken();

  if (type.includes(API_DELETE_REQUEST)) {
    return del({ csrfToken, url });
  }

  if (type.includes(API_POST_REQUEST)) {
    return post({ body, csrfToken, url });
  }

  if (type.includes(API_PUT_REQUEST)) {
    return put({ body, csrfToken, url });
  }

  return Promise.reject(new Error(`Unknown API request type: ${type}`));
}
