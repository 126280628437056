import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  bodyClass: PropTypes.string,
};

function SidebarContent({
  footer,
  children,
  className = '',
  header,
  headerClass = 'modal-header flex-shrink no-gutters',
  bodyClass = '',
  footerClass = 'modal-footer justify-content-start flex-shrink no-gutters',
  onClose,
  inSidebar = true,
  inModal,
}) {
  const outerClassName = `SidebarContent nnw-1r bg-white col-no-gutter ${inSidebar ? 'd-flex flex-column' : ''} ${className}`;
  const innerClassName = `col ${footer ? 'pt-0 px-0 pb-3' : 'p-0'} ${bodyClass}${(inSidebar || inModal) ? ' captureScroll scroll-box ' : ''}`;
  return (
    <div
      style={{ height: '100%' }}
      className={outerClassName}
    >
      {!!header && (
        <div className={headerClass}>
          {typeof header === 'string'
            ? <h5 className="modal-title">{header}</h5>
            : header
          }
          {inModal && (
            <button
              type="button"
              className="close noprint"
              aria-label="Close"
              onClick={onClose}
            >
              <span aria-hidden="true">
                {'\u00D7'}
              </span>
            </button>
          )}
        </div>
      )}
      <div
        className={innerClassName}
      >
        {children}
      </div>
      {!!footer && (
        <div className={footerClass}>
          {footer}
        </div>
      )}
    </div>
  );
}

SidebarContent.propTypes = propTypes;

export default SidebarContent;
