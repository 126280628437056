import React from 'react';
import { DropdownContext } from './Dropdown';

function DropdownContextConsumer(Component) {
  return (props) => {
    return (
      <DropdownContext.Consumer>
        {({
          open,
          right,
          handleClose,
          handleContentClick,
          handleToggle,
          handleFocus,
        } = {}) => (
          <Component
            open={open}
            right={right}
            onClick={handleContentClick}
            onClose={handleClose}
            handleToggle={handleToggle}
            handleFocus={handleFocus}
            {...props}
          />
        )}
      </DropdownContext.Consumer>
    );
  };
}

export default DropdownContextConsumer;
