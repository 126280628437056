import React, { useRef } from 'react';
import {
  getCaretPosition,
  focusElement,
  cursorAtEndOfInput,
  cursorAtStartOfInput,
} from './contentEditableUtils';

export const KeyEventHandler = ({ children, removeValueAt, onChangeActive }) => {
  const ref = useRef();

  const findTarget = (e) => {
    let el = e.target;
    while (el.parentNode && el.parentNode !== ref.current) {
      el = el.parentNode;
    }
    return el;
  };

  const handleKey = (e) => {
    const target = findTarget(e);
    const nodes = Array.from(ref.current.childNodes);
    const currentElIdx = nodes.indexOf(target);
    const isTextInput = target.contentEditable === 'true';
    const caretPosition = isTextInput && getCaretPosition(target);
    const inputLength = (isTextInput && target.firstChild?.length) || 0;
    const isInput = e.target instanceof HTMLInputElement; // Input inside combobox

    // eslint-disable-next-line default-case
    switch (e.key) {
    case 'Backspace':
      // If not text input, or at the first position in input
      if ((!isTextInput && !isInput) || (isInput && cursorAtStartOfInput())) {
        const nodeBeforeRemoved = nodes[currentElIdx - 2];
        removeValueAt(currentElIdx);
        focusElement(nodeBeforeRemoved || nodes[0], true);
        onChangeActive([
          nodeBeforeRemoved ? currentElIdx - 2 : 0,
          -1,
        ]);
        e.preventDefault();
      } else if (currentElIdx > 0 && caretPosition === 0) {
        focusElement(nodes[currentElIdx - 1], true);
        onChangeActive([currentElIdx - 1, -1]);
      } else {
        onChangeActive([currentElIdx, caretPosition - 1]);
      }
      break;
    case 'Delete':
      if (!isTextInput) {
        const nodeBeforeRemoved = nodes[currentElIdx - 1];
        removeValueAt(currentElIdx);
        focusElement(nodeBeforeRemoved || nodes[0]);
      } else if (isTextInput) {
        focusElement(nodes[currentElIdx + 1]);
        onChangeActive([currentElIdx + 1, 0]);
      }
      break;
    case 'ArrowLeft':
      // If not a text input, or at first position in input
      if (
        (!isTextInput && !isInput)
        || (isInput && cursorAtStartOfInput())
        || (caretPosition === 0)
      ) {
        focusElement(nodes[currentElIdx - 1], true);
        onChangeActive([currentElIdx - 1, -1]);
      } else {
        onChangeActive([currentElIdx, caretPosition - 1]);
      }
      break;
    case 'ArrowRight':
      // If not a text input, or at last position in input
      if (
        (!isTextInput && !isInput)
        || (isInput && cursorAtEndOfInput())
        || caretPosition === inputLength
      ) {
        focusElement(nodes[currentElIdx + 1]);
        onChangeActive([currentElIdx + 1, 0]);
      } else {
        onChangeActive([currentElIdx + 1, caretPosition + 1]);
      }
      break;
    default:
      onChangeActive([currentElIdx, caretPosition]);
    }
  };

  return (
    <div
      ref={ref}
      role="presentation"
      className="d-flex flex-wrap rounded p-2 border bg-light"
      onKeyDown={handleKey}
    >
      {children}
    </div>
  );
};
