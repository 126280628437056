import Immutable   from 'immutable';

// DELETE THIS FILE LATER

const types = {};
types.SELECT 		= '@PLAYLIST.SELECT';
types.DESELECT 		= '@PLAYLIST.DESELECT';
types.SETSELECTED 	= '@PLAYLIST.SETSELECTED';

const defaultState = Immutable.Map({
    selected    : Immutable.Set()
});

function select(toSelect, selected) {
    if (typeof toSelect === 'string' || typeof toSelect === 'number') {
        return selected.add(toSelect);
    } else if (Immutable.Set.isSet(toSelect)) {
        return selected.union(toSelect);
    }

    console.log('Error. Cannot select:');
    console.log(toSelect);
    return selected;
}

function deselect(toSelect, selected) {
    if (typeof toSelect === 'string' || typeof toSelect === 'number') {
        return selected.delete(toSelect);
    } else if (Immutable.Set.isSet(toSelect)) {
        return selected.subtract(toSelect);
    }

    console.log('Error. Cannot deselect:');
    console.log(toSelect);
    return selected;
}

const PLTEMPReducer = function (state = defaultState, action) {
    switch (action.type) {
        case types.SELECT : return state.set('selected', select(action.payload, state.get('selected')));
        case types.DESELECT : return state.set('selected', deselect(action.payload, state.get('selected')));
        case types.SETSELECTED :
            return state.set('selected', action.payload);

        default :
            return state;
    }
};


export const actions = {};

actions.select = function (id) {
	return {
		type 	: types.SELECT,
		payload : id
	};
};

actions.deselect = function (id) {
	return {
		type 	: types.DESELECT,
		payload : id
	};
};

actions.selectAll = function (ids) {
	return {
		type 	: types.SETSELECTED,
		payload : ids || Immutable.Set()
	};
};

export default PLTEMPReducer;