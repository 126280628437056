import React from 'react';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error, errorInfo) {
    this?.setState({
      error,
      errorInfo,
    });
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const {
      error,
      errorInfo,
    } = this.state;

    if (this.state.error) {
      // You can render any custom fallback UI
      return (
        <div className="alert alert-danger">
          <h2>
            {'Oh no. Something went wrong.'}
          </h2>
          <pre className="mb-3">
            {error && error.toString()}
          </pre>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            <br />
            {errorInfo.componentStack}
          </details>
        </div>
      );
    }

    return this.props.children;
  }
}
