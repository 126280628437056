import Dropdown, { DropdownContext } from './Dropdown';
import Toggle from './DropdownToggle';
import ContentView, { PositionedDropdownContent } from './DropdownContent';
import Nestled from './NestledDropdown';
import DropdownContextConsumer from './DropdownContextConsumer';

export const DropdownToggle = Toggle;
export const DropdownContent = DropdownContextConsumer(PositionedDropdownContent);
export const NestledDropdown = Nestled;
export const DropdownContentView = ContentView;

export { Dropdown, DropdownContext, DropdownContextConsumer };
export default Dropdown;
