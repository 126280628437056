import { orderBy } from 'lodash';
import moment from 'moment';
import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { DropdownList } from 'react-widgets';

import { getMediaBuildsInObject } from 'src/entities/mediaBuilds';
import { setBuildId, SimulationContext } from './StateManager';

const useFormattedBuilds = () => {
  const intl = useIntl();
  const mediaBuilds = useSelector(getMediaBuildsInObject);

  return useMemo(() => {
    const builds = Object.values(mediaBuilds || {}).map((build) => {
      return {
        ...build,
        displayName: intl.formatMessage(
          { id: 'views.simulation.build' },
          {
            date: moment(build.build_schedule).format('YYYY-MM-DD HH:mm'),
            id: build.id,
          },
        ),
      };
    });

    return [
      {
        displayName: intl.formatMessage({ id: 'views.simulation.currentBuild' }),
        id: null,
      },
      ...orderBy(builds, 'build_schedule', 'desc'),
    ];
  }, [mediaBuilds]);
};

export const BuildPicker = () => {
  const [, dispatch] = useContext(SimulationContext);
  const builds = useFormattedBuilds();

  const handleBuildChange = (build) => {
    dispatch(setBuildId(build.id));
  };

  return (
    <DropdownList
      data={builds}
      onChange={handleBuildChange}
      defaultValue={builds[0]}
      textField="displayName"
      dataKey="id"
    />
  );
};
