import React, { useState } from 'react';
import { Multiselect } from 'react-widgets';
import { useIntl } from 'react-intl';

function QuickSelectRow({ item }) {
  const title = `${item.name}`;
  return (
    <div
      title={title}
      className={`row-content ${item.isPlanned ? 'warning' : ''}`}
    >
      {item.name}
    </div>
  );
}

export const QuickSelect = ({
  onMediaSelected, media, selected, disabled,
}) => {
  const { formatMessage } = useIntl();
  const [isOpen, setOpen] = useState();

  const onChange = (v) => {
    onMediaSelected(v[0]);
    setOpen(false);
  };

  const filter = (mediaObject, value) => {
    return mediaObject.name.toLowerCase().indexOf(value.toLowerCase()) >= 0;
  };

  const getMedia = () => {
    if (isOpen) {
      const m = media.toJS ? media.toJS() : media;
      return m.map((mediaObject) => {
        return {
          ...mediaObject,
          isPlanned: selected?.has(mediaObject.id),
        };
      });
    }
    return [];
  };

  return (
    <Multiselect
      data-testid="planning-quick-add-media"
      style={{ width: 320 }}
      data={getMedia()}
      renderListItem={QuickSelectRow}
      value={[]}
      onToggle={setOpen}
      open={isOpen}
      filter={filter}
      placeholder={`+ ${formatMessage({
        id: 'views.planning.playlists.typeHereToQuickAdd',
      })}`}
      onChange={onChange}
      disabled={disabled}
      dataKey="id"
      textField="name"
    />
  );
};
