import React from 'react';
import SortUtil from 'js/react/_utils/sort-util';
import PlaylistRow from './row';
import ListHeader from './list-header';


class PlaylistListView extends SortUtil {
  renderRows() {
    const { date } = this.props;
    const templates = this.props.templates || {};
    return this.state.items.map((item) => {
      const id = item.get('id');
      const tmpl = templates[id] || [];
      return (
        <PlaylistRow
          date={date}
          key={id}
          item={item}
          templates={tmpl}
          fromDate={this.props.fromDate}
          toDate={this.props.toDate}
          planLocked={this.props.planLocked}
        />
      );
    }).toArray();
  }

  render() {
    return (
      <div className="table_container">
        <table
          className={`table table-fixed mb-0 table-hover table-sm ${this.props.planLocked ? 'disabled' : ''}`}
          data-testid="PlaylistList"
        >
          <ListHeader
            asc={this.state.asc}
            sortBy={this.state.sortBy}
            onClick={this.handleSort}
            planLocked={this.props.planLocked}
          />

          <tbody key="list">
            {this.renderRows()}
          </tbody>
        </table>
      </div>
    );
  }
}

export default PlaylistListView;
