/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import TextField from './FormWidgets/TextField';
import { TextArea } from './FormWidgets/TextArea';
import NumberField from './FormWidgets/NumberField';
import ColorPicker from './FormWidgets/ColorPicker';
import Checkbox from './FormWidgets/Checkbox';
import { OptionSelect } from './FormWidgets/OptionSelect';
import { TextList } from './FormWidgets/TextList';
import { URICallback } from './FormWidgets/URICallback';
import { ReadOnly } from './FormWidgets/ReadOnly';

const SettingsFactory = ({ type, ...other }) => {
  switch (type) {
    case 'number':
      return <NumberField {...other} />;
    case 'text':
      return <TextField {...other} />;
    case 'textarea':
      return <TextArea {...other} />;
    case 'checkbox':
      return <Checkbox {...other} />;
    case 'color':
      return <ColorPicker {...other} />;
    case 'select':
      return <OptionSelect {...other} />;
    case 'text-array':
      return <TextList {...other} />;
    case 'uri-callback':
      return <URICallback {...other} />;
    case 'read-only':
      return <ReadOnly {...other} />;
    default:
      return null;
  }
};

export default SettingsFactory;
