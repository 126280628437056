import isUndefined from 'lodash/isUndefined';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { FormattedMessage } from 'react-intl';
import debug from 'debug';
import moment from 'moment';
import { parseISODateTime } from 'tzdateutils';

import Store from 'js/stores/ajax-store';
import HelpIcon from 'components/HelpIcon';
import GSColumnsLayout from 'components/GSColumnsLayout';
import GsSideBar from 'utils/right-sidebar';
import FeatureMap from '../map/map.jsx';
import HistoryFilter from './history-filter';
import HistorySidebar from './history-media-sidebar';
import HistoryList from './history-list';
import { ISO_DATE_WITHOUT_TIMEZONE } from "constants/dates";

const HistoryStore = Store('/monitor/history');
const ClientStore = Store('/clients');
const log = debug('js:react:history:history');

function actions2Query(actions, displayCount) {
  let str = '';
  actions.map((value, key) => {
    if (!isUndefined(value.size) && value.size > 0) {
      str += `${key}=${value.toJS().toString()}&`;
    } else {
      if (key === 'search') {
        key = 'freetext_media';
      }
      str += `${key}=${value}&`;
    }
  });

  str += `displayCount=${displayCount}`;

  return str;
}

function buildAndTriggerRoute(history, date, buses = [], search = '') {
  let url = '/history/end/';

  url += moment(date).format(ISO_DATE_WITHOUT_TIMEZONE);

  if (buses.length > 0) {
    url += `/buses/${buses.toString()}`;
  }

  if (search !== '') {
    url += `/search/${search}`;
  }

  history.push(url);
}

const propTypes = {
  actions: PropTypes.string,
};
const defaultProps = {
  actions: '',
};

class HistoryComp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      clients: Immutable.List(),
      displayCount: '100',
      highlight: -1,
      history: Immutable.List(),
      loading: false,
      mapData: Immutable.List(),
      showMediaId: null,
    };
  }

  UNSAFE_componentWillMount() {
    this.loadData(this.getActionArray(this.props.match.params['actions'] || ''));
  }

  componentDidMount() {
    buildAndTriggerRoute(this.props.history, new Date());
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.match.params['actions'] !== this.props.match.params['actions'] ||
      prevState.displayCount !== this.state.displayCount
    ) {
      this.loadData(this.getActionArray(this.props.match.params['actions'] || ''));
    }
  }

  loadData = (actions) => {
    const query = actions2Query(actions, this.state.displayCount);
    this.setState({ loading: true });
    if (query.length > 1) {
      Promise.all([HistoryStore.fetch(query), ClientStore.fetch()]).then(this.dataLoaded);
    }
  };

  dataLoaded = ([history, clients]) => {
    this.setState({
      history,
      clients,
      loading: false,
      mapData: (history || Immutable.List()).map((h) => {
        return Immutable.fromJS({
          id: h.get('id'),
          wkt: h.getIn(['geometry', 'wkt']),
        });
      }),
    });
  };

  /*
        Methods
    */
  getActionArray = (actionStr) => {
    const tmpArr = actionStr.split('/');
    const len = tmpArr.length;
    const resArr = {};
    for (let i = 0; i < len; i += 2) {
      resArr[tmpArr[i]] = isUndefined(tmpArr[i + 1]) ? '' : tmpArr[i + 1];
      if (tmpArr[i] === 'buses') {
        resArr[tmpArr[i]] = Immutable.List(resArr[tmpArr[i]].split(','));
      }
    }

    return Immutable.Map(resArr);
  };

  onHover = (highlight) => {
    this.setState({ highlight });
  };

  onClick = (item) => {
    this.setState({ center: item.getIn(['geometry', 'wkt']) });
  };

  onTitleClick = (id) => {
    this.setState({ showMediaId: id });
  };

  onDisplayCountChange = (displayCount) => {
    if (displayCount !== this.state.displayCount) {
      this.setState({ displayCount });
    }
  };

  renderLeftCol() {
    const actions = this.getActionArray(this.props.match.params['actions'] || '');
    const search = actions.get('search');
    const selectedClients = actions.get('buses') || Immutable.Set();
    const actionDate = actions.get('end');
    const date = (actionDate && parseISODateTime(actionDate)) || new Date(); // eslint-disable-line no-undef

    const onFilterChange = (date, buses, search) => buildAndTriggerRoute(this.props.history, date, buses, search);

    return (
      <React.Fragment>
        <div className="pa15">
          <h3 className="mt-0">
            <FormattedMessage id="common.history" />
            <HelpIcon
              bodyKey="help.history.body"
              headerKey="help.history.header"
            />
          </h3>
          <HistoryFilter
            clients={this.state.clients}
            date={date}
            search={search}
            selectedClients={selectedClients.toSet()}
            displayCount={this.state.displayCount}
            onDisplayCountChange={this.onDisplayCountChange}
            onFilterChange={onFilterChange}
          />
        </div>
        <HistoryList
          history={!this.state.loading && this.state.history}
          onHover={this.onHover}
          onClick={this.onClick}
          onTitleClick={this.onTitleClick}
          highlight={this.state.highlight}
        />
        <div className="pa10">
          {this.state.loading && (
            <h4>
              <FormattedMessage id="views.history.loadingHistoryData" />
              ...
            </h4>
          )}
          {!this.state.loading &&
            this.state.history.size === 0 && (
              <h4>
                <FormattedMessage id="views.history.noHistoryDataFound" />
              </h4>
            )}
        </div>
      </React.Fragment>
    );
  }

  renderRightCol() {
    return (
      <FeatureMap
        features={this.state.mapData}
        onHover={this.onHover}
        highlight={this.state.highlight}
        center={this.state.center}
      />
    );
  }

  render() {
    log('render');
    return (
      <GSColumnsLayout
        leftCol={this.renderLeftCol()}
        rightCol={this.renderRightCol()}
        sidebar={(
          <GsSideBar
            href={window.location.hash}
            onClose={() => {
              this.onTitleClick(null);
            }}
          >
            {this.state.showMediaId !== null && (
              <HistorySidebar
                id={this.state.showMediaId}
                onClose={() => this.onTitleClick(null)}
              />
            )}
          </GsSideBar>
        )}
      />
    );
  }
}

HistoryComp.propTypes = propTypes;
HistoryComp.defaultProps = defaultProps;

export default HistoryComp;
