import { API_REQUEST_SUCCESS } from '../../core/api';
import { fetchAnnouncement } from './actionCreators';
import { ANNOUNCEMENTS_URL } from './constants';

const announcementUpdateMw =
  ({ dispatch }) => (next) => async (action) => {
    // Dispatch next action first to get the log sequence right.
    const value = next(action);

    if (
      action.type.includes(API_REQUEST_SUCCESS) &&
      action.meta?.url === ANNOUNCEMENTS_URL &&
      action.meta.httpMethod === 'PUT'
    ) {
      // '/media/pa/' returns a media object
      // so we need to manually fetch updated announcement.
      const {
        payload: { id },
      } = action;
      dispatch(fetchAnnouncement(id));
    }

    // Older async actions may depend on the returned value.
    return value;
  };

export const mediaMiddlewares = [announcementUpdateMw];
