import React from 'react';
import { FormattedMessage } from 'react-intl';
import LatestMediaBuilds from 'js/react/admin/Health/LatestMediaBuilds';
import ClientGroupList from './ClientGroupList';

export const PageHeader = () => {
  return (
    <div className="row">
      <div className="d-flex mb-4 col-12 col-md-4 col-xl-3 no-gutters">
        <LatestMediaBuilds
          className="col bg-white rounded p-3"
        >
          <h2 className="mb-3">
            <FormattedMessage id="views.admin.mediaBuild.executedBuilds" />
          </h2>
        </LatestMediaBuilds>
      </div>
      <div className="d-flex mb-4 col-12 col-md-4 col-xl-3 no-gutters">
        <LatestMediaBuilds
          className="col bg-white rounded p-3"
          upcoming
        >
          <h2 className="mb-3">
            <FormattedMessage id="views.admin.mediaBuild.upcomingBuilds" />
          </h2>
        </LatestMediaBuilds>
      </div>
      <div className="d-flex mb-4 col-12 col-md-4 col-xl-3 no-gutters">
        <ClientGroupList
          className="col bg-white rounded d-flex flex-column"
        />
      </div>
    </div>
  );
};
