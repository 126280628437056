export function getCaretPosition() {
  if (window.getSelection && window.getSelection().getRangeAt) {
    const range = window.getSelection().getRangeAt(0);
    const selectedObj = window.getSelection();
    let rangeCount = 0;
    const { childNodes } = selectedObj.anchorNode.parentNode;
    for (let i = 0; i < childNodes.length; i++) {
      if (childNodes[i] === selectedObj.anchorNode) {
        break;
      }
      if (childNodes[i].nodeType === 3) {
        rangeCount += childNodes[i].textContent.trim().length;
      }
    }
    return range.startOffset + rangeCount;
  }
  return -1;
}

export function placeCaretAtEnd(el) {
  el.focus();
  if (
    typeof window.getSelection !== 'undefined'
    && typeof document.createRange !== 'undefined'
  ) {
    const range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (typeof document.body.createTextRange !== 'undefined') {
    const textRange = document.body.createTextRange();
    textRange.moveToElementText(el);
    textRange.collapse(false);
    textRange.select();
  }
}

export const focusElement = (el, end) => {
  if (el && el.contentEditable === 'true') {
    end ? placeCaretAtEnd(el) : el.focus();
  } else if (el) {
    const focusable = el.getElementsByTagName('input')[0] || el.getElementsByTagName('button')[0];
    focusable?.focus();
  }
};

export const cursorAtEndOfInput = () => {
  return document.activeElement
    && document.activeElement.selectionStart >= document.activeElement.value?.length - 1;
};

export const cursorAtStartOfInput = () => {
  return !document.activeElement?.selectionStart;
};
