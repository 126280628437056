import { buildApiMetaFactory, buildGetType, buildPostType } from 'src/core/api';
import { extendPayloadActionEnhancer } from 'src/core/data';
import { COLLECTION } from './constants';

// export const {
//   create: createPlace,
//   readAll: fetchPlaces,
//   readById: fetchPlace,
//   update: updatePlace,
//   destroy: deletePlace,
// } = createCrudActionCreators({ collection: COLLECTION, url: URL });

export const fetchPlaylistPlaces = (playlistId) => {
  const buildApiMeta = buildApiMetaFactory({
    collection: COLLECTION,
    url: `/playlists/${playlistId}/places`,
  });

  return extendPayloadActionEnhancer({
    type: buildGetType(COLLECTION),
    meta: buildApiMeta({ reset: true }),
  }, {
    playlistId,
  });
};

export const createPlaylistPlace = (playlistId, payload) => {
  const buildApiMeta = buildApiMetaFactory({
    collection: COLLECTION,
    url: `/playlists/${playlistId}/places`,
  });

  return extendPayloadActionEnhancer({
    payload,
    type: buildPostType(COLLECTION),
    meta: buildApiMeta({ reset: true }),
  }, {
    playlistId,
  });
};
