import { createCrudActionCreators } from 'src/core/api';
import { URL, COLLECTION } from './constants';

export const {
  create: createPlaylist,
  readAll: fetchPlaylists,
  readById: fetchPlaylist,
  update: updatePlaylist,
  destroy: deletePlaylist,
} = createCrudActionCreators({ collection: COLLECTION, url: URL });
