/** @js */
/* global require, module */

const stringUtils = {

  // Converts seconds to string in format MM:SS
  toMMSS(s, moreThan60MinAllowed = false) {
    const d = Number(s);
    const minutes = moreThan60MinAllowed
      ? Math.floor(d / 60)
      : Math.floor(d % 3600 / 60);
    const seconds = Math.floor(d % 3600 % 60);
    return (`${(minutes < 10 ? '0' : '') + minutes}:${seconds < 10 ? '0' : ''}${seconds}`);
  },

  // Returns string w first letter in upper case.
  capitalize(s) {
    if (typeof s === 'string') {
      return s.charAt(0).toUpperCase() + s.substring(1);
    }

    return s;
  },

  // Returns string with every word capitalized
  toTitleCase(str) {
    return str.replace(/\w\S*/g, (txt) => { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
  },
};

export default stringUtils;
