import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import UserRow from './UserRow';

const propTypes = {
  groups: PropTypes.array,
  onSelect: PropTypes.func,
  selectAll: PropTypes.func,
  selected: PropTypes.array,
  updateUserGroup: PropTypes.func.isRequired,
  updateUserName: PropTypes.func.isRequired,
  updateUserPassword: PropTypes.func.isRequired,
  users: PropTypes.array,
};

const defaultProps = {};

function UserList({
  groups,
  onSelect,
  selectAll,
  selected,
  updateUserGroup,
  updateUserName,
  updateUserPassword,
  users,
}) {
  const allSelected = users.length === selected.length;

  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th style={{ width: '5%' }}>
            <input type="checkbox" checked={allSelected} onChange={selectAll} className="align-middle" />
          </th>
          <th>
            <FormattedMessage id="common.name" />
          </th>
          <th>
            <FormattedMessage id="common.group" />
          </th>
          <th>
            <FormattedMessage id="views.admin.userManagement.password" />
          </th>
        </tr>
      </thead>
      <tbody data-testid="user-table-body">
        {users && users.map((user) => (
          <UserRow
            key={user.id}
            groups={groups}
            onSelect={onSelect}
            selected={selected.indexOf(user.id) !== -1}
            updateUserGroup={updateUserGroup(user.id)}
            updateUserName={updateUserName(user.id)}
            updateUserPassword={updateUserPassword(user.id)}
            user={user}
          />
        ))}
      </tbody>
    </table>
  );
}

UserList.propTypes = propTypes;
UserList.defaultProps = defaultProps;

export default UserList;
