import React from 'react';
import { DropdownList } from 'react-widgets';
import { useIntl } from 'react-intl';

export const ClientGroupSelector = React.memo(({ selected, clientGroups, onChange }) => {
  const intl = useIntl();
  const ids = Object.keys(clientGroups);

  const handleChange = ({ id } = {}) => {
    id && onChange(id);
  };

  const data = ids.map((id) => ({
    id,
    name: clientGroups[id].name,
  }));

  return (
    <DropdownList
      data-testid="clients-group-select"
      className="mr-2 w200"
      data={data}
      textField="name"
      dataKey="id"
      value={selected && selected.toString()}
      onChange={handleChange}
      placeholder={intl.formatMessage({
        id: 'views.admin.health.client_group',
      })}
    />
  );
});
