import React from 'react';

var convertType2Icon = function(type) {
	switch(type) {
		case 'repeating':
			return <i title="Repeating Playlist" className="icon icon-repeat"></i>;
		case 'direct':
			return <i title="Direct Playlist" className="icon icon-play-circle"></i>;
		case 'driver' :
			return <i title="Driver Playlist" className="icon icon-road"></i>;
		default:
			return '';
	}
};


export default convertType2Icon;