import React from 'react';
import createClass from 'create-react-class';
import Immutable from 'immutable';
import { Multiselect } from 'react-widgets';
import { FormattedMessage, injectIntl } from 'react-intl';

const FilterTag = (props) => {
  return (
    <span className="filter-tag" title={props.item}>
      {props.item}
    </span>
  );
};

const Filter = createClass({
  getInitialState() {
    return { customFilters: [] };
  },

  render() {
    const { props } = this;
    const onChange = (d) => {
      props.onChange(Immutable.Set(d));
    };

    const selectAll = () => {
      props.onChange(Immutable.Set(props.data));
    };

    return (
      <div className={this.props.className || ''}>
        <p className="cap select-label">
          {props.name}
          {props.data.length > 0 && (
            <span>
              {'('}
              {props.data.length}
              {')'}
            </span>
          )}
          {':'}
          {props.selectAll && (
            <a className="small right" onClick={selectAll}>
              <FormattedMessage id="views.report.selectAll" />
            </a>
          )}
        </p>
        <Multiselect
          className=""
          disabled={props.data.length === 0}
          data={props.data}
          value={props.filteredData}
          filter="contains"
          tagComponent={FilterTag}
          onChange={onChange}
        />
      </div>
    );
  },
});

export default Filter;
