import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import SchemePicker from 'components/SchemePicker';
import SingleMediaPicker from 'components/MediaPicker/SingleMediaPicker';
import { AREA_DEFAULT_STYLE, LAYOUT_AREA_MEDIA, SCHEME } from 'js/react/ClientGroups/_utils';
import {
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_WIDGET_HTML,
  MEDIA_TYPE_WIDGET_WEBLINK,
  MEDIA_TYPE_CUSTOM_WIDGET,
} from 'constants/mediaTypes';
import { useDefaultScheme } from '../hooks/useDefaultScheme';

const allowedTypes = [
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_WIDGET_HTML,
  MEDIA_TYPE_WIDGET_WEBLINK,
];

function mediaFilter({ media_type: mediaType }) {
  return allowedTypes.includes(mediaType) || mediaType.includes(MEDIA_TYPE_CUSTOM_WIDGET);
}

const TypeSelect = ({
  id, contentType, updateArea, defaultSchemeId,
}) => {
  const { formatMessage } = useIntl();
  const htmlFor = `schemeSelect${id}`;

  const handleType = (e) => {
    const type = e.target.value;
    if (type !== contentType) {
      updateArea({
        contentType: type,
        contentId: contentType === SCHEME
          ? defaultSchemeId
          : undefined,
        style: AREA_DEFAULT_STYLE,
      });
    }
  };

  return (
    <>
      <label
        htmlFor={htmlFor}
        className="align-middle mb-0 mr-2"
      >
        <FormattedMessage id="common.type" />
        {':'}
      </label>
      <select
        id={htmlFor}
        className="form-control mr-3"
        onChange={handleType}
        value={contentType || ''}
      >
        <option value="">
          {formatMessage({ id: 'common.noOption' })}
        </option>
        <option value="scheme">
          {formatMessage({ id: 'common.scheme' })}
        </option>
        <option value="media">
          {formatMessage({ id: 'common.media' })}
        </option>
      </select>
    </>
  );
};

export const AreaRow = ({
  id,
  defaultMode,
  selectedSchemes,
  settings: { contentType, contentId } = {},
  handleStyleSettings,
  updateArea,
}) => {
  const { formatMessage } = useIntl();
  const defaultSchemeId = useDefaultScheme();
  const excludes = useMemo(() => {
    // eslint-disable-next-line eqeqeq
    return selectedSchemes.filter((sid) => sid != contentId);
  }, [selectedSchemes, contentId]);

  const handleContent = (cId) => {
    updateArea({
      contentId: contentType === SCHEME && !cId
        ? defaultSchemeId
        : cId,
    });
  };

  const renderSchemeOptions = () => {
    return (
      <SchemePicker
        value={contentId}
        onChange={(sId) => handleContent(sId || defaultSchemeId)}
        excludes={excludes}
        defaultMessage={defaultMode && formatMessage({ id: 'views.layout.useDefaultScheme' })}
      />
    );
  };

  if (defaultMode) {
    return (
      <div className="row no-gutters p-2 border-bottom align-items-center justify-content-between">
        <div className="col d-flex form-inline">
          {renderSchemeOptions()}
        </div>
      </div>
    );
  }

  return (
    <div className="row no-gutters p-2 border-bottom align-items-center justify-content-between">
      <div className="col-1 text-center">
        <h2>{id}</h2>
      </div>
      <div className="col d-flex form-inline">
        <TypeSelect
          id={id}
          contentType={contentType}
          updateArea={updateArea}
          defaultSchemeId={defaultSchemeId}
        />
        <div className="col min-width-1 pl-0">
          {contentType === SCHEME && renderSchemeOptions()}
          {contentType === LAYOUT_AREA_MEDIA && (
            <SingleMediaPicker
              onlyPicker
              selected={contentId}
              onChange={handleContent}
              mediaFilter={mediaFilter}
            />
          )}
        </div>
        <div>
          <button
            type="button"
            className="btn btn-light border float-right"
            onClick={handleStyleSettings}
          >
            <i className="icon-cog" />
          </button>
        </div>
      </div>
    </div>
  );
};
