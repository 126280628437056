/*global module, require */


// Playlist formatters

var playlist = {};

playlist.typeIcon = function (type) {
    switch(type) {
        case 'direct':
            return 'icon-play-circle';
        case 'driver' :
            return 'icon-road';
        default:
            return 'icon-repeat';
    }
};

playlist.typeDescr = function (type) {
    if (type === 'direct') {
        return 'Direct Playlist';
    }
    return 'Repeating Playlist';
};


// General formatting
var formatSeconds = function (time) {
    var neg = '';
    if (time < 0) {
        time = -time;
        neg = '-';
    }
    var mins = Math.floor(time / 60);
    var secs = Math.floor(time % 60);
    if (secs < 10) {
        secs = '0' + secs;
    }
    if (mins < 10) {
        mins = '0' + mins;
    }
    return neg + mins + ':' + secs;
};


export default {
	playlist,
	formatSeconds
}

