import produce from 'immer';
import {
  SET_ONGOING,
  REMOVE_ONGOING,
} from './actionTypes';

const defaultState = {};

export const reducer = produce((draft, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
  case SET_ONGOING:
    draft[action.payload.requestId] = action.payload;
    break;
  case REMOVE_ONGOING:
    delete draft[action.payload];
    break;
  }
}, defaultState);
