/*
* Here we combine the module reducers into one reducer
*/

import { combineReducers } from 'redux';

import { REDUCER_NAME as API, reducer as apiReducer } from 'src/core/api';
import { REDUCER_NAME as ENTITIES, reducer as dataReducer } from 'src/core/data';
import { reducer as i18n } from './i18n';

// Data Reducers
import data from './api/data';
import fileUpload from './api/fileUpload';
import helpModal from './helpModal/reducer';
import media from './media/media-reducer';

// View reducers
import dashboard from './dashboard/reducer';
import plan from './plan/plan-reducer';

// NOTE: TEMP TEST ONLY
import pltemp from './pltemp/red';

export default combineReducers({
  [API]: apiReducer,
  [ENTITIES]: dataReducer, // TODO: change reducer name to 'data' when old data reducer is gone
  dashboard,
  data,
  fileUpload,
  helpModal,
  i18n,
  media,
  plan,
  pltemp, // NOTE: TEMP TEST ONLY
});
