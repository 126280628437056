import React from 'react';
import moment from 'moment';
import { DatePicker } from 'react-widgets';
import { FormattedMessage, injectIntl } from 'react-intl';
import { isNaN } from 'lodash';

const MaxSelection = 3600 * 24 * 31 * 1000; // 1 Month

const DateRangePicker = (props) => {
  const onChangeStart = (v) => {
    if (v && !isNaN(v)) {
      const start = moment(v);
      let end = moment(props.to);

      if (end.diff(start) > MaxSelection) {
        end = moment(start).add(1, 'M');
      }

      props.onChange(start.toDate(), end.toDate());
    }
  };

  const onChangeEnd = (v) => {
    if (v && !isNaN(v)) {
      let start = moment(props.from);
      const end = moment(v);

      if (end.diff(start) > MaxSelection) {
        start = moment(end).add(-1, 'M');
      }

      props.onChange(start.toDate(), end.toDate());
    }
  };
  const format = 'YYYY-MM-DD';

  return (
    <div>
      <label className="d-block">
        <FormattedMessage id="common.from" />
        <DatePicker
          format={format}
          valueFormat={format}
          onChange={onChangeStart}
          value={props.from}
          culture="en-GB"
        />
      </label>
      <label className="d-block">
        <FormattedMessage id="common.to" />
        <DatePicker
          format={format}
          valueFormat={format}
          onChange={onChangeEnd}
          value={props.to}
          culture="en-GB"
        />
      </label>
    </div>
  );
};

export default DateRangePicker;
