import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { playerLayoutAreaShape } from 'utils/shapes';
import { makeDefaultArea } from 'js/react/ClientGroups/_utils';
import Area from './Area';

function getWidth(ratio = '16:9') {
  const [w, h] = ratio.split(':');
  const height = (h / w) * 100;
  let width = 100;

  if (height > 50) {
    const scale = 50 / height;
    width = Math.round(scale * 100);
  }

  return `${width}%`;
}

function getAspectRatio(ratio = '16:9') {
  const [w, h] = ratio.split(':');
  return {
    paddingBottom: `${Math.round((h / w) * 100)}%`, // Use padding-hack to display correct ratio.
    position: 'relative',
  };
}

export const LayoutPreview = memo(({
  areas,
  onClick,
  nolabels,
  aspectRatio,
  className = '',
  areaSettings = {},
  media,
  overlayText,
}) => {
  const { formatMessage } = useIntl();
  const _areas = areas || [makeDefaultArea(
    formatMessage({ id: 'views.layout.noLayoutsYet' }),
  )];

  const onClickStr = onClick ? ' btn btn-light pointer' : '';
  const outerClassName = `pa2 mx-auto card position-relative bbox${onClickStr} ${className}`;

  return (
    <div
      role="presentation"
      className={outerClassName}
      onClick={onClick}
      onKeyPress={() => {}}
      style={{
        width: getWidth(aspectRatio),
      }}
    >
      <div
        className="position-relative"
        style={{
          ...getAspectRatio(aspectRatio),
        }}
      >
        {_areas.map(({ id, ...other }) => (
          <Area
            key={id}
            id={id}
            areaSettings={areaSettings[id] || {}}
            nolabels={nolabels}
            overlayText={overlayText}
            media={media}
            other={other}
          />
        ))}
      </div>
    </div>
  );
});

LayoutPreview.propTypes = {
  areas: PropTypes.arrayOf(playerLayoutAreaShape),
  aspectRatio: PropTypes.string,
  onClick: PropTypes.func,
  nolabels: PropTypes.bool,
};

export default LayoutPreview;
