export function isValidPassword(password) {
  // validate that password is at least 4 chars long and has one upper case
  // letter, one lower case and one digit
  if (password.length < 4 ||
      password.match(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/) === null
  ) {
    return false;
  }

  return true;
}
