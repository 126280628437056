import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import {
  arrayOf, instanceOf, shape, string,
} from 'prop-types';
import ReactModal from 'react-modal';
import { MediaPlaybackConditions } from './Main';

function hasFieldConditions(data) {
  const operators = Object.keys(data?.conditions || {});
  return !!operators.find((operator) => {
    const conditions = data.conditions[operator];
    return !!conditions?.find?.(({ value }) => !isEmpty(value));
  });
}

function hasConditions(plannings) {
  const planning = plannings?.[0];
  return !!planning?.max_play_count || hasFieldConditions(planning?.additional_data);
}

export const ConditionsModal = ({
  plannings, maxDate, minDate, children, className,
}) => {
  const [isOpen, toggleOpen] = useState(false);
  const hasCond = hasConditions(plannings);

  return (
    <>
      <button
        type="button"
        className={className || `btn border ${hasCond ? 'btn-primary' : 'btn-light'}`}
        onClick={() => toggleOpen(true)}
      >
        {children || (
          <i className={`icon-cog${hasCond ? ' icon-white' : ''}`} />
        )}
      </button>
      <ReactModal
        isOpen={isOpen}
        contentLabel="Layout editor"
        ariaHideApp={false}
        appElement={document.getElementById('gs_main')}
        onRequestClose={() => toggleOpen(false)}
        style={{
          content: {
            maxWidth: 900,
            margin: 'auto',
          },
        }}
      >
        <MediaPlaybackConditions
          plannings={plannings}
          maxDate={maxDate}
          minDate={minDate}
          onClose={() => toggleOpen(false)}
        />
      </ReactModal>
    </>
  );
};

ConditionsModal.propTypes = {
  plannings: arrayOf(shape({
    id: string,
  })),
  maxDate: instanceOf(moment),
  minDate: instanceOf(moment),
};
