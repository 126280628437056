import {
  isUndefined,
  has,
  map,
  isPlainObject,
  isArray,
} from 'lodash';
import { EventEmitter } from 'events';
import constants from 'js/react/constants/constants.js';
import Immutable from 'immutable';


/**
 * Basic Store class
 */
export default function Store() {}
Store.prototype = Object.assign({}, EventEmitter.prototype, {
  state: {},
  /**
    * Return the map state or a sub-part of the state
    * @param {string} substate Identifier of a part of the state that should be returned
    */
  getState(substate) {
    if (!isUndefined(substate) && has(this.state, substate)) {
      return this.state[substate];
    }

    return this.state;
  },

  /**
    * Wrapper function to update the immutable _state variable
    * @param {object} data the state data to update
    */
  setState(data) {
    // Loop through all keys, check if they are already set and create/merge Immutables
    map(data, (value, key) => {
      if (Immutable.Iterable.isIterable(value)) {
        this.state[key] = value;
      } else if (isPlainObject(value) || isArray(value)) {
        this.state[key] = Immutable.Map(value);
      } else {
        this.state[key] = value;
      }
    }, this);
  },

  /**
     * @param {function} callback
     * @param {string} event Event identifier, if not specified CHANGE_EVENT
     */
  addChangeListener(callback, event) {
    if (isUndefined(event)) {
      this.on(constants.CHANGE_EVENT, callback);
    } else {
      this.on(event, callback);
    }
  },

  /**
     * @param {function} callback
     * @param {string} event Event identifier, if not specified CHANGE_EVENT
     */
  removeChangeListener(callback, event) {
    if (isUndefined(event)) {
      this.removeListener(constants.CHANGE_EVENT, callback);
    } else {
      this.removeListener(event, callback);
    }
  },

  /**
     * Event emitter
     * @param {string} Event Identifier of the event to emit
     */
  emitChange(event) {
    if (isUndefined(event)) {
      this.emit(constants.CHANGE_EVENT);
    } else {
      this.emit(event);
    }
  },
});
