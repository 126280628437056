import React from 'react';
import {
  func, number, oneOfType, shape, string,
} from 'prop-types';
import AudioPlayer from '../../js/react/components/AudioPlayer';

function ListItem({ item }) {
  const icon = item.custom ? 'icon-map-marker' : 'icon-volume-up';
  return (
    <span>
      {item.id && <i className={icon} />} {item.name}
    </span>
  );
}

ListItem.propTypes = {
  item: shape({
    id: oneOfType([string, number]),
  }),
};

const SpeechInput = ({
  width = 200,
  value,
  onChange,
}) => {
  const audioSrc = value ? `${window.GS_API}/speech/${btoa(value)}` : null;

  return (
    <div className="input-group">
      <input
        type="text"
        className="form-control"
        style={{ width }}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <div className="input-group-append">
        {audioSrc && <AudioPlayer src={audioSrc} /> }
      </div>
    </div>
  );
};

SpeechInput.propTypes = {
  width: number,
  value: string.isRequired,
  onChange: func.isRequired,
};

export default SpeechInput;
