import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getPlacesInObject } from 'src/entities/places';

// Push line updates
export const useSyncSelectedLine = (state, request, rootPath) => {
  const placesById = useSelector(getPlacesInObject);
  const {
    selectedLine, selectedDestination, selectedStop, error,
  } = state;

  useEffect(() => {
    if (error) {
      return;
    }

    const line = placesById[selectedLine]?.identifier || selectedLine;
    const dest = placesById[selectedDestination]?.identifier || selectedDestination;
    const stop = placesById[selectedStop]?.identifier || selectedStop;

    rootPath && request(`${rootPath}/api/v1/journey`, 'PUT', {
      // tripId: '1',
      route: {
        id: line?.toString(),
      },
      destination: {
        id: dest?.toString(),
      },
      currentStop: {
        id: stop?.toString(),
      },
    });
  }, [error, selectedLine, selectedDestination, selectedStop]);
};
