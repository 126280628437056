import {
  useEffect, useState, useContext,
  useRef,
} from 'react';
import WKT from 'ol/format/WKT';
import { useSelector } from 'react-redux';

import { getPlacesInObject } from 'js/redux/api/data';
import { setCurrentLocation, setPlaying, SimulationContext } from '../components/StateManager';

function wkt2vector(wkt, id = 0) {
  const formater = new WKT();
  const vector = formater.readFeature(wkt, {
    dataProjection: 'EPSG:900913',
    featureProjection: 'EPSG:4326',
  });

  vector.setId(id);

  return vector;
}

function flattenMultiLine(multiLine) {
  return multiLine && multiLine.reduce((acc, lines) => acc.concat(lines, []), []);
}

export const usePlaybackManager = () => {
  const playTimer = useRef();
  const [{
    playing,
    selectedLine,
  }, dispatch] = useContext(SimulationContext);
  const allPlaces = useSelector(getPlacesInObject);
  const selectedGeoLine = allPlaces[selectedLine];

  const [idx, setIdx] = useState(-1);
  const [coordinates, setCoordinates] = useState([]);
  const [speed, setSpeed] = useState(1);

  const handleLineChange = (line) => {
    const hasGeo = !!line.geometry;
    let coords = [];

    if (hasGeo) {
      const features = wkt2vector(line.geometry?.wkt);
      coords = features.getGeometry().getCoordinates();
    }

    dispatch(setPlaying(playing && hasGeo));
    setIdx(-1);
    setCoordinates(flattenMultiLine(coords));
  };

  const step = (increment = 1) => {
    if (coordinates.length === 0) {
      return;
    }

    const nextIdx = idx + increment < 0
      ? coordinates.length - 1
      : idx + increment;
    const coord = coordinates[nextIdx % coordinates.length];

    dispatch(setCurrentLocation(coord));
    setIdx(nextIdx);
  };

  useEffect(() => {
    if (playing) {
      playTimer.current = setTimeout(() => {
        step();
      }, 1000 / speed);
    }

    return () => {
      clearTimeout(playTimer.current);
    };
  }, [idx]);

  // Handle change line
  useEffect(() => {
    selectedGeoLine && handleLineChange(selectedGeoLine);

    // Stop playback if deselecting line
    !selectedGeoLine && dispatch(setPlaying(false));
  }, [selectedGeoLine]);

  // Handle start/stop
  useEffect(() => {
    playing && step();
    !playing && clearTimeout(playTimer.current);
  }, [playing]);

  return {
    playbackSpeed: speed,
    onSpeedChange: setSpeed,
    onStep: step,
  };
};
