import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import * as selectors from 'js/redux/selectors';
import {
  createGroup,
  deleteGroup,
  fetchGroups,
  updateGroup,
} from 'js/redux/api/data';
import HelpIcon from 'components/HelpIcon';
import { success as successMessage } from 'js/react/actions/message-actions';
import GroupList from './GroupList';
import AddGroup from './AddGroup';

function mapStateToProps(state) {
  return {
    groups: selectors.getGroupsSortedByNameExceptAdmin(state),
  };
}
const mapDispatchToActions = {
  createGroup,
  deleteGroup,
  fetchGroups,
  updateGroup,
};

const propTypes = {
  createGroup: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  fetchGroups: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  updateGroup: PropTypes.func.isRequired,
};

class GroupManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    };
  }

  componentDidMount() {
    this.props.fetchGroups();
  }

  handleRemove = (event) => {
    event.preventDefault();
    const idsToRemove = [...this.state.selected];
    idsToRemove.forEach((groupId) => {
      return this.props
        .deleteGroup(groupId)
        .then(() => successMessage(`Group with id ${groupId} deleted`));
    });
    this.setState({ selected: [] });
  };

  handleSelect = (id) => {
    const idx = this.state.selected.indexOf(id);
    const { selected } = this.state;

    if (idx === -1) {
      selected.push(id);
    } else {
      selected.splice(idx, 1);
    }

    this.setState({ selected });
  };

  createGroup = (name) => {
    return this.props
      .createGroup({ name })
      .then(() => successMessage('Group created'));
  };

  selectAll = () => {
    let { selected } = this.state;
    if (selected.length === this.props.groups.length && selected.length) {
      selected = [];
    } else {
      selected = this.props.groups.map((g) => g.id);
    }

    this.setState({ selected });
  };

  updateGroupName = (groupId) => (name) => {
    return this.props
      .updateGroup({ id: groupId, name })
      .then(() => successMessage('Group updated'));
  };

  render() {
    const { groups } = this.props;

    return (
      <>
        <div className="mb-3">
          <h2>
            <FormattedMessage id="views.admin.groupManagement.groupManagement" />
            <HelpIcon
              bodyKey="help.admin.groupManagement.body"
              headerKey="help.admin.groupManagement.header"
            />
          </h2>
          <div
            data-testid="add-group-container"
            className="mb-3"
          >
            <AddGroup createGroup={this.createGroup} />
            {this.state.selected.length > 0 && (
              <button
                type="button"
                className="btn btn-danger ml-1"
                onClick={this.handleRemove}
              >
                <i className="icon-trash icon-white" />{' '}
                <FormattedMessage id="views.admin.groupManagement.removeSelectedGroups" />
              </button>
            )}
          </div>
        </div>

        <GroupList
          groups={groups}
          onSelect={this.handleSelect}
          selectAll={this.selectAll}
          selected={this.state.selected}
          updateGroupName={this.updateGroupName}
        />
      </>
    );
  }
}

GroupManage.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToActions,
)(GroupManage);
