import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actionCreators } from 'js/redux/dashboard';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  WIDGETS,
  WIDGET_NAMES,
  PRESETS,
} from '../widgets';

const widgetThumbs = {
  list: require('img/widget-list.png'),
  'media-list': require('img/widget-media-list.png'),
  'pie-graph': require('img/widget-pie.png'),
  'bar-graph': require('img/widget-bar.png'),
};

function WidgetRow({ type, onClick, createFromPreset }) {
  const presets = PRESETS[type] || [];
  const href = `#dashboard/widget/${type}`;

  return (
    <div className="mb-3">
      <div className="d-flex mb-3">
        <h4 className="mr-auto">
          {WIDGET_NAMES[type]}
        </h4>
        <a
          href={href}
          className="btn btn-sm btn-light border"
        >
          <i className="icon-plus" />
          <FormattedMessage id="views.dashboard.addCustom" />
          {': '}
          {WIDGET_NAMES[type]}
        </a>
      </div>
      {presets.map((p) => {
        return (
          <div
            key={p.title}
            className="card flex-row align-content-center p-3 mb-3"
          >
            <img
              alt={p.title}
              src={widgetThumbs[p.thumb]}
              className="bg-light align-self-center"
              style={{
                maxWidth: 150,
              }}
            />
            <div className="col">
              <h5 className="mb-2">{p.title}</h5>
              <p className="text-secondary mb-2">{p.description}</p>
              <button
                type="button"
                className="btn btn-success"
                onClick={createFromPreset.bind(null, p.title, p.settings)}
              >
                <FormattedMessage id="views.dashboard.addWidget" />
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function AddWidget({ addWidget, onClose }) {
  const widgets = Object.keys(WIDGETS);

  return (
    <div>
      <h2 className="mb-3">
        <FormattedMessage id="views.dashboard.addDashboardWidget" />
      </h2>
      <hr />

      {widgets.map((w) => (
        <WidgetRow
          key={w}
          type={w}
          createFromPreset={(title, settings) => {
            addWidget({
              widgetType: w,
              title,
              settings,
            });
            onClose();
          }}
        />
      ))}
    </div>
  );
}

AddWidget.propTypes = {
  addWidget: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapActions = (dispatch) => ({
  addWidget({ widgetType, title, settings }) {
    dispatch(actionCreators.addDashboardWidget({
      widgetType,
      title,
      settings,
    }));
  },
});

export default connect(null, mapActions)(injectIntl(AddWidget));
