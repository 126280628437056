import { ADD_DASHBOARD_WIDGET } from '../actionTypes';
import { PRIMARY_DASHBOARD_ID } from '../constants';

export default function addDashboardWidget(
  {
    widgetType,
    dashboardId = PRIMARY_DASHBOARD_ID,
    settings = {},
    title = '',
  } = {},
) {
  return {
    payload: {
      settings, title, widgetType, dashboardId,
    },
    type: ADD_DASHBOARD_WIDGET,
  };
}
