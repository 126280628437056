import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMediaObjects, getApprovedAudio } from '../../../src/entities/media';
import { Combobox } from 'react-widgets';
import AudioPlayer from '../components/AudioPlayer';

const propTypes = {
  selected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  audio: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  custom: PropTypes.array,
  disabled: PropTypes.bool,
};

function ListItem({ item }) {
  const icon = item.custom ? 'icon-map-marker' : 'icon-volume-up';
  return (
    <span>
      {item.id && <i className={icon} />} {item.name}
    </span>
  );
}

ListItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
};

export const AudioSelect = ({
  selected, allowEmpty, custom, width, onChange,
}) => {
  const dispatch = useDispatch();
  const audio = useSelector(getApprovedAudio);
  const selectedAudio = useMemo(() => (
    // eslint-disable-next-line eqeqeq
    audio?.find((a) => a.id == selected)
  ), [audio, selected]);

  const data = useMemo(() => {
    if (allowEmpty) {
      return [{
        id: null,
        name: 'No audio',
      }, ...(custom || []), ...(audio || [])];
    }

    return [...(custom || []), ...(audio || [])];
  }, [audio, custom, allowEmpty]);

  useEffect(() => {
    if (!audio?.length) {
      // Load audio (well, all media) if needed
      dispatch(fetchMediaObjects());
    }
  }, []);

  const filterMedia = (item, value) => {
    const name = item.name.toLowerCase();
    const search = value.toLowerCase();
    return name.indexOf(search) !== -1;
  };

  return (
    <Combobox
      data={data}
      dataKey="id"
      textField="name"
      value={selectedAudio ? selected.toString() : undefined}
      filter={filterMedia}
      onChange={onChange}
      renderListItem={ListItem}
      className="mr-1"
      style={{ width }}
    />
  );
};

export const AudioPicker = ({
  selected,
  custom,
  onChange,
  width,
  allowEmpty,
  ...other
}) => {
  const audio = useSelector(getApprovedAudio);
  const selectedAudio = useMemo(() => (
    // eslint-disable-next-line eqeqeq
    audio?.find((a) => a.id == selected)
  ), [audio, selected]);
  const src = selectedAudio && `${window.ROOT_PATH}/${selectedAudio.preview}`;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div className="mb-1 d-flex" {...other}>
      <AudioSelect
        selected={selected}
        onChange={(media) => { onChange(media.id); }}
        width={width}
        allowEmpty={allowEmpty}
      />
      {selectedAudio && (
        <AudioPlayer
          src={src}
        />
      )}
    </div>
  );
};

AudioPicker.propTypes = propTypes;

export default AudioPicker;
