import React from 'react';
import { useSelector } from 'react-redux';
import { getAnnouncementTags } from 'src/entities/media';
import Dropdown, { DropdownContent, DropdownToggle } from 'js/react/components/Dropdown';
import { useIntl } from 'react-intl';

export const TokenPicker = ({
  value, onChange, className, disabled, testid = 'token-picker',
}) => {
  const tags = useSelector(getAnnouncementTags);
  const selected = tags?.find(({ tag } = {}) => tag === value);
  const intl = useIntl();

  return (
    <Dropdown
      closeOnClick
      disabled={disabled}
      data-testid={testid}
    >
      <DropdownToggle className={className}>
        {selected?.name || value || `+ ${intl.formatMessage({
          id: 'views.planning.announcement.audioToken',
        })}`}
      </DropdownToggle>
      <DropdownContent>
        {tags?.map(({ tag, name }) => (
          <button
            key={tag}
            type="button"
            onClick={() => onChange?.(tag)}
            className="dropdown-item"
          >
            {name}
          </button>
        ))}
      </DropdownContent>
    </Dropdown>
  );
};
