import * as actions from './actions';

export function fileUploadStarted() {
  return {
    type: actions.UPLOAD_STARTED,
  };
}

export function fileUploadProgress(progress) {
  return {
    type: actions.UPLOAD_PROGRESS,
    payload: progress,
  };
}

export function fileUploadEnded() {
  return {
    type: actions.UPLOAD_ENDED,
  };
}

export function fileUploadFailed() {
  return {
    type: actions.UPLOAD_FAILED,
  };
}
