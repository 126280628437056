import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import cancan from 'js/system/cancan';
import { Sortable } from 'js/react/_utils/sort-util';
import { PLANNING_TIMEPLAN } from 'constants/permissions';

function ListHeader(props) {
  return (
    <thead>
      <tr>
        <th className="w20">
          <Sortable
            {...props}
            sort="type"
            label=" "
          />
        </th>
        <th>
          <Sortable
            {...props}
            sort="name"
            label={props.intl.formatMessage({ id: 'common.name' })}
          />
        </th>
        <th>
          <FormattedMessage id="views.planning.playlists.timeTemplates" />
        </th>
        {cancan.can('read', PLANNING_TIMEPLAN) &&
          !props.planLocked && <th className="w30" />}
      </tr>
    </thead>
  );
}

export default injectIntl(ListHeader);
