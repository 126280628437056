import React, { memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { intlShape } from 'utils/shapes';
import Multiselect from 'react-widgets/Multiselect';

import { extractIds } from './utils';

const SELECT_ALL_OPTION_ID = 'SELECT_ALL_OPTION_ID';

const propTypes = {
  intl: intlShape.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired]).isRequired,
      name: PropTypes.string,
    }).isRequired,
  ),
  optionsCanBeUnknown: PropTypes.bool,
  placeholderTranslationKey: PropTypes.string,
  selectedIds: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.number.isRequired,
      PropTypes.string.isRequired,
    ]),
  ),
  testId: PropTypes.string,
  wrapperClassNames: PropTypes.string,
};
const defaultProps = {
  onChange: undefined,
  options: [],
  optionsCanBeUnknown: false,
  placeholderTranslationKey: undefined,
  // keep selectedIds undefined if component should be uncontrolled,
  // else always provide an array to keep it controlled
  selectedIds: undefined,
  testId: 'MultiselectFilter',
  wrapperClassNames: 'col-md-6 mb-3',
};

function MultiselectFilter({
  intl: { formatMessage },
  onChange,
  options,
  optionsCanBeUnknown,
  placeholderTranslationKey,
  selectedIds,
  testId,
  wrapperClassNames,
}) {
  const internalOptions = useMemo(() => {
    const optionsWithSelectAllFirst = [
      {
        id: SELECT_ALL_OPTION_ID,
        translationKey: 'common.selectAll',
      },
      ...options,
    ];

    if (optionsCanBeUnknown) {
      return [
        ...optionsWithSelectAllFirst,
        {
          id: '',
        },
      ];
    }

    return optionsWithSelectAllFirst;
  }, [options, optionsCanBeUnknown]);

  useEffect(() => {
    // Remove any selected ids of unavailable options
    const optionIds = options.map(({ id }) => id);
    const filteredIds = selectedIds?.filter((id) => optionIds.includes(id));

    if (selectedIds && filteredIds.length !== selectedIds.length) {
      onChange(filteredIds);
    }
  }, [selectedIds, options]);

  const allIdsExceptSelectAll = useMemo(() => {
    return extractIds(internalOptions.slice(1));
  }, [internalOptions]);

  const internalOnChange = (selectedOptions) => {
    if (onChange) {
      let newSelectedIds = extractIds(selectedOptions);

      if (newSelectedIds.includes(SELECT_ALL_OPTION_ID)) {
        newSelectedIds = allIdsExceptSelectAll;
      }

      onChange(newSelectedIds);
    }
  };

  const getText = ({ id, name, translationKey }) => {
    if (!id) {
      return formatMessage({ id: 'common.unknownOption' });
    }

    if (id === SELECT_ALL_OPTION_ID) {
      return `> ${formatMessage({ id: translationKey })}`;
    }

    if (translationKey) {
      return formatMessage({ id: translationKey });
    }

    return name;
  };

  return (
    <div
      data-testid={testId}
      className={wrapperClassNames}
    >
      {
        /*
        * needed to avoid rendering options like empty strings since selectedIds
        *  can come immediately from localStorage whereas options come from backend
        */
        options.length > 0 && (
          <Multiselect
            data={internalOptions}
            filter="contains"
            onChange={internalOnChange}
            placeholder={placeholderTranslationKey && formatMessage({ id: placeholderTranslationKey })}
            textField={getText}
            value={selectedIds || []}
            dataKey="id"
          />
        )
      }
    </div>
  );
}


MultiselectFilter.propTypes = propTypes;
MultiselectFilter.defaultProps = defaultProps;

export default compose(memo, injectIntl)(MultiselectFilter);
