import { createCrudActionCreators } from 'src/core/api';

import { COLLECTION, URL } from './constants';

export const {
  // create: createHealthVariable,
  readAll: fetchHealthVariables,
  update: updateHealthVariable,
  // destroy: deleteHealthVariable,
} = createCrudActionCreators({ collection: COLLECTION, url: URL });
