/* eslint-disable max-classes-per-file */
import HelpIcon from 'js/react/components/HelpIcon';
import cancan from 'js/system/cancan';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import createClass from 'create-react-class';
import Store from 'js/stores/ajax-store';
import storeListener from 'js/stores/store-listener';
import GsSideBar from 'js/react/_utils/right-sidebar';
import { DatePicker } from 'react-widgets';
import * as MsgActions from 'js/react/actions/message-actions';
import moment from 'moment';
import { ADMIN_MEDIABUILD } from 'constants/permissions';
import { map } from 'lodash';

require('./css/extra.scss');

const MediaBuildStore = Store('/build');

function StatusLabel({ status }) {
  let classStr = 'badge badge-secondary w-100';
  let text = <FormattedMessage id="views.admin.health.planned" />;

  if (status === 0 || status === '0') {
    text = <FormattedMessage id="common.success" />;
    classStr += ' badge-success';
  } else if (status > 0) {
    text = <FormattedMessage id="common.errors" />;
    classStr += ' badge-danger';
  }

  return (
    <div className={classStr}>
      {text}
    </div>
  );
}

const InfoBox = createClass({
  render() {
    return (
      <div className="card bg-light p-3">
        <p>
          <i className="icon-warning-sign" />{' '}
          <FormattedMessage id="views.admin.mediaBuild.buildsShown" />
        </p>
        {/* <p>{this.props.text}</p> */}
      </div>
    );
  },
});

// Form for creating a mediabuild
class CreateForm extends React.Component {
  state = { cand: null }

  reset = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    this.setState({ cand: null });
  }

  create = () => {
    MediaBuildStore.create({ build_schedule: this.state.cand }).then((data) => {
      this.reset();
      MsgActions.success('Created Media Build');
    });
  }

  handleDateChange = (d, dstr) => {
    if (d) {
      this.setState({ cand: d });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
  }

  initCandidate = (scheduleNow) => {
    const cand = new Date();

    if (!scheduleNow) {
      cand.setDate(cand.getDate() + 1);
      cand.setMinutes(0);
      cand.setHours(4);
    }

    this.setState({ cand, scheduleNow });
  }

  renderForm() {
    const dateStyle = { display: 'inline-block' };

    return (
      <div
        className="alert alert-info d-block hidden build-confirm"
      >
        <p>
          <em>
            <FormattedMessage id="views.admin.mediaBuild.setAndConfirmDate" />
          </em>
        </p>

        <form
          onSubmit={this.handleSubmit}
          className="admin-form form-horizontal mb-0"
        >
          <DatePicker
            includeTime
            format="YYYY-MM-DD HH:mm"
            valueFormat="YYYY-MM-DD HH:mm"
            culture="en-GB"
            defaultValue={this.state.cand}
            style={dateStyle}
            onChange={this.handleDateChange}
          />
          <button
            type="button"
            onClick={this.create}
            style={{ marginLeft: 5 }}
            className="btn btn-primary"
          >
            <FormattedMessage id="views.admin.mediaBuild.confirmBuild" />
          </button>
          <button
            type="button"
            className="btn hideConfirm"
            onClick={this.reset}
          >
            <FormattedMessage id="common.cancel" />
          </button>
        </form>
      </div>
    );
  }

  renderAsapForm() {
    return (
      <div className="alert alert-warning">
        <p>
          <FormattedMessage id="views.admin.mediaBuild.confirmBuildAsap" />
        </p>
        <form
          onSubmit={this.handleSubmit}
          className="admin-form form-horizontal mb-0"
        >
          <button
            type="button"
            onClick={this.create}
            style={{ marginLeft: 5 }}
            className="btn btn-warning"
          >
            <FormattedMessage id="views.admin.mediaBuild.confirmBuild" />
          </button>
          <button
            type="button"
            className="btn hideConfirm"
            onClick={this.reset}
          >
            <FormattedMessage id="common.cancel" />
          </button>
        </form>
      </div>
    );
  }

  render() {
    let form = <div />;

    if (this.state.cand) {
      form =
        (this.state.scheduleNow && this.renderAsapForm()) || this.renderForm();
    }

    const disabledCls = (this.state.cand && ' disabled') || '';
    return (
      <div className="d-inline-block">
        <form className="admin-form mb-2" onSubmit={this.handleSubmit}>
          <button
            type="button"
            onClick={() => this.initCandidate(false)}
            className={`btn btn-primary${disabledCls}`}
          >
            <FormattedMessage id="views.admin.mediaBuild.scheduleBuild" />
          </button>
          <button
            type="button"
            onClick={() => this.initCandidate(true)}
            className={`btn btn-warning${disabledCls}`}
          >
            <FormattedMessage id="views.admin.mediaBuild.buildAsap" />
          </button>
        </form>

        {form}
      </div>
    );
  }
}

const DeleteForm = createClass({
  render() {
    return (
      <div className="d-inline-block mb-2">
        <button
          type="button"
          className="btn btn-danger"
          onClick={this.props.onClick}
        >
          <i className="icon-trash icon-white" /> {this.props.text}
        </button>
      </div>
    );
  },
});

class BuildList extends React.Component { // eslint-disable-line
  static defaultProps = {
    data: [],
    selected: [],
  }

  isSelected = (d) => {
    return this.props.selected.indexOf(d) !== -1;
  }

  handleChange = (d) => {
    this.props.onChecked(d);
  }

  handleClick = (dataType, title, data) => {
    this.props.onClick(dataType, title, data);
  }

  renderSnapshotList({ id, snapshot = {} }) {
    const keys = Object.keys(snapshot);
    return keys.map((k) => {
      const v = snapshot[k];
      return (
        <button
          key={k}
          className="btn badge badge-info mr-1"
          onClick={this.handleClick.bind(
            this,
            'snapshot',
            `Build ${id}: ${k}`,
            v,
          )}
        >
          {k}{' '}
        </button>
      );
    });
  }

  renderRows() {
    return this.props.data && this.props.data.map((d) => {
      const scheduleTime = moment(d.build_schedule).format('YYYY-MM-DD HH:mm');
      return (
        <tr key={d.id}>
          <td>
            {this.props.canCheck && (
              <input
                onChange={this.handleChange.bind(this, d)}
                type="checkbox"
                checked={this.isSelected(d)}
              />
            )}
          </td>
          <td>{scheduleTime}</td>
          <td
            onClick={this.handleClick.bind(this, 'status', '', d.messages)}
          >
            <StatusLabel status={d.build_status} />
          </td>
          <td>{d.id}</td>
          <td>{this.renderSnapshotList(d)}</td>
        </tr>
      );
    });
  }

  render() {
    return (
      <table className="table">
        <thead>
          <tr>
            <th className="w10" />
            <th className="w200">
              <FormattedMessage id="views.admin.mediaBuild.buildDate" />
            </th>
            <th className="w75">
              <FormattedMessage id="common.status" />
            </th>
            <th className="w75">
              <FormattedMessage id="views.admin.mediaBuild.buildNo" />
            </th>
            <th>
              <FormattedMessage id="common.playlists" />
            </th>
          </tr>
        </thead>
        <tbody data-testid={this.props.testId}>
          {this.renderRows()}
        </tbody>
      </table>
    );
  }
}

const MediaBuildView = createClass({
  mixins: [storeListener],
  getInitialState() {
    return {
      data: [], // all media builds
      selected: [], // build marked as selected in UI
      sidebarData: null, // data to display in sidebar
    };
  },
  // a media build was clicked, handle display in sidebar
  handleBuildDataClick(dataType, title, data) {
    this.props.onDataChanged({ dataType, title, data });
  },
  // fetch media build from api
  loadData() {
    MediaBuildStore.fetch().then((data) => {
      this.setState({ data: data.toJS() });
    });
  },
  UNSAFE_componentWillMount() {
    this.loadData();

    // update if data is changed
    this.listenTo([MediaBuildStore], 'add change.* destroy.*', this.loadData);
  },

  // destroy all selected media builds
  destroySelected() {
    const { selected } = this.state;

    this.state.selected && this.state.selected.forEach((b) => {
      MediaBuildStore.destroy(b.id).then(() => {
        // if success, remove the build from selected list
        const idx = this.state.selected.indexOf(b);
        selected.splice(idx, 1);
        this.setState({ selected });
      });
    });
  },
  // add or remove a build from the list of selected builds
  toggleSelected(build) {
    const current = this.state.selected;
    const itemIdx = current.indexOf(build);
    if (itemIdx === -1) {
      current.push(build);
    } else {
      current.splice(itemIdx, 1);
    }
    this.setState({ selected: current });
  },
  clearSidebar() {
    this.props.onDataChange(null);
  },
  // render a list of sidebar messages, data.data should be a list of messages
  render() {
    const { selected, data = [] } = this.state;
    let completed = data.filter((b) => b.build_status !== -1);
    let pending = data.filter((b) => b.build_status === -1);

    completed = completed.reverse().slice(0, 5);

    pending = pending.slice(0, 5);

    const displayClass = selected.length > 0 ? 'd-inline-block' : 'd-none';

    return (
      <div className="row">
        <div className="col-3">
          <h3 className="mb-3" data-testid="media-build-header">
            <FormattedMessage id="views.admin.mediaBuild.mediaBuild" />
            <HelpIcon
              bodyKey="help.admin.mediaBuild.body"
              headerKey="help.admin.mediaBuild.header"
            />
          </h3>
          <InfoBox
            text={<FormattedMessage id="views.admin.mediaBuild.disclaimer" />}
          />
        </div>
        <div className="col-9">
          {cancan.can('read', ADMIN_MEDIABUILD) && (
            <CreateForm />
          )}
          <div className={displayClass}>
            <DeleteForm
              onClick={this.destroySelected}
              text={
                <FormattedMessage id="views.admin.mediaBuild.cancelSelected" />
              }
            />
          </div>

          {pending.length > 0 && (
            <div className="mt-3">
              <h5>
                <FormattedMessage id="views.admin.mediaBuild.upcomingBuilds" />
              </h5>

              <BuildList
                testId="pending-media-builds-table-body"
                onClick={this.handleBuildDataClick}
                onChecked={this.toggleSelected}
                data={pending}
                canCheck
                selected={selected}
              />
            </div>
          )}

          {pending.length === 0 && (
            <div className="mb-2">
              <em>
                <FormattedMessage id="views.admin.mediaBuild.noUpcomingBuilds" />
              </em>
            </div>
          )}

          <h5>
            <FormattedMessage id="views.admin.mediaBuild.completedBuilds" />
          </h5>
          <BuildList
            testId="latest-media-builds-table-body"
            onClick={this.handleBuildDataClick}
            onChecked={this.toggleSelected}
            data={completed}
            selected={selected}
          />
        </div>
      </div>
    );
  },
});

function fmtDuration(d) {
  return `${parseInt(d, 10)}`;
}

const iconTypeMap = {
  image: 'icon-picture',
  video: 'icon-film',
};

class MediaBuildSidebar extends React.Component { // eslint-disable-line
  renderMessages(messages) {
    let rows = (
      <p className="card bg-light p-3">
        <FormattedMessage id="views.admin.mediaBuild.noMessagesForMediaBuild" />
      </p>
    );

    if (messages && messages.length > 0) {
      rows = map(messages, (d, i) => {
        return (
          <p key={i} className="card bg-light p-3">
            {d}
          </p>
        );
      });
    }

    return (
      <div className="holder bg-white p-3">
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={this.props.onClose}
        >
          <span aria-hidden="true">
            {'\u00D7'}
          </span>
        </button>
        <h3>
          <FormattedMessage id="views.admin.mediaBuild.buildDetails" />
        </h3>
        {rows}
      </div>
    );
  }

  renderSnapshotContent(title, media) {
    const rows = media.map((v, i) => {
      return (
        <tr key={i}>
          <td>{i + 1}</td>
          <td>
            <i className={iconTypeMap[v.media_type] || 'icon-cog'} />
          </td>
          <td>{v.name}</td>
          <td>{fmtDuration(v.duration)}</td>
        </tr>
      );
    });

    return (
      <div className="holder bg-white p-3">
        <button
          type="button"
          aria-label="Close"
          className="close"
          onClick={this.props.onClose}
        >
          <span aria-hidden="true">
            {'\u00D7'}
          </span>
        </button>
        <div className="px-1 pb-1 pt-3">
          <h3 className="mt-0">{title}</h3>
        </div>
        <table className="table table-fixed">
          <thead>
            <tr>
              <th className="w10">#</th>
              <th className="w10" />
              <th>
                <FormattedMessage id="common.name" />
              </th>
              <th className="w75">
                <FormattedMessage id="common.length" />
              </th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }

  render() {
    const data = this.props.sidebarData;

    if (data.dataType === 'status') {
      return this.renderMessages(data.data);
    } if (data.dataType === 'snapshot') {
      return this.renderSnapshotContent(data.title, data.data);
    }

    return '';
  }
}

class MediaBuild extends React.Component { // eslint-disable-line
  state = { sidebarData: null };

  sidebarChanged = (data) => {
    this.setState({ sidebarData: data });
  }

  blockerHidden = () => {
    this.sidebarChanged(null);
  }

  render() {
    const onClose = (e) => {
      this.setState({ sidebarData: null });
    };

    return (
      <div>
        <MediaBuildView onDataChanged={this.sidebarChanged} />
        <GsSideBar href="/admin/mediabuild" onClose={onClose}>
          {this.state.sidebarData && (
            <MediaBuildSidebar
              sidebarData={this.state.sidebarData}
              onClose={onClose}
            />
          )}
        </GsSideBar>
      </div>
    );
  }
}

export default MediaBuild;
