import React from 'react';
import { FormattedMessage } from 'react-intl';
import UserViewPermissions from '.';

export default ({ users = [] }) => {
  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th>
              <FormattedMessage id="common.user" />
            </th>
            <th colSpan="0" />
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td className="border-right">
                {user.name}
              </td>
              <UserViewPermissions
                user={user}
              />
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
