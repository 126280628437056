import { useEffect, useRef } from 'react';
import { useAsyncDispatch } from 'src/helpers/useAsyncDispatch';
import { fetchPlacesWithQueryParams } from 'src/entities/places';

export const useEnsurePlaces = () => {
  const initialLoadingState = useRef(true);
  const [dispatch, pending] = useAsyncDispatch();

  useEffect(() => {
    dispatch(fetchPlacesWithQueryParams('?invisible=true'));
    initialLoadingState.current = false;
  }, []);

  return initialLoadingState.current || !!pending?.length;
};
