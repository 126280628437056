export const CREATE_MEDIA_OBJECT = '@DATA_CREATE_MEDIA_OBJECT';
export const CREATE_MEDIA_OBJECT_SUCCESS = '@DATA_CREATE_MEDIA_OBJECT_SUCCESS';
export const CREATE_MEDIA_OBJECT_FAIL = '@DATA_CREATE_MEDIA_OBJECT_FAIL';

export const FETCH_MEDIA_EXTRA = '@DATA_FETCH_MEDIA_OBJECTS';
export const FETCH_MEDIA_EXTRA_SUCCESS = '@DATA_FETCH_MEDIA_OBJECTS_SUCCESS';
export const FETCH_MEDIA_EXTRA_FAIL = '@DATA_FETCH_MEDIA_OBJECTS_FAIL';

export const FETCH_MEDIA_OBJECTS = '@DATA_FETCH_MEDIA_OBJECTS';
export const FETCH_MEDIA_OBJECTS_SUCCESS = '@DATA_FETCH_MEDIA_OBJECTS_SUCCESS';
export const FETCH_MEDIA_OBJECTS_FAIL = '@DATA_FETCH_MEDIA_OBJECTS_FAIL';

export const FETCH_WIDGET_TYPES = '@DATA_FETCH_WIDGET_TYPES';
export const FETCH_WIDGET_TYPES_SUCCESS = '@DATA_FETCH_WIDGET_TYPES_SUCCESS';
export const FETCH_WIDGET_TYPES_FAIL = '@DATA_FETCH_WIDGET_TYPES_FAIL';

export const FETCH_CUSTOM_WIDGET = '@DATA_FETCH_CUSTOM_WIDGETS';
export const FETCH_CUSTOM_WIDGET_SUCCESS = '@DATA_FETCH_CUSTOM_WIDGETS_SUCCESS';
export const FETCH_CUSTOM_WIDGET_FAIL = '@DATA_FETCH_CUSTOM_WIDGETS_FAIL';

export const UPDATE_MEDIA_OBJECT = '@DATA_UPDATE_MEDIA_OBJECT';
export const UPDATE_MEDIA_OBJECT_SUCCESS = '@DATA_UPDATE_MEDIA_OBJECT_SUCCESS';
export const UPDATE_MEDIA_OBJECT_FAIL = '@DATA_UPDATE_MEDIA_OBJECT_FAIL';

export const DELETE_MEDIA_OBJECT = '@DATA_DELETE_MEDIA_OBJECT';
export const DELETE_MEDIA_OBJECT_SUCCESS = '@DATA_DELETE_MEDIA_OBJECT_SUCCESS';
export const DELETE_MEDIA_OBJECT_FAIL = '@DATA_DELETE_MEDIA_OBJECT_FAIL';

export const CREATE_PLACE = '@DATA_CREATE_PLACE';
export const CREATE_PLACE_SUCCESS = '@DATA_CREATE_PLACE_SUCCESS';
export const CREATE_PLACE_FAIL = '@DATA_CREATE_PLACE_FAIL';

export const FETCH_PLACES = '@DATA_FETCH_PLACES';
export const FETCH_PLACES_SUCCESS = '@DATA_FETCH_PLACES_SUCCESS';
export const FETCH_PLACES_FAIL = '@DATA_FETCH_PLACES_FAIL';

export const UPDATE_PLACE = '@DATA_UPDATE_PLACE';
export const UPDATE_PLACE_SUCCESS = '@DATA_UPDATE_PLACE_SUCCESS';
export const UPDATE_PLACE_FAIL = '@DATA_UPDATE_PLACE_FAIL';

export const DELETE_PLACE = '@DATA_DELETE_PLACE';
export const DELETE_PLACE_SUCCESS = '@DATA_DELETE_PLACE_SUCCESS';
export const DELETE_PLACE_FAIL = '@DATA_DELETE_PLACE_FAIL';

export const CREATE_PLAYLIST = '@DATA_CREATE_PLAYLIST';
export const CREATE_PLAYLIST_SUCCESS = '@DATA_CREATE_PLAYLIST_SUCCESS';
export const CREATE_PLAYLIST_FAIL = '@DATA_CREATE_PLAYLIST_FAIL';

export const FETCH_PLAYLISTS = '@DATA_FETCH_PLAYLISTS';
export const FETCH_PLAYLISTS_SUCCESS = '@DATA_FETCH_PLAYLISTS_SUCCESS';
export const FETCH_PLAYLISTS_FAIL = '@DATA_FETCH_PLAYLISTS_FAIL';

export const UPDATE_PLAYLIST = '@DATA_UPDATE_PLAYLIST';
export const UPDATE_PLAYLIST_SUCCESS = '@DATA_UPDATE_PLAYLIST_SUCCESS';
export const UPDATE_PLAYLIST_FAIL = '@DATA_UPDATE_PLAYLIST_FAIL';

export const DELETE_PLAYLIST = '@DATA_DELETE_PLAYLIST';
export const DELETE_PLAYLIST_SUCCESS = '@DATA_DELETE_PLAYLIST_SUCCESS';
export const DELETE_PLAYLIST_FAIL = '@DATA_DELETE_PLAYLIST_FAIL';

export const FETCH_PLANNED = '@DATA_FETCH_PLANNED';
export const FETCH_PLANNED_SUCCESS = '@DATA_FETCH_PLANNED_SUCCESS';
export const FETCH_PLANNED_FAIL = '@DATA_FETCH_PLANNED_FAIL';

export const FETCH_CONFIGS = '@DATA_FETCH_CONFIGS';
export const FETCH_CONFIGS_SUCCESS = '@DATA_FETCH_CONFIGS_SUCCESS';
export const FETCH_CONFIGS_FAIL = '@DATA_FETCH_CONFIGS_FAIL';

export const CREATE_PLANNINGS = '@DATA_CREATE_PLANNINGS';
export const CREATE_PLANNINGS_SUCCESS = '@DATA_CREATE_PLANNINGS_SUCCESS';
export const CREATE_PLANNINGS_FAIL = '@DATA_CREATE_PLANNINGS_FAIL';

export const UPDATE_PLANNINGS = '@DATA_UPDATE_PLANNINGS';
export const UPDATE_PLANNINGS_SUCCESS = '@DATA_UPDATE_PLANNINGS_SUCCESS';
export const UPDATE_PLANNINGS_FAIL = '@DATA_UPDATE_PLANNINGS_FAIL';

export const DELETE_PLANNING = '@DATA_DELETE_PLANNING';
export const DELETE_PLANNING_SUCCESS = '@DATA_DELETE_PLANNING_SUCCESS';
export const DELETE_PLANNING_FAIL = '@DATA_DELETE_PLANNING_FAIL';

export const CREATE_USER = '@DATA_CREATE_USER';
export const CREATE_USER_SUCCESS = '@DATA_CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = '@DATA_CREATE_USER_FAIL';

export const DELETE_USER = '@DATA_DELETE_USER';
export const DELETE_USER_SUCCESS = '@DATA_DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = '@DATA_DELETE_USER_FAIL';

export const FETCH_USERS = '@DATA_FETCH_USERS';
export const FETCH_USERS_SUCCESS = '@DATA_FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = '@DATA_FETCH_USERS_FAIL';

export const UPDATE_USER_GROUP = '@DATA_UPDATE_USER_GROUP';
export const UPDATE_USER_GROUP_SUCCESS = '@DATA_UPDATE_USER_GROUP_SUCCESS';
export const UPDATE_USER_GROUP_FAIL = '@DATA_UPDATE_USER_GROUP_FAIL';

export const UPDATE_USER_NAME = '@DATA_UPDATE_USER_NAME';
export const UPDATE_USER_NAME_SUCCESS = '@DATA_UPDATE_USER_NAME_SUCCESS';
export const UPDATE_USER_NAME_FAIL = '@DATA_UPDATE_USER_NAME_FAIL';

export const UPDATE_USER_PASSWORD = '@DATA_UPDATE_USER_PASSWORD';
export const UPDATE_USER_PASSWORD_SUCCESS = '@DATA_UPDATE_USER_PASSWORD_SUCCESS';
export const UPDATE_USER_PASSWORD_FAIL = '@DATA_UPDATE_USER_PASSWORD_FAIL';

export const UPDATE_USER_VIEWS = '@DATA_UPDATE_USER_VIEWS';
export const UPDATE_USER_VIEWS_SUCCESS = '@DATA_UPDATE_USER_VIEWS_SUCCESS';
export const UPDATE_USER_VIEWS_FAIL = '@DATA_UPDATE_USER_VIEWS_FAIL';

export const CREATE_GROUP = '@DATA_CREATE_GROUP';
export const CREATE_GROUP_SUCCESS = '@DATA_CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAIL = '@DATA_CREATE_GROUP_FAIL';

export const DELETE_GROUP = '@DATA_DELETE_GROUP';
export const DELETE_GROUP_SUCCESS = '@DATA_DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAIL = '@DATA_DELETE_GROUP_FAIL';

export const FETCH_GROUPS = '@DATA_FETCH_GROUPS';
export const FETCH_GROUPS_SUCCESS = '@DATA_FETCH_GROUPS_SUCCESS';
export const FETCH_GROUPS_FAIL = '@DATA_FETCH_GROUPS_FAIL';

export const UPDATE_GROUP = '@DATA_UPDATE_GROUP';
export const UPDATE_GROUP_SUCCESS = '@DATA_UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAIL = '@DATA_UPDATE_GROUP_FAIL';

export const CREATE_HEALTH_VARIABLE = '@DATA_CREATE_HEALTH_VARIABLE';
export const CREATE_HEALTH_VARIABLE_SUCCESS =
  '@DATA_CREATE_HEALTH_VARIABLE_SUCCESS';
export const CREATE_HEALTH_VARIABLE_FAIL = '@DATA_CREATE_HEALTH_VARIABLE_FAIL';

export const DELETE_HEALTH_VARIABLE = '@DATA_DELETE_HEALTH_VARIABLE';
export const DELETE_HEALTH_VARIABLE_SUCCESS =
  '@DATA_DELETE_HEALTH_VARIABLE_SUCCESS';
export const DELETE_HEALTH_VARIABLE_FAIL = '@DATA_DELETE_HEALTH_VARIABLE_FAIL';

export const FETCH_HEALTH_VARIABLES = '@DATA_FETCH_HEALTH_VARIABLES';
export const FETCH_HEALTH_VARIABLES_SUCCESS =
  '@DATA_FETCH_HEALTH_VARIABLES_SUCCESS';
export const FETCH_HEALTH_VARIABLES_FAIL = '@DATA_FETCH_HEALTH_VARIABLES_FAIL';

export const UPDATE_HEALTH_VARIABLE = '@DATA_UPDATE_HEALTH_VARIABLE';
export const UPDATE_HEALTH_VARIABLE_SUCCESS =
  '@DATA_UPDATE_HEALTH_VARIABLE_SUCCESS';
export const UPDATE_HEALTH_VARIABLE_FAIL = '@DATA_UPDATE_HEALTH_VARIABLE_FAIL';

export const FETCH_CLIENT_HEALTH_STATUSES =
  '@DATA_FETCH_CLIENT_HEALTH_STATUSES';
export const FETCH_CLIENT_HEALTH_STATUSES_SUCCESS =
  '@DATA_FETCH_CLIENT_HEALTH_STATUSES_SUCCESS';
export const FETCH_CLIENT_HEALTH_STATUSES_FAIL =
  '@DATA_FETCH_CLIENT_HEALTH_STATUSES_FAIL';

export const CREATE_CLIENT_HEALTH_STATUS = '@DATA_CREATE_CLIENT_HEALTH_STATUS';
export const CREATE_CLIENT_HEALTH_STATUS_SUCCESS =
  '@DATA_CREATE_CLIENT_HEALTH_STATUS_SUCCESS';
export const CREATE_CLIENT_HEALTH_STATUS_FAIL =
  '@DATA_CREATE_CLIENT_HEALTH_STATUS_FAIL';

export const UPDATE_CLIENT_HEALTH_STATUS = '@DATA_UPDATE_CLIENT_HEALTH_STATUS';
export const UPDATE_CLIENT_HEALTH_STATUS_SUCCESS =
  '@DATA_UPDATE_CLIENT_HEALTH_STATUS_SUCCESS';
export const UPDATE_CLIENT_HEALTH_STATUS_FAIL =
  '@DATA_UPDATE_CLIENT_HEALTH_STATUS_FAIL';

export const DELETE_CLIENT_HEALTH_STATUS = '@DATA_DELETE_CLIENT_HEALTH_STATUS';
export const DELETE_CLIENT_HEALTH_STATUS_SUCCESS =
  '@DATA_DELETE_CLIENT_HEALTH_STATUS_SUCCESS';
export const DELETE_CLIENT_HEALTH_STATUS_FAIL =
  '@DATA_DELETE_CLIENT_HEALTH_STATUS_FAILexport';

export const FETCH_ANNOUNCEMENT_TAGS = '@DATA_FETCH_ANNOUNCEMENT_TAGS';
export const FETCH_ANNOUNCEMENT_TAGS_SUCCESS = '@DATA_FETCH_ANNOUNCEMENT_TAGS_SUCCESS';
export const FETCH_ANNOUNCEMENT_TAGS_FAIL = '@DATA_FETCH_ANNOUNCEMENT_TAGS_FAIL';

export const CREATE_PLAYER_LAYOUT = '@DATA_CREATE_PLAYER_LAYOUT';
export const CREATE_PLAYER_LAYOUT_SUCCESS = '@DATA_CREATE_PLAYER_LAYOUT_SUCCESS';
export const CREATE_PLAYER_LAYOUT_FAIL = '@DATA_CREATE_PLAYER_LAYOUT_FAIL';

export const FETCH_PLAYER_LAYOUTS = '@DATA_FETCH_PLAYER_LAYOUTS';
export const FETCH_PLAYER_LAYOUTS_SUCCESS = '@DATA_FETCH_PLAYER_LAYOUTS_SUCCESS';
export const FETCH_PLAYER_LAYOUTS_FAIL = '@DATA_FETCH_PLAYER_LAYOUTS_FAIL';

export const UPDATE_PLAYER_LAYOUT = '@DATA_UPDATE_PLAYER_LAYOUT';
export const UPDATE_PLAYER_LAYOUT_SUCCESS = '@DATA_UPDATE_PLAYER_LAYOUT_SUCCESS';
export const UPDATE_PLAYER_LAYOUT_FAIL = '@DATA_UPDATE_PLAYER_LAYOUT_FAIL';

export const DELETE_PLAYER_LAYOUT = '@DATA_DELETE_PLAYER_LAYOUT';
export const DELETE_PLAYER_LAYOUT_SUCCESS = '@DATA_DELETE_PLAYER_LAYOUT_SUCCESS';
export const DELETE_PLAYER_LAYOUT_FAIL = '@DATA_DELETE_PLAYER_LAYOUT_FAIL';

export const FETCH_CLIENTS = '@DATA_FETCH_CLIENTS';
export const FETCH_CLIENTS_SUCCESS = '@DATA_FETCH_CLIENTS_SUCCESS';
export const FETCH_CLIENTS_FAIL = '@DATA_FETCH_CLIENTS_FAIL';
