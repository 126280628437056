import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { actionCreators } from 'js/redux/dashboard';
import globalSelectors from 'js/redux/dashboard/globalSelectors';
import { WIDGET_NAMES } from '../index';

const WIDGET_TYPE = 'latestmedia';

class ClientHealthSettings extends React.Component {
  constructor(props) {
    super(props);
    const widget = props.widget || { settings: {} };

    this.state = {
      title: widget.title || '',
      count: widget.settings.count || 5,
    };
  }

  handleTitle = (e) => this.setState({ title: e.target.value })

  handleCount = (e) => this.setState({ count: e.target.value })

  handleSave = (e) => {
    e.preventDefault();
    this.props.saveSettings(this.props.activeId, this.state);
    this.props.onClose && this.props.onClose();
  }

  render() {
    return (
      <div>
        <form>
          <fieldset>
            <legend>
              <FormattedMessage id="views.dashboard.editDashboardWidget" />
              {': '}
              {WIDGET_NAMES[WIDGET_TYPE]}
            </legend>
            <label>
              <FormattedMessage id="common.name" />
            </label>
            <input
              type="text"
              className="form-control"
              value={this.state.title}
              onChange={this.handleTitle}
            />

            <br />

            <button
              className="btn btn-primary"
              onClick={this.state.title && this.handleSave}
              disabled={!this.state.title}
            >
              <FormattedMessage id="common.save" />
            </button>
          </fieldset>
        </form>
      </div>
    );
  }
}

ClientHealthSettings.propTypes = {
  activeId: PropTypes.string,
  widget: PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    widgetId: PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
  }),
  saveSettings: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  intl: PropTypes.object,
};

const mapState = (state, { activeId }) => {
  return {
    widget: globalSelectors.getDashboardWidget(state, { widgetId: activeId }),
  };
};

const mapActions = (dispatch) => ({
  saveSettings(widgetId, { title }) {
    const newTitle = { widgetId, title };
    const settings = {
      widgetId,
      settings: {},
    };

    if (!widgetId) {
      dispatch(actionCreators.addDashboardWidget({
        widgetType: WIDGET_TYPE,
        ...newTitle,
        ...settings,
      }));
    } else {
      dispatch(actionCreators.updateDashboardWidgetTitle(newTitle));
      dispatch(actionCreators.updateDashboardWidgetSettings(settings));
    }
  },
});

export default connect(mapState, mapActions)(injectIntl(ClientHealthSettings));
