import {
  RESET,
  MAX_PLAY_COUNT,
  TIME_LIMITS,
  FIELD_CONDITION,
} from '../constants';

export function valuesReducer(state, action) {
  const { type, payload } = action;
  switch (type) {
  case RESET:
    return payload;
  case MAX_PLAY_COUNT:
    return {
      ...state,
      [type]: payload,
    };
  case TIME_LIMITS:
    const newValues = { ...payload };

    // remove order, because this reducer holds shared values across plannings
    // and if we put order here it will overwrite the order for each individual planning
    delete newValues.order;

    return {
      ...state,
      ...newValues,
    };
  case FIELD_CONDITION:
    return {
      ...state,
      additional_data: {
        ...(state.additional_data || {}),
        conditions: {
          ...(state?.additional_data?.conditions || {}),
          op: payload.operator,
          ops: payload.conditions,
        },
      },
    };
  default:
    throw new Error('No such action', action);
  }
}
