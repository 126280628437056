import Immutable from 'immutable';
import React, {
  useMemo, useEffect, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import debug from 'debug';

import cancan from 'js/system/cancan';
import { ADMIN_GROUP_MANAGEMENT } from 'constants/permissions';
import { fetchMediaObjects, getMediaObjects } from 'src/entities/media';
import { fetchWidgetTypes } from 'src/entities/widgets';
import { fetchGroups } from 'js/redux/api/data';

import { useRequestWithPromise } from 'src/core/api';
import HelpIcon from 'components/HelpIcon';
import * as selectors from 'js/redux/selectors';

import MultiselectFilter from '../../components/MultiselectFilter';
import MediaList from './list';
import { MediaActions } from './MediaActions';

const log = debug('js:react:plan2:media-lib');
const WIDGET = 'widget';

const getMappedMediaType = (rawMediaType = '') => {
  if (rawMediaType.startsWith(WIDGET)) {
    return WIDGET;
  }

  return rawMediaType;
};

const useFromLocalStorage = (key) => {
  const [val, setVal] = useState(() => {
    // Get lazy initial value
    const value = key && window?.localStorage.getItem(key);
    return value
      ? JSON.parse(value)
      : [];
  });

  useEffect(() => {
    // Update localStorage
    window.localStorage?.setItem(key, JSON.stringify(val));
  }, [val]);

  return [val, setVal];
};

const MediaLibrary = ({
  mediaObjects, groupsById, className, groupsSortedByName,
}) => {
  const { dispatch } = useRequestWithPromise();
  const intl = useIntl();

  const containUnknownGroups = useRef(false);
  const mediaObjectsContainUnknownGroups = containUnknownGroups.current;

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredTypes, handleFilterMediaType] = useFromLocalStorage('medialib-filters');
  const [filteredGroups, handleFilterGroup] = useFromLocalStorage('medialib-filters-groups');

  const media = useMemo(() => {
    let containUG = containUnknownGroups.current;

    const imMedia = Immutable.fromJS(mediaObjects).reduce(
      (acc, item) => {
        const itemId = item.get('id');
        const groupId = item.get('group');
        const groupHasData = !!(groupId && groupsById && groupsById[groupId]);

        if (groupHasData) { return acc.set(itemId, item); }

        containUG = true;
        return acc.set(itemId, item.set('group', ''));
      },
      Immutable.Map(),
    );

    containUnknownGroups.current = containUG;
    return imMedia;
  }, [mediaObjects, groupsById]);

  const mediaTypeOptions = useMemo(() => {
    const mediaTypes = mediaObjects
      ? mediaObjects.filter((m) => m.state !== 'trashed').reduce((acc, m) => {
        return acc.add(getMappedMediaType(m.media_type).toLowerCase());
      }, Immutable.Set())
      : Immutable.Set();

    return mediaTypes.map((mediaType) => {
      return {
        id: mediaType,
        translationKey: `views.planning.media.${mediaType}`,
      };
    }).sortBy(({ id }) => id).toJS();
  }, [mediaObjects]);

  useEffect(() => {
    fetchGroups()(dispatch);
    dispatch(fetchMediaObjects());
    dispatch(fetchWidgetTypes());
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const matchesGroupFilter = (mediaObject) => {
    const groupId = mediaObject.get('group');
    return !filteredGroups?.length || filteredGroups.includes(groupId);
  };

  const matchesMediaTypeFilter = (mediaObject) => {
    const mediaType = getMappedMediaType(mediaObject.get('media_type'));
    return !filteredTypes?.length || filteredTypes.includes(mediaType);
  };

  const filteredMedia = useMemo(() => {
    return media.filter((mediaObject) => {
      return mediaObject.get('state') !== 'trashed'
        && matchesMediaTypeFilter(mediaObject)
        && matchesGroupFilter(mediaObject)
        && mediaObject
          .get('name')
          .toLowerCase()
          .indexOf(searchQuery) !== -1;
    });
  }, [media, searchQuery, filteredGroups, filteredTypes]);

  log('render');
  return (
    <div
      className={className}
      data-testid="MediaLibrary"
    >
      <div className="px-3 pt-3">
        <div className="row no-gutters">
          <h3 className="col m-0">
            <FormattedMessage id="common.media" />
            <HelpIcon
              bodyKey="help.planning.media.body"
              headerKey="help.planning.media.header"
            />
          </h3>
          <div className="d-flex align-items-center">
            <MediaActions />
            <div>
              <input
                type="text"
                className="form-control search-query"
                placeholder={intl.formatMessage({
                  id: 'common.search',
                })}
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
        <div className="row pt-3 mb-2">
          <MultiselectFilter
            wrapperClassNames="col"
            onChange={handleFilterMediaType}
            options={mediaTypeOptions}
            placeholderTranslationKey="views.planning.media.filterByMediaType"
            selectedIds={filteredTypes}
            testId="MultiselectFilter-mediaType"
          />
          {cancan.can('read', ADMIN_GROUP_MANAGEMENT) && (
            <MultiselectFilter
              wrapperClassNames="col"
              onChange={handleFilterGroup}
              options={groupsSortedByName}
              optionsCanBeUnknown={mediaObjectsContainUnknownGroups}
              placeholderTranslationKey="common.filterByGroup"
              selectedIds={filteredGroups}
              testId="MultiselectFilter-group"
            />
          )}
        </div>
      </div>
      <div className="card m-2 bshadow">
        <MediaList
          groupsById={groupsById}
          items={filteredMedia}
          hasSearch={searchQuery}
          sortBy="upload_date"
          asc={false}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    groupsById: selectors.getGroupsInObject(state),
    groupsSortedByName: selectors.getGroupsSortedByName(state),
    playlists: selectors.getPlaylists(state),
    mediaObjects: getMediaObjects(state),
  };
}

export default compose(
  connect(mapStateToProps),
  injectIntl,
)(MediaLibrary);
