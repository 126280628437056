import React from 'react';
import { FormattedMessage } from 'react-intl';
import Store from 'js/stores/ajax-store';

class HistoryMediaSidebar extends React.Component {
  state = {
    tryImage: false,
    playing: false,
    error: false,
  };

  async componentDidMount() {
    try {
      await Store(`/media/${this.props.id}/preview`).fetch();
    } catch (e) {
      this.setState({
        error: true,
      });
    }
  }

  onError = () => {
    this.setState({
      tryImage: true,
    });
  };

  onImageError = () => {
    this.setState({
      error: true,
    });
  };

  onPlaying = () => {
    this.setState({
      playing: true,
    });
  };

  render() {
    return (
      <div className="holder bg-white p-3">
        {/* <div className="m-2 card bshadow" style={{display: 'block'}}> */}
        <div className="module_head">
          <div>
            <button
              type="button"
              aria-label="Close"
              className="close"
              onClick={this.props.onClose}
            >
              <span aria-hidden="true">
                {'\u00D7'}
              </span>
            </button>
          </div>
          <div className="tabs" />
          <h3>
            <span>Media preview</span>
          </h3>

          {(() => {
            if (this.state.error) {
              return (
                <div className="alert alert-warning mb-0">
                  <FormattedMessage id="views.planning.playlists.noMediaAdded" />
                </div>
              );
            }

            if (this.state.tryImage) {
              return (
                <img
                  onError={this.onImageError}
                  alt=""
                  src={`/api/gui/media/${this.props.id}/preview`}
                />
              );
            }

            return (
              <video
                onError={this.onError}
                onPlay={this.onPlaying}
                controls
                autoPlay
                muted
                style={{
                  opacity: this.state.playing ? 1 : 0,
                  width: '100%',
                }}
              >
                <source
                  src={`/api/gui/media/${this.props.id}/preview`}
                  type="video/mp4"
                />
              </video>
            );
          })()}
        </div>
      </div>
    );
  }
}

export default HistoryMediaSidebar;
