import React from 'react';
import spinnerGif from '../../../../images/spinner.gif';

export default function Spinner(props) {
  return (
    <div
      className="w100p d-flex justify-content-center"
      {...props}
    >
      <img
        className="progressSpinner align-self-center"
        src={spinnerGif}
      />
    </div>
  );
}
