/*
* The reducer handles the three main cases, REQUEST, SUCCESS and FAIL. The
* action types for each category are filtered out from the actions.
*/

import {
  updateEntities,
  updateRequests,
  updateErrors,
} from './helpers/update-state';

import * as dataActions from './actions';

const dataActionKeys = Object.keys(dataActions);

const CRUD_START_ACTIONS = dataActionKeys
  .filter((key) => (!key.includes('_SUCCESS') && !key.includes('_FAIL')))
  .map((key) => dataActions[key]);

const CRUD_SUCCESS_ACTIONS = dataActionKeys
  .filter((key) => key.includes('_SUCCESS'))
  .map((key) => dataActions[key]);

const CRUD_FAIL_ACTIONS = dataActionKeys
  .filter((key) => key.includes('_FAIL'))
  .map((key) => dataActions[key]);

const initialState = {
  entities: {},
  errors: {},
  requests: {},
};

// The arguments of the update* methods should probably be changed to be part
// of an object, but it's a copy-paste thing that works for now.
export default function reducer(state = initialState, action = {}) {
  if (CRUD_START_ACTIONS.includes(action.type)) {
    return {
      ...state,
      errors: updateErrors(state.errors, undefined, action.meta, 'start'),
      requests: updateRequests(state.requests, action.meta, 'start'),
    };
  }

  if (CRUD_SUCCESS_ACTIONS.includes(action.type)) {
    return {
      ...state,
      requests: updateRequests(state.requests, action.meta, 'success'),
      entities: updateEntities(state.entities, action.payload, action.meta),
    };
  }

  if (CRUD_FAIL_ACTIONS.includes(action.type)) {
    return {
      ...state,
      errors: updateErrors(state.errors, action.payload, action.meta, 'fail'),
      requests: updateRequests(state.requests, action.meta, 'fail'),
    };
  }

  return state;
}
