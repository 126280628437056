import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  arrayOf, number, oneOfType, shape, string,
} from 'prop-types';
import format from 'js/react/_utils/format';
import { getMediaInObject } from 'src/entities/media';

export const ModalHeader = ({ plannings }) => {
  const { formatMessage } = useIntl();
  const mediaById = useSelector(getMediaInObject);

  return (
    <div className="mb-4">
      <h3>
        {formatMessage({ id: 'views.planning.playbackConditions.mediaPlaybackConditions' })}
      </h3>
      <div className="alert alert-info">

        {plannings.length === 1 && plannings?.map(({ id, media: mediaId } = {}) => {
          const media = mediaById[mediaId];

          return media && (
            <div
              key={id}
              className="d-flex"
            >
              <div className="w75 mr-3">
                <img alt="" src={window.ROOT_PATH + media.thumb} />
              </div>
              <div>
                <h5>
                  {media.name || media.id}
                </h5>
                {formatMessage({ id: 'common.playTime' })}
                {': '}
                <b>{format.formatSeconds(media.length)}</b>
              </div>
            </div>
          );
        })}

        {plannings.length > 1 && (
          <>
            {formatMessage({ id: 'views.planning.playbackConditions.selectedRows' })}
            {': '}
            <b>
              {plannings.length}
            </b>
          </>
        )}
      </div>

    </div>
  );
};

ModalHeader.propTypes = {
  plannings: arrayOf(shape({
    id: string,
    media: oneOfType([string, number]),
  })),
};
