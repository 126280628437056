import React from 'react';
import format from 'js/react/_utils/format';
import { DragTypes } from 'utils/constants';
import { useDrop } from 'react-dnd';

// Setup drop area for drag & drop

export const usePlDrop = ({
  dropMedia,
  planLocked,
  template,
}) => {
  return useDrop(() => ({
    accept: DragTypes.MEDIAROW,
    drop: ({ mediaIds }, monitor) => {
      if (!planLocked && !monitor.didDrop()) {
        dropMedia(
          mediaIds,
          template,
        );
      }
    },
    canDrop() {
      return !planLocked;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  }), [planLocked]);
};

const TemplateDrop = ({
  className: cls,
  max,
  template,
  planLocked,
  dropMedia,
  hoverRow,
}) => {
  const [{ isOver, canDrop }, drop] = usePlDrop({
    template,
    planLocked,
    dropMedia,
  });

  const className = `drop_tmpl px-1 d-flex flex-column justify-content-end ${cls || ''
  }${isOver || hoverRow ? ' active' : ''
  }${canDrop ? ' activate' : ''}`;

  const warningColor = '#b94a48';
  const timeStyle = {};
  const nameStyle = {};

  if (parseInt(template.totalPlanned, 10) > parseInt(max, 10)) {
    timeStyle.color = warningColor;
    nameStyle.color = warningColor;
  }

  return (
    <div
      ref={drop}
      className={className}
    >
      {template.name && (
        <div>
          <i className="icon-calendar" />
          <small style={nameStyle}>{template.name}</small>
        </div>
      )}
      {!template.name && <span>&nbsp;</span>}
      <div style={timeStyle}>
        {format.formatSeconds(template.totalPlanned)}{' / '}<b>{format.formatSeconds(max)}</b>
      </div>
    </div>
  );
};

export default TemplateDrop;
