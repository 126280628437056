/* eslint react/prop-types: 0 */
import HelpIcon from 'js/react/components/HelpIcon';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const LeftPanel = ({ children }) => (
  <div className="container pt-3">
    {children}
  </div>
);

export const Title = () => (
  <h3 className="mt-0">
    <FormattedMessage id="common.simulation" />
    <HelpIcon
      bodyKey="help.simulation.body"
      headerKey="help.simulation.header"
    />
  </h3>
);

export const RightContainer = ({ map, player }) => (
  <div className="h-100 row no-gutters flex-column flex-grow-1">
    <div>
      {map}
    </div>
    <div className="col min-height-1 min-width-1">
      {player}
    </div>
  </div>
);

export const MapContainer = ({ children }) => <div className="map-container">{children}</div>;

export const ControlsContainer = ({ children }) => (
  <div className="controls-container form-row p-2">{children}</div>
);

export const FrameControlsContainer = ({ children }) => (
  <div className="framecontrols-container">{children}</div>
);

export const HistoryContainer = ({ children }) => (
  <div className="history-container">{children}</div>
);

export const ControlSection = ({ title, children }) => (
  <div className="mb-3">
    <h6>
      {title}
    </h6>
    {children}
  </div>
);
