import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { BS_SM_BREAKPOINT } from 'js/react/sidebars';
import GsSideBar from 'utils/right-sidebar';
import SidebarContent from 'components/SidebarContent';
import SidebarNavigation from 'components/SidebarContent/SidebarNavigation';
import ClientGroupEditor from 'js/react/ClientGroups/ClientGroupEditor';

const ROUTE = '/clients';

export const ClientGroupEditorSidebar = (props) => {
  // Hack since GsSideBar needs no initial content for the animation to work
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    });
  }, []);

  const history = useHistory();
  const handleCloseSidebar = () => {
    const isMobile = window.innerWidth <= BS_SM_BREAKPOINT;
    if (!isMobile) {
      history.push('/clients');
    }
  };

  return (
    <GsSideBar href={ROUTE} onClose={handleCloseSidebar}>
      {visible && (
        <SidebarContent
          onClose={handleCloseSidebar}
          headerClass="d-sm-none modal-header flex-shrink no-gutters"
          header={(
            <SidebarNavigation onClose={handleCloseSidebar} />
          )}
        >
          <ClientGroupEditor
            inSidebar
            route={ROUTE}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...props}
            onClose={handleCloseSidebar}
          />
        </SidebarContent>
      )}
    </GsSideBar>
  );
};
