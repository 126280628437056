import React from 'react';
import { arrayOf, string } from 'prop-types';
import { uniq } from 'lodash';
import { useHistory } from 'react-router-dom';

const ROUTE = '/places';

export const placeNavigate = (visibleIds = [], toEdit, history) => {
  history.push(
    `${ROUTE}${visibleIds.length ? `/${visibleIds.join(',')}` : ''}${
      toEdit ? `/edit/${toEdit}` : ''
    }`
  );
};

export const InterceptNav = ({ visibleIds, placeToEditId = '', children }) => {
  const history = useHistory();
  // Intercept link clicks to build custom place url
  const handleClick = (e) => {
    const [action, id] = e.target.href?.split('::') || [];

    if (action && id) {
      let visible = visibleIds;
      let toEdit =
        action === 'edit' ? id !== placeToEditId && id : placeToEditId;

      e.preventDefault();

      if (action === 'view') {
        // Toggle visibility
        visible = visible.includes(id)
          ? visible.filter((i) => i !== id)
          : [...visible, id];

        // Exit edit mode if edited item is hidden
        toEdit = toEdit === id ? '' : toEdit;
      }

      if (toEdit && action !== 'view') {
        // Ensure that edited id is visible
        visible =
          toEdit === placeToEditId
            ? visible.filter((i) => i !== id)
            : uniq([...visible, toEdit]);
      }

      placeNavigate(visible, toEdit, history);
    }
  };

  return (
    <div
      role="presentation"
      className="w-100 h-100 relative"
      onClick={handleClick}
    >
      {children}
    </div>
  );
};

InterceptNav.propTypes = {
  visibleIds: arrayOf(string),
  placeToEditId: string,
};
