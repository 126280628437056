/* jshint white:true, browser:true, unused:true, undef:true, newcap:true, latedef:true, indent:4, forin:true, camelcase:true, esnext:true */
/*global module, require */

module.exports = function (emitter) {
    return function() {
        var args = arguments;
        emitter.on.apply(emitter, args);
        return {
            emitter,
            off : function () {
                return emitter.off.apply(emitter, args);
            }
        };
    };
};
