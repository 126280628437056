import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ContentEditable from 'react-contenteditable';
import { AudioSelect } from 'js/react/components/AudioPicker';

import { TEXT, TOKEN, AUDIO } from './constants';
import { KeyEventHandler } from './KeyEventHandler';
import { useAudioSequence } from './useAudioSequence';
import { TokenPicker } from './TokenPicker';
import { AudioSequencePreviewer } from './AudioSequencePreviewer';

import './style.scss';

const RemoveIcon = ({ onClick }) => {
  return (
    <button
      type="button"
      className="p-1 position-absolute badge badge-pill badge-dark border-0"
      style={{
        top: '-0.5rem',
        right: '-0.5rem',
        width: '1rem',
        height: '1rem',
        lineHeight: '0.5rem',
      }}
      onClick={onClick}
    >
      {'×'}
    </button>
  );
};

const NestedInput = ({ value, onChange }) => {
  function unsanitizeHTML(input) {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
  }

  function sanitizeHTML(text) {
    const element = document.createElement('div');
    element.innerText = text;
    return element.innerHTML;
  }

  const handleChange = (e) => {
    onChange(unsanitizeHTML(e.target.value));
  };

  return (
    <ContentEditable
      onChange={handleChange}
      className="align-self-center align-middle nested-input pr-1 pl-1 mb-1"
      html={sanitizeHTML(value)}
    />
  );
};

const trimValues = (items) => {
  // Remove empty text items
  const vals = items.filter(({ type, value }) => type !== TEXT || !!value.trim());
  return vals;
};

const TokenInjectors = ({
  onChange, sequence, caretIndex, activeConfigIndex,
}) => {
  const insertValue = (type, value) => {
    // Index of currently focused item
    const idx = activeConfigIndex || (sequence?.length || 1) - 1;
    let insertAt = idx + 1;
    const copy = [...(sequence || [])];
    const current = sequence[idx];

    // Inject component at cursor position in selected text
    if (current?.type === TEXT && caretIndex >= 0 && caretIndex < current.value.length) {
      const before = current.value.substring(0, caretIndex).trim();
      const after = current.value.substring(caretIndex).trim();

      if (before && after) {
        copy[idx] = { type: TEXT, value: before };
        copy.splice(insertAt, 0, { type: TEXT, value: after });
      } if (!before && after) {
        // Cursor at beginning of word (whitespace ignored)
        // Insert token before text input instead.
        insertAt = idx;
      }
    }

    copy.splice(insertAt, 0, { value, type });

    onChange(trimValues(copy));
  };

  return (
    <>
      <TokenPicker
        className="btn btn-light border btn-sm mr-1"
        onChange={(val) => insertValue(TOKEN, val)}
      />
      <button
        type="button"
        className="btn btn-sm btn-light border "
        onClick={() => insertValue(AUDIO)}
      >
        {'+ '}<FormattedMessage id="views.planning.announcement.audioFile" />
      </button>
    </>
  );
};

export const SpeechAudioInput = ({ audioConfig, onChange }) => {
  const sequence = useAudioSequence(audioConfig);
  const [
    [activeConfigIndex, caretIndex],
    setActiveConfig,
  ] = useState([0, 0]);

  const updateValue = (idx, type, value) => {
    const copy = [...(sequence || [])];
    copy[idx] = { value, type };
    onChange(trimValues(copy));
  };

  const removeValueAt = (idx) => {
    const copy = [...(sequence || [])];
    copy.splice(idx, 1);
    onChange(trimValues(copy));
  };

  const handleText = (idx) => (value) => {
    updateValue(idx, TEXT, value);
  };

  const handleAudio = (idx) => ({ id } = {}) => {
    updateValue(idx, AUDIO, id);
  };

  const handleToken = (idx) => (value) => {
    updateValue(idx, TOKEN, value);
  };

  return (
    <>
      <div className="d-flex mb-2">
        <TokenInjectors
          sequence={sequence}
          onChange={onChange}
          caretIndex={caretIndex}
          activeConfigIndex={activeConfigIndex}
        />
        <div className="ml-auto">
          <AudioSequencePreviewer sequence={sequence} />
        </div>
      </div>
      <KeyEventHandler
        removeValueAt={removeValueAt}
        onChangeActive={setActiveConfig}
      >
        {sequence.map(({ type, value }, idx) => {
          if (type === AUDIO) {
            return (
              <div
                key={idx}
                className="position-relative align-self-center d-inline-block align-middle mb-1"
              >
                <AudioSelect
                  selected={value}
                  onChange={handleAudio(idx)}
                />
                <RemoveIcon onClick={() => removeValueAt(idx)} />
              </div>
            );
          }

          if (type === TOKEN) {
            return (
              <div
                key={idx}
                className="position-relative align-self-center d-inline-block align-middle mb-1"
              >
                <TokenPicker
                  value={value}
                  onChange={handleToken(idx)}
                />
                <RemoveIcon onClick={() => removeValueAt(idx)} />
              </div>
            );
          }

          return (
            <NestedInput
              key={idx}
              value={value}
              onChange={handleText(idx)}
            />
          );
        })}
      </KeyEventHandler>
      <small className="text-muted">
        <a
          href="https://docs.aws.amazon.com/polly/latest/dg/supportedtags.html"
          target="_blank"
          rel="noreferrer"
        >
          <FormattedMessage id="views.planning.announcement.SSMLSuported" />
        </a>
      </small>
    </>
  );
};
