import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import cancan from 'js/system/cancan';
import { DragTypes } from 'utils/constants';
import moment from 'moment';
import { useDrag, useDrop } from 'react-dnd';

import { getHighestOrder, bulkUpdatePlannings } from 'src/entities/plannedMedia';
import { PLANNING_TIMEPLAN } from 'constants/permissions';
import MediaRow from './MediaRow';
import { Timeline } from './Timeline';

const propTypes = {
  planning: PropTypes.instanceOf(Immutable.List),
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  planLocked: PropTypes.bool,
  index: PropTypes.number,
  templateFrom: PropTypes.instanceOf(moment),
  templateTo: PropTypes.instanceOf(moment),
};

const PlanRow = ({
  index,
  noDrag,
  selected,
  planning,
  planLocked,
  templateFrom,
  templateTo,
  unordered,
  onDragEnd,
  onSelect,
  onHover,
}) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: DragTypes.PLANNEDROW,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },

    drop() {
      onDragEnd();
    },

    hover(item, monitor) {
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex || !ref.current) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      onHover(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  }, [planLocked, noDrag, index]);

  const [{ isDragging }, drag] = useDrag({
    type: DragTypes.PLANNEDROW,
    item: () => {
      return { index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }, [planLocked, noDrag, index]);

  const hasTimeplan = cancan.can('read', PLANNING_TIMEPLAN);
  const bgClass = planLocked ? '' : 'bg-white';
  const allPlanningIds = planning.map((pln) => pln.get('id')).toArray();

  if (!planLocked && !noDrag) {
    drag(drop(ref));
  }

  return (
    <table
      ref={ref}
      data-testid="planned-media-row"
      className={`${bgClass} mediagroup table rounded table-outline border table-fixed table-sm mb-1`}
      style={{
        background: isDragging ? '#eee' : '',
        opacity: isDragging ? 0.1 : 1.0,
      }}
    >
      <tbody>
        {planning.map((pl, i) => (
          <MediaRow
            key={i}
            index={index}
            rowSpan={i === 0 ? planning.size : 0}
            noDrag={planLocked || noDrag}
            planningId={pl.get('id')}
            planningIds={allPlanningIds}
            planLocked={planLocked}
            minDate={templateFrom}
            maxDate={templateTo}
            onSelect={onSelect}
            selected={selected}
            unordered={unordered}
          />
        ))}
        {hasTimeplan && (
          <Timeline
            plannings={planning}
            start={moment(planning.getIn([0, 'start']))}
            end={moment(planning.getIn([0, 'end']))}
          />
        )}
      </tbody>
    </table>
  );
};

PlanRow.propTypes = propTypes;

function mapState(state, { planning }) {
  const playlistId = planning && planning.size && planning.first().get('playlist');
  return {
    maxOrder: getHighestOrder(state, playlistId),
  };
}

export default compose(
  connect(mapState, { bulkUpdatePlannings }),
)(PlanRow);
