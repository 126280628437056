import React, { useEffect, useMemo, useState } from 'react';
import PropTypes, { arrayOf, string } from 'prop-types';
import { useSelector } from 'react-redux';
import * as selectors from 'js/redux/selectors';
import {
  fetchTriggerConditions,
} from 'js/redux/api/data';
import { useRequestWithPromise } from 'src/core/api';
import { ApiErrorMessage } from 'src/components/Error';

const propTypes = {
  playlistId: PropTypes.string.isRequired,
  conditionKey: PropTypes.string.isRequired,
  selected: arrayOf(string).isRequired,
  onChange: PropTypes.func.isRequired,
};

export const ConditionPicker = ({
  selected, playlistId, conditionKey, onChange,
}) => {
  const { dispatch, error } = useRequestWithPromise();
  const [saving, setSaving] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const conditions = useSelector((state) => selectors.getTriggerConditions(state, playlistId, conditionKey));
  const filteredItems = useMemo(() => {
    const q = searchQuery.toLocaleLowerCase();
    return Object.values(conditions || {})
      .filter((cond) => cond.name.toLocaleLowerCase().includes(q));
  }, [conditions, searchQuery]);

  useEffect(() => {
    if (!conditions?.length) {
      fetchTriggerConditions(playlistId, conditionKey)(dispatch);
    }
  }, []);

  useEffect(() => {
    setSaving(false);
  }, [selected]);

  const handleSelect = (item) => {
    const id = item.id.toString();
    const sel = [...selected];
    const idx = sel.indexOf(id);

    if (idx === -1) {
      sel.push(id);
    } else {
      sel.splice(idx, 1);
    }

    setSaving(true);
    onChange(conditionKey, sel);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  if (error) {
    <ApiErrorMessage error={error} />;
  }

  return (
    <div>
      <input
        type="text"
        className="form-control mb-2"
        onChange={handleSearch}
        placeholder="Type to search"
      />
      {!conditions?.length && 'Loading...'}
      {filteredItems.map((cond) => (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label
          key={cond.id}
          className="d-block"
        >
          <input
            type="checkbox"
            checked={selected.includes(cond.id.toString())}
            onChange={() => !saving && handleSelect(cond)}
            disabled={saving}
            className="mt-0"
          /> {cond.name}
        </label>
      ))}
    </div>
  );
};

ConditionPicker.propTypes = propTypes;
