import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import Chart from 'chart.js';
import Legend from './Legend';

class BarChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipData: Immutable.List(),
      tooltipOffset: [0, 0],
    };
  }

  componentDidUpdate(pp) {
    if (pp.data !== this.props.data) {
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart && this.chart.destroy();
  }

  setCanvas = (canvas) => {
    this.canvas = canvas;
    this.initChart();
  }

  updateChart() {
    if (this.chart && this.props.data) {
      this.chart.data.datasets = this.props.data.datasets;
      this.chart.update();
    } else {
      this.initChart();
    }
  }

  initChart = () => {
    if (this.canvas && this.props.data) {
      // const opts = {
      //   animationEasing: 'easeOut',
      //   animationSteps: 1,
      //   customTooltips: t => {
      //     if (!t) {
      //       this.setState({ tooltipData: Immutable.List() });
      //       return;
      //     }

      //     let tooltipData = Immutable.fromJS(t.labels);

      //     tooltipData = tooltipData.map((l, i) => {
      //       return Immutable.fromJS({ name: l, color: t.legendColors[i] });
      //     });

      //     this.setState({
      //       tooltipData,
      //       tooltipOffset: [t.x, 30],
      //     });
      //   },
      // };

      const ctx = this.canvas.getContext('2d');
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: this.props.data,
        // options: options
        options: {
          legend: false,
          maintainAspectRatio: false,
          // scales: {
          //   xAxes: [{
          //     display: false
          //   }]
          // },
        },
      });
    }
  }

  tooltip() {
    if (this.state.tooltipData.size === 0) {
      return <span />;
    }

    const rows = this.state.tooltipData.slice(0, 10).map((l, k) => {
      const s = {
        background: l.getIn(['color', 'fill']),
        width: 20,
        margin: 2,
        display: 'inline-block',
      };
      return (
        <div key={k}>
          <div style={s}>&nbsp;</div>&nbsp;{l.get('name')}
        </div>
      );
    });

    const wrapperStyle = {
      position: 'absolute',
      top: this.state.tooltipOffset[1],
      left: this.state.tooltipOffset[0],
      transform: 'translateX(25%)',
      border: '1px solid black',
      padding: '10px 8px',
      borderRadius: 5,
      opacity: 0.85,
      background: 'black',
      color: 'white',
    };

    const nbrNotShow = this.state.tooltipData.size - 10;

    return (
      <div style={wrapperStyle}>
        {rows}
        {nbrNotShow > 0 && (
          <div>
            {'+'}
            {nbrNotShow}
          </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <div className="row">
        <div className="col-8">
          <canvas
            ref={this.setCanvas}
            width="500px"
            height="350px"
          />
        </div>
        <div className="col-4">
          <Legend
            dataset={this.props.data.datasets || []}
            limit={10}
          />
        </div>
      </div>
    );
  }
}

BarChart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BarChart;
