/**
 * MapActions
 */
import MessageConstants from "../constants/message-constants";
import GSDispatcher from "../dispatchers/gs-dispatcher";

/**
 * Dict of available MapActions
 */

/**
 * @param {string} text Message text
 */
export const success = function (text) {
  GSDispatcher.handleViewAction({
    type: MessageConstants.MESSAGE_SUCCESS,
    data: text,
  });
};

/**
 * @param {string} text Message text
 */
export const error = function (text) {
  GSDispatcher.handleViewAction({
    type: MessageConstants.MESSAGE_ERROR,
    data: `ERROR: ${text}`,
  });
};

/**
 * @param {string} text Message text
 */
export const info = function (text) {
  GSDispatcher.handleViewAction({
    type: MessageConstants.MESSAGE_INFO,
    data: text,
  });
};

/**
 * @param {integer} index Message index
 */
export const hide = function (index) {
  GSDispatcher.handleViewAction({
    type: MessageConstants.HIDE_MESSAGE,
    data: index,
  });
};
