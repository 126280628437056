import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { fetchMediaObjects, getMediaObjects } from 'src/entities/media';
import {
  deleteClientGroup,
  fetchClientGroup,
  getClientGroupById,
  updateClientGroup,
} from 'src/entities/clientGroups';
import { success as successMessage } from 'js/react/actions/message-actions';
import Card from 'components/Card';
import { clientGroupShape, geoId } from 'utils/shapes';
import DeleteConfirm from 'components/DeleteConfirm';

import ClientLayout from './ClientLayout';
import ClientSettings from './ClientSettings';
import { LayoutEditorSidebar } from '../LayoutSidebar';

const ROUTE = '/clientgroups';

const propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      clientId: geoId.isRequired,
    }),
  }),
  fetchClientGroup: PropTypes.func.isRequired,
  updateClientGroup: PropTypes.func.isRequired,
  deleteClientGroup: PropTypes.func.isRequired,
  clientGroup: clientGroupShape,
};

class ClientGroupEditor extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = this.getState(props.clientGroup);
  }

  componentDidMount() {
    this.loadGroupData();
  }

  getState({ name } = {}) {
    return {
      name: name || '',
      hasChanges: false,
    };
  }

  loadGroupData = () => {
    this.props.fetchClientGroup(this.props.match.params.clientId);
    // For layout preview
    (!this.props.media || !this.props.media.length) && this.props.fetchMediaObjects();
  }

  handleNameChange = (name) => {
    this.setState({
      name,
      hasChanges: true,
    });
  }

  handleSave = () => {
    const { intl } = this.props;
    this.setState({
      hasChanges: false,
    });

    this.props.updateClientGroup({
      ...this.props.clientGroup,
      name: this.state.name,
    }).then(() => successMessage(intl.formatMessage({ id: 'views.layout.ClientGroupUpdated' })));
  }

  handleDelete = () => {
    this.props.history.replace(this.props.route || ROUTE);
    this.props.deleteClientGroup(this.props.match.params.clientId);
  }

  renderActions() {
    const { inSidebar } = this.props;
    const { hasChanges } = this.state;

    return (
      <div className="mt-3">
        {!inSidebar && (
          <button
            type="button"
            className="btn btn-primary mr-1"
            onClick={hasChanges ? this.handleSave : undefined}
            disabled={!hasChanges}
          >
            <FormattedMessage id="common.save" />
          </button>
        )}
        <DeleteConfirm
          onDelete={this.handleDelete}
          className="d-inline-block mr-2 mb-0 text-nowrap"
        >
          <i className="icon-trash icon-white mr-1" /> <FormattedMessage id="common.remove" />
        </DeleteConfirm>
      </div>
    );
  }

  render() {
    const {
      inSidebar,
      clientGroup = {},
      onClose,
    } = this.props;
    const clientId = parseInt(this.props.match.params?.clientId, 10);

    if (inSidebar) {
      return (
        <div className="holder bg-white p-3">
          <div className="d-flex mb-3">
            <h3 className="m-0">
              {clientGroup.name || '...'}
            </h3>
            <button
              type="button"
              className="close ml-auto"
              aria-label="Close"
              onClick={onClose}
            >
              <span aria-hidden="true">
                {'\u00D7'}
              </span>
            </button>
          </div>
          <div className="rounded border p-3 mb-4">
            <ClientSettings
              clientId={clientId}
              name={this.state.name}
              onChange={this.handleNameChange}
              onBlur={this.state.hasChanges && this.handleSave}
            />
            {this.renderActions()}
          </div>
          <LayoutEditorSidebar
            isNested
            clientId={clientId}
          />
        </div>
      );
    }

    return (
      <Card
        label={clientGroup.name || '...'}
      >
        <div className="row">
          <div className="col-12 col-lg-6 col-xl-5 mr-auto mb-3">
            <ClientSettings
              clientId={clientId}
              name={this.state.name}
              onChange={this.handleNameChange}
            />
          </div>
          <div className="col-12 col-lg-6 col-xl-5 border-left">
            <ClientLayout
              clientId={clientId}
            />
          </div>
        </div>
        {this.renderActions()}
      </Card>
    );
  }
}

function mapState(state, { match }) {
  return {
    clientGroup: getClientGroupById(match.params.clientId)(state) || undefined,
    media: getMediaObjects(state),
  };
}

const mapDispatch = {
  fetchClientGroup,
  updateClientGroup,
  deleteClientGroup,
  fetchMediaObjects,
};

ClientGroupEditor.propTypes = propTypes;

const ClientGroupEditorWithRouter = withRouter(ClientGroupEditor);

function Preloader(props) {
  // Instead of having to monitor prop changes with
  // componentDidUpdate to update state we relaunch
  // the component when data is loaded by setting a key.
  return (
    <ClientGroupEditorWithRouter
      key={props.clientGroup ? 'loaded' : 'loading'}
      loading={!!props.clientGroup}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

export default connect(mapState, mapDispatch)(injectIntl(Preloader));
