import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  type: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  loading: PropTypes.bool,
  hasError: PropTypes.bool,
};
const defaultProps = {
  type: 'text',
};

const FormField = ({
  hasError,
  loading,
  name,
  onBlur: handleBlur,
  onChange: handleChange,
  title,
  type = 'text',
  value,
}) => {
  const className = `form-control mb-1${hasError ? ' error' : ''}`;

  return (
    <tr className="control-group">
      <td>
        <label htmlFor={name}>
          <small>
            <span>
              {title}
              {':'}
            </span>
          </small>
        </label>
      </td>
      <td>
        <input
          type={type}
          className={className}
          disabled={loading}
          name={name}
          onBlur={handleBlur}
          onChange={handleChange}
          value={value}
        />
      </td>
    </tr>
  );
};

FormField.propTypes = propTypes;
FormField.defaultProps = defaultProps;

export default FormField;
