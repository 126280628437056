import { buildApiMetaFactory, buildGetType, createCrudActionCreators } from 'src/core/api';
import { getCsrfToken } from 'src/core/api/apiRequest/helpers';

import { HISTORY_COLLECTION, COLLECTION, URL } from './constants';

export const {
  readAll: fetchClients,
  update: updateClient,
  destroy: deleteClient,
} = createCrudActionCreators({ collection: COLLECTION, url: URL });

export const fetchClientHistory = (clientId) => {
  return {
    type: buildGetType(HISTORY_COLLECTION),
    meta: buildApiMetaFactory({
      collection: HISTORY_COLLECTION,
      url: `/clients/${clientId}/history`,
    })({
      reset: true,
      processResponse: (data) => {
        // Api returns a list of versions of the same client data.
        // Since all posts will have the same id, we need to process the data
        // to make it work with the redux store.
        return {
          id: data[0]?.id,
          history: data,
        };
      },
    }),
  };
};
