import React from 'react';

const BaseRouteContext = React.createContext();

export function BaseRouteProvider({ route = '#', children }) {
  return (
    <BaseRouteContext.Provider value={route}>
      {children}
    </BaseRouteContext.Provider>
  );
}

export function baseRouteConsumer(Component) {
  return function RouteConsumer(props) {
    return (
      <BaseRouteContext.Consumer>
        {(route) => (
          <Component {...props} route={route} />
        )}
      </BaseRouteContext.Consumer>
    );
  };
}
