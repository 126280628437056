/*
    calculateTextDuration should mirror the method used in geo-player/components/mbta/announcement
*/

const ROW_LIMIT = 15; // Max chars in one chunk.
export const INTERVAL = 5000;

function splitText(text = '') {
  const words = text.trim().split(' ');
  return words.reduce((acc, word) => {
    var lastChunk = acc[acc.length - 1];
    
    if ((lastChunk.length + word.length) <= (ROW_LIMIT - 1)) {
      lastChunk += ' ' + word;
      acc[acc.length - 1] = lastChunk.trim();
    } else {
      acc.push(word);
    }

    return acc;
  }, ['']);
}

export function calculateTextDuration(text = '') {
  const chunks = splitText(text);
  return (chunks.length * INTERVAL) / 1000;
}

export function calculateAudioDuration(audioIds, audio, tags) {
  if (!audioIds || !audioIds.length || !audio) {
    return 1;
  }

  // Calculate new min duration based on selected audio.
  const audioById = audio.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

  const tagIds = tags && tags.map(({tag}) => tag);

  return Math.ceil(
    audioIds.reduce((acc, id) => {
      const item = audioById[id];
      let duration = 0;
      if (item && item.length) {
        duration = item.length;
      } else if (tagIds && tagIds.indexOf(id) !== -1) {
        // Add a default duration for destination and next stop
        duration = 5;
      }

      return (acc += duration);
    }, 0)
  );
}

export function calculateMediaDuration(mediaIds = [], allMedia = {}) {
  return mediaIds.filter((id) => id).reduce((acc, id) => {
    const media = allMedia[id];
    return media
      ? acc + media.length
      : acc;
  }, 0);
}
