import React from 'react';
import PropTypes from 'prop-types';
import SingleMediaPicker from 'components/MediaPicker/SingleMediaPicker';

const propTypes = {
  selected: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  audio: PropTypes.array,
  disabled: PropTypes.bool,
};

class MediaPicker extends React.PureComponent {
  handleRemove = (index) => {
    const mediaIds = [...(this.props.selected || [])];
    const selected = [...(this.props.selectedMedia || [])];

    mediaIds.splice(index, 1);
    selected.splice(index, 1);
    this.props.onChange(mediaIds, selected);
  }

  handleChange = (index, id, media) => {
    const mediaIds = [...(this.props.selected || [])];
    const selected = [...(this.props.selectedMedia || [])];

    mediaIds[index] = id;
    selected[index] = media;
    this.props.onChange(mediaIds, selected);
  }

  handleMedia = (index) => (id, media) => {
    if (id) {
      this.handleChange(index, id, media);
    } else {
      this.handleRemove(index);
    }
  }

  handleAddRow = () => {
    const selected = [...this.props.selected];
    selected.push(null);
    this.props.onChange(selected);
  }

  renderMediaList() {
    const { selected } = this.props;

    if (!selected || selected.length === 0) {
      return (
        <em className="d-block mb-2">
          No media selected...
        </em>
      );
    }

    return selected.map((id, index) => (
      <SingleMediaPicker
        key={`${id}_${index}`}
        selected={id}
        onChange={this.handleMedia(index)}
        mediaFilter={this.props.mediaFilter}
      />
    ));
  }

  render() {
    return (
      <div className="">
        {this.renderMediaList()}
        <button
          type="button"
          className="btn btn-light border btn-sm mr-1"
          onClick={!this.props.disabled && this.handleAddRow}
          disabled={this.props.disabled}
        >
          <i className="icon-plus" /> Add Media
          </button>
      </div>
    );
  }
}

MediaPicker.propTypes = propTypes;

export default MediaPicker;
