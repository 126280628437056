import React, { memo } from 'react';
import { compose } from 'redux';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getClientGroupsInArray } from 'src/entities/clientGroups';
import Dropdown, { DropdownToggle, DropdownContent } from 'components/Dropdown';
import { useRequestWithPromise } from 'src/core/api';
import { fetchClientGroupLayouts } from 'src/entities/layouts';
import * as MsgActions from 'js/react/actions/message-actions';

function LayoutCopy({ onSelect, exclude = [] }) {
  const { dispatch } = useRequestWithPromise();
  const clientGroups = useSelector(getClientGroupsInArray);

  if (!clientGroups || !clientGroups.length) {
    return null;
  }

  const handleSelect = (selected) => async () => {
    const clientGroup = clientGroups.find(({ id }) => id === selected);
    if (clientGroup) {
      const response = await dispatch(fetchClientGroupLayouts(selected));
      const layout = response?.payload?.[0];

      if (layout) {
        onSelect(layout);
      } else {
        MsgActions.error('Invalid layout');
      }
    }
  };

  return (
    <Dropdown right closeOnClick>
      <DropdownToggle>
        <FormattedMessage id="views.layout.copyLayout" />
      </DropdownToggle>
      <DropdownContent>
        <div className="alert alert-info mx-2 mb-1">
          <FormattedMessage id="views.layout.copyLayoutInfo" />
        </div>
        <div className="overflow-y-auto mh-30r">
          {clientGroups.filter(
            ({ id }) => !exclude.includes(id),
          ).map(({ name, id } = {}) => (
            <button
              key={id}
              type="button"
              className="dropdown-item"
              onClick={handleSelect(id)}
            >
              {name}
            </button>
          ))}
        </div>
      </DropdownContent>
    </Dropdown>
  );
}

export default compose(memo)(LayoutCopy);
