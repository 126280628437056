import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

const Cell = ({ value, onChange }) => {
  // All this state management to handle '' vs 0
  const [val, setVal] = useState(value);

  useEffect(() => {
    if (value != val) { // eslint-disable-line eqeqeq
      setVal(value);
    }
  }, [value]);

  function handleChange(e) {
    onChange(e);
    setVal(parseInt(e.target.value, 10) || '');
  }

  function handleBlur(e) {
    setVal(parseInt(e.target.value, 10) || 0);
  }

  return (
    <td>
      <div className="w75">
        <input
          type="number"
          className="form-control w-100 h30 mb-0"
          value={val}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
    </td>
  );
};

function Row({
  id, left, top, width, height, selected, onChange, onSelect,
}) {
  return (
    <tr
      key={id}
      className={selected ? 'alert-info' : ''}
      onClick={onSelect || undefined}
      onFocus={onSelect || undefined}
    >
      <td className="align-middle">
        <b>{id}</b>
      </td>
      <Cell
        value={parseInt(left, 10)}
        onChange={onChange('left')}
      />
      <Cell
        value={parseInt(top, 10)}
        onChange={onChange('top')}
      />
      <Cell
        value={parseInt(width, 10)}
        onChange={onChange('width')}
      />
      <Cell
        value={parseInt(height, 10)}
        onChange={onChange('height')}
      />
    </tr>
  );
}

class LayoutTable extends React.PureComponent {
  handleChange = (areaId) => (attr) => (e) => {
    const area = this.props.areas.find(({ id }) => id === areaId);
    const { value } = e.target;

    if (area) {
      this.props.onChange({
        ...area,
        [attr]: value,
      });
    }
  }

  handleSelect = (id) => () => this.props.onSelect(id)

  renderAreas() {
    return this.props.areas.map((area) => (
      <Row
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...area}
        key={area.id}
        selected={area.id === this.props.selected}
        onChange={this.handleChange(area.id)}
        onSelect={this.handleSelect(area.id)}
      />
    )).reverse();
  }

  render() {
    return (
      <table
        className="table table-bordered table-sm"
      >
        <thead>
          <tr>
            <th>
              <FormattedMessage id="views.layout.layoutName" />
            </th>
            <th>
              <FormattedMessage id="views.layout.areaDistanceLeft" />
            </th>
            <th>
              <FormattedMessage id="views.layout.areaDistanceTop" />
            </th>
            <th>
              <FormattedMessage id="views.layout.areaWidth" />
            </th>
            <th>
              <FormattedMessage id="views.layout.areaHeight" />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.renderAreas()}
        </tbody>
      </table>
    );
  }
}

export default LayoutTable;
