import React, {
  memo, useMemo, useEffect, useState,
} from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import * as selectors from 'js/redux/selectors';
import format from 'js/react/_utils/format';
import { getMediaInObject } from 'src/entities/media';

function toHalfHours(timeInMilliseconds) {
  return Math.round(((timeInMilliseconds / 1000) / 60) * 30);
}

function useColumns(plannings) {
  // Generate columns for all media overlaps.
  const allMedia = useSelector(getMediaInObject);
  const fromDate = useSelector(selectors.getFromDate).valueOf();
  const toDate = useSelector(selectors.getToDate).valueOf();
  const [columns, setColumns] = useState([]);

  const getLength = (pl) => get(allMedia, [pl.get('media'), 'length'], 0);
  const getLongest = (pls) => pls.reduce((acc, pl) => {
    return Math.max(getLength(pl), acc);
  }, 0);
  const getLowerAndUpper = (plannedMedia) => {
    return [
      toHalfHours(Math.max(
        (plannedMedia.get('start') || fromDate).valueOf(),
        fromDate,
      )),
      toHalfHours(Math.min(
        (plannedMedia.get('end') || toDate).valueOf(),
        toDate,
      )),
    ];
  };

  useEffect(() => {
    // Debounce calculation for performance
    const timer = setTimeout(() => {
      const low = toHalfHours(fromDate);
      const high = toHalfHours(toDate);
      const tot = high - low;

      // Get all start and end times in order
      const breakpoints = Array.from(plannings.reduce((acc, pl) => {
        return new Set([...acc, ...getLowerAndUpper(pl)]);
      }, new Set([low]))).sort();

      // Problem: Grouped media (block_group > 0) should only be counted as one item.
      // Solution: group all items with block_group > 0.
      const groupedPlannings = plannings.groupBy((pl) => (pl.get('block_group') > 0
        ? pl.get('block_group')
        : pl.get('id')));

      // Get total time for all media in each interval
      const cols = breakpoints
        .slice(0, -1) // Ignore last end breakpoint
        .map((breakpoint, index) => {
          const nextBP = breakpoints[index + 1];
          const time = groupedPlannings.reduce((totalLength, group) => {
          // All plannings in a groups have the same start and end times so we only need the first.
            const pl = group.first();
            const [lower, upper] = getLowerAndUpper(pl);

            // Add time if media overlaps the breakpoint
            if (lower <= breakpoint && upper > breakpoint) {
              // Only count longest item in a group
              const length = group.size > 1 ? getLongest(group) : getLength(pl);
              return totalLength + length;
            }

            return totalLength;
          }, 0);

          return {
            width: Math.round(((nextBP - breakpoint) / tot) * 100),
            height: time,
          };
        });

      setColumns(cols);
    }, 250);

    return () => {
      // Clear timeout on rerender
      clearTimeout(timer);
    };
  }, [allMedia, plannings, fromDate, toDate]);

  return columns;
}

export const HeatMap = memo(({ plannings, maxTime, limits }) => {
  const mt = parseInt(maxTime, 10); // maxTime can apparently be a string sometimes...
  const columns = useColumns(plannings, limits);
  const maxHeight = useMemo(() => columns.reduce(
    (acc, { height }) => Math.max(acc, height),
    mt + (0.05 * mt), // Add 5% to max height to ensure limitline is always visible.
  ), [columns]);
  const limitLine = Math.ceil((mt / maxHeight) * 100);
  const maxTimeString = format.formatSeconds(mt);

  return (
    <div className="border rounded bg-light">
      <div
        style={{ height: 50 }}
        className="d-flex align-items-end position-relative px-2 overflow-hidden"
      >
        {columns.map(({ height, width }, i) => {
          const columnHeight = Math.round((height / maxHeight) * 100);
          const tooLong = columnHeight > limitLine;
          const overShoot = ((columnHeight - limitLine) / columnHeight) * 100;
          const time = format.formatSeconds(height);

          return !!width && (
            <div
              key={i}
              title={`${time} / ${maxTimeString}`}
              className={
                `border rounded-top shadow ${tooLong
                  ? 'alert-danger'
                  : 'alert-success'}`
              }
              style={{
                width: `${width}%`,
                height: `${columnHeight}%`,
                background: tooLong
                  ? `linear-gradient(to bottom, #ff9ba4 0%, #dae7da ${overShoot}%, #dae7da 100%)`
                  : undefined,
                transition: 'all 200ms cubic-bezier(0.7, 0, 0.175, 1) 0s',
              }}
            />
          );
        })}
        {limitLine < 100 && (
          <div
            style={{
              position: 'absolute',
              borderTop: '1px dashed #aaa',
              top: `${100 - limitLine}%`,
              left: 0,
              right: 0,
              color: '#aaa',
              fontSize: '0.6rem',
              transition: 'all 200ms cubic-bezier(0.7, 0, 0.175, 1) 0s',
            }}
          >
            <FormattedMessage id="common.limit" />
            {' '} {format.formatSeconds(mt)}
          </div>
        )}
      </div>
      <div className="d-flex border-top px-2">
        {columns.map(({ height, width }, i) => {
          const columnHeight = Math.round((height / maxHeight) * 100);
          const tooLong = columnHeight > limitLine;
          const time = format.formatSeconds(height);

          return !!width && (
            <div
              key={i}
              title={`${time} / ${maxTimeString}`}
              className={`overflow-hidden truncate-fade nowrap ${tooLong ? 'text-danger' : ''}`}
              style={{
                width: `${width}%`,
              }}
            >
              <small>
                {tooLong && <i className="icon-exclamation-sign mr-1" />}
                {time}
              </small>
            </div>
          );
        })}
      </div>
    </div>
  );
});
