import React from "react";
import PropTypes from "prop-types";
import Button from "../Button";
import { DropdownContentContext } from "./DropdownContent";

const style = {
  top: 0,
  lineHeight: "inherit",
};

function NestledDropdown({ label, contentId, children }) {
  return (
    <DropdownContentContext.Consumer>
      {({ activateContent, setChildren } = {}) => {
        setChildren(contentId, children);

        return (
          <Button
            className="dropdown-item d-flex justify-content-between"
            onClick={(e) => {
              e.stopPropagation();
              activateContent(contentId);
            }}
          >
            <span>{label}</span>
            <i
              className="halflings halflings-chevron-right ml-2 align-self-center"
              style={style}
            />
          </Button>
        );
      }}
    </DropdownContentContext.Consumer>
  );
}

NestledDropdown.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  contentId: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
};

export default NestledDropdown;
