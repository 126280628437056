import React from 'react';
import { func, shape, string } from 'prop-types';
import stringUtils from 'js/react/_utils/stringUtils';

// Components
import { createPlaylistPlace, fetchPlaylistPlaces } from 'src/entities/playlistPlaces';
import { useRequestWithPromise } from 'src/core/api';
import { sortBy } from 'lodash';
import ConditionDropdown from './ConditionDropdown';

const propTypes = {
  place: shape({
    name: string,
  }),
  handleTriggerChange: func,
  playlistId: string,
};

function PlaylistPlaceRow({
  place, handleTriggerChange, playlistId,
}) {
  const { dispatch } = useRequestWithPromise();
  const { name, triggers } = place;
  const sortedTriggers = sortBy(triggers, (t) => t.name);

  async function handleConditions(trigger) {
    const newTriggers = triggers.reduce((acc, tr) => {
      acc.push(tr.name === trigger.name
        ? trigger
        : tr);
      return acc;
    }, []);

    await dispatch(createPlaylistPlace(playlistId, {
      ...place,
      triggers: newTriggers,
      id: parseInt(place.id, 10),
    }));
    dispatch(fetchPlaylistPlaces(playlistId));
  }

  return (
    <tr>
      <td>
        {stringUtils.capitalize(name)}
      </td>
      <td className="py-0">
        {sortedTriggers.map((trigger) => {
          const className = `border-0 pointer badge my-1 ml-1 ${trigger.active ? 'badge-success' : 'badge-secondary'}`;
          return (
            trigger.name && (
              <div key={trigger.name}>
                <button
                  type="button"
                  className={className}
                  onClick={handleTriggerChange(place, trigger.name, !trigger.active)}
                >
                  {trigger.name}
                </button>
              </div>
            )
          );
        })}
      </td>
      <td style={{ overflow: 'visible' }} className="pb-0 pt2">
        {sortedTriggers.map((trigger) => (
          trigger.active
            ? trigger.name && trigger.conditions && (
              <ConditionDropdown
                key={trigger.name}
                playlistId={playlistId}
                trigger={trigger}
                onChange={handleConditions}
              />
            )
            : trigger.name && (
              <div
                key={trigger.name}
                style={{ height: 26, clear: 'both' }}
              />
            )
        ))}
      </td>
      <td>{stringUtils.capitalize(place.type)}</td>
    </tr>
  );
}

PlaylistPlaceRow.propTypes = propTypes;

export default PlaylistPlaceRow;
