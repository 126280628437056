import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

class AudioPlayer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      play: false,
    };
  }

    handleAudioToggle = () => {
      this.setState({
        play: !this.state.play,
      });
    }

    handleEnd = () => {
      this.setState({
        play: false,
      });
    }

    render() {
      const btnClass = (this.props.className || 'btn btn-info align-top px-1 py-1') + (this.state.play ? ' active' : '');
      return (
        <button
          type="button"
          className={btnClass}
          onClick={this.handleAudioToggle}
        >
          {this.state.play
            ? <i className="icon-stop icon-white" />
            : <i className="icon-play icon-white" />
          }
          {this.props.children}
          {this.state.play && (
            <audio
              src={this.props.src}
              autoPlay
              onEnded={this.handleEnd}
              onError={this.handleEnd}
            >
              <FormattedMessage id="views.planning.media.noAudioSupport" />
            </audio>
          )}
        </button>
      );
    }
}

AudioPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default AudioPlayer;
