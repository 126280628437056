import React from 'react';
import './GSColumns.scss';

// MOBILE
// bg-white

export default function GSColumnsLayout({
  topBar, leftCol, rightCol, sidebar, className = '', onClick,
}) {
  return (
    <div
      role="presentation"
      className={`position-relative h-100 ${className}`}
      onClick={onClick}
    >
      <div className={`h-100 gs-col-layout${topBar ? ' with-top-bar' : ''}`}>
        {topBar && (
          <div className="gs-grid-top">
            {topBar}
          </div>
        )}
        <div
          className="gs-col-left bg-white position-relative"
          data-testid="default-column-left"
        >
          <div className="position-absolute h-100 w-100 overflow-y-auto">
            {leftCol}
          </div>
        </div>
        <div
          className="gs-col-right position-relative"
          data-testid="default-column-right"
        >
          <div className="position-absolute d-none d-sm-block h-100 w-100 overflow-auto">
            {rightCol}
          </div>
          {sidebar}
        </div>
      </div>
    </div>
  );
}
