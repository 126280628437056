import { injectIntl } from 'react-intl';
import React from 'react';
import { NavLink, Switch, Route } from 'react-router-dom';

import cancan from 'js/system/cancan';
import {
  ADMIN_GROUP_MANAGEMENT,
  ADMIN_MEDIABUILD,
  ADMIN_USER_ACTIVITY,
  ADMIN_USER_MANAGEMENT
} from 'constants/permissions';

const AdminMenu = injectIntl(
  ({ intl: { formatMessage } }) => {
    const modules = [
      {
        title: formatMessage({ id: 'views.admin.mediaBuild.mediaBuild' }),
        path: '/admin/mediabuild',
        permission: ADMIN_MEDIABUILD,
      },
      {
        title: formatMessage({ id: 'views.admin.userActivity.userActivity' }),
        path: '/admin/activity',
        permission: ADMIN_USER_ACTIVITY,
      },
      {
        title: formatMessage({
          id: 'views.admin.userManagement.userManagement',
        }),
        path: '/admin/users',
        permission: ADMIN_USER_MANAGEMENT,
      },
      {
        title: formatMessage({
          id: 'views.admin.groupManagement.groupManagement',
        }),
        path: '/admin/groups',
        permission: ADMIN_GROUP_MANAGEMENT,
      },
    ];

    const activeModules = modules.filter((m) => {
      return cancan.can('read', m.permission);
    });

    const items = activeModules.map((m) => {
      return (
        <NavLink
          key={m.path}
          activeClassName={'active'}
          className='nav-link'
          to={m.path}
        >
          <i className="icon-chevron-right float-right" />
          {m.title}
        </NavLink>
      );
    });

    return (
      <div className="card bshadow p-2">
        <nav className="nav flex-column nav-pills">
          {items}
        </nav>
      </div>
    );
  },
);

function Admin({ routes }) {
  return (
    <div className="container-fluid h-100 position-absolute w-100">
      <div className="row">
        <div className="ml-3 my-3" style={{ width: 228 }}>
          <AdminMenu />
        </div>
        <div className="col card bshadow m-3 p-3">
          <Switch>
            {routes.map((route, i) => (
              <Route key={i} {...route} />
            ))}
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default Admin;
