import React from 'react';
import { injectIntl } from 'react-intl';
import cancan from 'js/system/cancan';
import { Sortable } from 'js/react/_utils/sort-util';
import { ADMIN_GROUP_MANAGEMENT } from 'constants/permissions';

function ListHeader({
  intl, selectAll, allSelected, ...params
}) {
  return (
    <thead>
      <tr>
        <th className="border-0 w10 d-none d-md-table-cell" />
        <th className="border-0 w10">
          <input type="checkbox" checked={allSelected} onChange={selectAll} />
        </th>
        <th className="border-0 w50 d-none d-md-table-cell" />
        <th className="border-0">
          <Sortable
            {...params}
            sort="name"
            label={intl.formatMessage({ id: 'common.name' })}
          />
        </th>
        <th className="border-0 text-truncate">
          <Sortable
            {...params}
            sort="length"
            label={(
              <span className="d-none d-md-table-cell">
                {intl.formatMessage({ id: 'common.playTime' })}
              </span>
            )}
          />
        </th>
        <th
          className="border-0 text-truncate"
        >
          <Sortable
            {...params}
            sort="media_type"
            label={(
              <span className="d-none d-md-table-cell">
                {intl.formatMessage({ id: 'common.type' })}
              </span>
            )}
          />
        </th>
        {cancan.can('read', ADMIN_GROUP_MANAGEMENT) && (
          <th
            className="sortList"
          >
            <Sortable
              {...params}
              sort="group"
              label={(
                <span className="d-none d-md-table-cell">
                  {intl.formatMessage({ id: 'common.group' })}
                </span>
              )}
            />
          </th>
        )}
        <th className="border-0 d-none d-md-table-cell">
          <Sortable
            {...params}
            sort="upload_date"
            label={intl.formatMessage({ id: 'common.uploaded' })}
          />
        </th>
      </tr>
    </thead>
  );
}

export default injectIntl(ListHeader);
