import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';

import store from 'js/redux/store';
import I18nProvider from './I18nProvider';

const propTypes = {
  children: PropTypes.node.isRequired,
};

function ContextProvider({ children }) {
  return (
    <Provider store={store}>
      <I18nProvider>{children}</I18nProvider>
    </Provider>
  );
}

ContextProvider.propTypes = propTypes;

export default ContextProvider;
