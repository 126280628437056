import { useSelector } from 'react-redux';
import { getLanguage, getDefaultLanguage } from 'js/redux/selectors';

// Returns an object containing the correct translation depending on selected/existing languages
export const useGetTranslation = () => {
  const language = useSelector(getLanguage);
  const defaultLanguage = useSelector(getDefaultLanguage);

  return (dictionary) => {
    try {
      if ({}.hasOwnProperty.call(dictionary, language)) {
        return dictionary[language];
      }
      if ({}.hasOwnProperty.call(dictionary, defaultLanguage)) {
        return dictionary[defaultLanguage];
      }
      if (dictionary[Object.keys(dictionary)[0]] !== undefined) {
        return dictionary[Object.keys(dictionary)[0]];
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }

    return { title: '', description: '' };
  };
};
