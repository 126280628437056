import React, { useEffect, useState } from 'react';
import { bool, func, number } from 'prop-types';
import { FormattedMessage } from 'react-intl';

const MaxCount = ({ value, onChange, disabled }) => {
  const [count, setCount] = useState(value);

  useEffect(() => {
    setCount(value);
  }, [value]);

  const handleCount = (e) => {
    setCount(e.target.value);
  };

  const handleSubmit = () => {
    onChange(count);
  };

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className="mb-0">
      <small>
        <FormattedMessage id="views.planning.media.maxCount" />
      </small><br />
      <input
        className="form-control form-control-sm mb-0"
        type="number"
        max={1000000}
        min={0}
        style={{
          width: 60,
          padding: '1px 2px',
        }}
        disabled={disabled}
        value={count}
        onChange={disabled ? undefined : handleCount}
        onBlur={handleSubmit}
      />
    </label>
  );
};

MaxCount.propTypes = {
  onChange: func,
  value: number,
  disabled: bool,
};

export default MaxCount;
