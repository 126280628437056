/*
* This middleware is a thunk, passing dispatch and getState to actions that are
* functions. It also handles API requests, identified by the existence of a
* promise in the action object. It must also have a types array with request,
* success and fail action types. The middleware dispatches the request action
* and then attempts to resolve the promise. Depending on the API response/error,
* the success or fail action is dispatched.
*/

export default function apiClientMiddleware() {
  return ({ dispatch, getState }) => (next) => (action) => {
    if (typeof action === 'function') {
      return action(dispatch, getState);
    }

    const { promise, types, meta } = action; // eslint-disable-line no-redeclare
    if (!promise) {
      return next(action);
    }

    const [REQUEST, SUCCESS, FAILURE] = types;
    next({ type: REQUEST, meta: meta || undefined });
    return promise().then(
      (result) => {
        return dispatch({
          type: SUCCESS,
          payload: result,
          meta: meta || undefined,
        });
      },
      (error) => {
        // const wrappedError = castError({ error, actionType: FAILURE });
        return dispatch({
          type: FAILURE,
          payload: error,
          error: true,
          meta: meta || undefined,
        });
      },
    );
  };
}
