import PropTypes from 'prop-types';

export const geoId = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
]);

export const clientGroupShape = PropTypes.shape({
  name: PropTypes.string,
  id: geoId,
  playlist_scheme_id: geoId,
});

export const playerLayoutAreaShape = PropTypes.shape({
  id: geoId,
  width: PropTypes.number,
  height: PropTypes.number,
  top: PropTypes.number,
  left: PropTypes.number,
});

export const playerLayoutShape = PropTypes.shape({
  id: geoId,
  name: PropTypes.string,
  aspectRatio: PropTypes.string,
  areaSettings: PropTypes.object,
  areas: PropTypes.arrayOf(playerLayoutAreaShape),
});

export const intlShape = PropTypes.shape({
  formatMessage: PropTypes.func.isRequired,
});
