import React, { memo, useMemo } from 'react';
import Immutable from 'immutable';
import { batch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { QuickSelect } from 'js/react/plan/quick-select';
import {
  bulkPlanMedia,
  bulkUpdatePlannings,
  deletePlanning,
} from 'src/entities/plannedMedia';

import { getMediaInObject } from 'src/entities/media';
import { useRequestWithPromise } from 'src/core/api';
import { ConditionsModal } from 'src/screens/MediaPlaybackConditions';
import OptionsDropdown from './template-options';
import { UNORDERED_THRESHOLD } from '../limit-picker';

function RemoveBtn({ onClick }) {
  return (
    <button
      data-testid="unplan-button"
      type="button"
      onClick={onClick}
      className="btn btn-danger mr-1"
    >
      <FormattedMessage id="views.planning.playlists.unplan" />
    </button>
  );
}

const QuickAdd = memo(({
  plannings, media, onMediaSelected, disabled,
}) => {
  const plannedMediaIds = useMemo(() => plannings.reduce((acc, p) => {
    return acc.add(p.get('media'));
  }, Immutable.Set()), [plannings]);

  const mediaList = Object.values(media);

  return (
    <div className="position-relative">
      <QuickSelect
        media={
          mediaList &&
          mediaList.filter((m) => {
            return m.state !== 'trashed';
          })
        }
        selected={plannedMediaIds}
        onMediaSelected={onMediaSelected}
        disabled={disabled}
      />
    </div>
  );
});

const getSelectedIds = (selected) => selected.reduce(
  (acc, composite) => acc.concat(composite.split(',')),
  [],
);

const getChecked = (selected) => {
  return Immutable.Set(
    selected.reduce((acc, ids) => acc.concat(ids.split(',')), []),
  );
};

const useSelectedPlannings = (plannings, checked) => {
  return useMemo(() => {
    const ids = getSelectedIds(checked);
    const p = plannings.filter((pl) => {
      return ids.includes(pl.get('id').toString());
    });
    return p.toJS();
  }, [plannings, checked]);
};

export const PlannedMediaGroupOptions = ({
  bounds,
  activeLimitFilter,
  template,
  playlist,
  selected,
  handleDeselectAll,
  planLocked,
  plannings,
  unordered,
}) => {
  const { dispatch } = useRequestWithPromise();
  const mediaObjects = useSelector(getMediaInObject);
  const hasSelected = selected.size > 0;
  const selectedPlannings = useSelectedPlannings(plannings, selected);

  const handleMediaAdd = (objects) => {
    const mo = objects && !Array.isArray(objects)
      ? [objects]
      : objects;
    const templates = [template];
    const plId = playlist?.id;
    dispatch(bulkPlanMedia(mo, plId, templates));
  };

  const handleRemoveChecked = () => {
    if (!planLocked && selected.size > 0) {
      const ids = getSelectedIds(selected);
      batch(() => {
        ids.forEach((id) => dispatch(deletePlanning(id)));
      });
      handleDeselectAll();
    }
  };

  const groupChecked = () => {
    /* Group all checked plannings into the same block group */
    const checked = getChecked(selected);
    const checkedPlannings = plannings?.filter(
      (pl) => checked.includes(pl.get('id', '').toString()),
    );
    const first = checkedPlannings?.sortBy((p) => p?.get('order')).first();
    const firstStart = first?.get('time_limit_lower');
    const firstEnd = first?.get('time_limit_upper');
    const firstMaxCount = first?.get('max_count');
    const firstConditions = first?.get('additional_data');
    const firstGroupOrder = first?.get('order');
    let groupIncr = 0;

    const groupId =
      plannings &&
      plannings.reduce((acc, planned) => {
        const gId = planned.get('block_group');
        return acc > gId ? acc : gId;
      }, 0) + 1;

    const prepped = plannings
      .sortBy((pl) => pl.get('order'))
      .map((planned, idx) => {
        let pl = planned;
        const pId = pl.get('id').toString();

        if (checked.contains(pId)) {
          pl = pl
            // Order all selected in sequence after the firs selected item
            .set('order', firstGroupOrder + groupIncr)
            .set('block_group', groupId)
            .set('time_limit_lower', firstStart)
            .set('time_limit_upper', firstEnd)
            .set('max_count', firstMaxCount)
            .set('additional_data', firstConditions);

          groupIncr += 1;
        } else if (pl.get('order') > firstGroupOrder && pl.get('order') < UNORDERED_THRESHOLD) {
          // Move up plannings after selection to accomodate the newly grouped items
          pl = pl.set('order', idx + checked.size);
        }

        return pl
          .delete('start')
          .delete('end');
      })
      .toJS();

    dispatch(bulkUpdatePlannings(prepped));
    handleDeselectAll();
  };

  const ungroupChecked = () => {
    const checked = getChecked(selected);

    const prepped = plannings
      .map((planned) => {
        let g = planned.get('block_group');
        const pId = planned.get('id').toString();
        if (checked.contains(pId)) {
          g = 0;
        }
        return planned
          .set('block_group', g)
          .delete('start')
          .delete('end');
      })
      .toJS();

    dispatch(bulkUpdatePlannings(prepped));
    handleDeselectAll();
  };

  return (
    <div className="row no-gutters mb-3">
      {!planLocked && !unordered && (
        <QuickAdd
          media={mediaObjects}
          plannings={plannings}
          onMediaSelected={handleMediaAdd}
          disabled={activeLimitFilter === 'current' || activeLimitFilter === 'future' || activeLimitFilter === 'past'}
        />
      )}
      <div className="ml-auto d-flex">
        {!planLocked && hasSelected && (
          <RemoveBtn onClick={handleRemoveChecked} />
        )}
        {!planLocked && hasSelected && (
          <ConditionsModal
            plannings={selectedPlannings}
            maxDate={bounds.templateTo}
            minDate={bounds.templateFrom}
          />
        )}
        {!unordered && (
          <OptionsDropdown
            checked={getChecked(selected)}
            playlistId={playlist?.id}
            template={template}
            groupChecked={groupChecked}
            ungroupChecked={ungroupChecked}
            templateFrom={bounds.templateFrom}
            templateTo={bounds.templateTo}
            planLocked={planLocked}
          />
        )}
        <a
          href="#/plan/mediaLibrary"
          className="d-sm-none"
        >
          <button
            type="button"
            className="btn btn-secondary"
          >
            <FormattedMessage id="views.planning.playlists.browse" />
          </button>
        </a>
      </div>
    </div>
  );
};
