import React from 'react';
import { FormattedMessage } from 'react-intl';

function Tools({
  canAdd,
  canDelete,
  selected,
  handleAdd,
  handleMoveUp,
  handleMoveDown,
  handleRemove,
}) {
  return (
    <div className="clearfix mb-2">
      <button
        type="button"
        className="btn btn-primary mr-1"
        onClick={canAdd ? handleAdd : undefined}
      >
        <i className="icon-plus icon-white" /> <FormattedMessage id="views.layout.addArea" />
      </button>
      {selected && (
        <button
          type="button"
          className="btn btn-light mr-1"
          onClick={canDelete ? handleMoveUp : undefined}
          disabled={!canDelete}
        >
          <FormattedMessage id="views.layout.moveUp" />
        </button>
      )}
      {selected && (
        <button
          type="button"
          className="btn btn-light mr-1"
          onClick={canDelete ? handleMoveDown : undefined}
          disabled={!canDelete}
        >
          <FormattedMessage id="views.layout.moveDown" />
        </button>
      )}
      {selected && (
        <button
          type="button"
          className="btn btn-danger float-right"
          onClick={canDelete ? handleRemove : undefined}
          disabled={!canDelete}
        >
          <i className="icon-minus icon-white" /> <FormattedMessage id="views.layout.removeArea" />
        </button>
      )}
    </div>
  );
}

export default Tools;
