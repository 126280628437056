import { v4 as getUuid } from 'uuid';
import * as actions from '../actions';
import {
  get, post, put, del,
} from '../helpers/actionCreatorFactories';

const LAYOUT_API = '/media/layout';

export function fetchLayouts() {
  const url = LAYOUT_API;
  const types = [
    actions.FETCH_PLAYER_LAYOUTS,
    actions.FETCH_PLAYER_LAYOUTS_SUCCESS,
    actions.FETCH_PLAYER_LAYOUTS_FAIL,
  ];
  const meta = {
    verb: 'get',
    collection: 'playerlayouts',
    uuid: getUuid(),
    url: LAYOUT_API,
    reset: true,
  };

  return get({ url, types, meta });
}

export const createLayout = (bodyInput) => {
  const url = LAYOUT_API;
  const types = [
    actions.CREATE_PLAYER_LAYOUT,
    actions.CREATE_PLAYER_LAYOUT_SUCCESS,
    actions.CREATE_PLAYER_LAYOUT_FAIL,
  ];
  const meta = {
    verb: 'post',
    bodyInput,
    collection: 'playerlayouts',
    uuid: getUuid(),
    url,
  };

  return post({
    url, types, meta, bodyInput,
  });
};

export const updateLayout = (bodyInput) => {
  const url = LAYOUT_API;
  const types = [
    actions.UPDATE_PLAYER_LAYOUT,
    actions.UPDATE_PLAYER_LAYOUT_SUCCESS,
    actions.UPDATE_PLAYER_LAYOUT_FAIL,
  ];
  const meta = {
    verb: 'put',
    bodyInput,
    id: bodyInput.id,
    collection: 'playerlayouts',
    uuid: getUuid(),
    url,
  };

  return put({
    url, types, meta, bodyInput,
  });
};

export const deleteLayout = (id) => {
  const url = LAYOUT_API;
  const types = [
    actions.DELETE_PLAYER_LAYOUT,
    actions.DELETE_PLAYER_LAYOUT_SUCCESS,
    actions.DELETE_PLAYER_LAYOUT_FAIL,
  ];
  const meta = {
    verb: 'del',
    id,
    collection: 'playerlayouts',
    uuid: getUuid(),
    url,
  };

  return del({
    url, types, meta, id,
  });
};
