import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter, Link } from 'react-router-dom';

import cancan from 'js/system/cancan';
import {
  PLANNING,
  PLACES,
  PLAYLISTS,
  CLIENTS,
  SIMULATION,
  HISTORY,
  REPORT,
  ADMIN,
  DASHBOARD,
  MODULE_DOWNLOAD_BUNDLE,
  VIEW_PASSWORD,
  USER_HIDE_LOGOUT,
  NEXT_UI,
} from 'constants/permissions';
import ContextProvider from 'js/react/components/ContextProvider';
import * as MsgActions from 'js/react/actions/message-actions';
import Dropdown, { DropdownToggle, DropdownContent } from '../../components/Dropdown';
import Nav, { ExternalNavItem, NavItem } from './components/Nav';

const menuItems = [
  {
    name: 'planning',
    route: 'plan',
    nextUrl: 'plan',
    permission: PLANNING,
    icon: 'icon-th-large',
  },
  {
    name: 'places',
    route: 'places',
    permission: PLACES,
    icon: 'icon-globe',
  },
  {
    name: 'playlists',
    route: 'playlists',
    permission: PLAYLISTS,
    icon: 'icon-list',
  },
  {
    name: 'clients',
    route: 'clients',
    permission: CLIENTS,
    icon: 'icon-road',
  },
  {
    name: 'simulation2',
    route: 'simulation2',
    permission: SIMULATION,
    icon: 'icon-list',
  },
  {
    name: 'history',
    route: 'history',
    permission: HISTORY,
    icon: 'icon-book',
  },
  {
    name: 'report',
    route: 'report',
    permission: REPORT,
    icon: 'icon-book',
  },
  {
    name: 'admin',
    route: 'admin',
    permission: ADMIN,
    icon: 'icon-cog',
  },
  {
    name: 'dashboard',
    route: 'dashboard',
    permission: DASHBOARD,
    icon: 'icon-home',
  },
];

async function fetchWithTimeout(resource) {
  const timeout = 2;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    signal: controller.signal,
  });
  clearTimeout(id);

  return response;
}

class MainMenu extends React.Component {
  // Methods
  onClick = (e) => {
    e.preventDefault();
  }

  downloadBundle = (e) => {
    e.preventDefault();
    const url = `${window.ROOT_PATH}/api/gui/build/download_bundle`;
    document.getElementById('media_bundle_iframe').src = url;

    fetchWithTimeout(url).then((response) => {
      if (response.status === 404) {
        MsgActions.error('(404) No bundle found.');
      }
    }).catch((error) => {
      if (error.name === 'AbortError') {
        console.log('Bundle download probably successfull.');
        return;
      }

      MsgActions.error('Media download not ready. Try again in a few minutes.');
    });
  }

  // Render

  renderDownload() {
    return (
      <NavItem
        onClick={this.downloadBundle}
      >
        <FormattedMessage id="common.downloadBundle" />
      </NavItem>
    );
  }

  renderRightArea() {
    return (
      <>
        {cancan.can('read', MODULE_DOWNLOAD_BUNDLE) && (
          this.renderDownload()
        )}
        <Dropdown
          right
          TagName="li"
          className="nav-item dropdown"
          data-testid="UserMenu"
        >
          <DropdownToggle
            Component="a"
            className="nav-link dropdown-toggle"
          >
            {this.props.username}
          </DropdownToggle>
          <DropdownContent>
            <Link className="dropdown-item" to="/language" data-testid="Language">
              <i className="icon-flag" />{' '}
              <FormattedMessage id="common.changeLanguage" />
            </Link>
            {cancan.can('read', VIEW_PASSWORD) && (
              <Link className="dropdown-item" to="/usersettings">
                <i className="icon-cog" />{' '}
                <FormattedMessage id="common.changePassword" />
              </Link>
            )}
            <div className="dropdown-divider" />
            {!cancan.can('read', USER_HIDE_LOGOUT) && (
              <a className="dropdown-item" href="/logout" data-testid="Logout">
                <i className="icon-off" />{' '}
                <FormattedMessage id="common.logOut" />{' '}
                {this.props.username}
              </a>
            )}
          </DropdownContent>
        </Dropdown>
      </>
    );
  }

  renderMenuItems(pathname) {
    return menuItems.map(({
      route, icon, name, permission = 'no', nextUrl,
    }) => {
      const activeView = pathname.split('/')[1].split(';')[0];
      const active = activeView === route || (activeView === '' && route === 'plan');
      const className = `px-2 top_${route}`;
      const url = `/${route}`;
      const id = `common.${name}`;
      const nextUi = cancan.can('read', NEXT_UI) && !!nextUrl;
      const NavComp = nextUi ? ExternalNavItem : NavItem;

      if (cancan.can('read', permission)) {
        return (
          <NavComp
            key={route}
            className={className}
            active={active}
            url={nextUi ? `/v2/${nextUrl}` : url}
            icon={icon}
          >
            <FormattedMessage
              id={id}
            />
          </NavComp>
        );
      }

      return null;
    });
  }

  render() {
    return (
      <ContextProvider>
        <Nav
          data-testid="MainMenu"
          rightArea={
            this.renderRightArea()
          }
        >
          {this.renderMenuItems(this.props.location.pathname)}
          {cancan.can('read', MODULE_DOWNLOAD_BUNDLE) && (
            <iframe
              title="download_bundle"
              id="media_bundle_iframe"
              className="d-none"
            />
          )}
        </Nav>
      </ContextProvider>
    );
  }
}

export default withRouter(MainMenu);
