import React, { useMemo, useReducer } from 'react';
import {
  arrayOf,
  func, instanceOf, number, oneOfType, shape, string,
} from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import cancan from 'js/system/cancan';
import { bulkUpdatePlannings, getHighestOrdersForPlaylistIds } from 'src/entities/plannedMedia';
import { useAsyncDispatch } from 'src/helpers/useAsyncDispatch';
import { ErrorBoundary } from 'src/components/Error/ErrorBoundary';
import { fmtDate, UNORDERED_THRESHOLD } from 'src/screens/Playlist/components/limit-picker';
import {
  PLACES, PLANNING_LONG_TERM, PLANNING_MAX_COUNT, PLANNING_TIMEPLAN, PLAN_PLAYBACK_CONDITIONS,
} from 'constants/permissions';
import { unlock } from 'js/redux/plan/plan-actions';

import { getToDate } from 'js/redux/selectors';
import LoadingSpinner from 'src/components/LoadingSpinner';
import { MaxCountCondition } from './MaxCountCondition';
import { TimelimitCondition } from './TimelimitCondition';
import { ConditionsContext } from './ConditionsContext';
import { valuesReducer } from './valuesReducer';
import {
  AreaCondition, VehicleCondition, DestinationCondition, LineCondition, StopCondition,
} from './PlaceConditions';
import { useSharedValues } from '../hooks/useSharedValues';
import { useEnsurePlaces } from '../hooks/useEnsurePlaces';
import { ModalHeader } from './ModalHeader';
import { DayOfWeekCondition } from './DayOfWeekCondition';
import { OddDayCondition } from './OddDayCondition';
import { TimeOfDayCondition } from './TimeOfDayCondition';
import { RESET } from '../constants';

const UnlockButton = () => {
  const dispatch = useDispatch();
  const toDate = useSelector(getToDate);
  const canUnlock = moment().isBefore(toDate);
  const handleUnlock = () => {
    dispatch(unlock());
  };

  return !canUnlock ? null : (
    <div className="alert alert-warning p-2 text-center">
      <button
        type="button"
        className="btn btn-light"
        onClick={handleUnlock}
      >
        <i className="icon-lock mr-1" />
        <FormattedMessage id="views.planning.calendarBar.clickToUnlock" />
      </button>
    </div>
  );
};

const getDefaultValues = (hasTimeplan, minDate, maxDate) => ({
  additional_data: { conditions: {} },
  max_play_count: 0,
  time_limit_lower: hasTimeplan ? fmtDate(minDate) : '1970-01-01T00:00:00',
  time_limit_upper: hasTimeplan ? fmtDate(maxDate) : '2100-01-01T23:59:59',
});

export const MediaPlaybackConditions = ({
  plannings, minDate, maxDate, onClose,
}) => {
  const [dispatch, pending] = useAsyncDispatch();
  const busy = pending?.length;
  const hasTimeplan = cancan.can('read', PLANNING_TIMEPLAN);
  const hasLongTimeplan = cancan.can('read', PLANNING_LONG_TERM);
  const isLocked = useSelector((state) => state.plan.get('planLocked'));
  const sharedValues = useSharedValues(plannings, getDefaultValues(hasTimeplan, minDate, maxDate));
  const [values, dispatchChange] = useReducer(valuesReducer, sharedValues.current);
  const disabled = !!(busy || isLocked);
  const canCustom = cancan.can('read', PLAN_PLAYBACK_CONDITIONS);

  const playlistIdsMap = (plannings || []).reduce((acc, planning) => {
    if (planning && planning.playlist) {
      acc[planning.playlist] = true;
    }

    return acc;
  }, {});
  const playlistIds = Object.keys(playlistIdsMap);
  const maxOrders = useSelector((state) => getHighestOrdersForPlaylistIds(state, playlistIds));
  const maxOrdersByPlaylistId = playlistIds.reduce((map, playlistId, index) => {
    map[playlistId] = maxOrders[index];

    return map;
  }, {});

  const handleSave = () => {
    const prepped = plannings.map((planning, idx) => {
      const start = moment(values.time_limit_lower);
      const end = moment(values.time_limit_upper);
      const isInsideBounds =
        (!minDate || start.isSameOrAfter(minDate))
        && (!maxDate || end.isSameOrBefore(maxDate));
      const maxOrder = maxOrdersByPlaylistId[planning.playlist];
      const isUnordered = planning.order >= UNORDERED_THRESHOLD;
      const pl = {
        ...getDefaultValues(hasTimeplan, minDate, maxDate),
        ...planning,
        ...values,
        // eslint-disable-next-line no-nested-ternary
        order: !isInsideBounds && !isUnordered
          ? planning.order + UNORDERED_THRESHOLD
          : isInsideBounds && isUnordered
            ? maxOrder + 1 + idx
            : planning.order,
      };
      delete pl.start;
      delete pl.end;

      if (!canCustom) {
        delete pl.additional_data;
      }

      return pl;
    });
    dispatch(bulkUpdatePlannings(prepped));
    onClose?.();
  };

  const handleReset = () => {
    dispatchChange({
      type: RESET,
      payload: getDefaultValues(hasTimeplan, minDate, maxDate),
    });
  };

  const memoizedContext = useMemo(() => ({
    values, dispatchChange, busy,
  }), [values, busy]);

  const loadingPlaces = useEnsurePlaces();

  return (
    <ErrorBoundary>
      <ConditionsContext.Provider value={memoizedContext}>
        <ModalHeader plannings={plannings} />

        {isLocked && (
          <UnlockButton />
        )}
        {(hasTimeplan || hasLongTimeplan) && (
          <TimelimitCondition
            isLocked={disabled}
            maxDate={maxDate}
            minDate={minDate}
          />
        )}

        {canCustom && (hasTimeplan || hasLongTimeplan) && (
          <>
            <DayOfWeekCondition />
            <OddDayCondition />
            <TimeOfDayCondition />
          </>
        )}

        {cancan.can('read', PLANNING_MAX_COUNT) && (
          <MaxCountCondition
            isLocked={disabled}
          />
        )}

        {cancan.can('read', PLACES) && canCustom && (
          <>
            {loadingPlaces && (
              <LoadingSpinner />
            )}
            {!loadingPlaces && (
              <>
                <VehicleCondition disabled={disabled} />
                <AreaCondition disabled={disabled} />
                <StopCondition disabled={disabled} />
                <LineCondition disabled={disabled} />
                <DestinationCondition disabled={disabled} />
              </>
            )}
          </>
        )}

        <div className="d-flex">
          <button
            type="button"
            className="btn btn-success mr-2"
            onClick={disabled ? undefined : handleSave}
            disabled={disabled}
          >
            <FormattedMessage id="common.save" />
          </button>
          <button
            type="button"
            className="btn btn-light border"
            onClick={disabled ? undefined : handleReset}
            disabled={disabled}
          >
            <FormattedMessage id="common.reset" />
          </button>
          <button
            type="button"
            className="btn btn-light border ml-auto"
            onClick={busy ? undefined : onClose}
            disabled={busy}
          >
            <FormattedMessage id="common.cancel" />
          </button>
        </div>
      </ConditionsContext.Provider>
    </ErrorBoundary>
  );
};

MediaPlaybackConditions.propTypes = {
  plannings: arrayOf(shape({
    id: string,
    media: oneOfType([string, number]),
  })),
  maxDate: instanceOf(moment).isRequired,
  minDate: instanceOf(moment).isRequired,
  onClose: func,
};
