import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import MaxCount from 'src/screens/Playlist/components/PlannedMedia/MaxCount';
import { bool } from 'prop-types';
import { ConditionSection } from './ConditionSection';
import { ConditionsContext } from './ConditionsContext';
import { MAX_PLAY_COUNT } from '../constants';

export const MaxCountCondition = ({ isLocked }) => {
  const { formatMessage } = useIntl();
  const {
    values: { max_play_count },
    dispatchChange,
  } = useContext(ConditionsContext);

  const handleCount = (count) => {
    dispatchChange({
      type: MAX_PLAY_COUNT,
      payload: count,
    });
  };

  return (
    <ConditionSection
      label={formatMessage({ id: 'views.planning.media.maxCount' })}
    >
      <MaxCount
        disabled={isLocked}
        value={parseInt(max_play_count, 10)}
        onChange={isLocked ? undefined : handleCount}
      />
    </ConditionSection>
  );
};

MaxCountCondition.propTypes = {
  isLocked: bool,
};
