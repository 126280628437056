import React from 'react';
import { injectIntl } from 'react-intl';

function sortInt(a, b) {
  return parseInt(a, 10) - parseInt(b, 10);
}

export function fmtDuration(d) {
  const zfill = (_d) => (_d < 10 ? `0${_d}` : _d);

  const h = parseInt(d / 3600, 10);
  const m = parseInt((d % 3600) / 60, 10);
  const s = zfill(parseInt((d % 3600) % 60, 10));

  return `${h}h ${m}m ${s}s`;
}

function StatsRow(props) {
  const i = props.item;

  const onToggle = () => {
    props.onToggle(i);
  };

  const cnts = i.get('cnts');
  const color = props.colors && props.colors[i.get('id')];

  return (
    <tr onClick={onToggle} className={props.isToggled ? 'toggled' : ''}>
      <td>
        <span
          style={{
            background: color,
          }}
          className="marker pa5 rounded"
        >
          {i.get('id')}
        </span>
      </td>
      <td className="text-nowrap">{fmtDuration(i.get('dur'))}</td>
      <td>{i.get('tot')}</td>
      {props.dates.map((d) => (
        <td key={d} className={cnts.get(d) ? '' : 'empty'}>
          {cnts.get(d)}
        </td>
      ))}
    </tr>
  );
}

class StatsTableComponent extends React.Component {
  state = {
    sortIdx: 0,
    asc: false,
  }

  render() {
    const { props } = this;

    const sortHeaders = [
      {
        title: this.props.intl.formatMessage({
          id: props.primaryFieldTranslationKey,
        }),
        sortFunc: props.primarySortFunc,
        field: (d) => d.get('id'),
      },
      {
        title: this.props.intl.formatMessage({ id: 'views.report.duration' }),
        sortFunc: sortInt,
        field: (d) => d.get('dur'),
      },
      {
        title: this.props.intl.formatMessage({ id: 'views.report.plays' }),
        sortFunc: sortInt,
        field: (d) => d.get('tot'),
      },
    ];

    const sorter = (sortFnc, fieldGetter) => {
      return (a, b) => sortFnc(fieldGetter(a), fieldGetter(b)) * (this.state.asc ? -1 : 1);
    };

    const sortFieldGetter = sortHeaders[this.state.sortIdx].field;
    const { sortFunc } = sortHeaders[this.state.sortIdx];

    const data = props.data.sort(sorter(sortFunc, sortFieldGetter));

    const createHeader = (h, idx) => {
      const handleClick = () => {
        if (this.state.sortIdx === idx) {
          this.setState({ asc: !this.state.asc });
        } else {
          this.setState({ sortIdx: idx });
        }
      };

      let icon = 'right';

      if (this.state.sortIdx === idx) {
        icon = this.state.asc ? 'up' : 'down';
      }
      const iconClassName = `icon-chevron-${icon}`;
      return (
        <th onClick={handleClick} key={idx} className="pointer text-nowrap">
          <i className={iconClassName} />
          {h.title}
        </th>
      );
    };

    return (
      <table className="table stats-table" data-testid="StatsTable">
        <thead>
          <tr className="cap">
            {sortHeaders.map(createHeader)}
            {props.dates.map((d, i) => (
              <th className="text-nowrap" key={10 + i}>{d}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((d, i) => (
            <StatsRow
              key={i}
              item={d}
              colors={props.colors}
              dates={props.dates}
              onToggle={props.onToggle}
              isToggled={props.toggledMedia && props.toggledMedia.has(d)}
            />
          ))}
        </tbody>
      </table>
    );
  }
}

export default injectIntl(StatsTableComponent);
