import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getClientGroupsInArray } from 'src/entities/clientGroups';

export const useClientGroups = () => {
  const rawClientGroups = useSelector(getClientGroupsInArray) || [];
  return useMemo(() => {
    return rawClientGroups.map((c) => ({
      ...c,
      type: 'clientgroup',
      displayName: c.name,
    }));
  }, [rawClientGroups]);
};
