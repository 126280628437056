import GSDispatcher from 'js/react/dispatchers/gs-dispatcher';
import MessageConstants from 'js/react/constants/message-constants';
import Store from 'js/react/stores/store';
import Immutable from 'immutable';

const _state = {
  messages: Immutable.List([]), // List of messages
};

// message = {
//     'text'
//     'type'
//     'read'
// }

const MessageStore = {
  ...Store.prototype,
  state: _state,
  /**
     * Create a new message and add it to the Store
     */
  handleNewMessage(action) {
    const msg = Immutable.Map({
      id: this.state.messages.size,
      text: action.data,
      type: action.type,
      show: true,
    });

    this.setState({
      messages: this.state.messages.push(msg),
    });
  },

  /**
     * Hide a message
     * @param {object} action The action data
     */
  handleHideMessage(action) {
    const index = action.data;

    // Make sure we have the message
    if (this.state.messages.has(index)) {
      const msg = this.state.messages.get(index).toJS();
      msg.show = false;
      // Update State
      this.state.messages = this.state.messages.set(index, Immutable.Map(msg));
    } else {
      console.log('Could not set message to read');
    }
  },

  /**
     * payload : { action { type, payload { id, data }}}
     */
  dispatcherIndex: GSDispatcher.register((payload) => {
    const { action } = payload;

    switch (action.type) {
    case MessageConstants.MESSAGE_INFO :
      MessageStore.handleNewMessage(action);
      MessageStore.emitChange();
      break;
    case MessageConstants.MESSAGE_SUCCESS :
      MessageStore.handleNewMessage(action);
      MessageStore.emitChange();
      break;
    case MessageConstants.MESSAGE_ERROR :
      MessageStore.handleNewMessage(action);
      MessageStore.emitChange();
      break;
    case MessageConstants.HIDE_MESSAGE :
      MessageStore.handleHideMessage(action);
      MessageStore.emitChange();
      break;
    default:
      return true; // No errors. Needed by promise in Dispatcher.
    }
  }),
};

export default MessageStore;
