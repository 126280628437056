/* eslint-disable max-classes-per-file */
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useState } from 'react';
import im from 'immutable';
import { Multiselect } from 'react-widgets';
import AddTag from '../AddTag';

const PlaylistChooser = ({ onPlaylistSelected, playlists }) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);

  const toggleDropdown = () => {
    setOpen((opn) => !opn);
  };

  const handleChange = (value) => {
    onPlaylistSelected(value[0].id);
    toggleDropdown();
  };

  return (
    <Multiselect
      value={[]}
      filter="contains"
      className="w-100"
      data-testid="playlistChooserSelect"
      placeholder={`${intl.formatMessage({
        id: 'views.playlists.clickToAddPlaylist',
      })}...`}
      textField="name"
      onChange={handleChange}
      disabled={playlists.size === 0}
      data={playlists.toList().toJS()}
      open={open}
      onToggle={toggleDropdown}
    />
  );
};

const addSections = ({
  schemePlaylistItems,
  tags,
}) => {
  // convert to JS
  const items = (
    schemePlaylistItems &&
    schemePlaylistItems.toJS &&
    schemePlaylistItems.toJS()
  ) || [];

  // make it easy to get tag name from tag id
  const tagIdToNameMap = ((tags && tags.toJS && tags.toJS()) || []).reduce((map, tag) => {
    return {
      ...map,
      [tag.id]: tag.name,
    };
  }, {});

  // figure out where the (unique) tags should be added in the list
  const firstIndexToTagMap = {}; // this is used to know when to add section
  const tagToFirstIndexMap = {}; // this is only used to create firstIndexToTagMap
  items.forEach(({ tag }, index) => {
    if (tag && tagToFirstIndexMap[tag] === undefined) {
      tagToFirstIndexMap[tag] = index;
      firstIndexToTagMap[index] = tag;
    }
  });

  // build new item list
  const newArrayLength = items.length + Object.values(firstIndexToTagMap).length;
  const itemsWithSections = new Array(newArrayLength) || [];

  let numberOfSectionHeadersAdded = 0;
  for (let itemIndex = 0; itemIndex < items.length; itemIndex++) {
    // if we're on an index where a section/tag should be inserted
    if (firstIndexToTagMap[itemIndex]) {
      // find the tag id, or name if new
      const tagIdOrNewName = firstIndexToTagMap[itemIndex];
      const existingTag = tagIdToNameMap[tagIdOrNewName];

      // add section header and increment numberOfSectionHeadersAdded
      itemsWithSections[itemIndex + numberOfSectionHeadersAdded] = {
        tagId: tagIdOrNewName,
        sectionName: existingTag || tagIdOrNewName,
      };
      numberOfSectionHeadersAdded += 1;

      // add the item from the index where the section header was just added
      itemsWithSections[itemIndex + numberOfSectionHeadersAdded] = items[itemIndex];
    } else {
      itemsWithSections[itemIndex + numberOfSectionHeadersAdded] = items[itemIndex];
    }
  }

  return im.fromJS(itemsWithSections);
};

export class SchemeItemList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: addSections({
        schemePlaylistItems: props.items,
        tags: props.tags,
      }),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // local state.items is kept for ordering items,
    // should be refreshed if component is fed a different set of items
    // as props
    // if it's a scheme of repeating lists, then also check for new tags
    if (
      (nextProps.items && !nextProps.items.equals(this.props.items)) ||
      (nextProps.repeating && nextProps.tags && !nextProps.tags.equals(this.props.tags))
    ) {
      this.buildItems(nextProps);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  handlePlaylistSelected = (playlistId) => {
    const { items } = this.state;
    let tag = null;
    if (items && items.size > 0) {
      const last = items.last();
      tag = last.get('/tag') || last.get('tagId');
    }

    this.props.onPlaylistSelected(playlistId, tag);
  };

  onTagAdd = (tagName) => {
    this.setState((state) => {
      const newItems = state.items.push(im.fromJS({
        tagId: tagName,
        sectionName: tagName,
      }));

      return {
        items: newItems,
      };
    });
  }

  onTagDelete = (tagId) => {
    this.setState((state) => {
      const newItems = state.items.filter((item) => item.get('tagId') !== tagId);
      this.commitDrag(newItems);

      return {
        items: newItems,
      };
    });
  }

  handleHover = (dragIdx, hoverIdx) => {
    let { items } = this.state;

    /* Swap places for dragIdx and hoverIdx */
    const dragMedia = items.get(dragIdx).set('isDragging', true);
    const hoverMedia = items.get(hoverIdx);

    items = items.set(hoverIdx, dragMedia);
    items = items.set(dragIdx, hoverMedia);

    this.setState({ items });

    // clear ongoing commit resets
    clearTimeout(this.timeout);
  }

  filterItems = (items) => {
    let latestTagIdOrNewName;
    return (items || this.state.items).map((item) => {
      if (!item.get('playlist')) { // this means it's a section
        if (item.get('tagId') !== undefined) {
          latestTagIdOrNewName = item.get('tagId');
        }

        return null;
      }

      const newItem = item.delete('isDragging').set('tag', latestTagIdOrNewName);
      return newItem;
    }).filter(Boolean);
  }

  commitDrag = (newItems) => { // newItems is only passed from onTagDelete
    const filteredItems = this.filterItems(newItems);

    this.props.onUpdateOrder(filteredItems);
  }

  buildItems(props) {
    const repeatingItemsWithSections = addSections({
      schemePlaylistItems: props.items,
      tags: props.tags,
    });
    this.setState({ items: repeatingItemsWithSections });
  }

  render() {
    const {
      playlists,
      RowComp,
      editing,
      repeating,
      uniqueOnly,
      onPlaylistDelete,
    } = this.props;

    const { items } = this.state;

    if (!items) {
      return null;
    }
    const rows = items.map((item, i) => {
      const sectionName = item.get('sectionName');
      const tagId = item.get('tagId');
      const itemId = item.get('playlist');
      const playlist = itemId && playlists.get(itemId.toString());

      return (
        <RowComp
          key={i}
          index={i}
          editing={editing}
          handleHover={this.handleHover}
          commitDrag={this.commitDrag}
          playlist={playlist}
          schemePlaylist={item}
          sectionName={sectionName}
          tagId={tagId}
          onPlaylistDelete={onPlaylistDelete}
          onTagDelete={this.onTagDelete}
        />
      );
    });

    // if uniqueOnly is set, filter out playlists that are not already choosen
    let choosablePlaylists = playlists;

    if (uniqueOnly) {
      const directItemIdMap = items.map((item) => item.get('playlist'));
      choosablePlaylists = playlists.filter((pl) => {
        return !directItemIdMap.includes(pl.get('id'));
      });
    }

    return (
      <div>
        {items.size === 0 && (
          <p data-testid="schemeEmptyPlaylist">
            <em>
              <FormattedMessage
                id="views.playlists.noPlaylists"
              />
            </em>
          </p>
        )}
        {items.size > 0 && (
          <table
            className="playlist-list mb-2"
            data-testid="schemePlaylistRows"
          >
            <tbody>{rows}</tbody>
          </table>
        )}
        {editing && (
          <PlaylistChooser
            onPlaylistSelected={this.handlePlaylistSelected}
            playlists={choosablePlaylists}
          />
        )}
        {editing && repeating && (
          <AddTag
            onTagAdd={this.onTagAdd}
          />
        )}
      </div>
    );
  }
}
