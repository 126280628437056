import { UPDATE_DASHBOARD_BADGES } from '../actionTypes';
import { PRIMARY_DASHBOARD_ID } from '../constants';

export default function updateDashboardBadges(
  { badges, dashboardId = PRIMARY_DASHBOARD_ID } = {},
) {
  return {
    payload: { dashboardId, badges },
    type: UPDATE_DASHBOARD_BADGES,
  };
}
