import { buildApiMetaFactory, buildGetType, createCrudActionCreators } from '../../core/api';
import { URL, COLLECTION } from './constants';

export const {
  create: createPlace,
  readAll: fetchPlaces,
  readById: fetchPlace,
  update: updatePlace,
  destroy: deletePlace,
} = createCrudActionCreators({ collection: COLLECTION, url: URL });

export const fetchPlacesWithQueryParams = (queryParams = '') => {
  const buildApiMeta = buildApiMetaFactory({
    collection: COLLECTION,
    url: URL + queryParams,
  });

  const action = {
    type: buildGetType(COLLECTION),
    meta: buildApiMeta({ reset: true }),
  };

  return action;
}