import { I18N_SET_LANGUAGE } from './actionTypes';
import { GEO_UI_LANGUAGE } from './constants';
import * as moment from 'moment';

export default function i18nMiddleware() {
  // prettier-ignore
  return ({ dispatch, getState }) => { // eslint-disable-line no-unused-vars
    return (next) => (action) => {
      if (action.type === I18N_SET_LANGUAGE) {
        window.localStorage &&
          window.localStorage.setItem(GEO_UI_LANGUAGE, action.payload);
        moment.locale(action.payload);
      }

      return next(action);
    };
  };
}
