import React from 'react';
import { FormattedMessage } from 'react-intl';

const createMarkup = (text) => {
  return { __html: text };
};

export const TextList = ({
  id,
  title,
  description,
  value: list,
  onChange,
  disabled,
}) => {
  const applyChange = (next, idx) => {
    const nextVals = [...list];
    nextVals[idx] = next;
    onChange(nextVals);
  };

  const handleChange = (idx) => ({ target }) => {
    applyChange(target.value, idx);
  };

  const handleAdd = () => {
    applyChange('', list.length);
  };

  const handleRemove = (idx) => () => {
    const nextVals = [...list];
    nextVals.splice(idx, 1);
    onChange(nextVals);
  };

  return (
    <div
      id={id}
      className="form-group"
      data-testid="customWidgetSelectField"
    >
      <label htmlFor={id}>
        {title}
      </label>
      {list.length > 0 && list.map((text, idx) => (
        <div className="position-relative">
          <input
            key={idx}
            onChange={handleChange(idx)}
            value={text}
            type="text"
            className="form-control mb-1 pr-4"
            disabled={disabled}
          />
          {idx > 0 && (
            <button
              className="close position-absolute"
              type="button"
              aria-label="Close"
              onClick={handleRemove(idx)}
              style={{
                right: '0.8rem',
                top: '0.5rem',
              }}
            >
              {'\u00D7'}
            </button>
          )}
        </div>
      ))}
      {!disabled && (
        <button
          type="button"
          className="btn btn-light border w-100"
          onClick={handleAdd}
        >
          {'+ '}<FormattedMessage id="common.add" />
        </button>
      )}
      <small className="form-text text-muted">
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={createMarkup(description)}
        />
      </small>
    </div>
  );
};
