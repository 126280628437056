import React from 'react';

const Sidebar = ({ children }) => {
  return (
    <div className="holder bg-white p-3">
      {children}
    </div>
  );
};

export default Sidebar;
