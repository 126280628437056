import React from 'react';
import { connect } from 'react-redux';
import * as selectors from 'js/redux/selectors';
import { fetchLayouts } from 'js/redux/api/data';
import { fetchClientGroups, getClientGroupsInArray, updateClientGroup } from 'src/entities/clientGroups';

function mapState(state) {
  return {
    layouts: selectors.getPlayerLayouts(state),
    clientGroups: getClientGroupsInArray(state),
  };
}

const mapDispatch = {
  fetchLayouts,
  fetchClientGroups,
  updateClientGroup,
};

class LayoutPicker extends React.Component {
  state = {
    loading: true,
  }

  componentDidMount() {
    Promise.all([
      this.props.fetchLayouts(),
      this.props.fetchClientGroups(),
    ]).then(this.loaded);
  }

  loaded = () => this.setState({ loading: false })

  getClientGroup(schemeId) {
    return this.props.clientGroups && this.props.clientGroups.find((group) => {
      return group.playlist_scheme_id == schemeId;
    });
  }

  handleChange = (e) => {
    const group = this.getClientGroup(this.props.schemeId);
    this.props.updateClientGroup({
      ...group,
      layout_id: e.target.value,
    });
  }

  renderOptions(layouts) {
    return layouts && layouts.map(({ id, name }) => (
      <option key={id} value={id}>
        {name}
      </option>
    ));
  }

  render() {
    const group = this.getClientGroup(this.props.schemeId);
    const schemeHasNoClientGroup = !group && !this.state.loading;

    return !schemeHasNoClientGroup && (
      <label className="w-100 mt-2">
        <select
          title="Layout"
          className="form-control w-100"
          value={group && group.layout_id}
          onChange={this.handleChange}
        >
          <option>
            {this.state.loading ? 'Loading...' : '-'}
          </option>
          {!this.state.loading && this.renderOptions(this.props.layouts)}
        </select>
      </label>
    );
  }
}

export default connect(mapState, mapDispatch)(LayoutPicker);
