import React, { useState } from "react";
import { useIntl } from "react-intl";
import Portal from "../../../js/react/components/Portal";

import './dialog.scss';

export const Dialog = ({
  onConfirm,
  renderToggle,
  children,
  title,
  confirmLabel,
  variant = "btn-primary",
}) => {
  const [showModal, setShowModal] = useState(false);
  const { formatMessage } = useIntl();

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleConfirmClick = () => {
    setShowModal(false);
    onConfirm();
  };

  return (
    <>
      {renderToggle(handleButtonClick)}
      {showModal && (
        <Portal
          className="dialogPortalContainer z-tooltip gs-dialog"
          data-testid="dialogPortalContainer"
        >
          <div className="modal-content" style={{ display: "block" }}>
            <div className="modal-header">
              <h5 className="modal-title">
                {title || formatMessage({ id: "common.confirm" })}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setShowModal(false)}
              >
                {/* eslint-disable-next-line react/jsx-no-literals */}
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            {children && <div className="modal-body">{children}</div>}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => setShowModal(false)}
              >
                {formatMessage({ id: "common.cancel" })}
              </button>
              <button
                type="button"
                className={`btn ${variant}`}
                onClick={handleConfirmClick}
              >
                {confirmLabel || formatMessage({ id: "common.confirm" })}
              </button>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};
