import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { intlShape } from 'utils/shapes';

function SearchInput({ onSearch, placeholder = '', intl }) {
  return (
    <div className="search_field">
      <input
        type="text"
        className="form-control search-query"
        placeholder={placeholder || intl.formatMessage({
          id: 'common.search',
        })}
        onChange={(e) => onSearch(e.target.value.toLowerCase())}
      />
    </div>
  );
}

SearchInput.propTypes = {
  onSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  intl: intlShape,
};

export default injectIntl(SearchInput);
