import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { intlShape } from 'utils/shapes';
import * as selectors from 'js/redux/selectors';
import { fetchGroups } from 'js/redux/api/data';
import React from 'react';
import PropTypes, {
  number, oneOfType, shape, string,
} from 'prop-types';
import { compose } from 'redux';
import stringUtils from 'js/react/_utils/stringUtils';
import cancan from 'js/system/cancan';
import Immutable from 'immutable';
import List from 'js/react/_utils/react-list';
import ListHeader from 'components/StandardView/ListHeader';
import PlaylistAddForm from 'js/react/playlists/playlist-add';
import MultiselectFilter from 'js/react/components/MultiselectFilter';
import { ADMIN_GROUP_MANAGEMENT } from 'constants/permissions';
import { isFinite, isNaN } from 'lodash';
import convertType2Icon from './utils';

// Listrow component
const PlaylistRow = (props) => {
  const { intl, item } = props;

  return (
    <tr
      key={item.get('id')}
      className={`c255${props.active === true ? ' active' : ''}`}
    >
      {/* <td>
                <i className="icon icon-remove-sign" onClick={ remove(item.get('id')) }></i>
            </td> */}
      <td>{convertType2Icon(item.get('type'))}</td>
      <td className="cap">{item.get('sub_type')}</td>
      <td>
        <a href={`#/playlists/${item.get('id')}/edit`}>
          {item.get('name')}
        </a>
      </td>
      {cancan.can('read', ADMIN_GROUP_MANAGEMENT) && (
        <td>{(props.group && props.group.name) || intl.formatMessage({ id: 'common.unknownOption' })}</td>
      )}
      <td>{stringUtils.toMMSS(item.get('max_time'), true)}</td>
    </tr>
  );
};

// Listbody component
const PlaylistListBody = List((props) => {
  return (
    <tbody>
      {props.items
        .map((i) => {
          const itemId = i.get('id');
          const groupId = i.get('group');

          return (
            <PlaylistRow
              key={itemId}
              intl={props.intl}
              item={i}
              group={props.groups.find(({ id }) => id === groupId)}
            />
          );
        })
        .toArray()}
    </tbody>
  );
});

// List component
const mapStateToProps = (state) => {
  return {
    groups: selectors.getGroups(state),
    groupsById: selectors.getGroupsInObject(state),
    groupsSortedByName: selectors.getGroupsSortedByName(state),
  };
};

const mapDispatchToProps = {
  fetchGroups,
};

const propTypes = {
  fetchGroups: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(shape({
    id: oneOfType([string, number]),
  })).isRequired,
  intl: intlShape.isRequired,
};

class PlaylistsList extends React.Component {
  constructor(props) {
    super(props);

    const storedSubTypeFilters =
      window.localStorage && window.localStorage.getItem('playlistlist-filters-subtypes');
    const filteredSubTypes = storedSubTypeFilters
      ? JSON.parse(storedSubTypeFilters)
      : [];

    const storedGroupFilters =
      window.localStorage && window.localStorage.getItem('playlistlist-filters-groups');
    const filteredGroups = storedGroupFilters
      ? JSON.parse(storedGroupFilters)
      : [];

    const { processedPlaylists: playlists, playlistsContainUnknownGroups } = this.handleEmptyGroupInPlaylists(this.props);

    this.state = {
      filteredSubTypes,
      filteredGroups,
      playlists,
      playlistsContainUnknownGroups,
      loading: true,
      sortBy: 'name',
      sortFunc: 'name',
      asc: true,
      showAddForm: false,
      searchQuery: '',
      // description: this.props.intl.formatMessage({
      //   id: 'views.planning.playlists.changeThisDescription',
      // }),
      // templates: [],
    };
  }

  componentDidMount() {
    this.props.fetchGroups();
  }

  UNSAFE_componentWillReceiveProps(nextProps) { // eslint-disable-line react/sort-comp
    if (this.props.playlists !== nextProps.playlists || this.props.groupsById !== nextProps.groupsById) {
      const { processedPlaylists: playlists, playlistsContainUnknownGroups } = this.handleEmptyGroupInPlaylists(nextProps);

      this.setState({
        playlists,
        playlistsContainUnknownGroups,
        subTypeOptions: this.getSubTypeOptions(nextProps.playlists).toJS(),
      });
    }
  }

  getSubTypeOptions(playlists) {
    const playlistSubTypes = playlists
      ? playlists.filter((playlist) => playlist.get('state') !== 'trashed').reduce((acc, playlist) => {
        return acc.add(playlist.get('sub_type').toLowerCase());
      }, Immutable.Set())
      : Immutable.Set();

    return playlistSubTypes.map((playlistSubType) => {
      return {
        id: playlistSubType,
        translationKey: `views.playlists.${playlistSubType}`,
      };
    }).sortBy(({ id }) => id);
  }

  closeAddForm = () => {
    this.setState({
      showAddForm: false,
    });
  }

  /**
     * Callback from render to set appropriate sort field or function
     * @param {string} category String identifier of key to sort on
     */
  setSortParams = (category) => {
    return () => {
      if (this.state.sortBy === category) {
        this.setState(({ asc }) => ({
          asc: !asc,
        }));
      } else {
        let sortFunc = category;
        // in special cases calculate the value that should be used in sorting
        if (category === 'max_time') {
          sortFunc = (val) => {
            if (
              !isNaN(parseFloat(val.get('max_time'))) &&
              isFinite(val.get('max_time'))
            ) {
              return parseFloat(val.get('max_time'));
            }
          };
        }
        if (category === 'group') {
          sortFunc = (val) => {
            const { name } = this.props.groups.find(
              ({ id }) => id === val.get('group'),
            ) || { name: '' };
            return name.toLowerCase();
          };
        }
        this.setState({
          asc: true,
          sortBy: category,
          sortFunc,
        });
      }
    };
  }

  /**
     * Called when searchfield value is changed
     * @param {object} event Standard javascript onChange event
     */
  handlePlaylistSearch = (searchQuery) => {
    this.setState({ searchQuery });
  }

  /**
     * Toggles the add form
     * @param {object} event Standard javascript onChange event
     */
  handleShowAddForm = () => {
    this.setState(({ showAddForm }) => ({ showAddForm: !showAddForm }));
  }

  handleFilterSubType = (filteredSubTypes) => {
    this.setState({ filteredSubTypes });
    window.localStorage &&
      window.localStorage.setItem(
        'playlistlist-filters-subtypes',
        JSON.stringify(filteredSubTypes),
      );
  }

  handleFilterGroup = (filteredGroups) => {
    this.setState({ filteredGroups });
    window.localStorage &&
      window.localStorage.setItem(
        'playlistlist-filters-groups',
        JSON.stringify(filteredGroups),
      );
  }

  handleEmptyGroupInPlaylists = (props = this.props) => {
    let playlistsContainUnknownGroups = false;
    const processedPlaylists = props.playlists.reduce(
      (acc, item) => {
        const itemId = item.get('id');
        const groupId = item.get('group');

        const groupHasData = !!(groupId && props.groupsById && props.groupsById[groupId]);

        if (groupHasData) {
          return acc.set(itemId, item);
        }

        playlistsContainUnknownGroups = true;

        return acc.set(itemId, item.set('group', ''));
      },
      Immutable.Map(),
    );

    return { playlistsContainUnknownGroups, processedPlaylists };
  }

  matchesGroupFilter = (playlist) => {
    const { filteredGroups } = this.state;
    const groupId = playlist.get('group');

    if (!filteredGroups || filteredGroups.length === 0) {
      return true;
    }

    if (filteredGroups.includes(groupId)) {
      return true;
    }

    return false;
  }

  matchesSubTypeFilter(playlist) {
    const { filteredSubTypes } = this.state;
    const mediaType = playlist.get('sub_type');

    if (!filteredSubTypes || filteredSubTypes.length === 0) {
      return true;
    }

    return filteredSubTypes.includes(mediaType);
  }

  prepPlaylists() {
    return this.state.playlists.filter((playlist) => {
      return (
        playlist.get('state') !== 'trashed' &&
        this.matchesSubTypeFilter(playlist) &&
        this.matchesGroupFilter(playlist)
      );
    });
  }

  // Render methods
  renderAddBtn() {
    return (
      <button
        type="button"
        className={`btn ${this.state.showAddForm ? 'btn-light border' : 'btn-primary'}`}
        onClick={this.handleShowAddForm}
        data-testid="PlaylistListAddButton"
      >
        <i
          className={
            `${this.state.showAddForm ? 'icon-ban-circle' : 'icon-list icon-white'
            }`
          }
        />{' '}
        {this.state.showAddForm ? (
          <FormattedMessage id="common.cancel" />
        ) : (
          <FormattedMessage id="common.add" />
        )}
      </button>
    );
  }

  render() {
    const ascChevron = this.state.asc
      ? ' icon-chevron-up'
      : ' icon-chevron-down';

    return (
      <div className="holder" data-testid="PlaylistList">
        <ListHeader
          label={<FormattedMessage id="common.playlists" />}
          actions={this.renderAddBtn()}
          onSearch={this.handlePlaylistSearch}
          helpBody="help.playlists.body"
          helpHeader="help.playlists.header"
        >
          <div
            className="row mb-2 mt-3"
          >
            <MultiselectFilter
              wrapperClassNames="col-md-6"
              onChange={this.handleFilterSubType}
              options={this.state.subTypeOptions}
              placeholderTranslationKey="views.playlists.filterBySubType"
              selectedIds={this.state.filteredSubTypes}
              testId="MultiselectFilter-subType"
            />
            {cancan.can('read', ADMIN_GROUP_MANAGEMENT) && (
              <MultiselectFilter
                onChange={this.handleFilterGroup}
                options={this.props.groupsSortedByName}
                optionsCanBeUnknown={this.state.playlistsContainUnknownGroups}
                placeholderTranslationKey="common.filterByGroup"
                selectedIds={this.state.filteredGroups}
                testId="MultiselectFilter-group"
              />
            )}
          </div>
        </ListHeader>
        {this.state.showAddForm && <PlaylistAddForm onAdd={() => this.setState({ showAddForm: false })} />}
        <div className="table_container">
          <table className="table mb-0 table-hover">
            <thead>
              <tr>
                <th className="w10" title="Type" />
                <th
                  className="w75 sortList"
                  onClick={this.setSortParams('sub_type')}
                >
                  <i
                    className={
                      `sortIcon${
                        this.state.sortBy === 'sub_type'
                          ? ascChevron
                          : ' icon-chevron-right'}`
                    }
                  />
                  <FormattedMessage id="common.subtype" />
                </th>
                <th className="sortList" onClick={this.setSortParams('name')}>
                  <i
                    className={
                      `sortIcon${
                        this.state.sortBy === 'name'
                          ? ascChevron
                          : ' icon-chevron-right'}`
                    }
                  />
                  <FormattedMessage id="common.name" />
                </th>
                {cancan.can('read', ADMIN_GROUP_MANAGEMENT) && (
                  <th
                    className="sortList"
                    onClick={this.setSortParams('group')}
                  >
                    <i
                      className={
                        `sortIcon${
                          this.state.sortBy === 'group'
                            ? ascChevron
                            : ' icon-chevron-right'}`
                      }
                    />
                    <FormattedMessage id="common.group" />
                  </th>
                )}
                <th
                  className="w75 sortList"
                  onClick={this.setSortParams('max_time')}
                >
                  <i
                    className={
                      `sortIcon${
                        this.state.sortBy === 'max_time'
                          ? ascChevron
                          : ' icon-chevron-right'}`
                    }
                  />
                  <FormattedMessage id="common.length" />
                </th>
              </tr>
            </thead>
            <PlaylistListBody
              intl={this.props.intl}
              items={this.prepPlaylists()}
              sortBy={this.state.sortFunc}
              asc={this.state.asc}
              groups={this.props.groups}
              searchBy={(item) => item.get('name')}
              searchQuery={this.state.searchQuery}
            />
          </table>
          <div className="pa10">
            {!this.props.loading && !this.state.loading &&
              this.state.playlists.size === 0 && (
              <h4>
                <FormattedMessage id="views.playlists.noPlaylistsCreated" />
              </h4>
            )}
          </div>
        </div>
      </div>
    );
  }
}

PlaylistsList.propTypes = propTypes;

export default compose(
  injectIntl,
  connect(mapStateToProps, mapDispatchToProps),
)(PlaylistsList);
