/* jshint white:true, browser:true, unused:true, undef:true, newcap:true, latedef:true, indent:4, forin:true, camelcase:true, esnext:true */

import {
  keys,
  each,
  isUndefined,
  sortBy,
} from 'lodash'

var DEFAULT_LIFETIME = 120000;
var DEFAULT_MAXSIZE = 1024;

var caches = {},
    nextId = 1;

function gc() {
    var now = new Date().getTime();
    console.log('Cache GC: ' + keys(caches).join(', '));
    each(caches, function (c, id) {
        var limit = now - c.lifetime;
        c.vacuum();
        if (c.ts < limit) {
            console.log('Cache expired: ' + id);
            delete caches[id];
        }
    });
}

var gcInterval = setInterval(gc, 60000);

export function cacheFactory (name, lifetime, maxSize) {
    var cacheId = name + '-' + nextId++,
        data = {},
        used = {};

    lifetime = lifetime || DEFAULT_LIFETIME;
    maxSize = maxSize || DEFAULT_MAXSIZE;

    function mostRecentlyUsed([id, ts]) {
        return -ts;
    }

    function vacuum() {
        var limit = new Date() - lifetime,
            size = 0;

        var tmpData =  sortBy(used, mostRecentlyUsed)
            .reduce((acc, [id, ts]) => {
                if (size < maxSize && (ts || 0) > limit) {
                    acc[id] = data[id];
                    size++;
                } else {
                    delete used[id];
                }
                return acc;
            }, {});
        data = tmpData;
    }

    return {
        get(id) {
            var item = data[id];
            if (!isUndefined(item)) {
                var now = new Date();
                if (used[id][1] > (now - lifetime)) {
                    used[id][1] = now;
                    caches[cacheId] = {lifetime, vacuum, now};
                    return item;
                } else {
                    console.log(cacheId + ': expired ' + id);
                    delete data[id];
                    delete used[id];
                    return null;
                }
            }
            return null;
        },

        evict(id) {
            if (id) {
                delete data[id];
                delete used[id];
            }
            vacuum();
            return this;
        },

        set(id, item) {
            var ts = new Date().getTime();

            data[id] = item;
            used[id] = [id, ts];
            caches[cacheId] = {lifetime, vacuum, ts};

            return item;
        },

        flush() {
            data = [];
            used = [];
            return this;
        }
    };
};

