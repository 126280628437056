import { useState } from 'react';
import { useDispatch } from 'react-redux';

export const useRequestWithPromise = () => {
  const dispatch = useDispatch();
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(false);

  const doRequest = (action) => {
    return new Promise((resolve, reject) => {
      const handleSuccess = (successAction) => {
        setError(null);
        setPending(false);
        resolve(successAction);
      };
      const handleError = (err) => {
        setError(err);
        setPending(false);
        reject(err);
      };

      setPending(true);
      dispatch({
        ...action,
        meta: {
          ...action.meta,
          onSuccess: handleSuccess,
          onError: handleError,
        },
      });
    });
  };

  return {
    dispatch: doRequest,
    pending,
    error,
  };
};
