import React from 'react';
import { compose } from 'redux';
import { defaultMemoize } from 'reselect';
import { injectIntl } from 'react-intl';
import { intlShape } from 'utils/shapes';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchSchemes } from 'js/redux/api/data';
import * as selectors from 'js/redux/selectors';

const propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func.isRequired,
  fetchSchemes: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  intl: intlShape.isRequired,
};

class SchemePicker extends React.PureComponent {
  getSchemes = defaultMemoize((schemes = [], excludes = []) => {
    return schemes.filter(({ id }) => !excludes.includes(id));
  })

  componentDidMount() {
    if (!this.props.schemes || !this.props.schemes.length) {
      this.props.fetchSchemes();
    }
  }

  handleChange = (e) => {
    !this.props.disabled && this.props.onChange(e.target.value);
  }

  render() {
    const {
      schemes = [],
      value,
      disabled,
      intl: { formatMessage },
      excludes,
      defaultMessage,
    } = this.props;

    return (
      <select
        className="form-control w-100"
        onChange={this.handleChange}
        value={value}
        disabled={disabled}
      >
        <option value="">
          {defaultMessage || formatMessage({ id: 'common.noOption' })}
        </option>
        {this.getSchemes(schemes, excludes).map(({ id, name }) => (
          <option
            key={id}
            value={id}
          >
            {name}
          </option>
        ))}
      </select>
    );
  }
}

SchemePicker.propTypes = propTypes;
SchemePicker.defaultProps = {};

export default compose(connect(
  (state) => {
    return {
      schemes: selectors.getSchemes(state) || [],
    };
  },
  {
    fetchSchemes,
  },
), injectIntl)(SchemePicker);
