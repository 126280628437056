import React from 'react';

import ContextProvider from 'components/ContextProvider';
import HelpModal from 'components/HelpModal';

const HelpModalProvider = () => {
  return (
    <ContextProvider>
      <HelpModal />
    </ContextProvider>
  );
};

export default HelpModalProvider;
