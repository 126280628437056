import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import Dropdown, { DropdownToggle, DropdownContent } from 'components/Dropdown';
import { fetchClientHistory } from 'src/entities/clients/actionCreators';
import { getClientHistory } from 'src/entities/clients';
import { useRequestWithPromise } from 'src/core/api';
import LoadingSpinner from 'src/components/LoadingSpinner';
import { PagePicker } from 'js/react/components/PaginatedList/PagePicker';

const ClientHistory = ({ clientId, columnId }) => {
  const pageSize = 10;
  const [indexOfFirstItemOnPage, setIndexOfFirstItemOnPage] = useState(0);
  const { dispatch, pending } = useRequestWithPromise();
  const { history } = useSelector(getClientHistory(clientId)) || {};

  useEffect(() => {
    dispatch(fetchClientHistory(clientId));
  }, []);

  if (pending) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <h5>
        <FormattedMessage id="common.history" />
      </h5>
      <table className="table table-sm">
        <thead>
          <tr>
            <th>
              <FormattedMessage id="common.date" />
            </th>
            <th>
              <FormattedMessage id="common.value" />
            </th>
          </tr>
        </thead>
        <tbody>
          {history?.slice(indexOfFirstItemOnPage, indexOfFirstItemOnPage + pageSize).map((data) => {
            const fieldValue = data.fields[columnId];
            const time = moment(data.timestamp * 1000).format('YYYY-MM-DD HH:mm');

            return (
              <tr key={data.timestamp}>
                <td className="small text-muted align-middle">
                  {time}
                </td>
                <td>
                  {fieldValue}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {!history?.length && (
        <FormattedMessage id="views.clients.noHistoryDataFound" />
      )}
      <PagePicker
        pageSize={pageSize}
        totalItems={history?.length || 0}
        currentIndex={indexOfFirstItemOnPage}
        onChange={setIndexOfFirstItemOnPage}
        showAll={false}
      />
    </>
  );
};

export const ClientFieldHistoryDropdown = ({ clientId, columnId }) => {
  return (
    <Dropdown className="btn-group ml-auto">
      <DropdownToggle className="btn btn-transparent py-0">
        <i className="icon-book" />
      </DropdownToggle>
      <DropdownContent className="dropdown-menu show p-2">
        <ClientHistory
          clientId={clientId}
          columnId={columnId}
        />
      </DropdownContent>
    </Dropdown>
  );
};
