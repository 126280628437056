import { isUndefined, isFunction } from 'lodash';

let session;
// let role = 'unauthorized';
let rules = {};

export const cancan = {
  getSession() {
    return session;
  },

  setSession(s) {
    rules = {};
    session = s;
    // role = session?.role || 'unauthorized';

    // GEOSIGNAGE-EDIT
    // role = (s && s.id) ? s.get('user').role : 'unauthorized';

    // Check if trial expired
    // var trial = this.isTrial();
    // if (trial && trial < new Date()) {
    //     role = 'expired';
    // }
    // GEOSIGNAGE-EDIT end
    const modules = session?.module_permissions;
    (modules || []).forEach((permission) => {
      this.allow('all', permission, true);
    });

    const systemSettings = session.settings;
    const settingKeys = Object.keys(systemSettings || {});
    settingKeys.forEach((settingKey) => {
      const settingsValue = systemSettings[settingKey];
      this.allow(settingsValue, settingKey, true);
    });
  },

  allow(action, subject, condition) {
    if (!isUndefined(subject.classId)) {
      subject = subject.classId;
    }
    rules = rules || {};
    rules[subject] = rules[subject] || {};
    rules[subject][action] = condition;
  },

  can(action, subject, object) {
    if (localStorage.getItem(subject)) {
      return localStorage.getItem(subject) == 'true';
    }

    if (!action || !subject) {
      return false;
    }

    if (!isUndefined(subject.classId)) {
      subject = subject.classId;
    }

    if (!rules[subject]) {
      if (!rules.all) {
        // console.log('Warning: No permissions defined for: ' + subject);
        return false;
      }
      subject = 'all';
    }

    let cond = rules[subject][action];
    if (isUndefined(cond)) {
      cond = rules[subject].all;
    }

    if (isFunction(cond)) {
      return cond(object);
    }
    return cond;
  },

};

export default cancan;
