import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actionCreators } from 'js/redux/dashboard';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  BADGES,
  BADGE_NAMES,
} from '../badges';

function WidgetRow({ type, createFromPreset }) {
  return (
    <div className="mb-3">
      <div className="clearfix mb-1">
        <h4>{BADGE_NAMES[type]}</h4>
        <button
          className="btn btn-success"
          onClick={createFromPreset}
        >
          <FormattedMessage id="views.dashboard.addBadge" />
        </button>
      </div>
    </div>
  );
}

function AddBadge({ addBadge, onClose }) {
  const badges = Object.keys(BADGES);

  return (
    <div>
      <h2><FormattedMessage id="views.dashboard.addBadge" /></h2>
      <hr />

      {badges.map((w) => (
        <WidgetRow
          key={w}
          type={w}
          createFromPreset={() => {
            addBadge(w);
            onClose();
          }}
        />
      ))}
    </div>
  );
}

AddBadge.propTypes = {
  addBadge: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const mapActions = (dispatch) => ({
  addBadge(badgeType) {
    dispatch(actionCreators.addDashboardBadge({
      badgeType,
    }));
  },
});

export default connect(null, mapActions)(injectIntl(AddBadge));
