import { createCrudActionCreators } from 'src/core/api';
import {
  URL,
  COLLECTION,
} from './constants';

export const {
  create: createScheme,
  readAll: fetchSchemes,
  update: updateScheme,
  destroy: deleteScheme,
} = createCrudActionCreators({ collection: COLLECTION, url: URL });
