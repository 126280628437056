// TODO: export namespaces instead, e.g. export * as selectors to avoid unnecessary work

import reducer from './reducer';

export default reducer;

export {
  fetchPlaces,
  createPlace,
  updatePlace,
  deletePlace,
  fetchPlaylists,
  createPlaylist,
  updatePlaylist,
  deletePlaylist,
  fetchGroups,
  createGroup,
  updateGroup,
  deleteGroup,
  fetchUsers,
  createUser,
  updateUserGroup,
  updateUserName,
  updateUserPassword,
  updateUserViews,
  deleteUser,
  fetchTriggerConditions,
  fetchLayouts,
  createLayout,
  updateLayout,
  deleteLayout,
  fetchSchemes,
  createScheme,
  updateScheme,
  deleteScheme,
} from './actionCreators';

export {
  getPlaces,
  getPlaceById,
  getPlacesInObject,
  getPlaylists,
  getPlaylistById,
  getPlaylistsInObject,
  getFromDate,
  getToDate,
  getGroups,
  getGroupsInObject,
  getGroupsSortedByName,
  getGroupsSortedByNameExceptAdmin,
  getGroupIdsSortedByName,
  getUsers,
  getUsersSortedByName,
  getTriggerConditions,
  getPlayerLayouts,
  getPlayerLayoutsInObject,
  getLayoutTemplates,
  getPlayerLayoutById,
  getLayoutTemplateById,
  getSchemes,
} from './selectors';
