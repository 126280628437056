import React, { memo, useContext } from 'react';
import { useIntl } from 'react-intl';
import { compose } from 'redux';

import TabView from 'js/react/components/TabView';
import LoadingSpinner from 'src/components/LoadingSpinner';
import GSColumnsLayout from 'js/react/components/GSColumnsLayout';
import { useSimulationData } from '../hooks/useSimulationData';
import { MapView as OpenStreetMap } from './Map';
import * as Layout from './Layout';
import Controls from './Controls';
import HistoryList from './HistoryList';
import { PlaceList } from './PlaceList';
import {
  setFullscreen,
  SimulationContext,
  StateManagerHOC,
} from './StateManager';
import { MediaPlayerControls } from './MediaPlayerControls';
import { SimulationFrame } from './SimulationFrame';
import { useMediaServerManager } from '../hooks/useMediaServerManager';

export const SimulationView = compose(
  memo,
  StateManagerHOC
)(() => {
  const intl = useIntl();
  const [{ visiblePlaces }, dispatch] = useContext(SimulationContext);
  const initialLoad = useSimulationData(visiblePlaces);
  const nextMedia = useMediaServerManager();

  if (initialLoad) {
    return (
      <GSColumnsLayout
        leftCol={
          <Layout.LeftPanel>
            <Layout.Title />
            <LoadingSpinner wrapperClassNames="flex-grow-1 py-4" />
          </Layout.LeftPanel>
        }
        rightCol={<LoadingSpinner wrapperClassNames="flex-grow-1 py-4" />}
      />
    );
  }

  return (
    <GSColumnsLayout
      className="scheme-preview"
      onClick={() => dispatch(setFullscreen(false))}
      leftCol={
        <>
          <Layout.LeftPanel>
            <Layout.Title />
            <MediaPlayerControls />
          </Layout.LeftPanel>
          <TabView
            className="px-3"
            tabs={[
              {
                id: 'history',
                label: intl.formatMessage({
                  id: 'views.simulation.playbackHistory',
                }),
                'data-testid': 'playback-history',
              },
              {
                id: 'places',
                label: intl.formatMessage({
                  id: 'views.simulation.visiblePlaces',
                }),
                'data-testid': 'simulation-place-list',
              },
            ]}
          >
            {(tab) =>
              tab === 'history' ? (
                <HistoryList nextMedia={nextMedia} />
              ) : (
                <PlaceList />
              )
            }
          </TabView>
        </>
      }
      rightCol={
        <Layout.RightContainer
          map={
            <Layout.MapContainer>
              <OpenStreetMap />
              <Layout.ControlsContainer>
                <Controls />
              </Layout.ControlsContainer>
            </Layout.MapContainer>
          }
          player={<SimulationFrame />}
        />
      }
    />
  );
});

SimulationView.propTypes = {};
