import LatestMedia from './LatestMedia';
import LatestMediaEdit from './LatestMedia/Edit';

import PlaybackReport from './PlaybackReport';
import PlaybackReportEdit from './PlaybackReport/Edit';

import ClientHealth from './ClientHealth';
import ClientHealthEdit from './ClientHealth/Edit';

export const WIDGETS = {
  latestmedia: LatestMedia,
  playbackreport: PlaybackReport,
  // clientHealth: ClientHealth,
};

export const WIDGET_EDITORS = {
  latestmedia: LatestMediaEdit,
  playbackreport: PlaybackReportEdit,
  // clientHealth: ClientHealthEdit,
};

export const WIDGET_NAMES = {
  latestmedia: 'Uploaded media',
  playbackreport: 'Playback Report',
  // clientHealth: 'Client Status',
};


export const PRESETS = {
  // clientHealth: [{
  //   title: 'Client health',
  //   thumb: 'pie-graph',
  //   description: 'Client health.',
  //   settings: {},
  // }],

  latestmedia: [{
    title: 'Last uploaded',
    thumb: 'media-list',
    description: 'Display the last five uploaded media objects.',
    settings: {
      count: 5,
    },
  }],

  playbackreport: [{
    type: 'playbackreport',
    title: 'Playback report: Top media',
    thumb: 'pie-graph',
    description: 'Pie chart displaying the media objects played most, measured in duration, the last day.',
    settings: {
      days: 3,
      graph: 'pie',
      filters: {},
      aggregate: {
        key: 'name',
        name: 'Media',
        translationKey: 'common.media',
      },
    },
  },

  {
    type: 'playbackreport',
    title: 'Playback report: Top lines',
    thumb: 'bar-graph',
    description: 'Bar chart displaying the lines with highest number of played media objects the last three days.',
    settings: {
      days: 3,
      graph: 'bar',
      filters: {},
      aggregate: {
        key: 'name',
        name: 'Media',
        translationKey: 'common.media',
      },
    },
  }],
};
