import { v4 as getUuid } from 'uuid';

import * as actions from '../actions';

import {
  get, post, put, del,
} from '../helpers/actionCreatorFactories';

/*
  PLACES
*/

export const fetchPlaces = () => {
  const url = '/places';
  const types = [
    actions.FETCH_PLACES,
    actions.FETCH_PLACES_SUCCESS,
    actions.FETCH_PLACES_FAIL,
  ];
  const meta = {
    verb: 'get',
    collection: 'places',
    uuid: getUuid(),
    url,
    reset: true,
  };

  return get({ url, types, meta });
};

export const createPlace = (bodyInput) => {
  const url = '/places';
  const types = [
    actions.CREATE_PLACE,
    actions.CREATE_PLACE_SUCCESS,
    actions.CREATE_PLACE_FAIL,
  ];
  const meta = {
    verb: 'post',
    bodyInput,
    collection: 'places',
    uuid: getUuid(),
    url,
  };

  return post({
    url, types, meta, bodyInput,
  });
};

export const updatePlace = (bodyInput) => {
  const url = '/places';
  const types = [
    actions.UPDATE_PLACE,
    actions.UPDATE_PLACE_SUCCESS,
    actions.UPDATE_PLACE_FAIL,
  ];
  const meta = {
    verb: 'put',
    bodyInput,
    id: bodyInput.id,
    collection: 'places',
    uuid: getUuid(),
    url,
  };

  return put({
    url, types, meta, bodyInput,
  });
};

export const deletePlace = (id) => {
  const url = '/places';
  const types = [
    actions.DELETE_PLACE,
    actions.DELETE_PLACE_SUCCESS,
    actions.DELETE_PLACE_FAIL,
  ];
  const meta = {
    verb: 'del',
    id,
    collection: 'places',
    uuid: getUuid(),
    url,
  };

  return del({
    url, types, meta, id,
  });
};
