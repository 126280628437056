import { identity } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getPlacesInObject } from 'js/redux/api/data';
import { useRequestWithPromise } from 'src/core/api';
import { fetchPlace } from 'src/entities/places';

function ensureArray(ids = []) {
  return Array.isArray(ids) ? ids : [ids];
}

const usePlacesByIds = (placeIds) => {
  const allPlaces = useSelector(getPlacesInObject);
  return useMemo(() => {
    const ids = ensureArray(placeIds);
    return ids.map((id) => allPlaces[id]).filter(identity);
  },
  [placeIds, allPlaces]);
};

// Ensure that all selected places have a geometry.
// Fetch the full version of any sparse place objects.
export const useEnsurePlacesWithGeo = (placeIds = []) => {
  const { dispatch } = useRequestWithPromise();
  const places = usePlacesByIds(placeIds);

  useEffect(() => {
    const noGeo = places.filter(({ geometry }) => !geometry);
    Promise.all(
      noGeo.map(({ id }) => dispatch(fetchPlace(id))),
    );
  }, [places]);
};

export const usePlacesWithGeometry = (placeIds) => {
  const places = usePlacesByIds(placeIds);

  return useMemo(() => {
    return places.filter((pl) => !!pl?.geometry);
  }, [placeIds, places]);
};
