import * as MsgActions from "../../../js/react/actions/message-actions";
import { apiRequest, getHttpMethod } from "./apiRequest";
import { API_REQUEST } from "./constants";
import {
  apiError,
  apiSuccess,
  removeOngoingRequest,
  setOngoingRequest,
} from "./actionCreators";
import { getPrintableErrorMessage } from "./utils";

export const apiMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    // Dispatch next action first to get the log sequence right.
    const value = next(action);

    if (action.type?.includes(API_REQUEST)) {
      const { requestId, url, onSuccess, onError } = action.meta;

      next(
        setOngoingRequest({
          requestId,
          url,
        })
      );

      const httpMethod = getHttpMethod(action);

      try {
        const response = await apiRequest(action);
        const successAction = apiSuccess({
          payload: response,
          meta: { ...action.meta, httpMethod },
        });
        dispatch(successAction);
        onSuccess?.(successAction);
      } catch (error) {
        let result;
        try {
          result = error.json();
        } catch (e) {
          result = error.text();
        }
        result.then((errorMessage) => {
          const errorAction = apiError({
            payload: errorMessage,
            meta: { ...action.meta, httpMethod },
          });
          dispatch(errorAction);
          onError?.(errorAction);

          if (!action.meta?.silent) {
            const printableErrorMessage =
              getPrintableErrorMessage(errorMessage);
            MsgActions.error(printableErrorMessage);
          }
        });
      }

      next(removeOngoingRequest({ requestId }));
    }

    // Older async actions may depend on the returned value.
    return value;
  };
