import { v4 as getUuid } from 'uuid';

import * as actions from '../actions';

import {
  get,
  // post,
  // put,
  // del
} from '../helpers/actionCreatorFactories';


/*
  Playlist trigger conditions
*/

export const fetchTriggerConditions = (playlistId, condition) => {
  const url = `/playlists/${playlistId}/triggerconditions/${condition}`;
  const types = [
    actions.FETCH_PLAYLISTS,
    actions.FETCH_PLAYLISTS_SUCCESS,
    actions.FETCH_PLAYLISTS_FAIL,
  ];
  const meta = {
    verb: 'get',
    collection: `triggerConditions${playlistId}/${condition}`,
    uuid: getUuid(),
    url,
    reset: true,
  };

  return get({ url, types, meta });
};
