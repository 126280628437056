import MediaCount from './MediaCount';
import PlaylistCount from './PlaylistCount';
import LatestMediaBuild from './LatestMediaBuild';

export const BADGES = {
  mediacount: MediaCount,
  latestbuild: LatestMediaBuild,
  playlistcount: PlaylistCount,
};

export const BADGE_NAMES = {
  mediacount: 'Media count',
  playlistcount: 'Playlist Count',
  latestbuild: 'Latest media build',
};
