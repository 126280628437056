import React from 'react';
import PropTypes from 'prop-types';

export const Button = ({
  onClick,
  children,
  disabled,
  className = 'btn btn-primary'
}) => {
  return (
    <button
      type="button"
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,

};

export default Button;
