import queryString from 'query-string';

export async function get({ queryParams = {}, url: path }) {
  const query = queryString.stringify(queryParams);
  const url = query ? `${path}?${query}` : path;

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw (response);
  }

  return response.json();
}
