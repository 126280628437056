import React, { memo, useContext } from 'react';
import { useIntl } from 'react-intl';

import HistoryItem from './HistoryItem';
import { SimulationContext } from './StateManager';

const NextButton = ({ onClick }) => {
  const intl = useIntl();
  const label = intl.formatMessage({ id: 'views.simulation.skipToNextMedia' });

  return (
    <button
      type="button"
      className="btn btn-white border pointer"
      title={label}
      onClick={onClick}
    >
      <i className="icon-fast-forward mr-1" />
      {label}
    </button>
  );
};

const HistoryList = memo(({ nextMedia }) => {
  const [{ history }] = useContext(SimulationContext);
  return (
    <div className="p-3">
      <NextButton onClick={nextMedia} />
      {history.map((item) => item && (
      // eslint-disable-next-line react/jsx-props-no-spreading
        <HistoryItem key={item.id} {...item} />
      ))}
    </div>
  );
});

export default HistoryList;
