export default {
  edit: 'icon-edit',
  playlists: 'icon-list',
  triggers: 'icon-screenshot',
  plan: 'icon-calendar',
  media: 'icon-film',
  movie: 'icon-facetime-video',
  I: 'icon-picture',
  V: 'icon-film',
  U: 'icon-cog',
  S: 'icon-volume-up',
};
