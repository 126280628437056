import { v4 as getUuid } from 'uuid';

import * as actions from '../actions';

import {
  get,
  post,
  put,
  del,
} from '../helpers/actionCreatorFactories';

const url = '/auth/users';

export function fetchUsers() {
  const types = [
    actions.FETCH_USERS,
    actions.FETCH_USERS_SUCCESS,
    actions.FETCH_USERS_FAIL,
  ];
  const meta = {
    verb: 'get',
    collection: 'users',
    uuid: getUuid(),
    url,
    reset: true,
  };

  return get({ url, types, meta });
}

export function createUser(bodyInput) {
  const types = [
    actions.CREATE_USER,
    actions.CREATE_USER_SUCCESS,
    actions.CREATE_USER_FAIL,
  ];
  const meta = {
    verb: 'post',
    bodyInput,
    collection: 'users',
    uuid: getUuid(),
    url,
  };

  return post({
    url, types, meta, bodyInput,
  });
}

function updateUser({ types, bodyInput }) {
  if (!types) {
    throw new Error('types are required');
  }

  if (!bodyInput) {
    throw new Error('bodyInput is required');
  }

  const meta = {
    verb: 'put',
    bodyInput,
    id: bodyInput.id,
    collection: 'users',
    uuid: getUuid(),
    url,
  };

  return put({
    url, types, meta, bodyInput,
  });
}

export function updateUserGroup({ userId, group }) {
  const types = [
    actions.UPDATE_USER_GROUP,
    actions.UPDATE_USER_GROUP_SUCCESS,
    actions.UPDATE_USER_GROUP_FAIL,
  ];
  const bodyInput = {
    id: userId,
    group,
  };

  return updateUser({ types, bodyInput });
}

export function updateUserName({ userId, name }) {
  const types = [
    actions.UPDATE_USER_NAME,
    actions.UPDATE_USER_NAME_SUCCESS,
    actions.UPDATE_USER_NAME_FAIL,
  ];
  const bodyInput = {
    id: userId,
    name,
  };

  return updateUser({ types, bodyInput });
}

export function updateUserPassword({ userId, password }) {
  const types = [
    actions.UPDATE_USER_PASSWORD,
    actions.UPDATE_USER_PASSWORD_SUCCESS,
    actions.UPDATE_USER_PASSWORD_FAIL,
  ];
  const bodyInput = {
    id: userId,
    password,
  };

  return updateUser({ types, bodyInput });
}

export function updateUserViews({ userId, accessibleViews }) {
  const types = [
    actions.UPDATE_USER_VIEWS,
    actions.UPDATE_USER_VIEWS_SUCCESS,
    actions.UPDATE_USER_VIEWS_FAIL,
  ];
  const bodyInput = {
    id: userId,
    accessible_views: accessibleViews,
  };

  return updateUser({ types, bodyInput });
}

export function deleteUser(id) {
  const types = [
    actions.DELETE_USER,
    actions.DELETE_USER_SUCCESS,
    actions.DELETE_USER_FAIL,
  ];
  const meta = {
    verb: 'del',
    id,
    collection: 'users',
    uuid: getUuid(),
    url,
  };

  return del({
    url, types, meta, id,
  });
}
