import { createSelector } from 'reselect';
import readLocalData from './helpers/read-local-data';

/*
* Two ways to call read():
* 1. read(state, resource, id) where id is optional
* 2. read({ state, resource, id, format }) where both id and format are optional
*/
export function read(arg1, arg2, arg3) {
  let state;
  let collectionName;
  let id;
  let format;
  if (arg2) {
    state = arg1;
    collectionName = arg2;
    id = arg3;
  } else {
    ({
      state,
      collectionName,
      id,
      format,
    } = arg1);
  }

  const { entities = {} } = state;

  return readLocalData({
    collectionName,
    id,
    entities,
    format,
  });
}

function sortByName(
  arrayOfObjectsWithNameProperty,
  reverseAlphabetical = false,
) {
  return arrayOfObjectsWithNameProperty.sort((elementA, elementB) => {
    const nameA = elementA.name.toLowerCase();
    const nameB = elementB.name.toLowerCase();

    if (nameA < nameB) {
      return reverseAlphabetical ? 1 : -1;
    }

    if (nameA > nameB) {
      return reverseAlphabetical ? -1 : 1;
    }

    return 0;
  });
}

export const getMediaExtraById = (state, id) => (
  read({
    state,
    collectionName: 'mediaExtra',
    id,
  })
);

export function getPlacesInObject(state) {
  return read({
    state,
    collectionName: 'places',
    format: 'object',
  });
}

export const getPlaces = createSelector(
  getPlacesInObject,
  (placesInObject) => Object.values(placesInObject),
);

export const getPlaceById = (state, id) => (
  read({
    state,
    collectionName: 'places',
    id,
  })
);

export function getPlaylists(state) {
  return read(state, 'playlists');
}

export function getPlaylistsInObject(state) {
  return read({
    state,
    collectionName: 'playlists',
    format: 'object',
  });
}

export const getPlaylistById = (state, id) => (
  read({
    state,
    collectionName: 'playlists',
    id,
  })
);

export function getGroups(state) {
  return read(state, 'groups');
}

export function getGroupsInObject(state) {
  return read({
    state,
    collectionName: 'groups',
    format: 'object',
  });
}

export function getTriggerConditions(state, playlistId, condition) {
  return read(state, `triggerConditions${playlistId}/${condition}`);
}

export const getGroupsSortedByName = createSelector(
  getGroupsInObject,
  (groupsInObject) => sortByName(Object.values(groupsInObject)),
);

export const getGroupsSortedByNameExceptAdmin = createSelector(
  getGroups,
  (groups) => sortByName(groups).filter((group) => group.name.toLowerCase() !== 'admin'),
);

export const getGroupIdsSortedByName = createSelector(
  getGroupsSortedByName,
  (sortedGroups) => sortedGroups.map(({ id }) => id),
);

export function getUsers(state) {
  return read(state, 'users');
}

export const getUsersSortedByName = createSelector(
  getUsers,
  (users) => sortByName(users),
);

export const getFromDate = (state) => {
  return state.get('fromDate');
};

export const getToDate = (state) => {
  return state.get('toDate');
};

export const getPlayerLayouts = (state) => {
  return read(state, 'playerlayouts');
};

export const getPlayerLayoutsInObject = (state) => {
  return read({
    state,
    collectionName: 'playerlayouts',
    format: 'object',
  });
};

export const getLayoutTemplates = (state) => {
  return read(state, 'playerlayouttemplates');
};

const makeNewLayout = () => {
  return {
    id: 'new',
    name: 'New layout',
    aspectRatio: '',
    areas: null,
    areaSettings: {},
  };
};

export const getPlayerLayoutById = (state, id) => {
  if (id === 'new') {
    return makeNewLayout();
  }

  return read({
    state,
    collectionName: 'playerlayouts',
    id,
  });
};

export const getLayoutTemplateById = (state, id) => {
  return read({
    state,
    collectionName: 'playerlayouttemplates',
    id,
  });
};

export const getSchemes = (state) => {
  return read(state, 'schemes');
};
