import {
  API_DELETE_REQUEST,
  API_GET_REQUEST,
  API_POST_REQUEST,
  API_PUT_REQUEST,
} from '../constants';

export const buildDeleteType = (collection) => `[${collection}] ${API_DELETE_REQUEST}`;
export const buildGetType = (collection) => `[${collection}] ${API_GET_REQUEST}`;
export const buildPostType = (collection) => `[${collection}] ${API_POST_REQUEST}`;
export const buildPutType = (collection) => `[${collection}] ${API_PUT_REQUEST}`;
