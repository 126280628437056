import React from 'react';
import { FormattedMessage } from 'react-intl';

const MAX_PAGE_BTNS = 6;
const ACTIVE_PLACEMENT = Math.ceil(MAX_PAGE_BTNS / 2);

function Btn({
  value, children, onClick, disabled, testId,
}) {
  return (
    <button
      type="button"
      className="btn border"
      data-testid={testId}
      disabled={disabled}
      onClick={disabled
        ? undefined
        : () => onClick(value)}
    >
      {children}
    </button>
  );
}

export function PagePicker({
  currentIndex = 0,
  totalItems = 0,
  pageSize = 0,
  onChange,
  className = 'mt-1',
  showAll = true,
}) {
  const pageCount = Math.ceil(totalItems / pageSize);
  const currentPage = Math.floor(currentIndex / pageSize);
  let pages = [];

  for (let i = 0; i < pageCount; i++) {
    const currentClassName = `btn border${i === currentPage ? ' active' : ''}`;
    pages.push(
      <button
        key={i}
        type="button"
        onClick={() => onChange(i * pageSize)}
        className={currentClassName}
      >
        {i + 1}
      </button>,
    );
  }

  if (pageCount <= 1 || currentIndex < 0) {
    return null;
  }

  if (pages.length > MAX_PAGE_BTNS) {
    let startIndex = Math.max(0, currentPage - ACTIVE_PLACEMENT);
    startIndex = Math.min(startIndex, pageCount - MAX_PAGE_BTNS);
    pages = pages.slice(startIndex, startIndex + MAX_PAGE_BTNS);
  }

  const canForward = currentPage < pageCount - 1;
  const canBack = currentPage > 0;
  const btnClassName = `btn-group ${className}`;
  const one = '1';

  return (
    <div className={btnClassName}>
      {pageCount > 2 && (
        <Btn
          disabled={!canBack}
          onClick={onChange}
          value={(currentPage - 1) * pageSize}
        >
          {'<'}
        </Btn>
      )}
      {pageCount > MAX_PAGE_BTNS && currentPage > ACTIVE_PLACEMENT && (
        <Btn
          onClick={onChange}
          value={1}
        >
          {one}
          {currentPage > ACTIVE_PLACEMENT + 1 && '...'}
        </Btn>
      )}
      {pages}
      {pageCount > MAX_PAGE_BTNS && currentPage < pageCount - ACTIVE_PLACEMENT && (
        <Btn
          onClick={onChange}
          value={(pageCount - 1) * pageSize}
        >
          {currentPage < pageCount - ACTIVE_PLACEMENT - 1 && '...'}
          {pageCount}
        </Btn>
      )}
      {pageCount > 2 && (
        <Btn
          disabled={!canForward}
          onClick={onChange}
          value={(currentPage + 1) * pageSize}
        >
          {'>'}
        </Btn>
      )}

      {showAll && (
        <Btn
          testId="pager-show-all"
          onClick={onChange}
          value={-1}
        >
          <FormattedMessage id="common.showAll" /> {totalItems}
        </Btn>
      )}
    </div>
  );
}
