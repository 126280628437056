import React, {
  useContext, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { arrayOf, string, bool } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import cancan from 'js/system/cancan';

import {
  DataTableProvider, DataTableBody, DataTableContainer, DataTableHead,
} from 'js/react/components/DataTable';
import ListHeader from 'js/react/components/StandardView/ListHeader';
import Spinner from 'js/react/Dashboard/components/Spinner';

import { ADMIN_GROUP_MANAGEMENT } from 'constants/permissions';
import { getGroupsInObject } from 'js/redux/selectors';
import { getPlacesInObject } from 'src/entities/places';
import { PlaceMapContext } from '../PlacesStateManager';
import { columnConfigs } from './configs';
import { PlaceFilters } from './PlaceFilters';

const PlaceRow = ({
  item, highlight, setHighlight, isVisible,
}) => {
  const groupsById = useSelector(getGroupsInObject);
  const {
    id, name, type, group,
  } = item;
  const btnStyle = { backgroundColor: isVisible ? 'grey' : '' };
  const intl = useIntl();

  return (
    <tr
      className={`p-3${id === highlight ? ' bg-info-light' : ''}`}
      onMouseEnter={setHighlight ? () => setHighlight(id) : undefined}
    >
      <td>
        <a
          href={`view::${id}`}
          style={btnStyle}
          className={`btn ${isVisible ? 'active' : ''}`}
          onClick={(e) => e.preventDefault()}
          data-testid="visibility-toggle"
        >
          <i
            className={isVisible
              ? 'icon-eye-open icon-white'
              : 'icon-eye-close'}
            style={{
              pointerEvents: 'none',
            }}
          />
        </a>
      </td>
      <td>
        <a
          href={`edit::${id}`}
          onClick={(e) => e.preventDefault()}
          data-testid="place-edit-link"
        >
          {name}
        </a>
      </td>
      <td>
        {type}
      </td>
      {cancan.can('read', ADMIN_GROUP_MANAGEMENT) && groupsById && (
        <td>
          {groupsById[group]?.name || intl.formatMessage({ id: 'common.unknownOption' })}
        </td>
      )}
    </tr>
  );
};

export const PlacesList = ({ visibleIds, isLoading }) => {
  const allPlaces = useSelector(getPlacesInObject);
  const placeList = useMemo(() => Object.values(allPlaces || {}), [allPlaces]);
  const { highlight, setHighlight } = useContext(PlaceMapContext) || {};
  const [searchQuery, setQuery] = useState('');
  const filteredPlaces = useMemo(() => {
    const query = searchQuery.toLocaleLowerCase();
    return placeList?.filter(({ name }) => name.toLowerCase().includes(query));
  }, [placeList, searchQuery]);

  return (
    <DataTableProvider
      initialSortByProperty="name"
      items={filteredPlaces}
    >
      <div
        className="holder"
        data-testid="PlaylistList"
        onMouseLeave={setHighlight ? () => setHighlight() : undefined}
      >
        <ListHeader
          label={<FormattedMessage id="common.places" />}
          helpBody="help.places.body"
          helpHeader="help.places.header"
          actions={(
            <a
              href={visibleIds?.length
                ? `#/places/${visibleIds.join(',')}/create`
                : '#/places/create'}
              className="btn btn-primary"
              data-testid="add-place-btn"
            >
              <i className="icon-globe icon-white" /> <FormattedMessage id="common.add" />
            </a>
          )}
          onSearch={setQuery}
        >
          <PlaceFilters />
        </ListHeader>
        {isLoading && (
          <Spinner
            style={{ minWidth: 300, height: 200 }}
          />
        )}
        {!isLoading && (
          <DataTableContainer>
            <DataTableHead theadConfigs={columnConfigs} />
            <DataTableBody>
              {(item) => {
                return (
                  <PlaceRow
                    key={item.id}
                    item={item}
                    highlight={highlight}
                    setHighlight={setHighlight}
                    isVisible={visibleIds.includes(item.id)}
                  />
                );
              }}
            </DataTableBody>
          </DataTableContainer>
        )}
      </div>
    </DataTableProvider>
  );
};

PlacesList.propTypes = {
  visibleIds: arrayOf(string),
  isLoading: bool,
};
