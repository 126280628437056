import React from 'react';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { baseRouteConsumer } from 'js/react/_utils/BaseRouteContext';
import { makeDefaultArea } from 'js/react/ClientGroups/_utils';

import AreaPreview from './components/AreaPreview';
import Tools from './components/LayoutTools';
import LayoutsTable from './components/LayoutsTable';
import LayoutSettings from './components/LayoutSettings';

import {
  DEFAULT_LAYOUT, DEFAULT_ASPECT_RATIO, mergeAreaSetting,
} from '../_utils';

const MAX_AREAS = 10;
const AREA_IDS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

function getLayout(layout) {
  return isEmpty(layout)
    ? DEFAULT_LAYOUT
    : layout;
}

class LayoutEditor extends React.Component {
  constructor(props) {
    super(props);

    const layout = getLayout(props.layout);

    this.state = {
      aspectRatio: layout?.data?.aspectRatio || DEFAULT_ASPECT_RATIO,
      areas: layout?.data?.areas || [makeDefaultArea(AREA_IDS[0])],
      selected: null,
    };
  }

  getAvailableId() {
    const taken = this.state.areas.map(({ id }) => id);
    return AREA_IDS.find((id) => taken.indexOf(id) === -1);
  }

  findArea = ({ id }) => id === this.state.selected

  handleAdd = () => {
    const nextId = this.getAvailableId();
    this.setState(({ areas }) => ({
      areas: [
        makeDefaultArea(nextId),
        ...areas,
      ],
      selected: nextId,
    }));
  }

  handleRemove = () => {
    this.state.areas.length > 1 && this.setState(({ areas }) => ({
      areas: areas.filter((a) => !this.findArea(a)),
      selected: null,
    }));
  }

  moveItem = (shift) => {
    const areas = [...this.state.areas];
    const idx = areas.findIndex(this.findArea);
    const newIndex = idx + shift;

    if (idx !== -1 && newIndex >= 0 && newIndex < areas.length) {
      const area = areas.splice(idx, 1)[0];
      areas.splice(newIndex, 0, area);
      this.setState({ areas });
    }
  }

  handleMoveUp = () => {
    this.moveItem(1);
  }

  handleMoveDown = () => {
    this.moveItem(-1);
  }

  handleSelect = (selected = null) => {
    this.setState({ selected });
  }

  handleDeselect = () => {
    this.setState({ selected: null });
  }

  preventDeselect = (e) => e.stopPropagation();

  handleUpdateArea = ({
    id, height, width, top, left,
  }) => {
    const areas = [...this.state.areas];
    const idx = areas.findIndex(({ id: aid }) => aid === id);
    const area = areas[idx];

    const safeTop = Math.min(Math.max(top, 0), 99);
    const safeLeft = Math.min(Math.max(left, 0), 99);

    if (area) {
      areas[idx] = {
        ...area,
        height: Math.min(height, 100 - safeTop),
        width: Math.min(width, 100 - safeLeft),
        top: safeTop,
        left: safeLeft,
      };
      this.setState({ areas });
    }
  }

  handleSettings = (settings = {}) => {
    this.setState({ ...settings });
  }

  handleSave = () => {
    const { updateLayout } = this.props;
    const { aspectRatio, areas } = this.state;
    const layout = getLayout(this.props.layout);
    const updated = {
      ...layout,
      data: {
        ...(layout.data || {}),
        aspectRatio,
        areas,
        areaSettings: areas.reduce((acc, { id }) => ({
          ...acc,
          [id]: mergeAreaSetting((layout?.data?.areaSettings || acc)[id]),
        }), {}),
      },
    };

    updateLayout(updated);
  }

  renderControls() {
    return (
      <div className="border-top pt-3 clearfix">
        <button
          type="button"
          className="btn btn-success float-right"
          onClick={this.handleSave}
        >
          <FormattedMessage id="common.save" />
        </button>
        <button
          type="button"
          className="btn btn-light"
          onClick={this.props.onClose}
        >
          <FormattedMessage id="common.cancel" />
        </button>
      </div>
    );
  }

  render() {
    return (
      <div
        role="presentation"
        onClick={this.handleDeselect}
        onKeyDown={() => {}}
      >
        <div className="row no-gutters">
          <div
            className="col mr-3"
          >
            <h3>
              <FormattedMessage id="views.layout.editLayout" />
            </h3>
            <div
              role="presentation"
              onClick={this.preventDeselect}
              onKeyDown={() => {}}
            >
              <LayoutSettings
                aspectRatio={this.state.aspectRatio}
                onChange={this.handleSettings}
              />
              <LayoutsTable
                areas={this.state.areas}
                selected={this.state.selected}
                onChange={this.handleUpdateArea}
                onSelect={this.handleSelect}
              />
            </div>
          </div>
          <div
            role="presentation"
            className="col"
            onClick={this.preventDeselect}
            onKeyDown={() => {}}
          >
            <div className="bg-light rounded mb-2">
              <AreaPreview
                areas={this.state.areas}
                aspectRatio={this.state.aspectRatio}
                onSelect={this.handleSelect}
                selected={this.state.selected}
                onChangeArea={this.handleUpdateArea}
              />
            </div>
            <Tools
              canDelete={this.state.areas.length > 1}
              canAdd={this.state.areas.length < MAX_AREAS}
              selected={!!this.state.selected}
              handleAdd={this.handleAdd}
              handleMoveUp={this.handleMoveUp}
              handleMoveDown={this.handleMoveDown}
              handleRemove={this.handleRemove}
            />
          </div>
        </div>
        {this.renderControls()}
      </div>
    );
  }
}

export default baseRouteConsumer(LayoutEditor);
