import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  arrayOf, objectOf, shape, string,
} from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Checkbox } from 'utils/ShiftSelect';
import Dropdown, { DropdownToggle, DropdownContent } from 'components/Dropdown';
import { updateClient } from 'src/entities/clients/actionCreators';
import { ClientFieldHistoryDropdown } from './ClientFieldHistoryDropdown';

const propTypes = {
  columns: arrayOf(shape({
    identifier: string,
    type: string,
    description: string,
    override: string,
  })).isRequired,
  status: shape({
    fields: objectOf(string),
    id: string,
  }).isRequired,
  warnings: arrayOf(string).isRequired,
};

const ColumnContent = ({
  hasWarning, fields, col, client,
}) => {
  const dispatch = useDispatch();
  let currentValue = fields[col.identifier];
  if (col.type === 'date') {
    currentValue = moment.utc(currentValue).local().format('YYYY-MM-DD HH:mm');
  }
  const pendingValue = client[col.override] || currentValue;
  const [value, setValue] = useState(pendingValue);
  const isPending = pendingValue !== currentValue;

  const handleSave = () => {
    dispatch(updateClient({
      ...client,
      [col.override]: value,
    }));
  };

  const label = (
    <>
      {hasWarning &&
        <i className="icon-warning-sign mr-1" />}
      {currentValue}
    </>
  );

  if (col.override) {
    return (
      <Dropdown>
        <DropdownToggle className="btn btn-transparent py-0 w-100">
          {isPending && (
            <i className="icon-refresh mr-1" />
          )}
          {label}
        </DropdownToggle>
        <DropdownContent className="dropdown-menu show p-2">
          <h5>
            <FormattedMessage id="common.edit" />
          </h5>
          {isPending && (
            <div className="alert alert-info alert-small">
              <h6>
                <i className="icon-refresh mr-1" />
                <FormattedMessage id="views.clients.valueIsPending" />
              </h6>
              <p className="mb-0">
                <FormattedMessage id="views.clients.currentvalue" />{': '}{currentValue}
              </p>
              <p className="mb-0">
                <FormattedMessage id="views.clients.nextValue" />{': '}{pendingValue}
              </p>
            </div>
          )}
          <input
            type="text"
            className="form-control mb-2"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <button
            type="button"
            onClick={value === pendingValue ? undefined : handleSave}
            className="btn btn-primary"
            disabled={value === pendingValue}
            data-close-popover
          >
            <FormattedMessage id="common.save" />
          </button>
        </DropdownContent>
      </Dropdown>
    );
  }

  return label;
};


export const ClientRow = ({
  columns = [], status = {}, warnings = [],
}) => {
  const { fields } = status;

  return (
    <tr>
      <td>
        <Checkbox
          id={status.id}
        />
      </td>
      {columns && columns.map((col) => {
        const hasWarning = warnings.indexOf(col.identifier) !== -1;
        const className = `text-nowrap ${hasWarning ? 'alert-danger' : ''}`;
        return (
          <td
            key={col.identifier}
            className={className}
            title={col.description}
          >
            <div className="d-flex">
              <ColumnContent
                col={col}
                client={status}
                fields={fields}
                hasWarning={hasWarning}
              />
              {col.history && (
                <ClientFieldHistoryDropdown
                  clientId={status.id}
                  columnId={col.identifier}
                />
              )}
            </div>
          </td>
        );
      })}
    </tr>
  );
};

ClientRow.propTypes = propTypes;
