import React, {
  useContext, useEffect, useState,
} from 'react';
import debug from 'debug';
import MultiselectFilter from 'js/react/components/MultiselectFilter';
import { DataTableContext } from './DataTableContext';

const log = debug('src:components:DataTable:Filter');

const getInitialFilterValuesFromLocalStorage = (localStorageKey) => {
  const storedFilterValues =
    window.localStorage && window.localStorage.getItem(localStorageKey);

  try {
    return JSON.parse(storedFilterValues);
  } catch (error) {
    return [];
  }
};

const setFilterValuesInLocalStorage = (localStorageKey, newFilterValues) => {
  try {
    const stringifiedValue = JSON.stringify(newFilterValues);
    window.localStorage &&
      window.localStorage.setItem(localStorageKey, stringifiedValue);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const DataTableFilter = ({
  localStorageKey,
  optionsCanBeUnknown = false,
  placeholderTranslationKey,
  propertyName,
  testId,
  wrapperClassNames = 'col-md-6',
  options,
}) => {
  log('render');
  const { filters, setFilters } = useContext(DataTableContext);
  const [filterValues, setFilterValues] = useState(
    getInitialFilterValuesFromLocalStorage(),
  );

  const onFilterChange = (newFilterValues) => {
    const filterFunction = (item) => {
      if (newFilterValues.length > 0) {
        const value = item[propertyName];
        return newFilterValues.includes(value.toLocaleLowerCase
          ? value.toLocaleLowerCase()
          : value);
      }

      return true;
    };

    setFilters({ ...filters, [localStorageKey]: filterFunction });
    setFilterValues(newFilterValues);
    setFilterValuesInLocalStorage(localStorageKey, newFilterValues);
  };

  useEffect(() => {
    const initialFilterValues = getInitialFilterValuesFromLocalStorage();

    if (Array.isArray(initialFilterValues)) {
      const filterFunction = (item) => {
        if (initialFilterValues.length > 0) {
          return initialFilterValues.includes(item[propertyName]);
        }

        return true;
      };

      setFilters({ ...filters, [localStorageKey]: filterFunction });
    }
  }, []);

  return (
    <MultiselectFilter
      wrapperClassNames={wrapperClassNames}
      onChange={onFilterChange}
      options={options}
      optionsCanBeUnknown={optionsCanBeUnknown}
      placeholderTranslationKey={placeholderTranslationKey}
      selectedIds={filterValues}
      testId={testId}
    />
  );
};
