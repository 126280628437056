import {
  findIndex, get, some, sortBy,
} from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

// Components
import Dropdown, { DropdownToggle, DropdownContent } from 'components/Dropdown';
import { useRequestWithPromise } from 'src/core/api';
import { useSelector } from 'react-redux';
import { createPlaylistPlace, fetchPlaylistPlaces, getAllPlaylistPlacesInObject } from 'src/entities/playlistPlaces';
import { ApiErrorMessage } from 'src/components/Error';
import PlaylistPlaceRow from './PlaylistPlaceRow';

const propTypes = {
  playlistId: string.isRequired,
};

const useSearchFilter = (items, attributes) => {
  const [searchQuery, setSearchQuery] = useState('');

  const filtered = useMemo(() => {
    const query = searchQuery.toLocaleLowerCase();
    return items.filter((item) => {
      const values = attributes.map((attr) => {
        const val = item[attr];
        return typeof val === 'string'
          ? val.toLocaleLowerCase()
          : '';
      });

      return some(values, (val) => val.includes(query));
    });
  }, [items, searchQuery, attributes]);

  return [filtered, searchQuery, setSearchQuery];
};

function hasActiveTrigger(place) {
  return place?.triggers?.some(({ active }) => active);
}

function sortByActiveTrigger(places, asc = true) {
  const sorted = places.sort((placeA, placeB) => {
    const aHasActiveTrigger = hasActiveTrigger(placeA);
    const bHasActiveTrigger = hasActiveTrigger(placeB);

    if (aHasActiveTrigger && !bHasActiveTrigger) return asc ? -1 : 1;
    if (!aHasActiveTrigger && bHasActiveTrigger) return asc ? 1 : -1;

    // if they have same activeTrigger status, sort by name
    const nameA = placeA.name;
    const nameB = placeB.name;
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });

  return asc ? sorted : sorted.reverse();
}

const useSortedItems = (items, sortParam, asc) => {
  return useMemo(() => {
    const sorted = sortParam === 'triggerActive'
      ? sortByActiveTrigger(items, asc)
      : items.sort((a, b) => {
        const aVal = get(a, sortParam);
        const bVal = get(b, sortParam);
        return aVal.localeCompare(bVal, navigator.languages[0] || navigator.language, {
          numeric: true,
          ignorePunctuation: true,
        });
      }, [items, sortParam, asc]);
    return asc ? sorted : sorted.reverse();
  });
};

const usePlaylistPlaces = (playlistId) => {
  const allPlaylistPlaces = useSelector(getAllPlaylistPlacesInObject);

  const places = useMemo(() => {
    return Object.values(allPlaylistPlaces || {})
      .filter((pl) => pl.playlistId === playlistId);
  }, [allPlaylistPlaces]);

  const triggers = useMemo(() => {
    // Walk through all triggers to list all available trigger options
    const trgrs = places.reduce((acc, place) => {
      (place.triggers || []).forEach(({ name }) => {
        // Identify triggers that has not been added to triggers before
        if (findIndex(acc, (item) => item.name === name) < 0 && name !== '') {
          // Add both enable and disable actions
          acc.push({ name, action: true });
          acc.push({ name, action: false });
        }
      });

      return acc;
    }, []);

    return sortBy(trgrs, ({ name }) => name);
  }, [places]);

  return [places, triggers];
};

const SEARCHABLE_ATTRIBUTES = ['name'];

export const PlaylistPlaces = ({ playlistId }) => {
  const { dispatch, error } = useRequestWithPromise();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(true);
  const [asc, setAsc] = useState(true);
  const [sortParam, setSortParam] = useState('name');
  const [places, triggers] = usePlaylistPlaces(playlistId);
  const [
    visiblePlaces, searchQuery, setSearchQuery,
  ] = useSearchFilter(places, SEARCHABLE_ATTRIBUTES);
  const sortedPlaces = useSortedItems(visiblePlaces, sortParam, asc);

  useEffect(() => {
    dispatch(fetchPlaylistPlaces(playlistId)).then(() => {
      setLoading(false);
    });
  }, []);

  // Handle the action-dropdown
  const handleTriggerChange = (changedPlace, changedTrigger, action) => {
    return (e) => {
      e.preventDefault();
      let changedPlaces;

      if (Array.isArray(changedPlace)) {
        changedPlaces = changedPlace;
      } else if (changedPlace === 'visible') {
        // Check if this was a multie-action
        // Fetch visible list items
        changedPlaces = visiblePlaces;
      } else {
        changedPlaces = [changedPlace];
      }

      // Loop place-ids requested for change
      changedPlaces.forEach((place) => {
        // Loop over existing places
        place.triggers?.forEach(({ name, active }) => {
          // Correct trigger found
          if (name === changedTrigger && active !== action) {
            dispatch(createPlaylistPlace(playlistId, {
              ...place,
              triggers: place.triggers.map((tr) => ({
                ...tr,
                active: tr.name === changedTrigger ? action : tr.active,
              })),
              id: parseInt(place.id, 10),
            })).then(() => {
              dispatch(fetchPlaylistPlaces(playlistId));
            });
          }
        });
      });
    };
  };

  /**
     * Callback from render to set appropriate sort field or function
     * @param {string} category String identifier of key to sort on
     */
  const setSortParams = (category) => {
    return () => {
      if (sortParam === category) {
        setAsc((a) => !a);
      } else {
        setAsc(true);
        setSortParam(category);
      }
    };
  };

  const classNameSortParam = ((value) => {
    let className;
    if (sortParam === value) {
      if (asc) {
        className = ' icon-chevron-up';
      } else {
        className = ' icon-chevron-down';
      }
    } else {
      className = ' icon-chevron-right';
    }
    return `sortIcon${className}`;
  });

  if (loading) {
    return (
      <div className="animate module_body">
        <FormattedMessage id="common.loading" />
        {'...'}
      </div>
    );
  }

  if (triggers.size === 0) {
    return (
      <h4 className="pa10">
        <FormattedMessage id="views.playlists.noPlaylistsAvailableForThisPlaylist" />
      </h4>
    );
  }

  if (error) {
    return (
      <ApiErrorMessage error={error} />
    );
  }

  return (
    <div className="animate module_body">
      <div>
        <div className="pa15">
          <div className="search_field">
            <input
              type="text"
              className="form-control search-query"
              placeholder={formatMessage({
                id: 'common.search',
              })}
              style={{ marginBottom: '10px' }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <Dropdown
            closeOnClick
            className="btn-group float-left"
          >
            <DropdownToggle
              className="btn btn-primary"
            >
              {formatMessage({ id: 'common.action' })}
            </DropdownToggle>
            <DropdownContent>
              {triggers.map(
                (trigger) => {
                  return (
                    <button
                      type="button"
                      key={`${trigger.name}_${trigger.action}`}
                      className="dropdown-item"
                      onClick={handleTriggerChange(
                        'visible',
                        trigger.name,
                        trigger.action,
                      )}
                    >
                      {trigger.action ? (
                        <FormattedMessage id="views.playlists.editPlaylist.enableAll" />
                      ) : (
                        <FormattedMessage id="views.playlists.editPlaylist.disableAll" />
                      )}{' '}
                      <span className="bold">{trigger.name}</span>
                    </button>
                  );
                },
              )}
            </DropdownContent>
          </Dropdown>
        </div>
      </div>

      <div className="table_container">
        <table className="table table-fixed table-striped mb-0">
          <thead>
            <tr>
              <th className="sortList" onClick={setSortParams('name')}>
                <i
                  className={classNameSortParam('name')}
                />
                <FormattedMessage id="common.name" />
              </th>
              <th
                className="sortList"
                onClick={setSortParams('triggerActive')}
              >
                <i
                  className={classNameSortParam('triggerActive')}
                />
                <FormattedMessage id="common.triggers" />
              </th>
              <th>
                <FormattedMessage id="views.playlists.triggerConditions" />
              </th>
              <th className="w50">
                <FormattedMessage id="common.type" />
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedPlaces.map((place) => (
              <PlaylistPlaceRow
                key={place.id}
                place={place}
                playlistId={playlistId}
                handleTriggerChange={handleTriggerChange}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

PlaylistPlaces.propTypes = propTypes;


// Handle listRef!!
// PlaylistRows immutable
