import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import cancan from 'js/system/cancan';
import moment from 'moment';
import format from 'js/react/_utils/format';
import mediaFormat from 'js/react/plan/format';
import { getPlannedById, getAllPlanned } from 'src/entities/plannedMedia';

import mediaUrl from 'js/react/_utils/mediaUrl';
import { PLANNING_LONG_TERM, PLANNING_TIMEPLAN } from 'constants/permissions';
import { ConditionsModal } from 'src/screens/MediaPlaybackConditions';
import { getMediaObjectById } from 'src/entities/media';

const defaultMedia = {
  name: '(hidden)',
  length: 0,
  thumb: require('images/not-allowed.png'),
  isDummy: true,
};

const propTypes = {
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  index: PropTypes.number,
  maxDate: PropTypes.instanceOf(moment),
  minDate: PropTypes.instanceOf(moment),
};

function LongPlanConditionsModal({
  planning, plannings,
}) {
  // calculate what fraction of the entire tempalte date interval
  // Is consumed by this planning
  const planStart = planning && moment(planning.time_limit_lower);
  const planEnd = planning && moment(planning.time_limit_upper);

  const dateFmt = 'YYYY-MM-DD';
  const now = Date.now();
  const inFuture = planStart && planStart.valueOf() > now;
  const inPast = planEnd && planEnd.valueOf() < now;
  let Comp = inFuture ? 'em' : 'span';
  Comp = inPast ? 'del' : Comp;

  const styles = ['d-block text-sm pointer m-0'];
  inFuture && styles.push('alert alert-dark text-info py-0 px-1');
  inPast && styles.push('alert alert-danger text-danger py-0 px-1');
  (!inPast && !inFuture) && styles.push('border-0 bg-transparent');

  return (
    <ConditionsModal
      plannings={plannings}
      planning={planning}
      className={styles.join(' ')}
    >
      <Comp>
        {`${planStart && planStart.format(dateFmt)} - ${planEnd && planEnd.format(dateFmt)}`}
      </Comp>
    </ConditionsModal>
  );
}

const MediaRow = (props) => {
  const {
    planningId,
    planningIds,
    index,
    maxDate,
    minDate,
    rowSpan,
    noDrag,
    unordered,
  } = props;

  const hasTimeplan = cancan.can('read', PLANNING_TIMEPLAN);
  const hasLongTimeplan = cancan.can('read', PLANNING_LONG_TERM);

  const allPlannings = useSelector(getAllPlanned);
  const planning = useSelector(getPlannedById(planningId));
  const media = useSelector(getMediaObjectById(planning.media)) || defaultMedia;
  const groupPlannings = useMemo(() => {
    return planningIds?.map((id) => allPlannings[id]);
  }, [allPlannings, planningIds]);

  return (
    <tr
      data-testid={`planned-media-row-${index}-${media.id}`}
    >
      {rowSpan > 0 && (
        <td
          style={{ width: 25, textAlign: 'center' }}
          className={`w10 grouporder${noDrag ? '' : ' listOrder'}`}
          rowSpan={rowSpan}
        >
          {!unordered && <span>{index + 1}</span>}
          {!noDrag && (
            <i className="icon-th" />
          )}
        </td>
      )}
      <td className="w20">
        <input
          data-testid="playlist-media-checkbox"
          type="checkbox"
          className="align-middle"
          onClick={(e) => props.onSelect(e)}
          onChange={() => { }} // Prevents error msg.
          checked={props.selected}
        />
      </td>
      <td className="w50">
        <img alt="" src={window.ROOT_PATH + media.thumb} />
      </td>
      <td>
        {(!media.isDummy && (
          <a href={mediaUrl(media)}>
            <i
              className={
                mediaFormat.mediaType(media.media_type) ||
                mediaFormat.type(media.type)
              }
            />{' '}
            {media.name || media.id}
          </a>
        )) || <span>{media.name}</span>}
        <br />
        <FormattedMessage id="common.playTime" />
        {': '}
        <b>{format.formatSeconds(media.length)}</b>
        {hasLongTimeplan && !hasTimeplan && (
          <LongPlanConditionsModal
            planning={planning}
            plannings={groupPlannings}
          />
        )}
      </td>
      {rowSpan > 0 && (
        <td
          style={{ width: 50, textAlign: 'center' }}
          rowSpan={rowSpan}
        >
          <ConditionsModal
            plannings={groupPlannings}
            maxDate={hasTimeplan ? maxDate : undefined}
            minDate={hasTimeplan ? minDate : undefined}
          />
        </td>
      )}
    </tr>
  );
};

MediaRow.propTypes = propTypes;

export default MediaRow;
