import { filter } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { intlShape } from 'utils/shapes';
import { getMediaObjects } from 'src/entities/media';
import Badge from '../components/Badge';

function MediaCount({ intl, mediaObjects, badgeId }) {
  return (
    <Badge
      title={intl.formatMessage({ id: 'views.dashboard.mediaCount.mediaInLibrary' })}
      badgeId={badgeId}
    >
      <h1 className="ma-0 text-center">
        {mediaObjects
          ? mediaObjects.length
          : `${intl.formatMessage({ id: 'common.loading' })}...`}
      </h1>
    </Badge>
  );
}

MediaCount.propTypes = {
  intl: intlShape.isRequired,
  mediaObjects: PropTypes.array,
  badgeId: PropTypes.string.isRequired,
};

const getMedia = createSelector(
  getMediaObjects,
  (media) => media && filter(media, (m) => m.state !== 'trashed'),
);

function mapStateToProps(state) {
  return {
    mediaObjects: getMedia(state),
  };
}

export default compose(
  injectIntl,
  connect(mapStateToProps),
)(MediaCount);
